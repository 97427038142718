/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { LandlordAction, LandlordActions, LandlordActionTypes } from '../actions/LandlordActions';
import {
  ApplicationRankingRequest,
  ApplicationRequest,
  ApplicationSubmitRequest,
  LandlordAvailableServicesRequest,
  LandlordAvailableServicesResponse,
  LandlordServices,
  MaintenanceRequest,
  MaintenanceResponse,
  MaintenanceStatus,
  MRQuoteRequest,
  MRTradeRequest,
  PropertyApplication,
  PropertyRequest,
  PropertyResponse,
  Service,
  TasksResponse,
  UpdateMRStatusRequest,
} from '../../models/landlord/LandlordProperty';
import landlordService from '../../services/landlord/LandlordService';
import camelcaseKeys from 'camelcase-keys';
import { updateInitialApplicationRanking } from '../../containers/landlordHomeScreen/components/applications/ApplicationsUtils';
import { navigate, resetNavigation } from '../../navigation/NavigationUtils';

export function* getPropertiesSaga(action: LandlordAction) {
  try {
    const {
      ownershipType,
      address,
      advertised,
      page,
      size,
      filterRequest,
      dashboardProperties,
      propertyType,
      search,
    } = action.data as PropertyRequest;
    if (!filterRequest) resetNavigation('home');
    const response: PropertyResponse = yield landlordService.getPropetiesService({
      ownershipType,
      address,
      advertised,
      page,
      size,
      propertyType,
    });
    if (dashboardProperties) {
      yield put(LandlordActions.getDashboardPropertiesSuccess(response));
    } else if (search) {
      yield put(LandlordActions.getPropertiesSearchSuccess(response));
    } else {
      yield put(LandlordActions.getPropertiesSuccess(response));
    }
  } catch (error) {
    yield put(LandlordActions.getPropertiesError(error as Error));
  }
}

export function* getApplicationsSaga(action: LandlordAction) {
  try {
    const { ownershipType, address, advertised } = action.data as ApplicationRequest;
    const response: PropertyApplication[] = yield landlordService.getApplicationsService({
      ownershipType,
      address,
      advertised,
    });
    yield put(LandlordActions.getApplicationsSuccess(updateInitialApplicationRanking(response)));
  } catch (error) {
    yield put(LandlordActions.getApplicationsError(error as Error));
  }
}

export function* submitApplicationSaga(action: LandlordAction) {
  try {
    const { propertyId, applicationId, onSuccess, onError } =
      action.data as ApplicationSubmitRequest;
    yield landlordService.submitApplicationService(propertyId, applicationId);
    yield put(LandlordActions.submitApplicationSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as ApplicationSubmitRequest;
    yield put(LandlordActions.submitApplicationError(error as Error));
    if (onError) {
      onError();
    }
  }
}

export function* getTasksSaga(action: LandlordAction) {
  try {
    const response: TasksResponse = yield landlordService.getTasksService();
    yield put(LandlordActions.getTasksSuccess(response));
  } catch (error) {
    yield put(LandlordActions.getTasksError(error as Error));
  }
}

export function* getMaintenanceSaga(action: LandlordAction) {
  try {
    const { status, propertyId, isMaintenanceHistory } = action.data as MaintenanceRequest;
    const response: MaintenanceResponse[] = yield landlordService.getMaintenanceService(
      status,
      propertyId,
    );
    if (isMaintenanceHistory) {
      yield put(LandlordActions.getMaintenanceHistorySuccess(response));
    } else if (status === MaintenanceStatus.LANDLORD) {
      yield put(LandlordActions.getMaintenanceAttendingSuccess(response));
    } else if (status === MaintenanceStatus.QUOTE) {
      yield put(LandlordActions.getMaintenanceQuoteSuccess(response));
    } else {
      yield put(LandlordActions.getMaintenanceRequestSuccess(response));
    }
  } catch (error) {
    yield put(LandlordActions.getMaintenanceError(error as Error));
  }
}

export function* updateMRStatusSaga(action: LandlordAction) {
  try {
    const { status, maintenanceId, onSuccess } = action.data as UpdateMRStatusRequest;
    yield landlordService.updateMaintenanceStatus(maintenanceId, status);
    yield put(LandlordActions.updateMRStatusSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as UpdateMRStatusRequest;
    yield put(LandlordActions.updateMRStatusError(error as Error));
    if (onError) {
      onError();
    }
  }
}

export function* submitMRTradeSaga(action: LandlordAction) {
  try {
    const { maintenanceId, onSuccess } = action.data as MRTradeRequest;
    yield landlordService.submitMRTradeRequest(maintenanceId);
    yield put(LandlordActions.submitMRTradeSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as MRTradeRequest;
    yield put(LandlordActions.submitMRTradeError(error as Error));
    if (onError) {
      onError();
    }
  }
}

export function* submitMRQuoteSaga(action: LandlordAction) {
  try {
    const { quoteId, status, propertyId, onSuccess } = action.data as MRQuoteRequest;
    yield landlordService.submitMRQuoteRequest(quoteId, status, propertyId);
    yield put(LandlordActions.submitMRQuoteSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as MRQuoteRequest;
    yield put(LandlordActions.submitMRQuoteError(error as Error));
    if (onError) {
      onError();
    }
  }
}

export function* getAvailableServicesSaga(action: LandlordAction) {
  try {
    const { propertyId, services } = action.data as LandlordAvailableServicesRequest;
    const response: LandlordServices = yield landlordService.getAvailableServicesService(
      propertyId,
      services,
    );

    const updatedAvailableServices = response.availableServices.map(
      (data: LandlordAvailableServicesResponse) => ({
        ...data,
        suppliers: data.suppliers.map((supplier) => ({
          ...supplier,
          extendedData: camelcaseKeys(supplier.extendedData, { deep: true }),
        })),
      }),
    );
    const updatedServices = response.services.map((data: Service) => ({
      ...data,
      details: camelcaseKeys(data.details, { deep: true }),
    }));
    yield put(
      LandlordActions.getLandlordAvailableServicesSuccess({
        availableServices: updatedAvailableServices,
        services: updatedServices,
      }),
    );
  } catch (error) {
    yield put(LandlordActions.getLandlordAvailableServicesError(error as Error));
  }
}

export function* updateApplicationRankingSaga(action: LandlordAction) {
  try {
    const { request, onSuccess, propertyId } = action.data as ApplicationRankingRequest;
    yield landlordService.updateApplicationRankingRequest(request, propertyId);
    yield put(LandlordActions.updateApplicationRankingsSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as ApplicationRankingRequest;
    yield put(LandlordActions.updateApplicationRankingError(error as Error));
    if (onError) {
      onError();
    }
  }
}

function* landlordWatcher() {
  yield takeEvery(LandlordActionTypes.GET_PROPERTIES_REQUEST, (action) =>
    getPropertiesSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.GET_APPLICATIONS_REQUEST, (action) =>
    getApplicationsSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.APPLICATION_SUBMIT_REQUEST, (action) =>
    submitApplicationSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.GET_TASKS_REQUEST, (action) =>
    getTasksSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.GET_MAINTENANCE_REQUEST, (action) =>
    getMaintenanceSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.UPDATE_MAINTENANCE_STATUS_REQUEST, (action) =>
    updateMRStatusSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.SUBMIT_MAINTENANCE_TRADE_REQUEST, (action) =>
    submitMRTradeSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.SUBMIT_MR_QUOTE_REQUEST, (action) =>
    submitMRQuoteSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES, (action) =>
    getAvailableServicesSaga(action as LandlordAction),
  );
  yield takeEvery(LandlordActionTypes.UPDATE_APPLCIATION_RANKING_REQUEST, (action) =>
    updateApplicationRankingSaga(action as LandlordAction),
  );
}

export default landlordWatcher;
