import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './DownloadScreenStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TabProps } from '../../models/dashboard/dashboard';
import { LABELS } from './DownloadScreenConstants';
import TabMenu from '../../components/tabMenu/TabMenu';
import SortedFooter from '../../components/sortedFooter/SortedFooter';
import peopleSquare from '../../assets/people-square.png';
import playstore from '../../assets/playstore.png';
import appstore from '../../assets/applestore.png';
import phone from '../../assets/mockphone.png';
import Text from '../../components/text/Text';
import { links } from '../cimetNeeds/CimetNeedsConstants';
import Button from '../../components/button/Button';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface DownloadScreenProps extends RouteComponentProps {}

const DownloadScreen: FC<DownloadScreenProps> = ({}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.contentContainer}>
              <div className={classes.contentWrapper}>
                <Text parentStyles={classes.lifeMade}>{LABELS.LIFE_MADE_SIMPLE}</Text>
                <Text parentStyles={classes.title} textVariant="title">
                  {LABELS.TITLE}
                </Text>
                <Text parentStyles={classes.description} textVariant="info">
                  {LABELS.DESCRIPTION}
                </Text>
                <div className={classes.buttons}>
                  <Button
                    parentStyles={classes.downloadButton}
                    onPress={() => {
                      window.open(links.playstoreNAX, 'https://www.sortedservices.com/try');
                    }}
                  >
                    {LABELS.DOWNLOAD_SORTED}
                  </Button>
                  <div className={classes.storeButtons}>
                    <div className={classes.storeButtonContainer}>
                      <img
                        src={appstore}
                        className={classes.storeButton}
                        alt="app-link"
                        onClick={() => {
                          if (themeKey === ThemeKey.NAX) {
                            window.open(links.appleStoreNAX, '_blank');
                          } else {
                            window.open(links.appleStore, '_blank');
                          }
                        }}
                      />
                    </div>

                    <div className={classes.storeButtonContainer}>
                      <img
                        src={playstore}
                        className={classes.storeButton}
                        alt="app-link"
                        onClick={() => {
                          if (themeKey === ThemeKey.NAX) {
                            window.open(links.playstoreNAX, '_blank');
                          } else {
                            window.open(links.playstore, '_blank');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.imagesContainer}>
                <div className={classes.phoneContainer}>
                  <img src={phone} className={classes.phone} alt="phone" />
                </div>
                <div className={classes.peopleContainer}>
                  <img src={peopleSquare} className={classes.people} alt="phone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DownloadScreen));
