import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { MasterProfileResponsePayload } from '../../models/masterProfile/MasterProfile';

export class MasterProfileService {
  private readonly masterProfileService: ApiServiceBase = new ApiService(ServiceType.MasterProfile);

  public getMasterProfileData():
    | Promise<MasterProfileResponsePayload>
    | MasterProfileResponsePayload {
    return this.masterProfileService.get({});
  }
}

const masterProfileService = new MasterProfileService();
export default masterProfileService;
