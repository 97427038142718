/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { PaymentActionTypes, PaymentActions } from '../../../../store/actions/PaymentActions';
import { connect } from 'react-redux';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { routes } from '../../../../Routes';
import { createEditPaymentObject, maskBankAccountDetails, MaskDetails } from '../AddPaymentUtils';
import { useStyles } from './AddBankStyles';
import {
  CommonPaymentSummary,
  MatchParams,
  PaymentSummary,
  InitialBankValues,
  accountNumberRegex,
  errorMessages,
  bsbValidatorPattern,
  LABELS,
  lengths,
  accountNameRegex,
  bsbNumberRegex,
  initialBankValues,
} from './AddBankConstants';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import secureIcon from '../../../../assets/images/payment/lock-24-px.svg';
import { PaymentServiceType } from '../../selectPayment/PaymentDetailsConstants';
import { Snackbar } from '@mui/material';
import {
  BankAccountRequest,
  GetPaymentMethodsResponse,
  SaveSelectedPaymentMethodPayload,
  PostEditPaymentPayload,
} from '../../../../models/payment/Payment';
import { ApplicationState } from '../../../../store/RootReducer';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { handlePaymentSuccess } from '../addCard/AddCardUtils';
import { extractParams, navigate, navigateBack } from '../../../../navigation/NavigationUtils';
import { AddBankParams } from '../../../../navigation/NavigationConstants';
import Input from '../../../../components/input/Input';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import lockIcon from '../../../../assets/lock-24-px.svg';
import Text from '../../../../components/text/Text';
import arrowLeft from '../../../../assets/navigation/arrow-left.png';

interface AddBankProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AddBank: FC<AddBankProps> = ({
  loading,
  paymentMethodId,
  paymentErrorState,
  saveSelectedPaymentMethod,
  getPaymentAuthKey,
  postEditPayment,
  getPaymentAuthKeyReset,
}) => {
  const classes = useStyles();
  const { propertyId, serviceType, paymentRefId, serviceAccountId } =
    extractParams<AddBankParams>();
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);
  const [maskedBankAccountDetails, setMaskedBankAccountDetails] = useState<MaskDetails>({
    paymentDetails: '',
    logo: '',
  });

  useEffect(() => {
    getPaymentAuthKeyReset();
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedBankAccountDetails,
    });
  }, [paymentMethodId]);

  const handleAccountNumber = (accountNumber: string, setFieldValue: any) => {
    setFieldValue('accountNumber', accountNumber.replace(accountNumberRegex, ''));
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const handleSubmit = (values: InitialBankValues) => {
    const maskedBankAccountDetails = maskBankAccountDetails(values.accountNumber, values.bsbNumber);
    setMaskedBankAccountDetails(maskedBankAccountDetails);
    saveSelectedPaymentMethod({
      paymentMethodId: '2',
      maskedDetails: maskedBankAccountDetails,
    });
    const paymentRequest: BankAccountRequest = {
      type: 'BANKACCOUNT',
      accountName: values.accountName.trim(),
      accountNumber: values.accountNumber,
      bsbNumber: values.bsbNumber,
      email: '',
    };
    getPaymentAuthKey(paymentRequest, handlePayment);
  };

  const handlePayment = (payment: GetPaymentMethodsResponse) => {
    switch (serviceType.toLowerCase()) {
      case PaymentServiceType.Rent:
        navigate('rentSummary');
        break;
      case PaymentServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.Wallet:
        navigate('viewPaymentMethods', { serviceType: 'wallet' });
        break;
      default:
        break;
    }
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment({ data: requestData, onSuccess: () => handlePaymentSuccess() });
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>
            <Formik
              initialValues={initialBankValues}
              onSubmit={(data) => handleSubmit(data)}
              isInitialValid={false}
              validationSchema={Yup.object().shape({
                accountName: Yup.string()
                  .required(errorMessages.required)
                  .max(lengths.accNameMax, errorMessages.accNameLength)
                  .matches(accountNameRegex, errorMessages.accName),
                bsbNumber: Yup.string()
                  .required(errorMessages.required)
                  .matches(bsbNumberRegex, errorMessages.invalidBsb),
                accountNumber: Yup.string()
                  .required(errorMessages.required)
                  .min(lengths.accNumMin, errorMessages.accNumMin)
                  .max(lengths.accNumMax),
              })}
              render={({
                values,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
                isValid,
              }) => (
                <>
                  <Input
                    placeholder={LABELS.ACCOUNT_NAME}
                    value={values.accountName}
                    setValue={(value: string) => {
                      setFieldValue('accountName', value);
                    }}
                    onBlur={() => setFieldTouched('accountName')}
                    touched={touched.accountName}
                    error={errors.accountName}
                    title={LABELS.ACCOUNT_NAME}
                    mandatory
                    big
                  />
                  <Input
                    placeholder={LABELS.BSB}
                    value={values.bsbNumber}
                    setValue={(value: string) => {
                      setFieldValue('bsbNumber', value);
                    }}
                    onBlur={() => setFieldTouched('bsbNumber')}
                    touched={touched.bsbNumber}
                    error={
                      errors.bsbNumber || (paymentErrorState.includes('BSB') && paymentErrorState)
                    }
                    title={LABELS.BSB}
                    mandatory
                    big
                    endElement={<img src={lockIcon} alt="" />}
                  />
                  <Input
                    placeholder={LABELS.ACCOUNT_NUMBER}
                    value={values.accountNumber}
                    setValue={(value: string) => {
                      handleAccountNumber(value, setFieldValue);
                    }}
                    onBlur={() => setFieldTouched('accountNumber')}
                    touched={touched.accountNumber}
                    error={
                      errors.accountNumber ||
                      (paymentErrorState.includes('account number') && paymentErrorState)
                    }
                    title={LABELS.ACCOUNT_NUMBER}
                    mandatory
                    big
                    endElement={<img src={lockIcon} alt="" />}
                  />
                  {!!paymentErrorState && (
                    <div className={classes.error}>
                      Oops! Please double check above for any errors
                    </div>
                  )}
                  <div className={classes.addNewPaymentFooter}>
                    <div className={classes.secureBoxStyle}>
                      <img src={lockIcon} className={classes.secureLockStyle} alt="lock" />
                      <div className={classes.secureText}>{LABELS.SECURE_CONNECTION}</div>
                    </div>
                    <div className={classes.cancelNewOptionSave} onClick={() => navigateBack()}>
                      {LABELS.CANCEL}
                    </div>
                    <div
                      className={classNameGenerator([
                        classes.addNewOptionSave,
                        (loading || !isValid) && classes.disabledButton,
                      ])}
                      onClick={() => {
                        if (!(loading || !isValid)) {
                          handleSubmit();
                        }
                      }}
                    >
                      {loading ? LABELS.LOADING : LABELS.SAVE}
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const error = errorMessageSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);
const loading = loadingSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: error(state) as any,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentAuthKeyReset: () => dispatch(PaymentActions.getPaymentAuthKeyReset()),
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentAuthKey: (
    data: BankAccountRequest,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token },
              onSuccess: (payment: GetPaymentMethodsResponse) => {
                handlePayment(payment);
              },
            }),
          );
        },
        onError: (error: Error) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
  postEditPayment: (data: PostEditPaymentPayload) => {
    dispatch(PaymentActions.postEditPaymentStart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBank);
