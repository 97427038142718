/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import ICON_CHEVRON from '../../assets/navigation/accordian.png';
import { resetService, shouldResetService } from '../../helpers/ResetServiceHelper';
import { useDashboardServices } from '../../helpers/ServiceInfoHelper';
import { useStyles } from './SupplierStyles';
import { LeaseResponse } from '../../models/dashboard/dashboard';
import { Services, Suppliers } from '../../models/services/services';
import { navigate } from '../../navigation/NavigationUtils';
import { createPreopen } from '../../helpers/CheckoutHelper';

interface SupplierProps {
  service: Services;
  suppliers: Suppliers[]; // from route props
  provider: Suppliers;
  index: number;
  onboardedProperties: LeaseResponse[] | undefined;
  selectedProperty: number;
}

const Supplier: FC<SupplierProps> = ({
  service,
  suppliers,
  provider,
  index,
  onboardedProperties,
  selectedProperty,
}) => {
  const classes = useStyles();
  const { property } = useDashboardServices();

  return (
    <div className={classes.serviceCard}>
      <div
        onClick={() => {
          if (shouldResetService(service, provider)) {
            resetService(service);
          }
          navigate('checkoutSelect', {
            requiredServiceTypes: service.serviceType,
            preopen: JSON.stringify(
              createPreopen(service.serviceType, suppliers[index].SupplierId),
            ),
          });
        }}
        className={classes.serviceCardItem}
      >
        <div className={classes.cardComponentStyle}>
          <div className={classes.cardLeftView}>
            <img
              className={classes.supplierIcon}
              src={
                provider.Logo && provider.Logo.Active
                  ? provider.Logo.Active
                  : provider.Logo && provider.Logo.Inactive
                  ? provider.Logo.Inactive
                  : ''
              }
            />

            <div className={classes.cardLeftVerticalView}>
              <div className={classes.supplierName}>{provider.SupplierName.toUpperCase()}</div>
              <div className={classes.supplierPlan}>
                {provider.SupplierLabel ? `${provider.SupplierLabel}` : ''}
              </div>
            </div>
          </div>
          <img className={classes.serviceStatusIcon} src={ICON_CHEVRON} />
        </div>
      </div>
    </div>
  );
};

export default Supplier;
