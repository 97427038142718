import React, { FC, useEffect, useState, useMemo } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputMobileStyles';
import PhoneInput, { Value } from 'react-phone-number-input';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { isEmpty } from '../../helpers/Validation';

interface InputMobileProps {
  placeholder: string;
  value: string;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  endElement?: any;
  showRequiredError?: boolean;
  big?: boolean;
  onBlur?: () => void;
  setValue: (value: string) => void;
}

const InputMobile: FC<InputMobileProps> = ({
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  endElement,
  showRequiredError,
  big,
  onBlur,
  setValue,
}) => {
  const classes = useStyles();
  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div
              className={classNameGenerator([
                classes.titleContainer,
                big && classes.titleContainerBig,
              ])}
            >
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <div
        className={classNameGenerator([
          classes.inputContainer,
          big && classes.inputContainerBig,
          ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
        ])}
      >
        <PhoneInput
          flagUrl="https://public.sortedservices.com/flags/4x3/{xx}.svg"
          defaultCountry="AU"
          className={classNameGenerator([
            classes.inputStyle,
            big && classes.bigInput,
            parentInputStyles,
          ])}
          disabled={disabled}
          placeholder={placeholder}
          value={value as Value}
          onChange={(getPhone) => setValue(getPhone as string)}
          onBlur={onBlur}
        />
        <div className={classNameGenerator([classes.endElement, big && classes.endElementBig])}>
          {endElement}
        </div>
        {((touched && error) || (showRequiredError && isEmpty(value)) || value) && (
          <img
            className={classNameGenerator([classes.endStatus, big && classes.endStatusBig])}
            src={
              (touched && error) || (showRequiredError && isEmpty(value))
                ? IncompleteIcon
                : value
                ? CompletedIcon
                : ''
            }
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default InputMobile;
