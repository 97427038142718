import React, { FC } from 'react';
import { useStyles } from './GravatarsStyles';
import localStorage from '../../services/LocalStorage';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { stringToHslColor } from '../../helpers/GravatarHelper';
import { GroupResponse } from '../../models/groups/Groups';

interface GravatarsProps {
  group: GroupResponse;
  parentStyles?: string;
}

const Gravatars: FC<GravatarsProps> = ({ group, parentStyles }) => {
  const primaryFirstname =
    group.primary.profileData.firstName || localStorage.getItem('firstname')!;
  const primaryLastname = group.primary.profileData.lastName || localStorage.getItem('lastname')!;
  const classes = useStyles();

  const renderGravatar = (firstname: string, lastname: string) => {
    if (firstname === '' && lastname === '') {
      return null;
    }
    return (
      <div
        className={classNameGenerator([classes.gravatar, parentStyles])}
        style={{ backgroundColor: stringToHslColor(`${firstname} ${lastname}`, 70, 70) }}
      >
        {firstname.charAt(0)}
        {lastname.charAt(0)}
      </div>
    );
  };

  return (
    <>
      {renderGravatar(primaryFirstname, primaryLastname)}
      {group.secondary.map((s) =>
        renderGravatar(s.profileData.firstName || '', s.profileData.lastName || ''),
      )}
      {group.dependents.map((d) =>
        renderGravatar(d.profileData.firstName || '', d.profileData.lastName || ''),
      )}
      {group.coApplicants.map((co) => (
        <>
          {renderGravatar(co.primary.firstName || '', co.primary.lastName || '')}
          {co.secondary.map((s) => renderGravatar(s.firstName || '', s.lastName || ''))}
          {co.dependent.map((d) => renderGravatar(d.firstName || '', d.lastName || ''))}
        </>
      ))}
    </>
  );
};

export default Gravatars;
