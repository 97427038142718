import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { AppSettings } from '../../AppSettings';
import {
  GuestTokenRequest,
  GuestTokenResponse,
  RegisterClientResponse,
} from '../../models/auth/RegisterToken';
import { SessionTokenResponse } from '../../models/auth/auth';

class TokenService {
  private readonly tradesAuthService: ApiServiceBase = new ApiService(ServiceType.TradesAuth);
  private readonly tokenService: ApiServiceBase = new ApiService(ServiceType.token);

  public registerClient(): Promise<RegisterClientResponse> | RegisterClientResponse {
    return this.tradesAuthService.get<any>({ route: ['token'] });
  }

  public getGuestToken(data: GuestTokenRequest): Promise<GuestTokenResponse> | GuestTokenResponse {
    const customTradesAuthService: ApiServiceBase = new ApiService(
      ServiceType.TradesAuth,
      AppSettings.server.baseUrl,
      {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${data.token}`,
      },
    );
    return customTradesAuthService.get<GuestTokenResponse>({ route: ['guest-token'] });
  }

  public getSessionToken(token: string): Promise<SessionTokenResponse> | SessionTokenResponse {
    return this.tokenService.get<SessionTokenResponse>({
      query: {
        token,
      },
    });
  }
}

const tokenService = new TokenService();
export default tokenService;
