import { put, takeEvery } from 'redux-saga/effects';
import {
  SaveImageRequest,
  CreateMaintenanceRequestStartPayload,
  CreateMaintenanceRequestStartRequest,
  CreateMaintenanceRequestUpdatePayload,
  CreateMaintenanceRequestUpdateRequest,
  CreateMaintenanceRequestSuccessResponse,
} from '../../models/maintenance/maintenance';
import MaintenanceService from '../../services/maintenance/MaintenanceService';
import {
  MaintenanceAction,
  MaintenanceActionTypes,
  MaintenanceActions,
} from '../actions/MaintenanceActions';

export function* postMaintenanceImageWorker(action: MaintenanceAction) {
  try {
    const data = action.data as SaveImageRequest;
    const response: string = yield MaintenanceService.postMultipartImage(data.imageData);
    yield put(
      MaintenanceActions.saveImageResponse({
        key: data.key,
        uuid: response,
      }),
    );
  } catch (error) {
    yield put(MaintenanceActions.saveImageError(error as Error));
  }
}

export function* postMaintenanceRequestWorker(action: MaintenanceAction) {
  const payloadData = action.data as CreateMaintenanceRequestStartPayload;
  const { onSuccess } = payloadData;
  try {
    delete payloadData.onSuccess;
    const request: CreateMaintenanceRequestStartRequest = {
      ...payloadData,
      data: JSON.stringify(payloadData.data),
    };
    const response: CreateMaintenanceRequestSuccessResponse =
      yield MaintenanceService.postMaintenanceRequest(request);
    yield put(MaintenanceActions.sendMaintenanceResponse(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(MaintenanceActions.sendMaintenanceError(error as Error));
  }
}

export function* updateMaintenanceRequestWorker(action: MaintenanceAction) {
  const payloadData = action.data as CreateMaintenanceRequestUpdatePayload;
  const { onSuccess } = payloadData;
  try {
    delete payloadData.onSuccess;
    const request: CreateMaintenanceRequestUpdateRequest = {
      ...payloadData,
      data: JSON.stringify(payloadData.data),
    };
    const response: CreateMaintenanceRequestSuccessResponse =
      yield MaintenanceService.updateMaintenanceRequest(request);
    yield put(MaintenanceActions.sendMaintenanceResponse(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(MaintenanceActions.sendMaintenanceError(error as Error));
  }
}

function* maintenanceWatcher() {
  yield takeEvery(MaintenanceActionTypes.SAVE_IMAGE_REQUEST, (action) =>
    postMaintenanceImageWorker(action as MaintenanceAction),
  );
  yield takeEvery(MaintenanceActionTypes.SEND_MAINTENANCE_REQUEST, (action) =>
    postMaintenanceRequestWorker(action as MaintenanceAction),
  );
  yield takeEvery(MaintenanceActionTypes.UPDATE_MAINTENANCE_REQUEST, (action) =>
    updateMaintenanceRequestWorker(action as MaintenanceAction),
  );
}

export default maintenanceWatcher;
