import { takeEvery, put } from 'redux-saga/effects';
import {
  AuthDetails,
  AuthDetailsResponse,
  FindUserPayload,
  FindUserResponse,
  ForgotPasswordRequest,
  ForgotPasswordToastMesssages,
  LoginRequest,
  PasswordRequest,
  RegisterUserRequest,
} from '../../models/auth/auth';
import { ProfileRequest } from '../../models/user/user';
import AuthService from '../../services/auth/AuthService';
import { AuthAction, AuthActions, AuthActionTypes } from '../actions/AuthActions';
import { ProfileActions } from '../actions/ProfileActions';
import { SpinnerActions } from '../actions/SpinnerActions';
import { TokenActions } from '../actions/TokenActions';
import { navigate, resetNavigation } from '../../navigation/NavigationUtils';
import { ToastMessage } from '../../helpers/ToastMessage';

export function* loginRequest(action: AuthAction) {
  try {
    const response: AuthDetailsResponse = yield AuthService.login(action.data as LoginRequest);
    const authResponse: AuthDetails = {
      userId: response.userId,
      email: response.email,
      accessToken: response.authToken,
      refreshToken: response.refreshToken,
      expiresIn: response.expires_in,
      resetRequired: response.passwordResetRequired,
      intercomId: response.intercomId,
      superUser: response.superUser,
    };

    yield put(TokenActions.setAccessToken(response.authToken));
    yield put(TokenActions.setRefreshToken(response.refreshToken));
    localStorage.setItem('userId', response.userId.toString());

    yield put(AuthActions.postLoginSuccess(authResponse));

    const profileRequest: ProfileRequest = {
      userId: response.userId,
      intercomId: authResponse.intercomId,
      superUser: authResponse.superUser,
    };
    yield put(ProfileActions.getProfileRequest(profileRequest));

    if (response.passwordResetRequired) {
      resetNavigation('passwordSetup');
    } else {
      yield put(ProfileActions.getUserRolesRequest());
    }
  } catch (error) {
    yield put(AuthActions.postLoginError(error as Error));
    ToastMessage.showMessage((error as any).message, 'Cancel', 'danger', 3000, true);
  }
}

export function* sendEmailSaga(action: AuthAction): IterableIterator<any> {
  try {
    const response = yield AuthService.sendEmail(action.data as ForgotPasswordRequest);
    yield put(AuthActions.forgotPasswordSuccess(response));
    navigate('emailSent');
  } catch (error: any) {
    if (error.message !== ForgotPasswordToastMesssages.wrongEmailResponse) {
      ToastMessage.showMessage(
        ForgotPasswordToastMesssages.emailError,
        'Cancel',
        'danger',
        3000,
        true,
      );
    }

    yield put(AuthActions.forgotPasswordError(error));
  }
}

export function* registerAndLogin(request: AuthAction) {
  try {
    const response: AuthDetailsResponse = yield AuthService.registerAndLogin(
      request.data as RegisterUserRequest,
    );

    const authResponse: AuthDetails = {
      userId: response.userId,
      email: response.email,
      accessToken: response.authToken,
      refreshToken: response.refreshToken,
      expiresIn: response.expires_in,
      resetRequired: response.passwordResetRequired,
      intercomId: response.intercomId,
      superUser: response.superUser,
    };

    yield put(TokenActions.setAccessToken(response.authToken));
    yield put(TokenActions.setRefreshToken(response.refreshToken));
    localStorage.setItem('userId', response.userId.toString());

    yield put(AuthActions.postRegisterSuccess(authResponse));

    navigate('creatingAccount', { response });
  } catch (error: any) {
    navigate('creatingAccount', { error });
    yield put(AuthActions.postRegisterError(error));
  }
}

export function* applyUserDetails(action: AuthAction) {
  try {
    const applyResponse: FindUserResponse = yield AuthService.getApplyUserDetails({
      username: (action.data as FindUserPayload).username,
    });
    yield put(AuthActions.getApplyUserDetailsSuccess(applyResponse));
  } catch (error) {
    yield put(AuthActions.getApplyUserDetailError(error as Error));
  }
}

export function* logoutSaga() {
  try {
    yield AuthService.logout();
    localStorage.clear();
    yield put(AuthActions.postLogoutSuccess());
  } catch (error) {
    yield put(AuthActions.postLogoutSuccess());
  }
}

export function* passwordReset(action: AuthAction) {
  try {
    const response: void = yield AuthService.resetPassword(action.data as PasswordRequest);
    yield put(AuthActions.passwordResetSuccess());
    ToastMessage.showMessage('Password Updated', 'Okay', 'success', 3000);
    yield put(ProfileActions.getUserRolesRequest());
  } catch (error) {
    yield put(AuthActions.passwordResetError(error as Error));
  }
}

// login new user after register user
export function* loginWithTokenWorker(response: AuthDetailsResponse): IterableIterator<any> {
  try {
    yield put(
      ProfileActions.getProfileRequest({
        userId: response.userId,
        intercomId: response.intercomId,
        superUser: response.superUser,
      }),
    );
    yield put(ProfileActions.getUserRolesRequest());
  } catch (error) {
    navigate('errorScreen');
    console.log('error', error);
  }
}

function* authWatcher() {
  yield takeEvery(AuthActionTypes.LOGIN_REQUEST, (action: AuthAction) => loginRequest(action));
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_REQUEST, (action: AuthAction) =>
    sendEmailSaga(action),
  );
  yield takeEvery(AuthActionTypes.REGISTER_REQUEST, (action: AuthAction) =>
    registerAndLogin(action),
  );
  yield takeEvery(AuthActionTypes.FIND_USER_REQUEST, (action: AuthAction) =>
    applyUserDetails(action),
  );
  yield takeEvery(AuthActionTypes.LOGOUT_REQUEST, () => logoutSaga());
  yield takeEvery(AuthActionTypes.PASSWORD_RESET_REQUEST, (action: AuthAction) =>
    passwordReset(action),
  );
  yield takeEvery(AuthActionTypes.LOGIN_WITH_TOKEN, (action: AuthAction) =>
    loginWithTokenWorker(action.data as AuthDetailsResponse),
  );
}

export default authWatcher;
