/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getFonts } from '../../helpers/GetFonts';
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
      marginBottom: theme.units.spacing * 4,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: theme.units.borderRadius / 1.5,
      border: `${theme.colors.borderColor} 2px solid`,
      backgroundColor: theme.colors.inputBackground,
    },
    inputStyle: {
      border: 'none',
      '&:focus-visible': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.inputText,
        fontSize: theme.font.m,
        fontWeight: getFonts('Light'),
      },
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      flex: 1,
      margin: theme.units.spacing * 2,
      marginLeft: theme.units.spacing * 3,
      position: 'relative',
      width: '100%',
      resize: 'none',
      height: 70,
    },
    errorInput: {
      border: `${theme.colors.error} 2px solid`,
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
    secondInput: {
      marginLeft: theme.units.spacing * 4,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    endElement: {
      marginLeft: theme.units.spacing * 2,
    },
    endStatus: {
      marginLeft: theme.units.spacing,
      width: 25,
      height: 25,
      marginRight: theme.units.spacing * 2,
    },
    disabled: {
      opacity: 0.4,
    },
    dollar: {
      marginLeft: theme.units.spacing * 2,
    },
  })();
};
