/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useMemo } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import backArrow from '../../assets/navigation/arrow-left.png';
import { useContext } from 'react';
import searchIcon from '../../assets/navigation/search.png';
import blackDoc from '../../assets/billcheck.png';
import blackWrench from '../../assets/maintenanceActive.png';
import gymIcon from '../../assets/marketplace/dark/gym.png';
import bookRoomIcon from '../../assets/marketplace/dark/bookRoom.png';
import locksmithIcon from '../../assets/marketplace/dark/locksmith.png';
import ICON_CHEVRON from '../../assets/right-chevron-dark.png';
import { grabSuppliers, SupplierService } from './SearchBarUtils';
import { DashboardState } from '../../store/state/DashboardState';
import { navigate } from '../../navigation/NavigationUtils';
import { CimetVisitRequest } from '../../models/cimet/Cimet';
import { CimetActions } from '../../store/actions/CimetActions';
import ElectricityCard from '../electricityCard/ElectricityCard';
import { findExtraServiceInfo, useDashboardServices } from '../../helpers/ServiceInfoHelper';
import BroadbandCard from '../broadbandCard/BroadbandCard';
import OtherServiceCards from '../otherServiceCards/OtherServiceCards';
import { getDarkServiceTypeIcon } from '../../containers/home/components/dashboard/DashboardConstants';
import { ApplicationState } from '../../store/RootReducer';
import { removeDuplicatedCimetServices } from '../../containers/home/components/dashboard/DashboardUtils';
import { ServiceTypes } from '../../models/services/services';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';
import { useStyles } from './SearchBarStyles';
import { AuthDetails } from '../../models/auth/auth';
import { classNameGenerator } from '../../theme/GlobalStyles';
import Supplier from '../supplier/Supplier';
import Input from '../input/Input';
import { ClickAwayListener } from '@mui/material';

interface SearchBarProps {
  paddingBottom?: boolean;
  isGuest: boolean;
  dashboardState: DashboardState;
  authDetails: AuthDetails;
  postCimetVisit: (data: CimetVisitRequest) => void;
}

const SearchBar: FC<SearchBarProps> = ({
  paddingBottom,
  isGuest,
  dashboardState: { onboardedProperties, selectedProperty },
  authDetails,
  postCimetVisit,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  const [searchString, setSearchString] = useState<string>('');
  const { property, newAndInProgessServices, cimetAvailableServices, activeServices } =
    useDashboardServices();
  const suppliersFound = useMemo<SupplierService[]>(() => {
    return grabSuppliers(
      newAndInProgessServices,
      searchString,
      onboardedProperties,
      selectedProperty,
    );
  }, [searchString]);

  const electricityServiceType = useMemo<SupplierService | undefined>(() => {
    return suppliersFound.find(
      (provider) =>
        provider.service.serviceType === ServiceTypes.Electricity && provider.showService,
    );
  }, [suppliersFound]);

  const broadbandServiceType = useMemo<SupplierService | undefined>(() => {
    return suppliersFound.find(
      (provider) => provider.service.serviceType === ServiceTypes.Broadband && provider.showService,
    );
  }, [suppliersFound]);

  const otherServiceTypes = useMemo<SupplierService[]>(() => {
    return suppliersFound.filter(
      (provider) =>
        provider.service.serviceType !== ServiceTypes.Electricity &&
        provider.service.serviceType !== ServiceTypes.Broadband &&
        provider.showService,
    );
  }, [suppliersFound]);

  const billCheck = useMemo<boolean>(() => {
    return 'bill check'.includes(searchString.toLowerCase()) && !isGuest;
  }, [searchString]);

  const gym = useMemo<boolean>(() => {
    return (
      ('gym'.includes(searchString.toLowerCase()) ||
        'fitness studio'.includes(searchString.toLowerCase())) &&
      !isGuest &&
      themeKey === ThemeKey.SWITCH
    );
  }, [searchString]);

  const bookRoom = useMemo<boolean>(() => {
    return (
      ('book room'.includes(searchString.toLowerCase()) ||
        'facilities'.includes(searchString.toLowerCase()) ||
        'book spaces'.includes(searchString.toLowerCase())) &&
      !isGuest &&
      themeKey === ThemeKey.SWITCH
    );
  }, [searchString]);

  const doorLocks = useMemo<boolean>(() => {
    return (
      'digital key'.includes(searchString.toLowerCase()) && !isGuest && themeKey === ThemeKey.SWITCH
    );
  }, [searchString]);

  const trades = useMemo<boolean>(() => {
    if (property && onboardedProperties![selectedProperty].property!.isMaintenanceAllowed) {
      return (
        'trades'.includes(searchString.toLowerCase()) ||
        'maintenance'.includes(searchString.toLowerCase())
      );
    }
    return false;
  }, [searchString]);

  const cimetServices = useMemo<ServiceTypes[]>(() => {
    if (
      property &&
      !!cimetAvailableServices.length &&
      !!removeDuplicatedCimetServices(activeServices, cimetAvailableServices).length
    ) {
      return removeDuplicatedCimetServices(activeServices, cimetAvailableServices).filter(
        (service) => {
          return service.toLowerCase().includes(searchString.toLowerCase());
        },
      );
    }
    return [];
  }, [searchString]);

  const somethingFound = useMemo<boolean>(() => {
    return (
      !!suppliersFound.length ||
      !!electricityServiceType ||
      !!broadbandServiceType ||
      !!otherServiceTypes.length ||
      gym ||
      bookRoom ||
      doorLocks ||
      billCheck ||
      trades ||
      !!cimetServices.length
    );
  }, [
    suppliersFound,
    electricityServiceType,
    broadbandServiceType,
    otherServiceTypes,
    billCheck,
    gym,
    bookRoom,
    doorLocks,
    trades,
    cimetServices,
  ]);

  return (
    <div className={classNameGenerator([paddingBottom && classes.paddingBottom])}>
      <div className={classes.searchBarContainer}>
        <div onClick={() => setSearchString('')}>
          <img src={searchString ? backArrow : searchIcon} className={classes.searchIcon} />
        </div>
        <Input
          placeholder={
            themeKey === ThemeKey.SWITCH ? 'Search our services' : 'Services, trades, products'
          }
          value={searchString}
          setValue={(value: string) => {
            setSearchString(value);
          }}
          hideEndElement
          parentStyles={classes.searchInput}
          parentInputStyles={classes.searchInput2}
        />
      </div>
      <ClickAwayListener onClickAway={() => setSearchString('')}>
        <div className={classes.root}>
          {searchString !== '' && (
            <div className={classes.resultsContainer}>
              <div className={classes.resultsTitle}>Search results</div>
              {!!electricityServiceType && (
                <ElectricityCard
                  addressConfirmed={
                    onboardedProperties![selectedProperty].addressConfirmed as boolean
                  }
                  service={electricityServiceType.service}
                  extraServiceInfo={findExtraServiceInfo(
                    electricityServiceType.service,
                    onboardedProperties![selectedProperty].availableServices,
                  )}
                  propertyId={property!.id}
                  showSearchIcon
                />
              )}
              {!!broadbandServiceType && (
                <BroadbandCard
                  addressConfirmed={
                    onboardedProperties![selectedProperty].addressConfirmed as boolean
                  }
                  service={broadbandServiceType.service}
                  extraServiceInfo={findExtraServiceInfo(
                    broadbandServiceType.service,
                    onboardedProperties![selectedProperty].availableServices,
                  )}
                  propertyId={property!.id}
                  showSearchIcon
                />
              )}
              {!!otherServiceTypes.length && (
                <OtherServiceCards
                  addressConfirmed={
                    onboardedProperties![selectedProperty].addressConfirmed as boolean
                  }
                  services={otherServiceTypes.map((s) => s.service)}
                  availableServices={onboardedProperties![selectedProperty].availableServices}
                  propertyId={property!.id}
                  showSearchIcon
                />
              )}
              {billCheck && (
                <div className={classes.requestRow} onClick={() => navigate('checkBillScreen')}>
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} style={{ left: 1 }} src={blackDoc} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>Bill check</div>
                    <div className={classes.description}>Find a better plan</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              )}
              {trades && (
                <div className={classes.requestRow} onClick={() => navigate('maintenance')}>
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} src={blackWrench} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>Maintenance</div>
                    <div className={classes.description}>Request a quote</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              )}
              {gym && (
                <div
                  className={classes.requestRow}
                  onClick={() => {
                    // if (Platform.OS === 'ios') {
                    //   Linking.openURL('https://itunes.apple.com/us/app/1590364463');
                    // } else {
                    //   Linking.openURL(
                    //     'https://play.google.com/store/apps/details?id=com.technogym.mywellness.societyfitnessattheswitch',
                    //   );
                    // }
                  }}
                >
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} src={gymIcon} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>Fitness studio</div>
                    <div className={classes.description}>Get the app</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              )}
              {bookRoom && (
                <div className={classes.requestRow} onClick={() => navigate('switchRoomBooking')}>
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} src={bookRoomIcon} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>Book spaces</div>
                    <div className={classes.description}>Book a room</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              )}
              {doorLocks && (
                <div
                  className={classes.requestRow}
                  onClick={() => {
                    // if (Platform.OS === 'ios') {
                    //   Linking.openURL('https://itunes.apple.com/us/app/1403770732');
                    // } else {
                    //   Linking.openURL(
                    //     'https://play.google.com/store/apps/details?id=com.assaabloy.hospitality.mobileaccess.studentliving&hl=en_AU&gl=US&pli=1',
                    //   );
                    // }
                  }}
                >
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} src={locksmithIcon} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>Digital key</div>
                    <div className={classes.description}>Get the app</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              )}
              {suppliersFound.map((provider) => (
                <>
                  {provider.suppliers.map((p, index) => (
                    <Supplier
                      service={provider.service}
                      suppliers={provider.suppliers}
                      provider={p}
                      index={index}
                      onboardedProperties={onboardedProperties}
                      selectedProperty={selectedProperty}
                    />
                  ))}
                </>
              ))}
              {cimetServices.map((service) => (
                <div
                  className={classes.requestRow}
                  onClick={() => {
                    postCimetVisit({ propertyId: property!.id, serviceType: service });
                    // Linking.openURL(
                    //   `${sortedWebUrls.cimet(
                    //     property!.id,
                    //     service,
                    //     authDetails.accessToken,
                    //     authDetails.refreshToken,
                    //     authDetails.email,
                    //     authDetails.userId,
                    //   )}`,
                    // );
                  }}
                >
                  <div className={classes.iconContainer}>
                    <img className={classes.icon} src={getDarkServiceTypeIcon(service)} />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{service.toString()}</div>
                    <div className={classes.description}>Set up {service.toString()}</div>
                  </div>
                  <img className={classes.arrow} src={ICON_CHEVRON} />
                </div>
              ))}
              {!somethingFound && <div className={classes.noResult}>No results found</div>}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  authDetails: state.authState.authDetails,
  isGuest: state.authState.guest,
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postCimetVisit: (data: CimetVisitRequest) => dispatch(CimetActions.postCimetVisitRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
