import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './PasswordSetupStyles';
import { LABELS, VALIDATION_SCHEMA } from './PasswordSetupConstants';
import Box from '../../components/box/Box';
import Text from '../../components/text/Text';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/NavigationUtils';
import sortedLogo from '../../assets/sorted-icon-dark.png';
import { useFormik } from 'formik';
import { AuthActionTypes, AuthActions } from '../../store/actions/AuthActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { PasswordRequest } from '../../models/auth/auth';
import Input from '../../components/input/Input';
import { loadingSelector } from '../../store/selectors/LoadingSelector';

interface PasswordSetupProps extends RouteComponentProps {
  loading: boolean;
  resetPassword: (request: PasswordRequest) => void;
}

const PasswordSetup: FC<PasswordSetupProps> = ({ loading, resetPassword }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      resetPassword({ password: values.confirmPassword });
    },
    validationSchema: VALIDATION_SCHEMA,
  });

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <form onSubmit={formik.handleSubmit}>
              <Box lightBorder parentStyles={classes.boxContainer}>
                <Text parentStyles={classes.title} textVariant="title" size="xl">
                  {LABELS.TITLE}
                </Text>
                <Input
                  placeholder={LABELS.NEW_PASSWORD}
                  key={LABELS.NEW_PASSWORD}
                  value={formik.values.newPassword}
                  setValue={formik.handleChange('newPassword')}
                  onBlur={() => formik.setFieldTouched('newPassword')}
                  touched={formik.touched.newPassword}
                  error={formik.errors.newPassword}
                  title={LABELS.NEW_PASSWORD}
                  mandatory
                  big
                />

                <Input
                  placeholder={LABELS.CONFIRM_PASSWORD}
                  key={LABELS.CONFIRM_PASSWORD}
                  value={formik.values.confirmPassword}
                  setValue={formik.handleChange('confirmPassword')}
                  onBlur={() => formik.setFieldTouched('confirmPassword')}
                  touched={formik.touched.confirmPassword}
                  error={formik.errors.confirmPassword}
                  title={LABELS.CONFIRM_PASSWORD}
                  mandatory
                  big
                />

                <ul className={classes.list}>
                  <li className={classes.dotPoint}>At least 8 characters</li>
                  <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                    One special character
                  </li>
                  <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                    One uppercase character
                  </li>
                </ul>
                <div className={classes.loginRow}>
                  <Button
                    parentStyles={classes.loginButton}
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    onPress={() => null}
                  >
                    {LABELS.DONE}
                  </Button>
                </div>
              </Box>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([AuthActionTypes.PASSWORD_RESET]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPassword: (request: PasswordRequest) => dispatch(AuthActions.passwordResetRequest(request)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PasswordSetup);
