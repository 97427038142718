/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { useStyles } from './ActionCardStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import errorIcon from '../../assets/alert-large.png';
import arrowRight from '../../assets/navigation/arrow-right.png';
import closeIcon from '../../assets/navigation/close.png';
import { getColouredIcon } from '../../containers/browse/components/marketplace/MarketplaceUtils';
import { getColourFilter } from '../../helpers/ColourHelper';

interface ActionCardProps {
  title: string;
  text: string;
  showErrorIcon?: boolean;
  buttonText: string;
  dismiss?: boolean;
  card?: boolean;
  image?: string;
  showRightText?: string;
  isLink?: boolean;
  hideButton?: boolean;
  backgroundColor?: string;
  dark?: boolean;
  parentStyles?: string;
  service?: string;
  coloured?: boolean;
  onDismiss?: () => void;
  handleButtonPress: () => void;
  handleRightAction?: () => void;
}

const ActionCard: FC<ActionCardProps> = ({
  title,
  text,
  showErrorIcon,
  buttonText,
  dismiss,
  card,
  image,
  isLink,
  showRightText,
  hideButton,
  backgroundColor,
  dark,
  parentStyles,
  service,
  coloured,
  onDismiss,
  handleButtonPress,
  handleRightAction,
}) => {
  const classes = useStyles();
  const colours = getColouredIcon(service);
  const filterPrimary = coloured ? getColourFilter(colours.primary) : null;
  const filterBackground = coloured ? getColourFilter(colours.background) : null;

  return (
    <div
      className={classNameGenerator([
        classes.actionCardContainer,
        showErrorIcon && classes.alert,
        parentStyles,
      ])}
    >
      <div
        className={classes.background}
        style={{
          ...(filterBackground && { filter: filterBackground }),
          ...(!!backgroundColor && { backgroundColor }),
        }}
      />
      <div className={classNameGenerator([classes.contentContainer])}>
        <div className={classes.textContainer}>
          <div className={classes.titleContainer}>
            <div
              className={classNameGenerator([
                classes.title,
                (showErrorIcon || dark) && classes.textDark,
              ])}
            >
              {title}
            </div>
            <div className={classes.actionsContainer}>
              {dismiss && (
                <div className={classes.crossContainer} onClick={onDismiss}>
                  <img src={closeIcon} className={classes.cross} />
                </div>
              )}
              {!!showRightText && (
                <div onClick={handleRightAction}>
                  <div className={classNameGenerator([classes.rightText, isLink && classes.link])}>
                    {showRightText}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classNameGenerator([classes.actionContainer])}>
            {!hideButton && (
              <div className={classes.action} onClick={handleButtonPress}>
                <div
                  className={classNameGenerator([
                    classes.buttonText,
                    (showErrorIcon || dark) && classes.textDark,
                  ])}
                >
                  {buttonText}
                </div>
                <img
                  src={arrowRight}
                  className={classNameGenerator([
                    classes.actionIcon,
                    (showErrorIcon || dark) && classes.darkIcon,
                  ])}
                />
              </div>
            )}
            {(image || showErrorIcon) && (
              <div className={classes.imageContainer}>
                <img
                  style={filterPrimary ? { filter: filterPrimary } : {}}
                  src={showErrorIcon ? errorIcon : image}
                  className={classes.image}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
