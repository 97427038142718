/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    contentContainer: {},
    summaryCard: {
      backgroundColor: theme.colors.light,
      padding: '16px',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: 12,
      border: `${theme.colors.borderColorLight} 2px solid`,
      marginBottom: '12px',
    },
    address: {
      fontSize: 22,
      fontWeight: 500,
      color: theme.colors.black87,
      fontFamily: 'GT Walsheim',
    },
    address2: {
      marginTop: 6,
      fontSize: 15,
      fontWeight: 500,
      color: '#b9b9b9',
      fontFamily: 'GT Walsheim',
    },
  })();
};
