export interface GenericServiceSummaryDictionary {
  [serviceAccountId: number]: GenericServiceSummaryResponse;
}

export interface GenericServiceSummaryRequest {
  serviceAccountId: number;
}

export interface GenericServiceSummaryResponse {
  serviceAccountId: number;
  backgroundImageUrl: string | undefined;
  logoUrl: string;
  serviceType: string;
  serviceProvider: string;
  clientCode: string;
  servicePageDetails: GenericSection[];
  serviceDetails: ServiceDetails;
  sections: GenericSection[];
  links?: Links[];
  extendedResponse: ExtendedResponse;
}

export interface ServiceDetails {
  planDetails: PlanDetails;
}

export interface PlanDetails {
  productDetails: ProductDetails;
}

export interface ProductDetails {
  productType: string;
}

export interface Links {
  type: string;
  name: string;
  label: string;
  link: string;
}

export interface ExtendedResponse {
  paymentRefId: string;
  serviceAccountId: number;
}

export interface GenericSection {
  sectionTitle: SectionTitle | string;
  data: KeyValuePair[];
}

export enum SectionTitle {
  yourCurrentPlan = 'YOUR CURRENT PLAN',
  accountDetails = 'ACCOUNT DETAILS',
  supplierDetails = 'SUPPLIER DETAILS',
  paymentDetails = 'PAYMENT DETAILS',
}

export enum PlanSection {
  personalDetails = 'PERSONAL DETAILS',
  planSummary = 'PLAN SUMMARY',
  benefits = 'BENEFITS',
  planDetails = 'PLAN DETAILS',
  documents = 'DOCUMENTS',
}

export interface KeyValuePair {
  key: string;
  value: string | number;
}
