/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import electricity from '../../../../assets/marketplace/dark/electricity.png';
import broadband from '../../../../assets/marketplace/dark/broadband.png';
import gas from '../../../../assets/marketplace/dark/gas.png';
import insurance from '../../../../assets/marketplace/dark/insurance.png';
import cleaning from '../../../../assets/marketplace/dark/cleaning.png';
import solar from '../../../../assets/marketplace/dark/solar.png';
import removals from '../../../../assets/marketplace/dark/removals.png';
import water from '../../../../assets/marketplace/dark/water.png';
import trades from '../../../../assets/marketplace/dark/trades.png';
import gardening from '../../../../assets/marketplace/dark/gardening.png';
import finance from '../../../../assets/marketplace/dark/finance.png';
import gym from '../../../../assets/marketplace/light/gym.png';
import bookRoom from '../../../../assets/marketplace/light/bookRoom.png';
import locksmith from '../../../../assets/marketplace/light/locksmith.png';
import heart from '../../../../assets/marketplace/light/heart.png';

import essentials from '../../../../assets/marketplace/dark/essentials.png';
import protect from '../../../../assets/marketplace/dark/protect.png';
import maintain from '../../../../assets/marketplace/dark/maintain.png';
import optimise from '../../../../assets/marketplace/dark/optimise.png';
import { getCurrentTheme, theme } from '../../../../theme/Theme';
import { ServiceTypes, Services } from '../../../../models/services/services';
import { navigate } from '../../../../navigation/NavigationUtils';

export const LABELS = {
  NO_SERVICES: 'No services are available at your address right now. Check back soon!',
};

export interface BannerItem {
  image: string;
  title: string;
  buttonText: string;
  backgroundColor?: string;
  serviceType: ServiceTypes;
  service?: Services;
  action: () => void;
}

export const banners: BannerItem[] = [
  {
    image: bookRoom,
    title: 'Tap here to book available meeting rooms or other common spaces.',
    buttonText: 'Book a space',
    backgroundColor: '#F22E5B',
    serviceType: ServiceTypes.OtherService,
    action: () => navigate('switchRoomBooking'),
  },
  {
    image: gym,
    title: "We've got your workout sorted! Get the Society Fitness app",
    buttonText: 'Get the app',
    backgroundColor: '#782A8F',
    serviceType: ServiceTypes.OtherService,
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1590364463');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.technogym.mywellness.societyfitnessattheswitch',
      //   );
      // }
    },
  },
  {
    image: heart,
    title: 'Prioritize your wellbeing. Get the Sonder Safety & Wellbeing App',
    buttonText: 'Get the app',
    backgroundColor: theme.colors.darkBlue,
    serviceType: ServiceTypes.OtherService,
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1178892371');
      // } else {
      //   Linking.openURL('https://play.google.com/store/apps/details?id=com.sonder.member.android');
      // }
    },
  },
  {
    image: locksmith,
    title: 'Download the Livvi app to get access to your digital key.',
    buttonText: 'Get the app',
    backgroundColor: '#DC6B19',
    serviceType: ServiceTypes.OtherService,
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1403770732');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.assaabloy.hospitality.mobileaccess.studentliving&hl=en_AU&gl=US&pli=1',
      //   );
      // }
    },
  },
  {
    image: cleaning,
    title: 'Take your house to the cleaners',
    buttonText: 'Find a cleaner',
    serviceType: ServiceTypes.Cleaning,
    action: () => null,
  },
  {
    image: electricity,
    title: 'Hit the switch: Set up electricity',
    buttonText: 'Find a plan',
    serviceType: ServiceTypes.Electricity,
    action: () => null,
  },
  {
    image: gardening,
    title: 'Winter is here! Need to clean those gutters?',
    buttonText: 'Find a gardener',
    serviceType: ServiceTypes.Gardening,
    action: () => null,
  },
  {
    image: removals,
    title: 'Get moving! Find a removalist near you',
    buttonText: 'Find a removalist',
    serviceType: ServiceTypes.Removals,
    action: () => null,
  },
  {
    image: cleaning,
    title: 'These people think laundry is loads of fun',
    buttonText: 'Find a cleaner',
    serviceType: ServiceTypes.Cleaning,
    action: () => null,
  },
  {
    image: gas,
    title: "It's cold! Get the heater on ASAP!",
    buttonText: 'Find a plan',
    serviceType: ServiceTypes.Gas,
    action: () => null,
  },
  {
    image: broadband,
    title: 'Get your game on: Setup broadband',
    buttonText: 'Find a plan',
    serviceType: ServiceTypes.Broadband,
    action: () => null,
  },
  {
    image: insurance,
    title: 'Protect your house from the big bad wolf',
    buttonText: 'Get insurance today',
    serviceType: ServiceTypes.Insurance,
    action: () => null,
  },
  {
    image: solar,
    title: 'Solar energy: a bright alternative',
    buttonText: 'Find a plan',
    serviceType: ServiceTypes.Solar,
    action: () => null,
  },
  {
    image: finance,
    title: "Cash. Because you're wallet needs a buddy",
    buttonText: 'Get finance',
    serviceType: ServiceTypes.Finance,
    action: () => null,
  },
];

export const categoryTypes = [
  {
    icon: essentials,
    name: 'Essentials',
    link: 'essentialsScreen',
  },
  {
    icon: protect,
    name: 'Protect',
    link: 'protectScreen',
  },
  {
    icon: maintain,
    name: 'Maintain',
    link: 'maintainScreen',
  },
  {
    icon: optimise,
    name: 'Optimise',
    link: 'optimiseScreen',
  },
];

export const serviceTypes = [
  {
    icon: electricity,
    name: 'Electricity',
  },
  {
    icon: broadband,
    name: 'Broadband',
  },
  {
    icon: gas,
    name: 'Gas',
  },
  {
    icon: insurance,
    name: 'Insurance',
  },

  {
    icon: cleaning,
    name: 'Cleaning',
  },
  {
    icon: solar,
    name: 'Solar',
  },
  {
    icon: removals,
    name: 'Removals',
  },
  {
    icon: water,
    name: 'Water',
  },
  {
    icon: trades,
    name: 'Trades',
  },
  {
    icon: gardening,
    name: 'Gardening',
  },
  {
    icon: finance,
    name: 'Finance',
  },
];
