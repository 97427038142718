import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './BlogCardStyles';
import Text from '../text/Text';
import Box from '../box/Box';
import Button from '../button/Button';
import electricity from '../../assets/marketplace/dark/electricity.png';
import { LABELS } from './BlogCardConstants';

interface BlogCardProps {
  title: string;
  body: string;
  image?: string;
  link?: string;
}

const BlogCard: FC<BlogCardProps> = ({ title, body, image, link }) => {
  const classes = useStyles();

  return (
    <Box parentStyles={classes.boxContainer} lightBorder>
      {image && <img src={image} className={classes.image} alt="blog" />}
      <div className={classes.goodToKnowRow}>
        <img src={electricity} className={classes.icon} alt="idea" />
        <Text textVariant="link">{LABELS.GOOD_TO_KNOW}</Text>
      </div>
      <Text textVariant="title" parentStyles={classes.titleStyles}>
        {title}
      </Text>
      <Text textVariant="info" size="l" parentStyles={classes.bodyStyles}>
        {body}
      </Text>
      {link && (
        <Button onPress={() => window.open(link, '_blank')} parentStyles={classes.link}>
          {LABELS.READ_MORE}
        </Button>
      )}
    </Box>
  );
};

export default BlogCard;
