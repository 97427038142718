import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './RentOnboardingSummaryStyles';
import { LABELS, rentDefaultServiceTypes } from './RentOnboardingSummaryConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { RentOnboardingActions } from '../../store/actions/RentOnboarding';
import {
  PaymentProvider,
  PostRentOnboardingRequest,
} from '../../models/rentOnboarding/rentOnboarding';
import { Property } from '../../models/property/property';
import { PostPaymentTokenResponse } from '../../models/payment/Payment';
import { ThemeKey, theme } from '../../theme/Theme';
import { AppSettings } from '../../AppSettings';
import { useGetLeaseData } from '../../helpers/RentServiceHelper';
import SORTED from '../../assets/logosCircle/sorted.png';
import house from '../../assets/houses/house3.png';
import TileContainer from '../../components/tileContainer/TileContainer';
import home from '../../assets/navigation/home.png';
import moment from 'moment';
import Button from '../../components/button/Button';
import { getPaymentType } from '../rentOnboardingConfirmation/RentOnboardingConfirmationConstants';
import { calculateCost } from '../../helpers/CostFrequencyHelper';
import { RentalFrequency } from '../../models/lease/lease';
import {
  PaymentType,
  paymentMethod,
} from '../checkoutFinalize/components/paymentSection/PaymentSectionConstants';
import { handlePaymentDetails } from './RentOnboardingSummaryUtils';

interface RentOnboardingSummaryProps {
  theme: ThemeKey;
  paymentResponse: PostPaymentTokenResponse | null;
  postRentService: (
    data: PostRentOnboardingRequest,
    theme: ThemeKey,
    propertyId: string,
    cimetEnabled?: boolean | null,
  ) => void;
}

const RentOnboardingSummary: FC<RentOnboardingSummaryProps> = ({
  theme,
  paymentResponse,
  postRentService,
}) => {
  const classes = useStyles();
  const lease = useGetLeaseData();

  const selectedPaymentMethod = useMemo<
    | {
        secondaryText: string;
        paymentTypeLogo: string;
      }
    | undefined
  >(() => {
    if (
      lease &&
      lease.paymentDetails &&
      lease.paymentDetails.paymentProvider === PaymentProvider.SORTED
    ) {
      return handlePaymentDetails(paymentResponse);
    } else {
      return undefined;
    }
  }, [lease]);

  const address = useMemo<{ address1: string; address2: string } | undefined>(() => {
    if (lease && lease.property) {
      const { unitNumber, streetNumber, streetName, state, suburb, postcode } = lease.property;
      const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
      const address2 = `${suburb}, ${state} ${postcode}`;
      return { address1, address2 };
    } else {
      return undefined;
    }
  }, [lease]);

  const renderPaymentProviderLogo = () => {
    if (paymentResponse) {
      return paymentResponse.paymentMethod === PaymentType.Bank ? (
        <img src={paymentMethod.Bank.logo} className={classes.bankLogo} />
      ) : paymentResponse.cardType === 'Mastercard' ? (
        <img src={paymentMethod.Credit[0].logo} className={classes.masterLogo} />
      ) : paymentResponse.cardType === 'Visa' ? (
        <img src={paymentMethod.Credit[1].logo} className={classes.visaLogo} />
      ) : (
        <img src={paymentMethod.Credit[1].logo} className={classes.visaLogo} />
      );
    } else {
      return <img src={paymentMethod.Credit[1].logo} className={classes.visaLogo} />;
    }
  };

  const handlePostRentService = () => {
    if (lease!.serviceability && lease!.serviceability.length === 0) {
      if (
        !!lease!.initialPayments.length ||
        (lease!.paymentDetails!.paymentProvider &&
          lease!.paymentDetails!.paymentProvider === PaymentProvider.SORTED)
      ) {
        postRentService(
          {
            paymentRefId: paymentResponse!.refId,
            leaseRefId: lease!.leaseRefId,
          },
          theme,
          `${lease!.property!.id}`,
          lease!.cimetEnabled,
        );
      } else {
        postRentService(
          {
            paymentRefId: null,
            leaseRefId: lease!.leaseRefId,
          },
          theme,
          `${lease!.property!.id}`,
          lease!.cimetEnabled,
        );
      }
    } else {
      navigate('leaseServiceability');
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            {lease ? (
              <>
                <div className={classes.title}>
                  {!!lease.initialPayments.length ? LABELS.NAV_INITIAL_TITLE : LABELS.NAV_TITLE}
                </div>
                <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>
                <TileContainer title={LABELS.PROPERTY} noScroll>
                  <div className={classes.propertyContainer}>
                    {address && (
                      <div className={classes.messageContainer}>
                        <div className={classes.messageTitle}>{address.address1}</div>
                        <div className={classes.messageSubtitle}>{address.address2}</div>
                      </div>
                    )}
                    <img
                      className={classes.agencyLogo}
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = SORTED;
                      }}
                      src={lease && !!lease.agency.logoUrl ? lease.agency.logoUrl : SORTED}
                    />
                    <div className={classes.houseContainer}>
                      <img className={classes.house} src={house} />
                    </div>
                  </div>
                </TileContainer>
                <TileContainer
                  title={
                    !!lease.initialPayments.length ? LABELS.INITIAL_PAYMENTS : LABELS.RENT_PAYMENTS
                  }
                  noScroll
                >
                  {!!lease.initialPayments.length ? (
                    <>
                      {lease.initialPayments.map((payment, idx) => (
                        <div className={classes.rentContainer}>
                          <div className={classes.rentLogoContainer}>
                            <img className={classes.rentLogo} src={home} />
                          </div>{' '}
                          <div className={classes.messageContainer}>
                            <div className={classes.messageTitle}>{getPaymentType(payment)}</div>
                            <div className={classes.messageSubtitle}>
                              {lease.agency.tradingName}
                            </div>
                          </div>
                          <div className={classes.rentTextContainer}>
                            <div className={classes.rentTitle}>${payment.amount.toFixed(2)}</div>
                            <div className={classes.rentSubtitle}>
                              Draw date: {moment(payment.paymentDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={classes.rentContainer}>
                      <div className={classes.rentLogoContainer}>
                        <img className={classes.rentLogo} src={home} />
                      </div>
                      <div className={classes.messageContainer}>
                        <div className={classes.messageTitle}>{LABELS.RENT}</div>
                        <div className={classes.messageSubtitle}>{lease.agency.tradingName}</div>
                      </div>
                      <div className={classes.rentTextContainer}>
                        <div className={classes.rentTitle}>
                          {calculateCost(
                            lease.paymentFrequency as RentalFrequency,
                            lease.monthlyCost as number,
                            true,
                          )}
                        </div>
                        <div className={classes.rentSubtitle}>{lease.paymentFrequency}</div>
                      </div>
                    </div>
                  )}
                  {(!!lease.initialPayments.length ||
                    (lease.paymentDetails!.paymentProvider &&
                      lease.paymentDetails!.paymentProvider === PaymentProvider.SORTED)) && (
                    <div className={classes.rentContainer}>
                      {renderPaymentProviderLogo()}
                      <div className={classes.messageContainer}>
                        <div className={classes.paymentTitle}>
                          {paymentResponse && paymentResponse.paymentMethod === PaymentType.Bank
                            ? 'Bank Account'
                            : 'Credit card'}
                        </div>
                        {selectedPaymentMethod && (
                          <div className={classes.messageSubtitle}>
                            {selectedPaymentMethod!.secondaryText.replace(/\./g, '*')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!lease.initialPayments.length && (
                    <div className={classes.extraInfoContainer}>
                      {lease.nextPaymentDate &&
                        lease.paymentDetails!.paymentProvider === PaymentProvider.SORTED && (
                          <div className={classes.infoRow}>
                            <div className={classes.itemTitle}>Next payment date</div>
                            <div className={classes.itemValue}>{lease.nextPaymentDate}</div>
                          </div>
                        )}
                      {lease.paymentDetails!.paymentProvider &&
                        lease.paymentDetails!.paymentProvider !== PaymentProvider.SORTED && (
                          <div className={classes.infoRow}>
                            <div className={classes.itemTitle}>Payment provider</div>
                            <div className={classes.itemValue}>
                              {lease.paymentDetails!.paymentProvider}
                            </div>
                          </div>
                        )}
                      {lease.paymentDetails!.billInfo && (
                        <div className={classes.infoRow}>
                          <div className={classes.itemTitle}>Biller code</div>
                          <div className={classes.itemValue}>{lease.paymentDetails!.billInfo}</div>
                        </div>
                      )}
                      {lease.paymentDetails!.paymentReferenceId && (
                        <div className={classes.infoRow}>
                          <div className={classes.itemTitle}>Reference number</div>
                          <div className={classes.itemValue}>
                            {lease.paymentDetails!.paymentReferenceId}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </TileContainer>
                <div className={classes.buttonContainer}>
                  <Button onPress={() => handlePostRentService()} parentStyles={classes.button}>
                    {LABELS.FINISH}
                  </Button>
                </div>
              </>
            ) : (
              <>Loading</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  theme: state.themeState.themeKey,
  paymentResponse: state.payment.selectedViewPaymentMethod,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentService: (
    data: PostRentOnboardingRequest,
    theme: ThemeKey,
    propertyId: string,
    cimetEnabled?: boolean | null,
  ) => {
    dispatch(
      RentOnboardingActions.postRentOnboardingStart({
        data,
        onSuccess: () => {
          if (theme !== ThemeKey.SWITCH && !!cimetEnabled) {
            navigate('cimet', { propertyId });
          } else {
            navigate('checkoutSelect', {
              requiredServiceTypes: rentDefaultServiceTypes,
              propertyId,
            });
          }
        },
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RentOnboardingSummary);
