import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';

export const LABELS = {
  TITLE: 'Maintain',
  SORRY: 'Sorry, there are no services currently available. Please check again soon.',
  BACK: 'Back to marketplace',
};

export enum Tab {
  Trades = 'Trades',
  Cleaning = 'Cleaning',
  Gardening = 'Gardening',
}

export const tradesTab = [
  {
    value: Tab.Trades,
    active: false,
  },
];

export const cleaningTab = [
  {
    value: Tab.Cleaning,
    active: false,
  },
];

export const gardeningTab = [
  {
    value: Tab.Gardening,
    active: false,
  },
];

export const initialTags = (otherServices: Services[]) => {
  let result: TagBarItem[] = [...tradesTab];

  if (otherServices.length) {
    const cleaning = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Cleaning,
    ]);
    const gardening = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Gardening,
    ]);

    if (gardening.length) {
      result = [...result, ...gardeningTab];
    }
    if (cleaning.length) {
      result = [...result, ...cleaningTab];
    }
  }
  return result;
};
