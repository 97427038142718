import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Snackbar, SnackbarContent, Grid } from '@mui/material';
import { ToastActions } from '../../store/actions/ToastActions';
import { Dispatch } from 'redux';
import { ToastState } from '../../store/state/ToastState';
import { useStyles } from './ToastStyles';
import success from '../../assets/done-24.png';
import error from '../../assets/error-outline-24.png';
import { ApplicationState } from '../../store/RootReducer';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface ToastProps {
  toastData: ToastState;
  hideToast: () => void;
}

const Toast: FC<ToastProps> = ({ toastData, hideToast }) => {
  const classes = useStyles();

  const getSource = (type: 'danger' | 'success' | 'warning' | undefined) => {
    switch (type) {
      case 'danger':
        return error;
      case 'success':
        return success;
      case 'warning':
        return error;
    }
  };

  return (
    <Snackbar
      open={toastData.showToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={toastData.duration || 2000}
      onClose={() => hideToast()}
    >
      <SnackbarContent
        className={classNameGenerator([
          classes.snackBarContentStyles,
          toastData.type === 'danger' && classes.errorStyle,
          toastData.type === 'warning' && classes.errorStyle,
        ])}
        message={
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <img src={getSource(toastData.type)} className={classes.icon} />
            </Grid>
            <Grid item className={classes.snackBarTextStyles}>
              {toastData.message}
            </Grid>
          </Grid>
        }
      />
    </Snackbar>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  toastData: state.toast,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideToast: () => dispatch(ToastActions.hideToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
