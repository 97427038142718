/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    contentContainer: {},
    heading: {
      margin: 5,
    },
    text: {
      color: theme.colors.black87,
      fontSize: '20px',
      fontWeight: 500,
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.light,
      padding: '12px',
      paddingLeft: 16,
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: 12,
      border: `${theme.colors.borderColorLight} 2px solid`,
      marginBottom: '12px',
      cursor: 'pointer',
      minHeight: 60,
    },
    selectedMenuItem: {
      border: `${theme.colors.buttonPrimary} 2px solid`,
    },
    menuRow: {
      display: 'flex',
      alignItems: 'center',
    },
    houseContainer: {
      width: '60px',
      height: '60px',
      marginRight: '24px',
    },
    houseImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    tickContainer: {
      width: '22px',
      height: '22px',
      marginLeft: 'auto',
      marginRight: '12px',
    },
    tick: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      ...actionGreen,
    },
    hideItem: {
      display: 'none',
    },
  })();
};
