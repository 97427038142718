import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PropertyOnboardingStep, getNavText } from './PropertyOnboardingConstants';
import { useStyles } from './PropertyOnboardingStyles';
import { checkAddress, getBtnStatus, getOnboardingStep } from './PropertyOnboardingUtils';
import { extractParams, navigate, navigateBack } from '../../navigation/NavigationUtils';
import { PropertyOnboardingParams } from '../../navigation/NavigationConstants';
import {
  AddressComponents,
  OnboardingPropertyDetails,
  PropertyClass,
  PropertyOnboardingRequest,
  PropertyType,
  SetOnboardingPropertyDetails,
} from '../../models/property/property';
import { useLocation } from 'react-router';
import { ApplicationState } from '../../store/RootReducer';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Button from '../../components/button/Button';
import Text from '../../components/text/Text';
import { PropertyActions } from '../../store/actions/PropertyOnboardingActions';
import AddProperty from './components/addProperty/AddProperty';
import SelectPropertyType from './components/selectPropertyType/SelectPropertyType';
import SelectRole from './components/selectRole/SelectRole';
import Summary from './components/summary/Summary';
import ManualEntry from './components/manualEntry/ManualEntry';
import SettingUpProperty from './components/settingUpProperty/SettingUpProperty';

interface PropertyOnboardingProps {
  address: AddressComponents;
  selectedType: PropertyType | undefined;
  selectedClass: PropertyClass | undefined;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  postProperty: (data: PropertyOnboardingRequest) => void;
}

const PropertyOnboarding: FC<PropertyOnboardingProps> = ({
  address,
  selectedType,
  selectedClass,
  setPropertyDetails,
  postProperty,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const onboardingStep = useMemo<PropertyOnboardingStep>(() => {
    const result = extractParams<PropertyOnboardingParams>();
    return getOnboardingStep(result);
  }, [location]);

  const nextStep = (searchedAddress?: AddressComponents) => {
    if (!getBtnStatus(onboardingStep, { address, selectedType, selectedClass })) {
      switch (onboardingStep) {
        case PropertyOnboardingStep.AddProperty:
          checkAddress(searchedAddress, address);

          break;
        case PropertyOnboardingStep.SelectPropertyType:
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.SelectRole });
          break;
        case PropertyOnboardingStep.SelectRole:
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.Summary });
          break;
        case PropertyOnboardingStep.ManualEntry:
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.SelectPropertyType });
          break;
        case PropertyOnboardingStep.Summary:
          postProperty({ address, selectedType: selectedType!, selectedClass: selectedClass! });
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.Loading });
          break;
        default:
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.Loading });
          break;
      }
    }
  };

  const renderStep = () => {
    switch (onboardingStep) {
      case PropertyOnboardingStep.AddProperty:
        return (
          <AddProperty
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => {
              setPropertyDetails(data);
              nextStep(data.value as AddressComponents);
            }}
            goToNextPage={() => null}
          />
        );
      case PropertyOnboardingStep.SelectPropertyType:
        return (
          <SelectPropertyType
            selectedType={selectedType}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
          />
        );
      case PropertyOnboardingStep.SelectRole:
        return (
          <SelectRole
            selectedClass={selectedClass}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
          />
        );
      case PropertyOnboardingStep.ManualEntry:
        return (
          <ManualEntry
            address={address}
            setPropertyDetails={(data: SetOnboardingPropertyDetails) => setPropertyDetails(data)}
            goToNextPage={() => nextStep()}
          />
        );
      case PropertyOnboardingStep.Summary:
        return <Summary address={address} />;
      case PropertyOnboardingStep.Loading:
        return <SettingUpProperty />;
      default:
        return <SettingUpProperty />;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{getNavText(onboardingStep).title}</div>
            <div className={classes.subtitle}>{getNavText(onboardingStep).subtitle}</div>
            {renderStep()}
            {![PropertyOnboardingStep.Loading, PropertyOnboardingStep.ManualEntry].includes(
              onboardingStep,
            ) && (
              <>
                <div className={classes.buttonContainer}>
                  {onboardingStep === PropertyOnboardingStep.Summary && (
                    <Button
                      outline
                      parentStyles={classes.button}
                      onPress={() =>
                        navigate('propertyOnboarding', { page: PropertyOnboardingStep.AddProperty })
                      }
                    >
                      Back to search
                    </Button>
                  )}
                  <Button
                    disabled={getBtnStatus(onboardingStep, {
                      address,
                      selectedType,
                      selectedClass,
                    })}
                    onPress={() => nextStep()}
                    parentStyles={classes.button}
                  >
                    {onboardingStep === PropertyOnboardingStep.Summary ? "That's it" : 'Next'}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  address: state.propertyOnboarding.address,
  selectedClass: state.propertyOnboarding.selectedClass,
  selectedType: state.propertyOnboarding.selectedType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPropertyDetails: (data: SetOnboardingPropertyDetails) =>
    dispatch(PropertyActions.setPropertyDetails(data)),
  postProperty: (data: PropertyOnboardingRequest) =>
    dispatch(PropertyActions.postPropertyRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyOnboarding);
