import { User, Roles, Profile } from '../../models/user/user';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class ProfileService {
  public getProfile(userId: number): Promise<User> {
    const service: ApiService = new ApiService(ServiceType.profile);
    const path: QueryPath = {
      route: [userId.toString()],
    };
    return service.get<User>(path);
  }

  public getUserRoles(): Promise<Roles> {
    const service: ApiService = new ApiService(ServiceType.userRoles);

    return service.get<Roles>({});
  }

  public updateProfile(userId: number, profile: User): Promise<Profile> {
    const service: ApiService = new ApiService(ServiceType.profile);
    const path: QueryPath = {
      route: ['update', userId.toString()],
      query: null,
    };
    return service.put<Profile>(path, profile);
  }
}

const profileService = new ProfileService();
export default profileService;
