import {
  AuthDetails,
  FindUserPayload,
  FindUserResponse,
  ForgotPasswordRequest,
  LoginRequest,
  RegisterUserRequest,
  PasswordRequest,
  AuthDetailsResponse,
} from '../../models/auth/auth';
import { StoreAction } from '../StoreHelper';

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_RESET = 'LOGIN_RESET',

  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',

  REGISTER = 'REGISTER',
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',

  LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN',

  SET_GUEST = 'SET_GUEST',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

  FIND_USER_REQUEST = 'FIND_USER_REQUEST',
  FIND_USER_SUCCESS = 'FIND_USER_SUCCESS',
  FIND_USER_ERROR = 'FIND_USER_ERROR',

  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR',
}

export type AuthActionPayload =
  | LoginRequest
  | ForgotPasswordRequest
  | RegisterUserRequest
  | FindUserPayload
  | FindUserResponse
  | AuthDetails
  | PasswordRequest
  | AuthDetailsResponse
  | Error;

export type AuthAction = StoreAction<AuthActionTypes, AuthActionPayload>;

export class AuthActions {
  public static loginWithToken(data: AuthDetailsResponse): AuthAction {
    return { type: AuthActionTypes.LOGIN_WITH_TOKEN, data };
  }

  public static postLoginRequest(data: LoginRequest): AuthAction {
    return { type: AuthActionTypes.LOGIN_REQUEST, data };
  }

  public static postLoginSuccess(data: AuthDetails): AuthAction {
    return { type: AuthActionTypes.LOGIN_SUCCESS, data };
  }

  public static postLoginError(data: Error): AuthAction {
    return { type: AuthActionTypes.LOGIN_ERROR, data };
  }

  public static resetLoginErrors() {
    return { type: AuthActionTypes.LOGIN_RESET };
  }

  public static forgotPasswordRequest(data: ForgotPasswordRequest): AuthAction {
    return {
      type: AuthActionTypes.FORGOT_PASSWORD_REQUEST,
      data,
    };
  }

  public static forgotPasswordSuccess(data: any): AuthAction {
    return {
      type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
      data,
    };
  }

  public static forgotPasswordError(data: Error): AuthAction {
    return {
      type: AuthActionTypes.FORGOT_PASSWORD_ERROR,
      data,
    };
  }

  public static postRegisterRequest(data: RegisterUserRequest): AuthAction {
    return { type: AuthActionTypes.REGISTER_REQUEST, data };
  }

  public static postRegisterSuccess(data: AuthDetails): AuthAction {
    return { type: AuthActionTypes.REGISTER_SUCCESS, data };
  }

  public static postRegisterError(data: Error): AuthAction {
    return { type: AuthActionTypes.REGISTER_ERROR, data };
  }

  public static postLogoutRequest(): AuthAction {
    return { type: AuthActionTypes.LOGOUT_REQUEST, data: null };
  }

  public static postLogoutSuccess(): AuthAction {
    return { type: AuthActionTypes.LOGOUT_SUCCESS, data: null };
  }

  public static getApplyUserDetails(data: FindUserPayload): AuthAction {
    return {
      type: AuthActionTypes.FIND_USER_REQUEST,
      data,
    };
  }
  public static getApplyUserDetailsSuccess(data: FindUserResponse): AuthAction {
    return {
      type: AuthActionTypes.FIND_USER_SUCCESS,
      data,
    };
  }
  public static getApplyUserDetailError(error: Error): AuthAction {
    return {
      type: AuthActionTypes.FIND_USER_ERROR,
      data: error,
    };
  }

  public static passwordResetRequest(data: PasswordRequest): AuthAction {
    return {
      type: AuthActionTypes.PASSWORD_RESET_REQUEST,
      data,
    };
  }

  public static passwordResetSuccess(): AuthAction {
    return {
      type: AuthActionTypes.PASSWORD_RESET_SUCCESS,
      data: null,
    };
  }

  public static passwordResetError(data: Error): AuthAction {
    return {
      type: AuthActionTypes.PASSWORD_RESET_ERROR,
      data,
    };
  }
}
