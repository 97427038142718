import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WalletActions } from '../../../store/actions/WalletActions';
import { isUndefined } from 'lodash';
import {
  LABELS,
  MatchParams,
  PAYMENT_TYPES,
  DRAWERS,
  termsDrawerTitle,
  commonTermsAndConditionText,
  serviceTypeList,
  debitCardTermsAnadConditionsText,
  downloadText,
  paymentDisabledModal,
  AGREEMENT_URL,
  defaultPaymentTypes,
} from './AddPaymentConstants';
import { useStyles } from './AddPaymentStyles';
import { getAgencyName } from './AddPaymentUtils';
import {
  ExtraPayText,
  PAYMENT_LABELS,
  PaymentServiceType,
  transactionFeeType,
} from '../selectPayment/PaymentDetailsConstants';
import { routes } from '../../../Routes';
import tick from '../../../assets/tick.png';
import arrowLeft from '../../../assets/navigation/arrow-left.png';
import { Route, extractParams, navigate, navigateBack } from '../../../navigation/NavigationUtils';
import { AddPaymentParams } from '../../../navigation/NavigationConstants';
import { GetPaymentTypeResponse } from '../../../models/payment/Payment';
import { ApplicationState } from '../../../store/RootReducer';
import { Typography, Drawer, Checkbox, Dialog } from '@mui/material';
import { AGENCIES } from '../../../helpers/AppNameHelper';
import { calculateCost } from '../../../helpers/CostFrequencyHelper';
import { RentalFrequency } from '../../../models/lease/lease';
import Button from '../../../components/button/Button';
import Text from '../../../components/text/Text';
import { useGetLeaseData } from '../../../helpers/RentServiceHelper';
import { theme } from '../../../theme/Theme';
import { DownloadHelper } from '../../../helpers/DownloadHelper';
import info from '../../../assets/info.png';
import SlideLeft from '../../../components/transitionsHelper/SlideLeft';
import closeIcon from '../../../assets/navigation/close.png';

interface AddPaymentProps {
  paymentTypes: GetPaymentTypeResponse[];
}

const AddPayment: FC<AddPaymentProps> = ({ paymentTypes }) => {
  const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } =
    extractParams<AddPaymentParams>();
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState<string>('');
  const [termsDrawer, setTermsDrawer] = useState<boolean>(false);
  const [paymentTypeDisabledDrawer, setPaymentTypeDisabledDrawer] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<DRAWERS | undefined>(undefined);
  const [checked, setChecked] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const rentService = useGetLeaseData();

  const paymentTypeOptions = useMemo<GetPaymentTypeResponse[]>(() => {
    return serviceType === serviceTypeList.wallet ? defaultPaymentTypes : paymentTypes;
  }, [paymentTypes, defaultPaymentTypes, serviceType]);

  const paymentMethodEnabled = useMemo<GetPaymentTypeResponse | undefined>(() => {
    if (selected === null) return undefined;
    return paymentTypeOptions.find((p) => p.paymentMethod === selected);
  }, [paymentTypeOptions, selected]);

  const paymentMethodDetails = useMemo<ExtraPayText | undefined>(() => {
    if (paymentMethodEnabled === undefined) return undefined;

    return PAYMENT_LABELS.extraPayText.find(
      (data) => data.type === paymentMethodEnabled.paymentMethod,
    );
  }, [paymentMethodEnabled]);

  const selectedPayment = (type: string, link: string, drawerName: DRAWERS) => {
    if (drawerName === DRAWERS.paymentTypeDisabledDrawer) {
      setPaymentTypeDisabledDrawer(true);
    }
    setSelected(type);
    setIsSelected(drawerName !== DRAWERS.paymentTypeDisabledDrawer);
    setNextPage(link);
    setOpenDrawer(drawerName);
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>

            {PAYMENT_TYPES.map((payment, index) => (
              <div
                key={payment.name}
                onClick={() =>
                  selectedPayment(
                    payment.type,
                    payment.type === 'Bank' ? 'addBank' : 'addCard',
                    paymentTypeOptions.find(
                      (paymentType) => paymentType.paymentMethod === payment.type,
                    )
                      ? DRAWERS.termsDrawer
                      : DRAWERS.paymentTypeDisabledDrawer,
                  )
                }
                style={
                  !paymentTypeOptions.find(
                    (paymentType) => paymentType.paymentMethod === payment.type,
                  )
                    ? { opacity: 0.5 }
                    : {}
                }
                className={classes.menuItem}
              >
                <div className={classes.iconContainer}>
                  <img src={payment.icon} className={classes.iconImage} />
                </div>
                <div className={classes.optionContainer}>
                  <div className={classes.optionTitle}>{payment.name}</div>
                </div>

                {isSelected && selected === payment.type && (
                  <div className={classes.tickContainer}>
                    <img src={tick} className={classes.tick} />
                  </div>
                )}
              </div>
            ))}
            {isSelected && paymentMethodEnabled && (
              <div className={classes.messageBox}>
                <img src={info} className={classes.info} />
                <>
                  {!paymentMethodEnabled.transactionFeeValue ? (
                    <Typography variant="body2" className={classes.transactionFeeStyle}>
                      {paymentMethodDetails && paymentMethodDetails.zeroValueMessage}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className={classes.transactionFeeStyle}>
                      {paymentMethodDetails &&
                        paymentMethodDetails.value(
                          paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                            ? `$${paymentMethodEnabled.transactionFeeValue}`
                            : `${paymentMethodEnabled.transactionFeeValue}%`,
                          paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                            ? `$${paymentMethodEnabled.internationalFeeValue}`
                            : `${paymentMethodEnabled.internationalFeeValue}%`,
                        )}
                    </Typography>
                  )}
                </>
              </div>
            )}

            <div className={classes.buttonContainer}>
              <Button
                disabled={!isSelected}
                parentStyles={classes.button}
                onPress={() => {
                  if (isSelected) {
                    if (openDrawer === DRAWERS.paymentTypeDisabledDrawer) {
                      setPaymentTypeDisabledDrawer(true);
                    } else {
                      setTermsDrawer(true);
                    }
                  }
                }}
              >
                {PAYMENT_LABELS.buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={termsDrawer}
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        onClose={() => setTermsDrawer(false)}
      >
        <div className={classes.popupHeader}>
          <div className={classes.popupLabel}>{termsDrawerTitle}</div>
          <div className={classes.closeContainer} onClick={() => setTermsDrawer(false)}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        <div className={classes.bottomDrawer}>
          <Typography className={classes.terms}>
            {serviceType === PaymentServiceType.Rent ? (
              <>
                {commonTermsAndConditionText(
                  calculateCost(
                    rentService!.paymentFrequency as RentalFrequency,
                    rentService!.monthlyCost as number,
                  ),
                  rentService!.agency.tradingName,
                  rentService!.nextPaymentDate,
                )}
              </>
            ) : (
              <div />
            )}
          </Typography>

          {serviceType === PaymentServiceType.Wallet ||
          serviceType === PaymentServiceType.EditRent ||
          serviceType === PaymentServiceType.EditElectricity ||
          serviceType === PaymentServiceType.EditBroadband ||
          selected === 'Bank' ||
          selected === 'Credit' ? (
            <div>
              <Typography className={classes.terms}>{debitCardTermsAnadConditionsText}</Typography>
              <Typography
                className={classes.downloadButtonText}
                onClick={() =>
                  DownloadHelper.downloadLink(
                    AGREEMENT_URL,
                    'Direct-Debit-Request-Service-Agreement',
                  )
                }
              >
                {downloadText}
              </Typography>
            </div>
          ) : (
            <div />
          )}
          <div className={classes.checkContainer}>
            <Checkbox
              sx={{
                color: theme.colors.borderColor,
                '&.Mui-checked': {
                  color: theme.colors.buttonPrimary,
                },
              }}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <Typography className={classes.checkboxText} onClick={() => setChecked(!checked)}>
              I accept terms and conditions
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              disabled={!checked}
              onPress={() => {
                if (checked) {
                  navigate(nextPage as Route, {
                    propertyId,
                    serviceType,
                    paymentRefId,
                    serviceAccountId,
                  });
                }
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        open={paymentTypeDisabledDrawer}
        onClose={() => setPaymentTypeDisabledDrawer(false)}
      >
        <div className={classes.popupHeader}>
          <div className={classes.popupLabel}>{paymentDisabledModal.title}</div>
          <div
            className={classes.closeContainer}
            onClick={() => setPaymentTypeDisabledDrawer(false)}
          >
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        <div className={classes.bottomDrawer}>
          <Typography variant="body2" className={classes.disabledMessage}>
            {paymentDisabledModal.info(
              getAgencyName(
                { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName },
                rentService!,
              ),
              selected!,
              serviceType.toLowerCase(),
            )}
          </Typography>
        </div>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const mapStateToProps = (state: ApplicationState) => ({
  paymentTypes: state.payment.paymentTypes,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
