import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { SIZES } from '../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    title: {
      color: theme.colors.title,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      paddingBottom: theme.units.spacing * 4,
    },
    twoColumn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    twoColumnGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr',
        gridGap: 0,
      },
    },
    titleColumn: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gridGap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
  })();
};
