import React, { FC, useEffect, useState, useMemo, createRef, useRef } from 'react';
import { useStyles } from './CheckoutCompareStyles';
import { LABELS } from './CheckoutCompareConstants';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import closeIcon from '../../../../assets/navigation/close.png';
import backArrow from '../../../../assets/navigation/arrow-left.png';
import PlanInformation from '../../../../components/planInformation/PlanInformation';
import { useCart } from '../../../../helpers/CheckoutHelper';
import { ModifyCart, PageName } from '../../../../models/checkout/Checkout';
import { ApplicationState } from '../../../../store/RootReducer';
import SwipeableViews from 'react-swipeable-views';
import TileContainer from '../../../../components/tileContainer/TileContainer';

interface CheckoutCompareProps {
  closePopup: () => void;
  resetCompare: () => void;
}

const CheckoutCompare: FC<CheckoutCompareProps> = ({ closePopup, resetCompare }) => {
  const { compareItems, compareCount } = useCart();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState<number>(compareItems.length - 1);
  const [planRefs, setPlanRefs] = useState<any>(undefined);
  const [planIndex, setPlanIndex] = useState<number>(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (compareItems && compareItems.length > 0) {
      let updatedplanRefs = {};
      compareItems[activeTab].suppliers!.map((supplier) => {
        supplier.plans!.map((plan) => {
          plan.planInformation &&
            plan.planInformation.length > 0 &&
            plan.planInformation.map((planInfo) => {
              updatedplanRefs = {
                ...updatedplanRefs,
                [`${plan.title}_${planInfo.sectionTitle}`]: {
                  ref: createRef(),
                  height: 0,
                },
              };
            });
        });
      });
      setPlanRefs(updatedplanRefs);
    }
  }, [activeTab, compareItems]);

  useEffect(() => {
    document.addEventListener('backbutton', () => closePopup());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  const updateRefsHeight = (key: string) => {
    let updatedPlanRefs = { ...planRefs };
    compareItems[activeTab].suppliers!.map((supplier) => {
      supplier.plans!.map((plan) => {
        updatedPlanRefs = {
          ...updatedPlanRefs,
          [`${plan.title}_${key}`]: {
            ...updatedPlanRefs[`${plan.title}_${key}`],
            height: updatedPlanRefs[`${plan.title}_${key}`]
              ? updatedPlanRefs[`${plan.title}_${key}`].height === 0
                ? updatedPlanRefs[`${plan.title}_${key}`].ref
                  ? updatedPlanRefs[`${plan.title}_${key}`].ref.current!.scrollHeight
                  : 0
                : 0
              : 0,
          },
        };
      });
    });
    setPlanRefs(updatedPlanRefs);
  };

  useEffect(() => {
    if (compareItems.length) {
      if (
        compareItems[activeTab] === undefined ||
        compareItems[activeTab].suppliers === undefined
      ) {
        setActiveTab(compareItems.length - 1);
      }
    } else {
      closePopup();
    }
  }, [activeTab, compareItems]);

  return (
    <>
      <div className={classes.popupHeader}>
        <div className={classes.popupLabel}>{LABELS.COMPARE}</div>
        <div className={classes.closeContainer} onClick={() => closePopup()}>
          <img className={classes.closeIcon} src={closeIcon} alt="backIcon" />
        </div>
      </div>
      <div
        className={classes.informationContainer}
        key={`plan_information_${activeTab}`}
        ref={scrollRef}
      >
        {!!planRefs &&
          compareItems.length &&
          compareItems[activeTab] &&
          compareItems[activeTab].suppliers && (
            <SwipeableViews
              enableMouseEvents
              style={{
                paddingRight: compareCount >= 4 ? '60%' : compareCount === 3 ? '67%' : '51%',
                position: 'relative',
              }}
              index={planIndex}
              onChangeIndex={(index: number) => {
                setPlanIndex(index);
              }}
            >
              {compareItems[activeTab].suppliers!.map((supplier, idx) =>
                supplier.plans.map((plan, index) => (
                  <PlanInformation
                    plan={plan}
                    serviceType={compareItems[activeTab].type}
                    supplier={supplier}
                    logo={supplier.logo}
                    closePopup={() => closePopup()}
                    compare
                    planRefs={planRefs}
                    updateRefsHeight={updateRefsHeight}
                    compareMode
                  />
                )),
              )}
            </SwipeableViews>
          )}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetCompare: () => dispatch(CheckoutActions.resetCompare()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCompare);
