import { BundleResponse } from '../../models/bundles/Bundles';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { ReviewResponse } from '../../models/tenantRequest/Request';
import { RequestAction, RequestActionTypes } from '../actions/RequestActions';
import { RequestState } from '../state/RequestState';

export const initialState: RequestState = {
  requestLoader: false,
  requests: [],
  reviewRequests: [],
  completedRequests: [],
  scheduledRequests: [],
  bundleResponse: undefined,
  review: undefined,
  reviewLoader: false,
};

export const requestReducer: (state: RequestState, action: RequestAction) => RequestState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case RequestActionTypes.GET_REQUESTS:
      return {
        ...state,
        requestLoader: true,
      };
    case RequestActionTypes.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.data as MaintenanceResponse[],
        requestLoader: false,
      };
    case RequestActionTypes.GET_REVIEW_REQUESTS_SUCCESS:
      return {
        ...state,
        reviewRequests: action.data as MaintenanceResponse[],
        requestLoader: false,
      };
    case RequestActionTypes.GET_SCHEDULED_REQUESTS_SUCCESS:
      return {
        ...state,
        scheduledRequests: action.data as MaintenanceResponse[],
        requestLoader: false,
      };
    case RequestActionTypes.GET_COMPLETED_REQUESTS_SUCCESS:
      return {
        ...state,
        completedRequests: action.data as MaintenanceResponse[],
        requestLoader: false,
      };
    case RequestActionTypes.GET_REQUESTS_ERROR:
      return {
        ...state,
        requestLoader: false,
      };
    case RequestActionTypes.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        bundleResponse: action.data as BundleResponse,
      };
    case RequestActionTypes.GET_MR_REVIEW_REQUEST:
      return {
        ...state,
        reviewLoader: true,
        review: undefined,
      };
    case RequestActionTypes.GET_MR_REVIEW_SUCCESS:
      return {
        ...state,
        reviewLoader: false,
        review: action.data as ReviewResponse,
      };
    case RequestActionTypes.GET_MR_REVIEW_ERROR:
      return {
        ...state,
        bundleLoading: false,
      };
    default:
      return state;
  }
};
