import {
  GetRentOnboardingResponse,
  PostRentOnboardingResponse,
  Services,
} from '../../models/rentOnboarding/rentOnboarding';
import { RentOnboardingAction, RentOnboardingActionTypes } from '../actions/RentOnboarding';
import { RentOnboardingState } from '../state/RentOnboarding';

export const initialState: RentOnboardingState = {
  services: [],
  postRentOnboardingResponse: undefined,
  postServiceabilityResponse: undefined,
  propertyId: undefined,
};

export function rentOnboardingReducer(
  state = initialState,
  action: RentOnboardingAction,
): RentOnboardingState {
  switch (action.type) {
    case RentOnboardingActionTypes.GET_RENT_ONBOARDINNG_SUCCESS:
      return {
        ...state,
        services: action.data as GetRentOnboardingResponse[],
      };
    case RentOnboardingActionTypes.POST_RENT_ONBOARDINNG_SUCCESS:
      return {
        ...state,
        postRentOnboardingResponse: action.data as PostRentOnboardingResponse,
      };
    case RentOnboardingActionTypes.POST_SERVICEABILITY_SUCCESS:
      return {
        ...state,
        postServiceabilityResponse: action.data as Services[],
      };
    case RentOnboardingActionTypes.SAVE_PROPERTY_ID:
      return {
        ...state,
        propertyId: action.data as number,
      };
    default:
      return state;
  }
}
