import { AuthDetails } from '../../models/auth/auth';
import { InvoiceResponse } from '../../models/invoices/invoices';
import { JobDetailResponse, LocationData } from '../../models/maintenance/maintenance';
import {
  GenericServiceSummaryDictionary,
  GenericServiceSummaryResponse,
} from '../../models/services/genericServiceSummary';
import { RentService, ServiceDictionary } from '../../models/services/rentService';
import {
  ActiveServicesPaymentSummaryResponse,
  ServiceSummaryResponse,
} from '../../models/services/services';
import { ServiceAction, ServiceActionTypes } from '../actions/ServiceActions';
import { ServiceState } from '../state/ServiceState';

export const initialState: ServiceState = {
  rentService: undefined,
  otherService: undefined,
  error: null,
  fetchingInvoices: false,
  invoices: [],
  invoicesError: null,
  serviceSummary: null,
  genericServiceSummary: null,
  maintenanceSummary: null,
  locationData: null,
  checkbillsent: false,
  activeServicePaymentSummaries: undefined,
};

export const serviceReducer = (state = initialState, action: ServiceAction): ServiceState => {
  switch (action.type) {
    case ServiceActionTypes.SET_MAINTENANCE_SUMMARY_REQUEST:
      return {
        ...state,
        maintenanceSummary: undefined,
      };
    case ServiceActionTypes.GET_MAINTENANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        maintenanceSummary: action.data as JobDetailResponse,
      };

    case ServiceActionTypes.GET_GENERIC_SERVICE_SUMMARY_SUCCESS:
      return {
        ...state,
        genericServiceSummary: {
          ...state.genericServiceSummary,
          [(action.data as GenericServiceSummaryResponse).serviceAccountId]:
            action.data as GenericServiceSummaryResponse,
        } as GenericServiceSummaryDictionary,
      };
    case ServiceActionTypes.GET_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        locationData: action.data as LocationData,
      };
    case ServiceActionTypes.GET_RENT_SERVICE_SUCCESS:
      return {
        ...state,
        rentService: {
          ...state.rentService,
          [(action.data as RentService).serviceAccountId]: action.data as RentService,
        },
      };
    case ServiceActionTypes.GET_OTHER_SERVICE_SUCCESS:
      return {
        ...state,
        otherService: {
          ...state.otherService,
          [(action.data as RentService).serviceAccountId]: action.data as RentService,
        } as ServiceDictionary,
      };
    case ServiceActionTypes.FETCH_INVOICES_REQUEST:
      return {
        ...state,
        fetchingInvoices: true,
        invoices: [],
        invoicesError: null,
      };
    case ServiceActionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        fetchingInvoices: false,
        invoices: action.data as InvoiceResponse[],
        invoicesError: null,
      };
    case ServiceActionTypes.FETCH_INVOICES_ERROR:
      return {
        ...state,
        fetchingInvoices: false,
        invoices: [],
        invoicesError: action.data as Error,
      };
    case ServiceActionTypes.GET_SERVICE_SUMMARY_SUCCESS:
      return {
        ...state,
        serviceSummary: action.data as ServiceSummaryResponse,
      };
    case ServiceActionTypes.POST_CHECK_BILL_REQUEST:
      return {
        ...state,
        checkbillsent: false,
      };
    case ServiceActionTypes.POST_CHECK_BILL_SUCCESS:
      return {
        ...state,
        checkbillsent: true,
      };
    case ServiceActionTypes.POST_CHECK_BILL_ERROR:
      return {
        ...state,
        checkbillsent: false,
        error: 'Upload failed, please send to hello@sortedservices.com instead',
      };
    case ServiceActionTypes.RESET_CHECK_BILL:
      return {
        ...state,
        checkbillsent: false,
        error: null,
      };
    case ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_REQUEST:
      return {
        ...state,
        activeServicePaymentSummaries: undefined,
      };
    case ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        activeServicePaymentSummaries: action.data as ActiveServicesPaymentSummaryResponse[],
      };
    default:
      return state;
  }
};
