export const LABELS = {
  YOUR_APPLICATIONS: 'Your applications',
  SHOWING: 'Showing',
  DRAFTS: 'Drafts',
  ADD_DRAFT: '+ Add draft',
  SUBMITTED: 'Submitted',
  INVITES: 'Invites',
  UPDATE: 'Update',
  EDIT: 'Edit',
  DELETE: 'Delete',
  WITHDRAW: 'Withdraw',
  HOT: 'This property is hot',
  CONTACT_INFO: 'Contact info',
  DECLINE: 'Decline invitation',
  ACCEPT: 'Accept',
  ACCEPT_ALL: 'Accept all',
  INFO_REQUESTED: 'Info requested',
  SYNC_PROFILE: 'Sync profile',
  SYNC_ALL: 'Sync all',
  DID_YOU_INSPECT: 'Have you inspected this property?',
  INSPECTION_DATE: 'When did you inspect this property?',
  PREFERRED_MOVE_IN_DATE: 'Preferred move in date',
  PREFERRED_LEASE_DURATION: 'Preferred lease duration',
  YOUR_APPLICATION_PROFILES: 'My Apply application profiles',
};

export const failedStatuses = ['Unsuccessful', 'Withdrawn', 'Cancelled', 'Declined'];
export const notInFlightStatus = ['Unsuccessful', 'Withdrawn', 'Accepted', 'Cancelled', 'Declined'];
export enum ResponseType {
  INVITE,
  DRAFT,
  SUBMITTED,
}

export enum ApplicationFilter {
  ALL = 'All',
  INVITED = 'Invited',
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
}

export enum PopupType {
  APPLICATIONS,
  GROUP,
}

export const CONTACT_INFO = 'Contact info';
