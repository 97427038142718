import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PropertyRentStyles';
import { AGREEMENTS, Agreement, DATE_FORMAT, LABELS, MappedData } from './PropertyRentConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { extractParams, navigate, navigateBack } from '../../navigation/NavigationUtils';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AddTagStatus, FastPayPayment } from '../../models/payment/Payment';
import { PaymentActions } from '../../store/actions/PaymentActions';
import { ServiceActionTypes, ServiceActions } from '../../store/actions/ServiceActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { DashboardState } from '../../store/state/DashboardState';
import { ServiceState } from '../../store/state/ServiceState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PropertyRentParams } from '../../navigation/NavigationConstants';
import { LeaseResponse } from '../../models/dashboard/dashboard';
import { Property } from '../../models/property/property';
import { AuthDetails } from '../../models/auth/auth';
import ActivityIndicator from '../../components/activityIndicator/ActivityIndicator';
import {
  Attachment,
  Documents,
  PaymentMethod,
  PaymentProvider,
  RentService,
  ServiceDetails,
} from '../../models/services/rentService';
import DEFT_LOGO from '../../assets/deft-logo.png';
import SORTED_IMAGE from '../../assets/rentServiceHeader.png';
import NAX_IMAGE from '../../assets/nax-background.png';

import { calculateCost } from '../../helpers/CostFrequencyHelper';
import houseIcon from '../../assets/navigation/home.png';
import { useDashboardServices } from '../../helpers/ServiceInfoHelper';
import homePlaceholder from '../../assets/homePlaceholder.png';
import { filterPaymentMethod, filterPaymentAccount } from './PropertyRentUtils';
import { DateFormatter } from '../../helpers/DateFormatter';
import { DownloadHelper } from '../../helpers/DownloadHelper';
import downloadPdfIcon from '../../assets/landlord/download.png';
import { ThemeKey, getCurrentThemeKey, theme } from '../../theme/Theme';

interface PropertyRentProps {
  dashboardState: DashboardState;
  serviceState: ServiceState;
  isInactive?: boolean;
  paymentDetail: FastPayPayment[];
  payIdSorted: string;
  authDetails: AuthDetails | Error | undefined | null;
  loading: boolean;
  getRentService: (summaryAccountId: string) => void;
  fetchPaymentDetails: (body: AddTagStatus) => void;
  downloadDocument: (documentId: string) => void;
}

const PropertyRent: FC<PropertyRentProps> = ({
  dashboardState: { onboardedProperties, selectedProperty },
  serviceState,
  isInactive,
  paymentDetail,
  payIdSorted,
  authDetails,
  loading,
  getRentService,
  fetchPaymentDetails,
  downloadDocument,
}) => {
  const [filterPayId, setFilterPayId] = useState<FastPayPayment[]>([]);
  const { serviceAccountId } = extractParams<PropertyRentParams>();
  const propertyData = (onboardedProperties as LeaseResponse[])[selectedProperty];
  const { property } = useDashboardServices();
  const themeKey = getCurrentThemeKey();

  // TODO make ENV
  const agencyLogo = `https://s3-ap-southeast-2.amazonaws.com/appdev.sortedservices.com/V2-App-${propertyData.agency.code}.png`;

  const classes = useStyles();

  const selectedRentService = useMemo<RentService | undefined>(() => {
    if (!!serviceState.rentService && !!serviceState.rentService[serviceAccountId!])
      return serviceState.rentService[serviceAccountId!];
    return undefined;
  }, [serviceState, serviceAccountId]);

  const rentData = useMemo<MappedData | undefined>(() => {
    if (selectedRentService) {
      const { agency, property } = propertyData;
      const { cost, paymentFrequency, nextPaymentDate, paymentMethodResponse, serviceDetails } =
        selectedRentService;
      const data: MappedData = {
        agencyImage: agency.imageUrl,
        thumbnail:
          property!.photos && !!property!.photos.length
            ? property!.photos[0].link
            : homePlaceholder,
        serviceProvider: agency.tradingName,
        rentAmount: cost,
        rentFrequency: paymentFrequency,
        nextPayment: nextPaymentDate,
        paymentMethod: filterPaymentMethod(paymentMethodResponse),
        paymentAccount: filterPaymentAccount(paymentMethodResponse),
        tenancyData:
          {
            tenancyTerm: (serviceDetails as ServiceDetails).tenancyTerm || null,
            bondAmount: (serviceDetails as ServiceDetails).bondAmount || null,
            fromDate: (serviceDetails as ServiceDetails).fromDate || null,
            toDate: (serviceDetails as ServiceDetails).toDate || null,
            documentData: (serviceDetails as ServiceDetails).documents,
            attachments: (serviceDetails as ServiceDetails).attachments,
          } || null,
        paymentDetails: (selectedRentService.serviceDetails as ServiceDetails).paymentDetails!,
      };
      return data;
    } else {
      return undefined;
    }
  }, [propertyData, selectedRentService]);

  useEffect(() => {
    const filterPayments = paymentDetail.filter((item) => {
      return item.serviceType === 'Rent' && item.platformPropertyId === property!.id;
    });
    const filterNotViewedPayments: number[] = [];
    paymentDetail.map((item) => {
      if (
        item.serviceType === 'Rent' &&
        item.paymentTags !== 'viewed' &&
        item.platformPropertyId === property!.id
      ) {
        filterNotViewedPayments.push(item.transactionRunScheduleId);
      }
    });
    setFilterPayId(filterPayments);
    fetchPaymentDetails({
      userId: (authDetails as AuthDetails).userId,
      platformPropertyId: property!.id,
      tag: 'viewed',
      unTag: '',
      transactionRunScheduleIds: filterNotViewedPayments,
    });
  }, []);

  useEffect(() => {
    getRentService(serviceAccountId!.toString());
  }, [serviceAccountId]);

  const handleRentPaymentEdit = () => {
    const availableServices = (onboardedProperties as LeaseResponse[])[selectedProperty];
    const paymentRefId =
      (selectedRentService!.paymentMethodResponse as PaymentMethod).refId || LABELS.RANDOMVALUE;
    const agencyName = availableServices.agency.tradingName;
    const id = selectedRentService!.id;
    const propertyId = (availableServices.property as Property).id;
    navigate('selectPayment', {
      propertyId: propertyId,
      serviceType: 'editrent',
      paymentRefId: paymentRefId,
      serviceAccountId,
      agencyName: 'Sorted_Services',
    });
  };

  const renderOtherPaymentCardItems = (
    paymentProvider?: PaymentProvider | null,
    billInfo?: string | null,
    paymentReferenceId?: string | null,
  ) => {
    return (
      <div className={classes.otherPaymentCardItem}>
        {paymentProvider && (
          <div className={classes.paymentSection}>
            <div className={classes.paymentBox}>
              <div className={classes.itemLabel}>{LABELS.PAYMENT_TYPE}</div>
              <div className={classes.itemValue}>{paymentProvider}</div>
            </div>
            <div className={classes.logoBox}>
              {paymentProvider && paymentProvider === PaymentProvider.DEFT && (
                <img src={DEFT_LOGO} className={classes.paymentLogoStyle} />
              )}
            </div>
          </div>
        )}
        {billInfo && (
          <div className={classes.flexBox}>
            <div className={classes.otherPaymentItemLabel}>{LABELS.BILLER_CODE}</div>
            <div className={classes.itemValue}>{billInfo}</div>
          </div>
        )}
        {paymentReferenceId && (
          <div className={classes.flexBox}>
            <div className={classes.otherPaymentItemLabel}>{LABELS.REFERENCE_NUMBER}</div>
            <div className={classes.itemValue}>{paymentReferenceId}</div>
          </div>
        )}
      </div>
    );
  };

  const renderPaymentCardItems = (
    label: string,
    value: string,
    buttonText?: string,
    buttonRedirect?: () => void,
  ) => {
    return (
      <div className={classes.cardItem}>
        <div className={classes.flexBox}>
          <div className={classes.itemLabel}>{label}</div>
          <div className={classes.itemValue}>{value}</div>
        </div>
        {(!isInactive || themeKey !== ThemeKey.NAX) && (
          <div className={classes.itemButtonBox}>
            <div className={classes.itemButton} onClick={buttonRedirect}>
              {buttonText}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            {!!rentData ? (
              <>
                <div className={classes.headerContainer}>
                  {themeKey === ThemeKey.SORTED && (
                    <img className={classes.agencyLogo} src={agencyLogo} />
                  )}
                  <img
                    className={classes.header}
                    src={themeKey === ThemeKey.NAX ? NAX_IMAGE : SORTED_IMAGE}
                  />
                </div>
                <div className={classes.body}>
                  <div className={classes.rentCard}>
                    <img className={classes.serviceIcon} src={houseIcon} />
                    <div className={classes.rentCardVerticalView}>
                      <div className={classes.itemLabel}>{LABELS.RENT_CARD_TITLE}</div>
                      <div className={classes.itemValue}>{rentData.serviceProvider}</div>
                    </div>
                  </div>
                  {filterPayId.length > 0 && (
                    <>
                      <div className={classes.duePaymentroot}>
                        <div className={classes.duePaymentHeading}>Payments currently due</div>
                        <div className={classes.duepaymentlengthcircle}>
                          <div className={classes.duePaymentsLength}>{filterPayId.length}</div>
                        </div>
                      </div>
                      <div className={classes.duePaymentLists}>
                        {filterPayId.map((item: FastPayPayment) => {
                          return (
                            <div className={classes.duePaymentList}>
                              <div className={classes.alignRow}>
                                <div>
                                  <div className={classes.duePaymentSubheading}>Amount</div>
                                  <div className={classes.duePaymentHeading}>{item.amount}</div>
                                </div>
                                <div className={classes.margin22}>
                                  <div className={classes.duePaymentSubheading}>Due date</div>
                                  <div className={classes.duePaymentHeading}>
                                    {item.paymentDate}
                                  </div>
                                </div>
                              </div>
                              <div className={classes.payIdstatusRow}>
                                <div>
                                  <div className={classes.duePaymentSubheading}>Payment method</div>
                                  <div className={classes.duePaymentHeading}>PayID</div>
                                </div>
                                <div className={classes.margin22}>
                                  <div className={classes.duePaymentSubheading}>Status</div>
                                  <div
                                    className={classes.duePaymentStatus}
                                    onClick={() => {
                                      navigate('payId', {
                                        referenceNumber: item.fastpayReferenceNumber,
                                        payIdNumber: payIdSorted,
                                      });
                                    }}
                                  >
                                    Pay Now
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                  <div className={classes.sectionTitle}>{LABELS.PAYMENT_SECTION}</div>
                  <div className={classes.card}>
                    {renderPaymentCardItems(
                      LABELS.PAYMENTS,
                      calculateCost(rentData.rentFrequency, rentData.rentAmount),
                    )}
                    {!!rentData.nextPayment &&
                      rentData.paymentDetails.paymentProvider === PaymentProvider.SORTED &&
                      renderPaymentCardItems(
                        LABELS.NEXT_PAYMEMT_DATE,
                        isInactive
                          ? LABELS.INACTIVE
                          : DateFormatter.format(rentData.nextPayment, DATE_FORMAT),
                      )}
                    {!!rentData.paymentMethod &&
                      !!rentData.paymentAccount &&
                      renderPaymentCardItems(
                        rentData.paymentMethod,
                        isInactive ? LABELS.INACTIVE : rentData.paymentAccount.replace(/\./g, '*'),
                        rentData.paymentMethod === LABELS.NOTAVAILABLE ? LABELS.ADD : LABELS.EDIT,
                        handleRentPaymentEdit,
                      )}
                    {rentData.paymentDetails.paymentProvider &&
                      rentData.paymentDetails.paymentProvider !== PaymentProvider.SORTED &&
                      renderOtherPaymentCardItems(
                        rentData.paymentDetails.paymentProvider,
                        rentData.paymentDetails.billInfo,
                        rentData.paymentDetails.paymentReferenceId,
                      )}
                  </div>
                  {rentData.tenancyData &&
                    rentData.tenancyData.bondAmount &&
                    rentData.tenancyData.tenancyTerm &&
                    rentData.tenancyData.fromDate &&
                    rentData.tenancyData.toDate && (
                      <div>
                        <div className={classes.sectionTitle}>
                          {isInactive ? LABELS.TENANCY_SECTION_INACTIVE : LABELS.TENANCY_SECTION}
                        </div>
                        <div className={classes.card}>
                          <div className={classes.grid}>
                            {rentData.tenancyData.tenancyTerm && (
                              <div className={classes.gridItem}>
                                <div className={classes.itemLabel}>{LABELS.TENANCY_TERM}</div>
                                <div className={classes.itemValue}>
                                  {rentData.tenancyData.tenancyTerm.replace('(s)', 's').trim()}
                                </div>
                              </div>
                            )}
                            {rentData.tenancyData.fromDate && (
                              <div className={classes.gridItem}>
                                <div className={classes.itemLabel}>{LABELS.TENANCY_FROM_DATE}</div>
                                <div className={classes.itemValue}>
                                  {DateFormatter.format(rentData.tenancyData.fromDate, DATE_FORMAT)}
                                </div>
                              </div>
                            )}
                            {rentData.tenancyData.bondAmount && (
                              <div className={classes.gridItem}>
                                <div className={classes.itemLabel}>{LABELS.TENANCY_BOND}</div>
                                <div
                                  className={classes.itemValue}
                                >{`$${rentData.tenancyData.bondAmount}`}</div>
                              </div>
                            )}
                            {rentData.tenancyData.toDate && (
                              <div className={classes.gridItem}>
                                <div className={classes.itemLabel}>{LABELS.TENANCY_TO_DATE}</div>
                                <div className={classes.itemValue}>
                                  {DateFormatter.format(rentData.tenancyData.toDate, DATE_FORMAT)}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {!isInactive && (
                    <div>
                      <div className={classes.sectionTitle}>{LABELS.DOCUMENTS_SECTION}</div>
                      <div className={classes.card}>
                        <div className={classes.flexBox}>
                          {rentData.paymentDetails.paymentProvider &&
                          rentData.paymentDetails.paymentProvider === PaymentProvider.SORTED
                            ? AGREEMENTS.map((agreement: Agreement, index: number) => (
                                <div
                                  key={index}
                                  className={classes.downloadButton}
                                  onClick={() =>
                                    DownloadHelper.downloadLink(agreement.url, agreement.title)
                                  }
                                >
                                  <img className={classes.downloadImage} src={downloadPdfIcon} />
                                  <div className={classes.documentListItemValue}>
                                    {agreement.title}
                                  </div>
                                </div>
                              ))
                            : null}
                          {(rentData.tenancyData ? rentData.tenancyData.documentData : []).map(
                            (data: Documents, index: number) => (
                              <div
                                key={index}
                                className={classes.downloadButton}
                                onClick={() =>
                                  DownloadHelper.downloadLink(data.documentLink, data.documentTitle)
                                }
                              >
                                <img className={classes.downloadImage} src={downloadPdfIcon} />
                                <div className={classes.documentListItemValue}>
                                  {data.documentTitle}
                                </div>
                              </div>
                            ),
                          )}
                          {(rentData.tenancyData && rentData.tenancyData.attachments
                            ? rentData.tenancyData.attachments
                            : []
                          ).map((data: Attachment, index: number) => (
                            <div
                              key={index}
                              className={classes.downloadButton}
                              onClick={() => DownloadHelper.downloadLink(data.link, data.title)}
                            >
                              <img className={classes.downloadImage} src={downloadPdfIcon} />
                              <div className={classes.documentListItemValue}>{data.title}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <ActivityIndicator color={theme.colors.buttonPrimary} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([ServiceActionTypes.GET_RENT_SERVICE]);

const mapStateToProps = (state: any, props: any) => {
  return {
    loading: loading(state),
    dashboardState: state.dashboardState,
    serviceState: state.services,
    paymentDetail:
      state.payment !== undefined
        ? state.payment.paymentDetails !== undefined &&
          state.payment.paymentDetails.response !== undefined
          ? state.payment.paymentDetails.response.payments
          : []
        : [],
    payIdSorted:
      state.payment !== undefined
        ? state.payment.paymentDetails !== undefined &&
          state.payment.paymentDetails.response !== undefined
          ? state.payment.paymentDetails.response.sortedPayId
          : ''
        : '',
    authDetails: state.authState.authDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRentService: (summaryAccountId: string) =>
    dispatch(ServiceActions.getRentServiceRequest(summaryAccountId)),
  fetchPaymentDetails: (body: AddTagStatus) => dispatch(PaymentActions.postTagRequest(body)),
  downloadDocument: (documentId: string) =>
    dispatch(ServiceActions.downloadDocumentRequest(documentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyRent);
