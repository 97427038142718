import React, { FC } from 'react';
import { useStyles } from './InputMultiSelectStyles';
import { SelectValues } from '../inputSelect/InputSelectConstants';
import { isEmpty } from '../../helpers/Validation';

interface InputMultiSelectProps {
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  selected: number | null;
  values: SelectValues[];
  showRequiredError?: boolean;
  setValue: (value: string | number) => void;
}

const InputMultiSelect: FC<InputMultiSelectProps> = ({
  title,
  mandatory,
  touched,
  error,
  selected,
  values,
  showRequiredError,
  setValue,
}) => {
  const classes = useStyles();

  return (
    <>
      {(touched && error) || (showRequiredError && isEmpty(selected)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <div className={classes.grid}>
        {values.map((v, i) => (
          <div
            className={selected === i ? classes.selected : classes.unselected}
            key={i}
            onClick={() => setValue(v.value)}
          >
            {v.display}
          </div>
        ))}
      </div>
    </>
  );
};
export default InputMultiSelect;
