import helloFreshTitle from '../../assets/offers/helloFreshTitle.png';
import helloFresh from '../../assets/offers/helloFresh.png';
import blysTitle from '../../assets/offers/blysTitle.png';
import blys from '../../assets/offers/blys.png';
import { theme } from '../../theme/Theme';
import security from '../../assets/category/security.png';
import optimise from '../../assets/category/optimise.png';
import trades from '../../assets/category/trades.png';
import local from '../../assets/category/local.png';
import maintain from '../../assets/category/maintain.png';
import essentials from '../../assets/category/essentials.png';
import switchGym from '../../assets/offers/switchGym.png';
import switchLocks from '../../assets/offers/switchLocks.png';
import sondar from '../../assets/offers/sondar.png';
import electricity from '../../assets/marketplace/dark/electricity.png';
import gas from '../../assets/marketplace/dark/gas.png';
import broadband from '../../assets/marketplace/dark/broadband.png';
import cleaning from '../../assets/marketplace/dark/cleaning.png';
import gardening from '../../assets/marketplace/dark/gardening.png';
import insurance from '../../assets/marketplace/dark/insurance.png';
import removals from '../../assets/marketplace/dark/removals.png';
import {
  AvailableServicesResponse,
  SERVICE_TYPE_TITLE,
  ServiceTypes,
  Services,
} from '../../models/services/services';
import ThankyouImage1 from '../../assets/articles/thankyou-image.jpg';
import ThankyouImage2 from '../../assets/articles/thankyou-image2.jpg';
import ThankyouImage3 from '../../assets/articles/thankyou-image3.jpg';
import ThankyouImage4 from '../../assets/articles/thankyou-image4.png';
import ThankyouImage5 from '../../assets/articles/thankyou-image5.png';
import ThankyouImage6 from '../../assets/articles/thankyou-image6.png';
import ThankyouImage7 from '../../assets/articles/thankyou-image7.png';

export const LABELS = {
  MARKETPLACE_TITLE: "Let's make your \nhouse a home!",
  MARKETPLACE_SUBTITLE:
    "All your home services connected in minutes. \nSearch to see what's available at your place or \nbrowse below to see what offers are on offer",
  NO_SERVICES: 'No services are available at your address right now. Check back soon!',
};

export const getOfferTitleImage = (offer: string) => {
  switch (offer) {
    case 'HelloFresh':
      return {
        title: helloFreshTitle,
        image: helloFresh,
        subtitle: 'Just for visiting Sorted',
        backgroundColor: theme.colors.lightBlue,
      };
    case 'Blys':
      return {
        title: blysTitle,
        image: blys,
        subtitle: 'Save $10 off any Blys treatment',
        backgroundColor: theme.colors.lightRed,
      };
    default:
      return null;
  }
};

export const browseCategories = [
  {
    name: 'Essentials',
    link: 'essentialsScreen',
    image: essentials,
  },
  {
    name: 'Security',
    link: 'protectScreen',
    image: security,
  },
  {
    name: 'Maintain',
    link: 'maintainScreen',
    image: maintain,
  },
  {
    name: 'Optimise',
    link: 'optimiseScreen',
    image: optimise,
  },
  {
    name: 'Trades',
    link: 'onboarding',
    image: trades,
  },
  {
    name: 'Local',
    link: 'onboarding',
    image: local,
  },
];

export const switchOffers = [
  {
    image: switchLocks,
    title: 'Phone Activated Door Lock',
    subtitle:
      "Don't need to worry about your door key as our doors can be activated via a smart phone app.",
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1403770732');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.assaabloy.hospitality.mobileaccess.studentliving&hl=en_AU&gl=US&pli=1',
      //   );
      // }
    },
  },
  {
    image: switchGym,
    title: 'Society Fitness at The Switch',
    subtitle:
      'Get full access to your workout, gym classes and challenges and integrate with other health apps',
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1590364463');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.technogym.mywellness.societyfitnessattheswitch',
      //   );
      // }
    },
  },
  {
    image: sondar,
    title: 'Sonder Safety & Wellbeing App',
    subtitle:
      'We’ve partnered with a market leading health, safety, and wellbeing service Sonder to keep you safe, 24/7. Sonder’s service is 100% free for our long-stay tenants and 100% confidential.',
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1178892371');
      // } else {
      //   Linking.openURL('https://play.google.com/store/apps/details?id=com.sonder.member.android');
      // }
    },
  },
];

export interface Pack {
  background: string;
  title: string;
  description: string;
  icons: string[];
  serviceTypes: ServiceTypes[];
  optionalServiceTypes: string[];
}

export const renterStarterPack = {
  background: ThankyouImage5,
  title: 'Renter starter pack',
  description:
    'Everything you need to move in! Electricity, Gas + Broadband + FREE quotes for removalists and vacate cleaners at your old place.',
  icons: [],
  serviceTypes: [
    ServiceTypes.Electricity,
    ServiceTypes.Gas,
    ServiceTypes.Broadband,
    ServiceTypes.Removals,
    ServiceTypes.Cleaning,
  ],
  optionalServiceTypes: [],
};

export const growingFamilyPack = {
  background: ThankyouImage4,
  title: 'Growing family',
  description:
    'Get moved in with great suggested plans for Electricity, Gas + Broadband + FREE quotes for gardening and content insurance',
  icons: [],
  serviceTypes: [
    ServiceTypes.Electricity,
    ServiceTypes.Gas,
    ServiceTypes.Broadband,
    ServiceTypes.Gardening,
    ServiceTypes.Insurance,
  ],
  optionalServiceTypes: [],
};

export const moveInPowerOnPack = {
  background: ThankyouImage6,
  title: 'Move in, power on',
  description:
    "Just the lights please! Let's just get in there with great suggested plans for Electricity + FREE quotes for removalists",
  icons: [],
  serviceTypes: [ServiceTypes.Electricity, ServiceTypes.Removals],
  optionalServiceTypes: [],
};

export const simpleRentalPack = {
  background: ThankyouImage1,
  title: 'Simple rental',
  description:
    'Includes all the basic services onn our cheapest plans, Electricity, Gas + Broadband. No bells or whistles',
  icons: [],
  serviceTypes: [ServiceTypes.Electricity, ServiceTypes.Gas, ServiceTypes.Broadband],
  optionalServiceTypes: [],
};

export const cleanupPack = {
  background: ThankyouImage7,
  title: 'Start fresh',
  description:
    'FREE quotes for removalists and cleaners. Everything you need to feel balanced, refreshed and ready to go.',
  icons: [],
  serviceTypes: [ServiceTypes.Removals, ServiceTypes.Cleaning],
  optionalServiceTypes: [],
};

export const homeNetworkPack = {
  background: ThankyouImage3,
  title: 'Home networking',
  description: 'Get connected with FREE quotes for electricians and tech support',
  icons: [],
  serviceTypes: [ServiceTypes.Electrical, ServiceTypes.TechSupport],
  optionalServiceTypes: [ServiceTypes.Broadband],
};

export const homeSecurityPack = {
  background: ThankyouImage2,
  title: 'Home security',
  description:
    'Feel secure with everything you need. Get connected with FREE quotes for home security, locksmiths and tech support',
  icons: [],
  serviceTypes: [ServiceTypes.HomeSecurity, ServiceTypes.TechSupport, ServiceTypes.Locksmith],
  optionalServiceTypes: [],
};

export const getPacks = (
  services: Services[],
  availableServices: AvailableServicesResponse[],
): Pack[] => {
  const check = services.map((service) => service.serviceType.replace(' ', ''));
  const filteredAvailableServices = availableServices.filter((a) =>
    check.includes(a.ServiceType.replace(' ', '')),
  );

  const packs = [
    renterStarterPack,
    growingFamilyPack,
    moveInPowerOnPack,
    simpleRentalPack,
    cleanupPack,
    homeNetworkPack,
    homeSecurityPack,
  ];

  const filteredPacks = packs.filter((pack) =>
    pack.serviceTypes.every((serviceType) => check.includes(serviceType.replace(' ', ''))),
  );
  const updatedPacks = filteredPacks.map((pack) => {
    return {
      ...pack,
      icons: [...pack.optionalServiceTypes, ...pack.serviceTypes]
        .map((serviceType) => {
          const tryToFind = filteredAvailableServices.find(
            (a) => a.ServiceType.replace(' ', '') === serviceType.replace(' ', ''),
          );
          if (tryToFind) {
            return tryToFind.Logo.Dark;
          } else {
            return undefined;
          }
        })
        .filter(Boolean) as string[],
    };
  });
  return updatedPacks;
};
