/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ThemeKey, getCurrentTheme, getCurrentThemeKey } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();
  const themeKey = getCurrentThemeKey();

  return makeStyles({
    nonScrollContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: theme.colors.light,
      padding: 12,
      paddingRight: 0,
      flexWrap: 'wrap',
    },
    tagBarContainer: {
      backgroundColor: theme.colors.light,
      padding: 12,
      display: 'flex',
    },
    tagContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: 300,
      backgroundColor: theme.colors.lightGrey,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      position: 'relative',
      marginRight: 16,
      minWidth: 75,
      cursor: 'pointer',
    },
    activeTag: {
      backgroundColor:
        themeKey === ThemeKey.SWITCH
          ? theme.colors.actionButtonSecondary
          : theme.colors.buttonPrimary,
    },
    tagText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    activeTagText: {
      color: theme.colors.light,
    },
    tagNotificationContainer: {
      position: 'absolute',
      top: -3,
      right: 2,
      borderRadius: 500,
      width: 12,
      height: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      borderWidth: 1,
      borderColor: theme.colors.light,
    },
    tagNotificationText: {
      color: theme.colors.light,
      fontSize: 9,
      fontWeight: getFonts('Medium'),
      marginLeft: 1,
    },
  })();
};
