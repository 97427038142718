import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CheckoutConfigureStyles';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useCart, createRequiredServiceTypes } from '../../helpers/CheckoutHelper';
import { getQueryParams } from '../../helpers/QueryHelper';
import { ServiceTypes } from '../../models/services/services';
import { ApplicationState } from '../../store/RootReducer';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  PersonalDetails,
} from '../../models/checkout/Checkout';
import { Property } from '../../models/property/property';
import SelectedPlan from './components/selectedPlan/SelectedPlan';

interface CheckoutConfigureProps extends RouteComponentProps {
  isCartOpen: boolean;
  requiredServiceTypes: ServiceTypes[];
  availableServices: AvailableServicesResponse[];
  personalDetails: PersonalDetails;
  property: Property;
  isExternal: boolean;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  toggleCart: () => void;
}

const CheckoutConfigure: FC<CheckoutConfigureProps> = ({
  location,
  isCartOpen,
  requiredServiceTypes,
  availableServices,
  personalDetails,
  property,
  isExternal,
  toggleCart,
  updateCart,
}) => {
  const classes = useStyles();
  const last = getQueryParams(location, 'last');
  const { cartCount, cartItems, cartServices } = useCart();
  const [activeTab, setActiveTab] = useState<number>(last ? cartCount - 1 : 0);
  const selectedPlan = useMemo<AvailableServicesResponse>(() => {
    return cartItems[activeTab];
  }, [cartItems, activeTab]);

  useEffect(() => {
    if (cartCount === 0) {
      navigateBack();
      if (isCartOpen) {
        toggleCart();
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [cartCount]);

  const onNext = () => {
    if (activeTab + 1 === cartCount) {
      if (!!cartServices.length) {
        navigate('checkoutPersonalDetails');
      } else {
        navigate('checkoutSummary');
      }
    } else {
      setActiveTab(activeTab + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div
          className={classes.backRow}
          onClick={() =>
            resetNavigation('checkoutSelect', {
              requiredServiceTypes: createRequiredServiceTypes(requiredServiceTypes),
            })
          }
        >
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link" size="l">
            Service selection
          </Text>
        </div>
        <div key={`selected_plan_${activeTab}`}>
          <div className={classes.pageContent}>
            <SelectedPlan
              availableServices={availableServices}
              selectedPlan={selectedPlan}
              activeTab={activeTab}
              onNext={onNext}
              setActiveTab={setActiveTab}
              cartItems={cartItems}
              personalDetails={personalDetails}
              property={property}
              isExternal={isExternal}
              updateCart={updateCart}
              cartServices={cartServices}
              cartCount={cartCount}
              key={`${cartItems[activeTab].type}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isCartOpen: state.checkout.isCartOpen,
  requiredServiceTypes: state.checkout.requiredServiceTypes,
  availableServices: state.checkout.availableServices,
  personalDetails: state.checkout.personalDetails,
  property: state.checkout.property,
  isExternal: state.token.isExternal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutConfigure));
