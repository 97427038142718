import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    dialog: {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      overflow: 'hidden',
    },
    popupContainer: {
      backgroundColor: theme.colors.light,
      height: '100%',
    },
    popupHeader: {
      margin: theme.units.spacing * 6,
      paddingBottom: theme.units.spacing * 6,
      backgroundColor: theme.colors.light,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    popupLabel: {
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 24,
    },
    closeContainer: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      cursor: 'pointer',
    },
    closeIcon: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
    cartScroll: {
      overflowY: 'scroll',
      'scrollbarWidth': 'none',
      'msOverflowStyle': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    cartContainer: {
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
    },
    break: {
      margin: '20px 15px',
      height: 2,
      backgroundColor: theme.colors.borderColorLight,
    },
    promoCodeContainer: {
      backgroundColor: theme.colors.fadedBlue,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 15px',
    },
    promoTextContainer: {
      marginRight: 'auto',
    },
    promoCode: {
      fontWeight: getFonts('Medium'),
      fontSize: '15px',
      color: theme.colors.buttonPrimary,
      textTransform: 'uppercase',
      marginBottom: 4,
    },
    promoSubtext: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.buttonPrimary,
    },
    promoIconContainer: {
      width: 24,
      height: 24,
    },
    promoIcon: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
    summaryContainer: {
      backgroundColor: theme.colors.lighterGrey,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
    summaryRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    summaryGreyKey: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black38,
    },
    summaryBlackValue: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black87,
    },
    summaryBlackKey: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      color: theme.colors.black87,
    },
    summaryBlueValue: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.buttonPrimary,
    },
    buttonContainer: {
      marginTop: 5,
      padding: 16,
      paddingLeft: 24,
      paddingRight: 24,
    },
    primaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 40,
      color: theme.colors.light,
      textAlign: 'center',
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
    },
    needSupport: {
      margin: 15,
      paddingBottom: 10,
      borderBottom: `${theme.colors.borderColor} 2px solid`,
      fontWeight: getFonts('Medium'),
      fontSize: '20px',
      color: theme.colors.black87,
    },
    supportRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 20,
    },
    supportIcon: {
      width: 22,
      height: 22,
      marginRight: 10,
    },
    supportText: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.black87,
    },
    emptyCartContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    emptyCartIconContainer: {
      width: 60,
      height: 60,
      marginBottom: 15,
      marginTop: 30,
      flexShrink: 0,
    },
    emptyCartIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    emptyCartTitle: {
      marginBottom: 15,
      fontWeight: getFonts('Medium'),
      fontSize: '16px',
      color: theme.colors.black87,
    },
    emptyCartText: {
      fontFamily: 'GT Walsheim Light',
      fontSize: '15px',
      color: theme.colors.black87,
      marginBottom: 15,
    },
    selectFrequency: {
      height: 30,
      width: 130,
    },
    showPricingContainer: {
      display: 'flex',
      paddingLeft: 24,
      paddingRight: 24,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    estimateLabel: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      color: theme.colors.black87,
    },
    estimateStyles: {
      marginBottom: '0px !important',
    },
    content: {
      overflow: 'hidden',
      transition: 'all 0.3s ease-out',
      height: '100%',
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.lighterGrey,
      padding: 16,
      paddingLeft: 24,
      paddingRight: 24,
      cursor: 'pointer',
    },
    actionIconContainer: {
      marginLeft: 'auto',
      width: 14,
      height: 14,
      transition: 'all 0.3s ease-out',
    },
    actionIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      opacity: 0.8,
    },
    actionTitle: {
      marginRight: 'auto',
      fontWeight: getFonts('Medium'),
      fontSize: '15px',
      color: theme.colors.black38,
    },
    rotate: {
      transform: 'rotate(180deg)',
    },
    disclamerText: {
      marginLeft: 15,
      marginTop: 15,
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black38,
    },
    cartSection: {
      marginBottom: theme.units.spacing * 8,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: theme.units.spacing * 4,
    },
    cartItemsHeadingRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: theme.units.spacing * 2,
      marginRight: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 4,
    },
    cartItemsHeading: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
    },
    clearAll: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      color: theme.colors.black38,
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.buttonPrimary,
      },
    },
    cartSummary: {
      marginTop: 'auto',
    },
  })();
};
