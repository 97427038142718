import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    serviceContainer: {
      marginRight: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 80,
      position: 'relative',
      cursor: 'pointer',
    },
    serviceIconContainer: {
      borderRadius: 5000,
      backgroundColor: theme.colors.lightGrey,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
      height: 60,
      marginBottom: 8,
      flexShrink: 0,
    },
    serviceIcon: {
      position: 'absolute',
      width: 24,
      height: 24,
      objectFit: 'contain',
      objectPosition: 'center',
      flexShrink: 0,
      top: 18,
    },
    serviceText: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
  })();
};
