import { MaintenanceResponse } from '../models/landlord/LandlordProperty';

export const filterSolarRequest = (requests: MaintenanceResponse[]) => {
  const updatedRequests: MaintenanceResponse[] = [];
  requests.forEach((request) => {
    const privateWorks = request.privateWorksDetails;
    if (privateWorks && privateWorks.private) {
      if (privateWorks.workflowType !== 'SOLAR') {
        updatedRequests.push(request);
      }
    } else {
      updatedRequests.push(request);
    }
  });
  return updatedRequests;
};
