import { InitialPaymentDetails } from '../../models/rentOnboarding/rentOnboarding';

export const LABELS = {
  NAV_TITLE: 'Rent setup',
  NAV_SUBTITLE: 'Please check that your rent details are correct',
  THATS_IT: "That's it",
  BACK: 'Back',
  PROPERTY: 'Property address',
  RENT: 'Rent',
  RENT_PAYMENTS: 'Rent payments',
  INITIAL_PAYMENTS: 'Initial payments',
  NAV_INITIAL_TITLE: 'Initial payments setup',
  NAV_INITIAL_SUBTITLE: 'Please check that your initial payment details are correct',
  BOND: 'Bond',
  HOLDING_DEPOSIT: 'Holding deposit',
  RENT_FIRST: 'First rent',
};

export const getPaymentType = (initialPayments?: InitialPaymentDetails | null) => {
  if (!initialPayments) return LABELS.RENT;
  if (initialPayments.paymentType === 'RentBond') return LABELS.BOND;
  if (initialPayments.paymentType === 'RentDeposit') return LABELS.HOLDING_DEPOSIT;
  if (initialPayments.paymentType === 'RentFirst') return LABELS.RENT_FIRST;
  return LABELS.RENT;
};
