import { ServiceTypes } from '../../models/services/services';

export enum PaymentMethodsType {
  Bank = 'Bank',
  Credit = 'Credit',
}

export const LABELS = {
  NAV_TITLE: 'Rent summary',
  NAV_INITIAL_TITLE: 'Initial payments summary',
  NAV_SUBTITLE: 'One last check of the details please',
  CARD_ADDRESS: 'ADDRESS',
  CARD_RENT: 'RENT AMOUNT',
  CARD_PAYMENT: 'PAYMENT',
  BILLER_CODE: 'BILLER CODE',
  REFERENCE_NUMBER: 'REFERENCE NUMBER',
  CARD_NEXT_PAYMENT: 'NEXT PAYMENT DATE',
  BUTTON_TEXT: 'NEXT',
  BACK: 'Back',
  PROPERTY: 'Property address',
  RENT: 'Rent',
  RENT_PAYMENTS: 'Rent payments',
  BOND: 'Bond',
  HOLDING_DEPOSIT: 'Holding Deposit',
  INITIAL_PAYMENTS: 'Initial payments',
  FINISH: 'Finish',
};

export const rentDefaultServiceTypes = [
  ServiceTypes.Electricity,
  ServiceTypes.Broadband,
  ServiceTypes.Gas,
  ServiceTypes.Cleaning,
  ServiceTypes.Removals,
];
