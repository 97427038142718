import { Route } from '../../navigation/NavigationUtils';

export const LABELS = {
  ABOUT: 'About',
  PRIVACY: 'Privacy policy',
  WEBSITE: 'www.sortedservices.com',
  COPY: '© 2023 Sorted Lab Pty Ltd',
};

export const links = {
  aboutlink: 'https://www.sortedservices.com/',
  privacylink:
    'https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/5f3f0b5aed6d327a8a61920b/1597967214021/Sorted+Privacy+Policy-2020.pdf',
  facebook: 'https://www.facebook.com/GetSortedServices/',
  linkedin: 'http://www.linkedin.com/company/18225958',
  instagram: 'http://instagram.com/sortedservices',
};

export const hideFooter: Route[] = ['checkoutSelect'];
