import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { store } from './store/Store';
import './theme/app.scss';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme/Theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { history } from './helpers/HistoryHelper';
import Toast from './components/toast/Toast';
import pkgInfo from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
console.log('Sorted Web version : ', pkgInfo.version);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
          <Toast />
        </StyledEngineProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
