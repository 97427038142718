/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    loadingContainer: {
      flex: 1,
      position: 'relative',
    },
    loading: {
      position: 'absolute',
      width: 16,
      height: 16,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      border: '4px solid transparent',
      borderTopColor: theme.colors.buttonPrimary,
      borderRadius: '50%',
      animation: 'button-loading-spinner 1s ease infinite',
    },
    loadingOutline: {
      position: 'absolute',
      width: 16,
      height: 16,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      border: '4px solid transparent',
      borderTopColor: theme.colors.buttonPrimary,
      borderRadius: '50%',
      animation: 'button-loading-spinner 1s ease infinite',
    },
  })();
};
