/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  primaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        ...smallPageContainer,
      },
    },
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 800,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    mainBox: {
      padding: theme.units.spacing * 6,
    },
    icon: {
      width: 30,
      height: 30,
    },
    title: {
      fontSize: 30,
      marginTop: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 6,
    },
    subtitle: {
      marginBottom: theme.units.spacing * 8,
    },
    option: {
      marginBottom: theme.units.spacing * 4,
      paddingTop: theme.units.spacing * 3,
      paddingBottom: theme.units.spacing * 3,
      cursor: 'pointer',
    },
    optionRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    optionIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    optionText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.m,
      marginRight: 'auto',
      marginLeft: theme.units.spacing * 4,
    },
    optionTick: {
      width: theme.units.iconSize / 1.4,
      height: theme.units.iconSize / 1.4,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    actionButtonContainer: {
      display: 'flex',
      marginTop: theme.units.spacing * 12,
    },
    actionButton: {
      flex: 1,
      width: 'max-content',
    },
  })();
};
