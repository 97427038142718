import React, { FC, useEffect, useState, useMemo } from 'react';
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';
import Plan from '../../../checkoutSelect/components/plan/Plan';
import { store } from '../../../../store/Store';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { useStyles } from './CartItemStyles';
import { getColouredIcon } from '../../../browse/components/marketplace/MarketplaceUtils';
import { getColourFilter } from '../../../../helpers/ColourHelper';
import { getDarkServiceTypeIcon } from '../../../home/components/dashboard/DashboardConstants';
import { ServiceTypes } from '../../../../models/services/services';
import { getFrequency } from '../../../checkoutSelect/components/plan/PlanUtils';
import { getProductDetails } from '../../../checkoutConfigure/components/selectOptions/SelectOptionsUtils';
import { Options } from '../../../../models/billing/Billing';
import binIcon from '../../../../assets/bin.png';
import tickIcon from '../../../../assets/tick-active.png';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { DashboardState } from '../../../../store/state/DashboardState';
import { getServiceIconForCheckout } from '../../../checkoutSelect/components/serviceTabs/ServiceTabsUtils';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { getFooterLabel } from '../../../checkoutConfigure/CheckoutConfigureUtils';

interface CartItemProps {
  hideAction?: boolean;
  cartItem: AvailableServicesResponse;
  frequency?: string;
  quote?: boolean;
  hidePrice?: boolean;
  showTick?: boolean;
  selected?: boolean;
  hover?: boolean;
  selectable?: boolean;
  handleAction: () => void;
  handleTap: () => void;
}

const CartItem: FC<CartItemProps> = ({
  hideAction,
  hidePrice,
  quote,
  cartItem,
  frequency,
  showTick,
  selected,
  hover,
  selectable,
  handleAction,
  handleTap,
}) => {
  const classes = useStyles();
  const colours = getColouredIcon(cartItem.type.replace(/([A-Z][A-Z\-]*)/g, ' $1').trim());
  const filterPrimary = getColourFilter(colours.primary);
  const filterBackground = getColourFilter(colours.background);
  const { dashboardState } = useSelector<ApplicationState, { dashboardState: DashboardState }>(
    (state: ApplicationState) => ({
      dashboardState: state.dashboardState,
    }),
  );

  const { productOptions } = useMemo(() => {
    return getProductDetails(
      cartItem.suppliers![0].extendedData!,
      cartItem.suppliers![0].plans[0].selectedProductId,
    );
  }, []);

  return (
    <div
      className={classNameGenerator([
        classes.cartContainer,
        selected && classes.selected,
        hover && classes.hover,
        selectable && classes.selectable,
      ])}
      onClick={() => handleTap()}
    >
      <div className={classes.topRow}>
        <div
          className={classes.serviceTypeContainer}
          style={filterBackground ? { filter: filterBackground } : {}}
        ></div>
        <img
          className={classes.serviceTypeIcon}
          style={filterPrimary ? { filter: filterPrimary } : {}}
          src={getServiceIconForCheckout(cartItem, dashboardState)}
          alt="service type"
        />
        <div className={classes.titleContainer}>
          <div className={classes.subtitle}>
            {cartItem.type.replace(/([A-Z][A-Z\-]*)/g, ' $1').trim()}
          </div>
          <div className={classes.title}>{cartItem.suppliers![0].plans[0].title}</div>
          <div className={classes.description}>{cartItem.suppliers![0].name}</div>
        </div>
        {!hidePrice && (
          <div className={classes.priceContainer}>
            <div className={classes.price}>
              {quote
                ? 'FREE'
                : `${
                    frequency && Object.keys(productOptions).length !== 0
                      ? getFooterLabel(productOptions as Options, frequency)
                      : `$${cartItem.suppliers![0].plans[0].price.toFixed(2)}`
                  }`}
            </div>
            <div className={classes.uom}>
              {quote
                ? 'Quote'
                : `${frequency ? getFrequency(frequency) : cartItem.suppliers![0].plans[0].uom}`}
            </div>
          </div>
        )}
        {!hideAction && (
          <div className={classes.actionContainer} onClick={() => handleAction()}>
            <img className={classes.actionIcon} src={binIcon} alt="closeIcon" />
          </div>
        )}
        {showTick && (
          <div className={classes.tickContainer}>
            <img className={classes.actionIcon} src={tickIcon} alt="tickIcon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
