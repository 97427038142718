import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AddTagStatus, FastPayPayment } from '../../models/payment/Payment';
import { Property } from '../../models/property/property';
import { Services } from '../../models/services/services';
import ActionCard from '../actionCard/ActionCard';
import { LABELS } from './PayIdBannerConstants';
import { navigate } from '../../navigation/NavigationUtils';

interface PayIdBannerProps {
  userId: number;
  property: Property;
  outstandingPayments: FastPayPayment[];
  activeServices: Services[];
  fetchPaymentDetails: (body: AddTagStatus) => void;
  dismissBanner: (value: boolean) => void;
}

const PayIdBanner: FC<PayIdBannerProps> = ({
  userId,
  property,
  outstandingPayments,
  activeServices,
  fetchPaymentDetails,
  dismissBanner,
}) => {
  const history = useHistory();

  const handleInfoClose = () => {
    const filterNotViewedPayments: number[] = [];
    outstandingPayments.map((item) => {
      if (item.paymentTags !== 'viewed' && item.platformPropertyId === property.id) {
        filterNotViewedPayments.push(item.transactionRunScheduleId);
      }
    });
    if (filterNotViewedPayments.length > 0) {
      fetchPaymentDetails({
        userId,
        platformPropertyId: property.id,
        tag: 'viewed',
        unTag: '',
        transactionRunScheduleIds: filterNotViewedPayments,
      });
    }
    dismissBanner(true);
  };

  const onCardPress = () => {
    let serviceAccountId = 0;
    let category = '';
    activeServices.map((service) => {
      if (service.serviceType === outstandingPayments[0].serviceType) {
        serviceAccountId = service.serviceAccountId;
        category = service.category as string;
      }
    });
    if (outstandingPayments[0].serviceType === 'Rent') {
      navigate('propertyRent', { serviceAccountId });
    } else {
      switch (category) {
        case null:
          navigate('serviceSummary', {
            serviceType: outstandingPayments[0].serviceType,
            serviceAccountId,
            propertyId: property.id,
          });
          break;

        case 'USAGE':
        case 'REFERRAL':
        case 'REFERRAL_IFRAME':
          navigate('genericServiceSummaryScreen', {
            serviceType: outstandingPayments[0].serviceType,
            serviceAccountId,
            propertyId: property.id,
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <ActionCard
      title={LABELS.PAY_ID_BANNER_TITLE}
      text={LABELS.PAY_ID_BANNER_INFO}
      buttonText="Make payment"
      handleButtonPress={() => onCardPress()}
      dismiss
      onDismiss={() => handleInfoClose()}
      showErrorIcon
    />
  );
};

export default PayIdBanner;
