import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ArrowsStyles';
import arrowRight from '../../assets/navigation/arrow-right.png';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import { classNameGenerator } from '../../theme/GlobalStyles';

export interface ArrowsProps {
  disableLeft: boolean;
  disableRight: boolean;
  onLeftArrow: () => void;
  onRightArrow: () => void;
}

const Arrows: FC<ArrowsProps> = ({ disableLeft, disableRight, onLeftArrow, onRightArrow }) => {
  const classes = useStyles();
  return (
    <div className={classes.arrowsContainer}>
      <div
        className={classNameGenerator([
          classes.arrowContainer,
          disableLeft && classes.disabledArrow,
        ])}
        onClick={() => {
          if (disableLeft) return;
          onLeftArrow();
        }}
      >
        <img src={arrowLeft} className={classes.arrow} alt="arrow-left" />
      </div>
      <div
        className={classNameGenerator([
          classes.arrowContainer,
          disableRight && classes.disabledArrow,
        ])}
        onClick={() => {
          if (disableRight) return;
          onRightArrow();
        }}
      >
        <img src={arrowRight} className={classes.arrow} alt="arrow-right" />
      </div>
    </div>
  );
};

export default Arrows;
