/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getFonts } from '../../helpers/GetFonts';
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
      marginBottom: theme.units.spacing * 4,
      flexShrink: 0,
    },
    inputContainer: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      borderRadius: theme.units.borderRadius / 1.5,
      border: `${theme.colors.borderColor} 2px solid`,
      backgroundColor: theme.colors.inputBackground,
    },
    inputContainerBig: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      paddingRight: theme.units.spacing * 1,
    },
    inputStyle: {
      border: 'none',
      '&:focus-visible': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.inputText,
        fontSize: theme.font.m,
        fontWeight: getFonts('Light'),
      },
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      flex: 1,
      padding: 0,
      paddingRight: theme.units.spacing * 2,
      paddingLeft: theme.units.spacing * 3,
      position: 'relative',
      marginTop: 0,
    },
    bigInput: {
      margin: theme.units.spacing * 4,
      marginTop: theme.units.spacing * 3,
      marginBottom: theme.units.spacing * 3,
      fontSize: theme.font.l,
      '&::-webkit-input-placeholder': {
        color: theme.colors.black38,
        fontSize: theme.font.l,
        fontWeight: getFonts('Regular'),
      },
    },
    errorInput: {
      border: `${theme.colors.error} 2px solid`,
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
    secondInput: {
      marginLeft: theme.units.spacing * 4,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing,
    },
    titleContainerBig: {
      marginBottom: theme.units.spacing * 2,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    endElement: {
      marginLeft: theme.units.spacing * 2,
    },
    endElementBig: {
      marginRight: theme.units.spacing * 2,
    },
    endStatus: {
      marginLeft: theme.units.spacing,
      width: 25,
      height: 25,
      marginRight: theme.units.spacing * 2,
    },
    endStatusBig: {
      marginRight: theme.units.spacing * 3,
    },
  })();
};
