/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import {
  DeleteFileRequest,
  FileUploadResponse,
  MultiFileUploadRequest,
} from '../../models/files/files';
import FileUploadService from '../../services/fileUploadService/FileUploadService';
import {
  FileUploadAction,
  FileUploadActions,
  FileUploadActionTypes,
} from '../actions/FileUploadActions';

export function* deleteFileWorker(action: FileUploadAction) {
  try {
    const data = action.data as DeleteFileRequest;
    yield FileUploadService.deleteFile(data);
    yield put(FileUploadActions.deleteFileSuccess());
  } catch (error) {
    yield put(FileUploadActions.deleteFileError(error as Error));
  }
}

export function* multipleFileUploadWorker(action: FileUploadAction) {
  try {
    yield put(FileUploadActions.showFileLoading());
    const { file, id } = action.data as MultiFileUploadRequest;
    const response: FileUploadResponse[] = yield FileUploadService.postMultipleFile(file);
    yield put(
      FileUploadActions.postMultiFileUploadSuccess({
        file: response.map((res) => ({
          link: res.fileLink,
          name: res.fileName,
        })),
        id,
      }),
    );
  } catch (error) {
    yield put(FileUploadActions.postMultiFileUploadError(error as Error));
  } finally {
    yield put(FileUploadActions.hideFileLoading());
  }
}

function* fileUploadWatcher(): IterableIterator<any> {
  yield takeEvery(FileUploadActionTypes.DELETE_FILE_REQUEST, (action: FileUploadAction) =>
    deleteFileWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(
    FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_REQUEST,
    (action: FileUploadAction) =>
      multipleFileUploadWorker({
        type: action.type,
        data: action.data,
      }),
  );
}

export default fileUploadWatcher;
