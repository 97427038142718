import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './BrowseStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TabProps } from '../../models/dashboard/dashboard';
import {
  LABELS,
  browseCategories,
  getOfferTitleImage,
  getPacks,
  switchOffers,
} from './BrowseConstants';
import Services from './components/services/Services';
import Marketplace from './components/marketplace/Marketplace';
import TabMenu from '../../components/tabMenu/TabMenu';
import { findExtraServiceInfo, useDashboardServices } from '../../helpers/ServiceInfoHelper';
import { BundleResponse, OfferCategory } from '../../models/bundles/Bundles';
import { OffersRequest } from '../../models/offers/Offers';
import { DashboardActionTypes, DashboardActions } from '../../store/actions/DashboardActions';
import { DashboardState } from '../../store/state/DashboardState';
import tealLady from '../../assets/bannerPeople/teal.png';
import arrowRight from '../../assets/navigation/arrow-right.png';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import SearchBar from '../../components/searchBar/SearchBar';
import BroadbandCard from '../../components/broadbandCard/BroadbandCard';
import ElectricityCard from '../../components/electricityCard/ElectricityCard';
import OtherServiceCards from '../../components/otherServiceCards/OtherServiceCards';
import ServiceIcon from '../../components/serviceIcon/ServiceIcon';
import TileContainer from '../../components/tileContainer/TileContainer';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';

import { Route, navigate } from '../../navigation/NavigationUtils';
import {
  getDarkServiceTypeIcon,
  SWITCH_SERVICES,
} from '../home/components/dashboard/DashboardConstants';
import {
  removeDuplicatedCimetServices,
  filterServicesByStatus,
} from '../home/components/dashboard/DashboardUtils';
import { View } from '../login/Login';
import {
  banners,
  categoryTypes,
  serviceTypes,
} from './components/marketplace/MarketplaceConstants';
import { CimetVisitRequest } from '../../models/cimet/Cimet';
import { CimetActions } from '../../store/actions/CimetActions';
import { getBanners } from './components/marketplace/MarketplaceUtils';
import ActionCard from '../../components/actionCard/ActionCard';
import { classNameGenerator } from '../../theme/GlobalStyles';
import SwipeableViews from 'react-swipeable-views';
import { ServiceTypes } from '../../models/services/services';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import Tile from '../../components/tile/Tile';
import Button from '../../components/button/Button';
import AddPropertyPopup from '../../components/addPropertyPopup/AddPropertyPopup';
import PrivateWorksCards from '../home/components/dashboard/components/privateWorksCards/PrivateWorksCards';
import { RequestActions } from '../../store/actions/RequestActions';
import ServiceOffer from '../../components/serviceOffer/ServiceOffer';
import MainBanner from '../../components/mainBanner/MainBanner';
import PackCard from '../../components/packCard/PackCard';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';
import { createPreopen, createRequiredServiceTypes } from '../../helpers/CheckoutHelper';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
} from '../../models/checkout/Checkout';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { Property } from '../../models/property/property';

interface BrowseProps extends RouteComponentProps {
  isGuest: boolean;
  dashboardState: DashboardState;
  bundleResponse: BundleResponse | undefined;
  loadingOffers: boolean;
  availableServices: AvailableServicesResponse[] | undefined;
  checkoutProperty: Property | null | undefined;
  getOffers: (data: OffersRequest) => void;
  postCimetVisit: (data: CimetVisitRequest) => void;
  getPackages: () => void;
  getAvailableServices: (data: AvailableServicesRequest) => void;
}

const Browse: FC<BrowseProps> = ({
  isGuest,
  dashboardState: { onboardedProperties, selectedProperty, offers },
  bundleResponse,
  loadingOffers,
  availableServices,
  checkoutProperty,
  getOffers,
  postCimetVisit,
  getPackages,
  getAvailableServices,
}) => {
  const classes = useStyles();
  const [switchIndex, setSwitchIndex] = useState<number>(0);
  const [packIndex, setPackIndex] = useState<number>(0);
  const [showAddPropertyPopup, setShowAddPropertyPopup] = useState<boolean>(false);
  const {
    property,
    newServices,
    activeServices,
    newAndInProgessServices,
    electricityServices,
    broadbandServices,
    otherServices,
    cimetAvailableServices,
  } = useDashboardServices();
  const themeKey = getCurrentThemeKey();
  const packages = useMemo<OfferCategory[]>(() => {
    const bundlePackages =
      bundleResponse && bundleResponse.packages && bundleResponse.packages.length > 0
        ? bundleResponse.packages
        : [];
    const filteredPackages = property
      ? bundlePackages.filter((data) => data.branches.includes(property.branch.internalCode))
      : [];

    let result: OfferCategory[] = [];
    filteredPackages
      .filter((p) => !!p.category)
      .map((p) => {
        if (!result.some((obj) => obj.title === p.category!.title)) {
          result.push(p.category!);
        }
      });

    return result;
  }, [bundleResponse]);

  useEffect(() => {
    if (property) {
      if (!offers || !offers.length) {
        getOffers({ branchId: property.branch.id });
      }

      if (!bundleResponse) {
        getPackages();
      }
    }
  }, [property]);

  useEffect(() => {
    if (
      property &&
      (availableServices === undefined ||
        availableServices.length === 0 ||
        (!!checkoutProperty && property.id !== checkoutProperty.id))
    ) {
      getAvailableServices({
        propertyId: property.id.toString(),
        requiredServiceTypes:
          onboardedProperties![selectedProperty].services!.map(
            (s) => s.serviceType.replace(' ', '') as ServiceTypes,
          ) || [],
      });
    }
  }, [property, availableServices]);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <MainBanner
          title={LABELS.MARKETPLACE_TITLE}
          subtitle={LABELS.MARKETPLACE_SUBTITLE}
          image={tealLady}
        />
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <SearchBar />
            {property ? (
              <>
                {newAndInProgessServices.length ||
                themeKey === ThemeKey.SWITCH ||
                (!!cimetAvailableServices.length &&
                  !!removeDuplicatedCimetServices(activeServices, cimetAvailableServices)
                    .length) ? (
                  <TileContainer title="All services" noScroll>
                    <div className={classes.grid}>
                      {!!electricityServices.length &&
                        filterServicesByStatus(
                          onboardingScreenValidStates,
                          electricityServices,
                        ).map((service) => (
                          <ElectricityCard
                            addressConfirmed={
                              onboardedProperties![selectedProperty].addressConfirmed as boolean
                            }
                            service={service}
                            extraServiceInfo={findExtraServiceInfo(
                              service,
                              onboardedProperties![selectedProperty].availableServices,
                            )}
                            propertyId={property.id}
                            showIconOnly
                            parentStyles={classes.gridItem}
                            coloured
                          />
                        ))}
                      {!!broadbandServices.length &&
                        filterServicesByStatus(onboardingScreenValidStates, broadbandServices).map(
                          (service) => (
                            <BroadbandCard
                              addressConfirmed={
                                onboardedProperties![selectedProperty].addressConfirmed as boolean
                              }
                              service={service}
                              extraServiceInfo={findExtraServiceInfo(
                                service,
                                onboardedProperties![selectedProperty].availableServices,
                              )}
                              propertyId={property.id}
                              showIconOnly
                              parentStyles={classes.gridItem}
                              coloured
                            />
                          ),
                        )}
                      {!!cimetAvailableServices.length &&
                        !!removeDuplicatedCimetServices(activeServices, cimetAvailableServices)
                          .length &&
                        removeDuplicatedCimetServices(activeServices, cimetAvailableServices).map(
                          (service, idx) => (
                            <ServiceIcon
                              icon={getDarkServiceTypeIcon(service)}
                              name={service.toString()}
                              coloured
                              parentStyles={classes.gridItem}
                              key={idx}
                              handlePress={() => {
                                postCimetVisit({ propertyId: property.id, serviceType: service });
                                navigate('cimet', { propertyId: property.id, service });
                              }}
                            />
                          ),
                        )}
                      {!!otherServices.length && (
                        <OtherServiceCards
                          addressConfirmed={
                            onboardedProperties![selectedProperty].addressConfirmed as boolean
                          }
                          services={filterServicesByStatus(
                            onboardingScreenValidStates,
                            otherServices,
                          )}
                          availableServices={
                            onboardedProperties![selectedProperty].availableServices
                          }
                          propertyId={property.id}
                          showIconOnly
                          parentStyles={classes.gridItem}
                          coloured
                        />
                      )}
                      {themeKey === ThemeKey.SWITCH && (
                        <>
                          {SWITCH_SERVICES.map((s, idx) => (
                            <ServiceIcon
                              icon={s.icon}
                              name={s.name}
                              coloured
                              parentStyles={classes.gridItem}
                              key={idx}
                              handlePress={() => s.action()}
                            />
                          ))}
                        </>
                      )}
                      {!!packages.length &&
                        packages.map((p, index) => (
                          <ServiceIcon
                            icon={p.icon.dark}
                            name={p.title}
                            parentStyles={classes.gridItem}
                            key={index}
                            coloured
                            handlePress={
                              () => null
                              // navigate('Onboarding', {
                              //   url: `category?category=${p.title}`,
                              //   isPrivateWorks: true,
                              // })
                            }
                          />
                        ))}
                      {/* {themeKey !== ThemeKey.SWITCH &&
                        categoryTypes.map((category, index) => (
                          <ServiceIcon
                            icon={category.icon}
                            name={category.name}
                            parentStyles={classes.gridItem}
                            key={index}
                            coloured
                            handlePress={() => {
                              if (property || category.link === 'optimiseScreen') {
                                navigate(category.link as Route);
                              } else {
                                setShowAddPropertyPopup(true);
                              }
                            }}
                          />
                        ))} */}
                    </div>
                  </TileContainer>
                ) : (
                  <TileContainer title="Services" noScroll>
                    <div className={classes.noServices}>{LABELS.NO_SERVICES}</div>
                  </TileContainer>
                )}
              </>
            ) : (
              <TileContainer title="All services" noScroll>
                <div className={classes.grid}>
                  {serviceTypes.map((category, index) => (
                    <ServiceIcon
                      icon={category.icon}
                      name={category.name}
                      parentStyles={classes.gridItem}
                      key={index}
                      coloured
                      handlePress={() => setShowAddPropertyPopup(true)}
                    />
                  ))}
                </div>
              </TileContainer>
            )}

            {property &&
              !!getPacks(
                newAndInProgessServices,
                onboardedProperties![selectedProperty].availableServices,
              ).length && (
                <TileContainer
                  title="Service bundles"
                  noScroll
                  arrowProps={{
                    disableLeft: packIndex === 0,
                    disableRight:
                      getPacks(
                        newAndInProgessServices,
                        onboardedProperties![selectedProperty].availableServices,
                      ).length -
                        1 ===
                      packIndex,
                    onLeftArrow: () => {
                      setPackIndex(packIndex - 1);
                    },
                    onRightArrow: () => {
                      setPackIndex(packIndex + 1);
                    },
                  }}
                >
                  <SwipeableViews
                    enableMouseEvents
                    style={{ paddingRight: '55%', position: 'relative' }}
                    index={packIndex}
                    onChangeIndex={(index: number) => {
                      setPackIndex(index);
                    }}
                  >
                    {getPacks(
                      newAndInProgessServices,
                      onboardedProperties![selectedProperty].availableServices,
                    ).map((s, idx) => (
                      <PackCard
                        key={idx}
                        background={s.background}
                        title={s.title}
                        description={s.description}
                        icons={s.icons}
                        onPress={() => {
                          navigate('checkoutSelect', {
                            requiredServiceTypes: createRequiredServiceTypes(s.serviceTypes),
                          });
                        }}
                      />
                    ))}
                  </SwipeableViews>
                </TileContainer>
              )}

            {loadingOffers ? (
              <TileContainer title="Offers" noScroll>
                <div className={classes.servicesGrid}>
                  {[...Array(2).fill(null)].map((service, idx) => (
                    <Tile key={idx} loading handlePress={() => null} />
                  ))}
                </div>
              </TileContainer>
            ) : (
              <>
                {offers && !!offers.length && (
                  <TileContainer title="Offers" noScroll>
                    <div className={classes.servicesGrid}>
                      {offers.map((offer, idx) => {
                        const content = getOfferTitleImage(offer.title);
                        if (!content) return null;
                        return (
                          <div
                            className={classes.offerContainer}
                            style={{ backgroundColor: content.backgroundColor }}
                            key={idx}
                          >
                            <img
                              src={content.image}
                              className={
                                offer.title === 'HelloFresh' ? classes.helloFresh : classes.blys
                              }
                            />
                            <img src={content.title} className={classes.offerTitle} />
                            <div className={classes.offerSubtitle}>{content.subtitle}</div>
                            <Button
                              parentStyles={classes.offerButton}
                              onPress={() => navigate('offerScreen', { id: idx })}
                            >
                              Redeem
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </TileContainer>
                )}
              </>
            )}
            {themeKey === ThemeKey.SWITCH && (
              <TileContainer
                title="For you"
                noScroll
                arrowProps={{
                  disableLeft: switchIndex === 0,
                  disableRight: switchOffers.length - 1 === switchIndex,
                  onLeftArrow: () => {
                    setSwitchIndex(switchIndex - 1);
                  },
                  onRightArrow: () => {
                    setSwitchIndex(switchIndex + 1);
                  },
                }}
              >
                <SwipeableViews
                  enableMouseEvents
                  style={{ paddingRight: '55%', position: 'relative' }}
                  index={switchIndex}
                  onChangeIndex={(index: number) => {
                    setSwitchIndex(index);
                  }}
                >
                  {switchOffers.map((s, idx) => (
                    <ServiceOffer
                      key={idx}
                      image={s.image}
                      title={s.title}
                      subtitle={s.subtitle}
                      buttonText="Download"
                      handlePress={() => s.action()}
                    />
                  ))}
                </SwipeableViews>
              </TileContainer>
            )}
            {/* {!isGuest && <PrivateWorksCards />} */}
            {property && (
              <TileContainer title="Browse by service category" noScroll>
                <div className={classes.categoryGrid}>
                  {browseCategories.map((category, index) => (
                    <div
                      className={classes.categoryContainer}
                      key={index}
                      onClick={() => navigate(category.link as Route)}
                    >
                      <div className={classes.categoryTitle}>{category.name}</div>
                      <img src={category.image} className={classes.categoryImage} />
                    </div>
                  ))}
                </div>
              </TileContainer>
            )}

            <AddPropertyPopup
              showModal={showAddPropertyPopup}
              toggleModal={(showModal: boolean) => setShowAddPropertyPopup(showModal)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const loadingOffers = loadingSelector([DashboardActionTypes.GET_OFFERS]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingOffers: loadingOffers(state),
  isGuest: state.authState.guest,
  dashboardState: state.dashboardState,
  bundleResponse: state.request.bundleResponse,
  availableServices: state.checkout.availableServices,
  checkoutProperty: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPackages: () => dispatch(RequestActions.getPackages()),
  getOffers: (data: OffersRequest) => dispatch(DashboardActions.getOffersRequest(data)),
  postCimetVisit: (data: CimetVisitRequest) => dispatch(CimetActions.postCimetVisitRequest(data)),
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Browse));
