import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './ActivityStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../components/text/Text';
import { LABELS } from './ActivityConstants';
import Box from '../../../../components/box/Box';
import Button from '../../../../components/button/Button';

interface ActivityProps extends RouteComponentProps {}

const Activity: FC<ActivityProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        <Box lightBorder parentStyles={classes.box} innerStyles={classes.innerBox}>
          <Text textVariant="info">{LABELS.NO_AVAILABLE}</Text>
          <Button
            parentStyles={classes.button}
            onPress={() => window.open('https://sortedservicesapp.app.link/ol749XFFxEb', '_blank')}
          >
            {LABELS.DOWNLOAD}
          </Button>
        </Box>
      </div>
      <div className={classes.secondaryContainer}></div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Activity));
