import { getCurrentTheme } from '../../../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../../../helpers/GetFonts';
import { SIZES } from '../../../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    questionContainer: {
      marginBottom: theme.units.spacing * 12,
    },
    heading: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 18,
      marginBottom: theme.units.spacing,
    },
    description: {
      marginBottom: theme.units.spacing * 2,
      fontWeight: getFonts('Regular'),
      color: theme.colors.black60,
      fontSize: 14,
    },
    infoText: {
      marginTop: theme.units.spacing * 2,
      fontWeight: getFonts('Regular'),
      color: theme.colors.black38,
      fontSize: 14,
    },

    // Radio Card ---------------------------------------------------------------- //
    optionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '12px',
      marginTop: '12px',
      marginBottom: '12px',
      maxWidth: 900,
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr 1fr',
      },
    },
    cardContainerStyle: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 2px solid`,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      cursor: 'pointer',
      backgroundColor: theme.colors.light,
    },
    selectedCardContainerStyle: {
      border: `${theme.colors.buttonPrimary} 2px solid !important`,
    },
    seperator: {
      height: 1,
      margin: 4,
      marginBottom: 12,
      background: '#eee',
    },
    gtwLight: {
      fontFamily: 'GT Walsheim Light',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    headerText2: {
      fontSize: 15,
      marginTop: 5,
    },
    imageContainer: {
      width: '100%',
      height: 140,
      flexShrink: 0,
      borderRadius: 6,
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectPosition: 'center',
      objectFit: 'cover',
    },
    imageContainerCentered: {
      width: '100%',
      height: 140,
      flexShrink: 0,
      borderRadius: 6,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
    },
    imageCentered: {
      width: '100%',
      height: '70%',
      objectPosition: 'center',
      objectFit: 'contain',
    },
    content: {
      padding: 12,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 'auto',
    },
    title: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: 20,
    },
    subtitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: 14,
      marginTop: 4,
    },
    line: {
      marginTop: 8,
      borderBottom: `${theme.colors.borderColorLight} 2px solid`,
      marginBottom: 8,
    },
    priceRow: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 12,
    },
    price: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: 26,
      marginRight: 6,
    },
    uom: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: 14,
      position: 'relative',
      top: '-4px',
    },
    body: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black38,
      fontSize: 14,
      marginBottom: 12,
    },
    viewMore: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 12,
      marginBottom: 12,
    },
    viewMoreText: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.buttonPrimary,
      textDecoration: 'underline',
      fontSize: 14,
    },
    link: {
      width: 18,
      height: 18,
      marginLeft: 12,
    },
    primaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 30,
      margin: 12,
      color: theme.colors.light,
      textAlign: 'center',
      fontFamily: 'GT Walsheim',
      fontSize: 15,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
      cursor: 'pointer',
    },
    outlineButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 30,
      margin: 12,
      color: theme.colors.buttonPrimary,
      textAlign: 'center',
      fontFamily: 'GT Walsheim',
      fontSize: 15,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: 6,
      cursor: 'pointer',
      transition: 'all 0.3s ease 0s',
      '&:hover': {
        transition: 'all 0.3s ease 0s',
        backgroundColor: theme.colors.buttonPrimary,
        color: theme.colors.light,
      },
    },

    // Toggle ------------------------------------------------------------ //
    toggleContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      height: 40,
      borderRadius: 2,
      margin: '10px 0px',
      boxShadow: 'none',
      borderRight: `1px solid ${theme.colors.buttonPrimary}`,
    },
    toggleButton: {
      flex: 1,
      color: theme.colors.buttonPrimary,
      borderTop: `1px solid ${theme.colors.buttonPrimary}`,
      borderBottom: `1px solid ${theme.colors.buttonPrimary}`,
      borderLeft: `1px solid ${theme.colors.buttonPrimary}`,
      fontSize: 15,
      height: '100%',
    },
    toggleButtonActive: {
      flex: 1,
      background: `${theme.colors.buttonPrimary} !important`,
      color: `#fff !important`,
      height: '100%',
    },
  })();
};
