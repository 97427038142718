import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PlanStyles';
import { LABELS } from './PlanConstants';
import {
  AddAndUpdateCartActionRequest,
  DeleteCartItemActionRequest,
  ModifyCart,
  PlanResponse,
  SupplierResponse,
} from '../../../../models/checkout/Checkout';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { getFrequency } from './PlanUtils';
import { Property } from '../../../../models/property/property';
import { updateAvailableServicesOnSelect, useCart } from '../../../../helpers/CheckoutHelper';
import { getProductDetails } from '../../../checkoutConfigure/components/selectOptions/SelectOptionsUtils';
import { Options } from '../../../../models/billing/Billing';
import { ThemeKey, getCurrentThemeKey } from '../../../../theme/Theme';
import { ApplicationState } from '../../../../store/RootReducer';
import info from '../../../../assets/info.png';
import { getFooterLabel } from '../../../checkoutConfigure/CheckoutConfigureUtils';

interface PlanProps {
  plan: PlanResponse;
  serviceType: string;
  supplier: SupplierResponse;
  logo: string;
  cartView?: boolean;
  hideAction?: boolean;
  showCompare?: boolean;
  property: Property;
  frequency?: string;
  handleAction: () => void;
  handleSupplierAction: () => void;
  onSelection?: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
  addToCompare: (data: ModifyCart) => void;
  removeFromCompare: (data: ModifyCart) => void;
}

const Plan: FC<PlanProps> = ({
  plan,
  serviceType,
  supplier,
  logo,
  cartView,
  hideAction,
  showCompare,
  property,
  frequency,
  handleAction,
  handleSupplierAction,
  onSelection,
  updateCart,
  deleteCartItem,
  addToCompare,
  removeFromCompare,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  const { compareItems } = useCart();

  const disableCompare = useMemo<boolean>(() => {
    if (compareItems && !!compareItems.length) {
      return compareItems.some((i) => i.type !== serviceType);
    }
    return false;
  }, [compareItems]);

  // TODO: Types conflict here
  const { productOptions } = useMemo(() => {
    return getProductDetails(supplier.extendedData!, plan.selectedProductId);
  }, []);

  const isTradeRequestSupplier = supplier.extendedData!.ServiceCategoryId === 'TradeRequest';

  const handleTap = () => {
    const updatedAvailableServices = updateAvailableServicesOnSelect(
      !!plan.selected,
      serviceType,
      supplier.providerId,
      plan.productId,
    );
    if (!!plan.selected) {
      deleteCartItem({
        availableServices: updatedAvailableServices,
        deleteCartItemRequest: {
          propertyId: property.id.toString(),
          serviceType,
          supplierId: supplier.providerId,
          planId: plan.productId,
        },
      });
    } else {
      updateCart({
        availableServices: updatedAvailableServices,
        propertyId: property.id.toString(),
      });
    }

    if (!plan.selected && onSelection) {
      onSelection();
    }
  };
  const toggleCompare = () => {
    const data = {
      productId: plan.productId,
      providerId: supplier.providerId,
      serviceType,
    };

    if (plan.compare) {
      removeFromCompare(data);
    } else {
      addToCompare(data);
    }
  };

  return (
    <div className={`${classes.planContainer} ${plan.selected && !cartView && classes.activePlan}`}>
      <div className={classes.planContent}>
        <div className={classes.planHeader}>
          <div className={classes.logoContainer} onClick={() => handleSupplierAction()}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          <div className={classes.titleContainer}>
            <div className={classes.title} onClick={() => handleSupplierAction()}>
              {supplier.name}
            </div>
            <div className={classes.description}>{plan.title}</div>
          </div>
          <div className={classes.planInformationContainer} onClick={() => handleAction()}>
            <img src={info} className={classes.infoIcon} alt="info icon" />
            <div className={classes.planInformationText}>{LABELS.PLAN_INFORMATION}</div>
          </div>
        </div>
        {!cartView && plan.subtext && (
          <div
            className={classes.infoText}
            dangerouslySetInnerHTML={{
              __html: plan.subtext,
            }}
          />
        )}
      </div>

      {!hideAction && (
        <div
          className={`${classes.actionContainer} ${
            plan.selected && !cartView && classes.actionContainerSelected
          }`}
        >
          {!!plan.price && (
            <div className={classes.priceContainer}>
              <div className={classes.from}>{plan.pricingType}</div>
              <div className={classes.priceWrapper}>
                <div className={classes.price}>{`${
                  frequency
                    ? getFooterLabel(productOptions as Options, frequency)
                    : `$${plan.price}`
                }`}</div>
                <div className={classes.uom}>{`/${
                  frequency ? getFrequency(frequency) : plan.uom
                }`}</div>
              </div>
            </div>
          )}
          {showCompare && !disableCompare && (
            <div
              className={`${!!plan.compare ? classes.activeCompare : classes.compareContainer}`}
              onClick={(e) => {
                e.stopPropagation();
                toggleCompare();
              }}
            >
              {!!plan.compare ? 'Comparing' : 'Compare'}
            </div>
          )}
          <div
            className={`${plan.selected ? classes.activeCompare : classes.compareContainer}`}
            onClick={(e) => {
              handleTap();
            }}
          >
            {isTradeRequestSupplier ? LABELS.SELECT : plan.selected ? LABELS.REMOVE : LABELS.ADD}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  addToCompare: (data: ModifyCart) => dispatch(CheckoutActions.addToCompare(data)),
  removeFromCompare: (data: ModifyCart) => dispatch(CheckoutActions.removeFromCompare(data)),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
