/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { getCurrentTheme } from '../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    arrowsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      marginLeft: theme.units.spacing * 2,
    },
    arrowContainer: {
      borderRadius: 5000,
      width: theme.units.iconSize * 1.6,
      height: theme.units.iconSize * 1.6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.lightGrey,
      cursor: 'pointer',
      marginLeft: theme.units.spacing * 2,
    },
    disabledArrow: {
      opacity: 0.4,
    },
    arrow: {
      width: theme.units.iconSize * 0.6,
      height: theme.units.iconSize * 0.6,
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
