export const routes = {
  // Done
  home: '/home',
  browse: '/browse',
  account: '/account',
  login: '/login',
  cimet: '/cimet',
  download: '/download',
  cimetScreen: '/cimetScreen',
  profileSelection: '/profileSelection',
  creatingAccount: '/creatingAccount',
  passwordSetup: '/passwordSetup',
  emailSent: '/emailSent',
  errorScreen: '/errorScreen',
  tenantRequestInfo: '/tenantRequestInfo',
  maintenance: '/maintenance',
  maintenanceSuccessScreen: '/maintenanceSuccessScreen',
  propertyInfo: '/propertyInfo',
  properties: '/properties',
  genericServiceSummaryScreen: '/genericServiceSummaryScreen',
  serviceSummary: '/serviceSummary',
  otherServicesSummary: '/otherServicesSummary',
  propertyRent: '/propertyRent',
  support: '/support',
  planDetails: '/planDetails',
  offerScreen: '/offerScreen',
  essentialsScreen: '/essentialsScreen',
  protectScreen: '/protectScreen',
  maintainScreen: '/maintainScreen',
  optimiseScreen: '/optimiseScreen',
  suppliersScreen: '/suppliersScreen',
  propertyOnboarding: '/propertyOnboarding',
  selectPayment: '/selectPayment',
  addPayment: '/addPayment',
  addBank: '/addBank',
  addCard: '/addCard',
  viewCard: '/viewCard',
  viewBank: '/viewBank',
  viewPaymentMethods: '/viewPaymentMethods',

  // Checkout
  checkoutSelect: '/checkoutSelect',
  checkoutConfigure: '/checkoutConfigure',
  checkoutPersonalDetails: '/checkoutPersonalDetails',
  checkoutFinalize: '/checkoutFinalize',
  checkoutSummary: '/checkoutSummary',
  checkoutCompleteCart: '/checkoutCompleteCart',

  // 2nd phase
  viewPropertyScreen: '/viewPropertyScreen',
  switchRoomBooking: '/switchRoomBooking',
  calendarScreen: '/calendarScreen',
  pinLogin: '/pinLogin',
  pinSetup: '/pinSetup',
  checkBillScreen: '/checkBillScreen',
  checkBillConfirmScreen: '/checkBillConfirmScreen',
  spendCreditsSelectScreen: '/spendCreditsSelectScreen',
  spendCreditsInputScreen: '/spendCreditsInputScreen',
  spendCreditsConfirmScreen: '/spendCreditsConfirmScreen',
  redeemCodeSelectScreen: '/redeemCodeSelectScreen',
  redeemCodeInputScreen: '/redeemCodeInputScreen',
  redeemCodeConfirmScreen: '/redeemCodeConfirmScreen',
  referAFriendScreen: '/referAFriendScreen',
  walletBreakDownScreen: '/walletBreakDownScreen',
  transactionScreen: '/transactionScreen',
  transactionSummary: '/transactionSummary',
  promotionDetailsScreen: '/promotionDetailsScreen',
  settings: '/settings',
  rentWelcome: '/rentWelcome',
  rentConfirmation: '/rentConfirmation',
  rentSummary: '/rentSummary',
  leaseServiceability: '/leaseServiceability',

  // Landlord
  maintenanceScreen: '/maintenanceScreen',
  applicationsScreen: '/applicationsScreen',
  landlordPropertyInfo: '/landlordPropertyInfo',
  landlordService: '/landlordService',
  landlordMaintenanceInfo: '/landlordMaintenanceInfo',
  notifications: '/notifications',
  applicationRankEdit: '/applicationRankEdit',

  // Need extra care in doing
  payId: '/payId', // Need help to reproduce
  emergencyMaintenance: '/emergencyMaintenance',
  onboarding: '/onboarding',
};
