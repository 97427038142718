/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { get, values } from 'lodash';
import {
  SupplierOptions,
  PlanOption,
  FeatureDetails,
  SupplierOption,
  PreAgreementSummary,
  PlanDetailObject,
  ProviderPlan,
  Options,
} from '../../../../models/billing/Billing';
import {
  ServiceDetailsData,
  AvailableServicesResponse,
} from '../../../../models/checkout/Checkout';

export const setSupplierAnswers = (
  supplierOptions: SupplierOptions[],
  planOptions: PlanOption[] | undefined,
  cardData?: ServiceDetailsData | null,
  cartItems?: AvailableServicesResponse[],
) => {
  // update suppiers questions answer object
  const featureDetails: FeatureDetails[] =
    cardData &&
    cardData.planDetails &&
    cardData.planDetails.featureDetails &&
    cardData.planDetails.featureDetails.length > 0
      ? cardData.planDetails.featureDetails
      : [];
  const allCartItemsFeatureDetails: FeatureDetails[] = cartItems
    ? getAllCartItemsFeatureDetails(cartItems)
    : [];

  let supplierAnswer = {};
  let updatedSupplierOptions: SupplierOptions[] = [];
  if (supplierOptions && supplierOptions.length > 0) {
    updatedSupplierOptions = supplierOptions.map((supplierOption: SupplierOptions) => {
      // first find pre-filled data from its submitted answers
      const preSelectedValue = featureDetails.find(
        (featureDetail) => featureDetail.labelId === get(supplierOption, 'Group', ''),
      );
      // if some common questions are present, then find from all submitted answers
      const preSelectedValueFromAllAnswers = allCartItemsFeatureDetails.find(
        (featureDetail) => featureDetail.labelId === get(supplierOption, 'Group', ''),
      );
      const featureDetail: FeatureDetails = {
        type: 'answers',
        label: supplierOption.PlanName || get(supplierOption, 'Heading', ''),
        labelId: get(supplierOption, 'Group', ''),
        value: preSelectedValue
          ? preSelectedValue.value
          : preSelectedValueFromAllAnswers
          ? preSelectedValueFromAllAnswers.value
          : '',
        valueId: preSelectedValue
          ? preSelectedValue.valueId
          : preSelectedValueFromAllAnswers
          ? preSelectedValueFromAllAnswers.valueId
          : '',
        isMandatory: true,
        componentType: get(supplierOption, 'ComponentType', ''),
        cost: preSelectedValue
          ? preSelectedValue.cost
          : preSelectedValueFromAllAnswers
          ? preSelectedValueFromAllAnswers.cost
          : null,
      };
      supplierAnswer = {
        ...supplierAnswer,
        [supplierOption.Group]: featureDetail,
      };
      return {
        ...supplierOption,
        Options: supplierOption.Options.map((option) => {
          const updateSupplierAnswer = (opt: SupplierOption) => {
            supplierAnswer = {
              ...supplierAnswer,
              [option.Option.Group]: getFeature(
                featureDetails,
                allCartItemsFeatureDetails,
                supplierAnswer,
                supplierOption,
                opt.Option,
              ),
            };
          };

          return getSelectedOptions(option, featureDetail.value || '', updateSupplierAnswer);
        }),
      };
    });
  }
  if (planOptions && planOptions.length > 0) {
    planOptions.map((planOption) => {
      const selectedValueFromAllAnswers =
        planOption.Options && planOption.Options.length > 1
          ? allCartItemsFeatureDetails.find(
              (featureDetail) => featureDetail.labelId === get(planOption, 'Group', ''),
            )
          : null;
      if (selectedValueFromAllAnswers) {
        supplierAnswer = {
          ...supplierAnswer,
          [planOption.Group]: {
            ...selectedValueFromAllAnswers,
            value: selectedValueFromAllAnswers.value,
            valueId: selectedValueFromAllAnswers.valueId,
          },
        };
      } else {
        planOption.Options &&
          planOption.Options.length > 1 &&
          planOption.Options.map((item) => {
            if (item.Default) {
              const optionAns: FeatureDetails = {
                type: 'first',
                label: item.PlanName || get(planOption, 'Heading', ''),
                labelId: get(planOption, 'Group', ''),
                value: item.Label,
                valueId: planOption.ProductId || item.Label,
                isMandatory: true,
                componentType: get(planOption, 'ComponentType', ''),
              };
              supplierAnswer = {
                ...supplierAnswer,
                [planOption.Group]: optionAns,
              };
            }
          });
      }
    });
  }
  return { supplierAnswer, updatedSupplierOptions };
};

export const getSelectedOptions = (
  option: SupplierOption,
  value: string,
  updateSupplierAnswerFn?: (option: SupplierOption) => void,
  checkConcessionFn?: () => void,
): SupplierOption => {
  if (option.Label.toLocaleLowerCase() === value.toLocaleLowerCase()) {
    if (option.Option && option.Option.Group && updateSupplierAnswerFn) {
      updateSupplierAnswerFn(option);
    }
    return {
      ...option,
      selected: true,
    };
  }
  if (
    option.Option &&
    option.Option.Options &&
    option.Option.Options.length &&
    option.Option.Group
  ) {
    let isSelected = false;
    return {
      ...option,
      Option: {
        ...option.Option,
        Options: option.Option.Options.map((opt) => {
          const result = getSelectedOptions(opt, value, updateSupplierAnswerFn, checkConcessionFn);
          if (result.selected) {
            isSelected = true;
          }
          return result;
        }),
      },
      selected: isSelected,
    };
  } else {
    if (checkConcessionFn) {
      checkConcessionFn();
    }
    return { ...option, selected: false };
  }
};

export const getFeature = (
  featureDetails: FeatureDetails[],
  allCartItemsFeatureDetails: FeatureDetails[],
  newFeatureDetails: {},
  parentOpts: SupplierOptions,
  opt: SupplierOptions,
) => {
  const innerSelectedValue = featureDetails.find(
    (featureDetail) => featureDetail.labelId === get(opt, 'Group', ''),
  );
  // if some common questions are present, then find from all submitted answers
  const innerSelectedValueFromAllAnswers = allCartItemsFeatureDetails.find(
    (featureDetail) => featureDetail.labelId === get(opt, 'Group', ''),
  );
  const result: FeatureDetails =
    parentOpts.Group === opt.Group
      ? {
          ...newFeatureDetails[parentOpts.Group],
          value: innerSelectedValue
            ? innerSelectedValue.value
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.value
            : '',
          valueId: innerSelectedValue
            ? innerSelectedValue.valueId
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.valueId
            : '',
          isMandatory: get(opt, 'ComponentIsMandatory', false),
          componentType: get(opt, 'ComponentType', ''),
          parentGroup: parentOpts.Group,
          cost: innerSelectedValue
            ? innerSelectedValue.cost
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.cost
            : null,
        }
      : {
          type: 'answers',
          label: opt.PlanName || get(opt, 'Heading', ''),
          labelId: get(opt, 'Group', ''),
          value: innerSelectedValue
            ? innerSelectedValue.value
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.value
            : '',
          valueId: innerSelectedValue
            ? innerSelectedValue.valueId
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.valueId
            : '',
          isMandatory: get(opt, 'ComponentIsMandatory', false),
          parentGroup: parentOpts.Group,
          componentType: get(opt, 'ComponentType', ''),
          cost: innerSelectedValue
            ? innerSelectedValue.cost
            : innerSelectedValueFromAllAnswers
            ? innerSelectedValueFromAllAnswers.cost
            : null,
        };
  return result;
};

export const getAllCartItemsFeatureDetails = (cartItems: AvailableServicesResponse[]) => {
  let featureDetails: FeatureDetails[] = [];

  cartItems.forEach((cartItem: AvailableServicesResponse) => {
    const cardData = cartItem.suppliers![0].plans[0].cartData;
    if (
      cardData &&
      cardData.planDetails &&
      cardData.planDetails.featureDetails &&
      cardData.planDetails.featureDetails.length > 0
    ) {
      featureDetails = [...featureDetails, ...cardData.planDetails.featureDetails];
    }
  });
  return featureDetails;
};

export const getDefaultValue = (optionsAns: Map<string, FeatureDetails> | {}, group: string) => {
  if (optionsAns[group]) {
    return (optionsAns[group] as FeatureDetails).value || '';
  }
  return '';
};

export const getTermsAndConditionsValue = (
  cardData?: ServiceDetailsData | null,
  preAgreementSummary?: PreAgreementSummary,
) => {
  const isTermsAndConditionsAccepted =
    cardData && cardData.planDetails && cardData.acceptTermAndCondition;
  const defaultAcceptedChecks: number[] = [];
  isTermsAndConditionsAccepted &&
    preAgreementSummary &&
    preAgreementSummary.AcceptanceChecks &&
    preAgreementSummary.AcceptanceChecks.length > 0 &&
    preAgreementSummary.AcceptanceChecks.forEach((value, index) => {
      defaultAcceptedChecks.push(index);
    });
  return { isTermsAndConditionsAccepted: !!isTermsAndConditionsAccepted, defaultAcceptedChecks };
};

export interface GetProductDetails {
  productOptions: Options | {};
  extendedDataPlan: PlanDetailObject | {};
}
export const getProductDetails = (extendedData: ProviderPlan, productId?: string) => {
  let productOptions: Options | {} = {};
  let extendedDataPlan = {};
  if (productId) {
    extendedData.Plans.forEach((plan: PlanDetailObject) => {
      plan.Options.forEach((option: PlanOption) => {
        if (option.Type === 'Product') {
          option.Options.forEach((product: Options) => {
            if (product.ProductId.toLocaleLowerCase() === productId.toLocaleLowerCase()) {
              extendedDataPlan = plan;
              productOptions = product;
            }
          });
        }
      });
    });
  }

  return {
    productOptions,
    extendedDataPlan,
  };
};

export const getPreAgreementSummary = (
  preAgreementSummary: PreAgreementSummary,
  selectedPlan: PlanDetailObject,
) => {
  if (selectedPlan && selectedPlan.Links && selectedPlan.Links.length > 0) {
    const planLinks = selectedPlan.Links;
    const updatedSummary: PreAgreementSummary = {
      ...preAgreementSummary,
      LinkItems: preAgreementSummary.LinkItems.map((linkItem) => {
        const findLinkItemIndex = planLinks.findIndex((item) => item.Name === linkItem.Name);
        if (findLinkItemIndex !== -1) {
          return planLinks[findLinkItemIndex];
        } else {
          return linkItem;
        }
      }),
    };
    return updatedSummary;
  } else {
    return preAgreementSummary;
  }
};
