export const LABELS = {
  TITLE: '',
  ACCOUNT_DETAILS_TITLE: 'Account Details',
  ACCOUNT_HOLDER: 'ACCOUNT HOLDER',
  ACCOUNT_NAME: 'ACCOUNT NAME',
  ACCOUNT_NUMBER: 'ACCOUNT NUMBER',
  REFERENCE_NUMBER: 'REFERENCE NUMBER',
  PHONE: 'PHONE',
  DATE_REQUESTED: 'DATE REQUESTED',
  SUPPLIER_DETAILS_TITLE: 'Supplier Details',
  CALL: 'CALL',
  EMAIL: 'EMAIL',
  WEB: 'WEB',
  EDIT: 'Edit',
  TENANCY_BOND: 'BOND',
  TENANCY_FROM_DATE: 'FROM DATE',
  TENANCY_TO_DATE: 'TO DATE',
  DOCUMENTS_SECTION: 'Rent documents',
  PAYMENTS: 'PAYMENTS',
  NEXT_PAYMEMT_DATE: 'NEXT PAYMEMT DATE',
  BANK_ACCOUNT: 'BANK ACCOUNT',
  CREDIT_CARD: 'CREDIT CARD',
  NOTAVAILABLE: 'PAYMENT METHOD',
  ADDPAYMENTLABEL: 'Add a payment method',
  MODAL_PRIMARY_TEXT:
    'Sorry, this feature is currently under development and is temporarily disabled while we make improvements.',
  MODAL_SECONDARY_TEXT:
    'You can still adjust your payment by chatting to one of our helpful team members. Would you like to chat now?',
  RANDOMVALUE: 'random-value',
};
