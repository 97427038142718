import { PersonalDetails } from '../../models/checkout/Checkout';

export const mockPersonalDetails: PersonalDetails = {
  id: 30913,
  userType: 'string',
  name: 'Solar',
  surname: 'Test',
  email: 'solar1@mail.com',
  phone: '+61422200327',
  salutation: 'Mr.',
  dob: '24/08/1988',
  idType: 'DriversLicence',
  idNumber: '12568768',
  address: null,
  idExpiry: '24/08/2021',
  active: true,
  branches: [],
  idDetails: {
    dlStateIssue: 'VIC',
    passportCountry: null,
    medicareCardColor: null,
    medicareRefNumber: null,
  },
};
