import React, { FC, useEffect, useState, useMemo, ReactNode } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { theme } from '../../theme/Theme';
import { TextSize, TextVariant } from './TextConstants';
import { useStyles } from './TextStyles';

interface TextProps {
  textVariant?: TextVariant;
  size?: TextSize;
  parentStyles?: string;
  children: any;
  onPress?: () => void;
}

const Text: FC<TextProps> = ({ size, textVariant, children, parentStyles, onPress }) => {
  const classes = useStyles();
  const textVariantStyle = () => {
    switch (textVariant) {
      case 'title':
        return classNameGenerator([classes.text, classes.title]);
      case 'boxTitle':
        return classNameGenerator([classes.text, classes.boxTitle]);
      case 'contentTitle':
        return classNameGenerator([classes.text, classes.contentTitle]);
      case 'subtitle':
        return classNameGenerator([classes.text, classes.subtitle]);
      case 'subtitle2':
        return classNameGenerator([classes.text, classes.subtitle2]);
      case 'body':
        return classNameGenerator([classes.text, classes.body]);
      case 'error':
        return classNameGenerator([classes.text, classes.error]);
      case 'info':
        return classNameGenerator([classes.text, classes.grey]);
      case 'link':
        return classNameGenerator([classes.text, classes.link]);
      default:
        return classes.text;
    }
  };
  return (
    <div
      className={classNameGenerator([textVariantStyle(), parentStyles])}
      style={size && { fontSize: theme.font[size] }}
      onClick={onPress}
    >
      {children}
    </div>
  );
};

export default Text;
