/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    mainteanceImagesContainer: {
      marginTop: 12,
      display: 'flex',
    },
    maintenanceImage: {
      borderRadius: 8,
      marginRight: 12,
      width: 65,
      height: 65,
      overflow: 'hidden',
      objectFit: 'cover',
      objectPosition: 'center',
      backgroundColor: theme.colors.borderColorLight,
      cursor: 'pointer',
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 12,
      padding: 12,
    },
    downloadIcon: {
      width: 22,
      height: 22,
      resizeMode: 'contain',
    },
    downloadText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: 16,
    },
    modalView: {
      position: 'relative',
      borderRadius: 6,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'black',
      height: 600,
      width: '100%',
      minWidth: 400,
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 40,
    },
    modalImage: {
      width: '100%',
      height: '100%',
      maxHeight: 500,
      resizeMode: 'contain',
      overflow: 'hidden',
    },
    downloadModalContainer: {
      position: 'absolute',
      bottom: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 12,
    },
    downloadWhiteIcon: {
      width: 22,
      height: 22,
      filter: 'invert(100%)',
      objectFit: 'contain',
    },
    downloadWhiteText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: 12,
    },
    closeIconContainer: {
      position: 'absolute',
      top: 12,
      right: 12,
    },
    closeIcon: {
      width: 24,
      height: 24,
      filter: 'invert(100%)',
      objectFit: 'contain',
      cursor: 'pointer',
    },
    noAttachmentsText: {
      fontSize: 14,
      fontWeight: getFonts('Light'),
      color: theme.colors.black60,
      marginTop: 12,
    },
    imageContainer: {
      width: 65,
      marginRight: 12,
    },
    imageViewContainer: {
      height: 65,
      position: 'relative',
    },
    contentViewStyle: {
      position: 'absolute',
    },
    loaderStyle: {
      width: 65,
      height: 65,
      borderRadius: 8,
    },
  })();
};
