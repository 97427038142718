import home from '../../assets/navigation/home.png';
import services from '../../assets/navigation/services.png';
import profile from '../../assets/navigation/profile.png';
import { routes } from '../../Routes';
import { Route } from '../../navigation/NavigationUtils';
import { SIZES } from '../../constants/Sizes';

export const LABELS = {
  YOU: 'You',
};

export interface LinkItem {
  name: string;
  route: Route;
  routes: Route[];
  icon: string;
}

export const LINKS: LinkItem[] = [
  {
    name: 'Home',
    route: 'home',
    routes: [
      'home',
      'tenantRequestInfo',
      'maintenance',
      'maintenanceSuccessScreen',
      'propertyInfo',
      'properties',
      'propertyRent',
      'genericServiceSummaryScreen',
      'serviceSummary',
      'otherServicesSummary',
      'planDetails',
    ],
    icon: home,
  },
  {
    name: 'Marketplace',
    route: 'browse',
    routes: [
      'browse',
      'offerScreen',
      'essentialsScreen',
      'protectScreen',
      'maintainScreen',
      'optimiseScreen',
      'checkoutSelect',
      'checkoutConfigure',
      'checkoutPersonalDetails',
      'checkoutFinalize',
      'checkoutSummary',
      'checkoutCompleteCart',
    ],
    icon: services,
  },
  {
    name: 'Profile',
    route: 'account',
    routes: ['account', 'viewPaymentMethods', 'viewBank', 'viewCard'],
    icon: profile,
  },
];

export const showUrl: Route[] = [
  'home',
  'browse',
  'account',
  'tenantRequestInfo',
  'maintenance',
  'maintenanceSuccessScreen',
  'propertyInfo',
  'properties',
  'propertyRent',
  'genericServiceSummaryScreen',
  'serviceSummary',
  'otherServicesSummary',
  'support',
  'planDetails',
  'offerScreen',
  'essentialsScreen',
  'protectScreen',
  'maintainScreen',
  'optimiseScreen',
  'suppliersScreen',
  'checkoutSelect',
  'checkoutConfigure',
  'checkoutPersonalDetails',
  'checkoutFinalize',
  'checkoutSummary',
  'checkoutCompleteCart',
  'propertyOnboarding',
  'viewPaymentMethods',
  'viewBank',
  'viewCard',
];

export const collapsedUrl = ['propertyOnboarding'];

export const hideMenuMobileUrl = [
  'checkoutSelect',
  'checkoutConfigure',
  'checkoutSummary',
  'checkoutPersonalDetails',
  'checkoutFinalize',
  'checkoutSummary',
  'checkoutAbandonCart',
  'checkoutCompleteCart',
];

export const intercomReposition = (
  showMenu: boolean,
  checkoutFooter?: boolean,
  cartOpen?: boolean,
) => {
  if (cartOpen) {
    document
      .querySelectorAll<HTMLElement>('.intercom-lightweight-app-launcher')
      .forEach((element) => {
        element.style.display = 'none';
      });
    document.querySelectorAll<HTMLElement>('.intercom-launcher-frame').forEach((element) => {
      element.style.display = 'none';
    });
    document.querySelectorAll<HTMLElement>('.intercom-messenger-frame').forEach((element) => {
      element.style.display = 'none';
    });
    return;
  }
  if (checkoutFooter) {
    window.intercomSettings = {
      ...window.intercomSettings,
      vertical_padding: 80,
    };
    document
      .querySelectorAll<HTMLElement>('.intercom-lightweight-app-launcher')
      .forEach((element) => {
        element.style.bottom = `80px`;
        element.style.display = 'block';
      });
    document.querySelectorAll<HTMLElement>('.intercom-launcher-frame').forEach((element) => {
      element.style.bottom = `80px`;
      element.style.display = 'block';
    });
    document.querySelectorAll<HTMLElement>('.intercom-messenger-frame').forEach((element) => {
      element.style.bottom = `160px`;
      element.style.display = 'block';
    });
  } else {
    const isMobileSize = window.innerWidth <= SIZES.sm;

    // This handles onMount
    window.intercomSettings = {
      ...window.intercomSettings,
      vertical_padding: isMobileSize && showMenu ? 130 : 20,
    };

    // This handles resizing (doesn't work onMount, hence above)
    document
      .querySelectorAll<HTMLElement>('.intercom-lightweight-app-launcher')
      .forEach((element) => {
        element.style.bottom = `${isMobileSize && showMenu ? '130px' : '20px'}`;
        element.style.display = 'block';
      });
    document.querySelectorAll<HTMLElement>('.intercom-launcher-frame').forEach((element) => {
      element.style.bottom = `${isMobileSize && showMenu ? '130px' : '20px'}`;
      element.style.display = 'block';
    });
    document.querySelectorAll<HTMLElement>('.intercom-messenger-frame').forEach((element) => {
      element.style.bottom = `${isMobileSize && showMenu ? '210px' : '100px'}`;
      element.style.display = 'block';
    });
  }
};
