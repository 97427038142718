import React, { FC, useEffect, useState, useMemo, useRef, ChangeEvent } from 'react';
import { useStyles } from './AddPhotoStyles';
import defaultImage from '../../../../assets/defaultMaintenanceIcon.jpg';
import cross from '../../../../assets/navigation/close.png';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { JobDetailResponse } from '../../../../models/maintenance/maintenance';
import { FormData, MAX_IMAGE_FILE_SIZE, MAX_VIDEO_FILE_SIZE } from '../../MaintenanceConstants';
import { isUndefined, isNil } from 'lodash';
import { compressIfImage } from '../../../../helpers/FileUploadHelper';
import { ContentTypeHelper } from '../../../../helpers/Content/ContentType.data';
import { Content, FileLink } from '../../../../services/MultipartFormData.data';
import { ToastMessage } from '../../../../helpers/ToastMessage';

interface AddPhotoProps {
  index: number;
  field: string;
  values: FormData;
  type: string;
  maintenanceSummary?: JobDetailResponse | undefined;
  setFieldValue: (key: string, value: any) => void;
}

const AddPhoto: FC<AddPhotoProps> = ({
  index,
  field,
  values,
  type,
  maintenanceSummary,
  setFieldValue,
}) => {
  const classes = useStyles();
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const maintenance = isUndefined(maintenanceSummary);
  const currentItem = values[field][index];
  const img =
    maintenance && currentItem
      ? (currentItem as FileLink).link || (currentItem as Content).dataBase64!
      : defaultImage;
  const isPhoto = type === 'photo';
  const maxFileSize = isPhoto ? MAX_IMAGE_FILE_SIZE : MAX_VIDEO_FILE_SIZE;
  const imagePickerText = currentItem
    ? isPhoto
      ? `Image${index + 1}`
      : `Video`
    : isPhoto
    ? 'Add photo'
    : 'Add Video';

  const removeCapturedImage = () => {
    const images = [...values[field]];
    images.splice(index, 1);
    setFieldValue(field, images);
    fileUploadRef.current!.value = '';
  };

  const handleFileSelect = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const originalFiles = Array.from(files!);
    const compressedFiles = await Promise.all(
      originalFiles.map((file) => {
        if (file.type.startsWith('video/')) {
          return file;
        } else {
          return compressIfImage(file);
        }
      }),
    );

    console.log('size', compressedFiles[0].size);
    if (compressedFiles[0].size > maxFileSize * 1024 * 1024) {
      ToastMessage.showMessage(
        `File size too big, please limit your files to ${maxFileSize}mb`,
        '',
        'danger',
        5000,
      );
      return;
    }
    const ctype = ContentTypeHelper.parseContentType(compressedFiles[0].type);
    setFieldValue(field, [
      ...values[field],
      {
        contentRef: { contentType: ctype!, name: compressedFiles[0].name },
        size: compressedFiles[0].size,
        data: compressedFiles[0],
        dataBase64: await ContentTypeHelper.convertBlobToBase64(compressedFiles[0]),
      },
    ]);
  };

  return (
    <>
      <div
        className={classes.photoRow}
        onMouseDown={() => {
          if (!!values[field][index]) {
            removeCapturedImage();
          } else {
            fileUploadRef.current!.click();
          }
        }}
      >
        {isPhoto ? (
          <img src={img} className={classes.photo} />
        ) : (
          <video
            className={classes.photo}
            src={!!currentItem ? URL.createObjectURL(currentItem.data) : ''}
          />
        )}
        <div className={classes.photoContent}>
          <div className={classes.photoText}>{imagePickerText}</div>
          <img
            src={cross}
            className={classNameGenerator([
              classes.photoAction,
              !!values[field][index] && classes.photoActionRotate,
            ])}
            alt="action"
          />
        </div>
      </div>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: 'none' }}
        accept={isPhoto ? '.jpg, .jpeg, .pdf, .png' : '.mp4, .m4v, .mov, .webm'}
        onChange={handleFileSelect}
      />
    </>
  );
};
export default AddPhoto;
