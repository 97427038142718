import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './RentOnboardingWelcomeStyles';
import {
  LABELS,
  StepItem,
  bondPaymentEnabledSteps,
  naxWelcomeSteps,
  paymentDisabledSteps,
  paymentEnabledSteps,
  taWelcomeSteps,
} from './RentOnboardingWelcomeConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { ThemeKey } from '../../theme/Theme';
import { AvailableServicesRequest } from '../../models/checkout/Checkout';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { GetRentOnboardingResponse } from '../../models/rentOnboarding/rentOnboarding';
import { RentOnboardingActions } from '../../store/actions/RentOnboarding';
import { useGetLeaseData } from '../../helpers/RentServiceHelper';
import SORTED from '../../assets/logosCircle/sorted.png';
import Button from '../../components/button/Button';

interface RentOnboardingWelcomeProps {
  theme: ThemeKey;
  accessToken: string;
  getRentService: (onSuccess: (response: GetRentOnboardingResponse[]) => void) => void;
  getAvailableServices: (data: AvailableServicesRequest) => void;
}

const RentOnboardingWelcome: FC<RentOnboardingWelcomeProps> = ({
  theme,
  accessToken,
  getRentService,
  getAvailableServices,
}) => {
  const classes = useStyles();
  const leaseObj = useGetLeaseData();
  const lease = {
    initialPayments: leaseObj && leaseObj.initialPayments,
    paymentEnabled: leaseObj && leaseObj.paymentEnabled,
    lease: leaseObj,
  };
  const stepList =
    theme === ThemeKey.SORTED
      ? lease.initialPayments && lease.initialPayments.length
        ? bondPaymentEnabledSteps
        : lease.paymentEnabled
        ? paymentEnabledSteps
        : paymentDisabledSteps
      : theme === ThemeKey.NAX
      ? naxWelcomeSteps
      : taWelcomeSteps;

  useEffect(() => {
    if (accessToken && leaseObj === undefined) {
      getRentService((response) => null);
    }
  }, [accessToken]);

  useEffect(() => {
    if (leaseObj) {
      getAvailableServices({
        propertyId: `${leaseObj.property!.id}`,
        hideLoading: true,
        requiredServiceTypes: [],
      });
    }
  }, [leaseObj]);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE(theme)}</div>

            <div className={classes.agencyContainer}>
              <img
                className={classes.agencyLogo}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = SORTED!;
                }}
                src={
                  lease.lease && !!lease.lease.agency.logoUrl ? lease.lease.agency.logoUrl : SORTED
                }
              />
              <div className={classes.messageContainer}>
                <div className={classes.messageTitle}>
                  {lease.lease ? lease.lease.agency.tradingName : LABELS.WELCOME(theme)}
                </div>
                <div className={classes.messageSubtitle}>
                  {lease.lease ? LABELS.WELCOMES(theme) : LABELS.LIFE_MADE}
                </div>
              </div>
            </div>

            <div className={classes.stepContainerStyle}>
              {stepList.map((step: StepItem, index: number) => (
                <div className={classes.listItem} key={index}>
                  <img className={classes.listIcon} src={step.logo} />
                  <div className={classes.listInfoBox}>
                    <div className={classes.listTitle}>{step.title}</div>
                    <div className={classes.listMessage}>{step.message}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.buttonContainer}>
              <Button onPress={() => navigate('rentConfirmation')} parentStyles={classes.button}>
                {LABELS.BUTTON_TEXT}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  theme: state.themeState.themeKey,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
  getRentService: (onSuccess: (response: GetRentOnboardingResponse[]) => void) => {
    dispatch(RentOnboardingActions.getRentOnboardingRequest({ onSuccess }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RentOnboardingWelcome);
