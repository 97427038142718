import { StoreAction } from '../StoreHelper';
import {
  CimetNeedsRequest,
  CimetNeedsResponse,
  CimetServicesRequest,
  CimetPropertyIdServiceResponse,
  CimetVisitRequest,
  CimetDatesResponse,
  CimetDatesRequest,
} from '../../models/cimet/Cimet';

export enum CimetActionTypes {
  GET_CIMET_SERVICES = 'GET_CIMET_SERVICES',
  GET_CIMET_SERVICES_REQUEST = 'GET_CIMET_SERVICES_REQUEST',
  GET_CIMET_SERVICES_SUCCESS = 'GET_CIMET_SERVICES_SUCCESS',
  GET_CIMET_SERVICES_ERROR = 'GET_CIMET_SERVICES_ERROR',

  RESET_CIMET_SERVICES = 'RESET_CIMET_SERVICES',

  POST_CIMET_VISIT = 'POST_CIMET_VISIT',
  POST_CIMET_VISIT_REQUEST = 'POST_CIMET_VISIT_REQUEST',
  POST_CIMET_VISIT_SUCCESS = 'POST_CIMET_VISIT_SUCCESS',
  POST_CIMET_VISIT_ERROR = 'POST_CIMET_VISIT_ERROR',

  GET_CIMET_DATES = 'GET_CIMET_DATES',
  GET_CIMET_DATES_REQUEST = 'GET_CIMET_DATES_REQUEST',
  GET_CIMET_DATES_SUCCESS = 'GET_CIMET_DATES_SUCCESS',
  GET_CIMET_DATES_ERROR = 'GET_CIMET_DATES_ERROR',

  POST_CIMET_NEEDS = 'POST_CIMET_NEEDS',
  POST_CIMET_NEEDS_REQUEST = 'POST_CIMET_NEEDS_REQUEST',
  POST_CIMET_NEEDS_SUCCESS = 'POST_CIMET_NEEDS_SUCCESS',
  POST_CIMET_NEEDS_ERROR = 'POST_CIMET_NEEDS_ERROR',

  RESET_CIMET_URL = 'RESET_CIMET_URL',
}

export type CimetActionPayload =
  | CimetServicesRequest
  | CimetPropertyIdServiceResponse
  | CimetVisitRequest
  | CimetNeedsRequest
  | CimetNeedsResponse
  | CimetDatesRequest
  | CimetDatesResponse[]
  | string
  | Error
  | null;

export type CimetAction = StoreAction<CimetActionTypes, CimetActionPayload>;

export class CimetActions {
  public static getCimetServicesRequest(data: CimetServicesRequest) {
    return { type: CimetActionTypes.GET_CIMET_SERVICES_REQUEST, data };
  }
  public static getCimetServicesSuccess(data: CimetPropertyIdServiceResponse) {
    return { type: CimetActionTypes.GET_CIMET_SERVICES_SUCCESS, data };
  }
  public static getCimetServicesError(data: Error) {
    return { type: CimetActionTypes.GET_CIMET_SERVICES_ERROR, data };
  }

  public static postCimetVisitRequest(data: CimetVisitRequest) {
    return { type: CimetActionTypes.POST_CIMET_VISIT_REQUEST, data };
  }
  public static postCimetVisitSuccess(data: null) {
    return { type: CimetActionTypes.POST_CIMET_VISIT_SUCCESS, data };
  }
  public static postCimetVisitError(data: Error) {
    return { type: CimetActionTypes.POST_CIMET_VISIT_ERROR, data };
  }

  public static postCimetNeedsRequest(data: CimetNeedsRequest) {
    return { type: CimetActionTypes.POST_CIMET_NEEDS_REQUEST, data };
  }
  public static postCimetNeedsSuccess(data: CimetNeedsResponse) {
    return { type: CimetActionTypes.POST_CIMET_NEEDS_SUCCESS, data };
  }
  public static postCimetNeedsError(data: Error) {
    return { type: CimetActionTypes.POST_CIMET_NEEDS_ERROR, data };
  }

  public static getCimetDatesRequest(data: CimetDatesRequest) {
    return { type: CimetActionTypes.GET_CIMET_DATES_REQUEST, data };
  }
  public static getCimetDatesSuccess(data: CimetDatesResponse[]) {
    return { type: CimetActionTypes.GET_CIMET_DATES_SUCCESS, data };
  }
  public static getCimetDatesError(data: Error) {
    return { type: CimetActionTypes.GET_CIMET_DATES_ERROR, data };
  }

  public static resetCimetURL() {
    return { type: CimetActionTypes.RESET_CIMET_URL, data: null };
  }

  public static resetCimetServices() {
    return { type: CimetActionTypes.RESET_CIMET_SERVICES, data: null };
  }
}
