import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ProtectScreenStyles';
import { LABELS, Tab, initialTags } from './ProtectScreenConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { findExtraServiceInfo, useDashboardServices } from '../../helpers/ServiceInfoHelper';
import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { areAllTabsOff, isTabActive } from '../../components/tagBar/TagBarUtils';
import TagBar from '../../components/tagBar/TagBar';
import Button from '../../components/button/Button';
import Box from '../../components/box/Box';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';
import Supplier from '../../components/supplier/Supplier';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { DashboardState } from '../../store/state/DashboardState';

interface ProtectScreenProps {
  dashboardState: DashboardState;
}

const ProtectScreen: FC<ProtectScreenProps> = ({
  dashboardState: { onboardedProperties, selectedProperty },
}) => {
  const classes = useStyles();
  const { property, otherServices, newServices } = useDashboardServices();
  const [tags, setTags] = useState<TagBarItem[]>(initialTags(otherServices));

  useEffect(() => {
    setTags(initialTags(otherServices));
  }, [property]);

  const allTagsOff = useMemo<boolean>(() => {
    return areAllTabsOff(tags);
  }, [tags]);

  const renderServiceType = (service: Services) => {
    const suppliers = findExtraServiceInfo(
      service,
      onboardedProperties![selectedProperty].availableServices,
    ).Suppliers;
    return (
      <div className={classes.suppliersRoot}>
        <div className={classes.suppliersTitle}>{service.serviceType}</div>
        {suppliers.map((provider, index) => (
          <Supplier
            service={service}
            suppliers={suppliers}
            provider={provider}
            index={index}
            onboardedProperties={onboardedProperties}
            selectedProperty={selectedProperty}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="title" size="xxl" parentStyles={classes.title}>
              {LABELS.TITLE}
            </Text>
            {tags.length ? (
              <>
                <TagBar tags={tags} handlePress={setTags} togglable />
                {!!otherServices.length && (
                  <Box lightBorder parentStyles={classes.box}>
                    {(isTabActive(tags, Tab.Insurance) || allTagsOff) &&
                      filterServicesByStatus(onboardingScreenValidStates, otherServices, [
                        ServiceTypes.Insurance,
                      ]).map((service) => <>{renderServiceType(service)}</>)}
                    {(isTabActive(tags, Tab.Security) || allTagsOff) &&
                      filterServicesByStatus(onboardingScreenValidStates, otherServices, [
                        ServiceTypes.Security,
                      ]).map((service) => <>{renderServiceType(service)}</>)}
                  </Box>
                )}
              </>
            ) : (
              <div className={classes.noServicesContainer}>
                <div className={classes.sorryText}>{LABELS.SORRY}</div>
                <Button onPress={() => navigateBack()}>{LABELS.BACK}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectScreen);
