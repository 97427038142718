/* eslint-disable import/prefer-default-export */
export const LABELS = {
  TITLE: 'Add your property',
  DESCRIPTION: 'To start connecting your home, you must add a property',
  PRIMARY_BUTTON: 'Get started',
  SECONDARY_BUTTON: "I'm just browsing",
  DESCRIPTION_NAX: 'To start connecting your home, you must be approved for a property.',
  LOGIN_TITLE: 'Please log in to continue',
  LOGIN: 'Log in',
  LOGIN_DESCRIPTION: 'To start connecting your home, you must create an account or log in',
  SIGNUP: 'Sign up',
};
