import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1000,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    secondaryContainer: {
      flex: 1,
      flexShrink: 0,
      maxWidth: 480,
      minWidth: 400,
      marginLeft: 48,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        display: 'none',
      },
    },
    title: {
      color: theme.colors.title,
      fontSize: 22,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: theme.units.spacing * 6,
    },
    disabledTitle: {
      color: theme.colors.black38,
      fontSize: 22,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: theme.units.spacing * 6,
      marginTop: theme.units.spacing * 6,
    },
    section: {
      marginBottom: theme.units.spacing * 12,
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    summaryTitle: {
      color: theme.colors.title,
      fontSize: 22,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      margin: theme.units.spacing * 4,
      marginTop: theme.units.spacing * 6,
      paddingBottom: theme.units.spacing * 4,
    },
    summaryContainer: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    serviceTabs: {
      display: 'none',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        display: 'block',
      },
    },
    cards: {
      paddingLeft: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 4,
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
    },
  })();
};
