import { store } from '../store/Store';
import { ToastActions } from '../store/actions/ToastActions';

export class ToastMessage {
  public static showMessage(
    message: string,
    buttonText?: string,
    type?: 'danger' | 'success' | 'warning',
    duration?: number,
    delayLaunch?: boolean | number,
  ) {
    if (delayLaunch) {
      setTimeout(
        () => {
          triggerToast(message, buttonText, type, duration);
        },
        delayLaunch === true ? 500 : delayLaunch,
      );
    } else {
      triggerToast(message, buttonText, type, duration);
    }
  }
}

const triggerToast = (
  message: string,
  buttonText?: string,
  type?: 'danger' | 'success' | 'warning',
  duration?: number,
) => {
  store.dispatch(
    ToastActions.showToast({
      showToast: true,
      message,
      type,
      buttonText,
      duration,
    }),
  );
  setTimeout(() => {
    store.dispatch(ToastActions.hideToast());
  }, duration || 3000);
};
