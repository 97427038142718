import React, { FC, useEffect, useState, useRef } from 'react';
import {
  IdDetails,
  PersonalDetails,
  PersonalDetailsFormInitialValues,
} from '../../../../models/checkout/Checkout';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isPersonalDetailsCompleted } from './AccountSectionUtils';
import moment from 'moment';
import { ApplicationState } from '../../../../store/RootReducer';
import { useFormik } from 'formik';
import {
  COUNTRY_CODES,
  ID_TYPES,
  LABELS,
  MEDICARE_CARD_COLOR,
  MINIMUM_AGE,
  REFERENCE_NUMBER,
  STATE_OF_ISSUE,
  TITLES,
} from './AccountSectionConstants';
import Input from '../../../../components/input/Input';
import InputSelect from '../../../../components/inputSelect/InputSelect';
import InputMobile from '../../../../components/inputMobile/InputMobile';
import InputDate from '../../../../components/inputDate/InputDate';
import { useStyles } from './AccountSectionStyles';

interface AccountSectionProps {
  personalDetails: PersonalDetails;
  setPersonalDetails: (personalDetails: PersonalDetails) => void;
  validate: boolean;
}

const AccountSection: FC<AccountSectionProps> = ({
  personalDetails,
  setPersonalDetails,
  validate,
}) => {
  const classes = useStyles();

  const updatePersonalDetails = (updatedDetails: PersonalDetailsFormInitialValues) => {
    const idDetails: IdDetails = {
      dlStateIssue: updatedDetails.stateIssue,
      medicareRefNumber: updatedDetails.referenceNumber,
      medicareCardColor: updatedDetails.medicareCardColor,
      passportCountry: updatedDetails.countryOfIssue,
    };
    const updatedPersonalDetails = {
      ...personalDetails,
      name: updatedDetails.firstName,
      surname: updatedDetails.lastName,
      email: updatedDetails.email,
      phone: updatedDetails.mobile,
      salutation: updatedDetails.title,
      dob: updatedDetails.dob ? moment(updatedDetails.dob as Date).format('DD/MM/YYYY') : null,
      idType: updatedDetails.idType,
      idNumber: updatedDetails.idNumber,
      idExpiry: updatedDetails.idExpiry
        ? moment(updatedDetails.idExpiry as Date).format('DD/MM/YYYY')
        : null,
      idDetails,
    };
    setPersonalDetails(updatedPersonalDetails);
  };

  const formik = useFormik({
    initialValues: {
      email: personalDetails.email || '',
      title: personalDetails.salutation || '',
      firstName: personalDetails.name || '',
      lastName: personalDetails.surname || '',
      mobile: personalDetails.phone ? personalDetails.phone.replace(/ /g, '') : '',
      dob: personalDetails.dob
        ? moment(personalDetails.dob as string, 'dd/MM/yyyy').toDate()
        : null,
      expiry: personalDetails.idExpiry
        ? moment(personalDetails.idExpiry as string, 'dd/MM/yyyy').toDate()
        : null,
      idExpiry: personalDetails.idExpiry
        ? moment(personalDetails.idExpiry as string, 'dd/MM/yyyy').toDate()
        : null,
      idNumber: personalDetails.idNumber || '',
      idType: personalDetails.idType || '',
      stateIssue: personalDetails.idDetails ? personalDetails.idDetails.dlStateIssue : null,
      medicareCardColor: personalDetails.idDetails
        ? personalDetails.idDetails.medicareCardColor
        : null,
      countryOfIssue: personalDetails.idDetails ? personalDetails.idDetails.passportCountry : null,
      referenceNumber: personalDetails.idDetails
        ? personalDetails.idDetails.medicareRefNumber
        : null,
    },
    onSubmit: (values) => {},
  });

  const expiryDate = new Date();
  expiryDate.setFullYear(expiryDate.getFullYear() + (2050 - expiryDate.getFullYear()));
  const expiryMinDate = new Date();
  expiryMinDate.setMonth(expiryMinDate.getMonth() - 3);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - MINIMUM_AGE);

  return (
    <div>
      <div className={classes.titleColumn}>
        <InputSelect
          placeholder={LABELS.TITLE}
          value={formik.values.title}
          values={Object.values(TITLES).map((v) => ({
            display: v.name,
            value: v.value,
          }))}
          title={LABELS.TITLE}
          error={formik.errors.title}
          touched={formik.touched.title}
          setValue={(value: string) => {
            formik.setFieldValue('title', value);
            updatePersonalDetails({
              ...formik.values,
              title: value,
            });
          }}
          onBlur={() => formik.setFieldTouched('title')}
          mandatory
          big
        />
        <InputDate
          placeholder={LABELS.DOB}
          value={formik.values.dob}
          setValue={(newValue: string) => {
            formik.setFieldValue('dob', newValue);
            updatePersonalDetails({
              ...formik.values,
              dob: newValue,
            });
          }}
          onBlur={() => formik.setFieldTouched('dob')}
          touched={formik.touched.dob}
          error={formik.errors.dob}
          title={LABELS.DOB}
          maxDate={maxDate}
          mandatory
          blockedDates={[]}
          big
        />
      </div>
      <div className={classes.twoColumn}>
        <Input
          placeholder={LABELS.FIRST_NAME}
          value={formik.values.firstName}
          setValue={(value: string) => {
            formik.setFieldValue('firstName', value);
            updatePersonalDetails({
              ...formik.values,
              firstName: value,
            });
          }}
          onBlur={() => formik.setFieldTouched('firstName')}
          touched={formik.touched.firstName}
          error={formik.errors.firstName}
          title={LABELS.FIRST_NAME}
          mandatory
          big
        />
        <Input
          placeholder={LABELS.LAST_NAME}
          value={formik.values.lastName}
          setValue={(value: string) => {
            formik.setFieldValue('lastName', value);
            updatePersonalDetails({
              ...formik.values,
              lastName: value,
            });
          }}
          onBlur={() => formik.setFieldTouched('lastName')}
          touched={formik.touched.lastName}
          error={formik.errors.lastName}
          title={LABELS.LAST_NAME}
          mandatory
          big
        />
      </div>
      <Input
        placeholder={LABELS.EMAIL}
        value={formik.values.email}
        setValue={(value: string) => {
          updatePersonalDetails({
            ...formik.values,
            email: value,
          });
          formik.setFieldValue('email', value);
        }}
        onBlur={() => formik.setFieldTouched('email')}
        touched={formik.touched.email}
        error={formik.errors.email}
        title={LABELS.EMAIL}
        mandatory
        big
        disabled
      />
      <InputMobile
        placeholder={LABELS.MOBILE}
        value={formik.values.mobile}
        setValue={(value: string) => {
          formik.setFieldValue('mobile', value);
          updatePersonalDetails({
            ...formik.values,
            mobile: value,
          });
        }}
        onBlur={() => formik.setFieldTouched('mobile')}
        touched={formik.touched.mobile}
        error={formik.errors.mobile}
        title={LABELS.MOBILE}
        mandatory
        big
      />
      <div className={classes.twoColumnGrid}>
        <InputSelect
          placeholder={LABELS.ID_TYPE}
          value={formik.values.idType}
          values={Object.values(ID_TYPES).map((v) => ({
            display: v.name,
            value: v.value,
          }))}
          title={LABELS.ID_TYPE}
          error={formik.errors.idType}
          touched={formik.touched.idType}
          setValue={(value: string) => {
            formik.setFieldValue('idType', value);
            formik.setFieldValue('referenceNumber', null);
            formik.setFieldValue('medicareCardColor', null);
            formik.setFieldValue('stateIssue', null);
            formik.setFieldValue('countryOfIssue', null);
            updatePersonalDetails({
              ...formik.values,
              idType: value,
            });
          }}
          onBlur={() => formik.setFieldTouched('idType')}
          mandatory
          big
        />
        {formik.values.idType === 'DriversLicence' ? (
          <InputSelect
            placeholder={LABELS.STATE_ISSUE}
            value={formik.values.stateIssue}
            values={Object.values(STATE_OF_ISSUE).map((v) => ({
              display: v.name,
              value: v.value,
            }))}
            title={LABELS.STATE_ISSUE}
            error={formik.errors.stateIssue}
            touched={formik.touched.stateIssue}
            setValue={(value: string) => {
              formik.setFieldValue('stateIssue', value);
              updatePersonalDetails({
                ...formik.values,
                stateIssue: value,
              });
            }}
            onBlur={() => formik.setFieldTouched('stateIssue')}
            mandatory
            big
          />
        ) : formik.values.idType === 'Medicare' ? (
          <>
            <InputSelect
              placeholder={LABELS.REFERENCE_NUMBER}
              value={formik.values.referenceNumber}
              values={Object.values(REFERENCE_NUMBER).map((v) => ({
                display: v.name,
                value: v.value,
              }))}
              title={LABELS.REFERENCE_NUMBER}
              error={formik.errors.referenceNumber}
              touched={formik.touched.referenceNumber}
              setValue={(value: string) => {
                formik.setFieldValue('referenceNumber', value);
                updatePersonalDetails({
                  ...formik.values,
                  referenceNumber: value,
                });
              }}
              onBlur={() => formik.setFieldTouched('referenceNumber')}
              mandatory
              big
            />
            <InputSelect
              placeholder={LABELS.MEDICARE_CARD_COLOR}
              value={formik.values.medicareCardColor}
              values={Object.values(MEDICARE_CARD_COLOR).map((v) => ({
                display: v.name,
                value: v.value,
              }))}
              title={LABELS.MEDICARE_CARD_COLOR}
              error={formik.errors.medicareCardColor}
              touched={formik.touched.medicareCardColor}
              setValue={(value: string) => {
                formik.setFieldValue('medicareCardColor', value);
                updatePersonalDetails({
                  ...formik.values,
                  medicareCardColor: value,
                });
              }}
              onBlur={() => formik.setFieldTouched('medicareCardColor')}
              mandatory
              big
            />
          </>
        ) : formik.values.idType === 'Passport' ? (
          <InputSelect
            placeholder={LABELS.COUNTRY_OF_ISSUE}
            value={formik.values.countryOfIssue}
            values={Object.values(COUNTRY_CODES).map((v) => ({
              display: v.name,
              value: v.value,
            }))}
            title={LABELS.COUNTRY_OF_ISSUE}
            error={formik.errors.countryOfIssue}
            touched={formik.touched.countryOfIssue}
            setValue={(value: string) => {
              formik.setFieldValue('countryOfIssue', value);
              updatePersonalDetails({
                ...formik.values,
                countryOfIssue: value,
              });
            }}
            onBlur={() => formik.setFieldTouched('countryOfIssue')}
            mandatory
            big
          />
        ) : null}
      </div>

      <div className={classes.twoColumn}>
        <Input
          placeholder={LABELS.ID_NUMBER}
          value={formik.values.idNumber}
          setValue={(value: string) => {
            formik.setFieldValue('idNumber', value);
            updatePersonalDetails({
              ...formik.values,
              idNumber: value,
            });
          }}
          onBlur={() => formik.setFieldTouched('idNumber')}
          touched={formik.touched.idNumber}
          error={formik.errors.idNumber}
          title={LABELS.ID_NUMBER}
          mandatory
          big
        />
        <InputDate
          placeholder={LABELS.ID_EXPIRY}
          value={formik.values.idExpiry}
          setValue={(newValue: string) => {
            formik.setFieldValue('idExpiry', newValue);
            formik.setFieldValue('expiry', newValue);
            updatePersonalDetails({
              ...formik.values,
              idExpiry: moment(newValue).toDate(),
            });
          }}
          onBlur={() => formik.setFieldTouched('idExpiry')}
          touched={formik.touched.idExpiry}
          error={formik.errors.idExpiry}
          title={LABELS.ID_EXPIRY}
          maxDate={expiryDate}
          minDate={expiryMinDate}
          mandatory
          blockedDates={[]}
          big
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  personalDetails: state.checkout.personalDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSection);
