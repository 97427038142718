import { routes } from '../Routes';
import { history } from '../helpers/HistoryHelper';
import { Location } from 'history';
import { ServiceTypes } from '../models/services/services';
import { RouteParams } from './NavigationConstants';
import { store } from '../store/Store';
import { DashboardActions } from '../store/actions/DashboardActions';
import { TabProps } from '../models/dashboard/dashboard';

// export function navigate(name: string, params?: any) {
//   history.push(name, { params });
// }

// export function navigateBack() {
//   history.goBack();
// }

// export function resetNavigation(name: string, params?: any) {
//   history.replace(name, { params });
// }

export type Route = keyof typeof routes;

export function navigate(name: Route, params?: RouteParams[Route], newTab?: boolean) {
  const url = new URL(window.location.href);
  // Clear out existing search parameters
  url.searchParams.forEach((_, key) => url.searchParams.delete(key));

  if (params) {
    for (const key in params) {
      url.searchParams.set(key, params[key]);
    }
  }

  if (['home', 'account', 'browse'].includes(name)) {
    store.dispatch(DashboardActions.switchFooterTab(name, params as TabProps));
  }

  if (newTab) {
    window.open(window.location.origin + routes[name] + url.search);
  } else {
    history.push(routes[name] + url.search);
  }

  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function navigateBack() {
  history.goBack();
}

export function resetNavigation(name: Route, params?: RouteParams[Route]) {
  const url = new URL(window.location.href);
  // Clear out existing search parameters
  url.searchParams.forEach((_, key) => url.searchParams.delete(key));

  if (params) {
    for (const key in params) {
      url.searchParams.set(key, params[key]);
    }
  }

  if (['home', 'account', 'browse'].includes(name)) {
    store.dispatch(DashboardActions.switchFooterTab(name, params as TabProps));
  }

  history.replace(routes[name] + url.search);
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export const extractParams = <T>(): T => {
  const url = new URL(window.location.href);
  const params = Object.fromEntries(url.searchParams.entries());

  return params as T;
};
