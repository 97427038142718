import { ServiceTypes } from '../../models/services/services';
import electricity from '../../assets/marketplace/dark/electricity.png';
import gas from '../../assets/marketplace/dark/gas.png';
import broadband from '../../assets/marketplace/dark/broadband.png';
import electricityActive from '../../assets/marketplace/active/electricity.png';
import gasActive from '../../assets/marketplace/active/gas.png';
import broadbandActive from '../../assets/marketplace/active/broadband.png';

import firstEnergy from '../../assets/cimetEnergy/firstEnergy.png';
import actewAGL from '../../assets/cimetEnergy/actewAGL.png';
import agl from '../../assets/cimetEnergy/agl.png';
import alinta from '../../assets/cimetEnergy/alinta.png';
import blueNRG from '../../assets/cimetEnergy/blueNRG.png';
import dodoEnergy from '../../assets/cimetEnergy/dodo.png';
import ea from '../../assets/cimetEnergy/ea.png';
import lumo from '../../assets/cimetEnergy/lumo.png';
import momentumEnergy from '../../assets/cimetEnergy/momentumEnergy.png';
import powershop from '../../assets/cimetEnergy/powershop.png';
import redEnergy from '../../assets/cimetEnergy/redEnergy.png';
import simplyEnergy from '../../assets/cimetEnergy/simplyEnergy.png';

import aussieBroadband from '../../assets/cimetBroadband/aussieBroadband.png';
import dodo from '../../assets/cimetBroadband/dodo.png';
import exetel from '../../assets/cimetBroadband/exetel.png';
import goodtel from '../../assets/cimetBroadband/goodtel.png';
import more from '../../assets/cimetBroadband/more.png';
import optus from '../../assets/cimetBroadband/optus.png';
import superloop from '../../assets/cimetBroadband/superloop.png';
import tangerine from '../../assets/cimetBroadband/tangerine.png';

import { getQueryParams } from '../../helpers/QueryHelper';
import { Location } from 'history';
import {
  CimetBroadbandNeeds,
  CimetDatesResponse,
  CimetElectricityNeeds,
} from '../../models/cimet/Cimet';
import moment from 'moment';

export const getServiceIcon = (serviceType: ServiceTypes, active: ServiceTypes | null) => {
  switch (serviceType) {
    case ServiceTypes.Electricity:
      return active === ServiceTypes.Electricity ? electricityActive : electricity;
    case ServiceTypes.Gas:
      return active === ServiceTypes.Gas ? gasActive : gas;
    case ServiceTypes.Broadband:
      return active === ServiceTypes.Broadband ? broadbandActive : broadband;
    default:
      return electricity;
  }
};

export const getPartnersImages = (serviceType: ServiceTypes | null) => {
  switch (serviceType) {
    case ServiceTypes.Broadband:
      return [aussieBroadband, dodo, tangerine, goodtel, more, optus, superloop, exetel];
    default:
      return [
        powershop,
        dodoEnergy,
        ea,
        agl,
        actewAGL,
        redEnergy,
        simplyEnergy,
        firstEnergy,
        alinta,
        blueNRG,
        // momentumEnergy,
        lumo,
      ];
  }
};

export const isFormComplete = (
  serviceType: ServiceTypes,
  values: CimetElectricityNeeds | CimetBroadbandNeeds,
  isChecked: boolean,
) => {
  if (!isChecked) return false;
  if (serviceType == ServiceTypes.Broadband) {
    // If moving type YES and supplied
    if (values.movingType === 1 && !!values.movingDate) return true;
    if (values.movingType === 0) return true;
    return false;
  }
  if (serviceType == ServiceTypes.Electricity || serviceType == ServiceTypes.Gas) {
    if (
      (values as CimetElectricityNeeds).solarPanel !== null &&
      (values as CimetElectricityNeeds).lifeSupport !== null
    ) {
      if (values.movingType === 1 && !!values.movingDate) return true;
      if (values.movingType === 0) return true;
      return false;
    }
    return false;
  }
  return false;
};

export const convertDates = (blockedDates: CimetDatesResponse[] | undefined): string[] => {
  if (!blockedDates) return [];
  const convertedResult = blockedDates.map((date) => {
    return moment(date.date).format('DD/MM/YYYY');
  });

  return convertedResult;
};
