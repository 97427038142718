import { TagBarItem } from '../../../../components/tagBar/TagBarConstants';

export const LABELS = {
  noRequestReview: 'No requests!',
  noRequestReviewSubText: 'Take a break, you are all sorted!',
  LOGIN: 'Log in to start raising maintenance requests',
};

export enum Tab {
  Request = 'New',
  Review = 'In Progress',
  Scheduled = 'Scheduled',
  Completed = 'Completed',
}

export const requestTab = [
  {
    value: Tab.Request,
    active: false,
  },
];

export const reviewTab = [
  {
    value: Tab.Review,
    active: false,
  },
];

export const scheduledTab = [
  {
    value: Tab.Scheduled,
    active: false,
  },
];

export const completedTab = [
  {
    value: Tab.Completed,
    active: false,
  },
];

export const initialTags = (tab: number | undefined) => {
  let result: TagBarItem[] = [];
  // TODO add in logic see essentials screen (remember to add the number of quotes for example)
  result = [...result, ...[{ ...requestTab[0], active: tab === undefined || tab === 0 }]];
  result = [...result, ...[{ ...reviewTab[0], active: tab === 1 }]];
  result = [...result, ...[{ ...scheduledTab[0], active: tab === 2 }]];
  result = [...result, ...[{ ...completedTab[0], active: tab === 3 }]];
  return result;
};
