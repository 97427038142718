import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  activeAccountStatus,
  activePlusPending,
  activeService,
  inactiveAccountStatus,
  newService,
  onboardingScreenValidStates,
  pendingService,
} from '../constants/AccountStatus';
import { LeaseResponse } from '../models/dashboard/dashboard';
import { MaintenanceResponse } from '../models/landlord/LandlordProperty';
import { FastPayPayment } from '../models/payment/Payment';
import { Property } from '../models/property/property';
import { AvailableServicesResponse, Services, ServiceTypes } from '../models/services/services';
import { ApplicationState } from '../store/RootReducer';
import { DashboardState } from '../store/state/DashboardState';
import { ESSENTIAL_SERVICES } from '../containers/home/components/dashboard/DashboardConstants';
import { CimetState } from '../store/state/CimetState';
import { CimetPurchasedServices } from '../models/cimet/Cimet';

export const findExtraServiceInfo = (
  service: Services,
  availableServices: AvailableServicesResponse[],
) => {
  return availableServices.find(
    (availableService: AvailableServicesResponse) =>
      availableService.ServiceType.replace(' ', '') === service.serviceType.replace(' ', ''),
  )!;
};

export const filterCimetServices = (services: Services[]): Services[] => {
  // If CIMET is active, this code will keep services that are NOT of type Electricity, Gas, or Broadband,
  // or services that ARE of those types BUT do NOT have the status activePlusPending.
  return services.filter(
    (data) =>
      ![ServiceTypes.Electricity, ServiceTypes.Gas, ServiceTypes.Broadband].includes(
        data.serviceType,
      ) ||
      ([ServiceTypes.Electricity, ServiceTypes.Gas, ServiceTypes.Broadband].includes(
        data.serviceType,
      ) &&
        activePlusPending.includes(data.status)),
  );
};

export const checkForEmptyDashboard = (
  noneOnboarded: boolean,
  onboardedTenancies: LeaseResponse[] | undefined,
) => noneOnboarded || onboardedTenancies === undefined || onboardedTenancies.length === 0;

interface ServicesProps {
  paymentDetails: FastPayPayment[];
  dashboardState: DashboardState;
  cimet: CimetState;
}

export const useDashboardServices = () => {
  const {
    paymentDetails,
    dashboardState: { onboardedProperties, selectedProperty, noneOnboarded },
    cimet,
  } = useSelector<ApplicationState, ServicesProps>((state: ApplicationState) => ({
    dashboardState: state.dashboardState,
    cimet: state.cimet,
    paymentDetails:
      state.payment !== undefined
        ? state.payment.paymentDetails !== undefined &&
          state.payment.paymentDetails.response !== undefined
          ? state.payment.paymentDetails.response.payments
          : []
        : [],
  }));

  const property = useMemo<Property | undefined>(() => {
    if (checkForEmptyDashboard(noneOnboarded, onboardedProperties)) {
      return undefined;
    } else {
      return onboardedProperties![selectedProperty].property as Property;
    }
  }, [onboardedProperties, selectedProperty]);

  const cimetAvailableServices = useMemo<ServiceTypes[]>(() => {
    if (
      property === undefined ||
      cimet.services === undefined ||
      cimet.services[property.id] === undefined
    )
      return [];
    return cimet.services[property.id].availableServices;
  }, [property, cimet]);

  const cimetPurchasedServices = useMemo<CimetPurchasedServices[]>(() => {
    if (
      property === undefined ||
      cimet.services === undefined ||
      cimet.services[property.id] === undefined
    )
      return [];
    return cimet.services[property.id].purchasedServices;
  }, [property, cimet]);

  const outstandingPayments = useMemo<FastPayPayment[]>(() => {
    if (property === undefined) return [];
    return paymentDetails.filter((item) => {
      return item.platformPropertyId === property.id && item.paymentTags !== 'viewed';
    });
  }, [property, paymentDetails]);

  const activeServices = useMemo<Services[]>(() => {
    if (property === undefined) return [];
    const result = (onboardedProperties![selectedProperty].services as Services[]).filter((data) =>
      activeAccountStatus.includes(data.status),
    );

    if (!!cimetAvailableServices.length || !!cimetPurchasedServices.length) {
      return filterCimetServices(result);
    }
    return result;
  }, [property, cimetAvailableServices, cimetPurchasedServices]);

  const activeAndOnline = useMemo<Services[]>(() => {
    if (property === undefined) return [];
    return (onboardedProperties![selectedProperty].services as Services[]).filter((data) =>
      activeService.includes(data.status),
    );
  }, [property]);

  const inactiveServices = useMemo<Services[]>(() => {
    if (property === undefined) return [];
    return (onboardedProperties![selectedProperty].services as Services[]).filter((data) =>
      inactiveAccountStatus.includes(data.status),
    );
  }, [property]);

  const openMaintainanceRequests = useMemo<MaintenanceResponse[]>(() => {
    if (property === undefined) return [];
    return (onboardedProperties![selectedProperty].maintenance as MaintenanceResponse[]).filter(
      (data) => !(data.status === 'closed' || data.status === 'complete'),
    );
  }, [property]);

  const closedMaintainanceRequests = useMemo<MaintenanceResponse[]>(() => {
    if (property === undefined) return [];
    return (onboardedProperties![selectedProperty].maintenance as MaintenanceResponse[]).filter(
      (data) => data.status === 'closed' || data.status === 'complete',
    );
  }, [property]);

  const rentServices = useMemo<Services[]>(() => {
    if (property === undefined) return [];
    return (onboardedProperties![selectedProperty].services as Services[]).filter(
      (data) => data.serviceType === ServiceTypes.Rent && activeAccountStatus.includes(data.status),
    );
  }, [property]);

  const electricityServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) =>
        data.serviceType === ServiceTypes.Electricity && activeAccountStatus.includes(data.status),
    );
  }, [activeServices]);

  const broadbandServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) =>
        data.serviceType === ServiceTypes.Broadband && activeAccountStatus.includes(data.status),
    );
  }, [activeServices]);

  const gasServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) => data.serviceType === ServiceTypes.Gas && activeAccountStatus.includes(data.status),
    );
  }, [activeServices]);

  const otherServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) =>
        data.serviceType !== ServiceTypes.Rent &&
        data.serviceType !== ServiceTypes.Electricity &&
        data.serviceType !== ServiceTypes.Broadband,
    );
  }, [activeServices]);

  const homeServices = useMemo<Services[]>(() => {
    return activeServices.filter((service) => ESSENTIAL_SERVICES.includes(service.serviceType));
  }, [activeServices]);

  const otherHomeServices = useMemo<Services[]>(() => {
    return otherServices.filter(
      (service) =>
        !ESSENTIAL_SERVICES.includes(service.serviceType) &&
        service.serviceType !== ServiceTypes.Callback,
    );
  }, [otherServices]);

  const pendingServices = useMemo<Services[]>(() => {
    return activeServices.filter((data) => pendingService.includes(data.status));
  }, [activeServices]);

  const newServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) => data.serviceType !== ServiceTypes.Rent && newService.includes(data.status),
    );
  }, [activeServices]);

  const newAndInProgessServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) =>
        data.serviceType !== ServiceTypes.Rent && onboardingScreenValidStates.includes(data.status),
    );
  }, [activeServices]);

  const openServices = useMemo<Services[]>(() => {
    return activeServices.filter(
      (data) => data.serviceType !== ServiceTypes.Rent && activePlusPending.includes(data.status),
    );
  }, [activeServices]);

  const hasServices = useMemo<boolean>(() => {
    if (openServices.length) return true;
    if (pendingServices.length) return true;
    if (inactiveServices.length) return true;
    // if (openMaintainanceRequests.length) return true;
    // if (closedMaintainanceRequests.length) return true;
    return false;
  }, [
    openServices,
    pendingServices,
    inactiveServices,
    // openMaintainanceRequests,
    // closedMaintainanceRequests,
  ]);

  return {
    property,
    newServices,
    newAndInProgessServices,
    outstandingPayments,
    activeAndOnline,
    activeServices,
    inactiveServices,
    openMaintainanceRequests,
    closedMaintainanceRequests,
    rentServices,
    electricityServices,
    broadbandServices,
    gasServices,
    otherServices,
    homeServices,
    otherHomeServices,
    pendingServices,
    openServices,
    hasServices,
    cimetAvailableServices,
    cimetPurchasedServices,
  };
};
