import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
  pageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
    },
    centerPageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black38,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 8,
    },
    agencyContainer: {
      display: 'flex',
      backgroundColor: theme.colors.light,
      padding: '16px',
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
    },
    agencyLogo: {
      width: '55px',
      height: '55px',
      borderRadius: 5000,
      backgroundColor: theme.colors.light,
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    messageContainer: {
      marginLeft: '16px',
    },
    messageTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '20px',
      fontWeight: 500,
    },
    messageSubtitle: {
      fontFamily: 'GT Walsheim',
      marginTop: 4,
      color: theme.colors.black38,
      fontSize: '16px',
      fontWeight: 500,
    },
    stepContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.light,
      padding: '24px',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
    },
    listBox: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 300,
    },
    listItem: {
      'fontFamily': 'GT Walsheim',
      'marginTop': 36,
      'display': 'flex',
      'flexDirection': 'row',
      'alignItems': 'center',
      '&:first-child': {
        marginTop: 0,
      },
    },
    listIcon: {
      marginRight: 24,
      height: 30,
      width: 30,
      marginLeft: 6,
    },
    listInfoBox: {},
    listTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '20px',
      fontWeight: 500,
    },
    listMessage: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black38,
      fontSize: '16px',
      fontWeight: 500,
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 8,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
      marginLeft: theme.units.spacing * 4,
    },
  })();
};
