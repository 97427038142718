/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    root: {
      backgroundColor: theme.colors.light,
      flex: 1,
    },
    headerContainer: {
      position: 'relative',
      backgroundColor: theme.colors.light,
      paddingBottom: 12,
    },
    MRContainer: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 12,
      overflow: 'hidden',
    },
    headingView: {
      backgroundColor: theme.colors.lightGrey,
      height: 32,
      borderBottomColor: theme.colors.lightGrey,
      borderBottomWidth: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 3,
      alignItems: 'center',
    },
    headingText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      textTransform: 'uppercase',
    },
    detailsView: {
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 3,
      paddingBottom: 12,
    },
    detailsSectionView: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 15,
    },
    detailsSectionTitle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    detailsSectionValue: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      marginLeft: theme.units.spacing,
    },
    descriptionSection: {
      marginTop: 24,
    },
    descriptionDetailsView: {
      padding: 8,
      borderColor: theme.colors.borderColorLight,
      borderWidth: 1,
      borderRadius: 4,
      marginTop: 8,
      backgroundColor: theme.colors.lightGrey,
      minHeight: 64,
    },
    descriptionText: {
      fontSize: 14,
      fontWeight: getFonts('Light'),
      color: theme.colors.black60,
    },
    noAttachmentsText: {
      fontSize: 14,
      fontWeight: getFonts('Light'),
      color: theme.colors.black60,
      marginTop: 12,
    },
    buttonViewStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: 12,
    },
    buttonStyle: {
      flex: 1,
      borderWidth: 1,
      borderRadius: 12,
      border: `${theme.colors.borderColorLight} 1px solid`,
      display: 'flex',
      paddingTop: theme.units.spacing * 3,
      paddingBottom: theme.units.spacing * 3,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    buttonTextStyle: {
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    submitButtonStyle: {
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 8,
      flexDirection: 'row',
    },
    submitTextStyle: {
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
    },
    buttonIconStyle: {
      width: 22,
      height: 22,
      marginRight: 12,
      marginLeft: -12,
    },
    selectedButtonStyle: {
      borderColor: theme.colors.lightBlue,
      backgroundColor: theme.colors.lightBlue,
    },
    mainteanceImagesContainer: {
      marginTop: 12,
    },
    maintenanceImage: {
      borderRadius: 8,
      marginRight: 12,
      width: 65,
      height: 65,
      overflow: 'hidden',
      resizeMode: 'cover',
      borderColor: theme.colors.borderColor,
      backgroundColor: theme.colors.borderColor,
      borderWidth: 1,
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 12,
      padding: 12,
    },
    downloadIcon: {
      width: 22,
      height: 22,
      resizeMode: 'contain',
    },
    downloadText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: 16,
    },
    quoteContainer: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginRight: 16,
      marginBottom: theme.units.spacing * 4,
      overflow: 'hidden',
    },
    quoteHeader: {
      backgroundColor: theme.colors.lightGrey,
      borderBottomColor: theme.colors.lightGrey,
      borderBottomWidth: 1,
      height: 32,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: 16,
    },
    quoteHeaderText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      textTransform: 'uppercase',
    },
    quoteDetailsHeader: {
      height: 54,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    quoteDetailsHeaderText: {
      fontSize: 18,
      fontWeight: getFonts('Light'),
      color: theme.colors.black87,
      marginBottom: theme.units.spacing,
    },
    quoteDetailsHeaderSubText: {
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    quoteDetailsContainer: {
      padding: 16,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: theme.colors.light,
    },
    quoteDetailsView: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    quoteDetailsText: {
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    estimatedCostStyle: {
      color: theme.colors.buttonPrimary,
    },
    quoteButtonStyle: {
      backgroundColor: theme.colors.buttonPrimary,
    },
    quoteTextStyle: {
      color: theme.colors.light,
    },
    pdfViewStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      // marginTop: 12,
      padding: 16,
    },
    pdfIconStyle: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
    },
    pdfDownloadIconStyle: {
      width: theme.units.iconSize * 1.2,
      height: theme.units.iconSize * 1.2,
      objectFit: 'contain',
    },
    pdfNameStyle: {
      flex: 2,
      justifyContent: 'flex-start',
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      marginLeft: 12,
    },
    tenantViewContainer: {
      borderRadius: 8,
      borderColor: theme.colors.borderColor,
      borderWidth: 1.5,
      marginLeft: theme.units.spacing * 3,
      marginRight: theme.units.spacing * 3,
      marginBottom: 16,
      overflow: 'hidden',
    },
    tenantHeader: {
      backgroundColor: theme.colors.lightGrey,
      borderBottomColor: theme.colors.lightGrey,
      borderBottomWidth: 1,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingLeft: 16,
    },
    tenantHeaderText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      textTransform: 'uppercase',
    },
    tenantNameView: {
      display: 'flex',
      flexDirection: 'row',
    },
    tenantDetailsContainer: {
      padding: 16,
    },
    tenantNameLabel: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    tenantNameValue: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    contactIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      marginRight: 10,
    },
    detailRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 12,
    },
    itemText: {
      color: theme.colors.black38,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
    },
    gravatar: {
      height: 40,
      width: 40,
      borderRadius: 5000,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.borderColor,
      flexShrink: 0,
      alignSelf: 'center',
    },
    gravatarText: {
      color: theme.colors.light,
      fontSize: 16,
    },
    quoteTextView: {
      marginLeft: 16,
    },
    inclusionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      marginTop: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    inclusionsCard: {
      height: 180,
    },
    inclusionImage: {
      height: '80%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    inclusionText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      marginTop: 12,
    },
    jobContainer: {
      borderRadius: 8,
      borderColor: theme.colors.borderColor,
      borderWidth: 1,
      marginLeft: theme.units.spacing * 3,
      marginRight: theme.units.spacing * 3,
    },
    modal: {
      justifyContent: 'flex-end',
      margin: 0,
    },
    modalView: {
      display: 'flex',
      flexDirection: 'column',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme.colors.light,
      minHeight: 200,
      padding: 16,
      borderWidth: 1,
      justifyContent: 'space-between',
    },
    modalTitleContainer: {
      paddingBottom: 12,
      marginBottom: 6,
      borderBottomColor: theme.colors.borderColor,
      borderBottomWidth: 1,
    },
    modalTitle: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    buttonsRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.units.spacing * 4,
    },
    button: {
      flex: 1,
    },
    addedHeaderStyle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    materialCostContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    arrowIconStyle: {
      width: 12,
      height: 12,
      marginLeft: 6,
      marginTop: 2,
    },
    statusViewStyle: {
      borderRadius: 4,
      padding: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 12,
    },
    statusTextStyle: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    logoStyle: {
      width: 40,
      height: 40,
      borderRadius: 20,
    },
    problemText: {
      padding: 8,
      height: 100,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.12)',
      backgroundColor: '#f2efef',
      textAlignVertical: 'top',
      width: '100%',
      marginTop: 12,
      marginBottom: 12,
    },
    reviewModalView: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme.colors.light,
      height: 400,
      padding: 16,
      borderWidth: 1,
    },
    reviewTitleStyle: {
      fontSize: 16,
      fontWeight: getFonts('Light'),
      color: theme.colors.black87,
      marginTop: 12,
    },
    reviewhelperTextStyle: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginTop: 12,
    },
    reviewhelperText1Style: {
      fontSize: 14,
      fontWeight: getFonts('Light'),
      color: theme.colors.black60,
      marginTop: 12,
      textAlign: 'center',
    },
    reviewCommentsTitle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    imageViewStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageLogoStyle: {
      width: 80,
      height: 80,
      borderRadius: 40,
      marginTop: -60,
    },
    ratingViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    ratingStyle: {
      marginLeft: theme.units.spacing,
      marginRight: theme.units.spacing,
      marginTop: 12,
    },
    ratingIcon: {
      width: 25,
      height: 25,
    },
    reviewStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 24,
    },
    documentRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: 12,
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 3,
    },
    documentIcon: {
      width: 22,
      height: 22,
      resizeMode: 'contain',
    },
    documentTitle: {
      marginRight: 'auto',
      marginLeft: 12,
    },
    quoteTitleViewStyle: {
      marginBottom: 8,
    },
    reviewRatingIcon: {
      width: 25,
      height: 25,
      marginRight: 8,
    },
    reviewLoaderView: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 16,
      paddingLeft: 0,
    },
    loaderContainer: {
      marginLeft: 20,
    },
    firstLoader: {
      width: 150,
      height: 20,
      borderRadius: 4,
    },
    secondLoader: {
      marginTop: 6,
      width: 150,
      height: 20,
      borderRadius: 4,
    },
    reviewContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    summaryDetailsSectionView: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 6,
    },
  })();
};
