export enum MR_STATUS {
  GET_TRADE = 'GET_TRADE',
  ATTENDING = 'landlord_attending',
  COMPLETED = 'complete',
}

export enum MR_QUOTE_STATUS {
  ACCEPT = 'approved',
  REJECT = 'rejected',
}

export const LABELS = {
  DOWNLOAD_ALL: 'Download all',
  DOWNLOAD: 'Download image',
};

export enum WeekDays {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export const WeekdayList: ReadonlyArray<WeekDays> = [
  WeekDays.MONDAY,
  WeekDays.TUESDAY,
  WeekDays.WEDNESDAY,
  WeekDays.THURSDAY,
  WeekDays.FRIDAY,
  WeekDays.SATURDAY,
  WeekDays.SUNDAY,
];

export const NO_PREFERENCE = 'No preference';
