import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    activityLoadingContainer: {
      marginTop: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sectionTitle: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: 16,
      marginBottom: 8,
    },
    sectionTitle2: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginTop: 18,
      marginLeft: 16,
      marginBottom: 8,
    },
    serviceCard: {
      flex: 1,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      padding: 16,
      position: 'relative',
    },
    serviceCardLeftView: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    serviceIcon: {
      marginLeft: 10,
      alignSelf: 'center',
      height: 24,
      width: 24,
    },
    serviceCardLeftVerticalView: {
      marginLeft: 20,
    },
    itemLabel: {
      fontSize: 14,
      color: theme.colors.black38,
    },
    itemValue: {
      fontSize: 16,
      color: theme.colors.black87,
      marginTop: 4,
    },
    serviceChevron: {
      height: 24,
      width: 24,
      alignSelf: 'center',
      position: 'absolute',
      right: 8,
      top: 20,
    },
    detailCard: {
      flex: 1,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
    },
    cardItem: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    flexBox: {
      marginBottom: 12,
    },
    itemButtonBox: {},
    itemButton: {
      fontSize: 14,
      letterSpacing: 0.4,
      color: theme.colors.error,
    },
  })();
};
