import React, { FC } from 'react';
import { useStyles } from './YourCurrentPlanStyles';
import {
  KeyValuePair,
  GenericSection,
  ServiceDetails,
} from '../../../../models/services/genericServiceSummary';
import accordian from '../../../../assets/navigation/accordian.png';
import { navigate } from '../../../../navigation/NavigationUtils';

interface YourCurrentPlanProps {
  isChartShown: boolean;
  data: KeyValuePair[];
  logo: string;
  servicePageDetails: GenericSection[];
  serviceDetails: ServiceDetails;
}

const YourCurrentPlan: FC<YourCurrentPlanProps> = ({
  isChartShown,
  data,
  logo,
  servicePageDetails,
  serviceDetails,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.currentPlanRow}
        onClick={() => navigate('planDetails', { servicePageDetails, serviceDetails })}
      >
        <img src={logo} className={classes.logoStyle} />
        <div className={classes.titleContainer}>
          <div className={classes.sectionKey}>{data[0].value}</div>
          <div className={classes.sectionValue}>
            {(data[1].value as string).replace(/([A-Z][A-Z\-]*)/g, ' $1').trim()}
          </div>
        </div>
        <img src={accordian} className={classes.accordian} />
      </div>
      {isChartShown && (
        <>
          {data &&
            data.map((item, index) => (
              <>
                {index > 1 ? (
                  <div className={classes.titleContainer2}>
                    <div className={classes.sectionKey}>{item.key}</div>
                    <div className={classes.sectionValue}>{item.value}</div>
                  </div>
                ) : (
                  <div />
                )}
              </>
            ))}
        </>
      )}
    </>
  );
};

export default YourCurrentPlan;
