import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    iconStyle: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    flexBox: {
      flex: 1,
    },
    emptyInvoiceCard: {
      flex: 1,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    invoiceCard: {
      flex: 1,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      paddingLeft: 16,
      marginBottom: 16,
    },
    invoiceYear: {
      fontSize: 14,
      height: 43,
      paddingTop: 14,
      letterSpacing: 0.09,
      color: theme.colors.sortedPurple,
    },
    invoiceList: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      paddingVertical: 15,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    invoiceImage: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      alignSelf: 'center',
    },
    invoiceVerticalBox: {
      marginLeft: 28,
      alignSelf: 'center',
    },
    invoiceTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      letterSpacing: 0.1,
      color: theme.colors.black87,
    },
    invoiceService: {
      fontWeight: getFonts('Medium'),
      marginTop: 5,
      fontSize: 14,
      letterSpacing: 0.4,
      color: theme.colors.black60,
    },
    invoiceAmountBox: {
      position: 'absolute',
      right: 16,
      alignSelf: 'center',
    },
    invoiceAmount: {
      fontWeight: getFonts('Medium'),
      textAlign: 'right',
      fontSize: 14,
      letterSpacing: 0.4,
      color: theme.colors.black87,
    },
  })();
};
