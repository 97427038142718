import { onboardingScreenValidStates } from '../../../../constants/AccountStatus';

import { findExtraServiceInfo } from '../../../../helpers/ServiceInfoHelper';
import { LeaseResponse } from '../../../../models/dashboard/dashboard';
import { Property } from '../../../../models/property/property';
import { ServiceTypes, Services } from '../../../../models/services/services';
import { navigate } from '../../../../navigation/NavigationUtils';
import { store } from '../../../../store/Store';
import {
  ThemeKey,
  getCurrentTheme,
  getCurrentThemeKey,
  getThemeKey,
  theme,
} from '../../../../theme/Theme';
import { filterServicesByStatus } from '../../../home/components/dashboard/DashboardUtils';
import { BannerItem } from './MarketplaceConstants';

export const LABELS = {};

export interface ColorSwatch {
  primary: string;
  background: string;
}

export const getColouredIcon = (service: string | undefined) => {
  switch (service) {
    case 'Essentials':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Protect':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Maintain':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Optimise':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Electricity':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Gas':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Broadband':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Insurance':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Cleaning':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Solar':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Removals':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Water':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Gardening':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Finance':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Tech Support':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Vertical Gardens':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Pest Control':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Home Security':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Bathroom/Kitchen':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Premium Service':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Fans':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Electrical':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Lights':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Handyman':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Plumbing':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Locksmith':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Parking':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Painter':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Travel':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Rewards':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Builder':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Homeware':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Decluttering':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Plastering':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Wellbeing':
      return { primary: theme.colors.darkRed, background: theme.colors.lightRed };
    case 'Digital key':
      return { primary: theme.colors.darkPurple, background: theme.colors.lightPurple };
    case 'Book spaces':
      return { primary: theme.colors.darkBlue, background: theme.colors.lightBlue };
    case 'Fitness studio':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    case 'Trades':
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
    case 'Rent':
      return { primary: theme.colors.darkGreen, background: theme.colors.lightGreen };
    default:
      return { primary: theme.colors.darkOrange, background: theme.colors.lightOrange };
  }
};

export const getBanners = (
  property: Property | undefined,
  onboardedProperties: LeaseResponse[] | undefined,
  selectedProperty: number,
  newAndInProgessServices: Services[],
  banners: BannerItem[],
) => {
  const themeKey = getThemeKey();
  if (property && onboardedProperties && onboardedProperties.length) {
    const serviceTypes = filterServicesByStatus(
      onboardingScreenValidStates,
      newAndInProgessServices,
    ).map((s) => s.serviceType);

    const filteredServices = filterServicesByStatus(
      onboardingScreenValidStates,
      newAndInProgessServices,
    );

    const filteredBanners = banners.filter(
      (b) =>
        serviceTypes.includes(b.serviceType) ||
        b.serviceType === ServiceTypes.Trades ||
        (themeKey === ThemeKey.SWITCH && b.serviceType === ServiceTypes.OtherService),
    );
    return filteredBanners.map((b) => {
      const service = filteredServices.find((s) => s.serviceType === b.serviceType);

      if (service) {
        const extraServiceInfo = findExtraServiceInfo(
          service,
          onboardedProperties[selectedProperty].availableServices,
        );

        return {
          ...b,
          service,
          action: () =>
            navigate('suppliersScreen', {
              service,
              suppliers: extraServiceInfo.Suppliers,
            }),
        };
      } else {
        return b;
      }
    });
  } else {
    return banners;
  }
};
