import { ToastState } from '../state/ToastState';

export enum ToastActionsTypes {
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
}

export interface ToastAction {
  type: ToastActionsTypes;
  data: ToastState | null;
}

export class ToastActions {
  public static showToast(data: ToastState): ToastAction {
    return {
      type: ToastActionsTypes.SHOW_TOAST,
      data,
    };
  }
  public static hideToast(): ToastAction {
    return {
      type: ToastActionsTypes.HIDE_TOAST,
      data: null,
    };
  }
}
