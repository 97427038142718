import * as Yup from 'yup';
import { Images, JobMedia } from '../../models/maintenance/maintenance';
import { FileLinkOrContent } from '../../services/MultipartFormData.data';

export const MAX_IMAGE_FILE_SIZE = 10;
export const MAX_VIDEO_FILE_SIZE = 40;

export const LABELS = {
  SUCCESS_TITLE: 'Request submitted',
  SUCCESS_HEADING: (agency: string) => `Someone from [${agency}] will contact you shortly to
          confirm next steps`,
  BACK_TO_HOME: 'BACK TO HOME',
  MAINTENANCE_TITLE: 'Maintenance Request',
  MAINTENANCE_HEADING: 'Submit a maintenance request',
  MAINTENANCE_SECTION_TITLE: 'Tell us about the problem',
  MAINTENANCE_ATTACH_PHOTO: 'Attach at least 3 photos',
  MAINTENANCE_CONTACT: 'Your contact information',
  MAINTENANCE_EMAIL_LABEL: 'Your email',
  MAINTENANCE_PHONE_LABEL: 'Your mobile',
  MAINTENANCE_INVALID_EMAIL: 'Please enter a valid email',
  MAINTENANCE_INVALID_MOBILE: 'Please enter a valid phone number',
  MAINTENANCE_SUBMIT: 'Submit',
  MAINTENANCE_HELP:
    'Complete the following form to submit a maintenance request. To help speed up the process, please provide as much detail as possible. Mandatory fields are marked with an asterisk*',
  MAINTENANCE_NEW: 'New maintenance request',
  MAINTENANCE_EDIT: 'Edit maintenance request',

  DONE: 'DONE',
  GET_BACK: 'Your maintenance request has been registered, your agent will be in contact soon.',
  GET_BACK_SORTED: 'Your request has been registered, a team member will be in contact soon.',
  JOB_DESCRIPTION: 'Job description',
  WHERE_IS_ISSUE: 'Where is the issue located?',
  FIELD_REQUIRED: 'This field is required',
  ATLEAST_ONE: 'Atleast one option is required',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  BRIEFLY_DESCRIBE:
    'Briefly decribe the problem, where it is on the premises and any other relevant information',
  PHOTOS: 'Photos',
  ATTACH: `Attach at least one photo(s), showing the site and affected areas, appliances, brand model and size. (If applicable) Maximum image size allowed is ${MAX_IMAGE_FILE_SIZE}MB per image`,
  ADD_PHOTO: 'Add photo',
  VIDEOS: 'Videos',
  ATTACH_VID: `Attach one video, showing the site and affected areas, appliances, brand model and size. (If applicable) Maximum video size allowed is ${MAX_VIDEO_FILE_SIZE}MB`,
  APPLIANCES: 'Appliances',
  RELATED_TO_APPLIANCE: 'Is this maintenance request related to an applicance?',
  MAKE: 'Please enter the make',
  MODEL: 'Please enter the model',
  WARRANTY: 'Is the warranty information available?',
  PREFERRED_AVAILABILITY: 'Preferred availability',
  PREFERRED_TIME: 'Preferred Time',
  PREFERRED_DAY: 'Preferred Day',
  NO_PREFERENCE: 'No preference',
  MORNING: 'Morning (8am - 12pm)',
  AFTERNOON: 'Afternoon (12pm - 5pm)',
  OTHER: 'Other (Enter time)',
  PERMISSION_PRESENT:
    'Do you provide permission for the trade to attend your house/apartment without you being present?',
  PROPERTY_DETAILS: 'Property Details',
  CONTACT_ACCESS: 'How can the tradesperson access the property?',
  CONTACT_HOME: 'Contact home owner/tenant',
  COLLECT_KEYS: 'Collect keys from real estate agent',
  MINOR: 'Minor',
  MINOR_INFO:
    'Please confirm no minor under 18 yo will be left unsupervised for the duration of the maintenance booking',
  PETS: 'Pets',
  PETS_INFO: 'Is there a dog or any animal onsite?',
  PETS_NAME: 'Dogs(s) name and description (all animals must be restrained)',
  ACCESS: 'Access',
  STAIRS:
    'Are there stairs to access the property which may be an obstacle to completing the required work?',
  BODY_CORPORATE:
    'Will the trades person need to contact body corporate to access the property or maintenance location (are meter boxes or manholes obstructed)?*',
  PARKING: 'Parking',
  IS_GUEST: 'Is there guest parking available onsite?',
  PARKING_YES: 'Yes, parking spaces are available',
  PARKING_NO: 'No, street parking',
};

export const DAYS_FULL = {
  Mon: 'MONDAY',
  Tue: 'TUESDAY',
  Wed: 'WEDNESDAY',
  Thu: 'THURSDAY',
  Fri: 'FRIDAY',
  Sat: 'SATURDAY',
  Sun: 'SUNDAY',
};

export interface FormData {
  area: string | undefined;
  parking: boolean | null;
  jobTitle: string | undefined;
  jobDesc: string | undefined;
  make: string;
  model: string;
  otherTime: string;
  days: string;
  locationDesc: string;
  dogDesc: string;
  stairsDesc: string;
  accessType: string | null;
  access: boolean | null;
  appliance: boolean | null;
  warranty: boolean | null;
  keyLeftOut: boolean;
  dog: boolean | null;
  stairs: boolean | null;
  jobPhotos: FileLinkOrContent[];
  jobVideos: FileLinkOrContent[];
  timeSlot: string | null;
  minor: boolean;
  attendPermissionWithoutTenant: boolean;
}

export const ValidationSchema = Yup.object().shape({
  area: Yup.string().required('Required'),
  parking: Yup.boolean().required('Required'),
  stairsDesc: Yup.string().notRequired(),
  accessType: Yup.string().required('Required'),
  access: Yup.boolean().required('Required'),
  model: Yup.string().notRequired(),
  warranty: Yup.boolean().required('Required'),
  keyLeftOut: Yup.boolean().notRequired(),
  stairs: Yup.boolean().required('Required'),
  timeSlot: Yup.string().required('Required'),
  otherTime: Yup.string().notRequired(),
  jobDesc: Yup.string().required('Required'),
  jobTitle: Yup.string().required('Required'),
  jobPhotos: Yup.array().min(1),
  dog: Yup.boolean().required('Required'),
  appliance: Yup.boolean().required('Required'),
  days: Yup.string().when('timeSlot', {
    is: 'No preference',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().min(1).required('Required'),
  }),
  make: Yup.string().when('appliance', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  minor: Yup.boolean().required('Required'),
  dogDesc: Yup.string().when('dog', {
    is: true,
    then: Yup.string().required('Required'),
  }),
});

export interface SelectItem {
  readonly label: string;
  readonly value: string;
}

export interface LocationObject {
  key: string;
  value: string;
}
export interface LocationData {
  LOCATION: LocationObject[];
}

export const MaintenanceRequestCategory: SelectItem[] = [
  {
    label: 'Bedroom',
    value: 'BEDROOM',
  },
  {
    label: 'Living room',
    value: 'LIVING_ROOM',
  },
  {
    label: 'Dining room',
    value: 'DINING_ROOM',
  },
  {
    label: 'Rumpus/Recreation room',
    value: 'RUMPUS_ROOM',
  },
  {
    label: 'Study',
    value: 'STUDY',
  },
  {
    label: 'Kitchen',
    value: 'KITCHEN',
  },
  {
    label: 'Pantry',
    value: 'PANTRY',
  },
  {
    label: 'Laundry',
    value: 'LAUNDRY',
  },
  {
    label: 'Bathroom',
    value: 'BATHROOM',
  },
  {
    label: 'Hallway',
    value: 'HALLWAY',
  },
  {
    label: 'Entrance',
    value: 'ENTRANCE',
  },
  {
    label: 'Outdoor/External',
    value: 'OUTDOOR_EXTERNAL',
  },
  {
    label: 'Pool house',
    value: 'POOL_HOUSE',
  },
  {
    label: 'Equipment room',
    value: 'EQUIPMENT_ROOM',
  },
  {
    label: 'Garage',
    value: 'GARAGE',
  },
  {
    label: 'Carport',
    value: 'CARPORT',
  },
  {
    label: 'Shed',
    value: 'SHED',
  },
  {
    label: 'Loft',
    value: 'LOFT',
  },
  {
    label: 'Basement',
    value: 'BASEMENT',
  },
  {
    label: 'Gym',
    value: 'GYM',
  },
  {
    label: 'Sustainability',
    value: 'SUSTAINABILITY',
  },
  {
    label: 'Technology',
    value: 'TECHNOLOGY',
  },
];

export const MaintenanceRequestServiceType: SelectItem[] = [
  {
    label: 'Toilet',
    value: 'TOILET',
  },
  {
    label: 'Hot water',
    value: 'HOT_WATER',
  },
  {
    label: 'Leak',
    value: 'LEAK',
  },
  {
    label: 'Vanity',
    value: 'VANITY',
  },
  {
    label: 'Taps Showerheads',
    value: 'TAPS_SHOWERHEADS',
  },
  {
    label: 'Waste drain',
    value: 'WASTE_DRAIN',
  },
  {
    label: 'Sink',
    value: 'SINK',
  },
  {
    label: 'Shower screen',
    value: 'SHOWER_SCREEN',
  },
  {
    label: 'Shower base',
    value: 'SHOWER_BASE',
  },
  {
    label: 'Spa',
    value: 'SPA',
  },
  {
    label: 'Baths',
    value: 'BATHS',
  },
  {
    label: 'Mirrors',
    value: 'MIRRORS',
  },
  {
    label: 'Laundry trough',
    value: 'LAUNDRY_TROUGH',
  },
  {
    label: 'Door',
    value: 'DOOR',
  },
  {
    label: 'Door hardware',
    value: 'DOOR_HARDWARE',
  },
  {
    label: 'Plaster',
    value: 'PLASTER',
  },
  {
    label: 'Skirting',
    value: 'SKIRTING',
  },
  {
    label: 'Window/Glazing',
    value: 'WINDOW_GLAZING',
  },
  {
    label: 'Splashback',
    value: 'SPLASHBACK',
  },
  {
    label: 'Bench stone',
    value: 'BENCH_STONE',
  },
  {
    label: 'Bench laminate/Wood',
    value: 'BENCH_LAMINATE_WOOD',
  },
  {
    label: 'Cabinetry',
    value: 'CABINETRY',
  },
  {
    label: 'Vinyl',
    value: 'VINYL',
  },
  {
    label: 'Balistrade',
    value: 'BALISTRADE',
  },
  {
    label: 'Decking',
    value: 'DECKING',
  },
  {
    label: 'Carpet',
    value: 'CARPET',
  },
  {
    label: 'Tile',
    value: 'TILE',
  },
  {
    label: 'Floorboards',
    value: 'FLOORBOARDS',
  },
  {
    label: 'Wood and Gas log fire',
    value: 'WOOD_AND_GAS_LOG_FIRE',
  },
  {
    label: 'Gas heater',
    value: 'GAS_HEATER',
  },
  {
    label: 'Electrical heater',
    value: 'ELECTRICAL_HEATER',
  },
  {
    label: 'Airconditioner',
    value: 'AIRCONDITIONER',
  },
  {
    label: 'Evaporative cooling',
    value: 'EVAPORATIVE_COOLING',
  },
  {
    label: 'Ceiling fans',
    value: 'CEILING_FANS',
  },
  {
    label: 'Smoke alarm repair',
    value: 'SMOKE_ALARM_REPAIR',
  },
  {
    label: 'Meth tester repair',
    value: 'METH_TESTER_REPAIR',
  },
  {
    label: 'Ducted vacuum',
    value: 'DUCTED_VACUUM',
  },
  {
    label: 'Skylight',
    value: 'SKYLIGHT',
  },
  {
    label: 'Render',
    value: 'RENDER',
  },
  {
    label: 'Electrical',
    value: 'ELECTRICAL',
  },
  {
    label: 'Lighting',
    value: 'LIGHTING',
  },
  {
    label: 'Locks',
    value: 'LOCKS',
  },
  {
    label: 'Blinds',
    value: 'BLINDS',
  },
  {
    label: 'Awning',
    value: 'AWNING',
  },
  {
    label: 'Paint',
    value: 'PAINT',
  },
  {
    label: 'Hvac',
    value: 'HVAC',
  },
  {
    label: 'Safes',
    value: 'SAFES',
  },
  {
    label: 'Bbq',
    value: 'BBQ',
  },
  {
    label: 'Garage door/Motor',
    value: 'GARAGE_DOOR_MOTOR',
  },
  {
    label: 'Roller shutter',
    value: 'ROLLER_SHUTTER',
  },
  {
    label: 'Clothes line',
    value: 'CLOTHES_LINE',
  },
  {
    label: 'Kids playground',
    value: 'KIDS_PLAYGROUND',
  },
  {
    label: 'Gates',
    value: 'GATES',
  },
  {
    label: 'Fencing',
    value: 'FENCING',
  },
  {
    label: 'Pergolas',
    value: 'PERGOLAS',
  },
  {
    label: 'Letterbox',
    value: 'LETTERBOX',
  },
  {
    label: 'Outdoor heating',
    value: 'OUTDOOR_HEATING',
  },
  {
    label: 'Gutter/Gutter guard',
    value: 'GUTTER_GUTTER_GUARD',
  },
  {
    label: 'Roofing metal/Tile concrete/Terracotta',
    value: 'ROOFING_METAL_TILE_CONCRETE_TERRACOTTA',
  },
  {
    label: 'Verandah',
    value: 'VERANDAH',
  },
  {
    label: 'Tree removal',
    value: 'TREE_REMOVAL',
  },
  {
    label: 'Garden maintenance',
    value: 'GARDEN_MAINTENANCE',
  },
  {
    label: 'Pool fence/Gate',
    value: 'POOL_FENCE_GATE',
  },
  {
    label: 'Pool equipment',
    value: 'POOL_EQUIPMENT',
  },
  {
    label: 'Shed',
    value: 'SHED',
  },
  {
    label: 'Dryer',
    value: 'DRYER',
  },
  {
    label: 'Washing machine',
    value: 'WASHING_MACHINE',
  },
  {
    label: 'Benchtops',
    value: 'BENCHTOPS',
  },
  {
    label: 'Rangehood',
    value: 'RANGEHOOD',
  },
  {
    label: 'Ovens',
    value: 'OVENS',
  },
  {
    label: 'Water dispenser',
    value: 'WATER_DISPENSER',
  },
  {
    label: 'Dishwasher',
    value: 'DISHWASHER',
  },
  {
    label: 'Wine cabinet',
    value: 'WINE_CABINET',
  },
  {
    label: 'Coffee machine',
    value: 'COFFEE_MACHINE',
  },
  {
    label: 'Cooktop gas/Electrical',
    value: 'COOKTOP_GAS_ELECTRIC',
  },
  {
    label: 'Solar/Enery Management',
    value: 'SOLAR_ENERGY_MANAGEMENT',
  },
  {
    label: 'Insulation',
    value: 'INSULATION',
  },
  {
    label: 'Irrigation',
    value: 'IRRIGATION',
  },
  {
    label: 'Water tank',
    value: 'WATER_TANK',
  },
  {
    label: 'Automation/Communication',
    value: 'AUTOMATION_COMMUNICATION',
  },
];

export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
