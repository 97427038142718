import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';

export const LABELS = {
  TITLE: 'Optimise',
  SORRY: 'Sorry, there are no services currently available. Please check again soon.',
  BACK: 'Back to marketplace',
};

export enum Tab {
  TechSupport = 'Tech support',
  Solar = 'Solar',
}

export const techSupportTab = [
  {
    value: Tab.TechSupport,
    active: false,
  },
];

export const solarTab = [
  {
    value: Tab.Solar,
    active: false,
  },
];

export const initialTags = (otherServices: Services[]) => {
  let result: TagBarItem[] = [];
  if (otherServices.length) {
    const techSupport = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.TechSupport,
    ]);
    const solar = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Solar,
    ]);

    if (techSupport.length) {
      result = [...result, ...techSupportTab];
    }
    if (solar.length) {
      result = [...result, ...solarTab];
    }
  }
  return result;
};
