import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    photoRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      marginBottom: theme.units.spacing * 4,
    },
    photo: {
      width: 60,
      height: 60,
      objectFit: 'cover',
      objectPosition: 'center',
      backgroundColor: theme.colors.lightGrey,
      borderRadius: theme.units.borderRadius,
      overflow: 'hidden',
      marginRight: theme.units.spacing * 4,
      border: 'none',
    },
    photoContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      flex: 1,
      height: 60,
    },
    photoText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginRight: 'auto',
    },
    photoAction: {
      width: theme.units.iconSize * 0.6,
      height: theme.units.iconSize * 0.6,
      objectFit: 'contain',
      objectPosition: 'center',
      transform: 'rotate(45deg)',
    },
    photoActionRotate: {
      transform: 'rotate(0deg) !important',
    },
  })();
};
