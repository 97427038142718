import React, { FC } from 'react';
import { useStyles } from './GenericSectionStyles';
import { KeyValuePair } from '../../../../models/services/genericServiceSummary';

interface GenericSectionProps {
  data: KeyValuePair[];
}

const GenericSection: FC<GenericSectionProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={classes.sectionKey}>{data[0].key}</div>
        <div className={classes.sectionValue}>{data[0].value}</div>
      </div>
      {data.slice(1).map((item, index) => (
        <div key={index} className={classes.marginTop}>
          <div className={classes.sectionKey}>{item.key}</div>
          <div className={classes.sectionValue}>{item.value}</div>
        </div>
      ))}
    </>
  );
};

export default GenericSection;
