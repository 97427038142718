/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import {
  primaryContent,
  primaryContentMaxWidth,
  secondaryContent,
} from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    actionCardContainer: {
      marginTop: 12,
    },
    readMoreScrollStyles: {
      marginTop: 12,
      padding: 12,
      paddingLeft: 0,
      display: 'flex',
      overflow: 'scroll',
    },
    readMoreContainer: {
      marginRight: 12,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} solid 1px`,
      backgroundColor: theme.colors.light,
      width: '100%',
      minHeight: 340,
      flexShrink: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    imageContainer: {
      borderRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'hidden',
    },
    image: {
      height: 300,
      width: '100%',
      objectFit: 'cover',
    },
    textContainer: {
      padding: theme.units.spacing * 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
    text: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    readmore: {
      marginTop: 'auto',
      fontSize: 16,
      marginBottom: theme.units.spacing * 4,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
    },
    addIconContainer: {
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 5000,
    },
    addIcon: {
      width: 22,
      height: 22,
    },
    imageContainerLoader: {
      position: 'relative',
      height: 160,
    },
    imageViewStyle: {
      position: 'absolute',
    },
    imageLoaderStyle: {
      height: 160,
      width: 240,
    },
    offerTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    offerTitle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    offerPrice: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
    },
    offerDescription: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.subtitle,
      marginTop: 12,
    },
    offerActionContainer: {
      backgroundColor: theme.colors.buttonPrimary,
      paddingHorizontal: 12,
      paddingVertical: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 4,
      marginTop: 12,
      overflow: 'hidden',
    },
    offerActionButton: {
      color: theme.colors.light,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
    },
    offerTextContainer: {
      padding: 12,
    },
    sectionTitleStyle: {
      color: theme.colors.black,
      fontSize: 18,
      fontWeight: getFonts('Medium'),
    },
    offerScrollContainer: {
      marginTop: 12,
      backgroundColor: theme.colors.light,
    },
    footerContainer: {
      marginTop: theme.units.spacing * 12,
      backgroundColor: theme.colors.light,
    },
    footerTitle: {
      color: theme.colors.black,
      fontSize: theme.font.xl,
      fontWeight: getFonts('Medium'),
      marginTop: 8,
      marginLeft: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 6,
    },
    footerLinkRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      // borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: theme.units.spacing * 4,
      // paddingBottom: 16,
      backgroundColor: theme.colors.lightGrey,
      padding: theme.units.spacing * 4,
      borderRadius: theme.units.spacing * 4,
    },
    footerLinkText: {
      color: theme.colors.black,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
    },
    footerLinkArrow: {
      height: theme.units.iconSize * 0.6,
      width: theme.units.iconSize * 0.6,
      tintColor: theme.colors.error,
    },
    servicesGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    activeServicesGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    thingsToDo: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    taskStyles: {
      marginRight: theme.units.spacing * 4,
    },
    banner: {
      marginRight: theme.units.spacing * 4,
    },
  })();
};
