/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dialog, Drawer } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ADD_NEW_ICON from '../../../assets/payment/filled.png';
import tick from '../../../../assets/tick.png';
import arrowLeft from '../../../../assets/navigation/arrow-left.png';
import { useStyles } from './ViewCardStyles';
import { extractParams, navigateBack } from '../../../../navigation/NavigationUtils';
import { ViewCardParams } from '../../../../navigation/NavigationConstants';
import { ApplicationState } from '../../../../store/RootReducer';
import Text from '../../../../components/text/Text';
import { LABELS } from './ViewCardConstants';
import Input from '../../../../components/input/Input';
import lockIcon from '../../../../assets/lock-24-px.svg';
import InputDate from '../../../../components/inputDate/InputDate';

interface ViewCardProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const ViewCard: FC<ViewCardProps> = ({ selectedViewPaymentMethod }) => {
  const classes = useStyles();
  const { serviceType } = extractParams<ViewCardParams>();

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>
            <Input
              placeholder={LABELS.CARD_NAME}
              value={selectedViewPaymentMethod!.cardName!}
              setValue={(value: string) => null}
              title={LABELS.CARD_NAME}
              mandatory
              big
              disabled
            />
            <Input
              placeholder={LABELS.CARD_NUMBER}
              value={selectedViewPaymentMethod!.cardNumber!}
              setValue={(value: string) => null}
              title={LABELS.CARD_NUMBER}
              mandatory
              big
              disabled
            />
            <InputDate
              placeholder="Expiry (MM/YY)"
              yearMonthOnly
              value={`${selectedViewPaymentMethod!.cardExpiryMonth}/${
                selectedViewPaymentMethod!.cardExpiryYear
              }`}
              setValue={(value: string) => null}
              title={LABELS.EXPIRY}
              minDate={new Date()}
              blockedDates={[]}
              mandatory
              big
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedViewPaymentMethod: state.payment.selectedViewPaymentMethod,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCard);
