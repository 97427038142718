import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import { useStyles } from './CheckoutSelectStyles';
import {
  BannerAsset,
  LABELS,
  getServiceTypeBanner,
  getServiceTypeCopy,
} from './CheckoutSelectConstants';
import orangePeople from '../../assets/bannerPeople/orange.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import MainBanner from '../../components/mainBanner/MainBanner';
import PropertyBar from './components/propertyBar/PropertyBar';
import ServiceTabs from './components/serviceTabs/ServiceTabs';
import { getQueryParams } from '../../helpers/QueryHelper';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  PageName,
  Preopen,
  RemindMeLaterRequest,
} from '../../models/checkout/Checkout';
import { ServiceTypes } from '../../models/services/services';
import { ApplicationState } from '../../store/RootReducer';
import { CheckoutActionTypes, CheckoutActions } from '../../store/actions/CheckoutActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { Dispatch } from 'redux';
import { Property } from '../../models/property/property';
import { useCart } from '../../helpers/CheckoutHelper';
import {
  getActiveServices,
  getPreopenedServices,
  isFooterButtonDisabled,
  shouldCallCheckout,
} from './CheckoutSelectUtils';
import { CheckoutSelectParams } from '../../navigation/NavigationConstants';
import LoadingServices from './components/loadingServices/LoadingServices';
import noServiceIcon from '../../assets/noservices.png';
import { ValidMessages, sendMessageToApp } from '../../helpers/MessageHelper';
import ServiceContainer from './components/serviceContainer/ServiceContainer';
import { PopupType } from './components/serviceContainer/ServiceContainerConstants';
import CheckoutFooter from './components/checkoutFooter/CheckoutFooter';
import { Dialog } from '@mui/material';
import SlideUp from '../../components/transitionsHelper/SlideUp';
import SlideLeft from '../../components/transitionsHelper/SlideLeft';
import CheckoutCompare from './components/checkoutCompare/CheckoutCompare';
import { useDashboardServices } from '../../helpers/ServiceInfoHelper';

interface CheckoutSelectProps extends RouteComponentProps {
  accessToken: string | boolean | null;
  availableServices: AvailableServicesResponse[] | undefined;
  requiredServiceTypes: ServiceTypes[] | undefined;
  preopen: Preopen | undefined;
  propertyId: string | undefined;
  infoPopupDismissed: boolean;
  isExternal: boolean;
  isCartOpen: boolean;
  isModalOpen: PopupType;
  loading: boolean;
  checkoutProperty: Property | null | undefined;
  getAvailableServices: (data: AvailableServicesRequest) => void;
  setRequiredServiceTypes: (data: ServiceTypes[]) => void;
  setPreopen: (data: Preopen) => void;
  setCheckoutPropertyId: (data: string) => void;
  setDismissedInfoPopup: () => void;
  remindMeLater: (data: RemindMeLaterRequest) => void;
  toggleCart: () => void;
  toggleModal: (data: PopupType) => void;
  setIsLandlord: (value: boolean) => void;
  resetCompare: () => void;
}

const CheckoutSelect: FC<CheckoutSelectProps> = ({
  accessToken,
  availableServices,
  requiredServiceTypes,
  preopen,
  location,
  propertyId,
  infoPopupDismissed,
  isExternal,
  isCartOpen,
  isModalOpen,
  loading,
  checkoutProperty,
  getAvailableServices,
  setRequiredServiceTypes,
  setPreopen,
  setCheckoutPropertyId,
  setDismissedInfoPopup,
  remindMeLater,
  toggleCart,
  toggleModal,
  setIsLandlord,
  resetCompare,
}) => {
  const classes = useStyles();
  const requiredURLServiceTypes = getQueryParams(location, 'requiredServiceTypes');
  const preopenURL = getQueryParams(location, 'preopen');
  const propertyIdURL = getQueryParams(location, 'propertyId');
  const isLandlordURL = getQueryParams(location, 'isLandlord');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showCompare, setShowCompare] = useState<boolean>(false);
  const { property } = useDashboardServices();
  const { cartCount, cartItems, compareCount } = useCart();

  const delayedCheckoutCall = useCallback(
    debounce(
      (
        availableServices: AvailableServicesResponse[] | undefined,
        requiredServiceTypes: ServiceTypes[] | undefined,
        propertyId: string | undefined,
        checkoutProperty: Property | null | undefined,
      ) => {
        if (
          shouldCallCheckout(availableServices, requiredServiceTypes, propertyId, checkoutProperty)
        ) {
          getAvailableServices({
            propertyId: propertyId!,
            hideLoading: true,
            requiredServiceTypes: requiredServiceTypes || [],
          });
          remindMeLater({ propertyId: propertyId!, flag: false });
        }
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    if (isLandlordURL) {
      setIsLandlord(true);
    }
  }, [isLandlordURL]);

  useEffect(() => {
    delayedCheckoutCall(availableServices, requiredServiceTypes, propertyId, checkoutProperty);
  }, [propertyId, requiredServiceTypes, accessToken, availableServices, checkoutProperty]);

  useEffect(() => {
    if (requiredURLServiceTypes) {
      setRequiredServiceTypes(requiredURLServiceTypes.split(',') as ServiceTypes[]);
    }
  }, [requiredURLServiceTypes]);

  useEffect(() => {
    if (preopenURL) {
      try {
        setPreopen(JSON.parse(preopenURL));
      } catch (err) {
        console.log(err);
      }
    }
  }, [preopenURL]);

  useEffect(() => {
    if (property !== undefined) {
      setCheckoutPropertyId(property.id.toString());
    } else if (propertyIdURL) {
      setCheckoutPropertyId(propertyIdURL);
    }
  }, [propertyIdURL, property]);

  const activeServices = useMemo<AvailableServicesResponse[]>(() => {
    return getActiveServices(requiredServiceTypes, cartItems, availableServices);
  }, [requiredServiceTypes, cartItems, availableServices]);

  const selectedServiceType = useMemo<AvailableServicesResponse | undefined>(() => {
    if (activeServices.length && !loading) {
      return activeServices[selectedTab];
    } else {
      return undefined;
    }
  }, [activeServices, selectedTab, loading]);

  const preopenedServices = useMemo<Preopen>(() => {
    const result = getPreopenedServices(preopen, cartItems);
    console.log('result', result);
    return result;
  }, [cartItems, preopen]);

  const serviceTypeCopy = useMemo<string | null>(() => {
    return getServiceTypeCopy(
      selectedServiceType ? (selectedServiceType.type as ServiceTypes) : undefined,
    );
  }, [selectedServiceType]);

  const bannerAssets = useMemo<BannerAsset>(() => {
    return getServiceTypeBanner(
      selectedServiceType ? (selectedServiceType.type as ServiceTypes) : undefined,
    );
  }, [selectedServiceType]);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <MainBanner
          title={bannerAssets.title}
          subtitle={bannerAssets.description}
          image={bannerAssets.image}
          backgroundColor={bannerAssets.backgroundColor}
        />
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <PropertyBar />
            {loading ? (
              <LoadingServices />
            ) : (
              <>
                {activeServices.length && !!selectedServiceType ? (
                  <>
                    {activeServices.length !== 1 && (
                      <>
                        <ServiceTabs
                          activeServices={activeServices}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          pageName={PageName.CHECKOUTSELECT}
                        />
                        {serviceTypeCopy && (
                          <div className={classes.serviceTypeCopy}>{serviceTypeCopy}</div>
                        )}
                      </>
                    )}
                    <div key={`service_${selectedServiceType}`}>
                      <ServiceContainer
                        key={`service_${selectedServiceType}`}
                        isPreopened={preopenedServices.hasOwnProperty(selectedServiceType.type)}
                        preopenedDetails={preopenedServices[selectedServiceType.type]}
                        service={selectedServiceType}
                      />
                    </div>
                  </>
                ) : (
                  <div className={classes.noServiceContainer}>
                    <img src={noServiceIcon} className={classes.noServiceImage} />
                    <div className={classes.noServiceTitleStyle}>{LABELS.NOTHINGTITLE}</div>
                    <div className={classes.noServiceSubtextSyle}>{LABELS.NOTHIN}</div>
                    <div
                      className={classes.contactSupportButtonStyle}
                      onClick={() => {
                        if (isExternal) {
                          (window as any).Intercom('show');
                        } else {
                          sendMessageToApp(ValidMessages.Chat);
                        }
                      }}
                    >
                      {LABELS.CONTACT_SUPPORT}
                    </div>
                  </div>
                )}
              </>
            )}
            <Dialog
              open={showCompare}
              fullWidth
              maxWidth={compareCount === 2 ? 'md' : 'lg'}
              style={{ zIndex: 999999 }}
              TransitionComponent={SlideLeft}
              onClose={() => setShowCompare(false)}
              PaperProps={{ sx: { borderRadius: '16px' } }}
            >
              <div className={classes.popupContainer}>
                <CheckoutCompare closePopup={() => setShowCompare(false)} />
              </div>
            </Dialog>
            <CheckoutFooter
              onCompare={() => null}
              onPrimary={() => {
                if (!!compareCount) {
                  setShowCompare(true);
                } else {
                  if (selectedTab === activeServices.length - 1) {
                    navigate('checkoutConfigure');
                  } else {
                    setSelectedTab(selectedTab + 1);
                  }
                }
              }}
              disabledPrimary={isFooterButtonDisabled(selectedServiceType) || !cartCount}
              hideSecondary={
                !isFooterButtonDisabled(selectedServiceType) ||
                (selectedTab === activeServices.length - 1 && !cartCount)
              }
              last={selectedTab === activeServices.length - 1}
              onSecondary={() => {
                if (!!compareCount) {
                  resetCompare();
                } else {
                  if (selectedTab === activeServices.length - 1) {
                    navigate('checkoutConfigure');
                  } else {
                    setSelectedTab(selectedTab + 1);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  checkoutProperty: state.checkout.property,
  availableServices: state.checkout.availableServices,
  requiredServiceTypes: state.checkout.requiredServiceTypes,
  preopen: state.checkout.preopen,
  propertyId: state.checkout.propertyId,
  infoPopupDismissed: state.checkout.infoPopupDismissed,
  isExternal: state.token.isExternal,
  isCartOpen: state.checkout.isCartOpen,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
  setRequiredServiceTypes: (data: ServiceTypes[]) =>
    dispatch(CheckoutActions.setRequiredServiceTypes(data)),
  setPreopen: (data: Preopen) => dispatch(CheckoutActions.setPreopen(data)),
  setCheckoutPropertyId: (data: string) => dispatch(CheckoutActions.setCheckoutPropertyId(data)),
  setDismissedInfoPopup: () => dispatch(CheckoutActions.dismissedInfoPopup()),
  remindMeLater: (data: RemindMeLaterRequest) => dispatch(CheckoutActions.remindMeLater(data)),
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
  toggleModal: (data: PopupType) => dispatch(CheckoutActions.toggleModal(data)),
  setIsLandlord: (value: boolean) => dispatch(CheckoutActions.setIsLandlord(value)),
  resetCompare: () => dispatch(CheckoutActions.resetCompare()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutSelect));
