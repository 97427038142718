import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';
import {
  WalletProfileResponse,
  WalletBreakdownRequest,
  WalletBreakdownResponse,
  WalletSpendRequest,
  AddPromoCodeRequest,
  AddPromoCodeResponse,
} from '../../models/wallet/wallet';

export class WalletService {
  public getWalletProfile(): Promise<WalletProfileResponse> {
    const service = new ApiService(ServiceType.wallet);
    const path: QueryPath = {
      route: ['walletProfile'],
    };
    return service.post(path, {});
  }

  public getWalletBreakdown(params: WalletBreakdownRequest): Promise<WalletBreakdownResponse> {
    const service = new ApiService(ServiceType.wallet);
    const path: QueryPath = {
      route: ['walletProfilePromotions'],
    };
    return service.post(path, params);
  }

  public applyWalletSpend(params: WalletSpendRequest[]): Promise<WalletProfileResponse> {
    const service = new ApiService(ServiceType.wallet);
    const path: QueryPath = {
      route: ['walletSpendRequest'],
    };
    return service.post(path, params);
  }

  public addPromoCode(params: AddPromoCodeRequest): Promise<AddPromoCodeResponse> {
    const service = new ApiService(ServiceType.Payment);
    const path: QueryPath = {
      route: ['walletAddPromotionCode'],
    };
    return service.post(path, params);
  }
}

const walletService = new WalletService();
export default walletService;
