import { get, pickBy } from 'lodash';
import { InitialConcessionCardValues } from '../../models/checkout/Checkout';
import {
  FeatureDetails,
  FooterPricing,
  KeyValueData,
  Link,
  Options,
  PlanDetailObject,
  ProductDetails,
  ProviderPlan,
  SummaryDetail,
} from '../../models/billing/Billing';
import { dollarFormatter } from '../../helpers/CurrencyHelper';

export const checkPlanDetails = (
  planSummary: Map<string, FeatureDetails> | {},
  concessionCard: InitialConcessionCardValues | null,
) => {
  const unAnswered = pickBy(planSummary, (value: FeatureDetails, key: string) => {
    return !value.isMandatory || value.value;
  });
  const isConcessionCardRequired = concessionCard
    ? concessionCard.name &&
      concessionCard.expiry &&
      concessionCard.cardNumber &&
      concessionCard.cardType
    : true;
  return (
    Object.keys(planSummary).length === Object.keys(unAnswered).length && !!isConcessionCardRequired
  );
};

export const allQuestionsAnswered = (
  planSummary: Map<string, FeatureDetails> | {},
  concessionCard: InitialConcessionCardValues | null,
) => {
  let isThereText = false;
  const unAnswered = pickBy(planSummary, (value: FeatureDetails, key: string) => {
    if (value.componentType === 'Text') {
      isThereText = true;
    }
    return value.value;
  });
  if (isThereText) {
    return false;
  }
  const isConcessionCardRequired = concessionCard
    ? concessionCard.name &&
      concessionCard.expiry &&
      concessionCard.cardNumber &&
      concessionCard.cardType
    : true;
  return (
    Object.keys(planSummary).length === Object.keys(unAnswered).length && !!isConcessionCardRequired
  );
};

export const getFooterLabel = (
  productOptions: Options,
  frequency: string,
  pricingLabel?: string,
): string => {
  if (productOptions) {
    return dollarFormatter(productOptions[frequency]) === 'N/A'
      ? ''
      : dollarFormatter(productOptions[frequency]) + (pricingLabel ? pricingLabel : '');
  }
  return '';
};

export const setSummary = (productDetails: ProductDetails, extendedData: ProviderPlan) => {
  const benefits = extendedData.Plans[0];
  const planDetailName: KeyValueData = {
    key: extendedData.ProductType + ' Name',
    value: get(productDetails as ProductDetails, 'productName', ''),
  };
  let planDetailCost: KeyValueData[] = [];
  extendedData.Plans[0].FooterPricing.map((footerPricing: FooterPricing) => {
    if (footerPricing.PriceField !== null && footerPricing.PriceField.length > 0) {
      planDetailCost = [
        ...planDetailCost,
        {
          key: footerPricing.UnitOfMeasure,
          value: productDetails ? (get(productDetails, 'monthlyCost', '') as string) : '0',
        },
      ];
    }
  });
  let planDetailDescription: KeyValueData[] = [];
  if (benefits.DescriptionFooter && benefits.DescriptionFooter.length > 0) {
    planDetailDescription = [
      {
        key: 'Estimated Cost',
        value: get(benefits, 'DescriptionFooter', ''),
      },
    ];
  }

  const planDetailData = [planDetailName, ...planDetailCost, ...planDetailDescription];
  const summaryPlanDetail: SummaryDetail = {
    sectionTitle: 'Plan summary',
    data: planDetailData,
  };
  const data = [];
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < benefits.Benefits.length; i++) {
    const summaryData = {
      key: get(benefits.Benefits[i], 'Heading', ''),
      value: get(benefits.Benefits[i], 'Body', ''),
    };
    data.push(summaryData);
  }
  const summaryBenefits: SummaryDetail = {
    sectionTitle: 'Benefits',
    data,
  };

  const documensList: KeyValueData[] = [];
  if (benefits && benefits.Links && benefits.Links.length > 0) {
    benefits.Links.forEach((link: Link) => {
      documensList.push({
        key: get(link, 'Label', '') as string,
        value: get(link, 'Link', ''),
      });
    });
  }
  const summaryDocuments: SummaryDetail = {
    sectionTitle: 'Documents',
    data: documensList,
  };
  const summary: SummaryDetail[] = [summaryPlanDetail, summaryBenefits, summaryDocuments];

  return summary;
};

export const getMonthlyDefault = (extendedDataPlan: PlanDetailObject) => {
  const found = (extendedDataPlan as PlanDetailObject).FooterPricing.find(
    (f) => f.PriceField === 'BasePriceMonthly',
  );

  if (found) {
    return found.PriceField;
  } else {
    return (extendedDataPlan as PlanDetailObject).FooterPricing[0].PriceField;
  }
};
