/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    boxContainer: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColor} 2px solid`,
      backgroundColor: theme.colors.light,
      transition: 'all 0.5s ease 0s',
    },
    lightBorder: {
      border: `${theme.colors.borderColorLight} 2px solid`,
    },
    selected: {
      border: `${theme.colors.secondary} 2px solid`,
    },
    highlight: {
      backgroundColor: theme.colors.highlightButtonColor,
    },
    clickable: {
      cursor: 'pointer',
    },
    boxWrapper: {
      padding: theme.units.spacing * 4,
    },
    loading: {
      border: 'none',
      ...loadingAnimation,
    },
  })();
};
