import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { actionGreen, loadingAnimation } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    propertyContainer: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      padding: theme.units.spacing * 4,
      backgroundColor: theme.colors.light,
      position: 'relative',
      display: 'flex',
      marginBottom: theme.units.spacing * 6,
    },
    propertyWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    photoContainer: {
      width: 42,
      height: 42,
      borderRadius: 500,
      marginRight: theme.units.spacing * 4,
      overflow: 'hidden',
    },
    photo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    titleContainer: {
      marginRight: 'auto',
    },
    address: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    address2: {
      marginTop: 4,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    changePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    accordian: {
      width: theme.units.iconSize * 0.5,
      height: theme.units.iconSize * 0.5,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: 12,
      marginLeft: 24,
      ...actionGreen,
    },
    propertyMenu: {
      position: 'absolute',
      top: 90,
      left: 0,
      minWidth: 'max-content',
      paddingTop: theme.units.spacing * 2,
      paddingBottom: theme.units.spacing * 2,
      backgroundColor: theme.colors.inputBackground,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 2px solid`,
      zIndex: 10,
      width: '100%',
    },
    menuDivider: {
      height: 1,
      width: '100%',
      backgroundColor: theme.colors.borderColorLight,
      marginTop: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 2,
    },
    menuItem: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      padding: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    menuItemLight: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
      color: theme.colors.inputText,
      padding: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
        fontWeight: getFonts('Medium'),
      },
    },
    selectedMenuItem: {
      color: theme.colors.buttonPrimary,
      fontWeight: getFonts('Medium'),
    },
    loadingPhoto: {
      width: 42,
      height: 42,
      borderRadius: 500,
      marginRight: theme.units.spacing * 4,
      ...loadingAnimation,
    },
    loadingAddress: {
      width: 140,
      borderRadius: theme.units.borderRadius,
      height: 18,
      ...loadingAnimation,
    },
    loadingAddress2: {
      marginTop: 4,
      width: 100,
      borderRadius: theme.units.borderRadius,
      height: 14,
      ...loadingAnimation,
    },
  })();
};
