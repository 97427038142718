import summer from '../../assets/articles/summer.jpg';
import clock from '../../assets/whatsNext/clock.png';
import mail from '../../assets/whatsNext/mail.png';
import edit from '../../assets/whatsNext/electricity.png';
import truck from '../../assets/whatsNext/essentials.png';

export const LABELS = {
  SUCCESS_TITLE: 'Nice work!',
  SUCCESS_SUBITLE:
    "Your request has been submitted. Thank you for choosing our services, here's summary below",
  WHATS_NEXT: 'What happens next?',
  FOR_YOU: 'For you',
  BLOG_TITLE: 'Prepping your home for the warmer weather',
  BLOG_BODY:
    'Summer is just around the corner and before we know it we will be cranking the aircon and...',
  BLOG_LINK: 'https://www.sortedservices.com/blog-spot/prepping-your-home-for-the-warmer-weather',
  BLOG_IMAGE: summer,
  SUMMARY: 'Your summary',
};

export const WHAT_HAPPENS_NEXT = [
  {
    icon: clock,
    time: '2-3mins',
    title: "We'll let our providers know",
    description: "Sit back and relax! Our team is on it. We'll take care of everything from here",
  },
  {
    icon: mail,
    time: '+2 days',
    title: "You'll be notified",
    description:
      'Keep an eye on your messages and email (remember to check junk spam) for updates and relevant details',
  },
  {
    icon: edit,
    time: '3-7 days',
    title: 'Get connected!',
    description:
      'Depending on the nature of the service or referral, connection will occur as soon as possible',
  },
  {
    icon: truck,
    time: '+7 days',
    title: 'Stay informed',
    description:
      'Download the Sorted app to see your connected services + quotes, you can managed everything using your Sorted account',
  },
];
