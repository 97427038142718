/* eslint-disable import/prefer-default-export */

export enum AustraliaState {
  ACT = 'ACT',
  NSW = 'NSW',
  SA = 'SA',
  TAS = 'TAS',
  QLD = 'QLD',
  VIC = 'VIC',
  WA = 'WA',
  NT = 'NT',
}

export const STATES = ['ACT', 'NSW', 'SA', 'TAS', 'QLD', 'VIC', 'WA', 'NT'];

export const STATE_MAP = {
  'new south wales': 'NSW',
  'northern territory	': 'NT',
  'queensland': 'QLD',
  'south australia': 'SA',
  'tasmania': 'TAS',
  'victoria': 'VIC',
  'western australia': 'WA',
};
