import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS, LINKS, cartUrls, hideAddressBarUrls, hideRightLinkUrls } from './HeaderConstants';
import { useStyles } from './HeaderStyles';
import sortedLogo from '../../assets/sorted.png';
import naxLogo from '../../assets/nax.png';
import profile from '../../assets/navigation/profile.png';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { routes } from '../../Routes';
import { ClickAwayListener, Dialog } from '@mui/material';
import { navigate, resetNavigation } from '../../navigation/NavigationUtils';
import house from '../../assets/navigation/home.png';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducer';
import { DashboardState } from '../../store/state/DashboardState';
import { Dispatch } from 'redux';
import { Property } from '../../models/property/property';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { AuthActions } from '../../store/actions/AuthActions';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';
import { useCart } from '../../helpers/CheckoutHelper';
import { CheckoutActionTypes, CheckoutActions } from '../../store/actions/CheckoutActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import cartIcon from '../../assets/cart.png';
import SlideLeft from '../transitionsHelper/SlideLeft';
import Cart from '../../containers/cart/Cart';

interface HeaderProps extends RouteComponentProps {
  dashboardState: DashboardState;
  isGuest: boolean;
  isCartOpen: boolean;
  loadingCheckout: boolean;
  setSelectedProperty: (index: number) => void;
  logout: () => void;
  toggleCart: () => void;
}

const Header: FC<HeaderProps> = ({
  history,
  location,
  dashboardState: { onboardedProperties, selectedProperty },
  isGuest,
  isCartOpen,
  loadingCheckout,
  toggleCart,
  setSelectedProperty,
  logout,
}) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showPropertyPopup, setShowPropertyPopup] = useState<boolean>(false);
  const hideRightLink = hideRightLinkUrls.some((s) => location.pathname.includes(s));
  const hideAddressBar = hideAddressBarUrls.some((s) => location.pathname.includes(s));
  const themeKey = getCurrentThemeKey();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { cartCount } = useCart();

  const showCart = cartUrls.some((s) => location.pathname.includes(s));

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isUserLoggedIn = !!(
    localStorage.getItem('userId') && localStorage.getItem('userId') !== 'undefined'
  );

  const signOut = () => {
    setMenuOpen(false);
    logout();
    resetNavigation('login');
  };

  const goToProfile = () => {
    setMenuOpen(false);
    navigate('account');
  };

  const goToSupport = () => {
    setMenuOpen(false);
    navigate('support');
  };

  const handlePropertySelect = (index: number) => {
    setShowPropertyPopup(false);
    if (selectedProperty !== index) {
      setSelectedProperty(index);
    }
  };

  return (
    <div className={classes.headerContainer}>
      <div
        className={classes.sortedLogoContainer}
        style={isUserLoggedIn ? { cursor: 'pointer' } : {}}
        onClick={() => {
          if (isUserLoggedIn) {
            navigate('home', { refresh: true });
          }
        }}
      >
        <img
          src={themeKey === ThemeKey.NAX ? naxLogo : sortedLogo}
          className={themeKey === ThemeKey.NAX ? classes.naxLogo : classes.sortedLogo}
          alt="sorted-logo"
        />
      </div>
      {!hideRightLink && (
        <>
          {isUserLoggedIn ? (
            <div className={classes.rightContent}>
              {onboardedProperties && onboardedProperties.length > 0 && !hideAddressBar && (
                <div
                  className={classes.propertyContainer}
                  onClick={() => {
                    if (isGuest) {
                      navigate('login');
                    } else {
                      setShowPropertyPopup(true);
                    }
                  }}
                >
                  <div className={classes.propertyText}>
                    {`${onboardedProperties[selectedProperty].property!.address.substring(
                      0,
                      18,
                    )}...`}
                  </div>
                  <img src={house} className={classes.house} alt="house" />
                </div>
              )}

              <div
                className={classNameGenerator([classes.profileContainer])}
                onClick={() => setMenuOpen(true)}
              >
                <div className={classes.profileText}>{LABELS.YOU}</div>
                <img className={classes.profileIcon} src={profile} alt="profile" />
              </div>
              {showCart && !loadingCheckout && (
                <div className={classes.cartContainer} onClick={() => toggleCart()}>
                  <img src={cartIcon} className={classes.cartIcon} alt="comapre" />
                  {!!cartCount && <div className={classes.cartCount}>{cartCount}</div>}
                </div>
              )}
            </div>
          ) : (
            <div className={classes.rightContent}>
              <div className={classes.profileContainer} onClick={() => navigate('login')}>
                <div className={classes.profileText}>{LABELS.LOGIN}</div>
                <img className={classes.profileIcon} src={profile} alt="profile" />
              </div>
            </div>
          )}
        </>
      )}
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <div className={classes.menu}>
            <div className={classes.menuItem} onClick={() => goToProfile()}>
              Profile
            </div>
            <div className={classes.menuDivider} />
            <div className={classes.menuItemLight} onClick={() => goToSupport()}>
              Support
            </div>
            <div className={classes.menuItemLight} onClick={() => (window as any).Intercom('show')}>
              Help
            </div>
            <div className={classes.menuItemLight} onClick={() => signOut()}>
              Log out
            </div>
          </div>
        </ClickAwayListener>
      )}
      {showPropertyPopup && (
        <ClickAwayListener onClickAway={() => setShowPropertyPopup(false)}>
          <div className={classes.propertyMenu}>
            <div className={classes.menuItem}>My properties</div>
            <div className={classes.menuDivider} />
            {onboardedProperties &&
              onboardedProperties.map((property, index) => {
                const { unitNumber, streetNumber, streetName, state, suburb, postcode } =
                  property.property as Property;
                const address1 = `${unitNumber ? `${unitNumber}/` : ''}${
                  streetNumber || ''
                } ${streetName}, ${suburb}, ${state} ${postcode}`;

                return (
                  <div
                    key={index}
                    className={classNameGenerator([
                      classes.menuItemLight,
                      selectedProperty === index && classes.selectedMenuItem,
                    ])}
                    onClick={() => handlePropertySelect(index)}
                  >
                    {address1}
                  </div>
                );
              })}
          </div>
        </ClickAwayListener>
      )}
      <Dialog
        open={isCartOpen}
        onClose={() => toggleCart()}
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <Cart configure={location.pathname.includes('checkoutConfigure')} />
      </Dialog>
    </div>
  );
};

const loadingCheckout = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  isCartOpen: state.checkout.isCartOpen,
  loadingCheckout: loadingCheckout(state),
  isGuest: state.authState.guest,
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedProperty: (index: number) => dispatch(DashboardActions.setSelectedProperty(index)),
  logout: () => dispatch(AuthActions.postLogoutRequest()),
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
