import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  pageContainer,
  smallPageContainer,
  primaryContent,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        ...smallPageContainer,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 8,
      },
    },
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingTop: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 800,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    boxContainer: {
      padding: theme.units.spacing * 6,
      borderRadius: theme.units.borderRadius * 2,
      borderWidth: 1,
    },
    centerContent: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    sortedLogo: {
      width: 50,
      height: 50,
      objectFit: 'contain',
      objectPosition: 'center',
      marginBottom: theme.units.spacing * 6,
    },
    title: {
      marginBottom: theme.units.spacing * 4,
    },
    text: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    loginRow: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    loginButton: {
      flex: 1,
      paddingTop: theme.units.spacing * 3,
      paddingBottom: theme.units.spacing * 3,
    },
  })();
};
