import { SIZES } from '../constants/Sizes';
import { theme } from './Theme';

export const globalStyles = {
  paperBack: {
    margin: 'auto',
    top: '-100px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    [`@media (min-width: ${SIZES.md}px)`]: {
      position: 'relative',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: 0,
      boxShadow: 'none',
    },
    // ipad-pro
    [`@media (min-device-width: ${SIZES.lg}px) and (max-device-width: ${SIZES.lg}px) and (min-device-height:${SIZES.lgMax}px) and (max-device-height:${SIZES.lgMax}px) and (orientation: portrait)`]:
      {
        position: 'relative',
      },
    [`@media (min-device-width:${SIZES.lgMax}px) and (max-device-width:${SIZES.lgMax}px) and (min-device-height:${SIZES.lg}px) and (max-device-height:${SIZES.lg}px) and (orientation: landscape)`]:
      {
        position: 'relative',
      },
  },
  secondaryTile: {
    margin: 'auto',
    position: 'relative',
    top: '-70px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: '20px',
      marginTop: '25px',
      top: 0,
    },
    marginBottom: '30px',
  },
  datePicker: {
    width: '100%',
    fontSize: '16px',
    paddingTop: '5px',
    paddingBottom: '10px',
  },
};

export const maxContentWidth = {
  maxWidth: 1800,
  margin: 'auto',
};

export const pageContainer = {
  position: 'relative',
  margin: 'auto',
  padding: 80,
  width: 0,
  minWidth: 'calc(100% - 192px)',
  [`@media (max-width: ${SIZES.lg}px)`]: {
    paddingLeft: 48,
    paddingRight: 48,
    minWidth: 'calc(100% - 96px)',
  },
  [`@media (max-width: ${SIZES.md}px)`]: {
    paddingLeft: 12,
    paddingRight: 12,
    minWidth: 'calc(100% - 24px)',
  },
} as const;

export const smallPageContainer = {
  position: 'relative',
  margin: 'auto',
  padding: 80,
  paddingLeft: 48,
  paddingRight: 48,
  width: 0,
  minWidth: 'calc(100% - 96px)',
  [`@media (max-width: ${SIZES.lg}px)`]: {
    paddingLeft: 24,
    paddingRight: 24,
    minWidth: 'calc(100% - 48px)',
  },
  [`@media (max-width: ${SIZES.md}px)`]: {
    paddingLeft: 12,
    paddingRight: 12,
    minWidth: 'calc(100% - 24px)',
  },
} as const;

export const pageTitleContainer = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 48,
  marginTop: 24,
};

export const primaryContent = {
  width: '100%',
  maxWidth: 1200,
};

export const secondaryContent = {
  flexShrink: 0,
  marginLeft: 24,
  [`@media (max-width: ${SIZES.lg}px)`]: {
    marginLeft: 12,
  },
  maxWidth: 380,
};

export const actionGreen = {
  // converts black to #0C7181
  filter: 'invert(21%) sepia(54%) saturate(7456%) hue-rotate(177deg) brightness(89%) contrast(91%)',
};

export const primaryContentMaxWidth = {
  maxWidth: 1200,
  width: `calc((((100vw - 320px) - 192px) - 48px) - 340px)`,
  [`@media (max-width: ${SIZES.lgMax}px)`]: {
    width: `calc(((100vw - 320px) - 192px))`,
  },
  [`@media (max-width: ${SIZES.lg}px)`]: {
    width: 'calc(100vw - (87px + 48px))',
  },
  [`@media (max-width: ${SIZES.md}px)`]: {
    width: 'calc(100vw - (87px + 24px))',
  },
  [`@media (max-width: ${SIZES.sm}px)`]: {
    width: '100%',
  },
};

export const loadingAnimation = {
  background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
  backgroundSize: '200% 100%',
  animation: '1.5s shine linear infinite',
  backgroundColor: theme.colors.offerOverlay,
};

export const classNameGenerator = (classes: (string | undefined | null | boolean)[]) => {
  return classes.join(' ');
};
