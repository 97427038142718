import * as Yup from 'yup';

export const errorMessages = {
  required: 'Required field',
};

export const Validation = Yup.object().shape({
  name: Yup.string()
    .required(errorMessages.required)
    .max(20)
    .matches(/^(\w+\s*\w*)$/, 'Please enter only alphabetical letters'),
  cardNumber: Yup.string().required(errorMessages.required),
  expiry: Yup.string().required(errorMessages.required).min(5).max(5),
});
