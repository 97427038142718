import { Agency } from '../agency/agency';
import { RentalFrequency } from '../lease/lease';
import { Property } from '../property/property';
import { User } from '../user/user';

export interface GetRentOnboardingPayload {
  onSuccess: (response: GetRentOnboardingResponse[]) => void;
}

export interface PostRentOnboardingPayload {
  data: PostRentOnboardingRequest;
  onSuccess: () => void;
}

export interface PostRentOnboardingRequest {
  readonly leaseRefId: string;
  readonly paymentRefId: string | null;
}

export interface PostServiceabilityPayload {
  data: PostServiceabilityRequest[];
  leaseId: number;
  onSuccess: () => void;
}

export interface PostServiceabilityRequest {
  serviceType: string;
  serviceability: Serviceability;
  providerId: number;
  providerName: string;
}

export interface PostRentOnboardingResponse {
  serviceAccount: {
    id: number;
    serviceType: string;
    serviceAddress: string;
    customerNumber: string;
    serviceNumber: string;
    provider: string;
    provisioningStatus: string;
    productCode: null | string;
    productName: null | string;
    productDescription: null | string;
    productType: null | string;
    cost: number;
    activatedDate: null | string;
    closedDate: null | string;
    extendedDataMap: any;
    status: string;
    createdBy: string;
    createdDate: string;
    lastModifiedDate: string;
    lastModifiedBy: string;
  };
}

export interface GetRentOnboardingResponse {
  readonly leaseId: number;
  readonly leaseRefId: string;
  readonly property: Property | undefined | null;
  readonly agency: Agency;
  readonly primaryTenant: User | undefined | null;
  readonly monthlyCost: number | undefined | null;
  readonly paymentEnabled: boolean;
  readonly paymentFrequency: RentalFrequency | null;
  readonly nextPaymentDate: string;
  serviceability: Services[];
  readonly paymentDetails?: PaymentDetails;
  readonly initialPayments: InitialPaymentDetails[];
  readonly cimetEnabled?: boolean | null;
}

export interface Services {
  serviceability: Serviceability;
  serviceType: string;
  serviceProvider: ServiceProvider;
}

export enum Serviceability {
  OPT_YES = 'OPT_YES',
  OPT_NO = 'OPT_NO',
  OPT_MANDATORY = 'OPT_MANDATORY',
}

export interface ServiceProvider {
  id: number;
  name: string;
  icon: string;
}

export interface PaymentDetails {
  paymentProvider: PaymentProvider | null;
  billInfo: string | null;
  paymentReferenceId: string | null;
}

export interface InitialPaymentDetails {
  amount: number;
  paymentDate: string | Date;
  paymentType: string;
}

export enum PaymentProvider {
  DEFT = 'DEFT',
  BPAY = 'BPAY',
  SORTED = 'SORTED',
  Other = 'Other',
}
