import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './CreatingAccountStyles';
import { LABELS } from './CreatingAccountConstants';
import Box from '../../components/box/Box';
import Text from '../../components/text/Text';
import Button from '../../components/button/Button';
import { extractParams, navigate } from '../../navigation/NavigationUtils';
import sortedLogo from '../../assets/sorted-icon-dark.png';
import { connect } from 'react-redux';
import { AuthDetails, AuthDetailsResponse, LoginRequest } from '../../models/auth/auth';
import { ApplicationState } from '../../store/RootReducer';
import { AuthActionTypes, AuthActions } from '../../store/actions/AuthActions';
import { ProfileActionTypes } from '../../store/actions/ProfileActions';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { Dispatch } from 'redux';
import { CreatingAccountParams } from '../../navigation/NavigationConstants';

interface CreatingAccountProps {
  loading: boolean;
  loadingProfile: boolean;
  loginWithToken: (request: AuthDetailsResponse) => void;
}

const CreatingAccount: FC<CreatingAccountProps> = ({ loading, loadingProfile, loginWithToken }) => {
  const { response, error } = extractParams<CreatingAccountParams>();
  const classes = useStyles();

  if (error) {
    navigate('errorScreen');
    console.log('error', error);
  }

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <Box lightBorder parentStyles={classes.boxContainer}>
              {loading ? (
                <div className={classes.centerContent}>
                  <img src={sortedLogo} className={classes.sortedLogo} />
                  <Text parentStyles={classes.title} textVariant="title" size="xl">
                    {LABELS.LOADING}
                  </Text>
                </div>
              ) : (
                <>
                  <div className={classes.centerContent}>
                    <img src={sortedLogo} className={classes.sortedLogo} />

                    <Text parentStyles={classes.title} textVariant="title" size="xl">
                      {LABELS.TITLE}
                    </Text>
                    <Text textVariant="info" parentStyles={classes.text}>
                      {LABELS.DESCRIPTION}
                    </Text>
                  </div>
                  <div className={classes.loginRow}>
                    <Button
                      parentStyles={classes.loginButton}
                      type="submit"
                      loading={loadingProfile}
                      disabled={loadingProfile}
                      onPress={() => loginWithToken(response!)}
                    >
                      {LABELS.DONE}
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([AuthActionTypes.REGISTER, ProfileActionTypes.GET_PROFILE]);
const loadingProfile = loadingSelector([
  ProfileActionTypes.GET_USER_ROLES,
  ProfileActionTypes.GET_PROFILE,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  loadingProfile: loadingProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginWithToken: (request: AuthDetailsResponse) => dispatch(AuthActions.loginWithToken(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatingAccount);
