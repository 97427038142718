import React, { FC, useEffect } from 'react';
import { useStyles } from './CheckoutFooterStyles';
import compareIcon from '../../../../assets/compare.png';
import cartIcon from '../../../../assets/cart.png';
import { LABELS } from './CheckoutFooterConstants';
import { FooterPricing } from '../../../../models/billing/Billing';
import { useCart } from '../../../../helpers/CheckoutHelper';
import Button from '../../../../components/button/Button';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { CheckoutActionTypes, CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';

interface CheckoutFooterProps {
  disabledPrimary?: boolean;
  hideSecondary?: boolean;
  loadingCheckout: boolean;
  last?: boolean;
  onCompare?: () => void;
  onPrimary: () => void;
  onSecondary: () => void;
  toggleCart: () => void;
}

const CheckoutFooter: FC<CheckoutFooterProps> = ({
  disabledPrimary,
  hideSecondary,
  loadingCheckout,
  last,
  onCompare,
  onPrimary,
  onSecondary,
  toggleCart,
}) => {
  const classes = useStyles();
  const { compareCount, cartCount, cartServices, cartReferrals } = useCart();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.menuBuffer} />
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <div className={classes.footerContent}>
            <div className={classes.footerWrapper}>
              {!!compareCount ? (
                <div className={classes.leftContent}>
                  <img src={compareIcon} className={classes.compareIcon} alt="comapre" />
                  <div className={classes.compareText}>{`Compare plans (${compareCount})`}</div>
                </div>
              ) : (
                <div className={classes.leftContent}>
                  {!loadingCheckout && (
                    <>
                      <div className={classes.cartContainer} onClick={() => toggleCart()}>
                        <img src={cartIcon} className={classes.cartIcon} alt="cart" />
                        {!!cartCount && <div className={classes.cartCount}>{cartCount}</div>}
                      </div>
                      <div className={classes.cartText}>View cart</div>
                    </>
                  )}
                </div>
              )}
              <div className={classes.buttonsContainer}>
                {!!compareCount ? (
                  <>
                    <Button
                      parentStyles={classes.mobileButton}
                      outline
                      onPress={() => onSecondary()}
                    >
                      {LABELS.CANCEL}
                    </Button>
                    <Button
                      parentStyles={classes.mobileButton}
                      disabled={compareCount === 1}
                      onPress={() => onPrimary()}
                    >
                      {LABELS.COMPARE}
                    </Button>
                  </>
                ) : (
                  <>
                    {!hideSecondary && (
                      <Button
                        parentStyles={classes.mobileButton}
                        outline
                        onPress={() => onSecondary()}
                      >
                        {LABELS.SKIP}
                      </Button>
                    )}
                    <Button
                      parentStyles={classes.mobileButton}
                      disabled={disabledPrimary}
                      onPress={() => onPrimary()}
                    >
                      {last
                        ? cartServices.length === 0 && cartReferrals.length > 0
                          ? LABELS.GET_QUOTE
                          : LABELS.SET_UP
                        : LABELS.NEXT}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const loadingCheckout = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingCheckout: loadingCheckout(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFooter);
