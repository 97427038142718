import { AnyAction, combineReducers } from 'redux';
import { ErrorState, initialErrorState } from './state/ErrorState';
import { LoadingState, initialLoadingState } from './state/LoadingState';
import { TokenState } from './state/TokenState';
import { SpinnerState } from './state/SpinnerState';
import { AuthState } from './state/AuthState';
import { DashboardState } from './state/DashboardState';
import { ProfileState } from './state/ProfileState';
import { RequestState } from './state/RequestState';
import { PaymentState } from './state/PaymentState';
import { ServiceState } from './state/ServiceState';
import { CimetState } from './state/CimetState';
import { tokenReducer, initialState as initialTokenState } from './reducers/TokenReducer';
import { spinnerReducer, initialState as initialSpinnerState } from './reducers/SpinnerReducer';
import { authReducer, initialState as initialAuthState } from './reducers/AuthReducer';
import {
  dashboardReducer,
  initialState as initialDashboardState,
} from './reducers/DashboardReducer';
import { profileReducer, initialState as initialProfileState } from './reducers/ProfileReducer';
import { serviceReducer, initialState as initialServiceState } from './reducers/ServiceReducer';
import { paymentReducer, initialState as initialPaymentState } from './reducers/PaymentReducer';
import { requestReducer, initialState as initialRequestState } from './reducers/RequestReducer';
import { cimetReducer, initialState as initialCimetState } from './reducers/CimetReducer';
import { loadingReducer } from './reducers/LoadingReducer';
import { errorReducer } from './reducers/ErrorReducer';
import { AuthActionTypes } from './actions/AuthActions';
import { GroupsState } from './state/GroupsState';
import { groupsReducer, initialState as initialGroupsState } from './reducers/GroupsReducer';
import { LandingScreenState } from './state/LandingScreenState';
import {
  landingScreenReducer,
  initialState as initialLandingScreenState,
} from './reducers/LandingScreenReducer';
import { MasterProfileState } from './state/MasterProfileState';
import {
  masterProfileReducer,
  initialState as initialMasterProfileState,
} from './reducers/MasterProfileReducer';
import { AdditionalPersonalDetailsScreenState } from './state/AdditionalPersonalDetailsScreenState';
import { ApplicantProfile } from './state/MyProfileFormState';
import {
  additionalPersonalDetailScreenReducer,
  initialState as initialAdditionalPersonalDetailScreenState,
} from './reducers/AdditionalPersonalDetailsReducer';
import {
  myProfileReducer,
  initialState as initialMyProfileState,
} from './reducers/MyProfileFormReducer';
import { WalletState } from './state/WalletState';
import { walletReducer, initialState as initialWalletState } from './reducers/WalletReducer';
import { LandlordState } from './state/LandlordState';
import { landlordReducer, initialState as initialLandlordState } from './reducers/LandlordReducer';
import { ToastState } from './state/ToastState';
import { toastReducer, initialState as initialToastState } from './actions/ToastReducer';
import {
  maintenanceReducer,
  initialState as initialMaintenanceState,
} from './reducers/MaintenanceReducer';
import { MaintenanceState } from './state/MaintenanceState';
import { checkoutReducer, initialState as initialCheckoutState } from './reducers/CheckoutReducer';
import { CheckoutState } from './state/CheckoutState';
import { themeReducer, initialState as initialThemeState } from './reducers/ThemeReducer';
import { ThemeState } from './state/ThemeState';
import { RentOnboardingState } from './state/RentOnboarding';
import {
  rentOnboardingReducer,
  initialState as initialRentOnboardingState,
} from './reducers/RentOnboardingReducer';
import { FileUploadState } from './state/FileUploadState';
import {
  fileUploadReducer,
  initialState as initialFileUploadState,
} from './reducers/FileUploadReducer';
import { PropertyOnboardingState } from './state/PropertyOnboardingState';
import {
  propertyOnboardingReducer,
  initialState as initialPropertyOnboardingState,
} from './reducers/PropertyOnboardingReducer';

export interface ApplicationState {
  payment: PaymentState;
  request: RequestState;
  profileState: ProfileState;
  dashboardState: DashboardState;
  services: ServiceState;
  authState: AuthState;
  token: TokenState;
  spinner: SpinnerState;
  cimet: CimetState;
  groups: GroupsState;
  landingScreen: LandingScreenState;
  masterProfile: MasterProfileState;
  additionalPersonalDetailScreen: AdditionalPersonalDetailsScreenState;
  myProfileForm: ApplicantProfile;
  wallet: WalletState;
  landlordState: LandlordState;
  toast: ToastState;
  maintenance: MaintenanceState;
  checkout: CheckoutState;
  themeState: ThemeState;
  rentOnboarding: RentOnboardingState;
  fileUploadState: FileUploadState;
  propertyOnboarding: PropertyOnboardingState;
  loadingState: LoadingState;
  errorState: ErrorState;
}

const rootReducer = combineReducers<ApplicationState>({
  payment: paymentReducer,
  request: requestReducer,
  profileState: profileReducer,
  dashboardState: dashboardReducer,
  authState: authReducer,
  services: serviceReducer,
  token: tokenReducer,
  spinner: spinnerReducer,
  cimet: cimetReducer,
  groups: groupsReducer,
  landingScreen: landingScreenReducer,
  masterProfile: masterProfileReducer,
  additionalPersonalDetailScreen: additionalPersonalDetailScreenReducer,
  myProfileForm: myProfileReducer,
  wallet: walletReducer,
  landlordState: landlordReducer,
  toast: toastReducer,
  maintenance: maintenanceReducer,
  checkout: checkoutReducer,
  themeState: themeReducer,
  rentOnboarding: rentOnboardingReducer,
  fileUploadState: fileUploadReducer,
  propertyOnboarding: propertyOnboardingReducer,
  loadingState: loadingReducer,
  errorState: errorReducer,
});

const initialState: ApplicationState = {
  payment: initialPaymentState,
  request: initialRequestState,
  profileState: initialProfileState,
  dashboardState: initialDashboardState,
  authState: initialAuthState,
  services: initialServiceState,
  token: initialTokenState,
  spinner: initialSpinnerState,
  cimet: initialCimetState,
  groups: initialGroupsState,
  landingScreen: initialLandingScreenState,
  masterProfile: initialMasterProfileState,
  additionalPersonalDetailScreen: initialAdditionalPersonalDetailScreenState,
  myProfileForm: initialMyProfileState,
  wallet: initialWalletState,
  landlordState: initialLandlordState,
  toast: initialToastState,
  maintenance: initialMaintenanceState,
  checkout: initialCheckoutState,
  themeState: initialThemeState,
  rentOnboarding: initialRentOnboardingState,
  fileUploadState: initialFileUploadState,
  propertyOnboarding: initialPropertyOnboardingState,
  loadingState: initialLoadingState,
  errorState: initialErrorState,
};

export default (state: ApplicationState | undefined, action: AnyAction) => {
  if (action.type === AuthActionTypes.LOGOUT_SUCCESS) {
    return rootReducer(initialState, action);
  }
  return rootReducer(state, action);
};
