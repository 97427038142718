/* eslint-disable no-undef */
import { Options, useCompress } from 'sorted-hooks';

// eslint-disable-next-line import/prefer-default-export
export const compressIfImage = (
  file: File,
  // default options
  options: Options = {
    mimeType: 'image/jpeg',
    /**
     * @see https://github.com/fengyuanchen/compressorjs#quality
     */
    quality: 0.7,
    /**
     * @see https://github.com/fengyuanchen/compressorjs#convertsize
     * since max file size is 10mb, we set the convert size to 8mb
     */
    convertSize: 8 * 1024 * 1024,
  },
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const compress = useCompress();
  // compress only if file is an image and exceeds 300kb size
  if (file.type.startsWith('image/') && file.size > 300000) {
    return compress(file, options);
  }

  return file;
};
