import { get } from 'lodash';
import { HttpContentType } from '../services/HttpContentType';
import { filenameExtractRegex } from '../constants/Strings';

export interface S3File {
  id: string;
  link: string;
  name: string;
  contentType: string;
}

export enum FileTypes {
  Image = 'image',
  Pdf = 'pdf',
  Video = 'video',
}

export class DownloadHelper {
  public static downloadPdfFile(data: ArrayBufferView, fileName: string) {
    const pdfBlob = new Blob([data], { type: HttpContentType.Pdf });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // check if browser is Edge or IE
      (window.navigator as any).msSaveOrOpenBlob(pdfBlob, fileName); // msSaveOrOpenBlob function by microsoft to download blob specific to IE and Edge browser
    } else {
      const url = (window.URL ? URL : webkitURL).createObjectURL(pdfBlob);
      DownloadHelper.downloadLink(url, fileName);
    }
  }

  public static generatePDFLink(data: ArrayBufferView): string {
    const pdfBlob = new Blob([data], { type: HttpContentType.Pdf });
    try {
      return (window.URL ? URL : webkitURL).createObjectURL(pdfBlob);
    } catch (err) {
      return '';
    }
  }

  public static downloadLink(url: string, fileName: string | undefined | null) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';

    if (fileName) {
      link.setAttribute('download', fileName);
    }
    if (typeof link.download === 'undefined') {
      // download attribute not supported by safari browser
      link.setAttribute('target', '_self');
    }

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setTimeout(() => {
      // timeout to support ios firefox
      (window.URL ? URL : webkitURL).revokeObjectURL(url);
    }, 100);
  }

  public static openInNewTab(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    (window.URL ? URL : webkitURL).revokeObjectURL(url);
  }

  public static getFileName(header: string) {
    const filenameRegex = new RegExp(filenameExtractRegex);
    const fileMatch = filenameRegex.exec(header);
    let filename = 'download.pdf'; // default filename if filename not recieved
    if (fileMatch != null && fileMatch[1]) {
      filename = fileMatch[1].replace(/['"]/g, ''); // to get rid of "" from filename
    }
    return filename;
  }

  public static getFileType(contentType: string | null): FileTypes | undefined {
    // Special case: for content-type as null, we have to assume it's an image
    if (contentType === null) {
      return FileTypes.Image;
    }
    const parts = contentType.split('/');
    const category = parts[0];
    const type = parts[1];
    // image
    if (category === FileTypes.Image) {
      return FileTypes.Image;
    }
    // pdf
    if (category === 'application') {
      switch (type) {
        case FileTypes.Pdf:
          return FileTypes.Pdf;
      }
    }
    // video
    if (category === 'video') {
      return FileTypes.Video;
    }
    return FileTypes.Image;
  }

  public static getImageSrc = (image: string | File | S3File): string => {
    if (typeof image === 'string') {
      return image;
    }
    const src = get(image, 'link');
    return (src || URL.createObjectURL(image as File)) as string;
  };
}
