import electricity from '../../../../assets/marketplace/dark/electricity.png';
import gas from '../../../../assets/marketplace/dark/gas.png';
import broadband from '../../../../assets/marketplace/dark/broadband.png';
import water from '../../../../assets/marketplace/dark/water.png';
import solar from '../../../../assets/marketplace/dark/solar.png';
import cleaning from '../../../../assets/marketplace/dark/cleaning.png';
import gardening from '../../../../assets/marketplace/dark/gardening.png';
import grooming from '../../../../assets/marketplace/dark/pets.png';
import insurance from '../../../../assets/marketplace/dark/insurance.png';
import finance from '../../../../assets/marketplace/dark/finance.png';
import removals from '../../../../assets/marketplace/dark/removals.png';
import gym from '../../../../assets/marketplace/dark/gym.png';
import bookRoom from '../../../../assets/marketplace/dark/bookRoom.png';
import locksmith from '../../../../assets/marketplace/dark/locksmith.png';
import heart from '../../../../assets/marketplace/dark/heart.png';
import rent from '../../../../assets/navigation/home.png';
import ThankyouImage1 from '../../../../assets/articles/thankyou-image.jpg';
import ThankyouImage2 from '../../../../assets/articles/thankyou-image2.jpg';
import ThankyouImage3 from '../../../../assets/articles/thankyou-image3.jpg';
import ThankyouImage4 from '../../../../assets/articles/thankyou-image4.png';
import ThankyouImage5 from '../../../../assets/articles/thankyou-image5.png';
import ThankyouImage6 from '../../../../assets/articles/thankyou-image6.png';
import ThankyouImage7 from '../../../../assets/articles/thankyou-image7.png';
import { ServiceTypes } from '../../../../models/services/services';
import star from '../../../../assets/star.png';
import tick from '../../../../assets/tick.png';

import electricityLight from '../../../../assets/marketplace/light/electricity.png';
import gasLight from '../../../../assets/marketplace/light/gas.png';
import broadbandLight from '../../../../assets/marketplace/light/broadband.png';

export const ESSENTIAL_SERVICES = [
  ServiceTypes.Electricity,
  ServiceTypes.Gas,
  ServiceTypes.Broadband,
];

export const LABELS = {
  NEW_PROPERTY_FOUND: 'Set up rent payments for your new property',
  NEW_PROPERTY_FOUND_INFO: 'Tap the button below to set up rent payments for your new property',
  NEW_PROPERTY_FOUND_BUTTON: "Let's do it",
  NO_TRADES: 'No trades',
  FIND_ONE_NOW: 'Find one now',
  QUICK_LINKS: 'Quick links',
  OFFERS_TITLE: 'Offers for you',
};

export enum TASK {
  CART_FORGOT,
  CART_REMINDER,
  NO_SERVICES,
  BILL_CHECK,
  ELECTRICITY,
  GAS,
  BROADBAND,
  MAINTENANCE,
  EMERGENCY_MAINTENANCE,
}

export const getDarkServiceTypeIcon = (serviceType: ServiceTypes) => {
  switch (serviceType) {
    case ServiceTypes.Electricity:
      return electricity;
    case ServiceTypes.Gas:
      return gas;
    case ServiceTypes.Broadband:
      return broadband;
    case ServiceTypes.Water:
      return water;
    case ServiceTypes.Cleaning:
      return cleaning;
    case ServiceTypes.Solar:
      return solar;
    case ServiceTypes.Insurance:
      return insurance;
    case ServiceTypes.Removals:
      return removals;
    case ServiceTypes.Rent:
      return rent;
    default:
      return electricity;
  }
};

export const getLightServiceTypeIcon = (serviceType: ServiceTypes) => {
  switch (serviceType) {
    case ServiceTypes.Electricity:
      return electricityLight;
    case ServiceTypes.Gas:
      return gasLight;
    case ServiceTypes.Broadband:
      return broadbandLight;
    default:
      return electricityLight;
  }
};

export const LINKS = [
  {
    name: 'New maintenance request',
    action: () => null,
  },
  {
    name: 'Contact support',
    action: () => null,
  },
];

export const NO_PROPERTY_NAX = {
  TITLE: 'My properties',
  TEXT: "It doesn't look like you have a property associated to your profile. If you're a homeowner, get started by tapping on Add property above, else a property will be added once your rental application is successful.",
  BUTTON: '',
};

export const NO_SERVICES = {
  TITLE: 'Setup your services',
  DESCRIPTION: 'Setup your services in minutes with our connection wizard',
  TIME: '2-5 mins',
  ICON: star,
};

export const NO_PROPERTY = {
  TITLE: 'My properties',
  TEXT: "It doesn't look like you have added your address yet. Add your place to start adding services and more.",
  BUTTON: 'Add property',
};

export const APPLICATIONS = {
  TITLE: 'Have a rental application?',
  TEXT: 'Track and manage your applications from your account. To go there now, tap the button below.',
  BUTTON: 'View applications',
};

export const SERVICES = {
  TITLE: 'Services',
  TEXT: 'No services added yet. Get your essentials sorted in a few taps.',
  BUTTON: 'Browse now',
};

export const MAINTENANCE = {
  TITLE: 'Maintenance',
  TEXT: 'Need something fixed? Submit a maintenance request here.',
  BUTTON: 'New request',
};

export const FORGOT_BANNER = {
  TITLE: "Forget something? Don't worry, we saved your cart items",
  TEXT: "Don't worry, we've held your services for you! Tap here to continue checkout. ",
  BUTTON: 'Continue checkout',
};

export const CART_BANNER = {
  TITLE: 'Looks like you asked us to remind you',
  TEXT: 'Get your essentials sorted in a few taps! Tap here to continue checkout.',
  BUTTON: 'Browse now',
};

export const WALLET_BANNER = {
  TITLE: 'Claim your $75 wallet credits now!',
  TEXT: "Complete checkout now and we'll pop an extra $75 in wallet credits to go towards your bills!",
  BUTTON: "Let's do it",
};

export const DONE_TASKS = {
  ICON: tick,
  TITLE: "That's it, you're Sorted!",
  TEXT: "We'll let you know when new tasks pop up",
  TIME: 'Complete',
};

export const readMore = [
  {
    image: ThankyouImage5,
    text: 'How to get on top of your bills',
    buttonText: 'Read more',
    link: 'http://www.sortedservices.com/blog-spot/how-to-get-on-top-of-your-bills',
  },
  {
    image: ThankyouImage7,
    text: 'How to make your next move, a stress free one with Sorted',
    buttonText: 'Read more',
    link: 'http://www.sortedservices.com/blog-spot/how-to-make-your-next-move-a-stress-free-one-with-sorted',
  },
  {
    image: ThankyouImage2,
    text: 'Inside advice from Property Managers ...',
    buttonText: 'Read more',
    link: 'https://www.sortedservices.com/blog-spot/inside-advice-from-property-managers',
  },
  {
    image: ThankyouImage4,
    text: 'How to secure a rental property with no rental history',
    buttonText: 'Read more',
    link: 'https://www.sortedservices.com/blog-spot/how-to-secure-a-rental-property-with-no-rental-history',
  },
  {
    image: ThankyouImage1,
    text: '10 ways to make your rental application stand out ...',
    buttonText: 'Read more',
    link: 'https://www.sortedservices.com/blog-spot/application-stand-out-tips',
  },
  {
    image: ThankyouImage6,
    text: 'Everything you need to know about renters insurance ...',
    buttonText: 'Read more',
    link: 'http://www.sortedservices.com/blog-spot/everything-you-need-to-know-about-renters-insurance',
  },
];

export const FAKE_ESSENTIAL_SERVICES = [
  {
    icon: electricity,
    name: 'Electricity',
  },
  {
    icon: gas,
    name: 'Gas',
  },
  {
    icon: broadband,
    name: 'Broadband',
  },
  {
    icon: water,
    name: 'Water',
  },
];

export const FAKE_HOME_SERVICES = [
  {
    icon: solar,
    name: 'Solar',
  },
  {
    icon: cleaning,
    name: 'Cleaning',
  },
  {
    icon: gardening,
    name: 'Gardening',
  },
  {
    icon: grooming,
    name: 'Grooming',
  },
  {
    icon: insurance,
    name: 'Insurance',
  },
  {
    icon: removals,
    name: 'Removals',
  },
  {
    icon: finance,
    name: 'Finance',
  },
];

export const SWITCH_SERVICES = [
  {
    icon: gym,
    name: 'Fitness studio',
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1590364463');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.technogym.mywellness.societyfitnessattheswitch',
      //   );
      // }
    },
  },
  {
    icon: bookRoom,
    name: 'Book spaces',
    action: () => {
      // navigate('SwitchRoomBooking')
    },
  },
  {
    icon: locksmith,
    name: 'Digital key',
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1403770732');
      // } else {
      //   Linking.openURL(
      //     'https://play.google.com/store/apps/details?id=com.assaabloy.hospitality.mobileaccess.studentliving&hl=en_AU&gl=US&pli=1',
      //   );
      // }
    },
  },
  {
    icon: heart,
    name: 'Wellbeing',
    action: () => {
      // if (Platform.OS === 'ios') {
      //   Linking.openURL('https://itunes.apple.com/us/app/1178892371');
      // } else {
      //   Linking.openURL('https://play.google.com/store/apps/details?id=com.sonder.member.android');
      // }
    },
  },
];
