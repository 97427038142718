import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CheckoutCompleteCartStyles';
import { LABELS, WHAT_HAPPENS_NEXT } from './CheckoutCompleteCartConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import DownloadSorted from '../../components/downloadSorted/DownloadSorted';
import AppReview from '../../components/appReview/AppReview';
import BlogCard from '../../components/blogCard/BlogCard';
import Task from '../../components/task/Task';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { DashboardState } from '../../store/state/DashboardState';
import { getOfferTitleImage } from '../browse/BrowseConstants';
import Button from '../../components/button/Button';
import { useCart } from '../../helpers/CheckoutHelper';
import CartItem from '../cart/components/cartItem/CartItem';
import { ServiceTypes } from '../../models/services/services';
import { essentialServices } from '../checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import WhileYouWait from './whileYouWait/WhileYouWait';

interface CheckoutCompleteCartProps {
  dashboardState: DashboardState;
}

const CheckoutCompleteCart: FC<CheckoutCompleteCartProps> = ({
  dashboardState: { onboardedProperties, selectedProperty, offers },
}) => {
  const classes = useStyles();
  const { cartItems } = useCart();

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{LABELS.SUCCESS_TITLE}</div>
          <Text textVariant="info" size="l" parentStyles={classes.submitSubtitle}>
            {LABELS.SUCCESS_SUBITLE}
          </Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.contentTitleContainer}>
              <Text textVariant="contentTitle">{LABELS.SUMMARY}</Text>
            </div>
            {!!cartItems.length && (
              <div className={classes.cartSection}>
                {cartItems.map((cartItem) => (
                  <div className={classes.cartItem}>
                    <CartItem
                      hideAction
                      cartItem={cartItem}
                      handleAction={() => null}
                      handleTap={() => null}
                      quote={!essentialServices.includes(cartItem.type as ServiceTypes)}
                    />
                  </div>
                ))}
              </div>
            )}
            <AppReview />

            <div className={classes.contentTitleContainer}>
              <Text textVariant="contentTitle">{LABELS.WHATS_NEXT}</Text>
            </div>
            <div className={classes.taskGrid}>
              {WHAT_HAPPENS_NEXT.map((next) => (
                <Task
                  title={next.title}
                  description={next.description}
                  icon={next.icon}
                  time={next.time}
                  handlePress={() => null}
                />
              ))}
            </div>
            <WhileYouWait />
          </div>
          <div className={classes.secondaryContainer}>
            <div className={classes.contentTitleContainer}>
              <Text textVariant="contentTitle">{LABELS.FOR_YOU}</Text>
            </div>

            {offers &&
              offers.map((offer, idx) => {
                const content = getOfferTitleImage(offer.title);
                if (!content) return null;
                return (
                  <div
                    className={classes.offerContainer}
                    style={{ backgroundColor: content.backgroundColor }}
                    key={idx}
                  >
                    <img
                      src={content.image}
                      className={offer.title === 'HelloFresh' ? classes.helloFresh : classes.blys}
                    />
                    <img src={content.title} className={classes.offerTitle} />
                    <div className={classes.offerSubtitle}>{content.subtitle}</div>
                    <Button
                      parentStyles={classes.offerButton}
                      onPress={() => navigate('offerScreen', { id: idx })}
                    >
                      Redeem
                    </Button>
                  </div>
                );
              })}
            <BlogCard
              title={LABELS.BLOG_TITLE}
              body={LABELS.BLOG_BODY}
              image={LABELS.BLOG_IMAGE}
              link={LABELS.BLOG_LINK}
            />
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <DownloadSorted />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCompleteCart);
