import { RentalFrequency } from '../lease/lease';

export interface ServiceDictionary {
  [serviceAccountId: number]: RentService;
}

export interface RentService {
  serviceAccountId: number;
  readonly id: number;
  readonly paymentMethodResponse: PaymentMethod | null;
  readonly paymentFrequency: RentalFrequency;
  monthlyCost: number;
  readonly cost: number;
  readonly nextPaymentDate: string | null;
  readonly serviceDetails: ServiceDetails | null;
}

export interface PaymentMethod {
  refId: string;
  paymentMethod: PaymentMethods;
  cardType: string | null;
  cardName: string | null;
  cardNumber: string | null;
  cardExpiryYear: string | null;
  cardExpiryMonth: string | null;
  accountName: string | null;
  accountNumber: string | null;
  bsb: string | null;
}

export interface ServiceDetails {
  readonly bondAmount: number;
  readonly tenancyTerm: string;
  readonly fromDate: string;
  readonly toDate: string;
  readonly documents: Documents[];
  readonly paymentDetails?: PaymentDetails;
  readonly attachments: Attachment[];
}

export interface Documents {
  readonly documentId: string;
  readonly documentTitle: string;
  readonly documentLink: string;
}

export interface Attachment {
  title: string;
  link: string;
}

export enum PaymentMethods {
  creditCard = 'Credit',
  bank = 'Bank',
  notAvailable = 'NotAvailable',
}

export interface PaymentDetails {
  paymentProvider: PaymentProvider | null;
  billInfo: string | null;
  paymentReferenceId: string | null;
}

export enum PaymentProvider {
  DEFT = 'DEFT',
  BPAY = 'BPAY',
  SORTED = 'SORTED',
  Other = 'Other',
}
