/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    text: {
      color: theme.colors.black87,
      fontSize: theme.font.m,
      fontWeight: getFonts('Regular'),
    },
    link: {
      color: theme.colors.buttonPrimary,
      cursor: 'pointer',
      fontWeight: getFonts('Medium'),
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
    },
    boxTitle: {
      color: theme.colors.title,
      fontSize: theme.font.xl,
      marginBottom: theme.units.spacing * 6,
      fontWeight: getFonts('Medium'),
    },
    contentTitle: {
      color: theme.colors.title,
      fontSize: 22,
      marginBottom: theme.units.spacing * 3,
      fontWeight: getFonts('Medium'),
    },
    body: {
      fontWeight: getFonts('Light'),
    },
    subtitle: {
      fontSize: theme.font.xl,
      fontWeight: getFonts('Medium'),
    },
    subtitle2: {
      fontSize: 28,
      fontWeight: getFonts('Medium'),
    },
    grey: {
      color: theme.colors.black38,
      fontWeight: getFonts('Regular'),
    },
    error: {
      color: theme.colors.error,
      fontWeight: getFonts('Medium'),
    },
  })();
};
