import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    marginTop: {
      marginTop: 24,
    },
    sectionKey: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      fontSize: 14,
      marginBottom: 6,
    },
    sectionValue: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 16,
    },
  })();
};
