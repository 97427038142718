import {
  AddPaymentTagsResponse,
  CheckoutStripeToken,
  GetFastPayResponse,
  GetPaymentAuthKey,
  GetPaymentAuthKeyResponse,
  GetPaymentMethodsResponse,
  GetPaymentTypeResponse,
  PaymentDetails,
  PostPaymentTokenResponse,
  PostPaymentUUIDResponse,
  SaveSelectedPaymentMethodPayload,
} from '../../models/payment/Payment';
import { PaymentAction, PaymentActionTypes } from '../actions/PaymentActions';
import { PaymentState } from '../state/PaymentState';

export const initialState: PaymentState = {
  paymentDetails: undefined,
  paymentTagDetails: undefined,
  paymentTypes: [],
  savedPaymentTypes: [],
  paymentMethods: [],
  selectedPaymentMethod: {
    paymentMethodId: '',
    maskedDetails: {
      paymentDetails: '',
      logo: '',
    },
  },
  paymentAuthKey: {
    authKey: '',
    status: 1,
  },
  selectedViewPaymentMethod: null,
  paymentPayId: null,
  stripeCheckoutTokens: [],
  deletePaymentResponse: undefined,
};

export const paymentReducer: (state: PaymentState, action: PaymentAction) => PaymentState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PaymentActionTypes.FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentDetails: action.data as GetFastPayResponse,
      };
    case PaymentActionTypes.CHANGE_PAYMENT_TAG_SUCCESS:
      return {
        ...state,
        paymentTagDetails: action.data as AddPaymentTagsResponse,
      };
    case PaymentActionTypes.SAVE_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.data as SaveSelectedPaymentMethodPayload,
      };
    case PaymentActionTypes.GET_AVAILABLE_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        paymentTypes: action.data as GetPaymentTypeResponse[],
      };
    case PaymentActionTypes.GET_SAVED_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.data as GetPaymentMethodsResponse[],
      };
    case PaymentActionTypes.GET_FAST_PAYID_SUCCESS:
      return {
        ...state,
        paymentPayId: action.data as GetFastPayResponse,
      };
    case PaymentActionTypes.DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        deletePaymentResponse: action.data as string[],
      };
    case PaymentActionTypes.POST_PAYMENT_METHOD_UUID_SUCCESS:
      const { paymentMethodResponse, paymentMethodUUID, user, id } =
        action.data as PostPaymentUUIDResponse;
      return {
        ...state,
        savedPaymentTypes: [
          ...state.savedPaymentTypes,
          {
            paymentMethodUUID,
            paymentMethodResponse,
            user,
            id,
          },
        ],
      };
    case PaymentActionTypes.GET_PAYMENT_AUTH_KEY_SUCCESS:
      return {
        ...state,
        paymentAuthKey: action.data as GetPaymentAuthKey,
      };
    case PaymentActionTypes.POST_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        selectedViewPaymentMethod: action.data as PostPaymentTokenResponse,
      };
    case PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_SUCCESS:
      const result =
        state.stripeCheckoutTokens !== null
          ? state.stripeCheckoutTokens.findIndex(
              (el) => el.productId === (action.data as CheckoutStripeToken).productId,
            )
          : -1;
      return {
        ...state,
        stripeCheckoutTokens:
          result === -1
            ? [...state.stripeCheckoutTokens, action.data as CheckoutStripeToken]
            : state.stripeCheckoutTokens.map((el, idx) =>
                idx === result ? (action.data as CheckoutStripeToken) : el,
              ),
      };

    default:
      return state;
  }
};
