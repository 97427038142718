import React, { FC, useEffect, useState } from 'react';
import { AvailableServicesResponse, PersonalDetails } from '../../../../models/checkout/Checkout';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyles } from './StripeSectionStyles';
import { LABELS } from './StripeSectionConstants';
import lockIcon from '../../../../assets/lock-24-px.svg';
import tick from '../../../../assets/radio-outlined.png';
import { calculateIntentCost, getCheckoutText, getCostItems } from './StripeSectionUtils';
import { CheckoutStripeToken } from '../../../../models/payment/Payment';
import { Property } from '../../../../models/property/property';
import { CreatePaymentMethodData } from '@stripe/stripe-js';

interface StripeSectionProps {
  cartItem: AvailableServicesResponse;
  stripeIntent: CheckoutStripeToken | undefined;
  personalDetails: PersonalDetails;
  property: Property | undefined | null;
  setPaymentMethod: (refId: string) => void;
}

const StripeSection: FC<StripeSectionProps> = ({
  cartItem,
  stripeIntent,
  personalDetails,
  property,
  setPaymentMethod,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (
      !(
        stripe &&
        stripeIntent &&
        cartItem.suppliers &&
        cartItem.suppliers[0].plans[0].cartData &&
        cartItem.suppliers[0].plans[0].cartData.paymentRefId
      )
    ) {
      return;
    }
    setError(null);
    setSucceeded(true);
    setProcessing(false);
  }, [stripe, stripeIntent, cartItem]);

  const handleSubmit = async () => {
    setProcessing(true);
    if (stripeIntent) {
      const payload = await stripe!.createPaymentMethod({
        type: 'card',
        card: elements!.getElement(CardElement)!,
        billing_details: {
          name: `${personalDetails.name} ${personalDetails.surname}`,
          email: personalDetails.email,
          address: {
            city: property!.suburb,
            country: 'AU',
            line1: `${property!.unitNumber ? `${property!.unitNumber}/` : ''}${
              property!.streetNumber || ''
            } ${property!.streetName}`,
            postal_code: property!.postcode,
            state: property!.state,
          },
        },
      } as CreatePaymentMethodData);

      if (payload.error) {
        setError(`Add payment method failed: ${payload.error.message}`);
        setProcessing(false);
        console.log('[error]', payload.error);
      } else {
        setError(null);
        setSucceeded(true);
        setProcessing(false);
        setPaymentMethod(payload.paymentMethod.id);
        console.log('[PaymentIntent]', payload.paymentMethod);
      }
    } else {
      console.log('stripeToken.clientSecret not ready');
    }
  };

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{succeeded ? LABELS.PAID : LABELS.TITLE}</div>
        <div className={classes.price}>${calculateIntentCost(cartItem).toFixed(2)}</div>
      </div>
      <div className={classes.subtitle}>{getCheckoutText(cartItem)}</div>
      <div className={classes.costBreakdown}>
        {getCostItems(cartItem).map((item) => (
          <div className={classes.breakdownItem}>
            <div className={classes.itemName}>{item.name}</div>
            <div className={classes.itemCost}>${item.cost.toFixed(2)}</div>
          </div>
        ))}
      </div>
      {succeeded ? (
        <div className={classes.successContainer}>
          <img src={tick} className={classes.successIcon} alt="" />
          <div className={classes.successText}>{LABELS.SUCCESS}</div>
        </div>
      ) : (
        <CardElement
          className={classes.cardContainer}
          options={{
            style: {
              base: {
                fontFamily: 'GT Walsheim',
                fontSize: '16px',
                '::placeholder': {
                  color: '#a2a2a2',
                },
              },
            },
          }}
        />
      )}
      <div className={classes.addNewPaymentFooter}>
        <div className={classes.secureBoxStyle}>
          <img src={lockIcon} className={classes.secureLockStyle} alt="lock" />
          <div className={classes.secureText}>{LABELS.SECURE_CONNECTION}</div>
        </div>
        <div
          className={processing || succeeded ? classes.disabledButton : classes.addNewOptionSave}
          onClick={() => {
            if (!processing && !succeeded) {
              handleSubmit();
            }
          }}
        >
          {processing ? LABELS.PROCESSING : LABELS.PAY}
        </div>
      </div>
      {error && <div className={classes.errorText}>{error}</div>}
    </div>
  );
};

export default StripeSection;
