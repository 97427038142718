export const isEmpty = (value: any) => {
  return value === undefined || value === null || value === '';
};

export const isRequired = (errorMsg: string) => (value: any) => {
  if (isEmpty(value)) {
    return errorMsg || `Enter a value`;
  }
  return undefined;
};
