/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material';
import { ErrorMessage, Formik, useFormik } from 'formik';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import visibilityOn from '../../../../assets/outline-remove-red-eye-24-px.svg';
import visibilityOff from '../../../../assets/outline-visibility-off-24-px.svg';
import localStorage from '../../../../services/LocalStorage';
import { View } from '../../Login';
import { LABELS, VALIDATION_SCHEMA } from './SignupFormConstants';
import { useStyles } from './SignupFormStyles';
import { theme } from '../../../../theme/Theme';
import Input from '../../../../components/input/Input';
import Text from '../../../../components/text/Text';
import Box from '../../../../components/box/Box';
import Button from '../../../../components/button/Button';
import { WHY_SORTED } from '../loginForm/LoginFormConstants';
import { ApplicationState } from '../../../../store/RootReducer';
import UserConfirmationDialog from '../../../../components/userConfirmationDialog/UserConfirmationDialog';
import { RegisterUserRequest, RegisterUserPayload } from '../../../../models/auth/auth';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { AuthActionTypes, AuthActions } from '../../../../store/actions/AuthActions';
import InputMobile from '../../../../components/inputMobile/InputMobile';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';

interface SignupFormProps {
  error: any;
  loading: boolean;
  userDetails: RegisterUserPayload | null;
  registerAndLogin: (data: RegisterUserRequest, onSubmit: () => void) => void;
  setView: (view: View) => void;
  setUserDetails: (userDetails: RegisterUserPayload) => void;
}

const SignupForm: FC<SignupFormProps> = ({
  error,
  userDetails,
  loading,
  registerAndLogin,
  setView,
  setUserDetails,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    if (error && error.response && error.response.status === 400) {
      setErrorMessage('User already exists. Please use the sorted credentials to login');
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      email: userDetails && userDetails.email ? userDetails.email : '',
      password: '',
      phone: '',
      name: userDetails && userDetails.firstname ? userDetails.firstname : '',
      surname: userDetails && userDetails.lastname ? userDetails.lastname : '',
    },
    onSubmit: (values) => {
      console.log('submitting');
      registerAndLogin(values, () => {
        setUserDetails({
          email: values.email,
          firstname: values.name,
          lastname: values.surname,
          password: values.password,
        });
      });
    },
    validationSchema: VALIDATION_SCHEMA,
  });

  return (
    <div className={classes.centerPageContent}>
      <div className={classes.primaryContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Box lightBorder parentStyles={classes.boxContainer}>
            <Text parentStyles={classes.title} textVariant="title" size="xl">
              {LABELS.SIGNUP_TITLE}
            </Text>
            <Text textVariant="info">{LABELS.SIGNUP_SUBTITLE}</Text>
            {/* FIRSTNAME ----------------------------------------------------------------- */}
            <div className={classes.inputRow}>
              <Input
                placeholder={LABELS.FIRST_NAME}
                key={LABELS.FIRST_NAME}
                value={formik.values.name}
                setValue={formik.handleChange('name')}
                onBlur={() => formik.setFieldTouched('name')}
                touched={formik.touched.name}
                error={formik.errors.name}
                title={LABELS.FIRST_NAME}
                mandatory
                big
              />

              {/* LASTNAME ----------------------------------------------------------------- */}
              <Input
                placeholder={LABELS.LAST_NAME}
                key={LABELS.LAST_NAME}
                value={formik.values.surname}
                setValue={formik.handleChange('surname')}
                onBlur={() => formik.setFieldTouched('surname')}
                touched={formik.touched.surname}
                error={formik.errors.surname}
                title={LABELS.LAST_NAME}
                parentStyles={classes.secondInput}
                mandatory
                big
              />
            </div>

            {/* EMAIL ----------------------------------------------------------------- */}
            <Input
              placeholder={LABELS.EMAIL_ADDRESS}
              key={LABELS.EMAIL_ADDRESS}
              value={formik.values.email}
              setValue={formik.handleChange('email')}
              onBlur={() => formik.setFieldTouched('email')}
              touched={formik.touched.email}
              error={formik.errors.email || errorMessage}
              title={LABELS.EMAIL_ADDRESS}
              mandatory
              big
            />

            {/* Mobile ----------------------------------------------------------------- */}
            <InputMobile
              placeholder={LABELS.MOBILE}
              key={LABELS.MOBILE}
              value={formik.values.phone}
              setValue={formik.handleChange('phone')}
              onBlur={() => formik.setFieldTouched('phone')}
              touched={formik.touched.phone}
              error={formik.errors.phone || errorMessage}
              title={LABELS.MOBILE}
              mandatory
              big
            />

            {/* PASSWORD ----------------------------------------------------------------- */}

            <Input
              placeholder={LABELS.PASSWORD}
              key={LABELS.PASSWORD}
              value={formik.values.password}
              setValue={formik.handleChange('password')}
              onBlur={() => formik.setFieldTouched('password')}
              touched={formik.touched.password}
              error={formik.errors.password}
              title={LABELS.PASSWORD}
              password={!showPassword}
              mandatory
              big
              endElement={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <img
                    src={showPassword ? visibilityOff : visibilityOn}
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                </IconButton>
              }
            />

            <ul className={classes.list}>
              <li className={classes.dotPoint}>At least 8 characters</li>
              <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                One special character
              </li>
              <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                One uppercase character
              </li>
            </ul>

            <div className={classes.signupRow}>
              <Button
                parentStyles={classes.signupButton}
                loading={loading}
                disabled={loading}
                type="submit"
                onPress={() => null}
              >
                {LABELS.SIGNUP_BUTTON}
              </Button>
            </div>
            <div className={classes.loginRow}>
              <Text textVariant="info">{LABELS.ALREADY_HAVE_ACCOUNT}</Text>
              <Text
                textVariant="link"
                parentStyles={classes.loginButton}
                onPress={() => setView(View.Login)}
              >
                {LABELS.LOGIN_BUTTON}
              </Text>
            </div>
          </Box>
        </form>
      </div>
    </div>
  );
};

const error = errorMessageSelector([AuthActionTypes.LOGIN, AuthActionTypes.REGISTER]);

const loading = loadingSelector([AuthActionTypes.REGISTER]);

const mapStateToProps = (state: ApplicationState) => ({
  error: error(state),
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  registerAndLogin: (data: RegisterUserRequest, onSuccess: () => void) => {
    dispatch(AuthActions.postRegisterRequest({ ...data, onSuccess }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
