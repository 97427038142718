import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './LoginStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS, TRIANGLES, validationSchema, WHY_SORTED } from './LoginConstants';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';
import Text from '../../components/text/Text';
import sortedIcon from '../../assets/sorted-icon-dark.png';
import { useFormik } from 'formik';
import Input from '../../components/input/Input';
import { AuthActions } from '../../store/actions/AuthActions';
import { LoginRequest, RegisterUserPayload } from '../../models/auth/auth';
import LoginForm from './components/loginForm/LoginForm';
import ForgotPasswordForm from './components/forgotPasswordForm/ForgotPasswordForm';
import SignupForm from './components/signupForm/SignupForm';

interface LoginProps extends RouteComponentProps {}

export enum View {
  Login,
  Signup,
  Verify,
  ForgotPassword,
  VerifyOtp,
}

const Login: FC<LoginProps> = ({}) => {
  const [view, setView] = useState<View>(View.Login);
  const [userDetails, setUserDetails] = useState<RegisterUserPayload | null>(null);
  const classes = useStyles();

  const renderView = () => {
    switch (view) {
      case View.Login:
        return <LoginForm userDetails={userDetails} setView={(aView: View) => setView(aView)} />;
      case View.Signup:
        return (
          <SignupForm
            userDetails={userDetails}
            setUserDetails={(details: RegisterUserPayload) => setUserDetails(details)}
            setView={(aView: View) => setView(aView)}
          />
        );
      case View.ForgotPassword:
        return (
          <ForgotPasswordForm userDetails={userDetails} setView={(aView: View) => setView(aView)} />
        );
      default:
        return <LoginForm userDetails={userDetails} setView={(aView: View) => setView(aView)} />;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>{renderView()}</div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
