export class TypeHelper {
  public static arrayReplaceAt<T>(array: T[], index: number, newItem: T): T[] {
    if (!array) {
      return [];
    } else if (index < 0 || index >= array.length) {
      return array;
    } else {
      return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
    }
  }

  public static isNullOrUndefined(obj: any): boolean {
    return obj == null || obj === undefined;
  }

  public static toString<T>(obj: any, emptyValue: T): string | T {
    return TypeHelper.isNullOrUndefined(obj) ? emptyValue : obj.toString() || emptyValue;
  }

  public static safeStringCompare(
    s1: string | undefined | null,
    s2: string | undefined | null,
    ignoreCase?: boolean | undefined | null,
    trim?: boolean | undefined | null,
  ): number {
    if (ignoreCase) {
      if (trim) {
        return TypeHelper.toString(s1, '')
          .trim()
          .toLocaleLowerCase()
          .localeCompare(TypeHelper.toString(s2, '').trim().toLocaleLowerCase());
      }
      return TypeHelper.toString(s1, '')
        .toLocaleLowerCase()
        .localeCompare(TypeHelper.toString(s2, '').toLocaleLowerCase());
    }
    if (trim) {
      return TypeHelper.toString(s1, '').trim().localeCompare(TypeHelper.toString(s2, '').trim());
    }
    return TypeHelper.toString(s1, '').localeCompare(TypeHelper.toString(s2, ''));
  }

  public static safeStringEquals(
    s1: string | undefined | null,
    s2: string | undefined | null,
    ignoreCase?: boolean | undefined | null,
    trim?: boolean | undefined | null,
  ): boolean {
    return TypeHelper.safeStringCompare(s1, s2, ignoreCase, trim) === 0;
  }
}
