import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 18,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    activityLoadingContainer: {
      marginTop: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageHeader: {
      height: 200,
      position: 'relative',
      borderRadius: theme.units.borderRadius,
      marginBottom: theme.units.spacing * 12,
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.borderColorLight,
      borderRadius: theme.units.borderRadius,
    },
    serviceTitle: {
      position: 'absolute',
      bottom: 25,
      left: 25,
      fontSize: 32,
      color: theme.colors.light,
      fontWeight: getFonts('Medium'),
    },
    logoStyle: {
      position: 'absolute',
      right: 50,
      bottom: -25,
      width: 50,
      height: 50,
      objectFit: 'contain',
      objectPosition: 'center',
      borderRadius: 5000,
    },
    sectionContainer: {
      marginBottom: 24,
    },
    sectionTitle: {
      paddingLeft: 16,
      marginBottom: 16,
      fontSize: 18,
      fontWeight: getFonts('Medium'),
    },
    section: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
    },
    card2: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.black38,
      padding: 16,
      paddingTop: 32,
    },
    noTopPadding: {
      paddingTop: 0,
    },
    linkStyle: {
      paddingTop: 10,
      paddingBottom: 10,
      textDecorationLine: 'underline',
      color: theme.colors.buttonPrimary,
    },
  })();
};
