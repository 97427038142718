import { AgencyCode } from '../../constants/Strings';
import { RentalFrequency } from '../lease/lease';
import { PhotoLink } from '../property/property';
import { ServiceTypes } from '../services/services';
import { Status } from '../tenantRequest/Request';
import { User } from '../user/user';

export enum ServiceStatus {
  Pending = 'Pending',
  Active = 'Active',
}

export enum TenancyStatusFilter {
  NoLease = 'NoLease',
  Pending = 'Pending',
  Signed = 'Signed',
  Leased = 'Leased',
  Periodic = 'Periodic',
}

export interface Agency {
  id: number;
  name: string;
  code: AgencyCode;
  tradingName: string;
  agentLicenseNumber: string;
  abn: string;
  acn: string;
  postalAddress: string;
  email: string;
  phone: string;
  pmsName: string;
  branchCode: string;
  logoUrl: string | null;
  backgroundUrl: string | null;
  logoHeight: string | null;
  config: string | null;
}

export interface Tenant {
  name: string;
  email: string;
}

export interface LeaseDocument {
  CloudLocationId: string;
  Content: string;
  DocumentType: string;
  ExtensionType: 'pdf';
  Name: string;
}

export const leaseStatus = {
  renewed: 'RENEWED',
  cancelled: 'CANCELLED',
  archived: 'ARCHIVED',
};

export enum LeaseStatus {
  Signed = 'Signed',
  Pending = 'Pending',
  Leased = 'Leased',
  Periodic = 'Periodic',
  PreSigned = 'PreSigned',
  Active = 'Active',
}

export interface Lease {
  weeklyRent: number;
  rentalFrequency: RentalFrequency;
  startDate: string | null;
  endDate: string | null;
  signDate: string | null;
  primaryTenant: Tenant;
  secondaryTenant: Tenant[];
  type: string;
  status: string;
  childLeaseStartDate: string | null;
  leased: boolean;
  documents: LeaseDocument[];
}

export interface PropertyLeaseData {
  advertised: boolean;
  current: boolean;
  daysLeft: number;
  pending: boolean;
  signed: boolean;
}
export interface Property {
  refId: string;
  address: string;
  photos: PhotoLink[];
  streetNumber: string;
  unitNumber: string;
  streetName: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
  agencyCode: string;
  bedrooms: number;
  bathrooms: number;
  carspaces: number;
  description: string;
  agency: Agency;
  managingAgent: User;
  leasingAgent: User;
  branch: string;
  lease: Lease[];
  ownerships: string[];
  lessor: string[];
  leaseData: PropertyLeaseData;
  id: number;
  pmsLink?: string;
}

export interface PropertyApplication {
  address: string;
  streetNumber: string | null;
  unitNumber: string | null;
  streetName: string | null;
  suburb: string | null;
  postcode: string | null;
  state: string | null;
  type: string | null;
  bedrooms: number | null;
  bathrooms: number | null;
  carspaces: number | null;
  applications: Application[];
  id: number;
}

export interface Application {
  email: string;
  name: string;
  rentOffer: number;
  bondAmount: number;
  years: number;
  months: number;
  preferredMoveInDate: string | null;
  endDate: string | null;
  depositAmount: number | null;
  urgentRepairsAmount: number | null;
  noOfPeople: number;
  noOfPets: number;
  inspectionDate: string | null;
  incomePercent: number | null;
  applicants: Applicant[];
  status: string;
  id: number;
  rank: number;
  shortlistedNote?: ShortlistedNote;
}

export interface ShortlistedNote {
  note: string;
}

export interface Applicant {
  id: number | null;
  title: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  introduction: string | null;
  dob: string | null;
  mobile: string | null;
  applicantType: ApplicantType;
  applicantAddressHistory: boolean;
  applicantEmploymentHistory: boolean;
  applicantIdentification: boolean;
  identificationPoints: number;
  isManaged: boolean;
  references: ReferenceSummary;
  ntdinformation: NTDInformationResponse | null;
  applicantAddressHistoryList: ApplicantAddressHistory[];
  applicantEmploymentHistoryList: ApplicantEmploymentHistory[];
  applicantIdentificationList: ApplicantIdentification[];
  pets: Pet[];
  applicantIncome: ApplicantIncome[];
}

export interface ApplicantIncome {
  source: string;
  weeklyAmount: number;
}

export interface Pet {
  type: string;
  count: number;
}

export interface ApplicantAddressHistory {
  id: number;
  address: string;
  currentLivingArrangement: string;
  years: number;
  months: number;
  propertyManagerName: string;
  propertyManagerEmail: string | null;
  monthlyRent: number | null;
  reasonForLeaving: string;
  applyReferenceId: number;
  notes: string[];
  current: boolean;
}
export interface ApplicantEmploymentHistory {
  endDate: string | null;
  startDate: string | null;
}
export interface ApplicantIdentification {
  type: string;
  verified: boolean;
}

export enum ApplicantType {
  PRIMARY,
  ADDITIONAL,
  DEPENDENT,
}

export interface ReferenceSummary {
  rental: number[];
  employment: number[];
  reference: number[];
}

export interface NTDInformationResponse {
  status: NTDStatus;
  data: any;
  pdfLink: string | null;
}

export enum NTDStatus {
  REQUESTED,
  PROCESSING,
  COMPLETED,
  FAILED,
  ARCHIVED,
  NOT_AVAILABLE,
}

export interface PropertyResponse {
  content: Property[];
  totalElements: number;
}

export interface PropertyRequest {
  ownershipType?: string | null;
  propertyType?: TenancyStatusFilter;
  address?: string | null;
  advertised?: boolean | null;
  filterRequest?: boolean;
  dashboardProperties?: boolean;
  moreProperties?: boolean;
  page: number;
  size: number;
  search?: boolean;
}

export interface ApplicationRequest {
  ownershipType?: string | null;
  address?: string | null;
  advertised?: boolean | null;
}

export interface ApplicationSubmitRequest {
  propertyId: number;
  applicationId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface TasksResponse {
  approveTenants: number;
  approveMaintenanceRequests: number;
  landlordAttending: number;
  approveQuotes: number;
}

export interface MaintenanceRequest {
  status: MaintenanceStatus;
  propertyId?: number;
  isMaintenanceHistory?: boolean;
}

export enum MaintenanceStatus {
  COMPLETED = 'COMPLETED',
  REQUEST = 'REQUEST',
  LANDLORD = 'LANDLORD',
  QUOTE = 'QUOTE',
}

export interface MaintenanceResponse {
  id: string;
  maintenanceRequestType: string;
  propertyId: number;
  leaseId: number | null;
  tenantIds: number[];
  agencyId: number;
  agencyBranchId: number;
  approved: boolean;
  location: string;
  status: string;
  details: MaintenanceDetails;
  workOrders: WorkOrder[];
  notificationSent: boolean;
  openRequestDetail: string | null;
  propertyDetails: Property;
  audit: Audit;
  isRequested?: boolean;
  isAttending?: boolean;
  isQuoteRequested?: boolean;
  tenants: User[];
  notes: string | null;
  landlordAttendingDetails: LandlordAttendingDetails;
  isTenantRequested?: boolean;
  isTenantReviewed?: boolean;
  isTenantScheduled?: boolean;
  isTenantCompleted?: boolean;
  privateWorksDetails: PrivateWorksDetails | null;
  dashboardStatus: Status;
}

export interface PrivateWorksDetails {
  private: boolean;
  privateName: string;
  storeName: string;
  workflowType: string;
}

export interface LandlordAttendingDetails {
  additionalNotes: string;
  alternateContact: AlternateContact;
  maintenanceRequestDescription: string;
}

export interface AlternateContact {
  name: string;
  contact: string;
}
export interface Audit {
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
}
export interface MaintenanceDetails {
  title: string;
  description: string;
  images: Image[];
  parkingAvailability: boolean;
  clearAccess: boolean;
  propertyAccess: PropertyAccess;
  pet: Pet;
  appliance: Appliance;
  stairs: Stairs;
  preferredAvailability: PreferredAvailability;
  sendEmailToPropertyManager: boolean;
  unSupervisedMinors: boolean;
  moreInfoRequired: boolean;
  extendedData?: Map<string, string | Inclusions | LocalMarkrtplaceSummary[]>;
}

export interface Inclusions {
  description: string;
  image: string;
  name: string;
  quantity: number;
}
export interface LocalMarkrtplaceSummary {
  label: string;
  labelId: string;
  type: string;
  value: string;
  valueId: string;
}

export interface Image {
  contentType: string;
  id: string;
  link: string | null;
  name: string;
}

export interface PropertyAccess {
  propertyAccessType: string;
  accessKeyNumber: string;
  description: string;
}

export interface Pet {
  present: boolean;
  description: string;
}

export interface Appliance {
  present: boolean;
  make: string;
  model: string;
  warranty: boolean;
}

export interface Stairs {
  present: boolean;
  description: string;
}

export interface PreferredAvailability {
  timeSlot: string;
  otherTime: string;
  days: string[];
}

export interface WorkOrder {
  referenceNumber: string;
  description: string;
  type: string;
  status: string;
  category: string;
  termsVersion: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  quoteDueDate: string;
  jobDueDate: string;
  propertyAccess: PropertyAccess;
  comments: string | null;
  preApprovedDetails: string | null;
  jobs: Job[];
  urgent: boolean;
  quotes: Quote[];
  selected?: boolean;
}

export interface Job {
  id: string;
  status: string;
  assignedUserId: number[];
  tradeId: number;
  quoteVariations: any[];
  jobVariations: Job[];
  parentId: number | null;
  propertyDetails: PropertyDetails;
  jobArtefactFiles: JobArtefactFiles;
  keyDates: KeyDates;
  jobDetail: JobDetail;
  quote: Quote;
  tradeName: string;
  type?: string;
  assignedUsers: AssignedUsers[];
  officeTradeResponse: OfficeDetails;
  ratingSubmitted: boolean;
}

export interface AssignedUsers {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  logo: string;
  mobileNumber: string;
  username: string;
}

export interface JobDetail {
  changeNote: string | null;
  labourCost: number;
  callOutCost: number;
  includedMaterialCost: number;
  serviceFee: number;
  serviceFeePercent: number;
  totalCost: number;
  lineAmountType: string;
  preApprovedDetails: string | null;
  invoiceDetails: string | null;
  specificMaterial: SpecificMaterials[];
}

export interface SpecificMaterials {
  description: string;
  charge: number;
  includedMaterial: boolean;
}

export interface Quote {
  id: string;
  workOrderId: string;
  tradeId: number;
  officeId: number;
  status: string;
  labourCost: number;
  materialCost: number;
  miscellaneousCost: number;
  serviceFee: number;
  serviceFeePercent: number;
  totalCost: number;
  remarks: string;
  jobMinCost: number;
  jobMaxCost: number;
  callOutCost: number;
  maintenanceRequestResponse: MaintenanceResponse;
  type: string;
  quoteVariationDetails: string | null;
  availability: Availability;
  notificationSent: boolean;
  lineAmountType: string;
  attachments: ArtefactFiles[];
  officeDetails: OfficeDetails;
  specificMaterials: SpecificMaterials[];
}

export interface OfficeDetails {
  country: string;
  covidApproved: boolean | null;
  email: string;
  id: number;
  name: string;
  phone: string;
  postcode: string;
  primary: boolean;
  state: string;
  streetName: string;
  streetNumber: string;
  suburb: string;
  trade: Trade;
  logo: string;
}

export interface Trade {
  id: number;
  logoKey: string;
  name: string;
}

export interface Availability {
  startDate: string;
  endDate: string;
}
export interface KeyDates {
  startDate: string;
  timeSlot: TimeSlot;
  expectedCompletion: string;
}

export interface TimeSlot {
  timeOfDay: string;
  clockTimeRange: string;
}

export interface PropertyDetails {
  propertyId: number;
  agencyId: number;
  agencyBranchId: number;
}

export interface JobArtefactFiles {
  jobImageFileUploadDetails: ArtefactFiles[];
  specialMaterialReceiptFileUploadDetails: ArtefactFiles[];
  invoiceFileUploadDetails: ArtefactFiles[];
  comment: string;
}

export interface ArtefactFiles {
  name: string;
  id: string;
  link: string;
  contentType: string;
}

export interface UpdateMRStatusRequest {
  maintenanceId: string;
  status: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface MRTradeRequest {
  maintenanceId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface MRQuoteRequest {
  quoteId: string;
  status: string;
  propertyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface LandlordAvailableServicesRequest {
  propertyId: number;
  services: string[];
}

export interface LandlordServices {
  availableServices: LandlordAvailableServicesResponse[];
  services: Service[];
}

export interface Service {
  id: number;
  productCode: null | string;
  productDescription: null | string;
  productName: null | string;
  provider: string;
  serviceType: ServiceTypes;
  status: ServiceStatus;
  details: SericeDetails;
  category: string;
}

export interface SericeDetails {
  description: string;
  descriptionBanner: string;
  descriptionFooter: string;
  supplierBackgroundLogo: null | string;
  supplierId: string;
  supplierLogo: string;
  supplierLogoHero: string;
  supplierName: string;
  supplierTagLine: string;
  supplierTitle: string;
}

export interface LandlordAvailableServicesResponse {
  logo: string;
  type: ServiceTypes;
  suppliers: Suppliers[];
}

export enum LandlordServiceType {
  INSURANCE = 'Insurance',
  ELECTRICITY = 'Electricity',
}
export interface ExtendedData {
  description: string;
  productId: string;
  productType: string;
  serviceCategoryId: string;
  serviceCategoryName: string;
  serviceName: string;
  serviceType: string;
  serviceTypeLogo: string;
  supplierId: string;
  supplierName: string;
  signUpFlow: string;
}
export interface ApplicationRankingRequest {
  request: Map<string, string>;
  propertyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface Suppliers {
  logo: string;
  name: string;
  providerId: string;
  description: string;
  extendedData: ExtendedData;
}
