import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './WhileYouWaitStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../components/text/Text';
import bannerPurple from '../../../assets/banner-purple.png';
import welcomeImage from '../../../assets/waiting-image.jpg';
import { LABELS } from './WhileYouWaitConstants';
import OptionalIcon from '../../../assets/Circle.svg';
import ArrowRight from '../../../assets/navigation/arrow-right.png';
import { navigate } from '../../../navigation/NavigationUtils';

interface WhileYouWaitProps {}

const WhileYouWait: FC<WhileYouWaitProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcomeBanner}>
        <div className={classes.welcomeLeftContent}>
          <Text textVariant="title" parentStyles={classes.welcomeTitle}>
            {LABELS.TITLE}
          </Text>
          <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
            {LABELS.SUBTITLE}
          </Text>
        </div>
        <div className={classes.welcomeImageBackground}>
          <img src={bannerPurple} className={classes.welcomeLeftImage} alt="overlay" />
          <img src={welcomeImage} className={classes.welcomeRightImage} alt="welcome" />
        </div>
      </div>
      <div className={classes.quickStartContainer}>
        <div className={classes.quickStartRow}>
          <Text textVariant="contentTitle" parentStyles={classes.quickStartTitle}>
            {LABELS.QUICK_START}
          </Text>
        </div>
        <div
          className={classes.quickStartItem}
          style={{ borderTop: 'none' }}
          onClick={() => navigate('home', { refresh: true })}
        >
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.VIEW_OR_EDIT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div className={classes.quickStartItem} onClick={() => navigate('browse')}>
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.CHECK_OUT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          className={classes.quickStartItem}
          onClick={() => window.open('https://g.page/r/CXsOlIz2jmyiEBM/review', '_blank')}
        >
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.CHECK_BLOGS}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
      </div>
    </div>
  );
};

export default WhileYouWait;
