/* eslint-disable class-methods-use-this */
import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';
import {
  ApplicationRequest,
  MaintenanceResponse,
  PropertyApplication,
  PropertyRequest,
  PropertyResponse,
  TasksResponse,
} from '../../models/landlord/LandlordProperty';
import { getAgencyCode } from '../../helpers/LandlordHelper';

export class LandlordService {
  public getPropetiesService(request: PropertyRequest): Promise<PropertyResponse> {
    const service = new ApiService(ServiceType.landlord);
    const { ownershipType, address, advertised, page, size, propertyType } = request;
    const path: QueryPath = {
      route: ['dashboard', 'paginated', 'properties'],
      query: {
        ownership: ownershipType,
        agency: getAgencyCode(),
        address,
        advertised,
        page,
        size,
        propertyType,
      },
    };
    return service.get<PropertyResponse>(path);
  }
  public getApplicationsService(request: ApplicationRequest): Promise<PropertyApplication[]> {
    const service = new ApiService(ServiceType.landlord);
    const { ownershipType, address, advertised } = request;
    const path: QueryPath = {
      route: ['dashboard', 'applications'],
      query: {
        ownership: ownershipType,
        agency: getAgencyCode(),
        address,
        advertised,
      },
    };
    return service.get<PropertyApplication[]>(path);
  }

  public submitApplicationService(propertyId: number, applicationId: number): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['property', propertyId.toString(), 'application', applicationId.toString()],
      query: {},
    };
    return service.put<any>(path);
  }

  public getTasksService(): Promise<TasksResponse> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['dashboard', 'tasks'],
      query: {
        agency: getAgencyCode(),
      },
    };
    return service.get<TasksResponse>(path);
  }

  public getMaintenanceService(
    status: string,
    propertyId?: number,
  ): Promise<MaintenanceResponse[]> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['dashboard', 'MR', status],
      query: { agency: getAgencyCode(), propertyId },
    };
    return service.get<MaintenanceResponse[]>(path);
  }
  public updateMaintenanceStatus(maintenanceId: string, status: string): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['mr', maintenanceId],
      query: {},
    };
    return service.patch<any>(path, JSON.stringify(status));
  }

  public submitMRTradeRequest(maintenanceId: string): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['mr', maintenanceId, 'get-trade'],
      query: {},
    };
    return service.patch<any>(path, {});
  }

  public submitMRQuoteRequest(quoteId: string, status: string, propertyId: number): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['quote', quoteId],
      query: {},
    };
    return service.patch<any>(path, {
      status,
      propertyId,
    });
  }

  public getAvailableServicesService(propertyId: number, services: string[]): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['available', 'services'],
      query: { propertyId, service: services.toString() },
    };
    return service.get<any>(path);
  }

  public updateApplicationRankingRequest(
    request: Map<string, string>,
    propertyId: number,
  ): Promise<any> {
    const service = new ApiService(ServiceType.landlord);
    const path: QueryPath = {
      route: ['properties', `${propertyId}`, 'application-rankings'],
      query: {},
    };
    return service.post<any>(path, request);
  }
}

const landlordService = new LandlordService();
export default landlordService;
