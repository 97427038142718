/* eslint-disable import/prefer-default-export */
export const LABELS = {
  PLEASE_SELECT:
    'Please select if you would like to withdraw this application for everyone or just yourself',
  WITHDRAW_APPLICATION: 'Withdraw this application for this property for everyone',
  WITHDRAW_MYSELF: 'Withdraw only myself from this application',
  WITHDRAW_TITLE: 'Withdraw application',
  PLEASE_SELECT_INFO: 'Please make your selection above',
  WITHDRAW_APPLICATION_INFO1: 'You have selected to withdraw the application from ',
  WITHDRAW_APPLICATION_INFO2:
    '. This will update the application for everyone you are applying with',
  WITHDRAW_MYSELF_INFO1: 'You have selected to withdraw only your details from ',
  WITHDRAW_MYSELF_INFO2:
    ". This will leave the application in it's current status, but your profile will be removed",
};

export enum CheckedOption {
  Application = 'APPLICATION',
  Myself = 'MYSELF',
}
