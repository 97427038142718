import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PlanDetailsStyles';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlanDetailsParams } from '../../navigation/NavigationConstants';
import { GenericSection, PlanSection } from '../../models/services/genericServiceSummary';
import { DownloadHelper } from '../../helpers/DownloadHelper';
import Button from '../../components/button/Button';
import Box from '../../components/box/Box';

interface PlanDetailsProps {}

const PlanDetails: FC<PlanDetailsProps> = () => {
  const classes = useStyles();
  const { servicePageDetails, serviceDetails } = extractParams<PlanDetailsParams>();

  const renderSection = (section: GenericSection) => {
    switch (section.sectionTitle.toUpperCase()) {
      case PlanSection.planSummary:
        return section.data.map((row, index) => (
          <>
            {row.key.toUpperCase() === 'ESTIMATED COST' ? (
              <div className={classes.col} key={index}>
                <div className={classes.keybold}>{row.key}*</div>
                <div className={classes.value}>{(row.value as string).split('<br/>')[1]}</div>
              </div>
            ) : (
              <div className={classes.row} key={index}>
                <div className={classes.key}>{row.key}:</div>
                <div className={classes.value}>{row.value}</div>
              </div>
            )}
          </>
        ));
      case PlanSection.planDetails:
        return section.data.map((col, index) => (
          <div className={classes.col} key={index}>
            <div className={classes.keybold}>{col.key}</div>
            <div className={classes.value}>{col.value}</div>
          </div>
        ));
      case PlanSection.documents:
        return (
          <div className={classes.buttonGrid}>
            {section.data.map((col, index) => (
              <Button
                key={index}
                muted
                onPress={() => DownloadHelper.downloadLink(col.value as string, col.key)}
              >
                {col.key}
              </Button>
            ))}
          </div>
        );
      default:
        return section.data.map((row, index) => (
          <div className={classes.row} key={index}>
            <div className={classes.key}>{row.key}:</div>
            <div className={classes.value}>{row.value}</div>
          </div>
        ));
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="title" size="xxl" parentStyles={classes.title}>
              Details
            </Text>
            {servicePageDetails.map(
              (section, index) =>
                section.data.length > 0 && (
                  <Box lightBorder parentStyles={classes.section} key={index}>
                    <div className={classes.titleContainer}>
                      <div className={classes.sectionTitle}>
                        {serviceDetails
                          ? section.sectionTitle.replace(
                              'Plan',
                              serviceDetails.planDetails.productDetails.productType,
                            )
                          : section.sectionTitle}
                      </div>
                    </div>

                    {renderSection(section)}
                  </Box>
                ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
