import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './ProfileStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ProfileActions } from '../../../../store/actions/ProfileActions';
import { Profile as ProfileModel, UpdateProfile, User } from '../../../../models/user/user';
import { ProfileState } from '../../../../store/state/ProfileState';
import { AuthDetails } from '../../../../models/auth/auth';
import { LABELS } from './ProfileConstants';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { stringToHslColor } from '../../../../helpers/GravatarHelper';
import { isValidNumber } from './ProfileUtils';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button/Button';
import ManagedProfiles from '../../../../components/managedProfiles/ManagedProfiles';
import { ThemeKey, getCurrentThemeKey } from '../../../../theme/Theme';

interface ProfileProps extends RouteComponentProps {
  profile: ProfileState;
  authDetails: AuthDetails | undefined;
  isGuest: boolean;
  getProfileDetails: (userId: number, intercomId?: string, superUser?: boolean) => void;
  updateProfile: (data: UpdateProfile) => void;
}

const Profile: FC<ProfileProps> = ({
  profile,
  authDetails,
  isGuest,
  getProfileDetails,
  updateProfile,
}) => {
  const classes = useStyles();
  const { firstName, lastName, email, mobile, dob } = profile.profile as ProfileModel;
  const [edit, setEdit] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(mobile || '');
  const [errorMsg, setErrorMsg] = useState('');
  const fullName = `${firstName} ${lastName}`;
  const themeKey = getCurrentThemeKey();

  const onEdit = () => {
    const { userId } = authDetails!;
    if (edit) {
      // call update here
      if (!phone) {
        setErrorMsg('Please enter phone number');
      } else if (!isValidNumber(phone)) {
        setErrorMsg('Invalid phone number');
      } else {
        updateProfile({
          userId,
          data: {
            ...(profile.profile as ProfileModel),
            mobile: phone,
          },
        });
        setEdit(false);
      }
    } else {
      setEdit(true);
    }
  };

  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        <div className={classes.cardContainer}>
          <div className={classes.row}>
            <div
              className={classNameGenerator([classes.gravatar])}
              style={{ backgroundColor: stringToHslColor(`${fullName}`, 70, 70) }}
            >
              <div className={classes.gravatarText}>
                {fullName.split(' ')[0].charAt(0)}
                {fullName.split(' ')[1].charAt(0)}
              </div>
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.title}>{fullName}</div>
              <div className={classes.description}>{email}</div>
            </div>
          </div>
        </div>
        <div className={classes.detailsTitle}>{LABELS.TITLE}</div>
        <div className={classes.cardContainer}>
          <div className={classes.detailRow}>
            <div className={classes.detailTitle}>{LABELS.NAME}</div>
            <div className={classes.detailDescription}>{fullName}</div>
          </div>
          <div className={classes.detailRow}>
            <div className={classes.detailTitle}>{LABELS.EMAIL}</div>
            <div className={classes.detailDescription}>{email}</div>
          </div>
          <div className={classes.phoneContainerStyle}>
            <div className={classNameGenerator([classes.detailRow, classes.phoneTextStyle])}>
              <div className={classes.detailTitle}>{LABELS.PHONE}</div>
              {edit ? (
                <Input
                  placeholder={LABELS.PHONE}
                  key={LABELS.PHONE}
                  value={phone}
                  setValue={(value: string) => {
                    setErrorMsg('');
                    setPhone(value);
                  }}
                  touched={!!errorMsg}
                  error={errorMsg}
                  mandatory
                  parentStyles={classes.phoneInputStyles}
                />
              ) : (
                <div className={classes.detailDescription}>{phone || '-'}</div>
              )}
            </div>
            {themeKey !== ThemeKey.NAX && (
              <div className={classes.buttonsContainer}>
                <div className={classes.buttons}>
                  {edit && (
                    <Button
                      parentStyles={classes.cancelButton}
                      onPress={() => setEdit(false)}
                      outline
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    muted
                    parentStyles={classes.editButton}
                    onPress={() => {
                      onEdit();
                    }}
                  >
                    {edit ? 'Save' : 'Edit'}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={classes.detailRow}>
            <div className={classes.detailTitle}>{LABELS.DOB}</div>
            <div className={classes.detailDescription}>{!!dob ? (dob as string) : '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profile: state.profileState,
  authDetails: state.authState.authDetails,
  isGuest: state.authState.guest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProfileDetails: (userId: number, intercomId?: string, superUser?: boolean) => {
    dispatch(ProfileActions.getProfileRequest({ userId, intercomId, superUser }));
  },
  updateProfile: (data: UpdateProfile) => {
    dispatch(ProfileActions.updateProfileRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
