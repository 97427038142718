import { takeEvery, put } from 'redux-saga/effects';
import { SpinnerActions } from '../actions/SpinnerActions';
import {
  PropertyAction,
  PropertyActionTypes,
  PropertyActions,
} from '../actions/PropertyOnboardingActions';
import { PropertyOnboardingRequest } from '../../models/property/property';
import { ValidMessages, sendMessageToApp } from '../../helpers/MessageHelper';
import PropertyService from '../../services/propertyOnboarding/PropertyOnboardingService';
import { store } from '../Store';
import { ToastMessage } from '../../helpers/ToastMessage';
import { navigate } from '../../navigation/NavigationUtils';
import { DashboardActions } from '../actions/DashboardActions';

export function* postPropertySaga(action: PropertyAction) {
  try {
    yield PropertyService.postProperty(action.data as PropertyOnboardingRequest);
    yield put(PropertyActions.postPropertySuccess());
    const { isWebView } = store.getState().token;
    sendMessageToApp(ValidMessages.Dashboard, { showPropertyAddedToast: true });
    if (!isWebView) {
      navigate('home', { refresh: true });
      ToastMessage.showMessage('Property added', '', 'success');
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PropertyActions.postPropertyError(error as Error));
  } finally {
    yield put(SpinnerActions.hide());
  }
}

function* propertyOnboardingWatcher() {
  yield takeEvery(PropertyActionTypes.POST_PROPERTY_REQUEST, (action: PropertyAction) =>
    postPropertySaga(action),
  );
}
export default propertyOnboardingWatcher;
