// REFACTORED to remove unneeded function renders
import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';
import {
  Job,
  LocalMarkrtplaceSummary,
  MaintenanceResponse,
  Quote,
  SpecificMaterials,
} from '../../models/landlord/LandlordProperty';
import smsIcon from '../../assets/textsms.png';
import { LandlordActions } from '../../store/actions/LandlordActions';
import rejectIcon from '../../assets/landlord/reject.png';
import acceptSelectedIcon from '../../assets/blue-tick.png';
import documentIcon from '../../assets/landlord/document.png';
import downloadPdfIcon from '../../assets/landlord/download.png';
import callIcon from '../../assets/call.png';
import emailIcon from '../../assets/email.png';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import {
  CancelRequest,
  RequestQuote,
  ReviewRequest,
  Status,
  TenantRequest,
} from '../../models/tenantRequest/Request';
import { RequestActions } from '../../store/actions/RequestActions';
import {
  getCostValues,
  getDays,
  getJobDates,
  getJobs,
  getQuotes,
  getTimeSlot,
  getTradeContactDetails,
  getTradeName,
  removeQuotesOnReject,
} from '../landlordMaintenanceInfo/LandlordMaintenanceInfoUtils';
import { MR_QUOTE_STATUS } from '../landlordMaintenanceInfo/LandlordMaintenanceInfoContants';
import { Inclusion } from '../../models/bundles/Bundles';
import {
  getAdditionalInfo,
  getMaintenanceDetailHeader,
  getMaintenanceDetailTitle,
  getRatingSubmittedFlag,
  getStatusInfo,
  INCLUSION_KEY,
  SUMMARY_KEY,
} from './TenantRequestInfoConstants';
import ArrowDownIcon from '../../assets/arrow-down-grey.png';
import RatingIcon from '../../assets/rating.png';
import RatingFilledIcon from '../../assets/rating-filled.png';
import UserIcon from '../../assets/user.png';
import { navigateBack } from '../../navigation/NavigationUtils';
import { extractLocalMarketplaceImages } from './TenantRequestInfoUtils';
import { useStyles } from './TenantRequestInfoStyles';
import { stringToHslColor } from '../../helpers/GravatarHelper';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { getCostLabel } from '../../helpers/LandlordHelper';
import { Dialog } from '@mui/material';
import { AuthDetails } from '../../models/auth/auth';
import MaintenanceCard from '../../components/maintenanceCard/MaintenanceCard';
import { getMaintenanceLocation } from '../../components/maintenanceCard/MaintenanceCardUtils';
import { ApplicationState } from '../../store/RootReducer';
import { ToastMessage } from '../../helpers/ToastMessage';
import { DownloadHelper } from '../../helpers/DownloadHelper';
import MultipleImageComponent from '../../components/multipleImageComponent/MultipleImageComponent';
import Button from '../../components/button/Button';
import TileContainer from '../../components/tileContainer/TileContainer';
import SwipeableViews from 'react-swipeable-views';
import ActivityIndicator from '../../components/activityIndicator/ActivityIndicator';
import Text from '../../components/text/Text';
import InputArea from '../../components/inputArea/InputArea';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface LandlordMaintenanceInfoProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const LandlordMaintenanceInfo: FC<LandlordMaintenanceInfoProps> = ({
  authDetails,
  submittedReview,
  selectedMaintenance,
  submittedReviewLoader,
  getReview,
  getRequests,
  submitReview,
  cancelRequest,
  submitRequestQuote,
  updatedSelectedMaintenance,
}) => {
  const classes = useStyles();
  const [quoteButtonSelected, setQuoteButtonSelected] = useState<string>('');
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const maintenance = selectedMaintenance as MaintenanceResponse;
  // const quoteCarousel = useRef<Carousel<Quote>>(null);
  const [quoteIndex, setQuoteIndex] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [modalCost, setModalCost] = useState<number>(0);
  const [addedSpecificMaterials, setAddedSpecificMaterials] = useState<SpecificMaterials[]>([]);
  const { details, audit, landlordAttendingDetails } = maintenance;
  const receviedDate =
    audit && audit.createdDate ? moment(audit.createdDate).format('DD/MM/YYYY') : '';
  const quotes = getQuotes(selectedMaintenance);
  const { startDate, expectedCompletion, timeSlot } = getJobDates(maintenance.workOrders);
  const themeKey = getCurrentThemeKey();

  const isPrivateWorks = !!(
    maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
  );
  const inclusions =
    maintenance &&
    maintenance.details &&
    maintenance.details.extendedData &&
    maintenance.details.extendedData[INCLUSION_KEY]
      ? maintenance.details.extendedData[INCLUSION_KEY]
      : [];
  const localmarketplaceSummary: LocalMarkrtplaceSummary[] =
    maintenance &&
    maintenance.details &&
    maintenance.details.extendedData &&
    maintenance.details.extendedData[SUMMARY_KEY]
      ? (maintenance.details.extendedData[SUMMARY_KEY] as LocalMarkrtplaceSummary[]).filter(
          (data) => data.type === 'answers',
        )
      : [];
  const localmarketplaceImages = extractLocalMarketplaceImages(localmarketplaceSummary);
  const { statusLabel, statusColor } = getStatusInfo(maintenance);
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>('');
  const [showReview, setShowReview] = useState<boolean>(false);
  const [reviewLoader, setReviewLoader] = useState<boolean>(false);
  const [ratingSubmitted, setRatingSubmitted] = useState<boolean>(
    getRatingSubmittedFlag(maintenance),
  );

  useEffect(() => {
    if (ratingSubmitted && maintenance.isTenantCompleted) {
      const jobs = getJobs(maintenance);
      if (jobs.length > 0) {
        getReview(jobs[0].id);
      }
    }
  }, [ratingSubmitted]);

  // Components ---------------------------------//
  const renderQuoteHeader = (item: Quote | Job, havingQuote: boolean) => {
    const { tradeName, officeName, firstChar, secondChar, logo } = getTradeName(item, havingQuote);
    return (
      <div className={classes.quoteDetailsHeader}>
        {logo ? (
          <img src={logo} className={classes.logoStyle} />
        ) : (
          <div
            style={{
              backgroundColor: stringToHslColor(tradeName, 70, 70),
            }}
            className={classes.gravatar}
          >
            <div className={classes.gravatarText}>
              {firstChar}
              {secondChar}
            </div>
          </div>
        )}
        <div className={classes.quoteTextView}>
          <div className={classes.quoteDetailsHeaderText}>{tradeName}</div>
          <div className={classes.quoteDetailsHeaderSubText}>{officeName}</div>
        </div>
      </div>
    );
  };

  const renderTradeContacts = (item: Quote | Job, havingQuote: boolean) => {
    const { phone, email, name } = getTradeContactDetails(item, havingQuote);
    return (
      <div className={classes.quoteDetailsContainer} style={{ paddingBottom: 12 }}>
        {name ? (
          <div className={classes.detailRow}>
            <img src={UserIcon} className={classes.contactIcon} />
            <div className={classes.itemText}>{name}</div>
          </div>
        ) : null}
        {email ? (
          <div className={classes.detailRow}>
            <img src={emailIcon} className={classes.contactIcon} />
            <div className={classes.itemText}>{email}</div>
          </div>
        ) : null}
        {phone ? (
          <div className={classes.detailRow}>
            <img className={classes.contactIcon} src={callIcon} />
            <div className={classes.itemText}>{phone}</div>
          </div>
        ) : null}
        {phone ? (
          <div className={classes.detailRow}>
            <img className={classes.contactIcon} src={smsIcon} />
            <div className={classes.itemText}>{phone}</div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderCost = (item: Quote | Job, havingQuote: boolean) => {
    const {
      labourCost,
      materialCost,
      callOutCost,
      totalCost,
      addedMaterials,
      addedMaterialsCost,
      startDate,
      endDate,
    } = getCostValues(item, havingQuote);
    const gstCost =
      totalCost -
      labourCost -
      callOutCost -
      materialCost -
      (addedMaterials && addedMaterials.length > 0 ? addedMaterialsCost : 0);
    return (
      <div className={classes.quoteDetailsContainer} style={{ paddingTop: 12 }}>
        {startDate || endDate ? (
          <div>
            <div className={classes.quoteTitleViewStyle}>
              <div className={classes.detailsSectionTitle}>Quoted date</div>
            </div>
            {startDate ? (
              <div className={classes.quoteDetailsView}>
                <div className={classes.quoteDetailsHeaderSubText}>Start date</div>
                <div className={classes.quoteDetailsText}>{startDate}</div>
              </div>
            ) : null}
            {endDate ? (
              <div className={classes.quoteDetailsView}>
                <div className={classes.quoteDetailsHeaderSubText}>End date</div>
                <div className={classes.quoteDetailsText}>{endDate}</div>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={classes.quoteTitleViewStyle}>
          <div className={classes.detailsSectionTitle}>Quoted cost</div>
        </div>
        <div className={classes.quoteDetailsView}>
          <div className={classes.quoteDetailsHeaderSubText}>Estimated cost</div>
          <div
            className={classNameGenerator([classes.quoteDetailsText, classes.estimatedCostStyle])}
          >
            {`$${getCostLabel(totalCost)}`}
          </div>
        </div>
        {gstCost ? (
          <div className={classes.quoteDetailsView}>
            <div className={classes.quoteDetailsHeaderSubText}>Includes GST</div>
            <div className={classes.quoteDetailsText}>{`$${getCostLabel(gstCost)}`}</div>
          </div>
        ) : null}
      </div>
    );
  };

  // Modals ---------------------------------//
  const renderMaterialCost = () => {
    return (
      <Dialog open={openModal} onClose={() => setOpenModal(false)} className={classes.modal}>
        <div className={classes.modalView}>
          <div className={classes.modalTitleContainer}>
            <div className={classes.modalTitle}>Materials Cost</div>
          </div>
          <div style={{ marginTop: 24 }}>
            <div className={classes.quoteDetailsView}>
              <div className={classes.quoteDetailsHeaderSubText}>Inclusions</div>
              <div className={classes.quoteDetailsText}>{`$${getCostLabel(modalCost)}`}</div>
            </div>
            <div className={classes.addedHeaderStyle}>Added materials</div>
            <div style={{ marginTop: 12 }}>
              {addedSpecificMaterials.map((data) => (
                <div className={classes.quoteDetailsView}>
                  <div className={classes.quoteDetailsHeaderSubText}>{data.description}</div>
                  <div className={classes.quoteDetailsText}>{`$${getCostLabel(data.charge)}`}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderRejectAlert = () => {
    return (
      <Dialog
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        className={classes.modal}
      >
        <div className={classes.modalView}>
          <div className={classes.modalTitleContainer}>
            <div className={classes.modalTitle}>
              Are you sure you want to reject this quote? Once rejected, this quote will be removed.
            </div>
          </div>
          <div className={classes.buttonsRow}>
            <Button
              onPress={() => {
                setQuoteButtonSelected('');
                setOpenRejectModal(false);
              }}
              outline
              muted
              parentStyles={classes.button}
            >
              No
            </Button>
            <Button
              parentStyles={classes.button}
              muted
              onPress={() => {
                setSubmitLoader(true);
                submitRequestQuote({
                  quoteId: quotes[quoteIndex].id,
                  status: MR_QUOTE_STATUS.REJECT,
                  onSuccess: () => {
                    const updatedMaintenace = removeQuotesOnReject(
                      maintenance,
                      quotes[quoteIndex].id,
                    );
                    const updatedQuotes = getQuotes(updatedMaintenace);
                    ToastMessage.showMessage('Quote rejected', '', 'success', 3000);
                    if (updatedQuotes.length) {
                      // update the selected maintenance reducer
                      setQuoteIndex(0);
                      updatedSelectedMaintenance(updatedMaintenace);
                      setSubmitLoader(false);
                    } else {
                      getRequests({
                        status: Status.REVIEW,
                        propertyId: maintenance.propertyDetails.id,
                      });
                      setSubmitLoader(false);
                      navigateBack();
                    }
                  },
                  onError: () => {
                    setSubmitLoader(false);
                    ToastMessage.showMessage('Failed, try again', '', 'danger', 3000, 4000);
                  },
                });
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderReviewModal = () => {
    const jobs = getJobs(maintenance);
    const officeDetails = jobs && jobs.length > 0 ? jobs[0].officeTradeResponse : null;

    return (
      <Dialog open={showReview} onClose={() => setShowReview(false)} className={classes.modal}>
        <div className={classes.reviewModalView}>
          <div className={classes.imageViewStyle}>
            <img src={officeDetails ? officeDetails.logo : ''} className={classes.imageLogoStyle} />
            <div className={classes.reviewTitleStyle}>
              {officeDetails && officeDetails.trade ? officeDetails.trade.name : ''}
            </div>
            <div className={classes.reviewhelperTextStyle}>How was your service?</div>
            <div className={classes.reviewhelperText1Style}>
              Your feedback will help improve our home maintenance experience
            </div>
            <div className={classes.ratingViewStyle}>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <div onClick={() => setRating(index + 1)} className={classes.ratingStyle}>
                    <img
                      src={index <= rating - 1 ? RatingFilledIcon : RatingIcon}
                      className={classes.ratingIcon}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className={classes.reviewStyle}>
            <div className={classes.reviewCommentsTitle}>Any other comments</div>
            <InputArea
              placeholder="Write comments here..."
              value={review}
              setValue={(value) => setReview(value)}
            />
          </div>
          <div
            className={classes.submitButtonStyle}
            onClick={() => {
              if (jobs && jobs.length > 0 && officeDetails) {
                const authdetails = authDetails as AuthDetails;
                const property = maintenance.propertyDetails;
                setReviewLoader(true);
                const request = {
                  jobId: jobs[0].id,
                  officeId: officeDetails.id,
                  rating,
                  review,
                  user: authdetails.userId,
                  onSuccess: () => {
                    setShowReview(false);
                    getRequests({
                      status: Status.COMPLETED,
                      propertyId: property.id,
                    });
                    setReviewLoader(false);
                    setRatingSubmitted(true);
                  },
                };
                submitReview(request);
              }
            }}
          >
            {reviewLoader && (
              <div style={{ marginRight: 8 }}>
                <ActivityIndicator size="small" color="#fff" />
              </div>
            )}
            <div className={classes.submitTextStyle}>Submit</div>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.headerContainer}>
              <MaintenanceCard maintenance={maintenance} />
            </div>
            <div className={classes.statusViewStyle} style={{ backgroundColor: statusColor }}>
              <div className={classes.statusTextStyle}>{statusLabel}</div>
            </div>
            <div className={classes.MRContainer}>
              <div className={classes.headingView}>
                <div className={classes.headingText}>{getMaintenanceDetailHeader(maintenance)}</div>
              </div>
              <div className={classes.detailsView}>
                <div className={classes.detailsSectionView}>
                  <div className={classes.detailsSectionTitle}>Request received: </div>
                  <div className={classes.detailsSectionValue}>{receviedDate}</div>
                </div>
                <div className={classes.detailsSectionView}>
                  <div className={classes.detailsSectionTitle}>
                    {getMaintenanceDetailTitle(maintenance)}
                  </div>
                  <div className={classes.detailsSectionValue}>{details.title}</div>
                </div>
                <div className={classes.detailsSectionView}>
                  <div className={classes.detailsSectionTitle}>Location: </div>
                  <div className={classes.detailsSectionValue}>
                    {getMaintenanceLocation(maintenance.location)}
                  </div>
                </div>
                {startDate ? (
                  <div className={classes.detailsSectionView}>
                    <div className={classes.detailsSectionTitle}>Start date: </div>
                    <div className={classes.detailsSectionValue}>{startDate}</div>
                  </div>
                ) : null}
                {expectedCompletion && !maintenance.isTenantCompleted ? (
                  <div className={classes.detailsSectionView}>
                    <div className={classes.detailsSectionTitle}>Expected completion date: </div>
                    <div className={classes.detailsSectionValue}>{expectedCompletion}</div>
                  </div>
                ) : null}
                {timeSlot && !maintenance.isTenantCompleted ? (
                  <div className={classes.detailsSectionView}>
                    <div className={classes.detailsSectionTitle}>Time slot: </div>
                    <div className={classes.detailsSectionValue}>{timeSlot}</div>
                  </div>
                ) : null}
                {inclusions && inclusions.length > 0 ? (
                  <div className={classes.descriptionSection}>
                    <div className={classes.detailsSectionTitle}>Base inclusions</div>
                    <div className={classes.inclusionsContainer}>
                      {inclusions.map((inclusion: Inclusion) => (
                        <div className={classes.inclusionsCard}>
                          <img src={inclusion.image} className={classes.inclusionImage} />
                          <div className={classes.inclusionText}>
                            {`${inclusion.name} x${inclusion.quantity}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={classes.descriptionSection}>
                    <div className={classes.detailsSectionTitle}>Description</div>
                    <div className={classes.descriptionDetailsView}>
                      <div className={classes.descriptionText}>
                        {landlordAttendingDetails &&
                        landlordAttendingDetails.maintenanceRequestDescription
                          ? landlordAttendingDetails.maintenanceRequestDescription
                          : details.description}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.headingView}>
                <div className={classes.headingText}>Attachments</div>
              </div>
              <div className={classes.detailsView}>
                <MultipleImageComponent
                  images={
                    localmarketplaceImages.length
                      ? localmarketplaceImages
                      : maintenance.details.images
                  }
                />
              </div>
              {/* render localmarketplace summary */}
              {localmarketplaceSummary && localmarketplaceSummary.length > 0 && (
                <div>
                  <div className={classes.headingView}>
                    <div className={classes.headingText}>Options Summary</div>
                  </div>
                  <div className={classes.detailsView}>
                    {localmarketplaceSummary
                      .filter((data) => data.labelId !== 'MediaUpload')
                      .map((data, index) => (
                        <div>
                          <div className={classes.detailsSectionView} key={index}>
                            <div className={classes.detailsSectionTitle}>{data.label} </div>
                          </div>
                          <div className={classes.summaryDetailsSectionView} key={index}>
                            <div className={classes.detailsSectionValue}>{data.value}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {/* addtional notes for tenant MRs */}
              {!isPrivateWorks && themeKey !== ThemeKey.SWITCH && (
                <div>
                  <div className={classes.headingView}>
                    <div className={classes.headingText}>Additional Information</div>
                  </div>
                  <div className={classes.detailsView}>
                    {getAdditionalInfo(maintenance).map((data, index) => (
                      <div className={classes.detailsSectionView} key={index}>
                        <div className={classes.detailsSectionTitle}>{data.key}: </div>
                        <div className={classes.detailsSectionValue}>{data.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* trades cards with quotes cost in case of new and in progress requestes */}
            {(maintenance.isTenantReviewed || maintenance.isTenantRequested) &&
            quotes.length > 0 &&
            isPrivateWorks ? (
              <TileContainer
                title={maintenance.isTenantRequested ? 'Trades' : 'Quotes'}
                noScroll
                noTitle={quotes.length === 1}
                noMarginTop
                arrowProps={
                  quotes.length === 1
                    ? undefined
                    : {
                        disableLeft: quoteIndex === 0,
                        disableRight: quotes.length - 1 === quoteIndex,
                        onLeftArrow: () => {
                          setQuoteIndex(quoteIndex - 1);
                        },
                        onRightArrow: () => {
                          setQuoteIndex(quoteIndex + 1);
                        },
                      }
                }
              >
                <SwipeableViews
                  enableMouseEvents
                  style={{
                    paddingRight: quotes.length === 1 ? '0' : '55%',
                    position: 'relative',
                  }}
                  index={quoteIndex}
                  onChangeIndex={(index: number) => {
                    setQuoteIndex(index);
                  }}
                >
                  {quotes.map((item, index) => (
                    <div
                      className={classes.quoteContainer}
                      style={{ ...(quotes.length === 1 && { marginRight: 0 }) }}
                      onClick={() => setQuoteIndex(index)}
                    >
                      <div className={classes.quoteHeader}>
                        <div className={classes.quoteHeaderText}>
                          {maintenance.isTenantRequested ? 'Preferred trade' : 'Quote details'}
                        </div>
                      </div>
                      <div>{renderQuoteHeader(item, true)}</div>
                      {maintenance.isTenantRequested && (
                        <div>{renderTradeContacts(item, true)}</div>
                      )}
                      {!maintenance.isTenantRequested && <div>{renderCost(item, true)}</div>}
                      {!maintenance.isTenantRequested &&
                      quotes[quoteIndex] &&
                      quotes[quoteIndex].attachments &&
                      quotes[quoteIndex].attachments.length > 0
                        ? quotes[quoteIndex].attachments.map((attachment) => (
                            <div
                              className={classes.pdfViewStyle}
                              onClick={() =>
                                DownloadHelper.downloadLink(attachment.link, attachment.name)
                              }
                            >
                              <img src={documentIcon} className={classes.pdfIconStyle} />
                              <div className={classes.pdfNameStyle}>{attachment.name}</div>
                              <img src={downloadPdfIcon} className={classes.pdfDownloadIconStyle} />
                            </div>
                          ))
                        : null}
                    </div>
                  ))}
                </SwipeableViews>
              </TileContainer>
            ) : null}
            {/*  trades cards with job details in scheduled and complete tab */}
            {(maintenance.isTenantScheduled || maintenance.isTenantCompleted) && isPrivateWorks && (
              <div>
                <div className={classes.MRContainer}>
                  <div>
                    {getJobs(maintenance).map((job) => (
                      <div>
                        <div className={classes.quoteHeader}>
                          <div className={classes.quoteHeaderText}>Confirmed trade</div>
                        </div>
                        <div>
                          {renderQuoteHeader(job, false)}
                          {renderTradeContacts(job, false)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={classes.MRContainer}>
                  <div>
                    {getJobs(maintenance).map((job) => (
                      <div>
                        <div className={classes.quoteHeader}>
                          <div className={classes.quoteHeaderText}>Job details</div>
                        </div>
                        {renderCost(job, false)}
                        {job &&
                        job.jobArtefactFiles &&
                        job.jobArtefactFiles.invoiceFileUploadDetails &&
                        job.jobArtefactFiles.invoiceFileUploadDetails.length > 0 ? (
                          <div style={{ marginLeft: 4, marginRight: 4 }}>
                            {job.jobArtefactFiles.invoiceFileUploadDetails.map((file) => (
                              <div
                                className={classes.documentRow}
                                onClick={() =>
                                  DownloadHelper.downloadLink(file.link, file.name || '')
                                }
                              >
                                <img src={documentIcon} className={classes.documentIcon} />
                                <div className={classes.documentTitle}>{file.name}</div>
                                <img src={downloadPdfIcon} className={classes.documentIcon} />
                              </div>
                            ))}
                          </div>
                        ) : null}
                        {job.jobArtefactFiles &&
                        job.jobArtefactFiles.jobImageFileUploadDetails &&
                        job.jobArtefactFiles.jobImageFileUploadDetails.length > 0 ? (
                          <div style={{ marginLeft: 4, marginRight: 4 }}>
                            <div className={classes.detailsView}>
                              <MultipleImageComponent
                                images={[...job.jobArtefactFiles.jobImageFileUploadDetails]}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* cost casd in case of new request */}
            {maintenance.isTenantRequested && quotes.length > 0 && isPrivateWorks && (
              <div className={classes.MRContainer}>
                <div className={classes.quoteHeader}>
                  <div className={classes.quoteHeaderText}>Cost</div>
                </div>
                <div className={classes.quoteDetailsContainer} style={{ paddingTop: 12 }}>
                  <div className={classes.quoteDetailsView}>
                    <div className={classes.quoteDetailsHeaderSubText}>Estimated cost</div>
                    <div
                      className={classNameGenerator([
                        classes.quoteDetailsText,
                        classes.estimatedCostStyle,
                      ])}
                    >
                      {`$${getCostLabel(quotes[0].materialCost)}`}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* submitted review card in case of completed request */}
            {maintenance.isTenantCompleted && ratingSubmitted ? (
              <div className={classes.MRContainer}>
                <div className={classes.quoteHeader}>
                  <div className={classes.quoteHeaderText}>Your review</div>
                </div>
                {submittedReviewLoader ? (
                  <ActivityIndicator />
                ) : submittedReview ? (
                  <div className={classes.quoteDetailsContainer} style={{ paddingTop: 12 }}>
                    <div className={classes.reviewContainer}>
                      {Array(5)
                        .fill(0)
                        .map((_, index) => (
                          <img
                            src={
                              index <= submittedReview.rating - 1 ? RatingFilledIcon : RatingIcon
                            }
                            className={classes.reviewRatingIcon}
                          />
                        ))}
                    </div>
                    <div style={{ marginBottom: 12, marginTop: 12 }}>
                      <div className={classes.detailsSectionValue}>{submittedReview.review}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* accept and reject buttons in case of in progress request */}
            {maintenance.isTenantReviewed && isPrivateWorks && quotes.length > 0 ? (
              <div>
                <div className={classes.buttonViewStyle}>
                  <div
                    style={{ marginRight: 8 }}
                    className={classNameGenerator([
                      classes.buttonStyle,
                      quoteButtonSelected === MR_QUOTE_STATUS.REJECT &&
                        submitLoader &&
                        classes.quoteButtonStyle,
                    ])}
                    onClick={() => {
                      setQuoteButtonSelected(MR_QUOTE_STATUS.REJECT);
                      setOpenRejectModal(true);
                    }}
                  >
                    {quoteButtonSelected === MR_QUOTE_STATUS.REJECT && submitLoader ? (
                      <div style={{ marginRight: 8 }}>
                        <ActivityIndicator size="small" color="#fff" />
                      </div>
                    ) : (
                      <img
                        src={
                          quoteButtonSelected === MR_QUOTE_STATUS.REJECT ? rejectIcon : rejectIcon
                        }
                        className={classes.buttonIconStyle}
                      />
                    )}
                    <div
                      className={classNameGenerator([
                        classes.buttonTextStyle,
                        quoteButtonSelected === MR_QUOTE_STATUS.REJECT &&
                          submitLoader &&
                          classes.quoteTextStyle,
                      ])}
                    >
                      Reject
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: 8 }}
                    className={classNameGenerator([
                      classes.buttonStyle,
                      quoteButtonSelected === MR_QUOTE_STATUS.ACCEPT &&
                        submitLoader &&
                        classes.quoteButtonStyle,
                    ])}
                    onClick={() => {
                      setSubmitLoader(true);
                      setQuoteButtonSelected(MR_QUOTE_STATUS.ACCEPT);
                      submitRequestQuote({
                        quoteId: quotes[quoteIndex].id,
                        status: MR_QUOTE_STATUS.ACCEPT,
                        onSuccess: () => {
                          getRequests({
                            status: Status.REVIEW,
                            propertyId: maintenance.propertyDetails.id,
                          });
                          setSubmitLoader(false);
                          ToastMessage.showMessage('Quote accepted', '', 'success', 3000);
                          navigateBack();
                        },
                        onError: () => {
                          setSubmitLoader(false);
                          ToastMessage.showMessage('Failed, try again', '', 'danger', 3000, 4000);
                        },
                      });
                    }}
                  >
                    {quoteButtonSelected === MR_QUOTE_STATUS.ACCEPT && submitLoader ? (
                      <div style={{ marginRight: 8 }}>
                        <ActivityIndicator size="small" color="#fff" />
                      </div>
                    ) : (
                      <img
                        src={
                          quoteButtonSelected === MR_QUOTE_STATUS.ACCEPT
                            ? acceptSelectedIcon
                            : acceptSelectedIcon
                        }
                        className={classes.buttonIconStyle}
                      />
                    )}
                    <div
                      className={classNameGenerator([
                        classes.buttonTextStyle,
                        quoteButtonSelected === MR_QUOTE_STATUS.ACCEPT &&
                          submitLoader &&
                          classes.quoteTextStyle,
                      ])}
                    >
                      Accept
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* cancel button in case of new request */}
            {maintenance.isTenantRequested && isPrivateWorks ? (
              <div
                className={classes.submitButtonStyle}
                onClick={() => {
                  setSubmitLoader(true);
                  cancelRequest({
                    maintenanceId: maintenance.id,
                    onSuccess: () => {
                      getRequests({
                        status: Status.REQUESTED,
                        propertyId: maintenance.propertyDetails.id,
                      });
                      setSubmitLoader(false);
                      ToastMessage.showMessage('Request cancelled', '', 'success', 3000);
                      navigateBack();
                    },
                    onError: () => {
                      setSubmitLoader(false);
                      ToastMessage.showMessage('Failed, try again', '', 'danger', 3000, 4000);
                    },
                  });
                }}
              >
                {submitLoader && (
                  <div style={{ marginRight: 8 }}>
                    <ActivityIndicator size="small" color="#fff" />
                  </div>
                )}
                <div className={classes.submitTextStyle}>Cancel request</div>
              </div>
            ) : null}
            {/* submit review button and modal in case of completed requests */}
            {maintenance.isTenantCompleted && !ratingSubmitted && themeKey !== ThemeKey.SWITCH && (
              <div className={classes.submitButtonStyle} onClick={() => setShowReview(true)}>
                {false && (
                  <div style={{ marginRight: 8 }}>
                    <ActivityIndicator size="small" color="#fff" />
                  </div>
                )}
                <div className={classes.submitTextStyle}>Leave a review</div>
              </div>
            )}
            {renderMaterialCost()}
            {renderReviewModal()}
            {renderRejectAlert()}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedMaintenance: state.landlordState.selectedMaintenance,
  authDetails: state.authState.authDetails,
  submittedReview: state.request.review,
  submittedReviewLoader: state.request.reviewLoader,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatedSelectedMaintenance: (data: MaintenanceResponse) =>
    dispatch(LandlordActions.setSelectedMaintenance(data)),
  cancelRequest: (data: CancelRequest) => dispatch(RequestActions.cancelRequest(data)),
  getRequests: (data: TenantRequest) => dispatch(RequestActions.getRequests(data)),
  submitRequestQuote: (data: RequestQuote) => dispatch(RequestActions.submitRequestQuote(data)),
  submitReview: (data: ReviewRequest) => dispatch(RequestActions.submitReviewRequest(data)),
  getReview: (data: string) => dispatch(RequestActions.getReviewRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandlordMaintenanceInfo);
