import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  GroupRequest,
  GroupResponse,
  CoApplicantRequest,
  RemoveCoApplicantRequest,
  GroupApplicationRequest,
  ApplicationsRequest,
  ApplicationsResponse,
  SyncApplicationRequest,
  InvitationRequest,
  UpdateCoApplicantRequest,
  GroupNameRequest,
  CreateNewGroupRequest,
  WithdrawRequest,
  WithdrawResponse,
  DeleteDraftRequest,
} from '../../models/groups/Groups';

export class GroupsService {
  private readonly serviceTypeGroup: ApiServiceBase = new ApiService(ServiceType.Group);
  private readonly serviceTypeApply: ApiServiceBase = new ApiService(ServiceType.Apply);
  private readonly serviceTypeAccept: ApiServiceBase = new ApiService(ServiceType.Accept);
  private readonly serviceTypeDecline: ApiServiceBase = new ApiService(ServiceType.Decline);
  private readonly serviceTypeCoApplicant: ApiServiceBase = new ApiService(ServiceType.CoApplicant);
  private readonly serviceTypeWithdraw: ApiServiceBase = new ApiService(ServiceType.Withdraw);

  // Get master profile with group context for logged-in User
  public getGroupMasterProfile(payload: GroupRequest): Promise<GroupResponse[]> | GroupResponse[] {
    if (payload.groupRefId) {
      return this.serviceTypeGroup.get<GroupResponse[]>({
        route: [payload.groupRefId],
      });
    } else {
      return this.serviceTypeGroup.get<GroupResponse[]>({});
    }
  }

  // Create a new group for logged-in User
  public postCreateNewGroup(
    payload: CreateNewGroupRequest,
  ): Promise<GroupResponse[]> | GroupResponse[] {
    return this.serviceTypeGroup.post<GroupResponse[]>(
      {
        query: { groupName: payload.groupName },
      },
      payload.coApplicants,
    );
  }

  // Add co-applicant to a group of logged-in User
  public addCoApplicant(payload: CoApplicantRequest[]): Promise<GroupResponse[]> | GroupResponse[] {
    return this.serviceTypeGroup.put({}, payload);
  }

  public updateCoApplicant(
    payload: UpdateCoApplicantRequest,
  ): Promise<GroupResponse[]> | GroupResponse[] {
    return this.serviceTypeCoApplicant.put(
      {
        query: { groupRefId: payload.groupRefId, currentEmail: payload.currentEmail },
      },
      payload.coApplicant,
    );
  }

  // remove co-applicant from a group of logged-in User
  public removeCoApplicant(
    payload: RemoveCoApplicantRequest,
  ): Promise<GroupResponse[]> | GroupResponse[] {
    return this.serviceTypeGroup.delete<GroupResponse[]>({
      query: { ...payload },
    });
  }

  // Submit one application against multiple properties
  public postGroupApplication(
    payload: GroupApplicationRequest,
  ): Promise<ApplicationsResponse> | ApplicationsResponse {
    return this.serviceTypeApply.post<ApplicationsResponse>(
      {
        route: ['submit'],
        query: { groupRefId: payload.groupRefId },
      },
      payload.applyForm,
    );
  }

  // Get all Applications
  public getAllApplications(
    payload: ApplicationsRequest,
  ): Promise<ApplicationsResponse> | ApplicationsResponse {
    return this.serviceTypeApply.get<ApplicationsResponse>({});
  }

  // Sync Applications
  public syncApplications(
    payload: SyncApplicationRequest,
  ): Promise<ApplicationsResponse> | ApplicationsResponse {
    return this.serviceTypeApply.post(
      {
        route: ['sync'],
      },
      payload.applicationRefs,
    );
  }

  // Co-Applicant accepts group invitation
  public acceptInvitation(
    payload: InvitationRequest,
  ): Promise<WithdrawResponse> | WithdrawResponse {
    return this.serviceTypeAccept.post(
      {
        query: { groupRefId: payload.groupRefId },
      },
      payload.applicationRefs,
    );
  }

  // Update Group name
  public updateGroupName(payload: GroupNameRequest): Promise<GroupResponse[]> | GroupResponse[] {
    return this.serviceTypeGroup.put(
      {
        route: ['groupName'],
        query: { groupRefId: payload.groupRefId, groupName: payload.groupName },
      },
      {},
    );
  }

  // Withdraw myself
  public declineInvitation(
    payload: InvitationRequest,
  ): Promise<WithdrawResponse> | WithdrawResponse {
    return this.serviceTypeDecline.post(
      {
        query: { groupRefId: payload.groupRefId },
      },
      payload.applicationRefs,
    );
  }

  // Withdraw myself
  public withdrawMyself(payload: WithdrawRequest): Promise<WithdrawResponse> | WithdrawResponse {
    return this.serviceTypeWithdraw.post(
      {
        route: ['applicant'],
        query: { applicationRefId: payload.applicationRefId },
      },
      {},
    );
  }

  // Withdraw application
  public withdrawApplication(
    payload: WithdrawRequest,
  ): Promise<WithdrawResponse> | WithdrawResponse {
    return this.serviceTypeWithdraw.post(
      {
        route: ['application'],
        query: {
          applicationRefId: payload.applicationRefId,
          type: payload.type!,
          groupRefId: payload.groupRefId,
        },
      },
      {},
    );
  }

  public deleteApplyDraft(
    payload: DeleteDraftRequest,
  ): Promise<ApplicationsResponse> | ApplicationsResponse {
    return this.serviceTypeApply.delete({
      route: ['draft', payload.draftId],
    });
  }
}

const groupsService = new GroupsService();
export default groupsService;
