export type VariantType = 'Light' | 'Regular' | 'Medium' | 'Bold';

export const getFonts = (variant?: VariantType) => {
  switch (variant) {
    case 'Light':
      return 100;
    case 'Regular':
      return 400;
    case 'Medium':
      return 500;
    case 'Bold':
      return 600;
    default:
      return 400;
  }
};
