import React, { FC } from 'react';
import { TagBarItem } from './TagBarConstants';
import { useStyles } from './TagBarStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface TagBarProps {
  tags: TagBarItem[];
  // allows selecting multiple options
  togglable?: boolean;
  // allows only one option at a time but also allows deselcting the only option selected
  singleToggleable?: boolean;
  noScroll?: boolean;
  handlePress: (tags: TagBarItem[], index: number) => void;
  // to be used in case we want no scroll and the wrapped component shouldn't grow
  noGrow?: boolean;
  evenWidth?: boolean;
  inlineCount?: boolean;
}

const TagBar: FC<TagBarProps> = ({
  tags,
  togglable,
  noScroll,
  handlePress,
  singleToggleable,
  noGrow,
  evenWidth,
  inlineCount,
}) => {
  const classes = useStyles();
  const handleTagPress = (index: number) => {
    const result = tags.map((tag, idx) => {
      if (idx === index) {
        return togglable || singleToggleable
          ? { ...tag, active: !tag.active }
          : { ...tag, active: true };
      } else {
        return togglable ? { ...tag } : { ...tag, active: false };
      }
    });
    handlePress(result, index);
  };
  const getInlineCount = (tag: TagBarItem) => {
    if (inlineCount && tag.notifcation) {
      return ` (${tag.notifcation})`;
    } else {
      return '';
    }
  };
  const renderItems = () => (
    <>
      {tags.map((tag, index) => (
        <div
          className={classNameGenerator([classes.tagContainer, tag.active && classes.activeTag])}
          style={{
            ...(noScroll && { marginTop: 10, flexGrow: noGrow ? 0 : 1 }),
            ...(evenWidth && { flex: 1 }),
          }}
          key={index}
          onClick={() => handleTagPress(index)}
        >
          {!!tag.notifcation && !inlineCount && (
            <div className={classes.tagNotificationContainer}>
              <div className={classes.tagNotificationText}>{tag.notifcation}</div>
            </div>
          )}
          <div
            className={classNameGenerator([classes.tagText, tag.active && classes.activeTagText])}
          >
            {tag.label || tag.value}
            {getInlineCount(tag)}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div>
      {noScroll ? (
        <div className={classes.nonScrollContainer}>{renderItems()}</div>
      ) : (
        <div className={classes.tagBarContainer}>{renderItems()}</div>
      )}
    </div>
  );
};

export default TagBar;
