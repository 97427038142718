import { LookUpDetails, PaymentGateway, PlanDetail, ProviderPlan } from '../billing/Billing';
import { Property } from '../property/property';
import { ServiceTypes } from '../services/services';

export interface ContentData {
  Key: string;
  Value: string;
}

type Content = ContentData & {
  type?: 'bold' | 'regular' | 'link' | 'highlighted';
};

export interface PlanInformation {
  sectionTitle: string;
  content: ContentData[];
}

export interface PlanResponse {
  productId: string;
  title: string;
  price: number;
  pricingType?: string;
  uom: string;
  subtext?: string;
  selected?: boolean;
  compare?: boolean;
  planInformation: PlanInformation[];
  cartData?: ServiceDetailsData | null;
  cartItemId?: number;
  selectedProductId?: string;
}

export interface Feature {
  icon: string;
  title: string;
}

export interface Testimonial {
  stars: number;
  description: string;
}

export interface SupplierResponse {
  providerId: string;
  plans: PlanResponse[];
  name: string;
  description: string;
  descriptionLong: string;
  logo: string;
  backgroundImg: string;
  features: Feature[];
  testimonials: Testimonial[];
  selected?: boolean;
  compare?: boolean;
  extendedData?: ProviderPlan;
}

export interface PromoCode {
  title: string;
  value: number;
}

export interface CompleteList {
  all?: boolean;
  options?: boolean;
  terms?: boolean;
  personalDetails?: boolean;
  payment?: boolean;
}

export interface Branch {
  id: number;
  internalCode: string;
}

export interface Agency {
  id: number;
  code: string;
}

export interface CardInfo {
  lastModifiedDate: string;
  createdDate: string;
}
export interface GetCartResponse {
  property: Property;
  cartInfo: CardInfo;
  availableServices: AvailableServicesResponse[];
  user: PersonalDetails;
}
export interface AvailableServicesResponse {
  type: ServiceTypes | string;
  logo: string;
  complete?: CompleteList;
  suppliers?: SupplierResponse[];
}

export interface AvailableServicesRequest {
  propertyId: string;
  hideLoading?: boolean;
  requiredServiceTypes: ServiceTypes[];
}

export interface ModifyCart {
  serviceType: string;
  providerId: string;
  productId: string;
}

export interface AddAndUpdateCartRequest {
  serviceType: string;
  supplierId: string;
  planId: string;
  data: ServiceDetailsData | null;
  checkoutType: CheckoutType;
}

export enum CheckoutType {
  EMAIL = 'EMAIL',
  CHECKOUT = 'CHECKOUT',
  TRADE_REQUEST = 'TRADE_REQUEST',
}

export interface AddAndUpdateCartResponse {
  property: Property;
  cartInfo: CardInfo;
  cartResponse: {
    cartItems: CartItemResponse[];
  };
}

export interface CartItemResponse {
  active: boolean;
  data: ServiceDetailsData | null;
  id: number;
  serviceType: string;
  supplierId: string;
  planId: string;
}

export interface ServiceDetailsData {
  serviceabilityAddress: Property;
  lookupDetails: LookUpDetails | null;
  planDetails: PlanDetail;
  connectionDateDetails: any | null;
  personalDetails: PersonalDetails;
  paymentRefId: string | null;
  transactionId: string | null;
  acceptTermAndCondition: boolean;
}

export interface PaymentRules {
  paymentMethods: string[];
  saveToDB: boolean;
  instantPay: boolean;
  paymentType: PaymentGateway;
}

export interface ServiceabilityAddress {
  id: number;
  address: string;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  postcode: string;
  country: string;
  state: string;
}

export interface AddAndUpdateCartActionRequest {
  availableServices: AvailableServicesResponse[];
  propertyId: string;
  onSuccess?: () => void;
}

export interface CheckoutRequest {
  propertyId: string;
  onSuccess: () => void;
  onError?: () => void;
  checkoutServiceTypes: ServiceTypes[];
}

export interface CheckoutResponse {
  serviceAccountId: number;
  serviceType: string;
  checkoutSuccess: boolean;
  errors: string[];
}

export interface DeleteCartItemRequest {
  propertyId: string;
  serviceType: string;
  planId: string;
  supplierId: string;
}

export interface DeleteCartItemActionRequest {
  deleteCartItemRequest: DeleteCartItemRequest;
  availableServices: AvailableServicesResponse[];
}

export interface InitialConcessionCardValues {
  name?: string;
  cardNumber?: string;
  cardType?: string;
  expiry?: string;
}
export interface PreopenDetails {
  providerId?: string;
  productId?: string;
}

export interface Preopen {
  [key: string]: PreopenDetails;
}

export interface PersonalDetailsFormInitialValues {
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  dob: Date | string | null;
  expiry: Date | string | null;
  idExpiry: Date | string | null;
  idType: string;
  stateIssue: string | null;
  medicareCardColor: string | null;
  referenceNumber: string | null;
  countryOfIssue: string | null;
  idNumber: string;
}

export interface PersonalDetails {
  id: number;
  userType: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  salutation: string | null;
  dob: string | null;
  idType: string | null;
  idNumber: string | null;
  address: string | null;
  idExpiry: string | null;
  active: boolean;
  branches: number[];
  idDetails: IdDetails;
}

export interface IdDetails {
  dlStateIssue: string | null;
  medicareRefNumber: string | null;
  medicareCardColor: string | null;
  passportCountry: string | null;
}

export interface RemindMeLaterRequest {
  propertyId: string;
  flag: boolean;
}

export enum PageName {
  CHECKOUTSELECT,
  CHECKOUTCONFIGURE,
  CHECKOUTFINALLIZE,
  CHECKOUTCOMPARE,
}

export interface PreopenDetails {
  providerId?: string;
  productId?: string;
}
