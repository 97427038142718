import { PostPaymentTokenResponse } from '../../models/payment/Payment';
import { PaymentMethodsType } from './RentOnboardingSummaryConstants';
import CardLogo from '../../assets/credit-card-24-px.svg';
import BankLogo from '../../assets/payment/bank-account.png';

export const handlePaymentDetails = (paymentResponse: PostPaymentTokenResponse | null) => {
  if (paymentResponse === null) {
    return {
      secondaryText: '',
      paymentTypeLogo: '',
    };
  }
  switch (paymentResponse.paymentMethod) {
    case PaymentMethodsType.Bank:
      return {
        secondaryText: `Acc ${paymentResponse.accountNumber} BSB ${paymentResponse.bsb}`,
        paymentTypeLogo: BankLogo,
      };
    case PaymentMethodsType.Credit: {
      return {
        secondaryText: `${paymentResponse.cardNumber}`,
        paymentTypeLogo: CardLogo,
      };
    }
    default:
      return {
        secondaryText: '',
        paymentTypeLogo: '',
      };
  }
};
