/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  primaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      // ...maxContentWidth,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    pageContainer: {
      ...pageContainer,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        ...smallPageContainer,
      },
    },
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingTop: theme.units.spacing * 12,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    contentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
    imagesContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        width: '100%',
        marginBottom: theme.units.spacing * 4,
      },
    },
    phoneContainer: {
      width: 200,
      height: '100%',
      position: 'absolute',
      bottom: -60,
      left: -120,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        width: 180,
        left: -60,
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        display: 'none',
      },
    },
    phone: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    peopleContainer: {
      width: 450,
      height: 450,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        width: 400,
        height: 400,
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        width: '100%',
        borderRadius: theme.units.borderRadius,
        overflow: 'hidden',
      },
    },
    people: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        objectFit: 'cover',
      },
    },
    contentWrapper: {
      marginRight: theme.units.spacing * 12,
    },
    lifeMade: {
      textTransform: 'uppercase',
      marginBottom: theme.units.spacing * 8,
      fontWeight: 'bold',
      color: theme.colors.buttonPrimary,
    },
    title: {
      marginBottom: theme.units.spacing * 6,
      maxWidth: 600,
      fontSize: theme.font.xxxl,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        fontSize: theme.font.xxl,
        maxWidth: 400,
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    description: {
      marginBottom: theme.units.spacing * 16,
      maxWidth: 300,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    buttons: {
      width: 290,
    },
    downloadButton: {
      height: 20,
      width: 236,
      marginBottom: theme.units.spacing * 6,
    },
    storeButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    storeButtonContainer: {
      height: 40,
    },
    storeButton: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
