import React, { FC } from 'react';

import { navigate } from '../../navigation/NavigationUtils';
import { useStyles } from './LoginPromptStyles';

interface LoginPromptProps {
  text: string;
}

const LoginPrompt: FC<LoginPromptProps> = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginText}>{text}</div>
      <div className={classes.button} onClick={() => navigate('login')}>
        <div className={classes.buttonText}>Login</div>
      </div>
    </div>
  );
};

export default LoginPrompt;
