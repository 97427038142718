export interface User {
  id: number;
  userType: UserType;
  name: string;
  surname: string;
  email: string;
  phone: string;
  salutation?: UserSalutation | null | undefined;
  dob?: Date | string | null | undefined;
  idType?: UserIdentifierType | null | undefined;
  idNumber?: string | null | undefined;
  idExpiry?: Date | string | null | undefined;
  address?: string | null | undefined;
  active?: boolean | null | undefined;
  idDetails?: IdDetails;
}

export enum UserType {
  Tenant = 'Tenant',
  Landlord = 'Landlord',
  Agent = 'Agent',
  Admin = 'Admin',
}

export enum UserSalutation {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Ms = 'Ms.',
}

export enum UserIdentifierType {
  DriversLicense = 'Drivers license',
  Passport = 'Passport',
  Medicare = 'Medicare',
}

export interface IdDetails {
  dlStateIssue: string | null;
  medicareRefNumber: string | null;
  medicareCardColor: string | null;
  passportCountry: string | null;
}

export interface Profile {
  dob?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  mobile?: string;
  id?: string;
  homePhone?: string;
  idType?: string;
  idNumber?: string;
  profileUrl?: string;
  title?: string;
  userName?: string;
  refreshToken?: string;
  user?: User;
}

export interface Roles {
  tenant: boolean;
  landlord: boolean;
}

export interface ProfileRequest {
  userId: number;
  intercomId?: string;
  superUser?: boolean;
}

export enum ProfileType {
  TENANT = 'TENANT',
  LANDLORD = 'LANDLORD',
}

export interface UpdateProfile {
  userId: number;
  data: Profile;
}
