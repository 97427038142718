import { LocalMarkrtplaceSummary, Image } from '../../models/landlord/LandlordProperty';
import { FileData } from '../../models/masterProfile/FileUploadState';

export const extractLocalMarketplaceImages = (
  localmarketplaceSummary: LocalMarkrtplaceSummary[],
): Image[] => {
  const images = localmarketplaceSummary.find((data) => data.labelId === 'MediaUpload');

  if (!images) return [];
  return (JSON.parse(images.value) as FileData[]).map((data) => ({
    contentType: 'image/png',
    id: data.link,
    link: data.link,
    name: data.name || '',
  }));
};
