/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';

export const calculateIntentCost = (cartItem: AvailableServicesResponse, cents?: boolean) => {
  const { productDetails, featureDetails } = cartItem.suppliers![0].plans[0].cartData!.planDetails;

  let cost = 0;
  cost += productDetails!.cost as number;
  featureDetails!.map((feature) => {
    if (feature.cost) {
      cost += feature.cost;
    }
  });

  // Need it in cents, hence * 100
  return cents ? cost * 100 : cost;
};

export const getCostItems = (cartItem: AvailableServicesResponse) => {
  const { productDetails, featureDetails } = cartItem.suppliers![0].plans[0].cartData!.planDetails;
  const costItems = [];
  costItems.push({ name: productDetails!.productName!, cost: productDetails!.cost as number });
  featureDetails!.map((feature) => {
    if (feature.cost) {
      costItems.push({ name: feature.label, cost: feature.cost });
    }
  });

  return costItems;
};

export const getCheckoutText = (cartItem: AvailableServicesResponse) => {
  const found = cartItem.suppliers![0].extendedData!.Plans.find(
    (plan) => plan.ProductId === cartItem.suppliers![0].plans[0].productId,
  )!;
  return found.PaymentInformation.CheckoutText;
};
