/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { globalStyles, primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingTop: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 800,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    title: {
      marginBottom: theme.units.spacing * 4,
    },
    boxContainer: {
      padding: theme.units.spacing * 6,
      borderRadius: theme.units.borderRadius * 2,
      borderWidth: 1,
    },
    questionMark: {
      position: 'relative',
      top: '1.5px',
      marginLeft: '8px',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
    subtitle: {
      fontWeight: 'lighter',
      opacity: 0.87,
    },
    headerButton: {
      backgroundColor: theme.colors.light,
      border: `${theme.colors.grey} 2px solid`,
      borderRadius: '6px',
      padding: '8px 25px',
      cursor: 'pointer',
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'none',
      },
    },
    contentContainer: {
      padding: '30px',
      backgroundColor: theme.colors.light,
    },
    inputContainerStyle: {
      width: '100%',
      paddingBottom: '8px',
    },
    inputStyle: {
      paddingTop: '5px',
      paddingBottom: '10px',
      color: theme.colors.black87,
    },
    errorText: {
      color: theme.colors.warning,
      marginTop: '15px',
      fontWeight: 300,
    },

    footerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '30px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column-reverse',
        width: '100%',
      },
    },
    loginButton: {
      marginLeft: theme.units.spacing * 2,
      textDecoration: 'underline',
    },
    signupButton: {
      flex: 1,
      paddingTop: theme.units.spacing * 3,
      paddingBottom: theme.units.spacing * 3,
    },
    secondaryActionContainer: {
      display: 'flex',
      justifyContent: 'center',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginTop: '30px',
      },
    },
    secondaryActionText: {
      fontWeight: 100,
    },
    secondaryAction: {
      marginLeft: '8px',
      color: theme.colors.sortedPurple,
      cursor: 'pointer',
    },
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 64,
      marginTop: 24,
    },
    icon: {
      width: 40,
      height: 40,
      objectFit: 'contain',
    },
    primaryTitle: {
      marginLeft: theme.units.spacing * 4,
      color: theme.colors.title,
      fontSize: theme.font.xxxl,
      fontWeight: getFonts('Medium'),
    },
    marginBottom: {
      marginBottom: theme.units.spacing * 4,
    },
    secondInput: {},
    loginRow: {
      marginTop: theme.units.spacing * 8,
      display: 'flex',
      alignItems: 'center',
    },
    signupRow: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    subtitleRow: {
      display: 'flex',
      alignItems: 'center',
    },
    inputRow: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.units.spacing * 8,
      flexDirection: 'column',
    },
    list: {
      paddingInlineStart: 16,
    },
    dotPoint: {
      color: theme.colors.black38,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5000,
      width: 60,
      height: 60,
      marginBottom: theme.units.spacing * 4,
    },
    blogIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
