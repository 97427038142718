import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CheckoutSummaryStyles';
import { LABELS } from './CheckoutSummaryConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { updateAccountInAvailableServices, useCart } from '../../helpers/CheckoutHelper';
import { isPersonalDetailsCompleted } from '../checkoutPersonalDetails/components/accountSection/AccountSectionUtils';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  CheckoutRequest,
  PersonalDetails,
} from '../../models/checkout/Checkout';
import { CheckoutActionTypes, CheckoutActions } from '../../store/actions/CheckoutActions';
import { Property } from '../../models/property/property';
import { ServiceTypes } from '../../models/services/services';
import { getPreSelectedPaymentMethods } from '../checkoutFinalize/components/paymentSection/PaymentSectionUtils';
import { ValidMessages, sendMessageToApp } from '../../helpers/MessageHelper';
import Button from '../../components/button/Button';
import Cart from '../cart/Cart';
import { hasAnyPayments } from '../checkoutFinalize/CheckoutFinalizeUtils';
import CartItem from '../cart/components/cartItem/CartItem';
import { essentialServices } from '../checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';

interface CheckoutSummaryProps {
  loading: boolean;
  error: any;
  property: Property;
  isExternal: boolean;
  personalDetails: PersonalDetails;
  availableServices: AvailableServicesResponse[];
  requiredServiceTypes: ServiceTypes[];
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  postCheckout: (data: CheckoutRequest) => void;
  resetErrors: () => void;
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({
  loading,
  error,
  isExternal,
  property,
  personalDetails,
  availableServices,
  requiredServiceTypes,
  updateCart,
  postCheckout,
  resetErrors,
}) => {
  const classes = useStyles();
  const { paymentItems, cartItems, cartServices } = useCart();
  const selectedPaymentMethods = getPreSelectedPaymentMethods(paymentItems);

  useEffect(() => {
    resetErrors();
  }, []);

  const isButtonEnabled = useMemo<boolean>(() => {
    if (loading) return false;
    let isPaymentMethodsSelected: boolean[] = [];

    paymentItems.map((cartItem, index) => {
      if (cartItem.complete && cartItem.complete.payment) {
        isPaymentMethodsSelected.push(true);
      } else {
        if (
          cartItem.suppliers![0].plans[0].cartData &&
          !cartItem.suppliers![0].plans[0].cartData.planDetails.skipPaymentStep
        ) {
          isPaymentMethodsSelected.push(!!selectedPaymentMethods[index]);
        } else {
          isPaymentMethodsSelected.push(true);
        }
      }
    });
    const isPersonalInfoCompleted = isPersonalDetailsCompleted(personalDetails);
    const isAllServicesPaymentCompleted =
      isPaymentMethodsSelected.findIndex((paymentMethod) => !paymentMethod) > -1;
    return !isAllServicesPaymentCompleted && isPersonalInfoCompleted;
  }, [paymentItems, loading]);

  const onNext = () => {
    let updatedAvailableServices = [...availableServices];
    cartItems &&
      cartItems.length > 0 &&
      cartItems.forEach((cartItem) => {
        updatedAvailableServices = updateAccountInAvailableServices(
          updatedAvailableServices,
          cartItem.type,
          cartItem.suppliers![0].providerId,
          cartItem.suppliers![0].plans[0].productId,
          personalDetails,
        );
      });
    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: property.id.toString(),
      onSuccess: () => {
        postCheckout({
          propertyId: property.id.toString(),
          checkoutServiceTypes: requiredServiceTypes,
          onSuccess: () => {
            if (isExternal) {
              navigate('checkoutCompleteCart');
            } else {
              sendMessageToApp(ValidMessages.Dashboard, {
                toHome: true,
                showSetupCompleteToast: true,
              });
            }
          },
        });
      },
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">
            {!!cartServices.length ? 'Payment' : 'Confirm service details'}
          </Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
              {LABELS.CONFIRM_DETAILS}
            </div>
            {!!cartServices.length && (
              <>
                <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
                  {LABELS.PERSONAL_DETAILS}
                </div>
                {hasAnyPayments(cartItems) && (
                  <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
                    {LABELS.PAYMENT}
                  </div>
                )}
              </>
            )}

            <div className={classes.title} style={{ marginBottom: 32, borderBottom: 'none' }}>
              {LABELS.SUMMARY}
            </div>
            <Cart summary />
            {error && (
              <Text parentStyles={classes.error} textVariant="error">
                Oops we've hit a snag, please reach out to support and we'll sort it all out
              </Text>
            )}
            <div className={classes.buttonContainer}>
              <Button
                loading={loading}
                disabled={!isButtonEnabled}
                onPress={() => onNext()}
                parentStyles={classes.button}
              >
                {LABELS.FINISH}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([CheckoutActionTypes.CHECKOUT]);
const error = errorMessageSelector([
  CheckoutActionTypes.CHECKOUT,
  CheckoutActionTypes.ADD_AND_UPDATE_CART,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  error: error(state),
  property: state.checkout.property,
  personalDetails: state.checkout.personalDetails,
  availableServices: state.checkout.availableServices,
  isExternal: state.token.isExternal,
  requiredServiceTypes: state.checkout.requiredServiceTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetErrors: () => {
    dispatch(CheckoutActions.resetCheckout());
    dispatch(CheckoutActions.resetAddUpdate());
  },
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  postCheckout: (data: CheckoutRequest) => dispatch(CheckoutActions.postCheckout(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary);
