import { Agency } from '../agency/agency';
import { Lease, LeaseData, Tenants } from '../lease/lease';
import { User } from '../user/user';

export interface Property {
  id: number;
  address: string;
  type: PropertyType | string | undefined | null;
  bedrooms: number | undefined | null;
  bathrooms: number | undefined | null;
  carspaces: number | undefined | null;
  isMaintenanceAllowed: boolean | undefined;
  description: string | undefined | null;
  managementFee: number | undefined | null;
  commissionType: string | undefined | null;
  secondaryAgents: User[] | undefined | null;
  landlords: User[] | undefined | null;
  managingAgent: User | undefined | null;
  leasingAgent: User | undefined | null;
  photos: PhotoLink[] | undefined | null;
  unitNumber: string | undefined | null;
  streetName: string | undefined | null;
  suburb: string | undefined | null;
  postcode: string | undefined | null;
  streetNumber: string | undefined | null;
  state: string | undefined | null;
  country: string | undefined | null;
  stateBondOffice: string | undefined | null;
  leases: Lease[] | undefined | null;
  leaseData: LeaseData;
  tenants: Tenants;
  emergencyMaintenanceLink: string;
  propertyClass: PropertyClass | undefined | null;
  extendedData?: ExtendedData;
  blogs: BlogLink[];
  refId: string;
  agencyCode: string;
  agency: Agency;
  branch: Branch;
  lease: Lease[];
  ownerships: string[];
  lessor: string[];
  pmsLink?: string;
}

export interface Branch {
  id: number;
  internalCode: string;
}

export interface PhotoLink {
  id: number;
  link: string;
  createdBy: string;
  createdDate: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface AddressComponents {
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
}

export enum PropertyType {
  Home = 'Home',
  House = 'House',
  Townhouse = 'Townhouse',
  Apartment = 'Apartment',
  Villa = 'Villa',
}

export enum PropertyClass {
  Tenant = 'Tenant',
  Owner = 'Owner occupier',
  Landlord = 'Landlord',
  Accommodation = 'Accommodation',
}

export interface OnboardingPropertyDetails {
  address: AddressComponents;
  selectedType: PropertyType | undefined;
  selectedClass: PropertyClass | undefined;
}

export interface SetOnboardingPropertyDetails {
  field: string;
  value: AddressComponents | PropertyClass | PropertyType;
}

export interface PropertyOnboardingRequest {
  selectedClass: PropertyClass;
  selectedType: PropertyType;
  address: AddressComponents;
}

export interface ExtendedData {
  contractDetails: ContractDetails;
  documents: Document[];
  salesProperty: boolean;
}

export interface ContractDetails {
  contractDate: string;
  price: number;
  settledDate: string;
  unconditionalDate: string;
}

export interface Document {
  title: string;
  type: string;
  url: string;
}

export interface BlogLink {
  imageLink: string;
  title: string;
  link: string;
}
