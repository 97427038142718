import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { SIZES } from '../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    terms: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      color: theme.colors.black87,
      marginBottom: theme.units.spacing * 6,
      lineHeight: 1.5,
      '& > ul': {
        fontSize: 14,
        color: theme.colors.black60,
      },
    },
    detailCard: {
      padding: theme.units.spacing * 6,
      marginBottom: theme.units.spacing * 4,
      backgroundColor: theme.colors.lighterGrey,
      borderRadius: theme.units.borderRadius,
    },
    switchContainer: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
      paddingBottom: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    linkHeader: {
      paddingTop: 10,
      paddingLeft: 20,
    },
    seperator: {
      height: 1,
      margin: 4,
      marginBottom: 20,
      background: '#eee',
    },
    link: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.buttonPrimary,
      textDecoration: 'underline',
      marginBottom: theme.units.spacing * 3,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    keyText: {
      fontSize: 16,
      marginRight: 10,
      cursor: 'pointer',
    },
    blueText: {
      color: theme.colors.buttonPrimary,
      textDecoration: 'underline',
    },
    acceptanceChecks: {
      flex: 1,
      backgroundColor: '#ffffff',
      width: '100%',
      flexDirection: 'column',
      marginBottom: 10,
      marginLeft: -20,
    },
    flexRow: {
      flex: 1,
      width: '100%',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 10,
      paddingTop: 10,
      alignItems: 'center',
    },
    termsSection: {
      marginRight: 20,
      paddingTop: 6,
      paddingLeft: 10,
    },
    acceptTermsdefault: {
      height: 20,
      width: 20,
      border: '2px solid #D3D3D3',
    },
    acceptedTerms: {
      height: 24,
      width: 24,
      backgroundColor: '#2d1b49',
    },
    tickImage: {
      height: 18,
      width: 18,
      display: 'flex',
      paddingLeft: 3,
      paddingTop: 3,
    },
    checkboxGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    checkboxText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      cursor: 'pointer',
    },
  })();
};
