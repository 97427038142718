import { Preopen } from '../models/checkout/Checkout';

/* eslint-disable import/prefer-default-export */
export const onboardingUrls = {
  checkoutUrl: (
    propertyId: number,
    preopenURL?: Preopen,
    requiredURLServiceTypes?: string,
    isLandlord?: boolean,
    isTradeRequest?: boolean,
  ) => {
    let url = `checkoutSelect?propertyId=${propertyId}&app=${'SORTED'}`;
    if (requiredURLServiceTypes) {
      url += `&requiredServiceTypes=${requiredURLServiceTypes}`;
    }
    if (isLandlord) {
      url += '&isLandlord=true';
    }
    if (isTradeRequest) {
      url += '&isTradeRequest=true';
    }
    if (preopenURL) {
      try {
        url += `&preopen=${JSON.stringify(preopenURL)}`;
      } catch (err) {
        console.log(err);
      }
    }
    return url;
  },
  service: (type: string, propertyId?: string) =>
    `onboarding/${type}/${propertyId}?app=${'SORTED'}`,
  editRentPayment: (
    type: string,
    propertyId: number,
    paymentRefId: string,
    serviceAccountId: number,
    agencyName?: string,
  ) =>
    `payment/${propertyId}/${type}/saved/${paymentRefId}/${serviceAccountId}/${agencyName}?app=${'SORTED'}`,
  commonServiceSummary: (propertyId: number, serviceAccountId: number) =>
    `onboarding/commonSummary/${propertyId}/summary/${serviceAccountId}?app=${'SORTED'}`,
  summary: (type: string, propertyId: number, serviceType: string) =>
    `onboarding/${type}/${propertyId}/termsAndConditions/${serviceType}`,
  electricitySignUpUrl: (propertyId: number, serviceAccountId?: number) =>
    serviceAccountId
      ? `onboarding/electricity/${propertyId}/overview/${serviceAccountId}?app=${'SORTED'}`
      : `onboarding/electricity/${propertyId}/overview?app=${'SORTED'}`,
  gasSignUpUrl: (propertyId: number, serviceAccountId?: number) =>
    serviceAccountId
      ? `onboarding/gas/${propertyId}/overview/${serviceAccountId}?app=${'SORTED'}`
      : `onboarding/gas/${propertyId}/overview?app=${'SORTED'}`,
  broadbandSignUpUrl: (propertyId: number, serviceAccountId?: number) =>
    serviceAccountId
      ? `onboarding/broadband/${propertyId}/overview/${serviceAccountId}?app=${'SORTED'}`
      : `onboarding/broadband/${propertyId}/overview?app=${'SORTED'}`,
  otherServiceSignUpUrl: (serviceName: string, propertyId: number, supplierId: string) =>
    `onboarding/otherService/${serviceName}/${propertyId}/overview/${supplierId}?appName=${'SORTED'}`,
  propertyOnboarding: () => `onboarding/propertyOnboard?app=${'SORTED'}`,
  otherServiceInfo: (serviceName: string, propertyId: number, serviceAccountId?: number) =>
    serviceAccountId
      ? `onboarding/otherService/${serviceName}/${propertyId}/info/${serviceAccountId}?appName=${'SORTED'}`
      : `onboarding/otherService/${serviceName}/${propertyId}/info?appName=${'SORTED'}`,
  // Summary screens for plan selection
  commonServiceOverview: (
    serviceType: string,
    propertyId: string,
    providerName: string,
    addressConfirmed: string,
    lookupRequired: string,
    serviceAccountId: any, // TODO: Resolve any
  ) =>
    serviceAccountId
      ? `onboarding/commonService/${propertyId}/overview/${serviceType}/${addressConfirmed}/${lookupRequired}/${providerName}/${serviceAccountId}?app=${'SORTED'}`
      : `onboarding/commonService/${propertyId}/overview/${serviceType}/${addressConfirmed}/${lookupRequired}/${providerName}?app=${'SORTED'}`,
  // Sign up flow for plan selection
  commonServiceSignUpUrl: (
    serviceType: string,
    propertyId: number,
    providerName: string,
    addressConfirmed: string,
    lookupRequired: string,
    serviceAccountId: any, // TODO: Resolve any
    first?: string,
  ) =>
    first
      ? `onboarding/commonService/service/${serviceType}/${propertyId}/address/${providerName}/${addressConfirmed}/${lookupRequired}/${serviceAccountId}/${first}?app=${'SORTED'}`
      : `onboarding/commonService/service/${serviceType}/${propertyId}/address/${providerName}/${addressConfirmed}/${lookupRequired}/${serviceAccountId}?app=${'SORTED'}`,
  iframeService: (
    serviceType: string,
    propertyId: string,
    providerName: string,
    addressConfirmed: string,
    lookupRequired: string,
    serviceAccountId: any,
  ) =>
    `onboarding/commonService/${serviceType}/Summary/${propertyId}/${providerName}/kanoppi/${addressConfirmed}/${lookupRequired}/${serviceAccountId}?app=${'SORTED'}`,
  commonServiceBasePlan: (
    serviceType: string,
    propertyId: number,
    providerName: string,
    addressConfirmed: string,
    lookupRequired: string,
  ) =>
    `onboarding/commonService/${serviceType}/overview/${propertyId}/${providerName}/addressConfirmed/${addressConfirmed}/${lookupRequired}?app=${'SORTED'}`,
};
