/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable default-case */
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useStyles } from './PaymentSectionStyles';
import { LABELS, PaymentType } from './PaymentSectionConstants';
import selectedIcon from '../../../../assets/radio-filled.png';
import selectedIconBlack from '../../../../assets/radio-filled-black.png';
import radioEmpty from '../../../../assets/radio-empty.png';
import addIcon from '../../../../assets/payment/filled.png';
import bankIcon from '../../../../assets/payment/account-balance-24-px.svg';
import cardIcon from '../../../../assets/payment/credit-card-24-px.svg';
import calendarIcon from '../../../../assets/outlined.svg';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import lockIcon from '../../../../assets/lock-24-px.svg';
import value from 'card-validator';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';
import { getServicePaymentRules } from './PaymentSectionUtils';
import tick from '../../../../assets/radio-outlined.png';
import { Property } from '../../../../models/property/property';
import {
  BankAccountRequest,
  CreditCardRequest,
  GetPaymentMethodsResponse,
  GetPaymentType,
  PostPaymentTokenResponse,
  SaveSelectedPaymentMethodPayload,
} from '../../../../models/payment/Payment';
import { PaymentGateway } from '../../../../models/billing/Billing';
import { ApplicationState } from '../../../../store/RootReducer';
import { PaymentActionTypes, PaymentActions } from '../../../../store/actions/PaymentActions';
import {
  CardType,
  InitialCardValues,
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardNumberPattern,
  cardTypeNotSupportedError,
  expiryDateError,
  expiryDateLength,
  expiryDatePattern,
  initialCardValues,
  invalidCardError,
  nameFormatError,
  nameMaxLengthError,
  requiredFieldError,
} from '../../../payment/addPayment/addCard/AddCardConstants';
import {
  InitialBankValues,
  accountNameRegex,
  accountNumberRegex,
  bsbNumberRegex,
  bsbValidatorPattern,
  errorMessages,
  initialBankValues,
  lengths,
} from '../../../payment/addPayment/addBank/AddBankConstants';
import { dateValidator } from '../../../../helpers/DateFormatter';
import {
  maskBankAccountDetails,
  maskCardDetails,
} from '../../../payment/addPayment/AddPaymentUtils';
import { PAYMENT_LABELS } from '../../../payment/selectPayment/PaymentDetailsConstants';
import { Snackbar } from '@mui/material';
import { renderCardIcon } from '../../../payment/addPayment/addCard/AddCardUtils';
import { CardHelper } from '../../../../helpers/CardHelper';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import Input from '../../../../components/input/Input';
import InputDate from '../../../../components/inputDate/InputDate';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';

interface PaymentSectionProps {
  serviceType: string;
  property: Property;
  accessToken: string | boolean | null;
  paymentMethods: GetPaymentMethodsResponse[];
  paymentErrorState: any;
  selectedPaymentRefId: string;
  cartItem: AvailableServicesResponse;
  loading: boolean;
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => void;
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => void;
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => void;
  getPaymentAuthKey: (
    data: BankAccountRequest | CreditCardRequest,
    fetchPayments: () => void,
    setPaymentMethod: (refId: string) => void,
    setSucceeded: (value: boolean) => void,
    paymentGateway?: PaymentGateway,
    providerId?: string,
    transactionId?: string,
  ) => void;
  setPaymentMethod: (refId: string) => void;
  setHeightOnAddNew: () => void;
  setPaymentRefIdOnAddNew: () => void;
  resetPaymentError: () => void;
}

const PaymentSection: FC<PaymentSectionProps> = ({
  serviceType,
  property,
  accessToken,
  paymentMethods,
  paymentErrorState,
  selectedPaymentRefId,
  cartItem,
  loading,
  saveSelectedPaymentMethod,
  getPaymentMethods,
  getPaymentTypes,
  getPaymentAuthKey,
  setPaymentMethod,
  setHeightOnAddNew,
  setPaymentRefIdOnAddNew,
  resetPaymentError,
}) => {
  const classes = useStyles();
  const paymentRules = getServicePaymentRules(cartItem);
  const selectedPaymentMethods = paymentMethods.findIndex(
    (paymentMethod) => paymentMethod.refId === selectedPaymentRefId,
  );
  const [index, setIndex] = useState<number | boolean>(
    selectedPaymentMethods !== -1 ? selectedPaymentMethods : false,
  );
  const [payment, setPayment] = useState<boolean | GetPaymentMethodsResponse>(false);
  const [succeeded, setSucceeded] = useState<boolean>(
    (!!cartItem.suppliers![0].plans[0].cartData!.paymentRefId &&
      paymentRules.paymentType === PaymentGateway.WESTPAC_QUICK) ||
      false,
  );
  const [addNew, setAddNew] = useState<boolean>(!paymentRules.saveToDB || false);
  const [newMethod, setNewMethod] = useState<PaymentType>(
    paymentRules.paymentMethods.includes(PaymentType.Bank) ? PaymentType.Bank : PaymentType.Credit,
  );

  useEffect(() => {
    if (addNew) {
      // call current height func here
      setHeightOnAddNew();
    }
  }, [addNew]);

  // Card State
  const [cardType, setCardType] = useState<string>('');
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>('');
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);

  // Bank State
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);

  useEffect(() => {
    resetPaymentError();
    if (accessToken) {
      getPaymentTypes({ propertyId: property.id!.toString(), serviceType }, true, () => true);
    }
  }, []);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const selectedPaymentMethodsIndex = paymentMethods.findIndex(
        (paymentMethod) => paymentMethod.refId === selectedPaymentRefId,
      );
      setIndex(selectedPaymentMethodsIndex !== -1 ? selectedPaymentMethodsIndex : false);
    }
  }, [paymentMethods]);

  const handlePaymentChange = (paymentToChange: GetPaymentMethodsResponse, idx: number) => {
    if (idx === index) {
      setIndex(false);
      setPaymentMethod('');
      return;
    }
    setPayment(paymentToChange);
    setIndex(idx);
    setAddNew(false);
    // update payment method ref id here
    setPaymentMethod(paymentToChange.refId);
  };

  const handleSetMethod = (method: PaymentType) => {
    setIndex(false);
    setNewMethod(method);
    resetPaymentError();
  };

  // Card methods
  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType('');
      setCardTypeValidation(true);
    } else if (cardType === '' && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation = type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, '')).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(`${cardType ? cardType : ''} ${cardTypeNotSupportedError}`);
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue('');
        break;
    }
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, 'paymentCard'));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  const saveNewCard = (data: InitialCardValues) => {
    const expiryDate = data.expiryDate.split('/');
    const paymentRequest: CreditCardRequest = {
      type: 'CREDITCARD',
      cardHolderName: data.cardHolderName,
      cardNumber: data.cardNumber,
      email: '',
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    const maskedCardNumber = maskCardDetails(data.cardNumber.slice(-4), cardType);
    saveSelectedPaymentMethod({
      paymentMethodId: '1',
      maskedDetails: maskedCardNumber,
    });
    getPaymentAuthKey(
      paymentRequest,
      () => getPaymentMethods(() => true),
      setPaymentMethod,
      setSucceeded,
      paymentRules.paymentType,
      cartItem.suppliers![0].providerId,
      cartItem.suppliers![0].plans[0].cartData!.transactionId!,
    );
  };

  // Bank methods
  const handleAccountNumber = (accountNumber: string, setFieldValue: any) => {
    setFieldValue('accountNumber', accountNumber.replace(accountNumberRegex, ''));
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const saveNewBank = (data: InitialBankValues) => {
    const maskedBankAccountDetails = maskBankAccountDetails(data.accountNumber, data.bsbNumber);
    const paymentRequest: BankAccountRequest = {
      type: 'BANKACCOUNT',
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      bsbNumber: data.bsbNumber,
      email: '',
    };
    saveSelectedPaymentMethod({
      paymentMethodId: '2',
      maskedDetails: maskedBankAccountDetails,
    });
    getPaymentAuthKey(
      paymentRequest,
      () => getPaymentMethods(() => true),
      setPaymentMethod,
      setSucceeded,
      paymentRules.paymentType,
      cartItem.suppliers![0].providerId,
      cartItem.suppliers![0].plans[0].cartData!.transactionId!,
    );
  };

  const renderFooter = (disabled: boolean, handleSubmit: () => void) => (
    <div className={classes.addNewPaymentFooter}>
      <div className={classes.secureBoxStyle}>
        <img src={lockIcon} className={classes.secureLockStyle} alt="lock" />
        <div className={classes.secureText}>{LABELS.SECURE_CONNECTION}</div>
      </div>
      <div className={classes.cancelNewOptionSave} onClick={() => setAddNew(false)}>
        {LABELS.CANCEL}
      </div>
      <div
        className={classNameGenerator([
          classes.addNewOptionSave,
          disabled && classes.disabledButton,
        ])}
        onClick={() => {
          if (!disabled) {
            handleSubmit();
          }
        }}
      >
        {loading ? LABELS.LOADING : LABELS.SAVE}
      </div>
    </div>
  );

  return (
    <div className={classes.sectionContainer}>
      {/* Saved Payment methods */}
      {succeeded ? (
        <div className={classes.successContainer}>
          <img src={tick} className={classes.successIcon} alt="" />
          <div className={classes.successText}>{LABELS.CAPTURED}</div>
        </div>
      ) : (
        <div className={classes.paymentSection}>
          <div className={classes.paymentSectionTitle}>{LABELS.PAYMENT_METHODS}</div>
          <div className={classes.paymentOptionsGrid}>
            {paymentRules.saveToDB &&
              paymentMethods &&
              paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, idx: number) => {
                const paymentMethodDetails =
                  paymentMethod.paymentMethod === PaymentType.Bank
                    ? PAYMENT_LABELS.paymentMethodDetails.Bank
                    : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
                        (data) => data.cardType === paymentMethod.cardType,
                      )!;
                return (
                  <div
                    onClick={() => handlePaymentChange(paymentMethod, idx)}
                    className={classNameGenerator([
                      classes.menuItem,
                      idx === index && classes.addNewOptionSelected,
                    ])}
                    key={idx}
                  >
                    <div className={classes.optionContainer}>
                      <div className={classes.optionSubtitle}>Saved payment method</div>
                      <div className={classes.optionTitle}>
                        {paymentMethodDetails.name} (
                        {paymentMethod.paymentMethod === 'Bank'
                          ? `${paymentMethod.bsb} - ${paymentMethod.accountNumber}`
                          : paymentMethod.paymentMethod === 'Credit'
                          ? `${paymentMethod.cardNumber!.replace(/\./g, '*')}`
                          : '**** **** **** 1234'}
                        )
                      </div>
                    </div>
                    <div className={classes.iconContainer}>
                      <img src={paymentMethodDetails.logo} className={classes.iconImage} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
          {/* Add new */}
          {addNew ? (
            <>
              <div className={classes.addPaymentSection}>
                <div className={classes.paymentDetailsTitle}>Add new payment method</div>
                {paymentRules.paymentMethods.length > 1 && (
                  <div className={classes.twoColumnGrid} style={{ marginBottom: 16 }}>
                    <div
                      className={classNameGenerator([
                        classes.addNewOption,
                        addNew && newMethod === PaymentType.Credit && classes.addNewOptionSelected,
                      ])}
                      onClick={() => {
                        handleSetMethod(PaymentType.Credit);
                      }}
                    >
                      Credit card
                    </div>
                    <div
                      className={classNameGenerator([
                        classes.addNewOption,
                        addNew && newMethod === PaymentType.Bank && classes.addNewOptionSelected,
                      ])}
                      onClick={() => {
                        handleSetMethod(PaymentType.Bank);
                      }}
                    >
                      Bank Account
                    </div>
                  </div>
                )}
                {newMethod === PaymentType.Credit ? (
                  <Formik
                    initialValues={initialCardValues}
                    onSubmit={(data) => saveNewCard(data)}
                    isInitialValid={false}
                    validationSchema={Yup.object().shape({
                      cardHolderName: Yup.string()
                        .max(cardHolderNameMaxLength, nameMaxLengthError)
                        .matches(cardHolderNameRegex, nameFormatError)
                        .required(requiredFieldError),
                      cardNumber: Yup.string().min(19).required(requiredFieldError),
                      expiryDate: Yup.string().min(5).required(requiredFieldError),
                      cvv: Yup.string().min(cvvLength).required(requiredFieldError),
                    })}
                    render={({
                      values,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      isValid,
                      touched,
                      errors,
                    }) => (
                      <>
                        <Input
                          placeholder={LABELS.CARD_NAME}
                          value={values.cardHolderName}
                          setValue={(value: string) => {
                            setFieldValue('cardHolderName', value);
                          }}
                          touched={touched.cardHolderName}
                          error={
                            errors.cardHolderName ||
                            (paymentErrorState.includes('cardholder name') && paymentErrorState)
                          }
                          title={LABELS.CARD_NAME}
                          mandatory
                          big
                        />
                        <Input
                          placeholder={LABELS.CARD_NUMBER}
                          value={values.cardNumber}
                          setValue={(value: string) => {
                            setFieldValue('cardNumber', value);
                            handleCardNumber(value);
                          }}
                          touched={touched.cardNumber}
                          error={
                            errors.cardNumber ||
                            cardErrorValue ||
                            (paymentErrorState.includes('card number') && paymentErrorState)
                          }
                          title={LABELS.CARD_NUMBER}
                          mandatory
                          big
                          endElement={
                            <img src={cardError ? errorIcon : renderCardIcon(cardType)} alt="" />
                          }
                        />
                        <div className={classes.twoColumn}>
                          <InputDate
                            placeholder="Expiry (MM/YY)"
                            yearMonthOnly
                            value={values.expiryDate}
                            setValue={(value: string) => {
                              expiryDateValidation(value);
                              setFieldValue('expiryDate', value);
                            }}
                            touched={touched.expiryDate}
                            error={
                              errors.expiryDate ||
                              (paymentErrorState.includes('expiry date') && paymentErrorState)
                            }
                            title={LABELS.EXPIRY}
                            minDate={new Date()}
                            blockedDates={[]}
                            mandatory
                            big
                          />
                          <Input
                            placeholder={LABELS.CSV}
                            value={values.cvv}
                            setValue={(value: string) => {
                              setFieldValue('cvv', CardHelper.formatCvvNumber(value));
                            }}
                            touched={touched.cvv}
                            error={
                              errors.cvv || (paymentErrorState.includes('CVN') && paymentErrorState)
                            }
                            title={LABELS.CSV}
                            mandatory
                            big
                            endElement={<img src={lockIcon} alt="" />}
                          />
                        </div>
                        {!!paymentErrorState && (
                          <div className={classes.error}>
                            Oops! Please double check above for any errors
                          </div>
                        )}
                        {renderFooter(
                          cardError || loading || expiryError || !isValid,
                          handleSubmit,
                        )}
                      </>
                    )}
                  />
                ) : (
                  <Formik
                    initialValues={initialBankValues}
                    onSubmit={(data) => saveNewBank(data)}
                    validationSchema={Yup.object().shape({
                      accountName: Yup.string()
                        .required(errorMessages.required)
                        .max(lengths.accNameMax, errorMessages.accNameLength)
                        .matches(accountNameRegex, errorMessages.accName),
                      bsbNumber: Yup.string()
                        .required(errorMessages.required)
                        .matches(bsbNumberRegex, errorMessages.invalidBsb),
                      accountNumber: Yup.string()
                        .required(errorMessages.required)
                        .min(lengths.accNumMin, errorMessages.accNumMin)
                        .max(lengths.accNumMax),
                    })}
                    render={({
                      values,
                      setFieldTouched,
                      setFieldValue,
                      handleSubmit,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <>
                        <Input
                          placeholder={LABELS.ACCOUNT_NAME}
                          value={values.accountName}
                          setValue={(value: string) => {
                            setFieldValue('accountName', value);
                          }}
                          touched={touched.accountName}
                          error={errors.accountName}
                          title={LABELS.ACCOUNT_NAME}
                          mandatory
                          big
                        />
                        <Input
                          placeholder={LABELS.BSB}
                          value={values.bsbNumber}
                          setValue={(value: string) => {
                            setFieldValue('bsbNumber', value);
                          }}
                          touched={touched.bsbNumber}
                          error={
                            errors.bsbNumber ||
                            (paymentErrorState.includes('BSB') && paymentErrorState)
                          }
                          title={LABELS.BSB}
                          mandatory
                          big
                          endElement={<img src={lockIcon} alt="" />}
                        />
                        <Input
                          placeholder={LABELS.ACCOUNT_NUMBER}
                          value={values.accountNumber}
                          setValue={(value: string) => {
                            handleAccountNumber(value, setFieldValue);
                          }}
                          touched={touched.accountNumber}
                          error={
                            errors.accountNumber ||
                            (paymentErrorState.includes('account number') && paymentErrorState)
                          }
                          title={LABELS.ACCOUNT_NUMBER}
                          mandatory
                          big
                          endElement={<img src={lockIcon} alt="" />}
                        />
                        {!!paymentErrorState && (
                          <div className={classes.error}>
                            Oops! Please double check above for any errors
                          </div>
                        )}
                        {renderFooter(!isValid, handleSubmit)}
                      </>
                    )}
                  />
                )}
              </div>
            </>
          ) : (
            <div
              className={classes.menuItem}
              style={{ marginBottom: 0, justifyContent: 'flex-start' }}
              onClick={() => {
                setAddNew(true);
                // set payment ref id to null to that service
                setPaymentRefIdOnAddNew();
                setIndex(false);
              }}
            >
              <div className={classes.iconContainer}>
                <img src={addIcon} className={classes.iconImage} alt="add" />
              </div>
              <div
                className={classes.optionTitle}
                style={{ marginLeft: 16, fontSize: 18, marginTop: 0 }}
              >
                {PAYMENT_LABELS.addNewLabel}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const error = errorMessageSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);
const loading = loadingSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  paymentMethods: state.payment.paymentMethods,
  accessToken: state.token.accessToken,
  paymentErrorState: error(state),
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPaymentError: () => dispatch(PaymentActions.getPaymentAuthKeyReset()),
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentTypesStart({
        data,
        onSuccess: () => onSuccess(isPaymentMethods),
      }),
    );
  },
  getPaymentAuthKey: (
    data: BankAccountRequest | CreditCardRequest,
    fetchPayments: () => void,
    setPaymentMethod: (refId: string) => void,
    setSucceeded: (value: boolean) => void,
    paymentGateway?: PaymentGateway,
    providerId?: string,
    transactionId?: string,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        paymentGateway,
        providerId,
        onSuccess: (token: string) => {
          if (paymentGateway === PaymentGateway.WESTPAC_QUICK) {
            dispatch(
              PaymentActions.getRefIdRequest({
                token,
                providerId,
                transactionId,
                onSuccess: (refId: string) => {
                  setSucceeded(true);
                  setPaymentMethod(refId);
                },
              }),
            );
          } else {
            dispatch(
              PaymentActions.postPaymentTokenStart({
                data: { resultKey: token },
                onSuccess: (response: PostPaymentTokenResponse) => {
                  fetchPayments();
                  setSucceeded(true);
                  setPaymentMethod(response.refId);
                },
              }),
            );
          }
        },
        onError: (error: Error) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSection);
