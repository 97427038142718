import { History } from 'history';
import { PropertyOnboardingStep } from './PropertyOnboardingConstants';
import villa from '../../assets/villa.svg';
import owner from '../../assets/owner.svg';
import landlord from '../../assets/landlord.svg';
import key from '../../assets/key.svg';
import apartment from '../../assets/apartment.svg';
import homeOutline from '../../assets/home-outline.svg';
import homeSolid from '../../assets/home-solid.svg';
import {
  AddressComponents,
  OnboardingPropertyDetails,
  PropertyClass,
  PropertyType,
} from '../../models/property/property';
import { PropertyOnboardingParams } from '../../navigation/NavigationConstants';
import { navigate } from '../../navigation/NavigationUtils';

export const createAddressString = (data: AddressComponents) => {
  if (data) {
    const unitNumber = data.unitNumber ? `${data.unitNumber}/` : '';
    return `${unitNumber}${data.streetNumber} ${data.streetName} ${data.streetType}, ${data.suburb} ${data.state} ${data.postcode}, ${data.country}`;
  }
  return '';
};

export const getPropertyLogo = (propertyType: PropertyType | undefined) => {
  switch (propertyType) {
    case PropertyType.Apartment:
      return apartment;
    case PropertyType.Home:
      return homeSolid;
    case PropertyType.Townhouse:
      return homeOutline;
    case PropertyType.Villa:
      return villa;
    default:
      return apartment;
  }
};

export const getClassLogo = (propertyClass: PropertyClass | undefined) => {
  switch (propertyClass) {
    case PropertyClass.Accommodation:
      return villa;
    case PropertyClass.Landlord:
      return landlord;
    case PropertyClass.Owner:
      return owner;
    case PropertyClass.Tenant:
      return key;
    default:
      return villa;
  }
};

export const getBtnStatus = (
  activeStep: PropertyOnboardingStep,
  propertyOnboardingState: OnboardingPropertyDetails,
): boolean => {
  const { selectedClass, selectedType, address } = propertyOnboardingState;
  switch (activeStep) {
    case PropertyOnboardingStep.SelectPropertyType:
      return !selectedType;
    case PropertyOnboardingStep.SelectRole:
      return !selectedClass;
    case PropertyOnboardingStep.AddProperty:
      return !address || !address.country;

    case PropertyOnboardingStep.ManualEntry:
      return !address || !address.country;
    default:
      return false;
  }
};

export const checkAddress = (
  address: AddressComponents | undefined,
  address2: AddressComponents,
) => {
  if (
    !!address2.state &&
    !!address2.streetName &&
    !!address2.streetType &&
    !!address2.suburb &&
    !!address2.streetNumber &&
    !!address2.postcode
  ) {
    navigate('propertyOnboarding', { page: PropertyOnboardingStep.SelectPropertyType });
  } else if (address !== undefined) {
    const { state, streetName, streetType, suburb, streetNumber, postcode } = address;
    if (!state || !streetName || !streetType || !suburb || !streetNumber || !postcode) {
      navigate('propertyOnboarding', { page: PropertyOnboardingStep.ManualEntry });
    } else {
      navigate('propertyOnboarding', { page: PropertyOnboardingStep.SelectPropertyType });
    }
  }
};

export const getOnboardingStep = (params: PropertyOnboardingParams) => {
  if (params.page === undefined) {
    return PropertyOnboardingStep.AddProperty;
  }
  return params.page as PropertyOnboardingStep;
};
