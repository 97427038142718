import { ServiceTypes } from '../../../../models/services/services';

export const LABELS = {
  BROWSE: 'Browse by provider',
};

export enum PopupType {
  PLAN,
  SUPPLIER,
  NONE,
}

export const ANIMATION_DELAY = 500;

export const essentialServices = [
  ServiceTypes.Electricity,
  ServiceTypes.Gas,
  ServiceTypes.Broadband,
];
