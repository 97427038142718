import { BundleResponse } from '../../models/bundles/Bundles';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import {
  CancelRequest,
  RequestQuote,
  ReviewRequest,
  ReviewResponse,
  TenantRequest,
} from '../../models/tenantRequest/Request';
import { StoreAction } from '../StoreHelper';

export enum RequestActionTypes {
  GET_REQUESTS = 'GET_REQUESTS',
  GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS',
  GET_REQUESTS_ERROR = 'GET_REQUESTS_ERROR',
  GET_REVIEW_REQUESTS_SUCCESS = 'GET_REVIEW_REQUESTS_SUCCESS',
  GET_SCHEDULED_REQUESTS_SUCCESS = 'GET_SCHEDULED_REQUESTS_SUCCESS',
  GET_COMPLETED_REQUESTS_SUCCESS = 'GET_COMPLETED_REQUESTS_SUCCESS',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  SUBMIT_REQUEST_QUOTE = 'SUBMIT_REQUEST_QUOTE',

  GET_PACKAGE = 'GET_PACKAGE',
  GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST',
  GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS',
  GET_PACKAGE_ERROR = 'GET_PACKAGE_ERROR',

  SUBMIT_REVIEW_REQUEST = 'SUBMIT_REVIEW_REQUEST',
  GET_MR_REVIEW_REQUEST = 'GET_MR_REVIEW_REQUEST',
  GET_MR_REVIEW_SUCCESS = 'GET_MR_REVIEW_SUCCESS',
  GET_MR_REVIEW_ERROR = 'GET_MR_REVIEW_ERROR',
}

export type RequestActionPayload =
  | TenantRequest
  | MaintenanceResponse[]
  | CancelRequest
  | RequestQuote
  | null
  | BundleResponse
  | ReviewRequest
  | ReviewResponse
  | string
  | Error;

export type RequestAction = StoreAction<RequestActionTypes, RequestActionPayload>;

export class RequestActions {
  public static getRequests(data: TenantRequest): RequestAction {
    return {
      type: RequestActionTypes.GET_REQUESTS,
      data,
    };
  }

  public static getRequestsSuccess(data: MaintenanceResponse[]): RequestAction {
    return {
      type: RequestActionTypes.GET_REQUESTS_SUCCESS,
      data,
    };
  }

  public static getReviewRequestsSuccess(data: MaintenanceResponse[]): RequestAction {
    return {
      type: RequestActionTypes.GET_REVIEW_REQUESTS_SUCCESS,
      data,
    };
  }
  public static getScheduledRequestsSuccess(data: MaintenanceResponse[]): RequestAction {
    return {
      type: RequestActionTypes.GET_SCHEDULED_REQUESTS_SUCCESS,
      data,
    };
  }
  public static getCompletedRequestsSuccess(data: MaintenanceResponse[]): RequestAction {
    return {
      type: RequestActionTypes.GET_COMPLETED_REQUESTS_SUCCESS,
      data,
    };
  }

  public static cancelRequest(data: CancelRequest): RequestAction {
    return {
      type: RequestActionTypes.CANCEL_REQUEST,
      data,
    };
  }

  public static submitRequestQuote(data: RequestQuote): RequestAction {
    return {
      type: RequestActionTypes.SUBMIT_REQUEST_QUOTE,
      data,
    };
  }

  public static getRequestsError(data: Error): RequestAction {
    return {
      type: RequestActionTypes.GET_REQUESTS_ERROR,
      data,
    };
  }

  public static getPackages(): RequestAction {
    return {
      type: RequestActionTypes.GET_PACKAGE_REQUEST,
      data: null,
    };
  }

  public static getPackagesSuccess(data: BundleResponse): RequestAction {
    return {
      type: RequestActionTypes.GET_PACKAGE_SUCCESS,
      data,
    };
  }

  public static getPackagesError(): RequestAction {
    return {
      type: RequestActionTypes.GET_PACKAGE_ERROR,
      data: null,
    };
  }

  public static submitReviewRequest(data: ReviewRequest): RequestAction {
    return {
      type: RequestActionTypes.SUBMIT_REVIEW_REQUEST,
      data,
    };
  }

  public static getReviewRequest(data: string): RequestAction {
    return {
      type: RequestActionTypes.GET_MR_REVIEW_REQUEST,
      data,
    };
  }

  public static getReviewSuccess(data: ReviewResponse): RequestAction {
    return {
      type: RequestActionTypes.GET_MR_REVIEW_SUCCESS,
      data,
    };
  }

  public static getReviewError(data: Error): RequestAction {
    return {
      type: RequestActionTypes.GET_PACKAGE_ERROR,
      data,
    };
  }
}
