/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, ReactNode } from 'react';
import { InitialConcessionCardValues } from '../../../../../../models/checkout/Checkout';
import { getDefaultValue } from '../../SelectOptionsUtils';
import { Card, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
  SupplierOptions,
  SupplierOption,
  FeatureDetails,
} from '../../../../../../models/billing/Billing';
import { FileData } from '../../../../../../models/masterProfile/FileUploadState';
import { useStyles } from './SupplierOptionsStyles';
import ConcessionCardForm from '../../../../../../components/concessionCardForm/ConcessionCardForm';
import FileUploads from '../../../../../../components/fileUploads/FileUploads';
import Input from '../../../../../../components/input/Input';
import InputSelect from '../../../../../../components/inputSelect/InputSelect';
import InputDate from '../../../../../../components/inputDate/InputDate';
import linkIcon from '../../../../../../assets/external-link.png';
import { get } from 'lodash';
import InputMultiSelect from '../../../../../../components/inputMultiSelect/InputMultiSelect';
import InputMultiCheckbox from '../../../../../../components/inputMultiCheckbox/InputMultiCheckbox';
import moment from 'moment';
import { excludeDates } from './SupplierOptionsUtils';

interface SupplierOptionsProps {
  setConcessionCard: (concessionCard: InitialConcessionCardValues | null) => void;
  option: SupplierOptions;
  handleMultipleOptionsChange: (
    supplierOption: SupplierOptions,
    selection: SupplierOption,
    value?: string | Date,
  ) => void;
  handleSingleOptionChange: (supplierOption: SupplierOptions, value?: string | Date) => void;
  supplierSelectionAns: Map<string, FeatureDetails> | {};
  publicHolidays?: string[];
}

const SupplierOptionsComponent: FC<SupplierOptionsProps> = ({
  setConcessionCard,
  option,
  handleMultipleOptionsChange,
  handleSingleOptionChange,
  supplierSelectionAns,
  publicHolidays,
}) => {
  const classes = useStyles();
  const renderComponentId = (): ReactNode => {
    if (option.ComponentId === 'ConcessionCardDetails') {
      return <ConcessionCardForm setConcessionCard={setConcessionCard} />;
    } else if (option.ComponentId === 'MediaFileInput') {
      return (
        <>
          <div className={classes.description}>{option.Description}</div>
          <FileUploads
            value={
              getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : []
            }
            id="123" //This needs to be unique but I cannot find anything in redux
            onSuccess={(files: FileData[]) => {
              const oldData = getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : [];
              const newData = oldData ? oldData.concat(files) : files;

              handleSingleOptionChange(option, JSON.stringify(newData)); // Are we joining all the file links into one string for one answer?
            }}
            multiple
            onDelete={(file: FileData) => {
              const oldData = getDefaultValue(supplierSelectionAns, option.Group)
                ? (JSON.parse(getDefaultValue(supplierSelectionAns, option.Group)) as FileData[])
                : [];
              const newData = oldData.filter((oldFile: FileData) => oldFile !== file);
              handleSingleOptionChange(option, JSON.stringify(newData)); // Are we joining all the file links into one string for one answer?
            }}
            acceptedTypes=".jpg, .jpeg, .pdf, .png"
          />
          <div className={classes.infoText}>{option.ComponentText}</div>
        </>
      );
    } else if (option.ComponentId === 'RadioCardSingleSelect') {
      return (
        <>
          <div className={classes.description}>{option.Description}</div>
          <div className={classes.optionsContainer}>
            {option.Options &&
              option.Options.length > 0 &&
              option.Options.map((selection, i) => {
                return (
                  <div
                    key={`card_${i}`}
                    className={`${classes.cardContainerStyle} ${
                      selection.selected ? classes.selectedCardContainerStyle : undefined
                    }`}
                    onClick={() =>
                      handleMultipleOptionsChange(option, selection, get(selection, 'Label', ''))
                    }
                  >
                    {selection.UiElements.ImageCentered ? (
                      <div className={classes.imageContainerCentered}>
                        <img
                          className={classes.imageCentered}
                          src={selection.UiElements.ImageCentered}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className={classes.imageContainer}>
                        <img className={classes.image} src={selection.UiElements.Image} alt="" />
                      </div>
                    )}
                    <div className={classes.content}>
                      <div className={classes.title}>{selection.UiElements.Title}</div>
                      {selection.UiElements.Subtitle && (
                        <div className={classes.subtitle}>{selection.UiElements.Subtitle}</div>
                      )}
                      <div className={classes.line} />
                      {selection.UiElements.Price && (
                        <div className={classes.priceRow}>
                          <div className={classes.price}>{selection.UiElements.Price}</div>
                          <div className={classes.uom}>{selection.UiElements.Uom}</div>
                        </div>
                      )}
                      <div className={classes.body}>{selection.UiElements.Body}</div>
                    </div>
                    {selection.UiElements.Link && (
                      <div
                        className={classes.viewMore}
                        onClick={() => {
                          window.open(selection.UiElements.Link);
                        }}
                      >
                        <div className={classes.viewMoreText}>Read more</div>
                        <img src={linkIcon} className={classes.link} alt="" />
                      </div>
                    )}
                    <div
                      className={selection.selected ? classes.primaryButton : classes.outlineButton}
                    >
                      {selection.selected ? 'Selected' : 'Select'}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      );
    } else if (option.Options.length > 1) {
      const selectedValue = getDefaultValue(supplierSelectionAns, option.Group);
      const footerText = (labelId: string | null, optionObj: SupplierOption[]) => {
        if (optionObj && optionObj.length) {
          const filteredObj = optionObj.filter((a: SupplierOption) => a.ProductId === labelId)[0];
          return filteredObj && filteredObj.HelpText
            ? filteredObj.HelpText
            : filteredObj && filteredObj.ComponentText
            ? filteredObj.ComponentText
            : '';
        } else {
          return '';
        }
      };

      return (
        <>
          <InputMultiSelect
            title={option.Description}
            selected={(() => {
              const index = option.Options.findIndex(
                (o) => o.Label === selectedValue || o.selected,
              );

              return index !== -1 ? index : null;
            })()}
            mandatory
            values={option.Options.map((o, i) => ({
              display: o.Label,
              value: i,
            }))}
            setValue={(value: number) => {
              handleMultipleOptionsChange(
                option,
                option.Options[value] as SupplierOption,
                option.Options[value].Label,
              );
            }}
          />
          <div className={classes.infoText}>{footerText(selectedValue, option.Options)}</div>
        </>
      );
    } else {
      const subOptions =
        option.Options && option.Options.length > 0 ? option.Options[0].Option : option;
      switch (subOptions.ComponentId) {
        case 'ConnectionDateInput': {
          const dates = excludeDates(subOptions, publicHolidays);
          return (
            <>
              <InputDate
                placeholder="Select date"
                title={subOptions.Description || 'Please select your preferred date'}
                value={
                  getDefaultValue(supplierSelectionAns, subOptions.Group) === 'Date'
                    ? ''
                    : getDefaultValue(supplierSelectionAns, subOptions.Group)
                }
                setValue={(date) => {
                  const newDate = moment(date).format('YYYY-MM-DD');
                  handleSingleOptionChange(subOptions, newDate);
                }}
                mandatory
                blockedDates={dates.excludedDates}
                disableWeekends={dates.disableWeekend}
                big
              />
            </>
          );
        }
        case 'TextInput':
          return (
            <>
              <div className={classes.description}>{subOptions.Description}</div>
              <Input
                placeholder="Start typing..."
                value={getDefaultValue(supplierSelectionAns, subOptions.Group)}
                setValue={(value) => handleSingleOptionChange(subOptions, value)}
                mandatory
                big
              />
            </>
          );
        case 'DropdownInput':
          return (
            <>
              <InputSelect
                placeholder="Please select"
                title={subOptions.Description}
                value={getDefaultValue(supplierSelectionAns, subOptions.Group)}
                values={Object.values(subOptions.ComponentOptions).map((v) => ({
                  display: v.Name,
                  value: v.Value,
                }))}
                setValue={(value: string) => handleSingleOptionChange(subOptions, value)}
                mandatory
                big
              />
            </>
          );
        case 'MultiCheckboxInput':
          return (
            <>
              <InputMultiCheckbox
                title={subOptions.Description}
                setValue={(value: string[]) =>
                  handleSingleOptionChange(subOptions, value.join(', '))
                }
                value={getDefaultValue(supplierSelectionAns, option.Group).split(', ') || []}
                values={subOptions.ComponentOptions.map((c) => ({
                  display: c.Name,
                  value: c.Value,
                }))}
              />
            </>
          );
        default:
          return null;
      }
    }
  };

  return <div className={classes.questionContainer}>{renderComponentId()}</div>;
};

export default SupplierOptionsComponent;
