import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './LoginFormStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS, validationSchema, WHY_SORTED } from './LoginFormConstants';
import Box from '../../../../components/box/Box';
import Button from '../../../../components/button/Button';
import Text from '../../../../components/text/Text';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import visibilityOff from '../../../../assets/outline-visibility-off-24-px.svg';
import visibilityOn from '../../../../assets/outline-remove-red-eye-24-px.svg';
import { useFormik } from 'formik';
import Input from '../../../../components/input/Input';
import { AuthActionTypes, AuthActions } from '../../../../store/actions/AuthActions';
import { LoginRequest, RegisterUserPayload } from '../../../../models/auth/auth';
import { View } from '../../Login';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { ProfileActionTypes } from '../../../../store/actions/ProfileActions';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { IconButton } from '@mui/material';

interface LoginProps extends RouteComponentProps {
  loading: boolean;
  userDetails: RegisterUserPayload | null;
  postLoginRequest: (data: LoginRequest) => void;
  setView: (view: View) => void;
  resetLoginErrors: () => void;
}

const Login: FC<LoginProps> = ({
  loading,
  userDetails,
  postLoginRequest,
  setView,
  resetLoginErrors,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    resetLoginErrors();
  }, []);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: userDetails && userDetails.email ? userDetails.email : '',
      password: '',
    },
    onSubmit: (values) => {
      postLoginRequest({ username: values.email, ...values });
    },
    validationSchema,
  });

  return (
    <div className={classes.centerPageContent}>
      <div className={classes.primaryContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Box lightBorder parentStyles={classes.boxContainer}>
            <Text parentStyles={classes.title} textVariant="title" size="xl">
              {LABELS.LOGIN_TITLE}
            </Text>
            <Text textVariant="info">{LABELS.LOGIN_SUBTITLE}</Text>
            <div className={classes.inputRow}>
              <Input
                placeholder={LABELS.EMAIL}
                key={LABELS.EMAIL}
                value={formik.values.email}
                setValue={formik.handleChange('email')}
                onBlur={() => formik.setFieldTouched('email')}
                touched={formik.touched.email}
                error={formik.errors.email}
                title={LABELS.EMAIL}
                mandatory
                big
              />
              <Input
                placeholder={LABELS.PASSWORD}
                key={LABELS.PASSWORD}
                value={formik.values.password}
                setValue={formik.handleChange('password')}
                onBlur={() => formik.setFieldTouched('password')}
                touched={formik.touched.password}
                error={formik.errors.password}
                title={LABELS.PASSWORD}
                parentStyles={classes.secondInput}
                password={!showPassword}
                mandatory
                big
                endElement={
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <img
                      src={showPassword ? visibilityOff : visibilityOn}
                      alt=""
                      style={{ height: 20, width: 20 }}
                    />
                  </IconButton>
                }
              />
            </div>
            <div className={classes.forgotRow}>
              <Text textVariant="info">{LABELS.CANT}</Text>

              <Text
                parentStyles={classes.signupButton}
                onPress={() => setView(View.ForgotPassword)}
                textVariant="link"
              >
                {LABELS.FORGOT}
              </Text>
            </div>
            <div className={classes.loginRow}>
              <Button
                parentStyles={classes.loginButton}
                loading={loading}
                disabled={loading}
                type="submit"
                onPress={() => null}
              >
                {LABELS.LOGIN}
              </Button>
            </div>

            <div className={classes.signupRow}>
              <Text textVariant="info">{LABELS.DONT}</Text>
              <Text
                textVariant="link"
                parentStyles={classes.signupButton}
                onPress={() => setView(View.Signup)}
              >
                {LABELS.SIGNUP}
              </Text>
            </div>
          </Box>
        </form>
      </div>
    </div>
  );
};

const loading = loadingSelector([AuthActionTypes.LOGIN, ProfileActionTypes.GET_USER_ROLES]);

// const error = errorMessageSelector([AuthActionTypes.LOGIN]);

const mapStateToProps = (state: ApplicationState) => ({
  // error: error(state),
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetLoginErrors: () => dispatch(AuthActions.resetLoginErrors()),
  postLoginRequest: (data: LoginRequest) => dispatch(AuthActions.postLoginRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
