import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './LeaseServiceabilityStyles';
import {
  LABELS,
  createPostServiceabilityRequestObject,
  message,
} from './LeaseServiceabilityConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import {
  PaymentProvider,
  PostRentOnboardingRequest,
  PostServiceabilityRequest,
  Serviceability,
  Services,
} from '../../models/rentOnboarding/rentOnboarding';
import { RentOnboardingActions } from '../../store/actions/RentOnboarding';
import { PostPaymentTokenResponse } from '../../models/payment/Payment';
import { ThemeKey, theme } from '../../theme/Theme';
import { useGetLeaseData } from '../../helpers/RentServiceHelper';
import { findIndex } from 'lodash';
import { getDarkServiceTypeIcon } from '../home/components/dashboard/DashboardConstants';
import { ServiceTypes } from '../../models/services/services';
import Button from '../../components/button/Button';
import TileContainer from '../../components/tileContainer/TileContainer';
import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import doneIcon from '../../assets/tick-active.png';
import { ToastMessage } from '../../helpers/ToastMessage';

interface LeaseServiceabilityProps {
  paymentResponse: PostPaymentTokenResponse;
  postRentServices: (data: PostRentOnboardingRequest, onSuccess: () => void) => void;
  postServiceability: (
    data: PostServiceabilityRequest[],
    leaseId: number,
    onSuccess: () => void,
  ) => void;
}

const LeaseServiceability: FC<LeaseServiceabilityProps> = ({
  paymentResponse,
  postRentServices,
  postServiceability,
}) => {
  const classes = useStyles();
  const [selectedOptInService, setSelectedOptInService] = useState<boolean[]>([]);
  const [leaseServices, setLeaseServices] = useState<Services[]>([]);
  const lease = useGetLeaseData();
  const { serviceability, leaseId, property, paymentDetails, leaseRefId } = lease!;

  useEffect(() => {
    const selectedOptInService: boolean[] = [];
    serviceability.map((service: Services) => {
      if (service.serviceability === Serviceability.OPT_YES) {
        selectedOptInService.push(true);
      } else if (service.serviceability === Serviceability.OPT_NO) {
        selectedOptInService.push(false);
      }
    });
    setSelectedOptInService(selectedOptInService);
    setLeaseServices(serviceability);
  }, []);

  const handleOnSelect = (service: any, index: number) => {
    console.log('tapped', index);
    const result = selectedOptInService.map((service, idx) => {
      if (index === idx) {
        return !service;
      }
      return service;
    });
    setSelectedOptInService(result);
  };

  const handleServiceabilitySuccess = () => {
    ToastMessage.showMessage('Rent setup success', '', 'success', 3000);
    navigate('home', { refresh: true });
  };

  const handlePostRentServiceSuccess = () => {
    const requestData = createPostServiceabilityRequestObject(leaseServices, selectedOptInService);
    postServiceability(requestData, leaseId, handleServiceabilitySuccess);
  };

  const handleServiceabilitySubmit = () => {
    const { paymentProvider } = paymentDetails!;
    if (paymentProvider && paymentProvider === PaymentProvider.SORTED) {
      const requestData = {
        leaseRefId,
        paymentRefId: paymentResponse.refId,
      };
      postRentServices(requestData, handlePostRentServiceSuccess);
    } else {
      const requestData = {
        leaseRefId,
        paymentRefId: null,
      };
      postRentServices(requestData, handlePostRentServiceSuccess);
    }
  };

  const checkMandatoryService = () => {
    return (
      findIndex(serviceability, function (service: Services) {
        return service.serviceability === Serviceability.OPT_MANDATORY;
      }) >= 0
    );
  };

  const checkOptInService = () => {
    return (
      findIndex(serviceability, function (service: Services) {
        return (
          service.serviceability === Serviceability.OPT_NO ||
          service.serviceability === Serviceability.OPT_YES
        );
      }) >= 0
    );
  };

  const getServiceIcon = (serviceType: string) => {
    return (
      <img
        src={getDarkServiceTypeIcon(serviceType as ServiceTypes)}
        className={classes.imageStyle}
      />
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>

            {checkMandatoryService() && (
              <TileContainer title={message.tenancyServiceTitle} noScroll>
                <div>
                  <div className={classes.card}>
                    {serviceability
                      .filter(
                        (service: Services) =>
                          service.serviceability === Serviceability.OPT_MANDATORY,
                      )
                      .map((service: Services) => {
                        return (
                          <List>
                            <ListItem
                              classes={{
                                root: classes.listItemStyle,
                                dense: classes.listItemStyle,
                              }}
                            >
                              <ListItemAvatar>
                                {service.serviceProvider.icon ? (
                                  <img
                                    src={service.serviceProvider.icon}
                                    className={classes.imageStyle}
                                  />
                                ) : (
                                  getServiceIcon(service.serviceType)
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={service.serviceType}
                                secondary={service.serviceProvider.name}
                              />
                              <ListItemSecondaryAction>
                                <img src={doneIcon} className={classes.doneIconStyle} />
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        );
                      })}
                  </div>
                  <Typography variant="body2" className={classes.subTextStyle}>
                    {message.tenancyServiceSubTitle}
                  </Typography>
                </div>
              </TileContainer>
            )}
            {checkOptInService() && (
              <TileContainer title={message.optInServiceTitle} noScroll>
                <div className={classes.optInSectionStyle}>
                  <div className={classes.card}>
                    {serviceability
                      .filter(
                        (service: Services) =>
                          service.serviceability === Serviceability.OPT_YES ||
                          service.serviceability === Serviceability.OPT_NO,
                      )
                      .map((service: Services, index: number) => {
                        return (
                          <List>
                            <ListItem
                              classes={{
                                root: classes.listItemStyle,
                                dense: classes.listItemStyle,
                              }}
                            >
                              <ListItemAvatar>
                                {service.serviceProvider.icon ? (
                                  <img
                                    src={service.serviceProvider.icon}
                                    className={classes.imageStyle}
                                  />
                                ) : (
                                  getServiceIcon(service.serviceType)
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={service.serviceType}
                                secondary={service.serviceProvider.name}
                              />
                              <ListItemSecondaryAction>
                                {service.serviceability === Serviceability.OPT_YES ? (
                                  <img src={doneIcon} className={classes.doneIconStyle} />
                                ) : (
                                  <Checkbox
                                    sx={{
                                      color: theme.colors.borderColor,
                                      '&.Mui-checked': {
                                        color: theme.colors.buttonPrimary,
                                      },
                                    }}
                                    checked={selectedOptInService[index]}
                                    onChange={() => handleOnSelect(service, index)}
                                  />
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        );
                      })}
                  </div>
                  <Typography variant="body2" className={classes.subTextStyle}>
                    {message.optInServiceSubTitle}
                  </Typography>
                </div>
              </TileContainer>
            )}

            <div className={classes.buttonContainer}>
              <Button onPress={() => handleServiceabilitySubmit()} parentStyles={classes.button}>
                {LABELS.CONFIRM}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentResponse: state.payment.selectedViewPaymentMethod,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentService: (data: PostRentOnboardingRequest, onSuccess: () => void) => {
    dispatch(
      RentOnboardingActions.postRentOnboardingStart({
        data,
        onSuccess,
      }),
    );
  },
  postServiceability: (data: PostServiceabilityRequest[], leaseId: number, onSuccess: () => void) =>
    dispatch(RentOnboardingActions.postServiceabilityStart({ data, leaseId, onSuccess })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaseServiceability);
