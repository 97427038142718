import { RentalFrequency } from '../lease/lease';

export interface Services {
  serviceAccountId: number;
  serviceType: ServiceTypes;
  status: AccountStatus;
  provisioningStatus: string;
  paymentEnabled: boolean;
  monthlyCost: number;
  ServiceabilityRequired: boolean;
  provider: string;
  paymentFrequency: RentalFrequency;
  category: string | null;
  providerId: string;
}

export enum ServiceTypes {
  Rent = 'Rent',
  Electricity = 'Electricity',
  Gas = 'Gas',
  Broadband = 'Broadband',
  Water = 'Water',
  Callback = 'UtilityCallback',
  OtherService = 'OtherService',
  Insurance = 'Insurance',
  Cleaning = 'Cleaning',
  Solar = 'Solar',
  Removals = 'Removals',
  Trades = 'Trades',
  Gardening = 'Gardening',
  Security = 'Security',
  WhiteGoods = 'White goods',
  TechSupport = 'Tech Support',
  Finance = 'Finance',
  Handyman = 'Handyman',
  Locksmith = 'Locksmith',
  Electrical = 'Electrical',
  HomeSecurity = 'Home Security',
  Plumbing = 'Plumbing',
  Rubbish = 'Rubbish',
}

export const STATUS_TITLE = {
  Gas: 'Pending',
  Water: 'Connected',
};

export const SERVICE_TYPE_TITLE = {
  TechSupport: 'Tech Support',
  VerticalGardens: 'Vertical Gardens',
  PestControl: 'Pest Control',
  HomeSecurity: 'Home Security',
  BathroomKitchen: 'Bathroom/Kitchen',
  PremiumService: 'Premium Service',
};

export enum AccountStatus {
  NEW = 'New',
  OPEN = 'Open',
  PENDING = 'Pending',
  INPROGRESS = 'InProgress',
  IN_PROGRESS = 'in_progress',
  LANDLORD = 'landlord_attending',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  CLOSE = 'closed',
  COMPLETE = 'complete',
  RECIEVED = 'received',
  PRESIGNED = 'Presigned',
}

export interface AvailableServicesResponse {
  ServiceType: ServiceTypes;
  ServiceName: string;
  ServiceNameLabel: string | null;
  StartingPrice: number | null;
  ServiceabilityRequired: boolean;
  Suppliers: Suppliers[];
  Logo: Logo;
}

export interface Suppliers {
  Description: string;
  DescriptionBanner: string;
  DescriptionFooter: string | null;
  ServiceCategory: string;
  Logo: Logo;
  PageTitle: string | null;
  ServiceabilityRequired: boolean;
  SignupFlow: string;
  StartingPrice: string | null;
  SupplierId: string;
  SupplierLabel: string;
  SupplierName: string;
  category: string | null;
}

export interface Logo {
  Active: string;
  Hero: string;
  Inactive: string;
  Dark: string;
  Light: string;
}

export interface ResetServicePlanRequest {
  serviceAccountId: number;
}

export interface ServiceSummaryResponse {
  serviceDetails: {
    personalDetailsStep: {
      step: string;
      completed: boolean;
      mandatory: boolean;
      updateable: boolean;
    };
    personalDetails: {
      id: number;
      name: string;
      surname: string;
      email: string;
      phone: string;
    };
    referenceNumberStep: {
      step: string;
      completed: boolean;
      mandatory: boolean;
      updateable: boolean;
    };
    referenceNumber: string;
    providerId: string;
  };
  serviceAccount: {
    id: 3;
    serviceType: string;
    provider: string;
    monthlyCost: number;
    status: string;
    provisioningStatus: string;
    serviceAddress: string;
    serviceNumber: string;
  };
  serviceProvider: {
    id: number;
    name: string;
    iconUrl: string;
    description: string;
    backgroundUrl: string;
    phone: string;
    email: string;
    website: string;
  };
}

export interface ServiceSummaryRequest {
  readonly serviceAccountId: string;
}

export interface ActiveServicesPaymentSummaryRequest {
  serviceAccountIds: string[];
}

export interface ActiveServicesPaymentSummaryResponse {
  userId: number;
  userServiceAccountId: string;
  userPaymentScheduleId: string;
  serviceType: string;
  serviceStatus: string;
  payDateStart: Date | string | null;
  payDateEnd: Date | string | null;
  paymentFrequency: RentalFrequency | null;
  paymentAmountMonthly: number;
}
