import { takeEvery, put } from 'redux-saga/effects';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import {
  GetRentOnboardingPayload,
  GetRentOnboardingResponse,
  PostRentOnboardingPayload,
  PostRentOnboardingResponse,
  PostServiceabilityPayload,
  Services,
} from '../../models/rentOnboarding/rentOnboarding';
import RentOnboardingService from '../../services/rentOnboarding/RentOnboardingService';
import {
  RentOnboardingAction,
  RentOnboardingActions,
  RentOnboardingActionTypes,
} from '../actions/RentOnboarding';

export function* getRentOnboardingSaga(action: RentOnboardingAction) {
  try {
    const { onSuccess } = action.data as GetRentOnboardingPayload;
    const response: GetRentOnboardingResponse[] = yield RentOnboardingService.getRentOnboarding();
    if (onSuccess) {
      onSuccess(response);
    }
    yield put(RentOnboardingActions.getRentOnboardingSuccess(response));
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentOnboardingActions.getRentOnboardingError(error as Error));
  }
}

export function* postRentOnboardingSaga(action: RentOnboardingAction) {
  try {
    const { onSuccess } = action.data as PostRentOnboardingPayload;
    const response: PostRentOnboardingResponse = yield RentOnboardingService.postRentOnboarding(
      (action.data as PostRentOnboardingPayload).data,
    );
    yield put(RentOnboardingActions.postRentOnboardingSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentOnboardingActions.postRentOnboardingError(error as Error));
  }
}

export function* postServiceabilitySaga(action: RentOnboardingAction) {
  try {
    const { onSuccess, data, leaseId } = action.data as PostServiceabilityPayload;
    const response: Services[] = yield RentOnboardingService.postServiceability(data, leaseId);
    yield put(RentOnboardingActions.postServiceabilitySuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(RentOnboardingActions.postServiceabilityError(error as Error));
  }
}

function* rentOnboardingWatcher() {
  yield takeEvery(
    RentOnboardingActionTypes.GET_RENT_ONBOARDINNG_REQUEST,
    (action: RentOnboardingAction) => getRentOnboardingSaga(action),
  );
  yield takeEvery(
    RentOnboardingActionTypes.POST_RENT_ONBOARDINNG_REQUEST,
    (action: RentOnboardingAction) => postRentOnboardingSaga(action),
  );
  yield takeEvery(
    RentOnboardingActionTypes.POST_SERVICEABILITY_REQUEST,
    (action: RentOnboardingAction) => postServiceabilitySaga(action),
  );
}
export default rentOnboardingWatcher;
