/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { useStyles } from './ServiceCardStyles';
import pendingIcon from '../../assets/pending.png';
import { RentalFrequency } from '../../models/lease/lease';
import { AccountStatus } from '../../constants/AccountStatus';
import { calculateCost } from '../../helpers/CostFrequencyHelper';
import { Route } from '../../navigation/NavigationUtils';

interface ServiceCardProps {
  serviceName: string;
  serviceProviderDetail: string;
  cost: number | null;
  billingPeriod: RentalFrequency;
  icon: string;
  iconFilled: string;
  status: AccountStatus;
  redirectTo: Route;
  redirectProps?: any;
  disabled?: boolean;
  isOtherService?: boolean;
}

const ServiceCard: FC<ServiceCardProps> = ({
  serviceName,
  serviceProviderDetail,
  cost,
  billingPeriod,
  icon,
  iconFilled,
  redirectTo,
  redirectProps,
  disabled,
  status,
  isOtherService,
}) => {
  const classes = useStyles();
  const calculatedCost = cost ? calculateCost(billingPeriod, cost, true, 0) : cost;
  const statusPending = status === AccountStatus.PENDING || status === AccountStatus.PRESIGNED;
  const statusComplete = status === AccountStatus.OPEN || status === AccountStatus.COMPLETED;

  const shouldShowCost = (cost: number | null, frequency: RentalFrequency | null) => {
    return cost !== null && frequency !== null;
  };

  return (
    <div
      onClick={() => {
        if (!disabled) {
          // ActionHelper.redirectTo(redirectTo, !!redirectProps, redirectProps);
        }
      }}
      className={classes.serviceCardItem}
    >
      <div>
        <div className={classes.cardLeftView}>
          <img
            className={
              statusComplete || status === AccountStatus.PENDING ? classes.activeIcon : classes.icon
            }
            src={statusComplete || status === AccountStatus.PENDING ? iconFilled : icon}
          />
          <div className={classes.titleContainer}>
            <div className={classes.title}>{serviceName}</div>
            <div className={classes.description}>{serviceProviderDetail}</div>
          </div>
        </div>
      </div>
      <div>
        {shouldShowCost(cost, billingPeriod) && !statusPending ? (
          <div>
            <div className={classes.serviceCost}>{calculatedCost}</div>
            <div className={classes.serviceBillingCycle}>{billingPeriod.toLocaleLowerCase()}</div>
          </div>
        ) : (
          <>
            <div className={classes.statusContainer}>
              <div className={classes.status}>{status}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
