/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DashboardState } from '../../store/state/DashboardState';
import addIcon from '../../assets/plus.png';
import { useStyles } from './PropertyCardStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { Property } from '../../models/property/property';
import { ApplicationState } from '../../store/RootReducer';
import homePlaceholder from '../../assets/homePlaceholder.png';
import { navigate } from '../../navigation/NavigationUtils';

interface PropertyCardProps {
  selected: boolean;
  property: Property | undefined | null;
  index: number;
  dashboardState: DashboardState;
  addProperty?: boolean;
  noProperty?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  greyBorder?: boolean;
  snapTo: () => void;
}

const PropertyCard: FC<PropertyCardProps> = ({
  selected,
  property,
  index,
  dashboardState: { onboardedProperties },
  addProperty,
  noProperty,
  fullWidth,
  greyBorder,
  loading,
  snapTo,
}) => {
  const classes = useStyles();
  let address1 = ``;
  let address2 = ``;

  if (property) {
    const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property;
    address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
    address2 = `${suburb}, ${state} ${postcode}`;
  }

  const handlePress = () => {
    if (addProperty) {
      navigate('propertyOnboarding');
    } else if (selected) {
      navigate('propertyInfo');
    } else {
      snapTo();
    }
  };

  return (
    <div
      className={classNameGenerator([
        classes.propertyContainer,
        fullWidth && classes.fullWidth,
        selected && classes.selectedBorder,
        greyBorder && classes.greyBorder,
        loading && classes.loading,
      ])}
      onClick={() => handlePress()}
    >
      <>
        {!loading && (
          <>
            {addProperty ? (
              <div className={classes.addPropertyContainer}>
                <div className={classes.titleContainer}>
                  <div className={classes.address}>
                    {noProperty ? 'No property' : 'Add property'}
                  </div>
                  <div className={classes.address2}>Tap here to add your place</div>
                </div>
                <img src={addIcon} className={classes.addPropertyIcon} />
              </div>
            ) : (
              <>
                <div className={classes.topRow}>
                  <div className={classes.photoContainer}>
                    <img
                      className={classes.photo}
                      src={
                        // property!.photos && !!property!.photos.length
                        //   ? property!.photos[0].link
                        //   :
                        homePlaceholder
                      }
                    />
                  </div>
                  <div className={classes.titleContainer}>
                    <div className={classes.address}>{address1}</div>
                    <div className={classes.address2}>{address2}</div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
