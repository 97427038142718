import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './PropertiesStyles';
import { LABELS, NO_PROPERTY } from './PropertiesConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducer';
import { AuthActions } from '../../store/actions/AuthActions';
import { DashboardState } from '../../store/state/DashboardState';
import LoginPrompt from '../../components/loginPrompt/LoginPrompt';
import ActionCard from '../../components/actionCard/ActionCard';
import PropertyCard from '../../components/propertyCard/PropertyCard';
import { View } from '../login/Login';
import TileContainer from '../../components/tileContainer/TileContainer';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface PropertiesProps {
  isGuest: boolean;
  dashboardState: DashboardState;
  logout: () => void;
  setSelectedProperty: (index: number) => void;
}

const Properties: FC<PropertiesProps> = ({
  isGuest,
  dashboardState: { onboardedProperties, selectedProperty, ctaRequired, noneOnboarded },
  logout,
  setSelectedProperty,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            {isGuest ? (
              <LoginPrompt text={LABELS.LOGIN} />
            ) : (
              <>
                {ctaRequired && (
                  <ActionCard
                    title={LABELS.NEW_PROPERTY_FOUND}
                    text={LABELS.NEW_PROPERTY_FOUND_INFO}
                    buttonText={LABELS.NEW_PROPERTY_FOUND_BUTTON}
                    handleButtonPress={() => {
                      navigate('rentWelcome');
                    }}
                    showErrorIcon
                  />
                )}
                {noneOnboarded ? (
                  themeKey === ThemeKey.NAX ? (
                    <Text textVariant="info">{LABELS.NO_PROPERTY_NAX}</Text>
                  ) : (
                    <ActionCard
                      title={NO_PROPERTY.TITLE}
                      text={NO_PROPERTY.TEXT}
                      buttonText={NO_PROPERTY.BUTTON}
                      handleButtonPress={() => {
                        navigate('propertyOnboarding');
                      }}
                      showErrorIcon
                    />
                  )
                ) : (
                  <TileContainer title="Properties" noScroll>
                    {onboardedProperties!.map((item, index) => (
                      <PropertyCard
                        property={item.property}
                        selected={selectedProperty === index}
                        addProperty={false}
                        index={index}
                        key={index}
                        fullWidth
                        snapTo={() => {
                          if (onboardedProperties!.length !== index) {
                            setSelectedProperty(index);
                          }
                          navigate('propertyInfo');
                        }}
                      />
                    ))}
                    {themeKey === ThemeKey.SORTED ? (
                      <PropertyCard
                        property={undefined}
                        selected={false}
                        addProperty
                        index={0}
                        fullWidth
                        snapTo={() => null}
                      />
                    ) : null}
                  </TileContainer>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isGuest: state.authState.guest,
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(AuthActions.postLogoutRequest()),
  setSelectedProperty: (index: number) => {
    dispatch(DashboardActions.setSelectedProperty(index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
