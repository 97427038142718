/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    activeServicesGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
  })();
};
