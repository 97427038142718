/* eslint-disable class-methods-use-this */

import { BundleResponse } from '../../models/bundles/Bundles';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { ReviewRequest, ReviewResponse } from '../../models/tenantRequest/Request';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class RequestService {
  public getRequestService(status: string, propertyId?: number): Promise<MaintenanceResponse[]> {
    const service = new ApiService(ServiceType.request);
    const path: QueryPath = {
      query: { property: propertyId, status },
    };
    return service.get<MaintenanceResponse[]>(path);
  }

  public cancelRequestService(maintenanceId: string): Promise<void> {
    const service = new ApiService(ServiceType.cancel);
    const path: QueryPath = {
      route: [maintenanceId],
      query: {},
    };
    return service.patch(path, JSON.stringify('closed'));
  }

  public submitQuoteRequestService(quoteId: string, status: string): Promise<void> {
    const service = new ApiService(ServiceType.cancel);
    const path: QueryPath = {
      route: ['quote', quoteId],
      query: { status: status.toUpperCase() },
    };
    return service.patch(path, {});
  }

  public getBundles(): Promise<BundleResponse> {
    const service: ApiService = new ApiService(ServiceType.packages);
    return service.get({
      query: {
        privateName: 'MIDDYS',
      },
      route: ['packages'],
    });
  }

  public submitReview(request: ReviewRequest): Promise<void> {
    const service: ApiService = new ApiService(ServiceType.review);
    const { jobId, ...body } = request;
    return service.post(
      {
        route: [jobId],
      },
      {
        officeId: body.officeId,
        user: body.user,
        rating: body.rating,
        review: body.review,
      },
    );
  }

  public getReview(jobId: string): Promise<ReviewResponse> {
    const service: ApiService = new ApiService(ServiceType.review);
    return service.get({
      route: [jobId],
    });
  }
}

const requestService = new RequestService();
export default requestService;
