export enum ContentCategory {
  Image,
  Text,
  Document,
  Video,
}

export class ContentCategoryHelper {
  public static readonly allContentCategories = [
    ContentCategory.Image,
    ContentCategory.Text,
    ContentCategory.Document,
    ContentCategory.Video,
  ];
}
