import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    serviceCardItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
      padding: 12,
      paddingLeft: theme.units.spacing * 4,
      backgroundColor: theme.colors.light,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    cardLeftView: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardLeftVerticalView: {
      marginLeft: 16,
    },
    imageContainer: {
      height: 32,
      width: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    serviceIcon: {
      width: 24,
      height: 24,
      resizeMode: 'contain',
      padding: theme.units.spacing * 3,
      borderRadius: 5000,
      backgroundColor: theme.colors.lightGrey,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    serviceName: {
      marginLeft: 16,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    supplierCountArea: {
      flexDirection: 'row',
    },
    supplierCount: {
      backgroundColor: '#eee',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 7,
      paddingRight: 7,
      borderRadius: 50,
    },
    serviceStatusMaintenance: {
      fontSize: 13,
      letterSpacing: 1.5,
      textAlign: 'right',
      alignSelf: 'stretch',
    },
    icon: {
      height: 14,
      width: 14,
      transform: 'rotate(270deg)',
      opacity: 0.4,
      marginRight: theme.units.spacing * 4,
    },
  })();
};
