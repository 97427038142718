import { ThemeState } from '../state/ThemeState';
import { ThemeAction, ThemeActionTypes } from '../actions/ThemeActions';
import { ThemeKey } from '../../theme/Theme';

export const initialState: ThemeState = {
  themeKey: ThemeKey.SORTED,
};

export const themeReducer = (state = initialState, action: ThemeAction): ThemeState => {
  switch (action.type) {
    case ThemeActionTypes.SET_THEME:
      return {
        ...state,
        themeKey: action.data as ThemeKey,
      };

    default:
      return state;
  }
};
