import {
  DeleteAdditionalPersonPayload,
  DependentProfile,
} from '../../models/masterProfile/MasterProfile';
import { PostFormData } from '../../models/masterProfile/MyProfileFormState';

export enum AdditionalPersonalDetailsScreenActionTypes {
  POST_ADDITIONAL_PROFILE_FORM_REQUEST,
  DELETE_ADDITIONAL_PROFILE,
  DELETE_SECONDARY_PROFILE,
  ADDITIONAL_MY_PROFILE_FORM_REQUEST,
  ADDITIONAL_INTRODUCTION_FORM_REQUEST,
  ADDITIONAL_CURRENT_INCOME_FORM_REQUEST,
  ADDITIONAL_EMPLOYMENT_FORM_REQUEST,
  ADDITIONAL_PETS_FORM_REQUEST,
  ADDITIONAL_VEHICLES_FORM_REQUEST,
  ADDITIONAL_ADDRESS_FORM_REQUEST,
  ADDITIONAL_REFERENCE_FORM_REQUEST,
  ADDITIONAL_IDENTIFICATION_FORM_REQUEST,
  ADDITIONAL_DECLARATION_FORM_REQUEST,
  DELETE_ALL_ADDITIONAL_PROFILE,
  DELETE_ALL_SECONDARY_PROFILE,
  POST_EMAIL_SECONDARY_PROFILE,
  TOGGLE_MANAGE_FLAG,
  ADDITIONAL_REF_ID_REQUEST,
}

export interface AdditionalPersonalDetailsScreenAction {
  type: AdditionalPersonalDetailsScreenActionTypes;
  data?: DependentProfile | PostFormData | ToggleManagePayload;
  id?: number;
  refId?: string | null;
}
export interface DeleteAdditionalPersonDetail {
  type: AdditionalPersonalDetailsScreenActionTypes;
  data?: DeleteAdditionalPersonPayload;
}
export interface DeleteSeconadaryPersonDetail {
  type: AdditionalPersonalDetailsScreenActionTypes;
  data?: DeleteAdditionalPersonPayload;
}
export interface ToggleManagePayload {
  key: number;
  value: boolean;
}

export class AdditionalPersonalDetailsScreenActions {
  public static postFormDetails(
    data: DependentProfile,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.POST_ADDITIONAL_PROFILE_FORM_REQUEST,
      data,
      id,
    };
  }
  // Delete Dependent profile data
  public static deleteAdditionalProfile(
    data: DeleteAdditionalPersonPayload,
  ): DeleteAdditionalPersonDetail {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.DELETE_ADDITIONAL_PROFILE,
      data,
    };
  }
  // Delete Seconadary profile data
  public static deleteSeconadaryProfile(
    data: DeleteAdditionalPersonPayload,
  ): DeleteSeconadaryPersonDetail {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.DELETE_SECONDARY_PROFILE,
      data,
    };
  }

  // Delete Dependent profile data
  public static deleteAllAdditionalProfile(): DeleteAdditionalPersonDetail {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.DELETE_ALL_ADDITIONAL_PROFILE,
    };
  }
  // Delete Seconadary profile data
  public static deleteAllSeconadaryProfile(): DeleteSeconadaryPersonDetail {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.DELETE_ALL_SECONDARY_PROFILE,
    };
  }

  // Change only the email field of the secondary profile
  public static postEmailSeconadaryProfile(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.POST_EMAIL_SECONDARY_PROFILE,
      data,
      id,
    };
  }

  // Post individual Forms.
  public static postMyProfileFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_MY_PROFILE_FORM_REQUEST,
      data,
      id,
    };
  }
  public static postIntroductionDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_INTRODUCTION_FORM_REQUEST,
      data,
      id,
    };
  }
  public static postCurrentIncomeFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_CURRENT_INCOME_FORM_REQUEST,
      data,
      id,
    };
  }
  public static postEmploymentFormDetails(
    data: PostFormData,
    id?: number,
    refId?: string | null,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_EMPLOYMENT_FORM_REQUEST,
      data,
      id,
      refId,
    };
  }
  public static postPetsFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_PETS_FORM_REQUEST,
      data,
      id,
    };
  }

  public static postVehiclesFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_VEHICLES_FORM_REQUEST,
      data,
      id,
    };
  }
  public static postAddressFormDetails(
    data: PostFormData,
    id?: number,
    refId?: string | null,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_ADDRESS_FORM_REQUEST,
      data,
      id,
      refId,
    };
  }
  public static postReferencesFormDetails(
    data: PostFormData,
    id?: number,
    refId?: string | null,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_REFERENCE_FORM_REQUEST,
      data,
      id,
      refId,
    };
  }
  public static postIdentificationFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_IDENTIFICATION_FORM_REQUEST,
      data,
      id,
    };
  }
  public static postDeclarationFormDetails(
    data: PostFormData,
    id?: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_DECLARATION_FORM_REQUEST,
      data,
      id,
    };
  }
  public static toggleManageFlag(
    key: number,
    value: boolean,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.TOGGLE_MANAGE_FLAG,
      data: { key, value },
    };
  }

  // Post individual Forms.
  public static saveRefIdForAdditionalProfile(
    data: PostFormData,
    id: number,
  ): AdditionalPersonalDetailsScreenAction {
    return {
      type: AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_REF_ID_REQUEST,
      data,
      id,
    };
  }
}
