import React, { FC, useEffect, useState, useMemo } from 'react';
import { isEmpty } from '../../helpers/Validation';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputStyles';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { ClickAwayListener } from '@mui/material';

interface InputProps {
  ref?: any;
  placeholder: string;
  value: string | number;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  password?: boolean;
  endElement?: any;
  showRequiredError?: boolean;
  dollar?: boolean;
  hideEndElement?: boolean;
  type?: string;
  name?: string;
  autoComplete?: boolean;
  numeric?: boolean;
  big?: boolean;
  onBlur?: () => void;
  setValue: (value: string) => void;
}

const Input: FC<InputProps> = ({
  ref,
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  password,
  endElement,
  showRequiredError,
  dollar,
  hideEndElement,
  type,
  name,
  autoComplete,
  numeric,
  big,
  onBlur,
  setValue,
}) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div
              className={classNameGenerator([
                classes.titleContainer,
                big && classes.titleContainerBig,
              ])}
            >
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <ClickAwayListener
        onClickAway={() => {
          if (isFocused && onBlur) {
            onBlur();
            setValue(value as string);
          }
        }}
      >
        <div
          className={classNameGenerator([
            classes.inputContainer,
            big && classes.inputContainerBig,
            parentInputStyles,
            ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
          ])}
        >
          {dollar && <div className={classes.dollar}>$</div>}
          <input
            ref={ref}
            inputMode={numeric ? 'numeric' : 'text'}
            name={name}
            autoComplete={autoComplete && name ? name : undefined}
            type={password ? 'password' : dollar ? 'number' : type || 'text'}
            className={classNameGenerator([
              classes.inputStyle,
              big && classes.bigInput,
              parentInputStyles,
              disabled && classes.disabled,
            ])}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            value={value}
            onBlur={onBlur}
            onFocus={() => setIsFocused(true)}
            disabled={disabled}
          />

          <div className={classNameGenerator([classes.endElement, big && classes.endElementBig])}>
            {endElement}
          </div>
          {((touched && error) || (showRequiredError && isEmpty(value)) || value) &&
            !hideEndElement && (
              <img
                className={classNameGenerator([classes.endStatus, big && classes.endStatusBig])}
                src={
                  (touched && error) || (showRequiredError && isEmpty(value))
                    ? IncompleteIcon
                    : value
                    ? CompletedIcon
                    : ''
                }
                alt=""
              />
            )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Input;
