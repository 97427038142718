/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    propertyContainer: {
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} solid 1px`,
      padding: 16,
      backgroundColor: theme.colors.light,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    maintenanceContainer: {
      marginTop: 6,
      flex: 1,
    },
    maintenanceRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    maintenanceKey: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginRight: 4,
    },
    maintenanceValue: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      textTransform: 'capitalize',
      flex: 1,
      flexWrap: 'wrap',
      marginRight: 12,
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    titleContainer: {
      // marginRight: 'auto',
    },
    address: {
      fontSize: 26,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    address2: {
      marginTop: 8,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
    // RIGHT SIDE ------------------------------------------------------------- //
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    house: {
      width: 100,
      height: 100,
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: 12,
    },
    statusContainer: {
      paddingTop: theme.units.spacing,
      paddingBottom: theme.units.spacing,
      borderRadius: 4,
      minWidth: 100,
      backgroundColor: theme.colors.darkGrey,
    },
    statusTextWhite: {
      textAlign: 'center',
      color: theme.colors.secondary,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
    },
    statusTextBlack: {
      textAlign: 'center',
      color: theme.colors.black87,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      textTransform: 'capitalize',
    },
    completeLabel: {
      backgroundColor: theme.colors.completeLabel,
    },
    incompleteLabel: {
      backgroundColor: theme.colors.incompleteLabel,
    },
    yellowLabel: {
      backgroundColor: theme.colors.yellowLabel,
    },
    unsuccessfulLabel: {
      backgroundColor: theme.colors.darkGrey,
    },
    noMaintenanceView: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 48,
      justifyContent: 'center',
      alignItems: 'center',
    },
    noMaintenanceText: {
      color: theme.colors.black87,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      marginTop: 32,
    },
    noMaintenanceSubText: {
      color: theme.colors.black87,
      fontSize: 14,
      fontWeight: getFonts('Light'),
      marginTop: 12,
    },
    noMaintenanceImage: { width: 90, height: 82 },
  })();
};
