/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { useStyles } from './ServiceContainerStyles';
import { History } from 'history';
import { ANIMATION_DELAY, LABELS, PopupType, essentialServices } from './ServiceContainerConstants';
import { AvailableServicesResponse, PreopenDetails } from '../../../../models/checkout/Checkout';
import SwipeableViews from 'react-swipeable-views';
import { isMiddle } from './ServiceContainerUtils';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { routes } from '../../../../Routes';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { ApplicationState } from '../../../../store/RootReducer';
import { Dialog } from '@mui/material';
import { ThemeKey, getCurrentThemeKey } from '../../../../theme/Theme';
import { SERVICE_TYPE_TITLE, ServiceTypes } from '../../../../models/services/services';
import { navigate } from '../../../../navigation/NavigationUtils';
import Plan from '../plan/Plan';
import SlideUp from '../../../../components/transitionsHelper/SlideUp';
import PlanInformation from '../../../../components/planInformation/PlanInformation';
import SupplierInformation from '../../../../components/supplierInformation/SupplierInformation';
import Quote from '../quote/Quote';

interface ServiceContainerProps {
  isPreopened?: boolean;
  preopenedDetails?: PreopenDetails;
  service: AvailableServicesResponse;
  rent?: boolean;
  isModalOpen: PopupType;
  toggleModal: (data: PopupType) => void;
}

const ServiceContainer: FC<ServiceContainerProps> = ({
  service,
  isPreopened,
  preopenedDetails,
  rent,
  isModalOpen,
  toggleModal,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(null);
  const [showPopup, setShowPopup] = useState<PopupType>(PopupType.NONE);
  const [openPlan, setOpenPlan] = useState<number>(0);
  const [hasPreopened, setHasPreopened] = useState<boolean>(false);
  const [touchedSupplier, setTouchedSupplier] = useState<number>(0);

  const isTradeRequest = useMemo<boolean>(() => {
    return !essentialServices.includes(service.type as ServiceTypes);
  }, [service]);

  const numOfPlans = useMemo<number>(() => {
    if (selectedSupplier === null) {
      return service.suppliers!.reduce((a, b) => a + b.plans.length, 0);
    } else {
      return !!service.suppliers![selectedSupplier]
        ? service.suppliers![selectedSupplier].plans.length
        : service.suppliers!.reduce((a, b) => a + b.plans.length, 0);
    }
  }, [service, selectedSupplier]);

  useEffect(() => {
    if (preopenedDetails && preopenedDetails.providerId && service.suppliers) {
      const index = service.suppliers.findIndex(
        (supplier) => supplier.providerId === preopenedDetails.providerId,
      );

      if (index !== -1 && !hasPreopened) {
        setSelectedSupplier(index);
      }
    } else {
      setSelectedSupplier(null);
    }
  }, [preopenedDetails]);

  return (
    <div className={classes.container}>
      {service.suppliers && (
        <>
          <div className={classes.providerPicker}>
            <div className={classes.providerTitle}>{LABELS.BROWSE}</div>
            <div className={classes.providerRow}>
              {service.suppliers.map((supplier, idx) => (
                <div
                  className={classNameGenerator([
                    classes.providerContainer,
                    selectedSupplier === idx && classes.selectedProviderContainer,
                  ])}
                  key={idx}
                  onClick={() => setSelectedSupplier(selectedSupplier === idx ? null : idx)}
                >
                  <div className={classes.providerIconContainer}>
                    <img className={classes.providerIconContainer} src={supplier.logo} alt="logo" />
                  </div>
                  <div className={classes.providerText}>{supplier.name}</div>
                </div>
              ))}
            </div>
          </div>

          <div className={classes.providerTitle}>
            ({numOfPlans}) {service.type} {isTradeRequest ? 'quotes' : 'services'}
          </div>
          <div className={isTradeRequest ? classes.quotesContainer : classes.servicesContainer}>
            {service.suppliers
              .filter((_, i) => {
                if (selectedSupplier === null) return true;
                return selectedSupplier === i;
              })
              .map((supplier, index) =>
                supplier.plans.map((plan, idx) => (
                  <>
                    {isTradeRequest ? (
                      <Quote
                        plan={plan}
                        supplier={supplier}
                        serviceType={service.type}
                        logo={supplier.logo}
                        handleAction={() => null}
                        handleSupplierAction={() => {
                          setTouchedSupplier(index);
                          setShowPopup(PopupType.SUPPLIER);
                          toggleModal(PopupType.SUPPLIER);
                        }}
                        onSelection={() => {
                          setHasPreopened(true);
                        }}
                        key={index}
                      />
                    ) : (
                      <Plan
                        plan={plan}
                        supplier={supplier}
                        serviceType={service.type}
                        logo={supplier.logo}
                        handleAction={() => {
                          if (!isTradeRequest) {
                            setOpenPlan(idx);
                            toggleModal(PopupType.PLAN);
                            setShowPopup(PopupType.PLAN);
                            setTouchedSupplier(index);
                          }
                        }}
                        handleSupplierAction={() => {
                          setTouchedSupplier(index);
                          setShowPopup(PopupType.SUPPLIER);
                          toggleModal(PopupType.SUPPLIER);
                        }}
                        showCompare={supplier.extendedData!.ServiceCategoryId !== 'TradeRequest'}
                        onSelection={() => {
                          if (isTradeRequest) {
                            navigate('checkoutConfigure');
                          } else {
                            setHasPreopened(true);
                          }
                        }}
                        key={index}
                      />
                    )}
                  </>
                )),
              )}
          </div>

          <Dialog
            open={isModalOpen !== PopupType.NONE && showPopup === PopupType.SUPPLIER}
            fullWidth
            maxWidth="sm"
            style={{ zIndex: 999999 }}
            PaperProps={{ sx: { borderRadius: '16px' } }}
            TransitionComponent={SlideUp}
            onClose={() => {
              setShowPopup(PopupType.NONE);
              toggleModal(PopupType.NONE);
            }}
          >
            <div className={classes.popupContainer}>
              <SupplierInformation
                supplier={service.suppliers[touchedSupplier]}
                closePopup={() => {
                  setShowPopup(PopupType.NONE);
                  toggleModal(PopupType.NONE);
                }}
                serviceType={service.type}
                isTradeRequest={isTradeRequest}
              />
            </div>
          </Dialog>
          <Dialog
            open={isModalOpen !== PopupType.NONE && showPopup === PopupType.PLAN}
            fullWidth
            maxWidth="sm"
            style={{ zIndex: 999999 }}
            PaperProps={{ sx: { borderRadius: '16px' } }}
            TransitionComponent={SlideUp}
            onClose={() => {
              setShowPopup(PopupType.NONE);
              toggleModal(PopupType.NONE);
            }}
          >
            <div className={classes.popupContainer}>
              <PlanInformation
                plan={service.suppliers![touchedSupplier].plans[openPlan]}
                serviceType={service.type}
                supplier={service.suppliers![touchedSupplier]}
                logo={service.suppliers![touchedSupplier].logo}
                closePopup={() => {
                  setShowPopup(PopupType.NONE);
                  toggleModal(PopupType.NONE);
                }}
              />
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isModalOpen: state.checkout.isModalOpen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleModal: (data: PopupType) => dispatch(CheckoutActions.toggleModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer);
