/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dialog, Drawer } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ADD_NEW_ICON from '../../../assets/payment/filled.png';
import tick from '../../../assets/tick.png';
import arrowLeft from '../../../assets/navigation/arrow-left.png';
import Button from '../../../components/button/Button';
import Text from '../../../components/text/Text';
import {
  GetFastPayResponse,
  GetPaymentMethodsResponse,
  GetPaymentType,
  GetPaymentTypeResponse,
  PayIdPayLoad,
  PostEditPaymentPayload,
} from '../../../models/payment/Payment';
import {
  SelectPaymentParams,
  ViewPaymentMethodsParams,
} from '../../../navigation/NavigationConstants';
import { extractParams, navigate, navigateBack } from '../../../navigation/NavigationUtils';
import { ApplicationState } from '../../../store/RootReducer';
import { PaymentActions } from '../../../store/actions/PaymentActions';
import { PaymentType } from '../../checkoutFinalize/components/paymentSection/PaymentSectionConstants';
import { serviceTypeList } from '../addPayment/AddPaymentConstants';
import { createEditPaymentObject } from '../addPayment/AddPaymentUtils';
import { handlePaymentSuccess } from '../addPayment/addCard/AddCardUtils';
import { useStyles } from './ViewPaymentMethodsStyles';
import { classNameGenerator } from '../../../theme/GlobalStyles';
import info from '../../../assets/info.png';
import SlideLeft from '../../../components/transitionsHelper/SlideLeft';
import closeIcon from '../../../assets/navigation/close.png';
import { LABELS } from './ViewPaymentMethodsConstants';
import { AuthDetails } from '../../../models/auth/auth';
import PayId from '../../../assets/payId.png';
import { ValidMessages, sendMessageToApp } from '../../../helpers/MessageHelper';
import { PAYMENT_LABELS } from '../selectPayment/PaymentDetailsConstants';

interface ViewPaymentMethodsProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const ViewPaymentMethods: FC<ViewPaymentMethodsProps> = ({
  paymentMethods,
  getPaymentMethods,
  saveSelectedPayment,
}) => {
  const classes = useStyles();
  const { serviceType } = extractParams<ViewPaymentMethodsParams>();
  const [index, setIndex] = useState<number | boolean>(false);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const handleOptions = (payment: GetPaymentMethodsResponse, idx: number) => {
    setIndex(idx);
    saveSelectedPayment(payment);
    switch (payment.paymentMethod) {
      case PaymentType.Bank:
        navigate('viewBank', { serviceType: serviceType.toLowerCase() });
        break;
      case PaymentType.Credit:
        navigate('viewCard', { serviceType: serviceType.toLowerCase() });
        break;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>

            {paymentMethods &&
              paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, idx: number) => {
                const paymentMethodDetails =
                  paymentMethod.paymentMethod === PaymentType.Bank
                    ? PAYMENT_LABELS.paymentMethodDetails.Bank
                    : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
                        (data) => data.cardType === paymentMethod.cardType,
                      ) || PAYMENT_LABELS.paymentMethodDetails.Credit[0];
                return (
                  <div
                    onClick={() => handleOptions(paymentMethod, idx)}
                    className={classNameGenerator([
                      classes.menuItem,
                      idx === index && classes.selectedMenuItem,
                    ])}
                  >
                    <div className={classes.iconContainer}>
                      <img src={paymentMethodDetails.logo} className={classes.iconImage} />
                    </div>
                    <div className={classes.optionContainer}>
                      <div className={classes.optionTitle}>{paymentMethodDetails.name}</div>
                      <div className={classes.optionSubtitle}>
                        {paymentMethod.paymentMethod === 'Bank'
                          ? `${paymentMethod.bsb} - ${paymentMethod.accountNumber}`
                          : paymentMethod.paymentMethod === 'Credit'
                          ? `${paymentMethod.cardNumber!.replace(/\./g, '*')}`
                          : '**** **** **** 1234'}
                      </div>
                    </div>
                    <div className={classes.tickContainer}>
                      {idx === index && <img src={tick} className={classes.tick} />}
                    </div>
                  </div>
                );
              })}
            <div
              className={classes.menuItem}
              onClick={() =>
                navigate('addPayment', {
                  serviceType: serviceType.toLowerCase(),
                  propertyId: 'propertyId',
                })
              }
            >
              <div className={classes.iconContainer}>
                <img src={ADD_NEW_ICON} className={classes.iconImage} />
              </div>
              <div className={classes.optionContainer}>
                <div className={classes.optionTitle}>{PAYMENT_LABELS.addNewLabel}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethods: state.payment.paymentMethods,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentMethods: () => {
    dispatch(PaymentActions.getPaymentMethodsStart({ onSuccess: () => null }));
  },
  saveSelectedPayment: (payment: GetPaymentMethodsResponse) => {
    dispatch(PaymentActions.postPaymentTokenSuccess(payment));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPaymentMethods);
