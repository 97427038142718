import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 20,
    },
    inputs: {
      width: '100%',
      marginTop: 10,
    },
    cardStyle: {
      marginTop: 20,
    },
    seperator: {
      height: 1,
      margin: 4,
      marginBottom: 12,
      background: '#eee',
    },
    cardType: {
      margin: '20px 0px',
    },
    cardToggleContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      height: 40,
      borderRadius: 2,
      margin: '10px 0px',
      boxShadow: 'none',
      borderRight: `1px solid ${theme.colors.buttonPrimary}`,
    },
    toggleButton: {
      flex: 1,
      color: theme.colors.buttonPrimary,
      borderTop: `1px solid ${theme.colors.buttonPrimary}`,
      borderBottom: `1px solid ${theme.colors.buttonPrimary}`,
      borderLeft: `1px solid ${theme.colors.buttonPrimary}`,
      fontSize: 15,
      height: '100%',
    },
    toggleButtonActive: {
      flex: 1,
      background: `${theme.colors.buttonPrimary} !important`,
      color: `#fff !important`,
      height: '100%',
    },
  })();
};
