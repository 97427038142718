import * as Yup from 'yup';
import { EMAIL_PATTERN } from '../../../../constants/Strings';

export const LABELS = {
  FORGOT_PASSWORD: "Let's confirm your login details",
  FORGOT_PASSWORD_SUBTITLE: 'Enter your email address to receive a reset password link',
  EMAIL: 'Email',
  RESET_PASSWORD: 'Reset password',
  LOGIN: 'Login',
  BACK: 'Go back?',
};

export const ERRORS = {
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
});
