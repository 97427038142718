import * as Yup from 'yup';
import { EMAIL_PATTERN } from '../../../../constants/Strings';

export const LABELS = {
  LOGIN_TITLE: 'Login to your Sorted profile',
  LOGIN_SUBTITLE: 'Enter your log in details below to get started!',
  SIGNUP_HEADER: 'Sign up',
  EMAIL_ADDRESS: 'Email',
  PASSWORD: 'Password',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  FORGOT_PASSWORD: 'Forgot Password?',
  RESEND_LINK: 'Resend Link',
  LOG_INTO_SORTED: 'Log into Sorted',
  LOGIN_BELOW: 'Log in below',
  ENTER: 'Enter your log in details below to log into your Sorted account',
  FORGOT: 'Reset password',
  LOGIN: 'Login',
  DONT: "Don't have an account?",
  SIGNUP: 'Sign up',
  EMAIL: 'Email',
  WHY_SORTED: 'Why Sorted?',
  CANT: "Can't log in?",
};

export const ERRORS = {
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  requiredPassword: 'Please enter password',
  minimumRequired: 'Minimum 8 characters required',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  password: Yup.string().required(ERRORS.requiredPassword).min(8, ERRORS.minimumRequired),
});

export const WHY_SORTED = [
  {
    title: 'Simple services',
    body: 'Sorted is Australia’s first all-in-one home services marketplace – allowing you to connect everything you need for your home, all in one place.',
  },
  {
    title: 'Bills made easy',
    body: 'The Sorted app lets you set up payments across all your services in seconds, then easily pay and manage all your bills in the one spot. Never spend hours updating your details across countless accounts again!',
  },
  {
    title: 'Real nice humans',
    body: 'Our chat service is powered by real, local, humans, ready to chat. From setting you up to making sure you get the most value, our friendly crew are here to help. ',
  },
];
