import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './SupplierInformationStyles';
import { LABELS } from './SupplierInformationConstants';
import { SupplierResponse, ModifyCart } from '../../models/checkout/Checkout';
import PlanInformation from '../planInformation/PlanInformation';
import SlideUp from '../transitionsHelper/SlideUp';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import doneCircle from '../../assets/radio-filled.png';
import doneCircleBlack from '../../assets/radio-filled-black.png';
import closeIcon from '../../assets/navigation/close.png';
import { Dialog } from '@mui/material';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface SupplierInformationProps {
  supplier: SupplierResponse;
  serviceType: string;
  closePopup: () => void;
  isTradeRequest: boolean;
}

const SupplierInformation: FC<SupplierInformationProps> = ({
  serviceType,
  supplier,
  closePopup,
  isTradeRequest,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  const [showBrand, setShowBrand] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [openPlan, setOpenPlan] = useState<number>(0);

  useEffect(() => {
    document.addEventListener('backbutton', () => closePopup());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  return (
    <>
      {/* <div className={classes.popupHeader}>
        <div className={classes.flex}>
          <div className={classes.popupActionContainer} onClick={() => closePopup()}>
            <img className={classes.popupAction} src={backArrowBlack} alt="backIcon" />
          </div>
          <div className={classes.popupLabel}>{supplier.name}</div>
        </div>
      </div> */}

      <div className={classes.informationContainer}>
        <div className={classes.supplierBackgroundImgContainer}>
          <img className={classes.supplierBackgroundImg} src={supplier.backgroundImg} alt="bg" />
          <div className={classes.closeContainer} onClick={() => closePopup()}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        <div className={classes.informationHeader}>
          <div className={classes.supplierLogoContainer}>
            <img className={classes.supplierLogo} src={supplier.logo} alt="logo" />
          </div>
          <div className={classes.supplierName}>{supplier.name}</div>
          {/* <div className={classes.tabsContainer}>
            <div
              className={showBrand ? classes.activeTab : classes.inactiveTab}
              onClick={() => setShowBrand(true)}
            >
              <div className={showBrand ? classes.activeTabText : classes.inactiveTabText}>
                {LABELS.BRAND}
              </div>
              {showBrand && <div className={classes.marker} />}
            </div>
            <div
              className={!showBrand ? classes.activeTab : classes.inactiveTab}
              onClick={() => setShowBrand(false)}
            >
              <div className={!showBrand ? classes.activeTabText : classes.inactiveTabText}>
                {LABELS.PLANS}
              </div>
              {!showBrand && <div className={classes.marker} />}
            </div>
          </div> */}
        </div>
        {/* {showBrand ? (
          <> */}
        <div className={classes.section}>
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: supplier.description,
            }}
          />
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: supplier.descriptionLong,
            }}
          />
        </div>
        {supplier.features && supplier.features.length !== 0 && (
          <div className={classes.section}>
            <div className={classes.sectionTitle}>{LABELS.FEATURES}</div>
            {supplier.features.map((feature, idx) => (
              <div className={classes.featureContainer} key={idx}>
                <div className={classes.featureIconContainer}>
                  <img
                    className={classes.featureIcon}
                    src={themeKey === ThemeKey.NAX ? doneCircleBlack : doneCircle}
                    alt="icon"
                  />
                </div>
                <div className={classes.featureText}>{feature.title}</div>
              </div>
            ))}
          </div>
        )}
        {/* <div className={classes.testimonialTitle}>{LABELS.TESTIMONIALS}</div>
            <div className={classes.testimonialContainer}>
              {supplier.testimonials.map((testimonial, idx) => (
                <div className={classes.testimonialCard} key={idx}>
                  <div className={classes.starsContainer}>
                    <img className={classes.stars} src={fiveStars} alt="stars" />
                  </div>
                  <div className={classes.testimonalText}>{testimonial.description}</div>
                  <div className={classes.testimonalSource}>{LABELS.APPSTORE}</div>
                </div>
              ))}
            </div> */}
        {/* </>
        ) : (
          <div className={classes.plansContainer}>
            {supplier.plans.map((plan, index) => (
              <Plan
                plan={plan}
                serviceType={serviceType}
                supplier={supplier}
                logo={supplier.logo}
                handleAction={() => {
                  if (!isTradeRequest) {
                    setOpenPlan(index);
                    setShowPopup(true);
                  }
                }}
                key={index}
              />
            ))}
          </div>
        )} */}
      </div>
      <Dialog open={showPopup} fullScreen style={{ zIndex: 999999 }} TransitionComponent={SlideUp}>
        <div className={classes.popupContainer}>
          <PlanInformation
            plan={supplier.plans[openPlan]}
            serviceType={serviceType}
            supplier={supplier}
            logo={supplier.logo}
            closePopup={() => setShowPopup(false)}
          />
        </div>
      </Dialog>
    </>
  );
};

export default SupplierInformation;
