import React, { createRef, FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './PlanInformationStyles';
import { LABELS } from './PlanInformationConstants';
import {
  AddAndUpdateCartActionRequest,
  DeleteCartItemActionRequest,
  ModifyCart,
  PlanResponse,
  SupplierResponse,
} from '../../models/checkout/Checkout';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import {
  removeFromCompare,
  resetCompare,
  updateAvailableServicesOnSelect,
} from '../../helpers/CheckoutHelper';
import closeIcon from '../../assets/navigation/close.png';
import downArrow from '../../assets/navigation/accordian.png';
import { sortSections } from './PlanInformationUtils';
import { ServiceTypes } from '../../models/services/services';
import { ApplicationState } from '../../store/RootReducer';
import { Property } from '../../models/property/property';
import binIcon from '../../assets/bin.png';

interface PlanInformationProps {
  plan: PlanResponse;
  serviceType: string;
  supplier: SupplierResponse;
  logo: string;
  accordian?: boolean;
  compare?: boolean;
  closePopup: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  property: Property;
  planRefs?: any;
  updateRefsHeight?: (key: string) => void;
  compareMode?: boolean;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
  isTradeRequest: boolean;
  removeFromCompare: (data: ModifyCart) => void;
}

const PlanInformation: FC<PlanInformationProps> = ({
  plan,
  serviceType,
  supplier,
  logo,
  accordian,
  compare,
  closePopup,
  updateCart,
  property,
  planRefs,
  updateRefsHeight,
  compareMode,
  deleteCartItem,
  isTradeRequest,
  removeFromCompare,
}) => {
  const planKey = `${plan.title}`;
  const classes = useStyles();

  const handleTap = () => {
    let updatedAvailableServices = updateAvailableServicesOnSelect(
      !!plan.selected,
      serviceType,
      supplier.providerId,
      plan.productId,
    );
    if (!!plan.selected) {
      deleteCartItem({
        availableServices: updatedAvailableServices,
        deleteCartItemRequest: {
          propertyId: property.id.toString(),
          serviceType,
          supplierId: supplier.providerId,
          planId: plan.productId,
        },
      });
    } else {
      if (compare) {
        updatedAvailableServices = resetCompare(updatedAvailableServices);
      }
      updateCart({
        availableServices: updatedAvailableServices,
        propertyId: property.id.toString(),
      });
    }
    closePopup();
  };

  useEffect(() => {
    document.addEventListener('backbutton', () => closePopup());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  return (
    <div className={classes.planInformationWrapper}>
      {!accordian && !compare && (
        <div className={classes.supplierBackgroundImgContainer}>
          <img className={classes.supplierBackgroundImg} src={supplier.backgroundImg} alt="bg" />
          <div className={classes.closeContainer} onClick={() => closePopup()}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
      )}
      <div
        className={`${classes.planInformationContainer} ${accordian && classes.noPadding} ${
          compareMode && classes.compareScroll
        }`}
      >
        {!accordian && !compare && (
          <div className={classes.section}>
            <div className={classes.planHeader}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
              <div className={classes.titleContainer}>
                <div className={classes.title}>{supplier.name}</div>
                <div className={classes.description}>{plan.title}</div>
              </div>
              <div className={classes.priceContainer}>
                <div className={classes.from}>{plan.pricingType}</div>
                <div className={classes.priceWrapper}>
                  <div className={classes.price}>{`$${plan.price}`}</div>
                  <div className={classes.uom}>{`/${plan.uom}`}</div>
                </div>
              </div>
            </div>
            {plan.subtext && serviceType === ServiceTypes.Electricity && (
              <div
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: plan.subtext,
                }}
              />
            )}
          </div>
        )}
        {compareMode && (
          <div className={classes.planCompareContainer}>
            <div className={classes.titleContainer2}>
              <div className={classes.title2}>{supplier.name}</div>
              <div className={classes.description2}>{plan.title}</div>
              <div
                className={classes.closeContainer2}
                onClick={() =>
                  removeFromCompare({
                    productId: plan.productId,
                    providerId: supplier.providerId,
                    serviceType,
                  })
                }
              >
                <img className={classes.closeIcon} src={binIcon} alt="closeIcon" />
              </div>
            </div>
            <div className={classes.priceContainer}>
              <div className={classes.from2}>{plan.pricingType}</div>
              <div className={classes.priceWrapper}>
                <div className={classes.price2}>{`$${plan.price}`}</div>
                <div className={classes.uom2}>{`/${plan.uom}`}</div>
              </div>
            </div>
            {plan.subtext && serviceType === ServiceTypes.Electricity && (
              <div
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: plan.subtext,
                }}
              />
            )}
          </div>
        )}

        {!compareMode ? (
          plan.planInformation.map((section, index) => (
            <div className={`${classes.section} ${accordian && classes.noBorder}`} key={index}>
              <div className={classes.sectionTitle}>{section.sectionTitle}</div>
              {section.content.map((content, idx) => (
                <div className={classes.textContainer} key={idx}>
                  <span className={classes.boldText}>{content.Key} :</span>
                  <div
                    className={classes.regularText}
                    dangerouslySetInnerHTML={{
                      __html: content.Value,
                    }}
                  />
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className={classes.planScroll}>
            {sortSections(plan.planInformation).map((section, index) => (
              <div
                className={classes.card}
                style={index === 0 ? { marginTop: 0 } : {}}
                key={`${planKey}_${section.sectionTitle}_${index}`}
              >
                <div className={classes.cardHeaderContainer}>
                  {/* add title here */}
                  <div className={classes.cardTitle}>{section.sectionTitle}</div>
                  <div
                    className={`${classes.actionIconContainer} ${
                      planRefs[`${planKey}_${section.sectionTitle}`].height > 0 && classes.rotate
                    }`}
                    onClick={() => {
                      if (updateRefsHeight) {
                        updateRefsHeight(section.sectionTitle);
                      }
                    }}
                  >
                    <img className={classes.actionIcon} src={downArrow} alt="status" />
                  </div>
                </div>
                <div
                  className={classes.content}
                  ref={planRefs[`${planKey}_${section.sectionTitle}`].ref}
                  style={{
                    maxHeight: `${planRefs[`${planKey}_${section.sectionTitle}`].height}px`,
                    opacity: planRefs[`${planKey}_${section.sectionTitle}`].height > 0 ? 1 : 0,
                    paddingBottom:
                      planRefs[`${planKey}_${section.sectionTitle}`].height > 0 ? 15 : 0,
                  }}
                >
                  {/* add content here */}
                  {section.content.map((content, idx) => (
                    <div className={classes.textContainer} key={idx}>
                      <span className={classes.boldText}>{content.Key}</span>
                      <div
                        className={classes.regularText}
                        dangerouslySetInnerHTML={{
                          __html: content.Value,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {!accordian && (
        <div
          className={classes.primaryButton}
          style={
            compareMode
              ? {
                  left: 0,
                  right: 0,
                  bottom: 24,
                  position: 'absolute',
                  width: 'calc(100% - 32px)',
                  zIndex: 1,
                }
              : {}
          }
          onClick={() => handleTap()}
        >
          {plan.selected ? LABELS.REMOVE : LABELS.ADD}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  removeFromCompare: (data: ModifyCart) => dispatch(CheckoutActions.removeFromCompare(data)),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanInformation);
