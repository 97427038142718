import {
  GetRentOnboardingResponse,
  PostRentOnboardingPayload,
  GetRentOnboardingPayload,
  PostServiceabilityPayload,
  PostRentOnboardingResponse,
  Services,
} from '../../models/rentOnboarding/rentOnboarding';
import { StoreAction } from '../StoreHelper';

export enum RentOnboardingActionTypes {
  GET_RENT_ONBOARDINNG = 'GET_RENT_ONBOARDINNG',
  GET_RENT_ONBOARDINNG_REQUEST = 'GET_RENT_ONBOARDINNG_REQUEST',
  GET_RENT_ONBOARDINNG_SUCCESS = 'GET_RENT_ONBOARDINNG_SUCCESS',
  GET_RENT_ONBOARDINNG_ERROR = 'GET_RENT_ONBOARDINNG_ERROR',

  POST_RENT_ONBOARDINNG_REQUEST = 'POST_RENT_ONBOARDINNG_REQUEST',
  POST_RENT_ONBOARDINNG_SUCCESS = 'POST_RENT_ONBOARDINNG_SUCCESS',
  POST_RENT_ONBOARDINNG_ERROR = 'POST_RENT_ONBOARDINNG_ERROR',

  POST_SERVICEABILITY_REQUEST = 'POST_SERVICEABILITY_REQUEST',
  POST_SERVICEABILITY_SUCCESS = 'POST_SERVICEABILITY_SUCCESS',
  POST_SERVICEABILITY_ERROR = 'POST_SERVICEABILITY_ERROR',

  SAVE_PROPERTY_ID = 'SAVE_PROPERTY_ID',
}

export type RentOnboardingPayload =
  | GetRentOnboardingResponse[]
  | PostRentOnboardingPayload
  | PostRentOnboardingResponse
  | Error
  | number
  | GetRentOnboardingPayload
  | PostServiceabilityPayload
  | Services[];

export type RentOnboardingAction = StoreAction<RentOnboardingActionTypes, RentOnboardingPayload>;

export class RentOnboardingActions {
  public static getRentOnboardingRequest(data: GetRentOnboardingPayload): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.GET_RENT_ONBOARDINNG_REQUEST,
      data,
    };
  }
  public static getRentOnboardingSuccess(data: GetRentOnboardingResponse[]): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.GET_RENT_ONBOARDINNG_SUCCESS,
      data,
    };
  }
  public static getRentOnboardingError(data: Error): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.GET_RENT_ONBOARDINNG_ERROR,
      data,
    };
  }
  public static postRentOnboardingStart(data: PostRentOnboardingPayload): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_RENT_ONBOARDINNG_REQUEST,
      data,
    };
  }
  public static postRentOnboardingSuccess(data: PostRentOnboardingResponse): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_RENT_ONBOARDINNG_SUCCESS,
      data,
    };
  }
  public static postRentOnboardingError(data: Error): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_RENT_ONBOARDINNG_ERROR,
      data,
    };
  }

  public static postServiceabilityStart(data: PostServiceabilityPayload): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_SERVICEABILITY_REQUEST,
      data,
    };
  }
  public static postServiceabilitySuccess(data: Services[]): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_SERVICEABILITY_SUCCESS,
      data,
    };
  }
  public static postServiceabilityError(data: Error): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.POST_SERVICEABILITY_ERROR,
      data,
    };
  }

  public static savePropertyId(data: number): RentOnboardingAction {
    return {
      type: RentOnboardingActionTypes.SAVE_PROPERTY_ID,
      data,
    };
  }
}
