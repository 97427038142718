import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    checkboxGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 2,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    checkboxText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      cursor: 'pointer',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      maxWidth: 800,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
  })();
};
