import {
  AddPaymentTagsResponse,
  AddTagStatus,
  EditPaymentRequest,
  GetFastPayment,
  GetFastPayResponse,
  GetPaymentAuthKey,
  GetPaymentAuthKeyResponse,
  GetPaymentMethodsResponse,
  GetPaymentTypeResponse,
  GetRefIdResponse,
  GetStripePaymentIntentRequest,
  GetStripePaymentIntentResponse,
  PayIdPayLoad,
  PaymentGateway,
  PostPaymentToken,
  PostPaymentTokenResponse,
  PostPaymentUUIDRequest,
  PostPaymentUUIDResponse,
} from '../../models/payment/Payment';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class PaymentService {
  public fetchPayment(body: any): Promise<GetFastPayResponse> {
    const endpoint = ServiceType.payment;
    const service: ApiService = new ApiService(endpoint);
    const path: QueryPath = {
      route: ['requestFastPayPaymentsList'],
      query: null,
    };

    return service.post(path, body);
  }

  public postTagPayment(body: any): Promise<AddPaymentTagsResponse> {
    const endpoint = ServiceType.payment;
    const service: ApiService = new ApiService(endpoint);
    const path: QueryPath = {
      route: ['addPayPaymentTag'],
      query: null,
    };

    return service.post(path, body);
  }

  public getPaymentTypes(data: {
    propertyId: string;
    serviceType: string;
  }): Promise<GetPaymentTypeResponse> | GetPaymentTypeResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.get({
      route: ['branch/paymentMethods', data.propertyId, data.serviceType],
    });
  }

  public postPaymentUUID(
    data: PostPaymentUUIDRequest,
  ): Promise<PostPaymentUUIDResponse> | PostPaymentUUIDResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.post(
      {
        route: ['user/paymentMethods'],
      },
      data,
    );
  }
  public getPaymentMethods(): Promise<GetPaymentMethodsResponse> | GetPaymentMethodsResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.get({
      route: ['user/paymentMethods'],
    });
  }
  public getPaymentPayId(data: PayIdPayLoad): Promise<GetFastPayResponse> {
    const servicePayId = new ApiService(ServiceType.payment);

    return servicePayId.post(
      {
        route: ['requestFastPayPaymentsList'],
      },
      data,
    );
  }
  public getPaymentAuthKey(
    paymentType?: PaymentGateway,
    paymentMethodType?: string,
    providerId?: string,
  ): Promise<GetPaymentAuthKey> | GetPaymentAuthKey {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.get({
      route: ['paymentMethods/authKey'],
      query: {
        paymentType,
        paymentMethodType,
        providerId,
      },
    });
  }

  public getPaymentRefId(
    token: string,
    providerId?: string,
    transactionId?: string,
  ): Promise<GetRefIdResponse> | GetRefIdResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.get({
      route: ['paymentMethods', 'accountDetails', token],
      query: {
        providerId,
        transactionId,
      },
    });
  }

  public postPaymentToken(
    data: PostPaymentToken,
  ): Promise<PostPaymentTokenResponse> | PostPaymentTokenResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.post(
      {
        route: ['user/paymentMethods'],
      },
      data,
    );
  }
  public deletePayment(paymentRefId: string): Promise<string[]> | string[] {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.delete({
      route: ['user/paymentMethods', paymentRefId],
    });
  }

  public getStripePaymentIntent(
    data: GetStripePaymentIntentRequest,
  ): Promise<GetStripePaymentIntentResponse> | GetStripePaymentIntentResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.post(
      {
        route: ['stripe/payment-intent'],
      },
      {
        totalAmount: data.totalAmount,
        cartItemId: data.cartItemId,
      },
    );
  }

  public postEditPaymentService(data: EditPaymentRequest): void | Promise<void> {
    const service: ApiServiceBase = new ApiService(ServiceType.transaction);

    return service.put<void>(
      {
        route: ['service/payment-method'],
      },
      data,
    );
  }
}

const paymentService = new PaymentService();
export default paymentService;
