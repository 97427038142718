import { ThemeKey, getCurrentThemeKey, getThemeKey } from '../theme/Theme';

export const PASSWORD_PATTERN = /(?=.{8,32})/;
export const EMAIL_PATTERN = /\S+@\S+\.\S+/;
export const NAME_PATTERN = /^([A-Za-z])([A-Za-z0-9]+)/;
export const NUMERIC_ONLY_PATTERN = /\D/g;
export const MOBILE_PATTERN = /^04([\d]){8}$/;
export const TITLES = ['Mrs.', 'Mr.'];
export const ID_PATTERN = /([A-Za-z0-9]+){8}/;
export const ID_MIN_LENGTH = 8;
export const PIN_LENGTH = 4;
export const APP_VERSION_REGEX = /^[\d]+.[\d]+.[\d]+$/;
export const FORTNIGHT_MULTIPLIER = 168 / 365;
export const WEEKLY_MULTIPLIER = 84 / 365;
export const REMOVE_BASE64_INITIAL = /data:application\/pdf;base64,/i;
export const ID_TYPES = ["Driver's License", 'Medicare', 'Passport'];
export const safeAreaStyle = { flex: 0, backgroundColor: '#ffffff' };
export const MIN_ID_POINTS = 80;
export const filenameExtractRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // Regex to extract filename from header content-disposition

export enum DateFormats {
  uiFormat = 'YYYY-MM-DD',
  apiFormat = 'DD/MM/YYYY',
  datePickerFormat = 'dd/MM/yyyy',
  displayFormat = 'DD MMM YYYY',
  CommentDateAndTime = 'MMM Do YYYY [at] h:mm A',
  medicareFormat = 'MM/YYYY',
  timeFormat = 'HH:mm',
  extendedUiFormat = 'ddd, DD/MM/YYYY',
}

export const ID_POINTS = {
  DRIVING_LICENSE: 40,
  PASSPORT: 40,
  BIRTH_CERTIFICATE: 30,
  MEDICARE: 20,
  OTHER: 10,
};

export const PROPERTY_TYPE = [
  {
    propertyType: "I'm an owner-occupier",
    iconName: 'home',
    value: 'OWNER_OCCUPIER',
  },
  {
    propertyType: "I'm a tenant in a rental",
    iconName: 'home',
    value: 'TENANT',
  },
  {
    propertyType: 'This is my investment property',
    iconName: 'home',
    value: 'INVESTMENT',
  },
  {
    propertyType: 'This is my holiday home',
    iconName: 'home',
    value: 'HOLIDAY_HOME',
  },
  {
    propertyType: 'This is an accoumodation rental',
    iconName: 'home',
    value: 'ACCOMMODATION_RENTAL',
  },
];

export const SORTED_LABELS = {
  LANDLORD_ATTENDING: 'Landlord attending',
  APPROVE_TENANT: 'Approve tenant',
  PROPERTY_TENANT: 'Tenant',
  Tenant: 'Tenant',
};

export const NAX_LABELS = {
  LANDLORD_ATTENDING: 'Rental provider attending',
  APPROVE_TENANT: 'Approve renter',
  PROPERTY_TENANT: 'Renter',
  Tenant: 'Renter',
};

export const getLabel = (key: string) => {
  const themeKey = getThemeKey();
  switch (themeKey) {
    case ThemeKey.NAX:
      return NAX_LABELS[key] || key;
    case ThemeKey.SORTED:
      return SORTED_LABELS[key] || key;
    default:
      return SORTED_LABELS[key] || key;
  }
};

export const ADDRESS_PARAMETERS_VALUE = {
  country: 'country',
  postCode: 'postal_code',
  state: 'administrative_area_level_1',
  streetName: 'sublocality_level_1',
  suburb: 'locality',
  currentLocation: 'Use my current location',
  language: 'en',
  googleQueryType: 'geocode',
  streetNo: 'street_number',
  route: 'route',
};

export const SCHEDULE_TYPE = [
  {
    type: '1 WEEK',
    value: '1 WEEK',
  },
  {
    type: '1 FORTNIGHT',
    value: '1 FORTNIGHT',
  },
  {
    type: '1 MONTH',
    value: '1 MONTH',
  },
  {
    type: '2 MONTH',
    value: '2 MONTH',
  },
  {
    type: '3 MONTH',
    value: '3 MONTH',
  },
  {
    type: '4 MONTH',
    value: '4 MONTH',
  },
  {
    type: '5 MONTH',
    value: '5 MONTH',
  },
  {
    type: '6 MONTH',
    value: '6 MONTH',
  },
  {
    type: '7 MONTH',
    value: '7 MONTH',
  },
  {
    type: '8 MONTH',
    value: '8 MONTH',
  },
  {
    type: '9 MONTH',
    value: '9 MONTH',
  },
  {
    type: '10 MONTH',
    value: '10 MONTH',
  },
  {
    type: '11 MONTH',
    value: '11 MONTH',
  },
  {
    type: '12 MONTH',
    value: '12 MONTH',
  },
];
export const REMINDER_TIME = [
  {
    type: '01:00',
    value: '1',
  },
  {
    type: '02.00',
    value: '2',
  },
  {
    type: '03:00',
    value: '3',
  },
  {
    type: '04:00',
    value: '4',
  },
  {
    type: '05:00',
    value: '5',
  },
  {
    type: '06:00',
    value: '6',
  },
  {
    type: '07:00',
    value: '7',
  },
  {
    type: '08:00',
    value: '8',
  },
  {
    type: '09:00',
    value: '9',
  },
  {
    type: '10:00',
    value: '10',
  },
  {
    type: '11:00',
    value: '11',
  },
  {
    type: '12:00',
    value: '12',
  },
  {
    type: '13:00',
    value: '13',
  },
  {
    type: '14:00',
    value: '14',
  },
  {
    type: '15:00',
    value: '15',
  },
  {
    type: '16:00',
    value: '16',
  },
  {
    type: '17:00',
    value: '17',
  },
  {
    type: '18:00',
    value: '18',
  },
  {
    type: '19:00',
    value: '19',
  },
  {
    type: '20:00',
    value: '20',
  },
  {
    type: '21:00',
    value: '21',
  },
  {
    type: '22:00',
    value: '22',
  },
  {
    type: '23:00',
    value: '23',
  },
  {
    type: '00:00',
    value: '0',
  },
];
export const REMINDER_DAYS = [
  {
    type: '1',
    value: '1',
  },
  {
    type: '2',
    value: '2',
  },
  {
    type: '3',
    value: '3',
  },
  {
    type: '4',
    value: '4',
  },
  {
    type: '5',
    value: '5',
  },
  {
    type: '6',
    value: '6',
  },
  {
    type: '7',
    value: '7',
  },
];
export const ALERTS = {
  PASSWORD_UPDATED: {
    TITLE: 'UPDATE PASSWORD',
    MESSAGE: 'PASSWORD UPDATED SUCCESSFULLY',
  },
  LOGIN_REQUIRED: {
    TITLE: 'Login Required',
    MESSAGE: 'You need to be logged-in to access this features',
  },
};

export const DEFAULT_FCM_TOKEN = 'token';

export const STREET_TYPES = [
  { name: 'Alley', type: 'Ally' },
  { name: 'Arcade', type: 'Arc' },
  { name: 'Avenue', type: 'Ave' },
  { name: 'Boulevard', type: 'Bvd' },
  { name: 'Bypass', type: 'Bypa' },
  { name: 'Circuit', type: 'Cct' },
  { name: 'Close', type: 'Cl' },
  { name: 'Corner', type: 'Crn' },
  { name: 'Court', type: 'Ct' },
  { name: 'Crescent', type: 'Cres' },
  { name: 'Cul-de-sac', type: 'Cds' },
  { name: 'Drive', type: 'Dr' },
  { name: 'Esplanade', type: 'Esp' },
  { name: 'Green', type: 'Grn' },
  { name: 'Grove', type: 'Gr' },
  { name: 'Highway', type: 'Hwy' },
  { name: 'Junction', type: 'Jnc' },
  { name: 'Lane', type: 'Lane' },
  { name: 'Link', type: 'Link' },
  { name: 'Mews', type: 'Mews' },
  { name: 'Parade', type: 'Pde' },
  { name: 'Place', type: 'Pl' },
  { name: 'Ridge', type: 'Rdge' },
  { name: 'Road', type: 'Rd' },
  { name: 'Square', type: 'Sq' },
  { name: 'Street', type: 'St' },
  { name: 'Terrace', type: 'Tce' },
];

export const AUSTRALIA_STATES = [
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
  'Australian Capital Territory',
  'Jervis Bay Territory',
  'Northern Territory',
];

export const TRANSACTION_CONSTANTS = {
  heading: 'Transactions',
  date: 'DD/MM/YYYY',
  buttonText: 'RETRY',
};

export type AgencyCode = 'IPG' | 'PBS' | 'NAX' | 'IMP' | 'LSI' | 'YBZ' | 'STNDALN' | '';
