import {
  AuthDetails,
  AuthDetailsResponse,
  FindUserPayload,
  FindUserResponse,
  ForgotPasswordRequest,
  LoginRequest,
  PasswordRequest,
  RegisterUserRequest,
} from '../../models/auth/auth';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class AuthService {
  public login(request: LoginRequest): Promise<AuthDetailsResponse> | AuthDetailsResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.login);
    return service.post<AuthDetailsResponse>({}, request);
  }

  public sendEmail(request: ForgotPasswordRequest) {
    const service: ApiService = new ApiService(ServiceType.ForgotPassword);
    const path: QueryPath = {
      route: [],
      query: null,
    };
    return service.post<any>(path, request, true);
  }

  public registerAndLogin(
    request: RegisterUserRequest,
  ): Promise<AuthDetailsResponse> | AuthDetailsResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.RegisterAndLogin);
    return service.post<AuthDetailsResponse>({}, request);
  }

  // Apply
  public getApplyUserDetails(
    payload: FindUserPayload,
  ): Promise<FindUserResponse> | FindUserResponse {
    const applyAuth: ApiServiceBase = new ApiService(ServiceType.ApplyUserDetails);
    return applyAuth.get({
      query: { username: payload.username },
    });
  }

  public logout(): Promise<any> {
    const applyAuth: ApiServiceBase = new ApiService(ServiceType.profile);
    return applyAuth.post(
      {
        route: ['logout'],
      },
      {},
    );
  }

  public resetPassword(request: PasswordRequest): Promise<void> | void {
    const service: ApiService = new ApiService(ServiceType.PasswordReset);
    return service.post({}, request);
  }
}

const authService = new AuthService();
export default authService;
