import React, { FC, useState, useEffect } from 'react';
import searchLogo from '../../assets/navigation/search.png';
import arrowBack from '../../assets/navigation/arrow-left.png';
import cross from '../../assets/navigation/close.png';
import { AddressValue } from '../../helpers/AddressValue.data';
import { GooglePlacesHelper } from '../../helpers/GooglePlacesHelper';
import { useStyles } from './AddressPickerStyles';
import { Input, InputAdornment } from '@mui/material';

interface AddressPickerProps {
  id: string;
  value?: AddressValue | undefined | null;
  disabled?: boolean | undefined | null;
  placeholder?: string | undefined | null;
  fixSubPremise?: boolean | undefined | null; // default: true, if true, calls GooglePlacesHelper.fixAddressSubPremise()
  handleAddressChanged?: ((value: AddressValue) => void) | undefined | null;
  onKeyPress: (text: string) => void;
}

const AddressPicker: FC<AddressPickerProps> = ({
  id,
  value,
  disabled,
  placeholder,
  fixSubPremise,
  handleAddressChanged,
  onKeyPress,
}) => {
  let addressInput: HTMLInputElement;
  const [address, setAddress] = useState(value ? value : new AddressValue());
  const classes = useStyles();

  useEffect(() => {
    GooglePlacesHelper.createGooglePlacesAutoComplete(addressInput, (value) =>
      addressChanged(value, true),
    );
  }, []);

  const addressChanged = (value: AddressValue, updateAddress = false) => {
    if (fixSubPremise !== false) {
      value = GooglePlacesHelper.fixAddressSubPremise(value);
    }
    setAddress(value);
    if (handleAddressChanged && updateAddress) {
      handleAddressChanged(value);
    }
  };

  return (
    <div className={classes.searchContainer}>
      <Input
        id={id}
        disableUnderline={true}
        name={id}
        value={AddressValue.format(address)}
        onChange={(e: React.ChangeEvent) => {
          addressChanged({
            inputValue: (e.target as HTMLInputElement).value,
            place: null,
          });
          onKeyPress((e.target as HTMLInputElement).value);
        }}
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        readOnly={!!disabled}
        inputProps={{
          maxLength: 200,
        }}
        placeholder={placeholder || undefined}
        startAdornment={
          <InputAdornment
            position="end"
            className={classes.searchIcon}
            onClick={() => {
              if (!!address.inputValue) {
                addressChanged({
                  inputValue: '',
                  place: null,
                });
              }
            }}
          >
            <img
              src={!!address.inputValue ? arrowBack : searchLogo}
              className={classes.searchLogo}
              style={!!address.inputValue ? { height: 25, width: 25 } : {}}
              alt={'Search Logo'}
            />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            className={classes.crossIcon}
            onClick={() => {
              addressChanged({
                inputValue: '',
                place: null,
              });
            }}
          >
            {!!address.inputValue && (
              <img
                src={cross}
                className={classes.searchLogo}
                style={{ height: 25, width: 25 }}
                alt={'Search Logo'}
              />
            )}
          </InputAdornment>
        }
        inputRef={(elem: HTMLInputElement) => (addressInput = elem)}
        className={classes.root}
        fullWidth
      />
    </div>
  );
};

export default AddressPicker;
