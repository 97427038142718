/* eslint-disable no-case-declarations */
import { cloneDeep, uniqBy } from 'lodash';
import { AdditionalPersonalDetailsScreenState } from '../state/AdditionalPersonalDetailsScreenState';
import {
  AdditionalPersonalDetailsScreenAction,
  AdditionalPersonalDetailsScreenActionTypes,
  DeleteAdditionalPersonDetail,
} from '../actions/AdditionalPersonalDetailsAction';
import {
  PostPersonalDetailsFormData,
  PostCurrentIncomeFormData,
  PostEmploymentFormData,
  PostPetsFormData,
  PostAddressFormData,
  PostReferencesFormData,
  PostIdentificationFormData,
  PostDeclarationFormData,
  IntroFormData,
  ApplicantProfile,
} from '../../models/masterProfile/MyProfileFormState';
import {
  Applicants,
  DeleteAdditionalPersonPayload,
  DependentProfile,
} from '../../models/masterProfile/MasterProfile';
import { PostVehiclesFormData } from '../state/MyProfileFormState';

export const initialState: AdditionalPersonalDetailsScreenState = {
  secondaryApplicantProfiles: [],
  dependentProfiles: [],
};

export function additionalPersonalDetailScreenReducer(
  state = initialState,
  action: AdditionalPersonalDetailsScreenAction,
) {
  state = postFormDetailsReducer(cloneDeep(state), action);
  return state;
}

// tslint:disable-next-line: cyclomatic-complexity
function postFormDetailsReducer(
  state = initialState,
  action: AdditionalPersonalDetailsScreenAction | DeleteAdditionalPersonDetail,
) {
  let length;
  let tenantId;
  let refId: string | null | undefined;

  switch (action.type) {
    case AdditionalPersonalDetailsScreenActionTypes.POST_ADDITIONAL_PROFILE_FORM_REQUEST:
      state.dependentProfiles = uniqBy(state.dependentProfiles, 'key');
      const dependentData = action.data as DependentProfile;

      const dependentAlreadyPresent = state.dependentProfiles.findIndex(
        (e) => e.key === dependentData.key,
      );
      if (dependentAlreadyPresent > -1) {
        state.dependentProfiles[dependentAlreadyPresent] = action.data as DependentProfile;
      } else {
        state.dependentProfiles.push(action.data as DependentProfile);
      }

      return {
        ...state,
        loading: false,
      };

    case AdditionalPersonalDetailsScreenActionTypes.DELETE_ADDITIONAL_PROFILE:
      const afterDeletion = state.dependentProfiles.filter(
        (tenant: DependentProfile, index: number) =>
          index !== (action.data as DeleteAdditionalPersonPayload).id,
      );
      return {
        ...state,
        loading: false,
        dependentProfiles: afterDeletion,
      };

    case AdditionalPersonalDetailsScreenActionTypes.DELETE_SECONDARY_PROFILE:
      const afterSecondaryDataDeletion = state.secondaryApplicantProfiles.filter(
        (tenant: ApplicantProfile, index: number) =>
          index !== (action.data as DeleteAdditionalPersonPayload).id,
      );
      return {
        ...state,
        loading: false,
        secondaryApplicantProfiles: afterSecondaryDataDeletion,
      };

    case AdditionalPersonalDetailsScreenActionTypes.DELETE_ALL_ADDITIONAL_PROFILE:
      return {
        ...state,
        loading: false,
        dependentProfiles: [],
      };

    case AdditionalPersonalDetailsScreenActionTypes.DELETE_ALL_SECONDARY_PROFILE:
      return {
        ...state,
        loading: false,
        secondaryApplicantProfiles: [],
      };

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_MY_PROFILE_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].personalDetails =
          action.data as PostPersonalDetailsFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          personalDetails: action.data as PostPersonalDetailsFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_INTRODUCTION_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].introDetails =
          action.data as IntroFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          introDetails: action.data as IntroFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_CURRENT_INCOME_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].currentIncomeDetails =
          action.data as PostCurrentIncomeFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          currentIncomeDetails: action.data as PostCurrentIncomeFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_EMPLOYMENT_FORM_REQUEST: {
      refId = (action as AdditionalPersonalDetailsScreenAction).refId;

      if (refId) {
        state.secondaryApplicantProfiles.forEach((applicantData) => {
          if (applicantData.refId === refId) {
            applicantData.employmentDetails = action.data as PostEmploymentFormData;
          }
        });
        return {
          ...state,
        };
      }

      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].employmentDetails =
          action.data as PostEmploymentFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          employmentDetails: action.data as PostEmploymentFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_PETS_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].petsDetails =
          action.data as PostPetsFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          petsDetails: action.data as PostPetsFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_VEHICLES_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        (
          state.secondaryApplicantProfiles[secondaryAlreadyPresent] as ApplicantProfile
        ).vehiclesDetails = action.data as PostVehiclesFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          vehiclesDetails: action.data as PostVehiclesFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_ADDRESS_FORM_REQUEST: {
      refId = (action as AdditionalPersonalDetailsScreenAction).refId;

      if (refId) {
        state.secondaryApplicantProfiles.forEach((applicantData) => {
          if (applicantData.refId === refId) {
            applicantData.addressDetails = action.data as PostAddressFormData;
          }
        });
        return {
          ...state,
        };
      }

      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].addressDetails =
          action.data as PostAddressFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          addressDetails: action.data as PostAddressFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_REFERENCE_FORM_REQUEST: {
      refId = (action as AdditionalPersonalDetailsScreenAction).refId;

      if (refId) {
        state.secondaryApplicantProfiles.forEach((applicantData) => {
          if (applicantData.refId === refId) {
            applicantData.referencesDetails = action.data as PostReferencesFormData;
          }
        });
        return {
          ...state,
        };
      }

      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].referencesDetails =
          action.data as PostReferencesFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          referencesDetails: action.data as PostReferencesFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_IDENTIFICATION_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].identificationDetails =
          action.data as PostIdentificationFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          identificationDetails: action.data as PostIdentificationFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_DECLARATION_FORM_REQUEST: {
      state.secondaryApplicantProfiles = uniqBy(
        state.secondaryApplicantProfiles,
        'personalDetails.key',
      );

      const secondaryAlreadyPresent = state.secondaryApplicantProfiles.findIndex(
        (e) =>
          e.personalDetails &&
          e.personalDetails.key === (action as AdditionalPersonalDetailsScreenAction).id,
      );
      if (secondaryAlreadyPresent > -1) {
        state.secondaryApplicantProfiles[secondaryAlreadyPresent].declarationDetails =
          action.data as PostDeclarationFormData;
      } else {
        state.secondaryApplicantProfiles.push({
          declarationDetails: action.data as PostDeclarationFormData,
        } as ApplicantProfile);
      }

      return {
        ...state,
      };
    }

    case AdditionalPersonalDetailsScreenActionTypes.POST_EMAIL_SECONDARY_PROFILE:
      length = state.secondaryApplicantProfiles ? state.secondaryApplicantProfiles.length : 0;
      tenantId = (action as AdditionalPersonalDetailsScreenAction).id;

      if (typeof tenantId === 'string') {
        tenantId = Number.parseInt(tenantId, 10);
      }
      if (
        typeof tenantId !== 'undefined' &&
        !Number.isNaN(tenantId) &&
        tenantId < length &&
        action.data
      ) {
        state.secondaryApplicantProfiles[tenantId].personalDetails!.email = (
          action.data as Applicants
        ).email;
      }

      return {
        ...state,
      };

    case AdditionalPersonalDetailsScreenActionTypes.TOGGLE_MANAGE_FLAG:
      const data: any = state.secondaryApplicantProfiles;
      let selectedId: number | undefined;
      data.forEach((a: any, index: number) => {
        if (a.personalDetails.key === (action as any).data.key) {
          selectedId = index;
        }
      });
      if (selectedId || selectedId === 0) {
        data[selectedId].personalDetails.isManaged = (action as any).data.value;
      }
      return {
        ...state,
        loading: false,
        secondaryApplicantProfiles: data,
      };
    case AdditionalPersonalDetailsScreenActionTypes.ADDITIONAL_REF_ID_REQUEST:
      tenantId = (action as AdditionalPersonalDetailsScreenAction).id;

      // TODO: tenantId shouldn't be string.
      if (typeof tenantId === 'string') {
        tenantId = Number.parseInt(tenantId);
      }

      if (state.secondaryApplicantProfiles.length > 0) {
        state.secondaryApplicantProfiles[tenantId! - 1].refId = action.data as string | null;
      }

      return {
        ...state,
      };

    default:
      return state;
  }
}
