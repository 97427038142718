import { store } from '../store/Store';

// checking app name from url, if it is not there,using sorted as default.
export const isSorted =
  window.location.href.split('?').length >= 2
    ? window.location.href.split('?')[1].split('=').length >= 2
      ? window.location.href.split('?')[1].split('=')[1] !== 'NAX'
      : true
    : true;

export enum AGENCIES {
  SORTED = 'SORTED',
  NAX = 'NAX',
  MM = 'MM',
  SWITCH = 'SWITCH',
  SWITCH_VIC = 'SWITCH_VIC',
  SWITCH_SA = 'SWITCH_SA',
  SWITCH_WA = 'SWITCH_WA',
  SWITCH_NSW = 'SWITCH_NSW',
}

export const APP_URL_CHECKER = window.location.href.includes('SORTED')
  ? AGENCIES.SORTED
  : window.location.href.includes('NAX')
  ? AGENCIES.NAX
  : window.location.href.includes('SWITCH') || window.location.href.includes('AU_SWIT')
  ? AGENCIES.SWITCH
  : AGENCIES.SORTED;

export const getPageTitle = (location: string) => {
  const prefix = 'Sorted | ';
  return (
    prefix +
    (() => {
      switch (true) {
        default:
          return 'Home';
      }
    })()
  );
};
