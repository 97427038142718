/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { get, isNil } from 'lodash';
import { AvailableServicesResponse, Services, ServiceTypes } from '../../models/services/services';
import { AccountStatus, summaryScreenValidStates } from '../../constants/AccountStatus';
import { onboardingUrls } from '../../constants/OnboardingURLs';
import { createPreopen } from '../../helpers/CheckoutHelper';
import Tile from '../tile/Tile';
import ServiceIcon from '../serviceIcon/ServiceIcon';
import EmptyServiceCard from '../emptyServiceCard/EmptyServiceCard';
import ICON_ADD_SERVICE from '../../assets/plus.png';
import ServiceCard from '../serviceCard/ServiceCard';
import { navigate } from '../../navigation/NavigationUtils';

interface BroadbandCardProps {
  addressConfirmed: boolean;
  service: Services;
  extraServiceInfo: AvailableServicesResponse;
  propertyId: number;
  showTile?: boolean;
  showIconOnly?: boolean;
  showSearchIcon?: boolean;
  parentStyles?: string;
  small?: boolean;
  coloured?: boolean;
}

const BroadbandCard: FC<BroadbandCardProps> = ({
  addressConfirmed,
  service,
  extraServiceInfo,
  propertyId,
  showTile,
  showIconOnly,
  showSearchIcon,
  parentStyles,
  small,
  coloured,
}) => {
  const shouldRedirectToSummary = (status: AccountStatus): boolean => {
    return summaryScreenValidStates.includes(status);
  };

  if (service.status === AccountStatus.NEW) {
    const url = onboardingUrls.checkoutUrl(
      propertyId,
      createPreopen(service.serviceType),
      service.serviceType,
    );
    return (
      <>
        {showTile ? (
          <Tile
            icon={extraServiceInfo.Logo.Dark}
            title="Broadband"
            subtitle="Add"
            handlePress={() => {
              navigate('checkoutSelect', {
                requiredServiceTypes: service.serviceType,
              });
            }}
          />
        ) : (
          <>
            {showIconOnly ? (
              <ServiceIcon
                icon={extraServiceInfo.Logo.Dark}
                name="Broadband"
                parentStyles={parentStyles}
                coloured={coloured}
                handlePress={() => {
                  navigate('checkoutSelect', {
                    requiredServiceTypes: service.serviceType,
                  });
                }}
              />
            ) : (
              <EmptyServiceCard
                serviceName="Broadband"
                leftIcon={extraServiceInfo.Logo.Dark}
                serviceInfo={service}
                suppliers={extraServiceInfo.Suppliers}
                rightIcon={ICON_ADD_SERVICE}
                redirectTo="checkoutSelect"
                redirectProps={{
                  requiredServiceTypes: service.serviceType,
                }}
              />
            )}
          </>
        )}
      </>
    );
  } else {
    // For the summary screens
    const lookupRequired = true;
    const addressConfirmed = false;
    let url = '';
    if (service.status === AccountStatus.INPROGRESS) {
      if (!isNil(extraServiceInfo.Suppliers)) {
        if (extraServiceInfo.Suppliers.length === 1) {
          if (extraServiceInfo.Suppliers[0].SignupFlow === 'V1') {
            url = onboardingUrls.broadbandSignUpUrl(propertyId, service.serviceAccountId);
          } else {
            url = onboardingUrls.commonServiceOverview(
              service.serviceType,
              propertyId.toString(),
              service.providerId,
              addressConfirmed.toString(),
              lookupRequired.toString(),
              get(service, 'serviceAccountId', null),
            );
          }
        } else if (isNil(service.category)) {
          url = onboardingUrls.broadbandSignUpUrl(propertyId, service.serviceAccountId);
        } else {
          url = onboardingUrls.commonServiceOverview(
            service.serviceType,
            propertyId.toString(),
            service.providerId,
            addressConfirmed.toString(),
            lookupRequired.toString(),
            get(service, 'serviceAccountId', ''),
          );
        }
      } else {
        url = onboardingUrls.broadbandSignUpUrl(propertyId, service.serviceAccountId);
      }
    } else {
      url = onboardingUrls.commonServiceSummary(propertyId, service.serviceAccountId);
    }

    return (
      <>
        {showTile ? (
          <Tile
            icon={extraServiceInfo.Logo.Dark}
            active
            title="Broadband"
            status="Pending"
            subtitle={service.provider}
            handlePress={() => {
              navigate(
                shouldRedirectToSummary(service.status)
                  ? service.category === null
                    ? 'serviceSummary'
                    : 'genericServiceSummaryScreen'
                  : 'onboarding',
                shouldRedirectToSummary(service.status)
                  ? {
                      serviceType: ServiceTypes.Broadband,
                      serviceAccountId: service.serviceAccountId,
                      propertyId,
                    }
                  : { url },
              );
            }}
          />
        ) : (
          <>
            {showIconOnly ? (
              <ServiceIcon
                icon={extraServiceInfo.Logo.Dark}
                name="Broadband"
                coloured={coloured}
                handlePress={() => {
                  // ActionHelper.redirectTo('Onboarding', true, {
                  //   url,
                  // });
                }}
              />
            ) : (
              <>
                {showSearchIcon ? (
                  <EmptyServiceCard
                    serviceName="Broadband"
                    leftIcon={extraServiceInfo.Logo.Dark}
                    serviceInfo={service}
                    suppliers={extraServiceInfo.Suppliers}
                    rightIcon={ICON_ADD_SERVICE}
                    redirectTo={
                      shouldRedirectToSummary(service.status)
                        ? service.category === null
                          ? 'serviceSummary'
                          : 'genericServiceSummaryScreen'
                        : 'onboarding'
                    }
                    redirectProps={
                      shouldRedirectToSummary(service.status)
                        ? {
                            serviceType: ServiceTypes.Broadband,
                            serviceAccountId: service.serviceAccountId,
                            propertyId,
                          }
                        : { url }
                    }
                  />
                ) : (
                  <ServiceCard
                    serviceName="Broadband"
                    icon={extraServiceInfo.Logo.Dark}
                    iconFilled={extraServiceInfo.Logo.Dark}
                    serviceProviderDetail={service.provider}
                    cost={service.monthlyCost}
                    status={service.status}
                    billingPeriod={service.paymentFrequency}
                    // If category is null, go old flow, else go new generic summary screen flow
                    redirectTo={
                      shouldRedirectToSummary(service.status)
                        ? service.category === null
                          ? 'serviceSummary'
                          : 'genericServiceSummaryScreen'
                        : 'onboarding'
                    }
                    redirectProps={
                      shouldRedirectToSummary(service.status)
                        ? {
                            serviceType: ServiceTypes.Broadband,
                            serviceAccountId: service.serviceAccountId,
                            propertyId,
                          }
                        : { url }
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
};

export default BroadbandCard;
