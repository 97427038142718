import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './ErrorScreenStyles';
import { LABELS } from './ErrorScreenConstants';
import Text from '../../components/text/Text';
import { extractParams, resetNavigation } from '../../navigation/NavigationUtils';
import { ErrorScreenParams } from '../../navigation/NavigationConstants';
import Button from '../../components/button/Button';

interface ErrorScreenProps {
  handleNext?: () => void;
}

const ErrorScreen: FC<ErrorScreenProps> = ({ handleNext }) => {
  const classes = useStyles();
  const { buttonText } = extractParams<ErrorScreenParams>();

  const handleNextHandler = () => {
    resetNavigation('home');
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <div className={classes.centerPageContent}>
            <Text textVariant="title">{LABELS.TITLE}</Text>
            <div className={classes.subtitle}>{LABELS.SUBTITLE}</div>
            <Button parentStyles={classes.button} onPress={handleNext || handleNextHandler}>
              {buttonText || LABELS.BUTTON}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
