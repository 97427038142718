/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ServiceActionTypes, ServiceActions } from '../../../../../../store/actions/ServiceActions';
import TileContainer from '../../../../../../components/tileContainer/TileContainer';
import { navigate } from '../../../../../../navigation/NavigationUtils';
import { loadingSelector } from '../../../../../../store/selectors/LoadingSelector';
import { CimetActionTypes } from '../../../../../../store/actions/CimetActions';
import { filterCimetPurchasedServices } from '../../DashboardUtils';
import { activeService } from '../../../../../../constants/AccountStatus';
import { useDashboardServices } from '../../../../../../helpers/ServiceInfoHelper';
import { Tab, filterServicesBy } from '../../../../../browse/components/services/ServicesUtils';
import {
  ActiveServicesPaymentSummaryRequest,
  ActiveServicesPaymentSummaryResponse,
  ServiceTypes,
  Services,
} from '../../../../../../models/services/services';
import { ApplicationState } from '../../../../../../store/RootReducer';
import Tile from '../../../../../../components/tile/Tile';
import { getDarkServiceTypeIcon } from '../../DashboardConstants';
import { useStyles } from './ActiveServicesStyles';
import ProgressTile from '../../../../../../components/progressTile/ProgressTile';

interface ActiveServicesProps {
  activeServicePaymentSummaries: ActiveServicesPaymentSummaryResponse[] | undefined;
  loading: boolean;
  propertyId: number;
  getActiveServicesPaymentSummary: (data: ActiveServicesPaymentSummaryRequest) => void;
}

const ActiveServices: FC<ActiveServicesProps> = ({
  activeServicePaymentSummaries,
  loading,
  propertyId,
  getActiveServicesPaymentSummary,
}) => {
  const { rentServices, openServices, cimetPurchasedServices } = useDashboardServices();
  const classes = useStyles();

  const servicesToCall = useMemo<Services[]>(() => {
    return filterServicesBy(Tab.Active, [...rentServices, ...openServices]);
  }, [openServices, rentServices]);

  const missingServices = useMemo<Services[]>(() => {
    if (activeServicePaymentSummaries && !!activeServicePaymentSummaries.length) {
      const activeServiceTypes = activeServicePaymentSummaries.map(
        (summary) => summary.serviceType,
      );
      return servicesToCall.filter((service) => !activeServiceTypes.includes(service.serviceType));
    } else {
      return servicesToCall;
    }
  }, [servicesToCall, activeServicePaymentSummaries]);

  useEffect(() => {
    const serviceAccountIds = servicesToCall.map((s) => s.serviceAccountId.toString());
    getActiveServicesPaymentSummary({ serviceAccountIds });
  }, [servicesToCall]);

  const navigateOnPress = (service: ActiveServicesPaymentSummaryResponse | Services) => {
    const findService = servicesToCall.find((s) => s.serviceType === service.serviceType)!;
    if (service.serviceType === 'Rent') {
      navigate('propertyRent', {
        serviceAccountId: findService.serviceAccountId,
      });
    } else {
      navigate(findService.category === null ? 'serviceSummary' : 'genericServiceSummaryScreen', {
        serviceType: service.serviceType,
        serviceAccountId: findService.serviceAccountId,
        propertyId,
      });
    }
  };

  return (
    <>
      {loading ? (
        <TileContainer title="Active services">
          <div className={classes.activeServicesGrid}>
            {[...Array(3).fill(null)].map((_, i) => (
              <Tile icon="" key={i} title="" subtitle="" loading handlePress={() => null} />
            ))}
          </div>
        </TileContainer>
      ) : (
        <>
          {((activeServicePaymentSummaries && !!activeServicePaymentSummaries.length) ||
            !!missingServices.length ||
            (!!cimetPurchasedServices.length &&
              !!filterCimetPurchasedServices(activeService, cimetPurchasedServices).length)) && (
            <TileContainer title="Active services">
              <div className={classes.activeServicesGrid}>
                {activeServicePaymentSummaries &&
                  !!activeServicePaymentSummaries.length &&
                  activeServicePaymentSummaries.map((service, i) => (
                    <>
                      {service.paymentFrequency !== null ? (
                        <ProgressTile
                          key={i}
                          service={service}
                          handlePress={() => navigateOnPress(service)}
                        />
                      ) : (
                        <Tile
                          key={i}
                          icon={getDarkServiceTypeIcon(service.serviceType as ServiceTypes)}
                          title={service.serviceType}
                          subtitle="Active"
                          active
                          handlePress={() => navigateOnPress(service)}
                        />
                      )}
                    </>
                  ))}
                {missingServices.map((service, i) => (
                  <Tile
                    key={i}
                    icon={getDarkServiceTypeIcon(service.serviceType)}
                    title={service.serviceType}
                    subtitle="Active"
                    active
                    handlePress={() => navigateOnPress(service)}
                  />
                ))}
                {filterCimetPurchasedServices(activeService, cimetPurchasedServices).map(
                  (service, i) => (
                    <Tile
                      key={i}
                      icon={getDarkServiceTypeIcon(service.serviceType)}
                      title={service.serviceType}
                      subtitle={service.salesInfo.providerName}
                      active
                      handlePress={() =>
                        navigate('cimetScreen', { serviceType: service.serviceType })
                      }
                    />
                  ),
                )}
              </div>
            </TileContainer>
          )}
        </>
      )}
    </>
  );
};

const loading = loadingSelector([
  ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY,
  CimetActionTypes.GET_CIMET_SERVICES,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  activeServicePaymentSummaries: state.services.activeServicePaymentSummaries,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getActiveServicesPaymentSummary: (data: ActiveServicesPaymentSummaryRequest) =>
    dispatch(ServiceActions.getActiveServicesPaymentSummaryRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveServices);
