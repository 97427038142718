import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    actionCard: {
      backgroundColor: theme.colors.lightPurple,
      marginTop: theme.units.spacing * 3,
      borderRadius: 8,
    },
    actionCardWrapper: {
      padding: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    icon: {
      width: theme.units.iconSize * 1.4,
      height: theme.units.iconSize * 1.4,
      marginRight: theme.units.spacing * 4,
    },
    title: {
      color: theme.colors.black87,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginRight: 'auto',
    },
    button: {
      padding: theme.units.spacing * 2,
      paddingLeft: theme.units.spacing * 5,
      paddingRight: theme.units.spacing * 5,
      backgroundColor: theme.colors.light,
      borderRadius: 4,
      border: `${theme.colors.borderColorLight} 1px solid`,
      color: theme.colors.black87,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
    },
  })();
};
