/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import {
  OnboardingPropertyDetails,
  PropertyClass,
  PropertyType,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/property';
import { useStyles } from './SelectRoleStyles';
import tick from '../../../../assets/tick.png';
import { LABELS } from './SelectRoleConstants';
import { classNameGenerator } from '../../../../theme/GlobalStyles';

interface SelectRoleProps {
  selectedClass: PropertyClass | undefined;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
}

const SelectRole: FC<SelectRoleProps> = ({ selectedClass, setPropertyDetails }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Tenant });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedClass === PropertyClass.Tenant && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.RENTING}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${
              selectedClass === PropertyClass.Tenant ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Owner });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedClass === PropertyClass.Owner && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.OWNER}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${selectedClass === PropertyClass.Owner ? classes.tick : classes.hideItem}`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Landlord });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedClass === PropertyClass.Landlord && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.LANDLORD}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${
              selectedClass === PropertyClass.Landlord ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedClass', value: PropertyClass.Accommodation });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedClass === PropertyClass.Accommodation && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <span className={classes.text}>{LABELS.HOLIDAY}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${
              selectedClass === PropertyClass.Accommodation ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectRole;
