import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ServiceTabsStyles';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { AvailableServicesResponse, PageName } from '../../../../models/checkout/Checkout';
import { getColouredIcon } from '../../../browse/components/marketplace/MarketplaceUtils';
import { getColourFilter } from '../../../../helpers/ColourHelper';
import { getDarkServiceTypeIcon } from '../../../home/components/dashboard/DashboardConstants';
import { ServiceTypes } from '../../../../models/services/services';
import { LABELS } from './ServiceTabsConstants';
import tick from '../../../../assets/tick.png';
import { useCart } from '../../../../helpers/CheckoutHelper';
import { useDashboardServices } from '../../../../helpers/ServiceInfoHelper';
import { getServiceIconForCheckout } from './ServiceTabsUtils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { DashboardState } from '../../../../store/state/DashboardState';

interface ServiceTabsProps {
  activeServices: AvailableServicesResponse[];
  selectedTab: number;
  pageName: PageName;
  setSelectedTab: (value: number) => void;
  overrideCompleted?: boolean;
}

const ServiceTabs: FC<ServiceTabsProps> = ({
  activeServices,
  selectedTab,
  pageName,
  setSelectedTab,
  overrideCompleted,
}) => {
  const classes = useStyles();
  const { cartItems } = useCart();
  const { dashboardState } = useSelector<ApplicationState, { dashboardState: DashboardState }>(
    (state: ApplicationState) => ({
      dashboardState: state.dashboardState,
    }),
  );

  const isCompleted = (cartItem: AvailableServicesResponse) => {
    switch (pageName) {
      case PageName.CHECKOUTSELECT:
        return (
          !!cartItem.suppliers &&
          !!cartItem.suppliers.filter((supplier) => supplier.selected).length
        );
      case PageName.CHECKOUTCONFIGURE:
        return (
          cartItem && cartItem.complete && cartItem.complete!.options && cartItem.complete!.terms
        );
      case PageName.CHECKOUTFINALLIZE:
        return (
          cartItem &&
          cartItem.complete &&
          cartItem.complete!.personalDetails &&
          cartItem.complete!.payment
        );
      case PageName.CHECKOUTCOMPARE:
        return false;
      default:
        return false;
    }
  };

  return (
    <div className={classes.container}>
      {([PageName.CHECKOUTSELECT, PageName.CHECKOUTFINALLIZE].includes(pageName)
        ? activeServices
        : cartItems
      ).map((item, idx) => {
        const colours = getColouredIcon(item.type.replace(/([A-Z][A-Z\-]*)/g, ' $1').trim());
        const filterPrimary = getColourFilter(colours.primary);
        return (
          <div
            key={idx}
            className={classNameGenerator([
              classes.tabWrapper,
              idx === selectedTab && classes.selected,
            ])}
            onClick={() => setSelectedTab(idx)}
          >
            <div
              className={
                isCompleted(item) || overrideCompleted
                  ? classes.completeLabel
                  : idx === selectedTab
                  ? classes.selectedLabel
                  : classes.label
              }
            >
              {isCompleted(item) || overrideCompleted ? (
                <>
                  <img src={tick} className={classes.tick} />
                  {LABELS.COMPLETE}
                </>
              ) : idx === selectedTab ? (
                <>{LABELS.IN_PROGRESS}</>
              ) : (
                <>{LABELS.TODO}</>
              )}
            </div>
            <div className={classes.tab}>
              <img
                src={getServiceIconForCheckout(item, dashboardState)}
                style={filterPrimary ? { filter: filterPrimary } : {}}
                className={classes.serviceIcon}
              />
              <div className={classes.text}>
                {item.type.replace(/([A-Z][A-Z\-]*)/g, ' $1').trim()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceTabs;
