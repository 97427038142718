import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PackCardStyles';
import { LABELS } from './PackCardConstants';
import Button from '../button/Button';

interface PackCardProps {
  title: string;
  background: string;
  description: string;
  icons: string[];
  onPress: () => void;
}

const PackCard: FC<PackCardProps> = ({ title, background, description, icons, onPress }) => {
  const classes = useStyles();
  return (
    <div className={classes.packContainer}>
      <div className={classes.packBackgroundContainer}>
        <img src={background} className={classes.background} alt="background" />
      </div>
      <div className={classes.content}>
        <div className={classes.label}>{LABELS.LABEL}</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
        <div className={classes.footer}>
          <div className={classes.icons}>
            {icons.map((icon, idx) => (
              <div key={idx} className={classes.iconContainer}>
                <img src={icon} className={classes.icon} alt="icon" />
              </div>
            ))}
          </div>
          <Button outline parentStyles={classes.button} onPress={onPress}>
            {LABELS.BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackCard;
