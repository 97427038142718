export const LABELS = {
  TITLE: 'Account',
  PERSONAL_DETAILS_SEGMENT: 'Personal details',
  EDIT: 'Edit',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  YOUR_APPLICATION_PROFILES: 'My application profiles',
  LOGIN_SETTINGS_SEGMENT: 'Login Settings',
  PASSCODE: 'Login with passcode',
  SUPPORT_SEGMENT: 'Support',
  NOTIFICATIONS: 'Notifications',
  CHAT: 'Chat',
  FEEDBACK: 'Feedback',
  FAQ: 'FAQ',
  ABOUT: 'About Sorted',
  ABOUT_NAX: 'About NAX',
  ABOUT_SWITCH: 'About The Switch',
  EMERGENCY: 'Faults and emergencies',
  TERMS: 'Terms of use',
  VERSION: 'Sorted V',
  VERSION_NAX: 'NAX V',
  LOGOUT: 'LOGOUT',
  PRIVACY: 'Privacy Policy',
};

export const URLS = {
  CONTACT: 'https://www.sortedservices.com/contact',
  FAQ: 'https://help.sortedservices.com/',
  ABOUT: 'https://www.sortedservices.com/about',
  TERMS: 'https://www.sortedservices.com/terms-of-use',
  EMERGENCY: 'https://www.sortedservices.com/faults-and-emergencies',
};

export const naxUrls = {
  CONTACT: 'https://www.nelsonalexander.com.au/feedback/',
  FAQ: 'https://www.nelsonalexander.com.au/what-to-know-when-renting-a-nelson-alexander-property/',
  ABOUT: 'https://www.nelsonalexander.com.au/about/',
  TERMS:
    'https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/5c9190189140b7d5faf3e12c/1553043482940/Sorted-Services-Customer-Terms-Conditions.pdf',
  PRIVACY:
    'https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/5d40dee75545900001568399/1564532841824/Sorted-Services-Privacy-Policy.pdf',
};

export const mmUrls = {
  CONTACT: 'https://www.mmgr.com.au/contact-us',
};

export const switchUrls = {
  FEEDBACK: 'https://www.switchliving.com.au/contact-us/',
  FAQ: 'https://www.switchliving.com.au/how-to-switch/',
  ABOUT: 'https://www.switchliving.com.au/',
  TERMS: 'https://www.switchliving.com.au/terms-of-use/',
  PRIVACY: 'https://www.switchliving.com.au/privacy-policy/',
};

export const sortedSupportUrls = [
  {
    title: LABELS.FEEDBACK,
    link: URLS.CONTACT,
  },
  {
    title: LABELS.FAQ,
    link: URLS.FAQ,
  },
  {
    title: LABELS.ABOUT,
    link: URLS.ABOUT,
  },
  {
    title: LABELS.EMERGENCY,
    link: URLS.EMERGENCY,
  },
  {
    title: LABELS.TERMS,
    link: URLS.TERMS,
  },
];

export const naxSupportUrls = [
  {
    title: LABELS.FEEDBACK,
    link: naxUrls.CONTACT,
  },
  {
    title: LABELS.FAQ,
    link: naxUrls.FAQ,
  },
  {
    title: LABELS.ABOUT_NAX,
    link: naxUrls.ABOUT,
  },
  {
    title: LABELS.TERMS,
    link: naxUrls.TERMS,
  },
  {
    title: LABELS.PRIVACY,
    link: naxUrls.PRIVACY,
  },
];

export const mmSupportUrls = [
  {
    title: LABELS.FEEDBACK,
    link: mmUrls.CONTACT,
  },
];

export const switchSupportUrls = [
  {
    title: LABELS.FEEDBACK,
    link: switchUrls.FEEDBACK,
  },
  {
    title: LABELS.FAQ,
    link: switchUrls.FAQ,
  },
  {
    title: LABELS.ABOUT_SWITCH,
    link: switchUrls.ABOUT,
  },
  {
    title: LABELS.TERMS,
    link: switchUrls.TERMS,
  },
  {
    title: LABELS.PRIVACY,
    link: switchUrls.PRIVACY,
  },
];
