import { TabParams } from '../../navigation/NavigationConstants';
import { Route } from '../../navigation/NavigationUtils';
import { Agency } from '../agency/agency';
import { MaintenanceResponse } from '../landlord/LandlordProperty';
import { Property, PropertyClass } from '../property/property';
import { AvailableServicesResponse, Services } from '../services/services';
import { User } from '../user/user';

export interface FetchDashboardRequest {
  skipReset?: boolean;
}

export interface LeaseResponse {
  leaseId?: number;
  property: Property | undefined | null;
  secondaryTenant?: boolean;
  agency: Agency;
  primaryTenant?: User | undefined | null;
  services: Services[] | undefined;
  addressConfirmed?: boolean;
  maintenance?: MaintenanceResponse[];
  availableServices: AvailableServicesResponse[];
  propertyClass: PropertyClass | undefined | null;
}

export interface TabProps {
  index?: number;
  tab?: number;
}

export interface SwitchTab {
  tabName: Route;
  tabProps?: TabProps;
}

export enum BottomNavValue {
  home = 'home',
  browse = 'browse',
  account = 'account',
}
