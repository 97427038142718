import { Property } from '../property/property';
import { User } from '../user/user';

export interface Lease {
  id: number;
  property: Property | undefined | null;
  rentalAmount: number | undefined | null;
  bond: number | undefined | null;
  urgentRepairsAmount: number | undefined | null;
  rentalFrequency: RentalFrequency | undefined | null;
  dateAvailable: string | undefined | null;
  leaseDuration: number | undefined | null;
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  moveInDate: string | undefined | null;
  premisesProvidedWith: string | undefined | null;
  adultsPermitted: number | undefined | null;
  childrenPermitted: number | undefined | null;
  petPermitted: boolean | undefined | null;
  allowedPetsInfo: string | undefined | null;
  inspectionDate: string | undefined | null;
  payViaSorted: boolean | undefined | null;
  includeOnBond: UsersInBond[] | undefined | null;
  tenants: User[] | undefined | null;
  primaryTenant: User | undefined | null;
  leased?: boolean;
  depositAmount?: number | null;
  depositPaymentDate?: string | undefined | null;
  weeklyRent: number;
  signDate: string | null;
  secondaryTenant: User[];
  type: string;
  status: string;
  childLeaseStartDate: string | null;
  documents: LeaseDocument[];
}

export interface LeaseDocument {
  CloudLocationId: string;
  Content: string;
  DocumentType: string;
  ExtensionType: 'pdf';
  Name: string;
}

export const leaseStatus = {
  renewed: 'RENEWED',
  cancelled: 'CANCELLED',
  archived: 'ARCHIVED',
};

export enum RentalFrequency {
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
}

export interface UsersInBond {
  readonly userId: number;
  readonly onBond: boolean;
}

export interface LeaseData {
  readonly current: boolean;
  readonly daysLeft: number;
  readonly signed: boolean;
  readonly advertised: boolean;
  readonly pending: boolean;
}

export interface Tenants {
  readonly currentTenants: TenantsType[];
  readonly pendingTenants: TenantsType[];
}

export interface TenantsType {
  readonly id: number;
  readonly primaryTenant: User;
  readonly secondaryTenants: User[] | undefined | null;
  readonly startdate: string | undefined | null;
  readonly leased: boolean | undefined | null;
}
