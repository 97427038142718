import { MaintenanceRequestPayload, SaveImageResponse } from '../../models/maintenance/maintenance';
import { MaintenanceAction, MaintenanceActionTypes } from '../actions/MaintenanceActions';
import { MaintenanceState } from '../state/MaintenanceState';

export const initialState: MaintenanceState = {
  imageUUIDs: [],
  submitedRequest: {
    description: '',
    propertyId: 0,
    photos: [],
    email: '',
    phone: '',
  },
};

export const maintenanceReducer = (
  state = initialState,
  action: MaintenanceAction,
): MaintenanceState => {
  switch (action.type) {
    case MaintenanceActionTypes.SAVE_IMAGE_SUCCESS:
      state.imageUUIDs.push(action.data as SaveImageResponse);
      return {
        ...state,
        imageUUIDs: state.imageUUIDs,
      };
    case MaintenanceActionTypes.SEND_MAINTENANCE_SUCCESS:
      return {
        ...state,
        // TODO RAHUL FIX TYPES
        submitedRequest: action.data as any,
      };
    case MaintenanceActionTypes.UPDATE_IMAGE_UUID_LIST:
      return {
        ...state,
        imageUUIDs: action.data as SaveImageResponse[],
      };
    case MaintenanceActionTypes.REMOVE_MAINTENANCE_DATA:
      return {
        imageUUIDs: [],
        submitedRequest: {
          description: '',
          propertyId: 0,
          photos: [],
          email: '',
          phone: '',
        },
      };
    default:
      return state;
  }
};
