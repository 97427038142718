/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    propertyContainer: {
      borderRadius: 6,
      borderColor: theme.colors.borderColorLight,
      borderWidth: 1.5,
      borderStyle: 'solid',
      padding: 12,
      backgroundColor: theme.colors.secondary,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    content: {
      paddingHorizontal: 16,
    },
    maintenanceContainer: {
      marginTop: 6,
    },
    maintenanceRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 4,
    },
    maintenanceKey: {
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginRight: 4,
    },
    maintenanceValue: {
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      marginRight: 'auto',
      textTransform: 'capitalize',
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    titleContainer: {
      // marginRight: 'auto',
    },
    address: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    address2: {
      marginTop: 4,
      fontSize: 13,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    // RIGHT SIDE ------------------------------------------------------------- //
    rightContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    house: {
      width: 65,
      height: 65,
      resizeMode: 'cover',
      borderRadius: 8,
    },
    statusContainer: {
      paddingVertical: 3,
      borderRadius: 4,
      minWidth: 65,
      backgroundColor: theme.colors.darkGrey,
    },
    statusTextWhite: {
      textAlign: 'center',
      color: theme.colors.secondary,
      fontSize: 12,
      fontWeight: getFonts('Medium'),
    },
    statusTextBlack: {
      textAlign: 'center',
      color: theme.colors.black87,
      fontSize: 10,
      fontWeight: getFonts('Medium'),
      textTransform: 'capitalize',
    },
    completeLabel: {
      backgroundColor: theme.colors.completeLabel,
    },
    incompleteLabel: {
      backgroundColor: theme.colors.incompleteLabel,
    },
    yellowLabel: {
      backgroundColor: theme.colors.yellowLabel,
    },
    unsuccessfulLabel: {
      backgroundColor: theme.colors.darkGrey,
    },
    noMaintenanceView: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 48,
      justifyContent: 'center',
      alignItems: 'center',
    },
    noMaintenanceText: {
      color: theme.colors.black87,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      marginTop: 32,
    },
    noMaintenanceSubText: {
      color: theme.colors.black87,
      fontSize: 14,
      fontWeight: getFonts('Light'),
      marginTop: 12,
    },
    noMaintenanceImage: { width: 90, height: 82 },
    contentBox: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginTop: theme.units.spacing * 4,
      borderRadius: theme.units.borderRadius,
      paddingBottom: theme.units.spacing * 16,
    },
    loadingBox: {
      height: 140,
      marginTop: theme.units.spacing * 4,
    },
  })();
};
