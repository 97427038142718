import { AustraliaState } from '../../models/australiaStates/australiaStates';
import { MasterProfilePayload } from '../../models/masterProfile/MasterProfile';
import { StoreAction } from '../StoreHelper';

export enum MasterProfileActionTypes {
  GET_MASTER_PROFILE_REQUEST = 'GET_MASTER_PROFILE_REQUEST',
  GET_MASTER_PROFILE_SUCCESS = 'GET_MASTER_PROFILE_SUCCESS',
  GET_MASTER_PROFILE_ERROR = 'GET_MASTER_PROFILE_ERROR',

  SET_AUSTRALIA_STATE = 'SET_AUSTRALIA_STATE',
}

export type MasterProfileActionPayload =
  | MasterProfilePayload
  | Error
  | null
  | string
  | AustraliaState;

export type MasterProfileAction = StoreAction<MasterProfileActionTypes, MasterProfileActionPayload>;

export class MasterProfileActions {
  public static getMasterProfileRequest(): MasterProfileAction {
    return { type: MasterProfileActionTypes.GET_MASTER_PROFILE_REQUEST, data: null };
  }
  public static getMasterProfileSuccess(data: MasterProfilePayload): MasterProfileAction {
    return { type: MasterProfileActionTypes.GET_MASTER_PROFILE_SUCCESS, data };
  }
  public static getMasterProfileError(data: Error): MasterProfileAction {
    return { type: MasterProfileActionTypes.GET_MASTER_PROFILE_ERROR, data };
  }

  public static setAustraliaState(data: AustraliaState | null) {
    return { type: MasterProfileActionTypes.SET_AUSTRALIA_STATE, data };
  }
}
