import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    cartContainer: {
      borderRadius: theme.units.borderRadius,
      padding: theme.units.spacing * 4,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.units.spacing * 4,
    },
    hover: {
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    selectable: {
      '&:hover': {
        backgroundColor: '#fafafa',
        cursor: 'pointer',
      },
    },
    selected: {
      backgroundColor: '#fafafa',
    },
    topRow: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    serviceTypeContainer: {
      width: 64,
      height: 64,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.lightGrey,
      borderRadius: theme.units.borderRadius,
      flexShrink: 0,
    },
    serviceTypeIcon: {
      position: 'absolute',
      left: 18,
      width: theme.units.iconSize * 1.2,
      height: theme.units.iconSize * 1.2,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    titleContainer: {
      marginLeft: theme.units.spacing * 4,
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      marginBottom: theme.units.spacing,
      color: theme.colors.black87,
    },
    subtitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black38,
      marginBottom: theme.units.spacing,
    },
    description: {
      fontWeight: getFonts('Medium'),
      fontSize: 13,
      color: theme.colors.black60,
    },
    priceContainer: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    price: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.buttonPrimary,
      marginBottom: theme.units.spacing,
    },
    uom: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.black60,
    },
    actionContainer: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      cursor: 'pointer',
      ...actionGreen,
      marginLeft: theme.units.spacing * 4,
    },
    actionIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    tickContainer: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      marginLeft: theme.units.spacing * 4,
      flexShrink: 0,
    },
  })();
};
