/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { get, isNil, isUndefined } from 'lodash';
import React, { FC, useState } from 'react';
import tickWhite from '../../../../assets/tick-white.svg';
import { ServiceDetailsData } from '../../../../models/checkout/Checkout';
import { getTermsAndConditionsValue } from '../selectOptions/SelectOptionsUtils';
import { useStyles } from './SelectTermsStyles';
import { Card, Checkbox, Typography } from '@mui/material';
import { sendMessageToApp, ValidMessages } from '../../../../helpers/MessageHelper';
import {
  PreAgreementSummary,
  UIAttributes,
  AcceptanceChecks,
  Link,
} from '../../../../models/billing/Billing';
import Box from '../../../../components/box/Box';
import { theme } from '../../../../theme/Theme';

interface SelectTermsProps {
  setTermsAndConditionsFlag: (flag: boolean) => void;
  preAgreementSummary: PreAgreementSummary;
  uiModelAttributes: UIAttributes;
  cartData?: ServiceDetailsData | null;
  isExternal: boolean;
  planLinks: Link[];
}

const SelectTerms: FC<SelectTermsProps> = ({
  setTermsAndConditionsFlag,
  preAgreementSummary,
  uiModelAttributes,
  cartData,
  isExternal,
  planLinks,
}) => {
  const classes = useStyles();
  const { defaultAcceptedChecks } = getTermsAndConditionsValue(cartData, preAgreementSummary);
  const [acceptedChecks, setAcceptedChecks] = useState<number[]>(defaultAcceptedChecks);
  const links =
    !isUndefined(preAgreementSummary) && !!preAgreementSummary.LinkItems.length
      ? [...preAgreementSummary.LinkItems, ...planLinks]
      : [...planLinks];

  return (
    <>
      {!isNil(preAgreementSummary) ? (
        <>
          <div
            className={classes.terms}
            dangerouslySetInnerHTML={{
              __html: get(preAgreementSummary, 'Body', ''),
            }}
          />
          {!!links.length && (
            <div className={classes.detailCard}>
              {uiModelAttributes.Links.Value && (
                <div className={classes.switchContainer}>{uiModelAttributes.Links.Value}</div>
              )}
              {!!links.length &&
                links.map((link: Link, index) => (
                  <div
                    key={`rate-${link.Label}${index}`}
                    className={classes.link}
                    onClick={() => {
                      if (isExternal) {
                        window.open(link.Link);
                      } else {
                        sendMessageToApp(ValidMessages.OpenUrl, get(link, 'Link', ''));
                      }
                    }}
                  >
                    {get(link, 'Label', '')}
                  </div>
                ))}
            </div>
          )}
        </>
      ) : undefined}
      <div className={classes.checkboxGroup}>
        {preAgreementSummary.AcceptanceChecks.map((item: AcceptanceChecks, index: number) => (
          <div
            key={`check${index}`}
            className={classes.checkboxRow}
            onClick={async () => {
              const updatedAcceptedChecks = [...acceptedChecks];
              if (acceptedChecks.includes(index)) {
                const arrayIndex = updatedAcceptedChecks.indexOf(index);
                if (arrayIndex !== -1) {
                  updatedAcceptedChecks.splice(arrayIndex, 1);
                }
              } else {
                updatedAcceptedChecks.push(index);
              }
              if (preAgreementSummary.AcceptanceChecks.length === updatedAcceptedChecks.length) {
                setTermsAndConditionsFlag(true);
              } else {
                setTermsAndConditionsFlag(false);
              }
              setAcceptedChecks(updatedAcceptedChecks);
            }}
          >
            <Checkbox
              style={{
                flexShrink: 0,
                paddingLeft: 0,
              }}
              sx={{
                color: theme.colors.borderColor,
                '&.Mui-checked': {
                  color: theme.colors.buttonPrimary,
                },
              }}
              checked={acceptedChecks.includes(index)}
              onChange={() => null}
            />
            <div className={classes.checkboxText}>
              {get(item, 'ComponentText', 'I accept the above terms')}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SelectTerms;
