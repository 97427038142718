import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';

export const getMaintenanceLocation = (location: string) => {
  return location ? location.replace(/_/g, ' ') : '';
};

export const getMaintenanceDetailTitle = (maintenance: MaintenanceResponse) => {
  const privateWorks = maintenance.privateWorksDetails;
  const isPrivateWorks = !!(
    maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
  );
  if (isPrivateWorks && privateWorks) {
    return privateWorks.workflowType === 'PACKAGE' ? 'Package: ' : 'Title: ';
  } else {
    return 'Title: ';
  }
};
