import { Moment } from 'moment';

export const blockDatesFn = (date: Moment, blockedDates: string[], disableWeekends?: boolean) => {
  if (disableWeekends) {
    return (
      date.isoWeekday() === 6 ||
      date.isoWeekday() === 7 ||
      blockedDates.includes(date.format('DD/MM/YYYY'))
    );
  } else {
    return blockedDates.includes(date.format('DD/MM/YYYY'));
  }
};
