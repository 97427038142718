import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    flexBox: {
      flex: 1,
    },
    sectionTitle: {
      fontSize: 18,
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 18,
      marginLeft: 16,
      marginBottom: 12,
      fontWeight: getFonts('Medium'),
    },
    headerContainer: {
      position: 'relative',
    },
    header: {
      height: 200,
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: theme.units.borderRadius,
    },
    body: {
      position: 'relative',
      paddingLeft: 16,
      paddingRight: 16,
      zIndex: 1,
    },
    agencyLogo: {
      width: 200,
      position: 'absolute',
      right: 20,
      top: 20,
      zIndex: 2,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    card: {
      position: 'relative',
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      backgroundColor: theme.colors.light,
      padding: 16,
      paddingBottom: 0,
    },
    cardItem: {
      position: 'relative',
      borderRadius: theme.units.borderRadius,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16,
    },
    otherPaymentCardItem: {
      borderRadius: theme.units.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    rentCard: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      backgroundColor: theme.colors.light,
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      height: 64,
      marginTop: -36,
      zIndex: 2,
    },
    rentCardVerticalView: {
      marginLeft: 20,
      alignSelf: 'center',
    },
    serviceIcon: {
      alignSelf: 'center',
      marginLeft: 12,
      height: 32,
      width: 32,
    },
    itemLabel: {
      fontSize: 14,
      letterSpacing: 0.88,
      color: 'rgba(0,0,0, 0.38)',
    },
    otherPaymentItemLabel: {
      fontSize: 14,
      letterSpacing: 0.88,
      color: 'rgba(0,0,0, 0.38)',
      marginTop: 12,
    },
    itemValue: {
      fontSize: 16,
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 5,
    },
    documentListItemValue: {
      fontSize: 16,
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 5,
      flex: 1,
      fontWeight: getFonts('Medium'),
    },
    itemButtonBox: {
      height: '100%',
    },
    itemButton: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: 14,
      letterSpacing: 0.4,
      color: '#ff525b',
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    gridItem: {},
    tenancyItemBox: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    downloadButton: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      cursor: 'pointer',
    },
    downloadImage: {
      marginRight: 16,
      height: 24,
      width: 24,
    },
    paymentSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    logoBox: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    paymentLogoStyle: {
      width: 72,
      height: 22,
    },
    paymentBox: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    modalText: {
      fontWeight: getFonts('Light'),
      fontSize: 17,
      marginTop: 16,
    },
    duePaymentroot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: 16,
      marginTop: 24,
    },
    duePaymentHeading: { fontSize: 16, color: theme.colors.black87 },
    duepaymentlengthcircle: {
      backgroundColor: theme.colors.error,
      height: 16,
      width: 16,
      borderRadius: 16,
      marginRight: 10,
    },
    duePaymentsLength: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
      textAlign: 'center',
      marginTop: 2,
    },
    duePaymentLists: {
      marginTop: 10,
      marginBottom: 10,
    },
    duePaymentList: {
      backgroundColor: theme.colors.light,
      paddingHorizontal: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      marginBottom: 10,
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    alignRow: {
      display: 'flex',
      flexDirection: 'column',
    },
    duePaymentSubheading: {
      fontSize: 14,
      color: theme.colors.black38,
      fontWeight: getFonts('Medium'),
    },
    duePaymentStatus: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      color: theme.colors.error,
      textDecorationLine: 'underline',
    },
    margin22: { marginTop: 22 },
    payIdstatusRow: {
      marginRight: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })();
};
