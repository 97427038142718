import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    marginTop: {
      marginTop: 24,
    },
    sectionKey: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      fontSize: 14,
      marginBottom: 6,
    },
    sectionValue: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 18,
    },
    accordian: {
      width: theme.units.iconSize * 0.5,
      height: theme.units.iconSize * 0.5,
      transform: 'rotate(270deg)',
      objectFit: 'contain',
      objectPosition: 'center',
      opacity: 0.4,
    },
    logoStyle: {
      width: 40,
      height: 40,
      objectFit: 'contain',
      objectPosition: 'center',
      borderRadius: 5000,
    },
    currentPlanRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    titleContainer: {
      marginRight: 'auto',
      marginLeft: 12,
    },
    titleContainer2: {
      marginTop: 20,
    },
  })();
};
