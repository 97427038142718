import React, { FC } from 'react';
import { useStyles } from './PaymentDetailsStyles';
import { KeyValuePair } from '../../../../models/services/genericServiceSummary';
import { onboardingUrls } from '../../../../constants/OnboardingURLs';
import moment from 'moment';
import { BANK_LABEL, CREDIT_LABEL, NEXT_PAYMENT_DATE_LABEL } from './PaymentDetailsContants';
import { navigate } from '../../../../navigation/NavigationUtils';
import { ServiceTypes } from '../../../../models/services/services';
import { classNameGenerator } from '../../../../theme/GlobalStyles';

interface PaymentDetailsProps {
  data: KeyValuePair[];
  paymentRefId: string;
  serviceAccountId: number;
  propertyId: number;
  serviceType: string;
}

const PaymentDetails: FC<PaymentDetailsProps> = ({
  data,
  paymentRefId,
  serviceAccountId,
  propertyId,
  serviceType,
}) => {
  const classes = useStyles();
  const onEdit = () => {
    navigate('selectPayment', {
      propertyId: propertyId,
      serviceType: serviceType === ServiceTypes.Electricity ? 'editelectricity' : 'editbroadband',
      paymentRefId: paymentRefId,
      serviceAccountId,
      agencyName: 'Sorted_Services',
    });
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((dataObj, index) => (
          <div
            className={classNameGenerator([
              index > 0 && classes.marginTop,
              classes.textContainerLink,
            ])}
          >
            <div>
              <div className={classes.sectionKey}>{dataObj.key}</div>
              <div className={classes.sectionValue}>
                {dataObj.key.toLocaleLowerCase() === NEXT_PAYMENT_DATE_LABEL
                  ? moment(dataObj.value).format('DD/MM/YYYY')
                  : dataObj.value}
              </div>
            </div>
            {(dataObj.key.toLocaleLowerCase() === BANK_LABEL ||
              dataObj.key.toLocaleLowerCase() === CREDIT_LABEL) && (
              <div onClick={() => onEdit()}>
                <div className={classes.redLink}>Edit</div>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default PaymentDetails;
