import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AccountStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TabProps } from '../../models/dashboard/dashboard';
import Applications from './components/applications/Applications';
import Profile from './components/profile/Profile';
import Wallet from './components/wallet/Wallet';
import { LABELS } from './AccountConstants';
import TabMenu from '../../components/tabMenu/TabMenu';
import { FindUserPayload, FindUserResponse } from '../../models/auth/auth';
import { AuthActions } from '../../store/actions/AuthActions';
import {
  ApplicationsRequest,
  ApplicationsResponse,
  GroupRequest,
  GroupResponse,
} from '../../models/groups/Groups';
import { GroupsActions } from '../../store/actions/GroupsActions';
import { Profile as ProfileModel } from '../../models/user/user';
import MainBanner from '../../components/mainBanner/MainBanner';
import purplePeople from '../../assets/bannerPeople/purple.png';
import { theme } from '../../theme/Theme';

interface AccountProps extends RouteComponentProps {
  tabProps: TabProps;
  applyUserDetails: FindUserResponse;
  profile: ProfileModel;
  groups: GroupResponse[] | undefined;
  groupApplications: ApplicationsResponse | undefined;
  getGroupMasterProfile: (data: GroupRequest) => void;
  getAllApplications: (data: ApplicationsRequest) => void;
  getApplyDetails: (data: FindUserPayload) => void;
}

const Account: FC<AccountProps> = ({
  tabProps,
  applyUserDetails,
  profile,
  groups,
  groupApplications,
  getGroupMasterProfile,
  getAllApplications,
  getApplyDetails,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (applyUserDetails) {
      if (groups === undefined) {
        getGroupMasterProfile({});
      }
      if (groupApplications === undefined) {
        getAllApplications({ userId: applyUserDetails.id!.toString() });
      }
    } else {
      getApplyDetails({ username: profile.email! });
    }
  }, []);

  const tabs = [
    { title: 'Profile', view: <Profile /> },
    { title: 'Wallet', view: <Wallet /> },
    { title: 'Applications', view: <Applications /> },
  ];

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <MainBanner
          title={LABELS.ACCOUNT_TITLE}
          subtitle={LABELS.ACCOUNT_SUBTITLE}
          image={purplePeople}
          parentStyles={classes.banner}
        />
        <div className={classes.tabContainer}>
          <TabMenu tabs={tabs.map((t) => t.title)} />
        </div>
        {tabProps && tabProps.index ? tabs[tabProps.index].view : tabs[0].view}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  tabProps: state.dashboardState.tabProps,
  profile: state.profileState.profile,
  applyUserDetails: state.authState.applyAuthDetails,
  groups: state.groups.groups,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getApplyDetails: (data: FindUserPayload) => {
    dispatch(AuthActions.getApplyUserDetails(data));
  },
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  getAllApplications: (data: ApplicationsRequest) => {
    dispatch(GroupsActions.getAllApplicationsRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
