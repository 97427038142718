import { RentalFrequency } from '../../models/lease/lease';
import { Documents, Attachment, PaymentDetails } from '../../models/services/rentService';

export const LABELS = {
  TITLE: 'Rent',
  RENT_CARD_TITLE: 'RENT',
  PAYMENT_SECTION: 'Payment details',
  ADJUST: 'Adjust',
  EDIT: 'Edit',
  ADD: 'Add',
  TENANCY_SECTION: 'Tenancy summary',
  TENANCY_SECTION_INACTIVE: 'Tenancy summary (Ended)',
  INACTIVE: '(Closed)',
  TENANCY_TERM: 'TENANCY TERM',
  TENANCY_BOND: 'BOND',
  TENANCY_FROM_DATE: 'FROM DATE',
  TENANCY_TO_DATE: 'TO DATE',
  DOCUMENTS_SECTION: 'Rent documents',
  PAYMENTS: 'PAYMENTS',
  NEXT_PAYMEMT_DATE: 'NEXT PAYMENT DATE',
  BANK_ACCOUNT: 'BANK ACCOUNT',
  CREDIT_CARD: 'CREDIT CARD',
  NOTAVAILABLE: 'PAYMENT METHOD',
  ADDPAYMENTLABEL: 'Add a payment method',
  MODAL_PRIMARY_TEXT:
    'Sorry, this feature is currently under development and is temporarily disabled while we make improvements.',
  MODAL_SECONDARY_TEXT:
    'You can still adjust your payment by chatting to one of our helpful team members. Would you like to chat now?',
  RANDOMVALUE: 'random-value',
  PAYMENT_TYPE: 'PAYMENT TYPE',
  BILLER_CODE: 'BILLER CODE',
  REFERENCE_NUMBER: 'REFERENCE NUMBER',
};

export interface Agreement {
  title: string;
  url: string;
}

export const AGREEMENTS: Agreement[] = [
  {
    title: 'Sorted Services Rental Payment Agreement',
    url: 'https://www.sortedservices.com/s/Sorted-Services-Rental-Payment-Agreement.pdf',
  },
  {
    title: 'Direct Debit Service Agreement',
    url: 'https://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf',
  },
];

export const DATE_FORMAT = 'DD MMM YYYY';

interface TenancyData {
  tenancyTerm: string | null;
  bondAmount: number | null;
  fromDate: string | null;
  toDate: string | null;
  documentData: Documents[];
  attachments: Attachment[];
}

export interface MappedData {
  agencyImage: string;
  thumbnail: string;
  serviceProvider: string;
  rentAmount: number;
  rentFrequency: RentalFrequency;
  nextPayment: string | null;
  paymentMethod: string | null;
  paymentAccount: string | null;
  tenancyData: TenancyData | null;
  paymentDetails: PaymentDetails;
}
