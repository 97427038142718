/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import {
  OnboardingPropertyDetails,
  PropertyType,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/property';
import { useStyles } from './SelectPropertyTypeStyles';
import apartment from '../../../../assets/houses/house1.png';
import villa from '../../../../assets/houses/house2.png';
import homeOutline from '../../../../assets/houses/house4.png';
import homeSolid from '../../../../assets/houses/house3.png';
import tick from '../../../../assets/tick.png';
import { LABELS } from './SelectPropertyTypeConstants';
import { classNameGenerator } from '../../../../theme/GlobalStyles';

interface SelectPropertyTypeProps {
  selectedType: PropertyType | undefined;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
}

const SelectPropertyType: FC<SelectPropertyTypeProps> = ({ selectedType, setPropertyDetails }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Apartment });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedType === PropertyType.Apartment && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={apartment} />
          </div>

          <span className={classes.text}>{LABELS.APARTMENT}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${
              selectedType === PropertyType.Apartment ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Villa });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedType === PropertyType.Villa && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={villa} />
          </div>

          <span className={classes.text}>{LABELS.VILLA}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${selectedType === PropertyType.Villa ? classes.tick : classes.hideItem}`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Home });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedType === PropertyType.Home && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={homeSolid} />
          </div>

          <span className={classes.text}>{LABELS.HOUSE}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${selectedType === PropertyType.Home ? classes.tick : classes.hideItem}`}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setPropertyDetails({ field: 'selectedType', value: PropertyType.Townhouse });
        }}
        className={classNameGenerator([
          classes.menuItem,
          selectedType === PropertyType.Townhouse && classes.selectedMenuItem,
        ])}
      >
        <div className={classes.menuRow}>
          <div className={classes.houseContainer}>
            <img className={classes.houseImage} src={homeOutline} />
          </div>

          <span className={classes.text}>{LABELS.TOWNHOUSE}</span>
        </div>
        <div className={classes.tickContainer}>
          <img
            src={tick}
            className={`${
              selectedType === PropertyType.Townhouse ? classes.tick : classes.hideItem
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectPropertyType;
