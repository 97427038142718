import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './MaintenanceStyles';
import { LABELS, FormData, DAYS, ValidationSchema } from './MaintenanceConstants';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { get, isUndefined, isNull, isNil } from 'lodash';
import {
  SaveImageRequest,
  CreateMaintenanceRequestStartPayload,
  CreateMaintenanceRequestUpdatePayload,
  SaveImageResponse,
  JobDetailResponse,
  MaintenanceRequestPayload,
  LocationData,
  Images,
} from '../../models/maintenance/maintenance';
import { MaintenanceActionTypes, MaintenanceActions } from '../../store/actions/MaintenanceActions';
import { ServiceActionTypes, ServiceActions } from '../../store/actions/ServiceActions';
import { LeaseResponse } from '../../models/dashboard/dashboard';
import { Profile } from '../../models/user/user';
import { AuthState } from '../../store/state/AuthState';
import { AuthDetails } from '../../models/auth/auth';
import {
  fileSizeValidation,
  getPostData,
  getSelectedDays,
  getUpdateData,
  shortenDays,
} from './MaintenanceUtils';
import { navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import Text from '../../components/text/Text';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import cross from '../../assets/navigation/close.png';
import { useFormik } from 'formik';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import ActivityIndicator from '../../components/activityIndicator/ActivityIndicator';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';
import { ToastMessage } from '../../helpers/ToastMessage';
import InputSelect from '../../components/inputSelect/InputSelect';
import Input from '../../components/input/Input';
import InputArea from '../../components/inputArea/InputArea';
import { classNameGenerator } from '../../theme/GlobalStyles';
import defaultImage from '../../assets/defaultMaintenanceIcon.jpg';
import AddPhoto from './components/addPhoto/AddPhoto';
import { theme, ThemeKey, getCurrentThemeKey } from '../../theme/Theme';
import InputToggle from '../../components/inputToggle/InputToggle';
import { Checkbox } from '@mui/material';
import DaysSelector from './components/daysSelector/DaysSelector';

import { FileLink } from '../../services/MultipartFormData.data';

interface MaintenanceProps extends RouteComponentProps {
  authState: AuthState;
  profile: Profile;
  onboardedProperties: LeaseResponse[] | undefined;
  storedImages: SaveImageResponse[];
  submitedRequest: MaintenanceRequestPayload;
  selectedPropertyIndex: number;
  maintenanceSummary: JobDetailResponse | undefined;
  locationData: LocationData;
  loadingForm: boolean;
  submittingForm: boolean;
  getLocationData: () => void;
  saveImage: (data: SaveImageRequest) => void;
  submitMaintenanceRequest: (data: CreateMaintenanceRequestStartPayload) => void;
  updateMaintenanceRequest: (data: CreateMaintenanceRequestUpdatePayload) => void;
  setMaintenanceSummary: () => void;
  updateImageUuids: (data: SaveImageResponse[]) => void;
  eraseMaintenanceData: () => void;
}

const Maintenance: FC<MaintenanceProps> = ({
  authState,
  profile,
  onboardedProperties,
  storedImages,
  submitedRequest,
  selectedPropertyIndex,
  maintenanceSummary,
  locationData,
  loadingForm,
  submittingForm,
  saveImage,
  getLocationData,
  submitMaintenanceRequest,
  updateMaintenanceRequest,
  setMaintenanceSummary,
  updateImageUuids,
  eraseMaintenanceData,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  const onSubmitMaintenanceRequest = (data: FormData) => {
    const { userId } = authState.authDetails as AuthDetails;
    const propertyId: number = get(onboardedProperties, `${selectedPropertyIndex}.property.id`)!;
    const leaseId = get(
      onboardedProperties,
      `${selectedPropertyIndex}.property.leases.0.id`,
      undefined,
    );
    const postBody: CreateMaintenanceRequestStartPayload = getPostData(
      data,
      propertyId,
      leaseId,
      userId,
    );
    submitMaintenanceRequest({
      ...postBody,
      onSuccess: () => resetNavigation('maintenanceSuccessScreen'),
    });
  };

  const onUpdateMaintenanceRequest = (data: FormData): void => {
    const { id, agencyBranchId, agencyId, tenantIds } =
      maintenanceSummary!.maintenanceRequestResponse;
    const propertyId: number = get(onboardedProperties, `${selectedPropertyIndex}.property.id`)!;
    const postBody: CreateMaintenanceRequestUpdatePayload = getUpdateData(
      id,
      data,
      propertyId,
      agencyBranchId,
      agencyId,
      tenantIds,
    );
    updateMaintenanceRequest({
      ...postBody,
      onSuccess: () => {
        setMaintenanceSummary();
        resetNavigation('maintenanceSuccessScreen');
      },
    });
  };

  useEffect(() => {
    getLocationData();
  }, []);

  const initialValues: FormData = {
    days: isNil(maintenanceSummary)
      ? DAYS.join(',')
      : shortenDays(
          maintenanceSummary.maintenanceRequestResponse.details.preferredAvailability.days,
        ),
    locationDesc: '',
    stairsDesc: '',
    area: isNil(maintenanceSummary)
      ? undefined
      : maintenanceSummary.maintenanceRequestResponse.location,
    jobTitle: isNil(maintenanceSummary)
      ? undefined
      : maintenanceSummary.maintenanceRequestResponse.details.title,
    jobDesc: isNil(maintenanceSummary)
      ? undefined
      : maintenanceSummary.maintenanceRequestResponse.details.description,
    parking: isNil(maintenanceSummary)
      ? true
      : maintenanceSummary.maintenanceRequestResponse.details.parkingAvailability,
    accessType: isNil(maintenanceSummary)
      ? 'TENANT_AVAILABLE'
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.propertyAccess)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.propertyAccess.propertyAccessType,
    access: isNil(maintenanceSummary)
      ? false
      : !maintenanceSummary.maintenanceRequestResponse.details.clearAccess,
    appliance: isNil(maintenanceSummary)
      ? false
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.appliance)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.appliance.present,
    make: isNil(maintenanceSummary)
      ? ''
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.appliance)
      ? ''
      : maintenanceSummary.maintenanceRequestResponse.details.appliance.make,
    model: isNil(maintenanceSummary)
      ? ''
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.appliance)
      ? ''
      : maintenanceSummary.maintenanceRequestResponse.details.appliance.model,
    warranty: isNil(maintenanceSummary)
      ? false
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.appliance)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.appliance.warranty,
    keyLeftOut: false,
    dog: isNil(maintenanceSummary)
      ? false
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.pet)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.pet.present,
    dogDesc: isNil(maintenanceSummary)
      ? ''
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.pet)
      ? ''
      : maintenanceSummary.maintenanceRequestResponse.details.pet.description,
    stairs: isNil(maintenanceSummary)
      ? false
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.stairs)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.stairs.present,
    jobPhotos: isNil(maintenanceSummary)
      ? []
      : (maintenanceSummary.maintenanceRequestResponse.details.images as FileLink[]).filter(
          (image) => image.contentType === 'image/jpeg',
        ),
    jobVideos: isNil(maintenanceSummary)
      ? []
      : (maintenanceSummary.maintenanceRequestResponse.details.images as FileLink[]).filter(
          (image) => image.contentType !== 'image/jpeg',
        ),
    timeSlot: isNil(maintenanceSummary)
      ? 'No preference'
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.preferredAvailability)
      ? null
      : maintenanceSummary.maintenanceRequestResponse.details.preferredAvailability.timeSlot,
    otherTime: isNil(maintenanceSummary)
      ? ''
      : isNil(maintenanceSummary.maintenanceRequestResponse.details.preferredAvailability)
      ? ''
      : maintenanceSummary.maintenanceRequestResponse.details.preferredAvailability.otherTime,
    minor: isNil(maintenanceSummary)
      ? false
      : maintenanceSummary.maintenanceRequestResponse.details.unSupervisedMinors,
    attendPermissionWithoutTenant: isNil(maintenanceSummary)
      ? true
      : maintenanceSummary.maintenanceRequestResponse.details.attendPermissionWithoutTenant,
  };

  const formik = useFormik({
    initialValues: { ...initialValues },
    isInitialValid: false,
    onSubmit: (values) => {
      if (isUndefined(maintenanceSummary)) onSubmitMaintenanceRequest(values);
      else onUpdateMaintenanceRequest(values);
    },
    validationSchema: ValidationSchema,
  });

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text parentStyles={classes.title} textVariant="title" size="xl">
              {LABELS.MAINTENANCE_TITLE}
            </Text>
            {loadingForm || !!!locationData ? (
              <>
                <Box loading parentStyles={classes.loading} />
                <Box loading parentStyles={classes.loading} />
                <Box loading parentStyles={classes.loading} />
              </>
            ) : (
              <>
                <div className={classes.helpContainer}>
                  <div className={classes.helpTitle}>
                    {initialValues.area ? LABELS.MAINTENANCE_EDIT : LABELS.MAINTENANCE_NEW}
                  </div>
                  <div className={classes.helpText}>{LABELS.MAINTENANCE_HELP}</div>
                </div>
                <Box lightBorder parentStyles={classes.boxContainer}>
                  <div className={classes.cardHeading}>{LABELS.JOB_DESCRIPTION}</div>
                  <InputSelect
                    value={
                      formik.values.area
                        ? locationData.LOCATION.find((l) => l.key === formik.values.area)!.value
                        : formik.values.area
                    }
                    values={Object.values(locationData.LOCATION).map((v) => ({
                      display: v.value,
                      value: v.key,
                    }))}
                    title={LABELS.WHERE_IS_ISSUE}
                    placeholder="Eg. Kitchen"
                    error={formik.errors.area}
                    touched={formik.touched.area}
                    setValue={(value: string) => formik.setFieldValue('area', value)}
                    mandatory
                  />
                  <Input
                    value={formik.values.jobTitle || ''}
                    setValue={formik.handleChange('jobTitle')}
                    title={LABELS.TITLE}
                    placeholder="Eg. Leaking tap"
                    error={formik.errors.jobTitle}
                    touched={formik.touched.jobTitle}
                    mandatory
                  />
                  <InputArea
                    value={formik.values.jobDesc || ''}
                    setValue={formik.handleChange('jobDesc')}
                    title={LABELS.DESCRIPTION}
                    placeholder={LABELS.BRIEFLY_DESCRIBE}
                    error={formik.errors.jobDesc}
                    touched={formik.touched.jobDesc}
                    mandatory
                  />
                </Box>
                <Box lightBorder parentStyles={classes.boxContainer}>
                  <div className={classes.cardHeading}>{LABELS.PHOTOS}</div>
                  <div className={classes.infoText}>{LABELS.ATTACH}</div>
                  {[...Array(3).fill(null)].map((_, idx) => (
                    <AddPhoto
                      index={idx}
                      field="jobPhotos"
                      values={formik.values}
                      type="photo"
                      setFieldValue={formik.setFieldValue}
                    />
                  ))}
                </Box>

                <Box lightBorder parentStyles={classes.boxContainer}>
                  <div className={classes.cardHeading}>{LABELS.VIDEOS}</div>
                  <div className={classes.infoText}>{LABELS.ATTACH_VID}</div>
                  {[...Array(1).fill(null)].map((_, idx) => (
                    <AddPhoto
                      index={idx}
                      field="jobVideos"
                      values={formik.values}
                      type="video"
                      setFieldValue={formik.setFieldValue}
                    />
                  ))}
                </Box>
                <Box lightBorder parentStyles={classes.boxContainer}>
                  <div className={classes.cardHeading}>{LABELS.APPLIANCES}</div>
                  <div className={classes.questionContainer}>
                    <Text parentStyles={classes.questionText} textVariant="info">
                      {LABELS.RELATED_TO_APPLIANCE}
                    </Text>
                    <InputToggle
                      value={formik.values.appliance}
                      setValue={(value: boolean) => formik.setFieldValue('appliance', value)}
                    />
                  </div>
                  {formik.values.appliance && (
                    <>
                      <Input
                        value={formik.values.make || ''}
                        setValue={formik.handleChange('make')}
                        title={LABELS.MAKE}
                        placeholder="Eg: Electroluc or Panasonic"
                        error={formik.errors.make}
                        touched={formik.touched.make}
                        mandatory
                      />
                      <Input
                        value={formik.values.model || ''}
                        setValue={formik.handleChange('model')}
                        title={LABELS.MODEL}
                        placeholder="Eg: EPF12CRN2A or CS/CU-S18PKR"
                        error={formik.errors.model}
                        touched={formik.touched.model}
                        mandatory
                      />
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.WARRANTY}
                        </Text>
                        <InputToggle
                          value={formik.values.warranty}
                          setValue={(value: boolean) => formik.setFieldValue('warranty', value)}
                        />
                      </div>
                    </>
                  )}
                </Box>
                <Box lightBorder parentStyles={classes.boxContainer}>
                  <div className={classes.cardHeading}>{LABELS.PREFERRED_AVAILABILITY}</div>
                  <div className={classes.preferredCheckboxesTitle}>{LABELS.PREFERRED_TIME}</div>
                  <div className={classes.checkboxRow}>
                    <Checkbox
                      style={{
                        color: theme.colors.buttonPrimary,
                        flexShrink: 0,
                        paddingLeft: 0,
                      }}
                      checked={formik.values.timeSlot === 'No preference'}
                      onChange={() => {
                        formik.setFieldValue('otherTime', '');
                        formik.setFieldValue('timeSlot', 'No preference');
                        formik.setFieldValue('days', 'Mon,Tue,Wed,Thu,Fri,Sat,Sun');
                      }}
                    />
                    <div className={classes.checkboxText}>{LABELS.NO_PREFERENCE}</div>
                  </div>
                  <div className={classes.checkboxRow}>
                    <Checkbox
                      style={{
                        color: theme.colors.buttonPrimary,
                        flexShrink: 0,
                        paddingLeft: 0,
                      }}
                      checked={formik.values.timeSlot === 'Morning'}
                      onChange={() => {
                        formik.setFieldValue('otherTime', '');
                        formik.setFieldValue('timeSlot', 'Morning');
                      }}
                    />
                    <div className={classes.checkboxText}>{LABELS.MORNING}</div>
                  </div>
                  <div className={classes.checkboxRow}>
                    <Checkbox
                      style={{
                        color: theme.colors.buttonPrimary,
                        flexShrink: 0,
                        paddingLeft: 0,
                      }}
                      checked={formik.values.timeSlot === 'Afternoon'}
                      onChange={() => {
                        formik.setFieldValue('otherTime', '');
                        formik.setFieldValue('timeSlot', 'Afternoon');
                      }}
                    />
                    <div className={classes.checkboxText}>{LABELS.AFTERNOON}</div>
                  </div>
                  <div className={classes.checkboxRow} style={{ marginBottom: 12 }}>
                    <Checkbox
                      style={{
                        color: theme.colors.buttonPrimary,
                        flexShrink: 0,
                        paddingLeft: 0,
                      }}
                      checked={formik.values.timeSlot === 'Other'}
                      onChange={() => {
                        formik.setFieldValue('timeSlot', 'Other');
                      }}
                    />
                    <div className={classes.checkboxText}>{LABELS.OTHER}</div>
                  </div>

                  <Input
                    disabled={formik.values.timeSlot !== 'Other'}
                    value={formik.values.otherTime || ''}
                    setValue={formik.handleChange('otherTime')}
                    placeholder="Eg: 2pm-6pm only"
                  />
                  {formik.values.timeSlot !== 'No preference' && (
                    <>
                      <div className={classes.preferredCheckboxesTitle}>{LABELS.PREFERRED_DAY}</div>
                      <DaysSelector
                        options={
                          themeKey === ThemeKey.SWITCH
                            ? DAYS.filter((d) => d !== 'Sat' && d !== 'Sun')
                            : DAYS
                        }
                        selected={getSelectedDays(formik.values.days)}
                        onChange={(value: number[]) => {
                          formik.setFieldValue('days', value.map((v) => DAYS[v]).join(','));
                        }}
                      />
                    </>
                  )}
                  <div
                    className={classes.questionContainer}
                    style={{
                      borderTop: `${theme.colors.borderColorLight} 1px solid`,
                      marginTop: 12,
                    }}
                  >
                    <Text parentStyles={classes.questionText} textVariant="info">
                      {LABELS.PERMISSION_PRESENT}
                    </Text>
                    <InputToggle
                      value={formik.values.attendPermissionWithoutTenant}
                      setValue={(value: boolean) =>
                        formik.setFieldValue('attendPermissionWithoutTenant', value)
                      }
                    />
                  </div>
                </Box>
                {themeKey !== ThemeKey.SWITCH && (
                  <>
                    <Box lightBorder parentStyles={classes.boxContainer}>
                      <div className={classes.cardHeading}>{LABELS.PROPERTY_DETAILS}</div>
                      <div className={classes.preferredCheckboxesTitle}>
                        {LABELS.CONTACT_ACCESS}
                      </div>
                      <div className={classes.checkboxRow}>
                        <Checkbox
                          style={{
                            color: theme.colors.buttonPrimary,
                            flexShrink: 0,
                            paddingLeft: 0,
                          }}
                          checked={formik.values.accessType === 'TENANT_AVAILABLE'}
                          onChange={() => {
                            formik.setFieldValue('accessType', 'TENANT_AVAILABLE');
                          }}
                        />
                        <div className={classes.checkboxText}>{LABELS.CONTACT_HOME}</div>
                      </div>
                      <div className={classes.checkboxRow}>
                        <Checkbox
                          style={{
                            color: theme.colors.buttonPrimary,
                            flexShrink: 0,
                            paddingLeft: 0,
                          }}
                          checked={formik.values.accessType === 'COLLECT_KEY'}
                          onChange={() => {
                            formik.setFieldValue('accessType', 'COLLECT_KEY');
                          }}
                        />
                        <div className={classes.checkboxText}>{LABELS.COLLECT_KEYS}</div>
                      </div>
                    </Box>
                    <Box lightBorder parentStyles={classes.boxContainer}>
                      <div className={classes.cardHeading}>{LABELS.MINOR}</div>
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.MINOR_INFO}
                        </Text>
                        <InputToggle
                          value={formik.values.minor}
                          setValue={(value: boolean) => formik.setFieldValue('minor', value)}
                        />
                      </div>
                    </Box>
                    <Box lightBorder parentStyles={classes.boxContainer}>
                      <div className={classes.cardHeading}>{LABELS.PETS}</div>
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.PETS_INFO}
                        </Text>
                        <InputToggle
                          value={formik.values.dog}
                          setValue={(value: boolean) => formik.setFieldValue('dog', value)}
                        />
                      </div>
                      {formik.values.dog && (
                        <InputArea
                          value={formik.values.dogDesc || ''}
                          setValue={formik.handleChange('dogDesc')}
                          title={LABELS.PETS_NAME}
                          placeholder="Name, size and type of animal(s)"
                          error={formik.errors.dogDesc}
                          touched={formik.touched.dogDesc}
                        />
                      )}
                    </Box>
                    <Box lightBorder parentStyles={classes.boxContainer}>
                      <div className={classes.cardHeading}>{LABELS.ACCESS}</div>
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.STAIRS}
                        </Text>
                        <InputToggle
                          value={formik.values.stairs}
                          setValue={(value: boolean) => formik.setFieldValue('stairs', value)}
                        />
                      </div>
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.BODY_CORPORATE}
                        </Text>
                        <InputToggle
                          value={formik.values.access}
                          setValue={(value: boolean) => formik.setFieldValue('access', value)}
                        />
                      </div>
                    </Box>
                    <Box lightBorder parentStyles={classes.boxContainer}>
                      <div className={classes.cardHeading}>{LABELS.PARKING}</div>
                      <div className={classes.preferredCheckboxesTitle}>{LABELS.IS_GUEST}</div>
                      <div className={classes.checkboxRow}>
                        <Checkbox
                          style={{
                            color: theme.colors.buttonPrimary,
                            flexShrink: 0,
                            paddingLeft: 0,
                          }}
                          checked={formik.values.parking === true}
                          onChange={() => {
                            formik.setFieldValue('parking', true);
                          }}
                        />
                        <div className={classes.checkboxText}>{LABELS.PARKING_YES}</div>
                      </div>
                      <div className={classes.checkboxRow}>
                        <Checkbox
                          style={{
                            color: theme.colors.buttonPrimary,
                            flexShrink: 0,
                            paddingLeft: 0,
                          }}
                          checked={formik.values.parking === false}
                          onChange={() => {
                            formik.setFieldValue('parking', false);
                          }}
                        />
                        <div className={classes.checkboxText}>{LABELS.PARKING_NO}</div>
                      </div>
                    </Box>
                  </>
                )}
                <div className={classes.buttonContainer}>
                  <Button
                    disabled={!!Object.keys(formik.errors).length || submittingForm}
                    loading={submittingForm}
                    parentStyles={classes.button}
                    onPress={() => {
                      const isFormValid = !formik.isValid;
                      if (isFormValid) {
                        ToastMessage.showMessage('Please check all fields', 'danger');
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    {LABELS.MAINTENANCE_SUBMIT}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const loadingForm = loadingSelector([ServiceActionTypes.GET_LOCATION_DATA]);

const submittingForm = loadingSelector([MaintenanceActionTypes.SEND_MAINTENANCE]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingForm: loadingForm(state),
  submittingForm: submittingForm(state),
  authState: state.authState,
  profile: state.profileState.profile,
  onboardedProperties: state.dashboardState.onboardedProperties,
  locationData: state.services.locationData,
  storedImages: state.maintenance.imageUUIDs,
  submitedRequest: state.maintenance.submitedRequest,
  selectedPropertyIndex: state.dashboardState.selectedProperty,
  maintenanceSummary:
    !isUndefined(state.services.maintenanceSummary) && !isNull(state.services.maintenanceSummary)
      ? state.services.maintenanceSummary
      : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveImage: (data: SaveImageRequest) => {
    dispatch(MaintenanceActions.saveImageRequest(data));
  },
  submitMaintenanceRequest: (data: CreateMaintenanceRequestStartPayload) => {
    dispatch(MaintenanceActions.sendMaintenanceRequest(data));
  },
  updateMaintenanceRequest: (data: CreateMaintenanceRequestUpdatePayload) => {
    dispatch(MaintenanceActions.updateMaintenanceRequest(data));
  },
  setMaintenanceSummary: () => dispatch(ServiceActions.setMaintenanceSummaryRequest()),
  updateImageUuids: (data: SaveImageResponse[]) => {
    dispatch(MaintenanceActions.updateUuidList(data));
  },
  eraseMaintenanceData: () => {
    dispatch(MaintenanceActions.removeMaintenanceData());
  },
  getLocationData: () => dispatch(ServiceActions.getLocationData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Maintenance));
