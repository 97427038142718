import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
  pageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
    },
    centerPageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black38,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 8,
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    propertyContainer: {
      display: 'flex',
      backgroundColor: theme.colors.light,
      justifyContent: 'space-between',
      padding: '16px',
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
    },
    messageContainer: {
      marginRight: 'auto',
    },
    messageTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '20px',
      fontWeight: 500,
    },
    messageSubtitle: {
      fontFamily: 'GT Walsheim',
      marginTop: 4,
      color: theme.colors.black38,
      fontSize: '14px',
      fontWeight: 500,
    },
    agencyLogo: {
      width: '55px',
      height: '55px',
      borderRadius: 5000,
      backgroundColor: theme.colors.light,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginLeft: 'auto',
      marginRight: 12,
    },
    houseContainer: {
      width: '55px',
      height: '55px',
    },
    house: {
      width: '100%',
      height: '100%',
      objectPosition: 'center',
      objectFit: 'contain',
    },
    rentLogoContainer: {
      // border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      marginRight: 12,
      backgroundColor: theme.colors.lightGreen,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 50,
      height: 50,
    },
    rentLogo: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
    rentTextContainer: {
      marginLeft: 'auto',
    },
    bankLogo: {
      marginLeft: 16,
      marginRight: 28,
    },
    masterLogo: {
      marginLeft: 10,
      marginRight: 24,
    },
    visaLogo: {
      marginLeft: 12,
      marginRight: 24,
    },
    paymentTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '18px',
      fontWeight: 500,
    },
    rentTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '20px',
      fontWeight: 500,
      textAlign: 'right',
    },
    rentSubtitle: {
      fontFamily: 'GT Walsheim',
      marginTop: 4,
      color: theme.colors.black38,
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'right',
    },
    rentContainer: {
      display: 'flex',
      backgroundColor: theme.colors.light,
      justifyContent: 'space-between',
      padding: '16px',
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
      minHeight: 55,
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.light,
      padding: '16px',
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
      minHeight: '40px',
    },
    menuRow: {
      display: 'flex',
      alignItems: 'center',
    },
    extraInfoContainer: {
      backgroundColor: theme.colors.light,
      padding: '16px',
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
    },
    infoRow: {
      'display': 'flex',
      'justifyContent': 'space-between',
      'alignItems': 'center',
      'marginBottom': 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    itemTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black38,
      fontSize: '16px',
      fontWeight: 500,
    },
    itemValue: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'right',
    },
    iconContainer: {
      width: '26px',
      height: '26px',
      marginRight: '16px',
    },
    iconImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    optionContainer: {
      marginRight: 'auto',
    },
    optionTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '16px',
      fontWeight: 500,
    },
    optionSubtitle: {
      fontFamily: 'GT Walsheim',
      marginTop: 4,
      color: theme.colors.black38,
      fontSize: '13px',
      fontWeight: 500,
    },
    tickContainer: {
      width: '22px',
      height: '22px',
      marginLeft: 'auto',
      marginRight: '12px',
    },
    tick: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    hideItem: {
      display: 'none',
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 8,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
      marginLeft: theme.units.spacing * 4,
    },
  })();
};
