import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CheckoutFinalizeStyles';
import { LABELS } from './CheckoutFinalizeConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack } from '../../navigation/NavigationUtils';
import { updateAccountAndPaymentInAvailableServices, useCart } from '../../helpers/CheckoutHelper';
import CartItem from '../cart/components/cartItem/CartItem';
import { essentialServices } from '../checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import { ServiceTypes } from '../../models/services/services';
import ServiceTabs from '../checkoutSelect/components/serviceTabs/ServiceTabs';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  PageName,
  PersonalDetails,
} from '../../models/checkout/Checkout';
import Button from '../../components/button/Button';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import { PaymentActions } from '../../store/actions/PaymentActions';
import { Property } from '../../models/property/property';
import { hasAnyPayments, hasPayments } from './CheckoutFinalizeUtils';
import { getPreSelectedPaymentMethods } from './components/paymentSection/PaymentSectionUtils';
import { PaymentGateway } from '../../models/billing/Billing';
import StripeWrapper from './components/stripeSection/StripeWrapper';
import PaymentSection from './components/paymentSection/PaymentSection';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';

interface CheckoutFinalizeProps {
  property: Property;
  personalDetails: PersonalDetails;
  availableServices: AvailableServicesResponse[];
  accessToken: string | boolean | null;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  getPaymentMethods: (onSuccess: () => void) => void;
}

const CheckoutFinalize: FC<CheckoutFinalizeProps> = ({
  property,
  personalDetails,
  availableServices,
  accessToken,
  updateCart,
  getPaymentMethods,
}) => {
  const classes = useStyles();
  const { cartItems, paymentItems } = useCart();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedPaymentMethods, setSelectedPaymentMethod] = useState<Map<string, string> | {}>(
    getPreSelectedPaymentMethods(paymentItems),
  );
  const [validate, setValidationFlag] = useState<boolean>(false);
  const paymentsCount = paymentItems.length;

  useEffect(() => {
    if (accessToken) {
      getPaymentMethods(() => true);
    }
  }, []);

  const arePaymentsDone = (): boolean => {
    let isPaymentMethodsSelected: boolean[] = [];

    paymentItems.map((cartItem, index) => {
      if (cartItem.complete && cartItem.complete.payment) {
        isPaymentMethodsSelected.push(true);
      } else {
        if (
          cartItem.suppliers![0].plans[0].cartData &&
          !cartItem.suppliers![0].plans[0].cartData.planDetails.skipPaymentStep
        ) {
          isPaymentMethodsSelected.push(!!selectedPaymentMethods[index]);
        } else {
          isPaymentMethodsSelected.push(true);
        }
      }
    });

    const isAllServicesPaymentCompleted =
      isPaymentMethodsSelected.findIndex((paymentMethod) => !paymentMethod) > -1;
    return !isAllServicesPaymentCompleted;
  };

  const onNext = () => {
    if (activeTab + 1 === paymentsCount) {
      navigate('checkoutSummary');
    } else {
      setActiveTab(activeTab + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const savePaymentMethod = (refId: string | null) => {
    setSelectedPaymentMethod({ ...selectedPaymentMethods, [activeTab]: refId });
    const updatedAvailableServices = updateAccountAndPaymentInAvailableServices(
      availableServices,
      paymentItems[activeTab].type,
      paymentItems[activeTab]!.suppliers![0].providerId,
      paymentItems[activeTab]!.suppliers![0].plans[0].productId,
      personalDetails,
      refId,
    );
    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: property.id.toString(),
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link" size="l">
            Confirm your details
          </Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
              {LABELS.CONFIRM_DETAILS}
            </div>
            <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
              {LABELS.PERSONAL_DETAILS}
            </div>
            <div className={classes.title} style={{ marginBottom: 32, borderBottom: 'none' }}>
              {LABELS.PAYMENT}
            </div>
            {paymentItems.length > 1 && (
              <div className={classes.serviceTabs}>
                <ServiceTabs
                  activeServices={paymentItems}
                  selectedTab={activeTab}
                  setSelectedTab={(value) => setActiveTab(value)}
                  pageName={PageName.CHECKOUTFINALLIZE}
                />
                <div style={{ marginTop: -16 }}>
                  <CartItem
                    hideAction
                    hidePrice
                    cartItem={paymentItems[activeTab]}
                    key={`${paymentItems[activeTab].type}`}
                    handleAction={() => null}
                    handleTap={() => null}
                  />
                </div>
              </div>
            )}
            {paymentItems.length > 1 && (
              <div className={classes.mobileActiveItem}>
                <CartItem
                  hideAction
                  hidePrice
                  cartItem={paymentItems[activeTab]}
                  key={`${paymentItems[activeTab].type}`}
                  handleAction={() => null}
                  handleTap={() => null}
                />
              </div>
            )}

            {paymentItems[activeTab].suppliers![0].plans[0].cartData!.planDetails.paymentType ===
            PaymentGateway.STRIPE ? (
              <StripeWrapper
                cartItem={paymentItems[activeTab]}
                key={`payment_section_${activeTab}`}
                setPaymentMethod={(refId: string) => savePaymentMethod(refId)}
              />
            ) : (
              <PaymentSection
                serviceType={paymentItems[activeTab].type}
                setPaymentMethod={(refId: string) => savePaymentMethod(refId)}
                cartItem={paymentItems[activeTab]}
                selectedPaymentRefId={selectedPaymentMethods[activeTab] || ''}
                key={`payment_section_${activeTab}`}
                setHeightOnAddNew={() => null}
                setPaymentRefIdOnAddNew={() => savePaymentMethod(null)}
              />
            )}

            <div className={classes.buttonContainer}>
              <Button
                disabled={activeTab + 1 === paymentsCount ? !arePaymentsDone() : false}
                onPress={() => onNext()}
                parentStyles={classes.button}
              >
                {activeTab + 1 === paymentsCount ? LABELS.NEXT : LABELS.NEXT_SERVICE}
              </Button>
            </div>
            <div className={classes.disabledTitle}>{LABELS.SUMMARY}</div>
          </div>
          <div className={classes.secondaryContainer}>
            <div className={classes.summaryContainer}>
              <div className={classes.summaryTitle}>{LABELS.SUMMARY}</div>
              <div className={classes.cards}>
                {!!cartItems.length && (
                  <>
                    {!!cartItems.length &&
                      cartItems.map((cartItem, i) => (
                        <CartItem
                          hideAction
                          hidePrice
                          cartItem={cartItem}
                          key={`${cartItem.type}`}
                          handleTap={() => {
                            const result = paymentItems.findIndex((p) => p.type === cartItem.type);
                            if (result !== -1) {
                              setActiveTab(result);
                            }
                          }}
                          handleAction={() => null}
                          quote={!essentialServices.includes(cartItem.type as ServiceTypes)}
                          showTick={
                            cartItem.complete &&
                            cartItem.complete!.personalDetails &&
                            cartItem.complete!.payment
                          }
                          selectable={paymentItems.some((p) => p.type === cartItem.type)}
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
  personalDetails: state.checkout.personalDetails,
  availableServices: state.checkout.availableServices,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  getPaymentMethods: (onSuccess: () => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFinalize);
