import { AccountStatus } from '../../../../constants/AccountStatus';
import { Services } from '../../../../models/services/services';

const pendingService = [AccountStatus.PRESIGNED, AccountStatus.INPROGRESS, AccountStatus.PENDING];
const activeService = [AccountStatus.COMPLETED, AccountStatus.OPEN];

export enum Tab {
  Active = 'Active',
  Pending = 'Pending',
  Past = 'Past',
}

export const filterServicesBy = (tabType: Tab, services: Services[]) => {
  return services.filter((data) =>
    tabType === Tab.Pending
      ? pendingService.includes(data.status)
      : activeService.includes(data.status),
  );
};

export const filterServiceBy = (tabType: Tab, services: Services): boolean => {
  if (tabType === Tab.Pending) {
    return pendingService.includes(services.status);
  } else {
    return activeService.includes(services.status);
  }
};
