import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    tileContainer: {
      marginTop: theme.units.spacing * 12,
      backgroundColor: theme.colors.light,
      flex: 1,
      position: 'relative',
    },
    noMarginTop: {
      marginTop: theme.units.spacing * 6,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    title: {
      color: theme.colors.title,
      fontSize: `22px !important`,
      fontWeight: getFonts('Medium'),
      marginLeft: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    icon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
    text: {
      color: theme.colors.subtitle,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      marginLeft: 'auto',
    },
    link: {
      color: theme.colors.buttonPrimary,
      cursor: 'pointer',
    },
    contentContainer: {
      display: 'flex',
      overflow: 'scroll',
    },
    secondaryContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    secondaryIcon: {
      paddingLeft: theme.units.spacing * 3,
    },
    leftButton: {
      minWidth: 60,
      marginLeft: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
  })();
};
