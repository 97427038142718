export const LABELS = {
  PAY: 'Save',
  PROCESSING: 'Processing',
  SECURE_CONNECTION: 'Secure',
  TITLE: 'Total to pay today',
  PAID: 'Pending total to pay today',
  SUCCESS: 'Add Payment success',
  SUBTITLE:
    "This service requires an upfront payment that includes your first month's bill plus any extra addons",
};
