import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    container: {
      width: '100%',
      borderBottom: `1px solid ${theme.colors.borderColor}`,
      display: 'flex',
      marginBottom: theme.units.spacing * 6,
    },
    tabWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.units.spacing * 2,
      minWidth: 100,
      paddingRight: theme.units.spacing * 2,
      marginRight: theme.units.spacing * 4,
      borderBottom: `${theme.colors.light} 2px solid`,
      cursor: 'pointer',
      flexShrink: 0,
    },
    tick: {
      width: theme.units.iconSize * 0.3,
      height: theme.units.iconSize * 0.3,
      filter: 'invert(1)',
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.lightGrey,
      color: theme.colors.black,
      fontWeight: getFonts('Regular'),
      fontSize: 9,
      width: 60,
      height: 15,
      borderRadius: 2,
      marginBottom: theme.units.spacing * 3,
    },
    completeLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      color: theme.colors.light,
      fontWeight: getFonts('Regular'),
      fontSize: 9,
      width: 60,
      height: 15,
      borderRadius: 3,
      marginBottom: theme.units.spacing * 3,
    },
    selectedLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.lightPurple,
      color: theme.colors.black87,
      fontWeight: getFonts('Regular'),
      fontSize: 9,
      width: 60,
      height: 15,
      borderRadius: 3,
      marginBottom: theme.units.spacing * 3,
    },
    tab: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    serviceIcon: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 2,
    },
    text: {
      flexShrink: 0,
      color: theme.colors.black87,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    selected: {
      borderBottom: `${theme.colors.black} 2px solid`,
    },
  })();
};
