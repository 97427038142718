/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    secondaryContainer: {
      ...secondaryContent,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        marginLeft: 0,
        marginTop: theme.units.spacing * 12,
        maxWidth: '100%',
      },
    },
    box: {
      marginTop: theme.units.spacing * 8,
    },
    innerBox: {
      height: 300,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    button: {
      marginTop: theme.units.spacing * 4,
    },
  })();
};
