import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import calendarIcon from '../../assets/outlined.svg';
import cardIcon from '../../assets/credit-card-24-px.svg';
import { InitialConcessionCardValues } from '../../models/checkout/Checkout';
import { FormControl, Input } from '@mui/base';
import {
  Typography,
  InputLabel,
  InputAdornment,
  withStyles,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useStyles } from './ConcessionCardFormStyles';
import { Validation } from './ConcessionCardFormConstants';

interface ConcessionCardFormProps {
  setConcessionCard: (concessionCard: InitialConcessionCardValues | null) => void;
}

const ConcessionCardForm: FC<ConcessionCardFormProps> = ({ setConcessionCard }) => {
  const classes = useStyles();
  const [cardType, setCardType] = useState('');

  const initialValues = {
    name: '',
    expiry: '',
    cardNumber: '',
    cardType: '',
  };

  return (
    <div className={classes.cardStyle} style={{ marginLeft: 16, marginRight: 16 }}>
      <Typography variant="h6">Add card details</Typography>
      <div className={classes.seperator} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {}}
        validationSchema={Validation}
        render={({ values, setFieldValue, handleSubmit, isValid }) => (
          <div className={classes.form}>
            <div className={classes.cardType}>
              <Typography variant="subtitle1">Card type</Typography>
              <ToggleButtonGroup className={classes.cardToggleContainer} value={cardType}>
                <ToggleButton
                  classes={{ selected: classes.toggleButtonActive }}
                  className={
                    cardType === 'PensionerConcessionCard'
                      ? classes.toggleButtonActive
                      : classes.toggleButton
                  }
                  value={'PensionerConcessionCard'}
                  onClick={() => {
                    setCardType('PensionerConcessionCard');
                    setFieldValue('cardType', 'PensionerConcessionCard');
                    setConcessionCard({
                      ...values,
                      cardType: 'PensionerConcessionCard',
                    });
                  }}
                >
                  Pensioner
                </ToggleButton>
                <ToggleButton
                  classes={{ selected: classes.toggleButtonActive }}
                  className={
                    cardType === 'HealthCareCard'
                      ? classes.toggleButtonActive
                      : classes.toggleButton
                  }
                  value={'HealthCareCard'}
                  onClick={() => {
                    setCardType('HealthCareCard');
                    setFieldValue('cardType', 'HealthCareCard');
                    setConcessionCard({
                      ...values,
                      cardType: 'HealthCareCard',
                    });
                  }}
                >
                  Health Care
                </ToggleButton>
                <ToggleButton
                  classes={{ selected: classes.toggleButtonActive }}
                  className={
                    cardType === 'GoldCard' ? classes.toggleButtonActive : classes.toggleButton
                  }
                  value={'GoldCard'}
                  onClick={() => {
                    setCardType('GoldCard');
                    setFieldValue('cardType', 'GoldCard');
                    setConcessionCard({
                      ...values,
                      cardType: 'GoldCard',
                    });
                  }}
                >
                  Gold
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Card Holder Name</InputLabel>
              <Input
                name="name"
                onChange={(event) => {
                  setConcessionCard({
                    ...values,
                    name: event.target.value,
                  });
                  setFieldValue('name', event.target.value);
                }}
                value={values.name}
              />
            </FormControl>
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Card Number</InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position="end">
                    <img src={cardIcon} />
                  </InputAdornment>
                }
                name="cardNumber"
                onChange={(event) => {
                  setConcessionCard({
                    ...values,
                    cardNumber: event.target.value,
                  });
                  setFieldValue('cardNumber', event.target.value);
                }}
                value={values.cardNumber}
              />
            </FormControl>
            <FormControl className={classes.inputs} error={false}>
              <InputLabel>Expiry Date MM/YY</InputLabel>
              <Input
                name="expiry"
                type="text"
                value={values.expiry}
                placeholder="Expiry (MM/YY)"
                // inputComponent={CustomInput(Labels.expiryDatePattern) as any}
                autoFocus={false}
                onChange={(event) => {
                  if (event.target.value.length === 3 && values.expiry.length === 4) {
                    setFieldValue('expiry', event.target.value.slice(0, -1));
                    setConcessionCard({
                      ...values,
                      expiry: event.target.value.slice(0, -1),
                    });
                  } else if (event.target.value.length === 2 && values.expiry.length === 1) {
                    setFieldValue('expiry', `${event.target.value}/`);
                    setConcessionCard({
                      ...values,
                      expiry: `${event.target.value}/`,
                    });
                  } else {
                    setFieldValue('expiry', event.target.value);
                    setConcessionCard({
                      ...values,
                      expiry: event.target.value,
                    });
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={calendarIcon} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        )}
      />
    </div>
  );
};

export default ConcessionCardForm;
