/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
      marginBottom: theme.units.spacing * 4,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: theme.units.borderRadius / 1.5,
      border: `${theme.colors.borderColor} 2px solid`,
      backgroundColor: theme.colors.inputBackground,
      padding: theme.units.spacing * 2,
      paddingRight: 0,
      paddingLeft: 0,
    },
    inputContainerBig: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      paddingTop: theme.units.spacing * 4,
      paddingBottom: theme.units.spacing * 4,
      paddingLeft: theme.units.spacing * 2,
      paddingRight: theme.units.spacing * 1,
    },
    inputStyle: {
      border: 'none',
      '&:focus-visible': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.inputText,
        fontSize: theme.font.m,
        fontWeight: getFonts('Light'),
      },
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      flex: 1,
      marginLeft: theme.units.spacing * 3,
      position: 'relative',
      height: 24,
    },
    inputStyleBig: {
      '&::-webkit-input-placeholder': {
        color: theme.colors.black38,
        fontSize: theme.font.m,
        fontWeight: getFonts('Medium'),
      },
    },
    errorInput: {
      border: `${theme.colors.error} 2px solid`,
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
    secondInput: {
      marginLeft: theme.units.spacing * 4,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing,
    },
    titleContainerBig: {
      marginBottom: theme.units.spacing * 4,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    titleStyleBig: {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    endElement: {
      marginLeft: theme.units.spacing * 2,
    },
    endElementBig: {
      marginRight: theme.units.spacing * 2,
    },
    endStatus: {
      marginLeft: theme.units.spacing,
      width: 25,
      height: 25,
      marginRight: theme.units.spacing * 2,
    },
    endStatusBig: {
      marginRight: theme.units.spacing * 3,
    },
    calendarContainer: {
      marginLeft: 'auto',
      width: 'max-content',
      height: 'max-content',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      cursor: 'pointer',
    },
    calendar: {
      width: theme.units.iconSize / 1.2,
      height: theme.units.iconSize / 1.2,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    calendarBig: {
      opacity: 0.4,
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
  })();
};
