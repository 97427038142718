import { AuthDetails, FindUserResponse } from '../../models/auth/auth';
import { AuthAction, AuthActionTypes } from '../actions/AuthActions';
import { AuthState } from '../state/AuthState';

export const initialState: AuthState = {
  authDetails: undefined,
  applyAuthDetails: undefined,
  guest: false,
};

export const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SET_GUEST:
      return {
        ...state,
        guest: true,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authDetails: action.data as AuthDetails,
        guest: false,
      };
    case AuthActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        authDetails: action.data as AuthDetails,
        guest: false,
      };
    case AuthActionTypes.FIND_USER_SUCCESS:
      return {
        ...state,
        applyAuthDetails: action.data as FindUserResponse,
        guest: false,
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        authDetails: undefined,
      };
    case AuthActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        guest: false,
        authDetails: {
          ...(state.authDetails as AuthDetails),
          resetRequired: false,
        },
      };
    default:
      return state;
  }
};
