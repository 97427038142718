import React, { FC } from 'react';
import { LABELS } from './SettingUpPropertyConstants';
import LOADER_IMAGE from '../../../../assets/sorteLogo.png';
import { useStyles } from './SettingUpPropertyStyles';

interface SettingUpPropertyProps {}

const SettingUpProperty: FC<SettingUpPropertyProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.loadingRoot}>
      <img className={classes.sortedLoader} src={LOADER_IMAGE} />
      <div className={classes.primaryText}>{LABELS.SETTING_UP}</div>
      <div className={classes.secondaryText}>{LABELS.WONT}</div>
    </div>
  );
};

export default SettingUpProperty;
