/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { Application, PropertyApplication } from '../../../../models/landlord/LandlordProperty';
import {
  AffordabilityFilter,
  affordabilityFilters,
  ApplicationFilter,
  Filter,
  MoveInDateFilter,
  moveInFilters,
  RentOfferFilter,
} from './ApplicationsConstants';
import { API_DATE_FORMAT } from '../../../home/components/activity/ActivityConstants';

export const sortApplications = (
  applications: Application[],
  filter?: ApplicationFilter,
): Application[] => {
  switch (filter) {
    case AffordabilityFilter.LowToHigh:
      return [...applications].sort((a, b) => a.incomePercent! - b.incomePercent!);

    case AffordabilityFilter.HighToLow:
      return [...applications].sort((a, b) => b.incomePercent! - a.incomePercent!);

    case MoveInDateFilter.EarliestToLatest:
      return [...applications].sort((a, b) =>
        moment(a.preferredMoveInDate as string, API_DATE_FORMAT).diff(
          moment(b.preferredMoveInDate as string, API_DATE_FORMAT),
        ) > 0
          ? 1
          : -1,
      );
    case MoveInDateFilter.LatestToEarliest:
      return [...applications].sort((a, b) =>
        moment(b.preferredMoveInDate as string, API_DATE_FORMAT).diff(
          moment(a.preferredMoveInDate as string, API_DATE_FORMAT),
        ) > 0
          ? 1
          : -1,
      );
    case RentOfferFilter.HighToLow:
      return [...applications].sort((a, b) => b.rentOffer - a.rentOffer);
    case RentOfferFilter.LowToHigh:
      return [...applications].sort((a, b) => a.rentOffer - b.rentOffer);
    default:
      return [...applications].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
  }
};

export const getFilterCategory = (filter: Filter): string => {
  if (Object.values(AffordabilityFilter).includes(filter.value as AffordabilityFilter)) {
    return 'Affordability';
  } else if (Object.values(MoveInDateFilter).includes(filter.value as MoveInDateFilter)) {
    return 'Move in date';
  } else if (Object.values(RentOfferFilter).includes(filter.value as RentOfferFilter)) {
    return 'Rent offer';
  } else {
    return '';
  }
};

export const updateInitialApplicationRanking = (applicationList: PropertyApplication[]) => {
  const updatedApplicationList = applicationList.map((propertyObj) => {
    let rankIndex = Math.max(...propertyObj.applications.map((o) => o.rank), 1);
    const updatedApplications = propertyObj.applications.map((application) => {
      const updatedApplication = {
        ...application,
        rank: application.rank || rankIndex,
      };
      rankIndex++;
      return updatedApplication;
    });
    return {
      ...propertyObj,
      applications: updatedApplications.sort((a, b) => (a.rank > b.rank ? 1 : -1)),
    };
  });
  return updatedApplicationList;
};

export const getApplicationRanking = (applications: Application[]): Map<string, number> | {} => {
  let applicationRanking: Map<string, string> | {} = {};
  applications.forEach((application, index) => {
    applicationRanking = {
      ...applicationRanking,
      [`${application.id}`]: index,
    };
  });
  return applicationRanking;
};

export const sortApplicationsOnRank = (applications: Application[]) => {
  return applications.sort((a, b) => (a.rank > b.rank ? 1 : -1));
};

export const getApplicationNotes = (
  properties: PropertyApplication[],
  selectedProperty: number,
): string => {
  const applications =
    properties && properties[selectedProperty] ? properties[selectedProperty].applications : [];
  const notes =
    applications && applications.length > 0
      ? applications[0].shortlistedNote && applications[0].shortlistedNote
        ? applications[0].shortlistedNote.note
        : ''
      : '';
  return notes;
};
