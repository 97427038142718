import React, { FC } from 'react';
import { useStyles } from './ServiceIconStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { getColouredIcon } from '../../containers/browse/components/marketplace/MarketplaceUtils';
import { getColourFilter } from '../../helpers/ColourHelper';

interface ServiceIconProps {
  icon: string;
  name: string;
  parentStyles?: string;
  coloured?: boolean;
  handlePress: () => void;
}

const ServiceIcon: FC<ServiceIconProps> = ({ icon, name, parentStyles, coloured, handlePress }) => {
  const classes = useStyles();
  const colours = getColouredIcon(name);
  const filterPrimary = coloured ? getColourFilter(colours.primary) : null;
  const filterBackground = coloured ? getColourFilter(colours.background) : null;

  return (
    <div
      className={classNameGenerator([classes.serviceContainer, parentStyles])}
      onClick={() => handlePress()}
    >
      <div
        className={classes.serviceIconContainer}
        style={filterBackground ? { filter: filterBackground } : {}}
      />
      <img
        src={icon}
        className={classes.serviceIcon}
        style={filterPrimary ? { filter: filterPrimary } : {}}
      />
      <div className={classes.serviceText}>{name}</div>
    </div>
  );
};

export default ServiceIcon;
