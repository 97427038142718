/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../theme/Theme';
import { globalStyles } from '../../theme/GlobalStyles';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    footerContainer: {
      backgroundColor: theme.colors.sortedPurple,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 'auto',
    },
    mobileOnly: {
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'none',
      },
    },
    hide: {
      display: 'none !important',
    },
    paddingContainer: {
      paddingLeft: '96px',
      paddingRight: '96px',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        paddingLeft: 48,
        paddingRight: 48,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingLeft: 12,
        paddingRight: 12,
      },
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    contentContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridTemplateRows: 'auto auto',
      width: '100%',
      paddingTop: '30px',
      paddingBottom: '30px',
      gridGap: '30px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto auto auto auto',
      },
    },
    sortedLogo: {
      height: '40px',
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridRow: '3 / 4',
        margin: 'auto',
      },
      cursor: 'pointer',
    },
    socialContainer: {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
      marginTop: '45px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridColumn: '1 / 2',
        gridRow: '2 / 3',
        justifySelf: 'center',
        marginTop: '5px',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridGap: '10px',
      },
    },
    socialIcon: {
      height: '23px',
      marginRight: '10px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginRight: '0px',
      },
    },
    linkContainer: {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
      display: 'flex',
      justifySelf: 'end',
      marginTop: '10px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginTop: '0px',
        gridColumn: '1 / 2',
        gridRow: '1 / 2',
        justifySelf: 'center',
        flexDirection: 'column',
      },
    },
    link: {
      color: theme.colors.light,
      opacity: 0.87,
      marginLeft: '30px',
      fontSize: '14px',
      fontWeight: 'bold',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginBottom: '25px',
        marginLeft: '0px',
        textAlign: 'center',
        '&:last-child': {
          marginBottom: '0px',
        },
      },
      cursor: 'pointer',
    },
    urlContainer: {
      gridColumn: '2 / 3',
      gridRow: '2 / 3',
      justifySelf: 'end',
      marginTop: '15px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridColumn: '1 / 2',
        gridRow: '4 / 5',
        justifySelf: 'center',
        marginTop: '0px',
      },
    },
    url: {
      color: theme.colors.light,
      opacity: 0.5,
      textAlign: 'right',
      marginTop: '10px',
      fontSize: '13px',
      [`@media (max-width: ${SIZES.md}px)`]: {
        textAlign: 'center',
      },
    },
  })();
};
