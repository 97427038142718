import {
  CreateMaintenanceRequestStartPayload,
  CreateMaintenanceRequestUpdatePayload,
  Images,
  JobMedia,
  SaveImageResponse,
} from '../../models/maintenance/maintenance';
import { DAYS, DAYS_FULL, MAX_IMAGE_FILE_SIZE, MAX_VIDEO_FILE_SIZE } from './MaintenanceConstants';
import { isUndefined } from 'lodash';
import { FormData } from './MaintenanceConstants';
import { Content, FileLink } from '../../services/MultipartFormData.data';
import { getCurrentThemeKey, getThemeKey } from '../../theme/Theme';

export const getRequestConfig = (
  description: string,
  propertyId: number,
  photos: SaveImageResponse[] = [],
  email: string,
  phone: string,
) => {
  const updatedPhotosList = photos.map((photo) => photo.uuid);
  return {
    description,
    propertyId,
    photos: updatedPhotosList,
    email,
    phone,
  };
};

export const getPostData = (
  data: FormData,
  propertyId: number,
  leaseId?: number,
  userId?: number,
): CreateMaintenanceRequestStartPayload => {
  const themeKey = getThemeKey();
  const photosAndVideos = [...data.jobPhotos, ...data.jobVideos];
  const postData = {
    data: {
      propertyId,
      leaseId,
      tenantIds: userId ? [userId] : [],
      status: 'received',
      location: data.area,
      app: themeKey,
      details: {
        images: data.jobPhotos
          ? (data.jobPhotos.filter((image) => image.hasOwnProperty('link')) as FileLink[])
          : [],
        title: data.jobTitle,
        description: data.jobDesc,
        parkingAvailability: data.parking,
        clearAccess: !data.access,
        propertyAccess:
          data.accessType === null
            ? null
            : {
                propertyAccessType: data.accessType,
                description: '',
              },
        preferredAvailability:
          data.timeSlot === null && data.otherTime.length === 0 && data.days.length === 0
            ? null
            : {
                timeSlot: data.timeSlot,
                otherTime: data.otherTime,
                days:
                  data.days.length === 0
                    ? []
                    : data.days.split(',').map((day: string) => DAYS_FULL[day]),
              },
        unSupervisedMinors: data.minor,
        appliance:
          data.appliance === null
            ? null
            : {
                present: data.appliance,
                make: data.make,
                model: data.model,
                warranty: data.warranty,
              },
        pet:
          data.dog === null
            ? null
            : {
                present: data.dog,
                description: data.dogDesc,
              },
        stairs:
          data.stairs === null
            ? null
            : {
                present: data.stairs,
                description: data.stairsDesc,
              },
        attendPermissionWithoutTenant: data.attendPermissionWithoutTenant,
      },
    },
    images: photosAndVideos
      ? (photosAndVideos.filter((image) => image.hasOwnProperty('contentRef')) as Content[])
      : [],
  };
  return postData;
};

export const getUpdateData = (
  id: string,
  data: FormData,
  propertyId: number,
  agencyBranchId: number,
  agency: number,
  tenantIds: number[],
): CreateMaintenanceRequestUpdatePayload => {
  const photosAndVideos = [...data.jobPhotos, ...data.jobVideos];
  const themeKey = getThemeKey();

  const postData = {
    data: {
      propertyId,
      agencyBranchId,
      agency,
      tenantIds,
      status: 'received',
      app: themeKey,
      location: data.area as string,
      details: {
        images: data.jobPhotos
          ? (data.jobPhotos.filter((image) => image.hasOwnProperty('link')) as FileLink[])
          : [],
        title: data.jobTitle as string,
        description: data.jobDesc as string,
        parkingAvailability: data.parking,
        clearAccess: !data.access,
        attendPermissionWithoutTenant: false,
        propertyAccess:
          data.accessType === null
            ? null
            : {
                propertyAccessType: data.accessType,
                description: '',
              },
        preferredAvailability:
          data.timeSlot === null && data.otherTime.length === 0 && data.days.length === 0
            ? null
            : {
                timeSlot: data.timeSlot,
                otherTime: data.otherTime,
                days:
                  data.days.length === 0
                    ? []
                    : data.days.split(',').map((day: string) => DAYS_FULL[day]),
              },
        unSupervisedMinors: data.minor,
        appliance:
          data.appliance === null
            ? null
            : {
                present: data.appliance,
                make: data.make,
                model: data.model,
                warranty: data.warranty,
              },
        pet:
          data.dog === null
            ? null
            : {
                present: data.dog,
                description: data.dogDesc,
              },
        stairs:
          data.stairs === null
            ? null
            : {
                present: data.stairs,
                description: data.stairsDesc,
              },
      },
    },
    images: photosAndVideos
      ? (photosAndVideos.filter((image) => image.hasOwnProperty('contentRef')) as Content[])
      : [],
    id,
  };
  return postData;
};

export const getFileSize = (x: number) => {
  let l = 0,
    n = x;
  while (l < 2) {
    n = n / 1024;
    ++l;
  }
  return parseFloat(n.toFixed(1));
};

export const fileSizeValidation = (photos: JobMedia[], videos: Images[]) => {
  const isValidImages =
    photos.findIndex((data) => data.size && data.size > MAX_IMAGE_FILE_SIZE) > -1;
  const isValidaVideos =
    videos.findIndex((data) => data.size && data.size > MAX_VIDEO_FILE_SIZE) > -1;
  return !(isValidImages || isValidaVideos);
};

export const getSelectedDays = (days: string): number[] => {
  const daysArray = days.split(',');

  const selectedDays = daysArray.map((day) => {
    const index = DAYS.indexOf(day);
    return index !== -1 ? index : -1;
  });

  return selectedDays.filter((index) => index !== -1);
};

export const shortenDays = (fullDays: string[] | undefined): string => {
  if (fullDays === undefined) return '';
  const shortenedDays = fullDays.map((fullDay) => DAYS_FULL[fullDay]);
  return shortenedDays.join(',');
};
