/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  primaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        ...smallPageContainer,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 8,
      },
    },
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingTop: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1000,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },

    selectServiceContainer: {
      overflow: 'hidden',
      borderWidth: 1,
    },
    selectServiceWrapper: {
      padding: `0 !important`,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.highlightButtonColor,
      width: `calc(100% + ${theme.units.spacing * 8})`,
      padding: theme.units.spacing * 8,
      [`@media (max-width: ${SIZES.md}px)`]: {
        padding: theme.units.spacing * 4,
        flexDirection: 'column',
      },
    },
    headerTextContent: {},
    headerTitle: {
      marginBottom: theme.units.spacing * 6,
      marginRight: theme.units.spacing * 8,
    },
    headerSubtitle: {
      maxWidth: 350,
      fontWeight: getFonts('Regular'),
      opacity: 0.8,
    },
    headerImage: {
      flexShrink: 0,
      height: 280,
      objectFit: 'contain',
      objectPosition: 'center',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginTop: theme.units.spacing * 4,
      },
    },
    selectSection: {
      marginTop: theme.units.spacing * 2,
      padding: theme.units.spacing * 8,
      [`@media (max-width: ${SIZES.md}px)`]: {
        padding: theme.units.spacing * 4,
      },
    },
    selectTitle: {
      marginBottom: theme.units.spacing * 8,
    },
    selectSubtitle: {
      marginBottom: theme.units.spacing * 8,
    },
    selectItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column',
      },
    },
    inactiveServiceContainer: {
      flex: 1,
      borderColor: theme.colors.lightGrey,
      backgroundColor: theme.colors.lightGrey,
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '100%',
      },
    },
    inactiveItemIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 4,
      opacity: 0.3,
    },
    inactiveServiceContent: {},
    selectItemContainer: {
      flex: 1,
      // borderWidth: 1,
      borderColor: theme.colors.lightGrey,
      backgroundColor: theme.colors.light,
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '100%',
      },
    },
    selectItemContainerActive: {
      flex: 1,
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '100%',
      },
      // borderWidth: 1,
    },
    selectItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.units.spacing * 6,
      maxHeight: 24,
    },
    selectItemIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 4,
    },
    selectItemText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.l,
    },
    inactiveItemText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.l,
      opacity: 0.3,
    },
    inactiveItemSubText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.s,
      opacity: 0.3,
    },
    partnersSection: {
      marginTop: theme.units.spacing * 2,
      padding: theme.units.spacing * 8,
      [`@media (max-width: ${SIZES.md}px)`]: {
        padding: theme.units.spacing * 4,
      },
    },
    downloadContainer: {
      backgroundColor: theme.colors.highlightButtonColor,
      padding: theme.units.spacing * 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    downloadLinks: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: 'auto',
    },
    downloadLink: {
      width: '100%',
      height: 45,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 8,
    },
    downloadButton: {
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'none',
      },
      height: 30,
      width: 200,
    },
    partnersTitle: {
      marginBottom: theme.units.spacing * 8,
    },
    partners: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
      gridGap: theme.units.spacing * 6,
      alignItems: 'center',
      justifyItems: 'center',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
      },
    },
    partnerImage: {
      height: 50,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    needsContainer: {
      borderWidth: 1,
      marginTop: theme.units.spacing * 8,
    },
    needWrapper: {
      padding: theme.units.spacing * 8,
      [`@media (max-width: ${SIZES.md}px)`]: {
        padding: theme.units.spacing * 4,
      },
    },
    questionContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 70,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'unset',
        marginBottom: theme.units.spacing * 2,
      },
    },
    question: {
      marginTop: theme.units.spacing * 4,
    },
    questionText: {
      width: 400,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginBottom: theme.units.spacing * 2,
      },
    },
    errorMessage: {
      position: 'absolute',
      right: 0,
      bottom: -8,
      fontSize: 14,
    },
    buttonContainer: {
      margin: 'auto',
      marginTop: theme.units.spacing * 8,
      paddingBottom: '80px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    submitButton: {
      minWidth: 200,
    },
    loadingServicesContainer: {
      height: 500,
    },
    loadingItemService: {
      height: 60,
      flex: 1,
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 2,
        paddingBottom: theme.units.spacing * 2,
        width: '100%',
      },
    },
    checkboxContainer: {
      marginTop: theme.units.spacing * 8,
      display: 'flex',
      alignItems: 'center',
    },
    checkBoxText: {
      fontSize: theme.font.s,
      color: theme.colors.subtitle,
      marginLeft: theme.units.spacing * 2,
      '& > div': {
        '& > a': {
          color: theme.colors.buttonPrimary,
        },
      },
    },
    movingInfo: {
      backgroundColor: theme.colors.lightGreen,
      borderRadius: theme.units.borderRadius,
      color: theme.colors.darkGreen,
      padding: theme.units.spacing * 4,
    },
    helpIcon: {
      cursor: 'pointer',
      alignSelf: 'baseline',
      width: 24,
      height: 24,
    },
    helpText: {
      marginRight: 'auto',
      marginLeft: theme.units.spacing * 4,
      width: 380,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginBottom: theme.units.spacing * 2,
      },
    },
    popupContainer: {
      padding: theme.units.spacing * 4,
    },
    popupTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: theme.units.spacing * 4,
    },
    popupTitle: {
      fontSize: 24,
    },
    popupAction: {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
    popupContent: {
      '& > ul': {
        paddingInlineStart: 0,
      },
    },
  })();
};
