/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './FileUploadsStyles';
import cancelIcon from '../../assets/cancelIcon.svg';
import addFileIcon from '../../assets/attach-file-24-px.svg';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { FileData } from '../../models/files/files';
import { compressIfImage } from '../../helpers/FileUploadHelper';
import { FileUploadActions } from '../../store/actions/FileUploadActions';
import { ApplicationState } from '../../store/RootReducer';
import { Typography, IconButton, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

interface FileUploadProps
  extends ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {
  multiple: boolean;
  // id to differentiate between two same component state
  id: string;
  maxFiles?: number;
  value: FileData[];
  placeholder?: string;
  acceptedTypes?: string;
  error?: boolean;
  // pass the api callback if there is a different api to upload file other than default
  uploadFile?: (data: File | File[]) => void;
  onDelete: (file: FileData) => void;
  onSuccess: (file: FileData | FileData[]) => void;
}

export const ColorCircularProgress = withStyles({
  root: {
    color: 'rgb(0, 159, 179)',
  },
})(CircularProgress);

const FileUploads: FC<FileUploadProps> = ({
  id,
  callerId,
  acceptedTypes,
  multiple,
  placeholder,
  value,
  maxFiles,
  fileData,
  error,
  isUploading,
  deleteFile,
  postMultipleFile,
  uploadFile,
  onSuccess,
  onDelete,
  resetFileState,
}) => {
  // refs
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const [filesToUpload, setFilesToUpload] = useState<string[]>([]);
  const [fileLimitError, setFileLimitError] = useState(false);
  const defaultAcceptedTypes = '.txt, .docx, .csv, .pdf, .png, .jpeg, .jpg';
  const classes = useStyles();

  const getShowFileInputValue = () => {
    let showUploadFile = true;
    if (multiple && maxFiles && value.length >= maxFiles) {
      showUploadFile = false;
    } else if (!multiple && value.length >= 1) {
      showUploadFile = false;
    }
    return showUploadFile;
  };

  const [showUploadInput, setShowUploadInput] = useState(getShowFileInputValue());

  useEffect(() => {
    if (fileData && callerId === id) {
      onSuccess(fileData as FileData[]);
      setFilesToUpload([]);
      setTimeout(() => {
        resetFileState();
      });
    }
  }, [fileData, callerId]);

  useEffect(() => {
    setShowUploadInput(getShowFileInputValue());
  }, [value]);

  const handleFileUploaded = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const originalFiles = Array.from(files!);
    const compressedFiles = await Promise.all(originalFiles.map((file) => compressIfImage(file)));

    setShowUploadInput(false);
    if (multiple) {
      if (compressedFiles.length + value.length > (maxFiles || Number.MAX_SAFE_INTEGER)) {
        setFileLimitError(true);
        setShowUploadInput(true);
      } else {
        if (uploadFile) {
          uploadFile(compressedFiles);
        } else {
          postMultipleFile(compressedFiles, id);
        }
        setFileLimitError(false);
        setFilesToUpload(compressedFiles.map((file) => file.name));
      }
    } else {
      setFilesToUpload(compressedFiles.map((file) => file.name));
      if (uploadFile) {
        uploadFile(compressedFiles[0]);
      } else {
        postMultipleFile([compressedFiles[0]], id);
      }
    }
  };

  const handleFileDelete = (file: FileData) => {
    deleteFile(file.link || file.name!);
    onDelete(file);
    resetFileState();
  };

  return (
    <>
      {value.map((file) => (
        <div className={classes.displayFile}>
          <Typography variant="body2">{file.name || 'Attachment'}</Typography>
          <IconButton onMouseDown={() => handleFileDelete(file)} style={{ marginLeft: 'auto' }}>
            <img src={cancelIcon} alt="delete file" className={classes.cancelIcon} />
          </IconButton>
          <img style={{ width: 25, height: 25 }} src={CompletedIcon} alt="" />
        </div>
      ))}
      {filesToUpload.map((fileName) => (
        <div className={classes.displayFile}>
          <Typography variant="body2">{fileName || 'Attachment'}</Typography>
          <IconButton>
            <ColorCircularProgress size="20px" thickness={5} />
          </IconButton>
        </div>
      ))}
      {showUploadInput && (
        // Added id for the following div for Automation
        <div
          className={`${classes.uploadFile} ${error && classes.errorLine}`}
          id="file-upload"
          onMouseDown={() => fileUploadRef.current!.click()}
        >
          <Typography variant="body2">{placeholder || 'Select files to upload'}</Typography>
          <IconButton style={{ position: 'relative', left: 12, marginLeft: 'auto' }}>
            <img src={addFileIcon} alt="upload file" className={classes.fileImage} />
          </IconButton>
          {error && <img style={{ marginLeft: 12 }} src={IncompleteIcon} alt="" />}
        </div>
      )}
      {fileLimitError && (
        <Typography variant="caption" color="error">
          {`number of files cannot exceed ${maxFiles}`}
        </Typography>
      )}
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: 'none' }}
        accept={acceptedTypes || defaultAcceptedTypes}
        multiple={multiple}
        onChange={handleFileUploaded}
        onMouseDown={() => {
          // clearing value on click to trigger onChange even after selecting same file again
          fileUploadRef.current!.value = '';
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postMultipleFile: (data: File[], id: string) => {
    dispatch(FileUploadActions.postMultiFileUploadRequest({ file: data, id }));
  },
  deleteFile: (key: string) => {
    dispatch(FileUploadActions.deleteFileRequest({ key }));
  },
  resetFileState: () => dispatch(FileUploadActions.resetFileState()),
});

const mapStateToProps = (state: ApplicationState) => ({
  fileData: state.fileUploadState.fileUpload,
  callerId: state.fileUploadState.callerId,
  isUploading: state.fileUploadState.isUploading,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploads);
