import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen, maxContentWidth, smallPageContainer } from '../../../../theme/GlobalStyles';
import { SIZES } from '../../../../constants/Sizes';

const MOBILE_WIDTH = 86;
const DESKTOP_WIDTH = 220;

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    footerContainer: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      boxShadow: '0px 15px 20px #555',
      width: '100vw',
      display: 'flex',
      backgroundColor: theme.colors.light,
    },
    menuBuffer: {
      flexShrink: 0,
      width: DESKTOP_WIDTH,
      transition: 'all 0.3s ease 0.1s',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        width: MOBILE_WIDTH,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: 0,
      },
    },
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
      paddingTop: 0,
      paddingBottom: 0,
    },
    footerContent: {
      maxWidth: 1200,
    },
    footerWrapper: {
      padding: theme.units.spacing * 4,
      // paddingLeft: theme.units.spacing * 12,
      // paddingRight: theme.units.spacing * 12,
      display: 'flex',
      alignItems: 'center',
    },
    leftContent: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: theme.units.spacing * 2,
    },
    compareIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      ...actionGreen,
    },
    compareText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.m,
      color: theme.colors.black87,
    },
    cartContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 8,
      padding: theme.units.spacing * 2,
      marginRight: theme.units.spacing,
      cursor: 'pointer',
      position: 'relative',
    },
    cartIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      filter: 'invert(1)',
    },
    cartCount: {
      position: 'absolute',
      right: -4,
      top: -4,
      width: 14,
      height: 14,
      backgroundColor: theme.colors.lightPurple,
      border: `${theme.colors.light} 1px solid`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: theme.colors.sortedPurple,
      fontWeight: getFonts('Medium'),
      fontSize: 11,
    },
    cartText: {
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.m,
      color: theme.colors.black87,
      cursor: 'pointer',
    },
    buttonsContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: theme.units.spacing * 2,
    },
    mobileButton: {
      [`@media (max-width: ${SIZES.md}px)`]: {
        minWidth: '70px !important',
      },
    },
  })();
};
