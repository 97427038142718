import {
  ApplicationRankingRequest,
  ApplicationRequest,
  ApplicationSubmitRequest,
  LandlordAvailableServicesRequest,
  LandlordServices,
  MaintenanceRequest,
  MaintenanceResponse,
  MRQuoteRequest,
  MRTradeRequest,
  Property,
  PropertyApplication,
  PropertyRequest,
  PropertyResponse,
  TasksResponse,
  UpdateMRStatusRequest,
} from '../../models/landlord/LandlordProperty';
import { StoreAction } from '../StoreHelper';

export enum LandlordActionTypes {
  SELECTED_LANDLORD_PROPERTY = 'SELECTED_LANDLORD_PROPERTY',
  GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST',
  GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS',
  GET_PROPERTIES_ERROR = 'GET_PROPERTIES_ERROR',
  GET_PROPERTIES_SEARCH_SUCCESS = 'GET_PROPERTIES_SEARCH_SUCCESS',
  RESET_PROPERTY_SEARCH = 'RESET_PROPERTY_SEARCH',
  GET_DASHBOARD_PROPERTIES_SUCCESS = 'GET_DASHBOARD_PROPERTIES_SUCCESS',
  GET_APPLICATIONS_REQUEST = 'GET_APPLICATIONS_REQUEST',
  GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR',
  APPLICATION_SUBMIT_REQUEST = 'APPLICATION_SUBMIT_REQUEST',
  APPLICATION_SUBMIT_SUCCESS = 'APPLICATION_SUBMIT_SUCCESS',
  APPLICATION_SUBMIT_ERROR = 'APPLICATION_SUBMIT_ERROR',
  GET_TASKS_REQUEST = 'GET_TASKS_REQUEST',
  GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS',
  GET_TASKS_ERROR = 'GET_TASKS_ERROR',
  GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST',
  GET_MAINTENANCE_REQUEST_SUCCESS = 'GET_MAINTENANCE_REQUEST_SUCCESS',
  GET_MAINTENANCE_QUOTE_SUCCESS = 'GET_MAINTENANCE_QUOTE_SUCCESS',
  GET_MAINTENANCE_ATTENDING_SUCCESS = 'GET_MAINTENANCE_ATTENDING_SUCCESS',
  GET_MAINTENANCE_ERROR = 'GET_MAINTENANCE_ERROR',
  GET_MORE_PROPERTIES_SUCCESS = 'GET_MORE_PROPERTIES_SUCCESS',
  SELECTED_LANDLORD_MAINTENANCE = 'SELECTED_LANDLORD_MAINTENANCE',
  UPDATE_MAINTENANCE_STATUS_REQUEST = 'UPDATE_MAINTENANCE_STATUS_REQUEST',
  UPDATE_MAINTENANCE_STATUS_SUCCESS = 'UPDATE_MAINTENANCE_STATUS_SUCCESS',
  UPDATE_MAINTENANCE_STATUS_ERROR = 'UPDATE_MAINTENANCE_STATUS_ERROR',
  SUBMIT_MAINTENANCE_TRADE_REQUEST = 'SUBMIT_MAINTENANCE_TRADE_REQUEST',
  SUBMIT_MAINTENANCE_TRADE_SUCCESS = 'SUBMIT_MAINTENANCE_TRADE_SUCCESS',
  SUBMIT_MAINTENANCE_TRADE_ERROR = 'SUBMIT_MAINTENANCE_TRADE_ERROR',
  SUBMIT_MR_QUOTE_REQUEST = 'SUBMIT_MR_QUOTE_REQUEST',
  SUBMIT_MR_QUOTE_SUCCESS = 'SUBMIT_MR_QUOTE_SUCCESS',
  SUBMIT_MR_QUOTE_ERROR = 'SUBMIT_MR_QUOTE_ERROR',
  GET_MAINTENANCE_HISTORY_SUCCESS = 'GET_MAINTENANCE_HISTORY_SUCCESS',
  SET_LANDLORD_DASHBOARD_REFRESH = 'SET_LANDLORD_DASHBOARD_REFRESH',
  SET_LANDLORD_PROPERTIES_REFRESH = 'SET_LANDLORD_PROPERTIES_REFRESH',
  SET_LANDLORD_MAINTENANCE_REFRESH = 'SET_LANDLORD_MAINTENANCE_REFRESH',
  SET_LANDLORD_APPLICATIONS_REFRESH = 'SET_LANDLORD_APPLICATIONS_REFRESH',
  UPDATE_MAINTENANCE_HISTORY = 'UPDATE_MAINTENANCE_HISTORY',
  GET_LANDLORD_AVAILABLE_SERVICES = 'GET_LANDLORD_AVAILABLE_SERVICES',
  GET_LANDLORD_AVAILABLE_SERVICES_SUCCESS = 'GET_LANDLORD_AVAILABLE_SERVICES_SUCCESS',
  GET_LANDLORD_AVAILABLE_SERVICES_ERROR = 'GET_LANDLORD_AVAILABLE_SERVICES_ERROR',
  UPDATE_APPLCIATION_RANKING_REQUEST = 'UPDATE_APPLCIATION_RANKING_REQUEST',
  UPDATE_APPLCIATION_RANKING_SUCCESS = 'UPDATE_APPLCIATION_RANKING_SUCCESS',
  UPDATE_APPLCIATION_RANKING_ERROR = 'UPDATE_APPLCIATION_RANKING_ERROR',
}

export type LandlordActionPayload =
  | number
  | PropertyRequest
  | PropertyResponse
  | ApplicationRequest
  | PropertyApplication[]
  | ApplicationSubmitRequest
  | TasksResponse
  | MaintenanceRequest
  | MaintenanceResponse[]
  | MaintenanceResponse
  | UpdateMRStatusRequest
  | MRTradeRequest
  | MRQuoteRequest
  | LandlordAvailableServicesRequest
  | LandlordServices
  | ApplicationRankingRequest
  | null
  | Error;

export type LandlordAction = StoreAction<LandlordActionTypes, LandlordActionPayload>;

export class LandlordActions {
  public static setSelectedLandlordProperty(property: Property): LandlordAction {
    return {
      type: LandlordActionTypes.SELECTED_LANDLORD_PROPERTY,
      data: property,
    };
  }

  public static getProperties(data: PropertyRequest): LandlordAction {
    return {
      type: LandlordActionTypes.GET_PROPERTIES_REQUEST,
      data,
    };
  }

  public static getPropertiesSuccess(data: PropertyResponse): LandlordAction {
    return {
      type: LandlordActionTypes.GET_PROPERTIES_SUCCESS,
      data,
    };
  }

  public static getPropertiesError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.GET_PROPERTIES_ERROR,
      data,
    };
  }

  public static getPropertiesSearchSuccess(data: PropertyResponse): LandlordAction {
    return {
      type: LandlordActionTypes.GET_PROPERTIES_SEARCH_SUCCESS,
      data,
    };
  }

  public static resetPropertySearch(): LandlordAction {
    return {
      type: LandlordActionTypes.RESET_PROPERTY_SEARCH,
      data: null,
    };
  }

  public static getDashboardPropertiesSuccess(data: PropertyResponse): LandlordAction {
    return {
      type: LandlordActionTypes.GET_DASHBOARD_PROPERTIES_SUCCESS,
      data,
    };
  }

  public static getApplications(data: ApplicationRequest): LandlordAction {
    return {
      type: LandlordActionTypes.GET_APPLICATIONS_REQUEST,
      data,
    };
  }

  public static getApplicationsSuccess(data: PropertyApplication[]): LandlordAction {
    return {
      type: LandlordActionTypes.GET_APPLICATIONS_SUCCESS,
      data,
    };
  }
  public static getApplicationsError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.GET_APPLICATIONS_ERROR,
      data,
    };
  }

  public static submitApplicationRequest(data: ApplicationSubmitRequest): LandlordAction {
    return {
      type: LandlordActionTypes.APPLICATION_SUBMIT_REQUEST,
      data,
    };
  }

  public static submitApplicationSuccess(): LandlordAction {
    return {
      type: LandlordActionTypes.APPLICATION_SUBMIT_SUCCESS,
      data: null,
    };
  }
  public static submitApplicationError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.APPLICATION_SUBMIT_ERROR,
      data,
    };
  }

  public static getTasksRequest(): LandlordAction {
    return {
      type: LandlordActionTypes.GET_TASKS_REQUEST,
      data: null,
    };
  }

  public static getTasksSuccess(data: TasksResponse): LandlordAction {
    return {
      type: LandlordActionTypes.GET_TASKS_SUCCESS,
      data,
    };
  }
  public static getTasksError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.GET_TASKS_ERROR,
      data,
    };
  }

  public static getMaintenanceRequest(data: MaintenanceRequest): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_REQUEST,
      data,
    };
  }

  public static getMaintenanceRequestSuccess(data: MaintenanceResponse[]): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_REQUEST_SUCCESS,
      data,
    };
  }

  public static getMaintenanceQuoteSuccess(data: MaintenanceResponse[]): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_QUOTE_SUCCESS,
      data,
    };
  }

  public static getMaintenanceAttendingSuccess(data: MaintenanceResponse[]): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_ATTENDING_SUCCESS,
      data,
    };
  }

  public static getMaintenanceError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_ERROR,
      data,
    };
  }
  public static setSelectedMaintenance(data: MaintenanceResponse): LandlordAction {
    return {
      type: LandlordActionTypes.SELECTED_LANDLORD_MAINTENANCE,
      data,
    };
  }

  public static updateMRStatusRequest(data: UpdateMRStatusRequest): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_MAINTENANCE_STATUS_REQUEST,
      data,
    };
  }

  public static updateMRStatusSuccess(): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_MAINTENANCE_STATUS_SUCCESS,
      data: null,
    };
  }
  public static updateMRStatusError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_MAINTENANCE_STATUS_ERROR,
      data,
    };
  }

  public static submitMRTradeRequest(data: MRTradeRequest): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MAINTENANCE_TRADE_REQUEST,
      data,
    };
  }

  public static submitMRTradeSuccess(): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MAINTENANCE_TRADE_SUCCESS,
      data: null,
    };
  }
  public static submitMRTradeError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MAINTENANCE_TRADE_ERROR,
      data,
    };
  }

  public static submitMRQuoteRequest(data: MRQuoteRequest): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MR_QUOTE_REQUEST,
      data,
    };
  }

  public static submitMRQuoteSuccess(): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MR_QUOTE_SUCCESS,
      data: null,
    };
  }
  public static submitMRQuoteError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.SUBMIT_MR_QUOTE_ERROR,
      data,
    };
  }

  public static getMaintenanceHistorySuccess(data: MaintenanceResponse[]): LandlordAction {
    return {
      type: LandlordActionTypes.GET_MAINTENANCE_HISTORY_SUCCESS,
      data,
    };
  }
  public static setLandlordDashboardRefresh(): LandlordAction {
    return {
      type: LandlordActionTypes.SET_LANDLORD_DASHBOARD_REFRESH,
      data: null,
    };
  }
  public static setLandlordPropertiesRefresh(): LandlordAction {
    return {
      type: LandlordActionTypes.SET_LANDLORD_PROPERTIES_REFRESH,
      data: null,
    };
  }
  public static setLandlordMaintenanceRefresh(): LandlordAction {
    return {
      type: LandlordActionTypes.SET_LANDLORD_MAINTENANCE_REFRESH,
      data: null,
    };
  }
  public static setLandlordApplicationsRefresh(): LandlordAction {
    return {
      type: LandlordActionTypes.SET_LANDLORD_APPLICATIONS_REFRESH,
      data: null,
    };
  }

  public static updateMaintenaceHistory(data: MaintenanceResponse[]): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_MAINTENANCE_HISTORY,
      data,
    };
  }

  public static getLandlordAvailableServices(
    data: LandlordAvailableServicesRequest,
  ): LandlordAction {
    return {
      type: LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES,
      data,
    };
  }
  public static getLandlordAvailableServicesSuccess(data: LandlordServices): LandlordAction {
    return {
      type: LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES_SUCCESS,
      data,
    };
  }
  public static getLandlordAvailableServicesError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES_ERROR,
      data,
    };
  }

  public static updateApplicationRankingRequest(data: ApplicationRankingRequest): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_APPLCIATION_RANKING_REQUEST,
      data,
    };
  }

  public static updateApplicationRankingsSuccess(): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_APPLCIATION_RANKING_SUCCESS,
      data: null,
    };
  }

  public static updateApplicationRankingError(data: Error): LandlordAction {
    return {
      type: LandlordActionTypes.UPDATE_APPLCIATION_RANKING_ERROR,
      data,
    };
  }
}
