import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    actionCardContainer: {
      marginTop: theme.units.spacing * 3,
      borderRadius: theme.units.borderRadius,
      minHeight: 160,
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    background: {
      backgroundColor: theme.colors.info,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    flex: {
      flex: 1,
    },
    alert: {
      backgroundColor: theme.colors.focus,
    },
    marginSides: {
      marginHorizontal: theme.units.spacing * 3,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      // alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 8,
    },
    title: {
      color: theme.colors.light,
      fontSize: theme.font.xl,
      fontWeight: getFonts('Medium'),
      flexWrap: 'wrap',
      flexShrink: 1,
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      marginLeft: 'auto',
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
    crossContainer: {
      marginLeft: theme.units.spacing * 3,
    },
    cross: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      padding: 16,
      zIndex: 1,
    },
    imageContainer: {
      width: 50,
      height: 50,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flex: 1,
    },
    text: {
      lineHeight: 20,
      color: theme.colors.light,
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
    },
    textDark: {
      color: `${theme.colors.black} !important`,
    },
    marginTopNone: {
      marginTop: 0,
    },
    actionContainer: {
      marginTop: theme.units.spacing * 3,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
    },
    action: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    actionIcon: {
      width: theme.units.iconSize / 1.4,
      height: theme.units.iconSize / 1.4,
      marginLeft: theme.units.spacing * 2,
      filter: 'invert(1)',
    },
    darkIcon: {
      filter: 'grayscale(100%)',
    },
    buttonText: {
      color: theme.colors.light,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    rightText: {
      color: theme.colors.subtitle,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    link: {
      color: theme.colors.success,
    },
  })();
};
