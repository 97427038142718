import { useContext } from 'react';
import { MaintenanceResponse, PreferredAvailability } from '../../models/landlord/LandlordProperty';
import { getJobs, getQuotes } from '../landlordMaintenanceInfo/LandlordMaintenanceInfoUtils';
import { ThemeKey, getCurrentThemeKey, getThemeKey, theme } from '../../theme/Theme';

export const INCLUSION_KEY = 'inclusions';

export const SUMMARY_KEY = 'featureDetails';

export const getStatusInfo = (maintenance: MaintenanceResponse) => {
  let statusLabel = '';
  let statusColor = '';
  const isPrivateWorks = !!(
    maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
  );
  const themeKey = getThemeKey();

  switch (true) {
    case maintenance.isTenantRequested:
      statusLabel =
        themeKey === ThemeKey.SWITCH
          ? 'Hold tight, a team member will be in touch soon!'
          : isPrivateWorks
          ? 'Hold tight, our trades will be in touch soon!'
          : 'Hold tight, your property manager will be in touch soon!';
      statusColor = theme.colors.statusRequested;
      break;
    case maintenance.isTenantReviewed:
      statusLabel = getScheduledStatus(maintenance);
      statusColor = theme.colors.statusReviewed;
      break;
    case maintenance.isTenantScheduled:
      statusLabel = 'Scheduled';
      statusColor = theme.colors.statusScheduled;
      break;
    case maintenance.isTenantCompleted:
      statusLabel = 'Completed';
      statusColor = theme.colors.statusCompleted;
      break;
    default:
      statusLabel =
        themeKey === ThemeKey.SWITCH
          ? 'Hold tight, a team member will be in touch soon!'
          : 'Hold tight, our trades will be in touch soon!';
      statusColor = theme.colors.statusRequested;
      break;
  }
  return { statusLabel, statusColor };
};

export const getScheduledStatus = (maintenance: MaintenanceResponse) => {
  const themeKey = getThemeKey();

  if (maintenance.status === 'landlord_attending') {
    return 'Landlord attending';
  } else if (maintenance.status === 'other_attending') {
    return 'Other attending';
  } else if (maintenance.status === 'in_progress') {
    const jobs = getJobs(maintenance);
    const quotes = getQuotes(maintenance);
    let label = '';
    if (jobs.length > 0) {
      label =
        themeKey === ThemeKey.SWITCH
          ? 'Hold tight, your request will be scheduled soon'
          : 'Hold tight, trade will confirm booking soon';
    } else if (quotes.length > 0) {
      label = 'Quotes received';
    } else {
      label = 'Quotes received';
    }
    return label;
  } else {
    return 'Quotes received';
  }
};

export const getMaintenanceDetailHeader = (maintenance: MaintenanceResponse) => {
  const privateWorks = maintenance.privateWorksDetails;
  const isPrivateWorks = !!(
    maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
  );
  if (isPrivateWorks && privateWorks) {
    return privateWorks.workflowType === 'PACKAGE' ? 'Package details' : 'Booking details';
  } else {
    return 'Maintenance details';
  }
};

export const getMaintenanceDetailTitle = (maintenance: MaintenanceResponse) => {
  const privateWorks = maintenance.privateWorksDetails;
  const isPrivateWorks = !!(
    maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
  );
  if (isPrivateWorks && privateWorks) {
    return privateWorks.workflowType === 'PACKAGE' ? 'Package: ' : 'Title: ';
  } else {
    return 'Title: ';
  }
};

export const getRatingSubmittedFlag = (maintenance: MaintenanceResponse) => {
  const jobs = getJobs(maintenance);
  const ratingSubmitted = jobs && jobs.length > 0 ? jobs[0].ratingSubmitted : false;
  return ratingSubmitted;
};

export const getAdditionalInfo = (maintenance: MaintenanceResponse) => {
  const {
    propertyAccess,
    preferredAvailability,
    unSupervisedMinors,
    pet,
    clearAccess,
    stairs,
    parkingAvailability,
  } = maintenance.details;
  const themeKey = getThemeKey();

  return [
    {
      key: 'Property Access',
      value: propertyAccess
        ? propertyAccess.propertyAccessType === 'COLLECT_KEY'
          ? 'Collect keys from real estate agent'
          : themeKey === ThemeKey.NAX
          ? 'Contact me'
          : 'Contact home owner/tenant'
        : 'NA',
    },
    {
      key: 'Preferred time',
      value: preferredAvailability
        ? preferredAvailability.timeSlot === 'other'
          ? `Othen ( ${preferredAvailability.otherTime} )`
          : preferredAvailability.timeSlot
        : 'NA',
    },
    {
      key: 'Preferred days',
      value: preferredAvailability ? renderDays(preferredAvailability) : 'NA',
    },
    {
      key: 'No unaccompanied minor',
      value: unSupervisedMinors ? 'No' : 'Yes',
    },
    {
      key: 'Pet onsite',
      value: pet ? (pet.present ? 'Yes' : 'No') : 'NA',
    },
    {
      key: 'Clear access to site',
      value: clearAccess === null ? (clearAccess ? 'No' : 'Yes') : 'NA',
    },
    {
      key: 'Stairs to access site',
      value: stairs ? (stairs.present ? 'Yes' : 'No') : 'NA',
    },
    {
      key: 'Parking onsite',
      value: parkingAvailability ? 'Yes' : 'NA',
    },
  ];
};

const renderDays = (preferredAvailability: PreferredAvailability) => {
  let days = '';
  if (preferredAvailability.days.includes('MONDAY')) {
    days += 'Mon,';
  }
  if (preferredAvailability.days.includes('TUESDAY')) {
    days += 'Tue,';
  }
  if (preferredAvailability.days.includes('WEDNESDAY')) {
    days += 'Wed,';
  }
  if (preferredAvailability.days.includes('THURSDAY')) {
    days += 'Thu,';
  }
  if (preferredAvailability.days.includes('FRIDAY')) {
    days += 'Fri,';
  }
  if (preferredAvailability.days.includes('SATURDAY')) {
    days += 'Sat,';
  }
  if (preferredAvailability.days.includes('SUNDAY')) {
    days += 'Sun,';
  }
  return days.substr(0, days.length - 1);
};
