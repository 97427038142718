/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */

import { AustraliaState, STATE_MAP } from '../models/australiaStates/australiaStates';

export const extractState = (address: string): AustraliaState | null => {
  const regEx = new RegExp(
    /New\ South\ Wales|NSW|Victoria|VIC|Queensland|QLD|Australian\ Capital\ Territory|ACT|South\ Australia|SA|West\ Australia|WA|Tasmania|TAS|Northern\ Territory|NT\b\/?/gi,
  );

  // Returns array of matches or null
  const found = regEx.exec(address);

  if (found) {
    // If found is an abbreviation e.g NSW return it
    if (found[0].length <= 3) {
      return found[0].toUpperCase() as AustraliaState;
    } else {
      // else lowercase the match, loop through a dictionary of states and return the abbreviation
      const lowercaseState = found[0].toLowerCase();
      for (const [key, value] of Object.entries(STATE_MAP)) {
        if (key === lowercaseState) {
          return value as AustraliaState;
        }
      }
    }
  }
  return null;
};
