import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';

export const LABELS = {
  TITLE: 'Essentials',
  SORRY: 'Sorry, there are no services currently available. Please check again soon.',
  BACK: 'Back to marketplace',
};

export enum Tab {
  Electricity = 'Electricity',
  Gas = 'Gas',
  Water = 'Water',
  Removals = 'Removals',
  UtilityCallback = 'Utility callback',
  Broadband = 'Broadband',
}

export const electricityTab = [
  {
    value: Tab.Electricity,
    active: false,
  },
];

export const gasTab = [
  {
    value: Tab.Gas,
    active: false,
  },
];

export const broadbandTab = [
  {
    value: Tab.Broadband,
    active: false,
  },
];

export const waterTab = [
  {
    value: Tab.Water,
    active: false,
  },
];

export const removalsTab = [
  {
    value: Tab.Removals,
    active: false,
  },
];

export const utilityTab = [
  {
    value: Tab.UtilityCallback,
    active: false,
  },
];

export const initialTags = (
  electricityServices: Services[],
  broadbandServices: Services[],
  otherServices: Services[],
) => {
  let result: TagBarItem[] = [];
  if (
    !!electricityServices.length &&
    filterServicesByStatus(onboardingScreenValidStates, electricityServices)
  ) {
    result = [...result, ...electricityTab];
  }
  if (
    !!broadbandServices.length &&
    filterServicesByStatus(onboardingScreenValidStates, broadbandServices)
  ) {
    result = [...result, ...broadbandTab];
  }
  if (otherServices.length) {
    const gas = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Gas,
    ]);
    const water = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Water,
    ]);
    const removals = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Removals,
    ]);
    const callBack = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Callback,
    ]);
    if (gas.length) {
      result = [...result, ...gasTab];
    }
    if (water.length) {
      result = [...result, ...waterTab];
    }
    if (removals.length) {
      result = [...result, ...removalsTab];
    }
    if (callBack.length) {
      result = [...result, ...utilityTab];
    }
  }
  return result;
};
