import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    loginContainer: {
      marginTop: 16,
    },
    loginText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginBottom: 16,
    },
    button: {
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
      paddingHorizontal: 16,
      paddingVertical: 8,
      width: 100,
      alignItems: 'center',
    },
    buttonText: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
    },
  })();
};
