/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/prefer-default-export */

import { findExtraServiceInfo } from '../../helpers/ServiceInfoHelper';
import { LeaseResponse } from '../../models/dashboard/dashboard';
import { Services, Suppliers } from '../../models/services/services';

export interface SupplierService {
  service: Services;
  suppliers: Suppliers[];
  showService?: boolean;
}

export const grabSuppliers = (
  services: Services[],
  searchString: string,
  onboardedProperties: LeaseResponse[] | undefined,
  selectedProperty: number,
): SupplierService[] => {
  if (searchString === '') {
    return [];
  }
  const filteredServices: SupplierService[] = services.map((service) => {
    const suppliers = findExtraServiceInfo(
      service,
      onboardedProperties![selectedProperty].availableServices,
    ).Suppliers;
    return { service, suppliers, showService: true };
  });
  const filteredSearch: SupplierService[] = filteredServices
    .map((s) => {
      let shouldShowService = false;
      const foundSuppliers = s.suppliers.filter((supplier) => {
        const supplierCompare = supplier.SupplierName.toLowerCase().includes(
          searchString.toLowerCase(),
        );
        const serviceCompare = s.service.serviceType
          .toLowerCase()
          .includes(searchString.toLowerCase());
        if (serviceCompare) {
          shouldShowService = true;
        }
        return supplierCompare || serviceCompare;
      });
      if (foundSuppliers) {
        return { service: s.service, suppliers: foundSuppliers, showService: shouldShowService };
      } else {
        return { service: s.service, suppliers: [], showService: shouldShowService };
      }
    })
    .filter((s) => !!s.suppliers.length);

  return filteredSearch;
};
