import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './HomeStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TabProps } from '../../models/dashboard/dashboard';
import { LABELS } from './HomeConstants';
import Dashboard from './components/dashboard/Dashboard';
import Activity from './components/activity/Activity';
import Request from './components/request/Request';
import TabMenu from '../../components/tabMenu/TabMenu';
import Text from '../../components/text/Text';
import { banners } from '../browse/components/marketplace/MarketplaceConstants';
import ActionCard from '../../components/actionCard/ActionCard';
import MainBanner from '../../components/mainBanner/MainBanner';
import redPeople from '../../assets/bannerPeople/red.png';
import { extractParams } from '../../navigation/NavigationUtils';
import { TabParams } from '../../navigation/NavigationConstants';

interface HomeProps extends RouteComponentProps {
  tabProps: TabProps | undefined;
}

const Home: FC<HomeProps> = ({ tabProps }) => {
  const classes = useStyles();

  const tabs = [
    { title: 'Dashboard', view: <Dashboard /> },
    { title: 'Activity', view: <Activity /> },
    { title: 'Request', view: <Request /> },
  ];

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <MainBanner
          title={LABELS.DASHBOARD_TITLE}
          subtitle={LABELS.DASHBOARD_SUBTITLE}
          image={redPeople}
          parentStyles={classes.banner}
        />
        <div className={classes.tabContainer}>
          <TabMenu tabs={tabs.map((t) => t.title)} />
        </div>
        {tabProps && tabProps.index ? tabs[tabProps.index].view : tabs[0].view}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  tabProps: state.dashboardState.tabProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
