import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';

export const LABELS = {
  TITLE: 'Protect',
  SORRY: 'Sorry, there are no services currently available. Please check again soon.',
  BACK: 'Back to marketplace',
};

export enum Tab {
  Insurance = 'Insurance',
  Security = 'Security',
}

export const insuranceTab = [
  {
    value: Tab.Insurance,
    active: false,
  },
];

export const securityTab = [
  {
    value: Tab.Security,
    active: false,
  },
];

export const initialTags = (otherServices: Services[]) => {
  let result: TagBarItem[] = [];
  if (otherServices.length) {
    const insurance = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Insurance,
    ]);
    const security = filterServicesByStatus(onboardingScreenValidStates, otherServices, [
      ServiceTypes.Security,
    ]);

    if (insurance.length) {
      result = [...result, ...insuranceTab];
    }
    if (security.length) {
      result = [...result, ...securityTab];
    }
  }
  return result;
};
