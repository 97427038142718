import { BottomNavValue, LeaseResponse, SwitchTab } from '../../models/dashboard/dashboard';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { OffersResponse } from '../../models/offers/Offers';
import { DashboardAction, DashboardActionTypes } from '../actions/DashboardActions';
import { DashboardState } from '../state/DashboardState';

export const initialState: DashboardState = {
  onboardedProperties: undefined,
  pendingProperties: undefined,
  ctaRequired: false,
  noneOnboarded: false,
  selectedProperty: 0,
  selectedTab: BottomNavValue.home,
  tabProps: undefined,
  showToast: false,
  refreshing: false,
  selectNewProperty: false,
  maintenances: [],
  maintenanceLoader: false,
  offers: undefined,
};

export const dashboardReducer = (state = initialState, action: DashboardAction): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.FETCH_PENDING_SUCCESS:
      return {
        ...state,
        pendingProperties: action.data as LeaseResponse[],
      };
    case DashboardActionTypes.FETCH_COMPLETED_SUCCESS:
      return {
        ...state,
        selectedProperty: state.selectNewProperty
          ? (action.data as LeaseResponse[]).length - 1
          : state.selectedProperty,
        selectNewProperty: false,
        onboardedProperties: (action.data as LeaseResponse[]).sort(
          (a, b) => a.property!.id - b.property!.id,
        ),
      };

    case DashboardActionTypes.GET_PROPERTY_MAINTENANCE_REQUEST:
      return {
        ...state,
        maintenances: [],
        maintenanceLoader: true,
      };
    case DashboardActionTypes.GET_PROPERTY_MAINTENANCE_SUCCESS:
      return {
        ...state,
        maintenances: action.data as MaintenanceResponse[],
        maintenanceLoader: false,
      };
    case DashboardActionTypes.GET_PROPERTY_MAINTENANCE_ERROR:
      return {
        ...state,
        maintenanceLoader: false,
      };

    case DashboardActionTypes.REFRESH_SUCCESS:
      return {
        ...state,
        refreshing: false,
        pendingProperties: action.data as LeaseResponse[],
      };

    case DashboardActionTypes.SWITCH_CTA:
      return {
        ...state,
        ctaRequired: action.data as boolean,
      };
    case DashboardActionTypes.NONE_ONBOARDED:
      return {
        ...state,
        noneOnboarded: action.data as boolean,
      };
    case DashboardActionTypes.SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.data as number,
      };
    case DashboardActionTypes.SHOW_TOAST:
      return {
        ...state,
        showToast: action.data as boolean,
      };
    case DashboardActionTypes.SWITCH_FOOTER_TAB:
      return {
        ...state,
        selectedTab: (action.data as SwitchTab).tabName,
        tabProps: (action.data as SwitchTab).tabProps,
      };
    case DashboardActionTypes.SELECT_NEW_PROPERTY:
      return {
        ...state,
        selectNewProperty: !!action.data,
      };
    case DashboardActionTypes.GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.data as OffersResponse[],
      };
    default:
      return state;
  }
};
