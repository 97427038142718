import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './EmailSentStyles';
import { LABELS } from './EmailSentConstants';
import Box from '../../components/box/Box';
import Text from '../../components/text/Text';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/NavigationUtils';
import sortedLogo from '../../assets/sorted-icon-dark.png';

interface EmailSentProps extends RouteComponentProps {}

const EmailSent: FC<EmailSentProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <Box lightBorder parentStyles={classes.boxContainer}>
              <div className={classes.centerContent}>
                <img src={sortedLogo} className={classes.sortedLogo} />
                <Text parentStyles={classes.title} textVariant="title" size="xl">
                  {LABELS.TITLE}
                </Text>
                <Text textVariant="info" parentStyles={classes.text}>
                  {LABELS.DESCRIPTION}
                </Text>
              </div>
              <div className={classes.loginRow}>
                <Button
                  parentStyles={classes.loginButton}
                  type="submit"
                  onPress={() => navigate('login')}
                >
                  {LABELS.DONE}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmailSent);
