import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import pageNotFound from '../../assets/pageNotFound.png';
import { SIZES } from '../../constants/Sizes';
import { maxContentWidth, pageContainer, smallPageContainer } from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    container: {
      backgroundImage: `url(${pageNotFound})`,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      position: 'relative',
      backgroundColor: theme.colors.light,
    },
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        ...smallPageContainer,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 8,
      },
    },
    centerPageContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 24,
      flexDirection: 'column',
    },
    subtitle: {
      fontWeight: getFonts('Medium'),
      marginTop: theme.units.spacing * 4,
      color: theme.colors.black60,
    },
    button: {
      marginTop: theme.units.spacing * 12,
    },
  })();
};
