import { CimetState } from '../state/CimetState';
import { CimetAction, CimetActionTypes } from '../actions/CimetActions';
import {
  CimetDatesResponse,
  CimetNeedsResponse,
  CimetPropertyIdServiceResponse,
} from '../../models/cimet/Cimet';
import LogRocket from 'logrocket';

export const initialState: CimetState = {
  services: undefined,
  url: undefined,
  blockedDates: undefined,
};

export const cimetReducer: (state: CimetState, action: CimetAction) => CimetState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CimetActionTypes.GET_CIMET_SERVICES_SUCCESS:
      const { propertyId, data } = action.data as CimetPropertyIdServiceResponse;
      if (!!data.email && !!data.userId) {
        LogRocket.identify(data.userId.toString(), {
          email: data.email,
        });
      }
      return {
        ...state,
        services: {
          ...state.services,
          [propertyId]: data,
        },
      };
    case CimetActionTypes.POST_CIMET_NEEDS_SUCCESS:
      return {
        ...state,
        url: (action.data as CimetNeedsResponse).url,
      };
    case CimetActionTypes.GET_CIMET_DATES_SUCCESS:
      return {
        ...state,
        blockedDates: action.data as CimetDatesResponse[],
      };
    case CimetActionTypes.RESET_CIMET_URL:
      return {
        ...state,
        url: undefined,
      };

    case CimetActionTypes.RESET_CIMET_SERVICES:
      return {
        ...state,
        services: undefined,
      };

    default:
      return state;
  }
};
