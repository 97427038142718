import {
  MaintenanceResponse,
  CreateMaintenanceRequestStartPayload,
  SaveImageRequest,
  SaveImageResponse,
  CreateMaintenanceRequestUpdatePayload,
  CreateMaintenanceRequestSuccessResponse,
} from '../../models/maintenance/maintenance';
import { StoreAction } from '../StoreHelper';

export enum MaintenanceActionTypes {
  SAVE_IMAGE_REQUEST = 'SAVE_IMAGE_REQUEST',
  SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS',
  SAVE_IMAGE_ERROR = 'SAVE_IMAGE_ERROR',

  SEND_MAINTENANCE = 'SEND_MAINTENANCE',
  SEND_MAINTENANCE_REQUEST = 'SEND_MAINTENANCE_REQUEST',
  SEND_MAINTENANCE_SUCCESS = 'SEND_MAINTENANCE_SUCCESS',
  SEND_MAINTENANCE_ERROR = 'SEND_MAINTENANCE_ERROR',

  UPDATE_MAINTENANCE_REQUEST = 'UPDATE_MAINTENANCE_REQUEST',
  UPDATE_IMAGE_UUID_LIST = 'UPDATE_IMAGE_UUID_LIST',
  REMOVE_MAINTENANCE_DATA = 'REMOVE_MAINTENANCE_DATA',
}

export type MaintenanceActionPayload =
  | CreateMaintenanceRequestStartPayload
  | MaintenanceResponse
  | SaveImageRequest
  | SaveImageResponse
  | SaveImageResponse[]
  | string
  | Error;

export type MaintenanceAction = StoreAction<MaintenanceActionTypes, MaintenanceActionPayload>;

export class MaintenanceActions {
  public static saveImageRequest(data: SaveImageRequest): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SAVE_IMAGE_REQUEST,
      data,
    };
  }
  public static saveImageResponse(data: SaveImageResponse): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SAVE_IMAGE_SUCCESS,
      data,
    };
  }
  public static saveImageError(data: Error): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SAVE_IMAGE_ERROR,
      data,
    };
  }
  public static updateUuidList(data: SaveImageResponse[]): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.UPDATE_IMAGE_UUID_LIST,
      data,
    };
  }
  public static sendMaintenanceRequest(
    data: CreateMaintenanceRequestStartPayload,
  ): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SEND_MAINTENANCE_REQUEST,
      data,
    };
  }
  public static updateMaintenanceRequest(
    data: CreateMaintenanceRequestUpdatePayload,
  ): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.UPDATE_MAINTENANCE_REQUEST,
      data,
    };
  }
  public static sendMaintenanceResponse(
    data: CreateMaintenanceRequestSuccessResponse,
  ): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SEND_MAINTENANCE_SUCCESS,
      data,
    };
  }
  public static sendMaintenanceError(data: Error): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.SEND_MAINTENANCE_ERROR,
      data,
    };
  }
  public static removeMaintenanceData(): MaintenanceAction {
    return {
      type: MaintenanceActionTypes.REMOVE_MAINTENANCE_DATA,
      data: '',
    };
  }
}
