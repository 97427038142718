/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const MOBILE_WIDTH = 80;
const DESKTOP_WIDTH = 220;

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    menuContainer: {
      width: DESKTOP_WIDTH,
      minHeight: 'calc(100vh - 87px)',
      borderRight: `1px solid ${theme.colors.borderColorLight}`,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        width: MOBILE_WIDTH,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '100vw',
        minHeight: 0,
        height: MOBILE_WIDTH,
        borderRight: 'none',
        borderTop: `1px solid ${theme.colors.borderColorLight}`,
      },
      flexShrink: 0,
      transition: 'all 0.3s ease 0.1s',
    },
    hide: {
      display: 'none',
    },
    hideMobile: {
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'none !important',
      },
    },
    collapsed: {
      width: MOBILE_WIDTH,
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '100vw',
        height: MOBILE_WIDTH,
        borderRight: 'none',
        borderTop: `1px solid ${theme.colors.borderColorLight}`,
      },
      transition: 'all 0.3s ease 0.1s',
    },
    linksWrapper: {
      height: '100%',
    },
    links: {
      paddingTop: 48,
      display: 'flex',
      flexDirection: 'column',
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: 0,
        flexDirection: 'row',
      },
    },
    linkItem: {
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      height: MOBILE_WIDTH,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        justifyContent: 'center',
      },
      // borderBottom: `3px solid ${theme.colors.light}`,
      [`@media (max-width: ${SIZES.md}px)`]: {
        opacity: 0.6,
        marginBottom: 0,
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    linkItemActive: {
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      height: MOBILE_WIDTH,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        justifyContent: 'center',
      },
      // borderBottom: `3px solid ${theme.colors.error}`,
      backgroundColor: theme.colors.lighterGrey,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginBottom: 0,
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    link: {
      color: theme.colors.black87,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        display: 'none',
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'block',
      },
    },
    icon: {
      width: theme.units.iconSize * 1,
      height: theme.units.iconSize * 1,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      marginLeft: theme.units.spacing * 6,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: theme.units.spacing * 2,
      },
    },
    collapsedShowIcon: {
      display: 'block',
    },
    collapsedHideLink: {
      display: 'none',
    },
  })();
};
