import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './SuppliersScreenStyles';
import { LABELS } from './SuppliersScreenConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { DashboardState } from '../../store/state/DashboardState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SuppliersScreenParams } from '../../navigation/NavigationConstants';
import Box from '../../components/box/Box';
import Supplier from '../../components/supplier/Supplier';

interface SuppliersScreenProps {
  dashboardState: DashboardState;
}

const SuppliersScreen: FC<SuppliersScreenProps> = ({
  dashboardState: { onboardedProperties, selectedProperty },
}) => {
  const classes = useStyles();
  const { service, suppliers } = extractParams<SuppliersScreenParams>();

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="title" size="xxl" parentStyles={classes.title}>
              {LABELS.TITLE}
            </Text>
            <Box lightBorder parentStyles={classes.box}>
              <div className={classes.suppliersRoot}>
                <div className={classes.suppliersTitle}>{service.serviceType}</div>
                {suppliers.map((provider, index) => (
                  <Supplier
                    service={service}
                    suppliers={suppliers}
                    provider={provider}
                    index={index}
                    onboardedProperties={onboardedProperties}
                    selectedProperty={selectedProperty}
                  />
                ))}
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersScreen);
