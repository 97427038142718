import React, { FC, useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  LABELS,
  LINKS,
  collapsedUrl,
  intercomReposition,
  showUrl,
  hideMenuMobileUrl,
} from './MenuConstants';
import { useStyles } from './MenuStyles';
import sortedLogo from '../../assets/sorted.png';
import naxLogo from '../../assets/nax.png';
import profile from '../../assets/navigation/profile.png';
import { SIZES } from '../../constants/Sizes';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { navigate } from '../../navigation/NavigationUtils';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';

interface MenuProps {
  isCartOpen: boolean;
}

const Menu: FC<MenuProps> = ({ isCartOpen }) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const showMenu = showUrl.some((s) => location.pathname.includes(s));
  const collapsed = collapsedUrl.some((s) => location.pathname.includes(s));
  const hideMobile = hideMenuMobileUrl.some((s) => location.pathname.includes(s));
  const checkoutFooter = location.pathname.includes('checkoutSelect');
  const isUserLoggedIn = !!(
    localStorage.getItem('userId') && localStorage.getItem('userId') !== 'undefined'
  );

  useEffect(() => {
    intercomReposition(showMenu && !hideMobile, checkoutFooter, isCartOpen);
    window.addEventListener('resize', () =>
      intercomReposition(showMenu && !hideMobile, checkoutFooter, isCartOpen),
    );
    return () => {
      window.removeEventListener('resize', () =>
        intercomReposition(showMenu && !hideMobile, checkoutFooter, isCartOpen),
      );
    };
  }, [showMenu, checkoutFooter, isCartOpen]);

  return (
    <div
      className={classNameGenerator([
        classes.menuContainer,
        collapsed && classes.collapsed,
        (!showMenu || !isUserLoggedIn) && classes.hide,
        hideMobile && classes.hideMobile,
      ])}
    >
      <div className={classes.linksWrapper}>
        <div className={classes.links}>
          {LINKS.map((link, idx) => (
            <div
              className={
                link.routes.some((s) => location.pathname.includes(s))
                  ? classes.linkItemActive
                  : classes.linkItem
              }
              key={idx}
              onClick={() => navigate(link.route)}
            >
              <img src={link.icon} className={classes.icon} alt="icon" />
              <div
                className={classNameGenerator([
                  classes.link,
                  collapsed && classes.collapsedHideLink,
                ])}
              >
                {link.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isCartOpen: state.checkout.isCartOpen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
