/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    serviceCard: {
      height: 64,
      borderBottomColor: theme.colors.borderColorLight,
      borderBottomWidth: 1,
      padding: 12,
      paddingLeft: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    serviceCardItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardLeftView: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 'auto',
      alignItems: 'center',
    },
    cardLeftVerticalView: {
      marginLeft: 16,
    },
    supplierIcon: {
      alignSelf: 'center',
      height: 50,
      width: 50,
      borderRadius: 16,
    },
    serviceStatusIcon: {
      height: 14,
      width: 14,
      transform: 'rotate(270deg)',
      opacity: 0.4,
      marginRight: theme.units.spacing * 4,
    },
    supplierName: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      textTransform: 'capitalize',
    },
    supplierPlan: {
      marginTop: 4,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    cardComponentStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  })();
};
