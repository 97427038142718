import { takeEvery, put } from 'redux-saga/effects';
import {
  User,
  ProfileRequest,
  Roles,
  ProfileType,
  UpdateProfile,
  Profile,
} from '../../models/user/user';
import ProfileService from '../../services/profile/ProfileService';
import { DashboardActions } from '../actions/DashboardActions';
import { ProfileAction, ProfileActions, ProfileActionTypes } from '../actions/ProfileActions';
import { SpinnerActions } from '../actions/SpinnerActions';
import { store } from '../Store';
import { history } from '../../helpers/HistoryHelper';
import { routes } from '../../Routes';
import { navigate } from '../../navigation/NavigationUtils';

function* getProfileRequest(action: ProfileAction) {
  try {
    const { userId, intercomId, superUser } = action.data as ProfileRequest;
    const response: Profile = yield ProfileService.getProfile(userId);
    response.profileUrl = response.profileUrl
      ? `${response.profileUrl}?${new Date().getMilliseconds()}`
      : undefined;
    const profileResponse = profileResponseConvertor(response);
    yield put(ProfileActions.getProfileSuccess(profileResponse));
    // TODO
    // const authDetails = (store.getState().authState as AuthState).authDetails as AuthDetails;
    // if (intercomId !== null) {
    //   IntercomChat.registeruser(
    //     profileResponse,
    //     intercomId as string,
    //     superUser as boolean,
    //     store.getState().fcmState.FCMToken,
    //   );
    // }
    // setTimeout(() => {
    //   IntercomChat.log('V2', authDetails.intercomId);
    // }, 5000);
  } catch (error) {
    yield put(ProfileActions.getProfileError(error as Error));
  }
}

function* getUserRolesRequest() {
  try {
    const response: Roles = yield ProfileService.getUserRoles();
    yield put(ProfileActions.getUserRolesSuccess(response));

    const { profileState } = store.getState();

    if (!(profileState && profileState.profileType)) {
      if (response.landlord && response.tenant) {
        navigate('profileSelection');
      } else if (response.landlord) {
        yield put(ProfileActions.switchProfileType(ProfileType.LANDLORD));
        // This is in the DashboardHelper from the app
        // getLandLordDashboard();
      } else {
        yield put(ProfileActions.switchProfileType(ProfileType.TENANT));
        yield put(DashboardActions.fetchCompletedRequest());
        // TODO This is extracted from Dashboardhelper from the app, the payment stuff is excluded
      }
    } else {
      if (profileState.profileType === ProfileType.LANDLORD) {
        // This is in the DashboardHelper from the app
        // getLandLordDashboard();
      } else {
        yield put(DashboardActions.fetchCompletedRequest());
      }
    }
  } catch (error) {
    yield put(ProfileActions.getUserRolesError(error as Error));
  }
}

export function* updateProfileSaga(action: ProfileAction) {
  try {
    const { data, userId } = action.data as UpdateProfile;
    const user: User = {
      ...data.user!,
      phone: data.mobile!,
    };
    const response: Profile = yield ProfileService.updateProfile(userId, user);
    const profileResponse = profileResponseConvertor(response);
    yield put(ProfileActions.updateProfileSuccess(profileResponse));
  } catch (error) {
    yield put(ProfileActions.updateProfileError(error as Error));
  }
}

export const profileResponseConvertor = (response: any): Profile => {
  const profile = {
    dob: response.dob,
    email: response.email,
    firstName: response.name,
    lastName: response.surname,
    mobile: response.phone,
    id: response.id,
    homePhone: '',
    idType: response.idType,
    idNumber: response.idNumber,
    profileUrl: response.profileUrl,
    title: '',
    userName: response.email,
    refreshToken: '',
    user: response,
  };
  return profile;
};

function* profileWatcher() {
  yield takeEvery(ProfileActionTypes.GET_PROFILE_REQUEST, (action: ProfileAction) =>
    getProfileRequest(action),
  );
  yield takeEvery(ProfileActionTypes.GET_USER_ROLES_REQUEST, () => getUserRolesRequest());
  yield takeEvery(ProfileActionTypes.UPDATE_PROFILE_REQUEST, (action: ProfileAction) =>
    updateProfileSaga(action),
  );
}

export default profileWatcher;
