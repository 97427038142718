import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    grid: {
      display: 'flex',
      [`@media (max-width: ${SIZES.md}px)`]: {
        justifyContent: 'space-between',
        '& div:last-child': {
          marginRight: '0px !important',
        },
      },
    },
    selected: {
      backgroundColor: theme.colors.buttonPrimary,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.light,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      height: 40,
      borderRadius: theme.units.borderRadius / 1.5,
      minWidth: 60,
      paddingLeft: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 4,
      marginRight: theme.units.spacing * 3,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flex: 1,
      },
    },
    unselected: {
      backgroundColor: theme.colors.light,
      border: `${theme.colors.borderColor} 2px solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.black87,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      height: 40,
      borderRadius: theme.units.borderRadius / 1.5,
      minWidth: 60,
      paddingLeft: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 4,
      marginRight: theme.units.spacing * 3,
      transition: 'all 0.3s ease 0s',
      '&:hover': {
        backgroundColor: theme.colors.buttonPrimary,
        color: theme.colors.light,
        border: `${theme.colors.buttonPrimary} 2px solid`,
        transition: 'all 0.3s ease 0s',
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        flex: 1,
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
  })();
};
