/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    balanceCard: {
      backgroundColor: theme.colors.light,
      paddingVertical: 24,
      minHeight: 142,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginTop: 12,
    },
    availableContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 16,
    },
    balanceSubtext: {
      fontWeight: getFonts('Medium'),
      textAlign: 'center',
      fontSize: 16,
      color: theme.colors.sortedPurple,
      marginRight: theme.units.spacing * 4,
      marginLeft: theme.units.spacing * 4,
    },
    splitContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.units.spacing * 4,
    },
    breakdownContainer: {
      flex: 1,
    },
    breakdownAmount: {
      fontWeight: getFonts('Medium'),
      fontSize: 25,
      color: theme.colors.sortedPurple,
      textAlign: 'center',
      marginBottom: 4,
    },
    breakdownSubtext: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.sortedPurple,
      textAlign: 'center',
    },
    line: {
      flex: 1,
      maxWidth: 2,
      height: 22,
      backgroundColor: theme.colors.borderColorLight,
    },
    iconStyle: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      resizeMode: 'contain',
    },
    iconStyleArrow: {
      width: 14,
      height: 14,
      resizeMode: 'contain',
      transform: 'rotate(270deg)',
      opacity: 0.3,
    },
    optionButtonText: {
      fontWeight: getFonts('Medium'),
      fontSize: 13.6,
      marginLeft: 8,
    },
    listItemsContainer: {
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginTop: theme.units.spacing * 4,
      padding: theme.units.spacing * 4,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      justifyContent: 'space-between',
      paddingBottom: theme.units.spacing * 4,
      paddingTop: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    listItemText: {
      fontSize: 16,
      marginLeft: 16,
      marginRight: 'auto',
      color: theme.colors.black60,
      fontWeight: getFonts('Medium'),
    },
    notAvailableText: {
      fontSize: 14,
      fontWeight: getFonts('Light'),
      color: theme.colors.black87,
    },
  })();
};
