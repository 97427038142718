/* eslint-disable import/prefer-default-export */
import { TagBarItem } from './TagBarConstants';

export const isTabActive = (tags: TagBarItem[], tab: string): boolean => {
  const tag = tags.find((tag) => tag.value === tab);
  if (tag) {
    return tag.active;
  }
  return false;
};

export const areAllTabsOff = (tags: TagBarItem[]) => {
  return !tags.find((tag) => tag.active);
};
