import { PlanInformation } from '../../models/checkout/Checkout';

export const sortSections = (planInformation: PlanInformation[]) => {
  let planBenefits: PlanInformation | null = null;
  let planRates: PlanInformation | null = null;
  const temp = planInformation.filter((section) => {
    if (section.sectionTitle.toUpperCase() === 'PLAN RATES') {
      planRates = section;
      return false;
    } else if (section.sectionTitle.toUpperCase() === 'PLAN BENEFITS') {
      planBenefits = section;
      return false;
    } else {
      return true;
    }
  });
  if (planBenefits !== null) {
    temp.unshift(planBenefits);
  }
  if (planRates !== null) {
    temp.unshift(planRates);
  }

  return temp;
};
