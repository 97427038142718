/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, ReactNode } from 'react';
import { useStyles } from './TileContainerStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import errorIcon from '../../assets/alert-large.png';
import Text from '../text/Text';
import Arrows, { ArrowsProps } from '../arrows/Arrows';
import Button from '../button/Button';

interface TileContainerProps {
  title: string;
  showErrorIcon?: boolean;
  showRightText?: string;
  isLink?: boolean;
  children?: ReactNode;
  noScroll?: boolean;
  noMarginTop?: boolean;
  leftActionText?: string;
  arrowProps?: ArrowsProps;
  noTitle?: boolean;
  handleLeftAction?: () => void;
  handleRightAction?: () => void;
}

const TileContainer: FC<TileContainerProps> = ({
  title,
  showErrorIcon,
  showRightText,
  isLink,
  children,
  noScroll,
  noMarginTop,
  arrowProps,
  leftActionText,
  noTitle,
  handleLeftAction,
  handleRightAction,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNameGenerator([classes.tileContainer, noMarginTop && classes.noMarginTop])}
    >
      {!noTitle && (
        <div className={classes.titleContainer}>
          <div className={classes.secondaryContainer}>
            <Text textVariant="boxTitle" parentStyles={classes.title}>
              {title}
            </Text>
            {showErrorIcon && <img src={errorIcon} className={classes.icon} />}
            {!!leftActionText && (
              <Button parentStyles={classes.leftButton} onPress={() => handleLeftAction!()} outline>
                {leftActionText}
              </Button>
            )}
          </div>
          {!!showRightText && (
            <div
              onClick={handleRightAction}
              className={classNameGenerator([classes.text, isLink && classes.link])}
            >
              {showRightText}
            </div>
          )}
          {arrowProps && <Arrows {...arrowProps} />}
        </div>
      )}
      {!!children && (
        <>
          {noScroll ? (
            <div style={{ position: 'relative' }}>{children}</div>
          ) : (
            <div className={classes.contentContainer}>{children}</div>
          )}
        </>
      )}
    </div>
  );
};

export default TileContainer;
