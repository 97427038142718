import React, { FC } from 'react';
import { useStyles } from './ProgressTileStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import addIcon from '../../assets/plus.png';
import { ActiveServicesPaymentSummaryResponse } from '../../models/services/services';
import { calculateCost, getFrequencySmall } from '../../helpers/CostFrequencyHelper';
import PercentageWheel from '../percentageWheel/PercentageWheel';
import moment from 'moment';

interface TileProps {
  service: ActiveServicesPaymentSummaryResponse;
  loading?: boolean;
  handlePress: () => void;
}

const Tile: FC<TileProps> = ({ service, loading, handlePress }) => {
  const classes = useStyles();
  const span = moment(service.payDateEnd).diff(moment(service.payDateStart), 'days');
  const difference = moment(service.payDateEnd).diff(moment(), 'days');
  return (
    <div
      className={classNameGenerator([classes.tileContainer, loading && classes.loading])}
      onClick={handlePress}
    >
      {!loading && (
        <div className={classes.tileWrapper}>
          <div className={classes.dot} />
          <div className={classes.titleContainer}>
            <div className={classes.title}>{service.serviceType}</div>
            <div className={classes.subtitle}>
              {calculateCost(service.paymentFrequency!, service.paymentAmountMonthly, true, 0)}
              {getFrequencySmall(service.paymentFrequency!)}
            </div>
          </div>
          <div className={classes.bottomRow}>
            <PercentageWheel
              completed={((span - difference) / span) * 100}
              parentStyles={classes.percentageStyles}
              text={`${span - difference}\n days`}
              middleStyles={classes.middleStyles}
            />
            <div className={classes.status}>Connected</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tile;
