import {
  AddPaymentTagsResponse,
  AddTagStatus,
  CheckoutStripeToken,
  DeletePaymentRequest,
  GetFastPaymentRequest,
  GetFastPayResponse,
  GetPaymentAuthKey,
  GetPaymentAuthKeyRequest,
  GetPaymentAuthKeyResponse,
  GetPaymentMethodsPayLoad,
  GetPaymentMethodsResponse,
  GetPaymentRefId,
  GetPaymentTypeResponse,
  GetPaymentTypes,
  GetStripePaymentIntentRequest,
  PayIdPayLoad,
  PaymentDetails,
  PostEditPaymentPayload,
  PostPaymentRequest,
  PostPaymentTokenRequest,
  PostPaymentTokenResponse,
  PostPaymentUUIDRequest,
  PostPaymentUUIDResponse,
  SaveSelectedPaymentMethodPayload,
} from '../../models/payment/Payment';
import { StoreAction } from '../StoreHelper';

export enum PaymentActionTypes {
  CHANGE_PAYMENT_TAG_REQUEST = 'CHANGE_PAYMENT_TAG_REQUEST',
  CHANGE_PAYMENT_TAG_SUCCESS = 'CHANGE_PAYMENT_TAG_SUCCESS',
  CHANGE_PAYMENT_TAG_ERROR = 'CHANGE_PAYMENT_TAG_ERROR',

  FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST',
  FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS',
  FETCH_PAYMENT_ERROR = 'FETCH_PAYMENT_ERROR',

  RESET_PAYMENT_ERROR = 'RESET_ERROR',

  GET_AVAILABLE_PAYMENT_TYPES_REQUEST = 'GET_AVAILABLE_PAYMENT_TYPES_REQUEST',
  GET_AVAILABLE_PAYMENT_TYPES_SUCCESS = 'GET_AVAILABLE_PAYMENT_TYPES_SUCCESS',
  GET_AVAILABLE_PAYMENT_TYPES_ERROR = 'GET_AVAILABLE_PAYMENT_TYPES_ERROR',

  POST_PAYMENT_METHOD_UUID_REQUEST = 'POST_PAYMENT_METHOD_UUID_REQUEST',
  POST_PAYMENT_METHOD_UUID_SUCCESS = 'POST_PAYMENT_METHOD_UUID_SUCCESS',
  POST_PAYMENT_METHOD_UUID_ERROR = 'POST_PAYMENT_METHOD_UUID_ERROR',

  GET_SAVED_PAYMENT_METHODS_REQUEST = 'GET_SAVED_PAYMENT_METHODS_REQUEST',
  GET_SAVED_PAYMENT_METHODS_SUCCESS = 'GET_SAVED_PAYMENT_METHODS_SUCCESS',
  GET_SAVED_PAYMENT_METHODS_ERROR = 'GET_SAVED_PAYMENT_METHODS_ERROR',

  GET_FAST_PAYID_REQUEST = 'GET_FAST_PAYID_REQUEST',
  GET_FAST_PAYID_SUCCESS = 'GET_FAST_PAYID_SUCCESS',
  GET_FAST_PAYID_ERROR = 'GET_FAST_PAYID_ERROR',

  DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_ERROR = 'DELETE_PAYMENT_ERROR',

  SAVE_SELECTED_PAYMENT_METHOD = 'SAVE_SELECTED_PAYMENT_METHOD',

  GET_PAYMENT_AUTH_KEY = 'GET_PAYMENT_AUTH_KEY',
  GET_PAYMENT_AUTH_KEY_REQUEST = 'GET_PAYMENT_AUTH_KEY_REQUEST',
  GET_PAYMENT_AUTH_KEY_SUCCESS = 'GET_PAYMENT_AUTH_KEY_SUCCESS',
  GET_PAYMENT_AUTH_KEY_ERROR = 'GET_PAYMENT_AUTH_KEY_ERROR',
  GET_PAYMENT_AUTH_KEY_RESET = 'GET_PAYMENT_AUTH_KEY_RESET',

  POST_PAYMENT_TOKEN_REQUEST = 'POST_PAYMENT_TOKEN_REQUEST',
  POST_PAYMENT_TOKEN_SUCCESS = 'POST_PAYMENT_TOKEN_SUCCESS',
  POST_PAYMENT_TOKEN_ERROR = 'POST_PAYMENT_TOKEN_ERROR',

  GET_STRIPE_CHECKOUT_TOKEN_REQUEST = 'GET_STRIPE_CHECKOUT_TOKEN_REQUEST',
  GET_STRIPE_CHECKOUT_TOKEN_SUCCESS = 'GET_STRIPE_CHECKOUT_TOKEN_SUCCESS',
  GET_STRIPE_CHECKOUT_TOKEN_ERROR = 'GET_STRIPE_CHECKOUT_TOKEN_ERROR',

  GET_REFID_REQUEST = 'GET_REFID_REQUEST',
  GET_REFID_SUCCESS = 'GET_REFID_SUCCESS',
  GET_REFID_ERROR = 'GET_REFID_ERROR',

  POST_EDIT_PAYMENT_REQUEST = 'POST_EDIT_PAYMENT_REQUEST',
  POST_EDIT_PAYMENT_SUCCESS = 'POST_EDIT_PAYMENT_SUCCESS',
  POST_EDIT_PAYMENT_ERROR = 'POST_EDIT_PAYMENT_ERROR',
}

export type PaymentPayload =
  | GetPaymentAuthKeyRequest
  | PaymentDetails
  | PostPaymentTokenRequest
  | GetPaymentAuthKeyResponse
  | GetFastPaymentRequest
  | GetFastPayResponse
  | AddPaymentTagsResponse
  | AddTagStatus
  | Error
  | GetPaymentTypeResponse[]
  | PayIdPayLoad
  | GetFastPayResponse
  | PostPaymentUUIDRequest
  | PostPaymentUUIDResponse
  | GetPaymentMethodsResponse[]
  | SaveSelectedPaymentMethodPayload
  | PostPaymentRequest
  | GetPaymentAuthKey
  | GetPaymentAuthKeyRequest
  | PostPaymentTokenResponse
  | string
  | GetPaymentTypes
  | number
  | null
  | string[]
  | GetPaymentMethodsPayLoad
  | GetStripePaymentIntentRequest
  | Error
  | GetPaymentRefId
  | PostEditPaymentPayload
  | DeletePaymentRequest
  | CheckoutStripeToken;

export type PaymentAction = StoreAction<PaymentActionTypes, PaymentPayload>;

export class PaymentActions {
  public static resetPaymentError(): PaymentAction {
    return { type: PaymentActionTypes.RESET_PAYMENT_ERROR, data: null };
  }

  public static postTagRequest(data: AddTagStatus): PaymentAction {
    return {
      type: PaymentActionTypes.CHANGE_PAYMENT_TAG_REQUEST,
      data,
    };
  }
  public static paymentTagSuccess(data: AddPaymentTagsResponse): PaymentAction {
    return {
      type: PaymentActionTypes.CHANGE_PAYMENT_TAG_SUCCESS,
      data,
    };
  }
  public static paymentTagError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.CHANGE_PAYMENT_TAG_ERROR,
      data,
    };
  }

  public static fetchPaymentRequest(data: GetFastPaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.FETCH_PAYMENT_REQUEST,
      data,
    };
  }
  public static fetchPaymentSuccess(data: GetFastPayResponse): PaymentAction {
    return {
      type: PaymentActionTypes.FETCH_PAYMENT_SUCCESS,
      data,
    };
  }
  public static fetchPaymentError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.FETCH_PAYMENT_ERROR,
      data,
    };
  }

  public static deletePaymentStart(data: DeletePaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.DELETE_PAYMENT_REQUEST,
      data,
    };
  }
  public static deletePaymentSuccess(data: string[]): PaymentAction {
    return {
      type: PaymentActionTypes.DELETE_PAYMENT_SUCCESS,
      data,
    };
  }
  public static deletePaymentError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.DELETE_PAYMENT_ERROR,
      data,
    };
  }

  public static saveSelectedPaymentMethod(data: SaveSelectedPaymentMethodPayload): PaymentAction {
    return {
      type: PaymentActionTypes.SAVE_SELECTED_PAYMENT_METHOD,
      data,
    };
  }

  public static getPaymentTypesStart(data: GetPaymentTypes): PaymentAction {
    return {
      type: PaymentActionTypes.GET_AVAILABLE_PAYMENT_TYPES_REQUEST,
      data,
    };
  }
  public static getPaymentTypesSuccess(data: GetPaymentTypeResponse[]): PaymentAction {
    return {
      type: PaymentActionTypes.GET_AVAILABLE_PAYMENT_TYPES_SUCCESS,
      data,
    };
  }
  public static getPaymentTypesError(error: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_AVAILABLE_PAYMENT_TYPES_ERROR,
      data: error,
    };
  }
  public static postPaymentMethodUUIDStart(data: PostPaymentUUIDRequest): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_METHOD_UUID_REQUEST,
      data,
    };
  }
  public static postPaymentMethodUUIDSuccess(data: PostPaymentUUIDResponse): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_METHOD_UUID_SUCCESS,
      data,
    };
  }

  public static postPaymentMethodUUIDError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_METHOD_UUID_ERROR,
      data,
    };
  }

  public static getPaymentMethodsStart(data: GetPaymentMethodsPayLoad): PaymentAction {
    return {
      type: PaymentActionTypes.GET_SAVED_PAYMENT_METHODS_REQUEST,
      data,
    };
  }

  public static getPaymentMethodsSuccess(data: GetPaymentMethodsResponse[]): PaymentAction {
    return {
      type: PaymentActionTypes.GET_SAVED_PAYMENT_METHODS_SUCCESS,
      data,
    };
  }
  public static getPaymentMethodsError(error: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_SAVED_PAYMENT_METHODS_ERROR,
      data: error,
    };
  }

  public static requestFastPayIdDetails(data: PayIdPayLoad): PaymentAction {
    return {
      type: PaymentActionTypes.GET_FAST_PAYID_REQUEST,
      data,
    };
  }
  public static getPaymentPayIdSuccess(data: GetFastPayResponse): PaymentAction {
    return {
      type: PaymentActionTypes.GET_FAST_PAYID_SUCCESS,
      data,
    };
  }
  public static getPaymentPayIdError(error: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_FAST_PAYID_ERROR,
      data: error,
    };
  }
  public static getPaymentAuthKeyStart(data: GetPaymentAuthKeyRequest): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_REQUEST,
      data,
    };
  }
  public static getPaymentAuthKeySuccess(data: GetPaymentAuthKey): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_SUCCESS,
      data,
    };
  }
  public static getPaymentAuthKeyError(error: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_ERROR,
      data: error,
    };
  }

  public static getPaymentAuthKeyReset(): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_RESET,
      data: null,
    };
  }

  public static postPaymentTokenStart(data: PostPaymentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_REQUEST,
      data,
    };
  }
  public static postPaymentTokenSuccess(data: PostPaymentTokenResponse): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_SUCCESS,
      data,
    };
  }
  public static postPaymentTokenError(error: Error): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_ERROR,
      data: error,
    };
  }

  public static postEditPaymentStart(data: PostEditPaymentPayload): PaymentAction {
    return {
      type: PaymentActionTypes.POST_EDIT_PAYMENT_REQUEST,
      data,
    };
  }
  public static postEditPaymentSuccess(): PaymentAction {
    return {
      type: PaymentActionTypes.POST_EDIT_PAYMENT_SUCCESS,
      data: null,
    };
  }
  public static postEditPaymentError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.POST_EDIT_PAYMENT_ERROR,
      data,
    };
  }

  // Checkout ---------------------------------------------------------------------- //
  public static getStripeCheckoutTokenRequest(data: GetStripePaymentIntentRequest): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_REQUEST,
      data,
    };
  }

  public static getStripeCheckoutTokenSuccess(data: CheckoutStripeToken): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_SUCCESS,
      data,
    };
  }

  public static getStripeCheckoutTokenError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_ERROR,
      data,
    };
  }

  public static getRefIdRequest(data: GetPaymentRefId): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_REQUEST,
      data,
    };
  }

  public static getRefIdSuccess(data: any): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_SUCCESS,
      data,
    };
  }

  public static getRefIdError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_REFID_ERROR,
      data,
    };
  }
}
