import { ApplicationState } from '../../store/RootReducer';
import { Applicants, ApplyPropertyForm } from '../masterProfile/MasterProfile';

export interface ApplicantResponse {
  profileData: Applicants;
}

export enum AcceptedEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPT',
  DECLINED = 'DECLINED',
}

export interface CoApplicantResponse {
  primary: LimitedApplicantResponse;
  secondary: LimitedApplicantResponse[];
  dependent: LimitedApplicantResponse[];
}

export interface LimitedApplicantResponse {
  id?: number;
  firstName: string | null;
  email: string | null;
  exists?: boolean;
  accepted?: AcceptedEnum;
  pets?: number | null;
  lastName: string | null;
  title: string | null;
  mobile: string | null;
  groupRefId?: string;
}

// Get master profile with group context for logged-in User
export interface GroupRequest {
  groupRefId?: string;
}

export interface GroupResponse {
  primary: ApplicantResponse;
  secondary: ApplicantResponse[];
  dependents: ApplicantResponse[];
  coApplicants: CoApplicantResponse[];
  adults: number;
  children: number;
  pets: number;
  groupRefId: string;
  groupName: string;
}

// Create a new group for logged-in User ???
// Response same as GroupResponse

// Add co-applicant to a group of logged-in User
export interface CoApplicantRequest {
  firstName: string | null;
  email: string | null;
  lastName: string | null;
  groupRefId?: string;
  title: string | null;
  mobile: string | null;
}

export interface CreateNewGroupRequest {
  coApplicants: CoApplicantRequest[];
  groupName: string;
}

// Update co-applicant
export interface UpdateCoApplicantRequest {
  groupRefId: string;
  currentEmail: string;
  coApplicant: CoApplicantRequest;
}

// remove co-applicant from a group of logged-in User
export interface RemoveCoApplicantRequest {
  groupRefId: string;
  email: string;
}
// Response same as GroupResponse

// Submit one application against multiple properties

export interface PostGroupApplicationRequest {
  applicationState: ApplicationState;
  groupRefId: string;
}
export interface GroupApplicationRequest {
  applyForm: ApplyPropertyForm;
  groupRefId: string;
}

// Response same as GroupResponse

// Get all Applications
export interface ApplicationsRequest {}

export interface Status {
  status: string;
  isCurrent: boolean;
}

export interface DeleteDraftRequest {
  draftId: string;
}

export interface Application {
  propertyList: ApplicationProperty[];
  draftId?: string; // ONLY ON DRAFTS
  refId?: string; // NOT ON DRAFTS
  agencyCode: string;
  agencyName: string;
  branch: Branch | undefined;
  createdDate?: string | Date; // NOT ON DRAFTS
  formattedDate?: string | Date; // NOT ON DRAFTS
  lastModifiedDate?: string | Date; // ONLY ON DRAFTS
  propertyRefId?: string; // NOT ON DRAFTS
  updateRequiredStatus?: boolean; // NOT ON DRAFTS
  masterProfileVersion?: number; // NOT ON DRAFTS
  groupRefId?: string; // NOT ON DRAFTS
  status?: Status[]; // NOT ON DRAFTS
}

export interface Branch {
  agencyCode: string;
  agencyId: number;
  email: string;
  internalCode: string;
  name: string;
  phone: string;
  tradingName: string;
}
export interface ApplicationProperty {
  propertyRefId: string;
  rentOffer: number;
  years: number;
  months: number;
  preferredMoveInDate: string | Date;
  inspectionDate: string | Date;
  inspectionMethod: string;
  address: string;
  agency: string;
  branch?: string;
  status?: string; // NOT ON DRAFTS
  applicationGroupId?: string; // NOT ON DRAFTS
  applicationRefId?: string; // NOT ON DRAFTS
  agentDetails?: AgentDetails;
  photo?: string;
  draftId?: string; // ONLY ON DRAFTS
  unitNumber: string;
  streetName: string;
  suburb: string;
  postcode: string;
  streetNumber: string;
  state: string;
  country: string;
  bedrooms: number;
  bathrooms: number;
  carspaces: number;
}

export interface AgentDetails {
  name: string;
  email: string;
  phoneNumber: string;
}
export interface AgencyWiseApplication {
  agency: string;
  application: Application[];
  agencyLogo?: string;
  agencyName?: string;
}

export interface Group {
  agencyWiseApplications: AgencyWiseApplication[];
  groupRefId: string;
  groupName?: string;
}
export interface ApplicationsResponse {
  invitedApplicationGroups: {
    groups: Group[];
  };
  draftApplications: {
    agencyWiseApplications: AgencyWiseApplication[];
  };
  submittedApplicationGroups: {
    groups: Group[];
  };
  updateRequired: string[];
  informationRequested: string[];
}

// Sync Applications ??
export interface SyncApplicationRequest {
  applicationRefs: string[];
}

// Co-Applicant accepts/declines the invitation to a given application
export interface InvitationRequest {
  groupRefId: string;
  applicationRefs: string[];
}

// Save Group Name
export interface GroupNameRequest {
  groupName: string;
  groupRefId: string;
}

export enum WithdrawApplicationEnum {
  INVITE = 'INVITE',
  SUBMITTED = 'SUBMITTED',
}

export interface WithdrawRequest {
  applicationRefId: string;
  type?: WithdrawApplicationEnum;
  groupRefId?: string;
}

export interface WithdrawResponse {
  applicationGroupResponse: ApplicationsResponse;
  groups: GroupResponse[];
}
