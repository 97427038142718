import { takeEvery, put } from 'redux-saga/effects';
import camelCaseKeys from 'camelcase-keys';
import walletService from '../../services/wallet/WalletService';
import { WalletAction, WalletActions, WalletActionTypes } from '../actions/WalletActions';
import {
  WalletProfileResponse,
  WalletBreakdownRequest,
  WalletBreakdownResponse,
  WalletSpendRequest,
  AddPromoCodeRequest,
  AddPromoCodeResponse,
} from '../../models/wallet/wallet';

export function* getWalletProfile() {
  try {
    // const requestData = action.data as WalletProfileRequest;
    const response: WalletProfileResponse = yield walletService.getWalletProfile();
    const convertedToCamel: WalletProfileResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(WalletActions.getWalletProfileSuccess(convertedToCamel));
  } catch (error) {
    yield put(WalletActions.getWalletProfileError(error as Error));
  }
}

export function* getWalletBreakdown(action: WalletAction) {
  try {
    const requestData = action.data as WalletBreakdownRequest;
    const response: WalletBreakdownResponse = yield walletService.getWalletBreakdown(requestData);
    const convertedToCamel: WalletBreakdownResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(WalletActions.getWalletBreakdownSuccess(convertedToCamel));
  } catch (error) {
    yield put(WalletActions.getWalletBreakdownError(error as Error));
  }
}

export function* applyWalletSpend(action: WalletAction) {
  try {
    const requestData = action.data as WalletSpendRequest[];
    const response: WalletProfileResponse = yield walletService.applyWalletSpend(requestData);
    const convertedToCamel: WalletProfileResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(WalletActions.applyWalletSpendSuccess(convertedToCamel));
  } catch (error) {
    yield put(WalletActions.applyWalletSpendError(error as Error));
  }
}

export function* addPromoCode(action: WalletAction) {
  try {
    const requestData = action.data as AddPromoCodeRequest;
    const response: AddPromoCodeResponse = yield walletService.addPromoCode(requestData);
    const convertedToCamel: AddPromoCodeResponse = camelCaseKeys(response, {
      deep: true,
    });

    yield put(WalletActions.addPromoCodeSuccess(convertedToCamel));
  } catch (error) {
    yield put(WalletActions.addPromoCodeError(error as Error));
  }
}

function* walletWatcher() {
  yield takeEvery(WalletActionTypes.GET_WALLET_PROFILE_REQUEST, () => getWalletProfile());
  yield takeEvery(WalletActionTypes.GET_WALLET_BREAKDOWN_REQUEST, (action) =>
    getWalletBreakdown(action as WalletAction),
  );
  yield takeEvery(WalletActionTypes.APPLY_WALLET_SPEND_REQUEST, (action) =>
    applyWalletSpend(action as WalletAction),
  );
  yield takeEvery(WalletActionTypes.ADD_PROMO_CODE_REQUEST, (action) =>
    addPromoCode(action as WalletAction),
  );
}

export default walletWatcher;
