/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../theme/Theme';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    container: {
      position: 'fixed',
      bottom: 24,
      left: 24,
      display: 'none',
      zIndex: 99,
      width: '100%',
    },
    active: {
      display: 'flex !important',
    },
    themeButton: {
      height: 30,
      minWidth: 80,
      border: `${theme.colors.borderColor} 2px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.units.spacing * 2,
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      cursor: 'pointer',
    },
    activeThemeButton: {
      backgroundColor: theme.colors.lightGrey,
    },
    toggle: {
      position: 'absolute',
      left: -16,
      bottom: -16,
      width: 14,
      height: 14,
      borderRadius: 8,
      backgroundColor: theme.colors.buttonPrimary,
      transition: 'all 0.4s cubic-bezier(0.4, 0.01, 0, 1.38)',
      '&:hover': {
        transform: 'scale(1.3)',
      },
      cursor: 'pointer',
    },
  })();
};
