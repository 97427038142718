/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    modal: {
      borderRadius: 12,
    },
    modalView: {
      backgroundColor: theme.colors.light,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.units.spacing * 4,
    },
    banner: {
      backgroundColor: theme.colors.buttonPrimary,
      display: 'flex',
      justifyContent: 'flex-end',
      // alignItems: 'center',
      marginTop: 32,
      marginLeft: 32,
      width: '100%',
    },
    imageContainer: {
      width: 50,
      height: 50,
      resizeMode: 'contain',
    },
    image: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    title: {
      marginTop: 12,
      fontSize: 24,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    descriptionContainer: {
      marginTop: 16,
      display: 'flex',
    },
    description: {
      width: '80%',
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      opacity: 0.6,
    },
    buttonContainer: {
      display: 'flex',
      marginTop: theme.units.spacing * 8,
      width: '100%',
    },
    button: {
      width: '100%',
    },
    buttonText: {
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
    },
    secondaryTextContainer: {
      marginTop: 24,
      marginBottom: 24,
    },
    secondaryText: {
      cursor: 'pointer',
      fontSize: 15,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      textAlign: 'center',
    },
  })();
};
