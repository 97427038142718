import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';

export const LABELS = {
  TITLE: 'Suppliers',
  SORRY: 'Sorry, there are no services currently available. Please check again soon.',
  BACK: 'Back to marketplace',
};
