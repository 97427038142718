import { GooglePlaceResult } from './GooglePlacesHelper';

export class AddressValue {
  public static format(value: AddressValue | undefined | null): string {
    if (value) {
      return value.place && value.place.formatted_address
        ? value.place.formatted_address
        : value.inputValue || '';
    } else {
      return '';
    }
  }

  public static equals(
    a: AddressValue | undefined | null,
    b: AddressValue | undefined | null,
  ): boolean {
    return AddressValue.format(a) === AddressValue.format(b);
  }

  public static isNullOrEmpty(value: AddressValue): boolean {
    return !AddressValue.format(value);
  }

  public readonly inputValue: string | undefined | null;
  public readonly place: GooglePlaceResult | undefined | null;
}
