import { AvailableServicesResponse, ServiceDetailsData } from '../../models/checkout/Checkout';

export const hasPayments = (cardData?: ServiceDetailsData | null): boolean => {
  return cardData && cardData.planDetails ? !cardData.planDetails.skipPaymentStep : false;
};

export const hasAnyPayments = (cartItems: AvailableServicesResponse[]): boolean => {
  let result = false;

  cartItems.filter((cartItem) => {
    if (hasPayments(cartItem.suppliers![0].plans[0].cartData)) {
      result = true;
    }
  });
  return result;
};
