import { PaymentMethod, PaymentMethods } from '../../models/services/rentService';
import { LABELS } from './PropertyRentConstants';

export const filterPaymentMethod = (paymentMethod: PaymentMethod | null): string | null => {
  if (paymentMethod === null) {
    return null;
  } else {
    let paymentMethodLabel = '';
    switch (paymentMethod.paymentMethod) {
      case PaymentMethods.bank:
        paymentMethodLabel = LABELS.BANK_ACCOUNT;
        break;
      case PaymentMethods.creditCard:
        paymentMethodLabel = LABELS.CREDIT_CARD;
        break;
      case PaymentMethods.notAvailable:
        paymentMethodLabel = LABELS.NOTAVAILABLE;
        break;
    }
    return paymentMethodLabel;
  }
};

export const filterPaymentAccount = (paymentMethod: PaymentMethod | null): string | null => {
  if (paymentMethod === null) {
    return null;
  } else {
    let paymentMethodValue = '';
    switch (paymentMethod.paymentMethod) {
      case PaymentMethods.bank:
        paymentMethodValue = `${paymentMethod.accountNumber as string}-${paymentMethod.bsb}`;
        break;
      case PaymentMethods.creditCard:
        paymentMethodValue = paymentMethod.cardNumber as string;
        break;
      case PaymentMethods.notAvailable:
        paymentMethodValue = LABELS.ADDPAYMENTLABEL;
        break;
    }
    return paymentMethodValue;
  }
};
