import { getCurrentTheme } from '../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../helpers/GetFonts';
import { SIZES } from '../../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
  pageTitleContainer,
  secondaryContent,
} from '../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    welcomeContainer: {},
    welcomeBanner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      height: 280,
      borderRadius: theme.units.borderRadius,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'hidden',
    },
    welcomeLeftContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      marginTop: theme.units.spacing * 4,
      padding: theme.units.spacing * 4,
      zIndex: 3,
    },
    welcomeTitle: {
      marginTop: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 6,
      marginLeft: theme.units.spacing * 2,
    },
    welcomeSubtitle: {
      marginBottom: 'auto',
      marginLeft: theme.units.spacing * 2,
      fontSize: theme.font.l,
      maxWidth: 350,
      lineHeight: 1.5,
    },
    welcomeButton: {
      height: 30,
      minWidth: 120,
      marginBottom: theme.units.spacing * 4,
    },
    welcomeImageBackground: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 2,
    },
    welcomeLeftImage: {
      position: 'absolute',
      width: '65%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 2,
      // [`@media (max-width: 1600px)`]: {
      //   width: '75%',
      //   objectPosition: 'right',
      // },
      // [`@media (max-width: 1500px)`]: {
      //   width: '80%',
      //   objectPosition: 'right',
      // },
      [`@media (max-width: 1200px)`]: {
        width: '100%',
        objectPosition: 'right',
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        width: '70%',
        objectPosition: 'right',
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        width: '80%',
        objectPosition: 'right',
      },
      [`@media (max-width: 700px)`]: {
        width: '85%',
        objectPosition: 'right',
      },
      [`@media (max-width: 650px)`]: {
        width: '90%',
        objectPosition: 'right',
      },
      [`@media (max-width: ${SIZES.sm}px)`]: {
        width: '100%',
        objectPosition: 'left',
      },
    },
    welcomeRightImage: {
      position: 'absolute',
      right: 0,
      width: '50%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
      zIndex: 1,
    },
    quickStartContainer: {
      padding: theme.units.spacing * 4,
      border: `${theme.colors.borderColorLight} 2px solid`,
      borderRadius: theme.units.borderRadius,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 'none',
    },
    quickStartRow: {
      display: 'flex',
      marginTop: theme.units.spacing * 2,
    },
    quickStartIcon: {
      width: 32,
      height: 32,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 8,
    },
    quickStartTitle: {
      fontSize: theme.font.xl,
      fontWeight: getFonts('Medium'),
      marginBottom: 0,
    },
    quickStartItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.units.spacing * 4,
      paddingTop: theme.units.spacing * 4,
      borderTop: `${theme.colors.borderColorLight} 2px solid`,
      cursor: 'pointer',
    },
    quickStartTick: {
      width: theme.units.iconSize * 1.2,
      height: theme.units.iconSize * 1.2,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 4,
    },
    quickStartTickIncomplete: {
      width: theme.units.iconSize * 1.2,
      height: theme.units.iconSize * 1.2,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 4,
      filter: 'grayscale(100%)',
      opacity: 0.3,
    },
    quickStartIconAction: {
      ...actionGreen,
      width: theme.units.iconSize / 1.5,
      height: theme.units.iconSize / 1.5,
      objectFit: 'contain',
      objectPosition: 'center',
      cursor: 'pointer',
    },
    quickStartText: {
      marginRight: 'auto',
      fontSize: theme.font.l,
    },
  })();
};
