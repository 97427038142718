/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    marketplaceBanner: {
      position: 'relative',
      borderRadius: theme.units.borderRadius * 2,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.lightGreen,
      paddingTop: theme.units.spacing * 4,
      minHeight: 340,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.md}px)`]: {
        minHeight: 300,
      },
    },
    logo: {
      position: 'absolute',
      top: theme.units.spacing * 10,
      left: theme.units.spacing * 12,
      height: theme.units.iconSize,
      width: 100,
      objectPosition: 'center',
      objectFit: 'contain',
      [`@media (max-width: ${SIZES.md}px)`]: {
        left: theme.units.spacing * 8,
      },
    },
    naxLogo: {
      position: 'absolute',
      top: theme.units.spacing * 10,
      left: theme.units.spacing * 12,
      height: 40,
      objectPosition: 'center',
      objectFit: 'contain',
      [`@media (max-width: ${SIZES.md}px)`]: {
        left: theme.units.spacing * 8,
      },
    },
    titleContainer: {
      marginLeft: theme.units.spacing * 12,
      alignSelf: 'center',
      flexShrink: 0,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginLeft: theme.units.spacing * 8,
        alignSelf: 'flex-end',
        marginBottom: theme.units.spacing * 8,
      },
    },
    title: {
      color: theme.colors.title,
      fontSize: 48,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
      whiteSpace: 'pre-wrap',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        fontSize: 42,
      },
      [`@media (max-width: ${SIZES.lg}px)`]: {
        fontSize: 36,
        whiteSpace: 'normal',
        maxWidth: 360,
      },
    },
    subtitle: {
      color: theme.colors.black60,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      lineHeight: 1.5,
      whiteSpace: 'pre-wrap',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        whiteSpace: 'normal',
        maxWidth: 360,
      },
    },
    bannerImageContainer: {
      height: '100%',
      width: 400,
      display: 'flex',
      flexShrink: 0,
      [`@media (max-width: ${SIZES.md}px)`]: {
        display: 'none',
      },
    },
    bannerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'bottom',
    },
  })();
};
