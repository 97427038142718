/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    loadingRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      height: '100vh',
      top: 0,
      left: 0,
      width: '100vw',
      backgroundColor: theme.colors.light,
    },
    sortedLoader: {
      height: 70,
      animation: 'spin 1s linear infinite',
    },
    primaryText: {
      color: theme.colors.black87,
      fontSize: '30px',
      fontWeight: getFonts('Medium'),
      marginTop: theme.units.spacing * 4,
    },
    secondaryText: {
      color: theme.colors.black87,
      marginTop: 6,
      fontWeight: getFonts('Medium'),
    },
  })();
};
