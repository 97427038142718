/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dialog, Drawer } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ADD_NEW_ICON from '../../../assets/payment/filled.png';
import tick from '../../../../assets/tick.png';
import arrowLeft from '../../../../assets/navigation/arrow-left.png';
import { useStyles } from './ViewBankStyles';
import { extractParams, navigateBack } from '../../../../navigation/NavigationUtils';
import { ViewBankParams } from '../../../../navigation/NavigationConstants';
import { ApplicationState } from '../../../../store/RootReducer';
import Text from '../../../../components/text/Text';
import { LABELS } from './ViewBankConstants';
import Input from '../../../../components/input/Input';
import lockIcon from '../../../../assets/lock-24-px.svg';
import { PaymentActions } from '../../../../store/actions/PaymentActions';
import Button from '../../../../components/button/Button';
import SlideLeft from '../../../../components/transitionsHelper/SlideLeft';
import closeIcon from '../../../../assets/navigation/close.png';

interface ViewBankProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const ViewBank: FC<ViewBankProps> = ({ selectedViewPaymentMethod, deletePaymentMethod }) => {
  const classes = useStyles();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>

            <Input
              placeholder={LABELS.ACCOUNT_NAME}
              value={selectedViewPaymentMethod!.accountName!}
              setValue={(value: string) => null}
              title={LABELS.ACCOUNT_NAME}
              mandatory
              big
              disabled
            />
            <Input
              placeholder={LABELS.BSB}
              value={selectedViewPaymentMethod!.bsb!}
              setValue={(value: string) => null}
              title={LABELS.BSB}
              mandatory
              big
              endElement={<img src={lockIcon} alt="" />}
              disabled
            />
            <Input
              placeholder={LABELS.ACCOUNT_NUMBER}
              value={selectedViewPaymentMethod!.accountNumber!}
              setValue={(value: string) => null}
              title={LABELS.ACCOUNT_NUMBER}
              mandatory
              big
              endElement={<img src={lockIcon} alt="" />}
              disabled
            />
            {/* <div className={classes.buttonContainer}>
              <Button parentStyles={classes.button} onPress={() => setShowConfirmation(true)}>
                {LABELS.DELETE}
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={classes.popupHeader}>
          <div className={classes.popupLabel}>{LABELS.DELETE_DRAWER_TITLE}</div>
          <div className={classes.closeContainer} onClick={() => setShowConfirmation(false)}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        <div className={classes.bottomDrawer}>
          <div className={classes.disabledMessage}>{LABELS.DELETE_DRAWER_SUBTITLE}</div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedViewPaymentMethod: state.payment.selectedViewPaymentMethod,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deletePaymentMethod: (paymentRefId: string, handleDeletePayment: (response: string[]) => void) =>
    dispatch(
      PaymentActions.deletePaymentStart({
        paymentRefId,
        onSuccess: (response: string[]) => handleDeletePayment(response),
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBank);
