import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
  pageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
    },
    centerPageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black38,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 8,
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 8,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
      marginLeft: theme.units.spacing * 4,
    },
    imageStyle: {
      width: 24,
      height: 24,
      objectFit: 'contain',
    },
    defaultIconStyle: {
      width: 24,
      height: 24,
      borderRadius: 12,
      objectFit: 'contain',
    },
    listItemStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    emptyCardStyle: {
      height: 64,
      backgroundColor: '#f9f9f9',
      display: 'flex',
      paddingLeft: 24,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    textStyle: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 14,
      fontWeight: 300,
    },
    optInSectionStyle: {
      marginBottom: 64,
    },
    doneIconStyle: {
      width: 24,
      height: 24,
      marginRight: 16,
    },
    subTextStyle: {
      padding: 16,
      color: theme.colors.black60,
    },
    card: {
      backgroundColor: theme.colors.light,
      padding: '12px',
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: '12px',
    },
  })();
};
