/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getFonts } from '../../helpers/GetFonts';
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      marginBottom: theme.units.spacing * 4,
      flexShrink: 0,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: theme.units.borderRadius / 1.5,
      border: `${theme.colors.borderColor} 2px solid`,
      '&:focus-visible': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.inputText,
        fontSize: theme.font.m,
        fontWeight: getFonts('Light'),
      },
      backgroundColor: theme.colors.inputBackground,
      padding: theme.units.spacing * 2,
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 2,
      cursor: 'pointer',
    },
    inputContainerBig: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      padding: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 4,
    },
    inputStyle: {
      border: 'none',
      '&:focus-visible': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.inputText,
        fontSize: theme.font.m,
        fontWeight: getFonts('Light'),
      },
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      marginRight: theme.units.spacing * 3,
      overflow: 'scroll',
      color: theme.colors.inputText,
      flex: 1,
    },
    inputStyleBig: {
      '&::-webkit-input-placeholder': {
        color: theme.colors.black38,
        fontSize: theme.font.m,
        fontWeight: getFonts('Medium'),
      },
    },
    value: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      marginRight: theme.units.spacing * 3,
      whiteSpace: 'nowrap',
      overflow: 'scroll',
    },
    placeholder: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
      // color: theme.colors.disabledText,
      marginRight: theme.units.spacing * 3,
    },
    placeholderBig: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      marginRight: theme.units.spacing * 3,
    },
    accordianContainer: {
      marginLeft: 'auto',
      width: 'max-content',
      height: 'max-content',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      minHeight: 25,
    },
    accordian: {
      width: theme.units.iconSize / 1.8,
      height: theme.units.iconSize / 1.8,
      objectFit: 'contain',
      objectPosition: 'center',
      transition: 'all 0.5s ease 0s',
    },
    accordianActive: {
      transform: 'rotate(180deg)',
      transition: 'all 0.5s ease 0s',
    },
    errorInput: {
      border: `${theme.colors.error} 2px solid`,
    },
    errorTextStyle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      marginBottom: theme.units.spacing,
    },
    secondInput: {
      marginLeft: theme.units.spacing * 4,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.units.spacing,
    },
    titleContainerBig: {
      marginBottom: theme.units.spacing * 4,
    },
    titleStyle: {
      color: theme.colors.inputText,
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
    },
    titleStyleBig: {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
    },
    mandatory: {
      color: theme.colors.buttonPrimary,
      fontSize: theme.font.s,
      marginLeft: theme.units.spacing,
      fontWeight: getFonts('Medium'),
    },
    endElement: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    endElementBig: {
      marginRight: theme.units.spacing * 2,
    },
    endStatus: {
      marginLeft: theme.units.spacing * 2,
      width: 25,
      height: 25,
    },
    endStatusBig: {
      // marginRight: theme.units.spacing * 3,
    },
    menu: {
      position: 'absolute',
      top: 40,
      left: -2,
      width: 'auto',
      minWidth: 'max-content',
      paddingTop: theme.units.spacing * 2,
      paddingBottom: theme.units.spacing * 2,
      backgroundColor: theme.colors.inputBackground,
      borderRadius: theme.units.borderRadius / 1.5,
      border: `${theme.colors.borderColor} 2px solid`,
      zIndex: 10,
      maxHeight: 300,
      overflow: 'scroll',
    },
    menuItem: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      paddingTop: theme.units.spacing * 2,
      paddingBottom: theme.units.spacing * 2,
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 3,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    disabled: {
      opacity: 0.4,
      cursor: 'default',
    },
  })();
};
