import { ToastState } from '../state/ToastState';
import { ToastAction, ToastActionsTypes } from '../actions/ToastActions';

export const initialState: ToastState = {
  showToast: false,
  message: '',
};

export const toastReducer = (state = initialState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionsTypes.SHOW_TOAST:
      return {
        ...state,
        ...action.data,
        showToast: true,
      };
      break;

    case ToastActionsTypes.HIDE_TOAST:
      return {
        ...state,
        ...action.data,
        showToast: false,
      };
      break;
    default:
      return state;
  }
};
