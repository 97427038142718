import { getFonts } from '../../helpers/GetFonts';
import { actionGreen } from '../../theme/GlobalStyles';
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    planInformationWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      minHeight: 'calc(100vh - 280px)',
    },
    planInformationContainer: {
      padding: 15,
      paddingTop: 0,
      zIndex: 1,
      position: 'relative',
      // paddingBottom: 60,
    },
    noPadding: {
      padding: 0,
      marginTop: -15,
    },
    compareScroll: {
      position: 'relative',
      marginRight: theme.units.spacing * 8,
      height: 'calc(100vh - 364px)',
      padding: 0,
      overflow: 'scroll',
    },
    section: {
      marginTop: 15,
      padding: 15,
      backgroundColor: theme.colors.light,
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: theme.units.borderRadius,
      // '&:first-child': {
      //   marginTop: 5,
      // },
    },
    noBorder: {
      border: 'none !important',
      marginTop: 0,
    },
    planHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    logoContainer: {
      height: '40px',
      width: '40px',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    titleContainer: {
      marginLeft: 15,
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      paddingBottom: 3,
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black60,
    },
    description: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
    },
    titleContainer2: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      paddingBottom: theme.units.spacing * 3,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: theme.units.spacing * 4,
    },
    title2: {
      fontWeight: getFonts('Regular'),
      fontSize: 12,
      color: theme.colors.black60,
      marginBottom: theme.units.spacing,
    },
    description2: {
      fontWeight: getFonts('Medium'),
      fontSize: 22,
      color: theme.colors.black87,
    },
    priceContainer: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    from: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.black38,
    },
    from2: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.black87,
      marginBottom: theme.units.spacing,
    },
    priceWrapper: {
      display: 'flex',
      alignItems: 'baseline',
    },
    price: {
      fontWeight: getFonts('Medium'),
      fontSize: '20px',
      color: theme.colors.buttonPrimary,
    },
    uom: {
      fontWeight: getFonts('Medium'),
      fontSize: '12px',
      color: theme.colors.buttonPrimary,
    },
    price2: {
      fontWeight: getFonts('Medium'),
      fontSize: '26px',
      color: theme.colors.buttonPrimary,
    },
    uom2: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.buttonPrimary,
    },
    sectionTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: '18px',
      color: theme.colors.black87,
      marginBottom: 10,
    },
    textContainer: {
      marginTop: 10,
    },
    regularText: {
      fontWeight: getFonts('Light'),
      fontSize: '14px',
      color: theme.colors.black87,
      marginTop: theme.units.spacing,
    },
    boldText: {
      marginRight: 5,
      fontWeight: getFonts('Medium'),
      fontSize: '16px',
      color: theme.colors.black87,
    },
    link: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.buttonPrimary,
      textDecoration: 'underline',
    },
    highlight: {
      fontWeight: getFonts('Light'),
      fontSize: '14px',
      color: theme.colors.buttonPrimary,
    },
    popupHeader: {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'end',
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 16,
      marginBottom: 16,
    },
    popupActionContainer: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      cursor: 'pointer',
    },
    popupAction: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
    primaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 40,
      color: theme.colors.light,
      textAlign: 'center',
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
      marginTop: 15,
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    card: {
      borderRadius: 12,
      marginTop: 15,
      backgroundColor: theme.colors.light,
    },
    cardHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.units.spacing * 3,
      paddingBottom: theme.units.spacing * 3,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    content: {
      overflow: 'hidden',
      transition: 'all 0.3s ease-out',
      height: '100%',
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 15px',
      backgroundColor: theme.colors.light,
      borderTop: `${theme.colors.borderColorLight} 1px solid`,
      cursor: 'pointer',
    },
    actionTitle: {
      marginRight: 'auto',
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.black87,
    },
    actionIconContainer: {
      marginLeft: 'auto',
      width: 14,
      height: 14,
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
    },
    actionIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      // ...actionGreen,
    },
    rotate: {
      transform: 'rotate(180deg)',
    },
    cardTitle: {
      marginRight: 'auto',
      fontWeight: getFonts('Medium'),
      fontSize: '18px',
      color: theme.colors.black87,
    },
    planScroll: {
      // overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      // height: 'calc(100% - 190px)',
      height: '100%',
      marginTop: 15,
      pointerEvents: 'all',
    },
    infoText: {
      fontWeight: getFonts('Light'),
      fontSize: 14,
      color: theme.colors.black60,
      marginTop: 15,
      lineHeight: 1.5,
    },
    supplierBackgroundImgContainer: {
      width: '100%',
      height: '180px',
      position: 'relative',
      marginBottom: -50,
    },
    supplierBackgroundImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    closeContainer: {
      position: 'absolute',
      top: 15,
      right: 15,
      textAlign: 'center',
      margin: 'auto',
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      filter: 'invert(1)',
      cursor: 'pointer',
    },
    closeIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    closeContainer2: {
      position: 'absolute',
      right: 0,
      top: 15,
      textAlign: 'center',
      margin: 'auto',
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      cursor: 'pointer',
    },
    planCompareContainer: {
      paddingBottom: theme.units.spacing * 8,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    buttonContainer: {
      display: 'flex',
    },
    compareButtonContainer: {
      left: 0,
      right: 15,
      bottom: 24,
      position: 'absolute',
      width: '100%',
      display: 'flex',
    },
    removeCompare: {
      height: 38,
      width: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: theme.colors.light,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      borderRadius: 6,
      marginRight: theme.units.spacing * 2,
    },
    bin: {
      height: theme.units.iconSize,
      width: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
