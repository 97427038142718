import { SpinnerState } from '../state/SpinnerState';
import { SpinnerAction, SpinnerActionTypes } from '../actions/SpinnerActions';

export const initialState: SpinnerState = {
  isLoading: false,
};

export const spinnerReducer = (state = initialState, action: SpinnerAction) => {
  switch (action.type) {
    case SpinnerActionTypes.ShowIndicator:
      return {
        ...state,
        isLoading: true,
      };

    case SpinnerActionTypes.HideIndicator:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
