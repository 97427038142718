import {
  PropertyOnboardingRequest,
  SetOnboardingPropertyDetails,
} from '../../models/property/property';
import { StoreAction } from '../StoreHelper';

export enum PropertyActionTypes {
  POST_PROPERTY = 'POST_PROPERTY',
  POST_PROPERTY_REQUEST = 'POST_PROPERTY_REQUEST',
  POST_PROPERTY_SUCCESS = 'POST_PROPERTY_SUCCESS',
  POST_PROPERTY_ERROR = 'POST_PROPERTY_ERROR',

  SET_PROPERTY_DETAILS = 'SET_PROPERTY_DETAILS',
}

export type PropertyActionPayload =
  | PropertyOnboardingRequest
  | SetOnboardingPropertyDetails
  | Error;

export type PropertyAction = StoreAction<PropertyActionTypes, PropertyActionPayload>;

export class PropertyActions {
  public static postPropertyRequest(data: PropertyOnboardingRequest): PropertyAction {
    return {
      type: PropertyActionTypes.POST_PROPERTY_REQUEST,
      data,
    };
  }
  public static postPropertySuccess(): PropertyAction {
    return {
      type: PropertyActionTypes.POST_PROPERTY_SUCCESS,
      data: null,
    };
  }
  public static postPropertyError(error: Error): PropertyAction {
    return {
      type: PropertyActionTypes.POST_PROPERTY_ERROR,
      data: error,
    };
  }

  public static setPropertyDetails(data: SetOnboardingPropertyDetails): PropertyAction {
    return {
      type: PropertyActionTypes.SET_PROPERTY_DETAILS,
      data,
    };
  }
}
