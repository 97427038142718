/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    tabMenu: {
      height: 45,
      width: '100%',
      maxWidth: 'calc(1200px - 24px)',
      borderBottom: `1px solid ${theme.colors.borderColorLight}`,
      display: 'flex',
      marginBottom: theme.units.spacing * 4,
    },
    tabWrapper: {
      marginRight: theme.units.spacing * 12,
      height: 'calc(100% - 3px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.units.spacing * 3,
      paddingRight: theme.units.spacing * 3,
      minWidth: 92,
      borderBottom: `${theme.colors.light} 3px solid`,
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginRight: theme.units.spacing * 8,
      },
    },
    unselectedTabText: {
      color: theme.colors.black38,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      textAlign: 'center',
    },
    selected: {
      borderBottom: `${theme.colors.error} 3px solid`,
    },
    selectedTabText: {
      color: theme.colors.black87,
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      textAlign: 'center',
    },
  })();
};
