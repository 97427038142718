import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from '../../CheckoutConfigureStyles';
import { get, isEmpty } from 'lodash';
import { LABELS } from '../../CheckoutConfigureConstants';
import { Options } from 'react-redux';
import { updateCardDataInAvailableServices } from '../../../../helpers/CheckoutHelper';
import { ServiceTypes } from '../../../../models/services/services';
import CartItem from '../../../cart/components/cartItem/CartItem';
import ServiceTabs from '../../../checkoutSelect/components/serviceTabs/ServiceTabs';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesResponse,
  InitialConcessionCardValues,
  PageName,
  PersonalDetails,
  ServiceDetailsData,
} from '../../../../models/checkout/Checkout';
import { essentialServices } from '../../../checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import uuid from 'uuid';
import {
  ProviderPlan,
  PlanOption,
  PlanDetailObject,
  FeatureDetails,
  SummaryDetail,
  ConcessionCard,
  ProductDetails,
} from '../../../../models/billing/Billing';
import { checkPlanDetails, setSummary } from '../../CheckoutConfigureUtils';
import {
  getPreAgreementSummary,
  getProductDetails,
  getTermsAndConditionsValue,
  setSupplierAnswers,
} from '../../components/selectOptions/SelectOptionsUtils';
import { Property } from '../../../../models/property/property';
import SelectOptions from '../../components/selectOptions/SelectOptions';
import SelectTerms from '../../components/selectTerms/SelectTerms';
import Button from '../../../../components/button/Button';
import { hasAnyPayments } from '../../../checkoutFinalize/CheckoutFinalizeUtils';

interface SelectedPlanProps {
  availableServices: AvailableServicesResponse[];
  personalDetails: PersonalDetails;
  property: Property;
  isExternal: boolean;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  selectedPlan: AvailableServicesResponse;
  onNext: () => void;
  activeTab: number;
  cartItems: AvailableServicesResponse[];
  cartServices: AvailableServicesResponse[];
  cartCount: number;
  setActiveTab: (value: number) => void;
}

const SelectedPlan: FC<SelectedPlanProps> = ({
  availableServices,
  personalDetails,
  property,
  isExternal,
  updateCart,
  selectedPlan,
  activeTab,
  cartItems,
  cartServices,
  cartCount,
  setActiveTab,
  onNext,
}) => {
  const classes = useStyles();
  const extendedData = useMemo<ProviderPlan>(() => {
    return selectedPlan && selectedPlan.suppliers![0].extendedData!;
  }, [selectedPlan]);

  // TODO: Types conflict here
  const { productOptions, extendedDataPlan } = useMemo(() => {
    return getProductDetails(extendedData, selectedPlan.suppliers![0].plans[0].selectedProductId);
  }, [extendedData, selectedPlan]);

  const [productDetails, setProductDetails] = useState<Options | {}>(productOptions);

  const cartData = useMemo<ServiceDetailsData | null | undefined>(() => {
    return selectedPlan && selectedPlan.suppliers![0].plans[0].cartData;
  }, [selectedPlan]);

  const planOptions = useMemo<PlanOption[] | undefined>(() => {
    if (!!(extendedDataPlan as PlanDetailObject).Options) {
      return (extendedDataPlan as PlanDetailObject).Options.filter(
        (option: PlanOption) => option.Options.length > 1,
      );
    }
    return undefined;
  }, [extendedDataPlan]);

  const planLinks = get(extendedDataPlan, 'Links', []);

  const [concessionCard, setConcessionCard] = useState<InitialConcessionCardValues | null>(null);
  const [stopSaleList, setStopSaleList] = useState<string[]>([]);
  const [planSummary, setPlanSummary] = useState<Map<string, FeatureDetails> | {}>({
    ...setSupplierAnswers(
      selectedPlan.suppliers![0].extendedData!.SupplierOptions,
      planOptions,
      cartData,
      cartItems,
    ).supplierAnswer,
  });
  const [isTermsConditionAccepted, setTermsAndConditionsAccepted] = useState<boolean>(
    getTermsAndConditionsValue(cartData).isTermsAndConditionsAccepted,
  );
  const [isPlanDetailsCompleted, setIsPlanDetailsCompleted] = useState<boolean>(
    checkPlanDetails(planSummary, concessionCard),
  );

  const areAllServicesCompleted = useMemo<boolean>(() => {
    const result = cartItems.filter((item, index) =>
      index === activeTab
        ? isPlanDetailsCompleted && isTermsConditionAccepted && stopSaleList.length === 0
        : item.complete && item.complete!.options && item.complete!.terms,
    ).length;

    return result === cartItems.length;
  }, [isPlanDetailsCompleted, isTermsConditionAccepted, activeTab, cartItems]);

  useEffect(() => {
    setIsPlanDetailsCompleted(checkPlanDetails(planSummary, concessionCard));
  }, [planSummary, concessionCard]);

  const submitPlanDetails = () => {
    // product details
    const selectedProductDetails: ProductDetails = {
      productCode: get(productDetails, 'ProductId', ''),
      productId: get(productDetails, 'ProductId', ''),
      productName: get(productDetails, 'PlanName', ''),
      monthlyCost: get(productDetails, 'BasePriceMonthly', '0'),
      dailyCost: get(productDetails, 'BasePriceDaily', '0'),
      cost: get(productDetails, 'BasePriceMonthly', '0'),
      productType: extendedData.ProductType,
      productDescription: get(productDetails, 'Label', ''), // TODO need to update
      provider: selectedPlan.suppliers![0].name,
    };

    // features details
    const featureDetails: FeatureDetails[] = [];
    const settings =
      extendedData && extendedData.Settings && extendedData.Settings.length > 0
        ? extendedData.Settings
        : [];
    const officeSetting = settings.find((setting) => setting.Name === 'TradesOfficeId');
    const officeId = officeSetting ? officeSetting.Value : null;

    Object.keys({ ...planSummary }).map((keyName) => {
      featureDetails.push({
        ...planSummary[keyName],
        cost: planSummary[keyName].cost || null,
      });
    });

    if (officeId) {
      const officeDetails: FeatureDetails = {
        type: 'officeId',
        label: 'officeId',
        labelId: 'officeId',
        value: officeId,
        valueId: 'officeId',
        cost: null,
      };
      featureDetails.push(officeDetails);
    }
    if (extendedData.PostAgreementSummary) {
      const bodySummary: FeatureDetails = {
        type: 'postSummaryDetails',
        label: 'Body',
        labelId: 'Body',
        value: extendedData.PostAgreementSummary.Body.toString(),
        valueId: 'postSummary',
        cost: null,
      };
      const titleSummary: FeatureDetails = {
        type: 'postSummaryDetails',
        label: 'title',
        labelId: 'title',
        value: extendedData.PostAgreementSummary.Title.toString(),
        valueId: 'postSummary',
        cost: null,
      };
      featureDetails.push(bodySummary);
      featureDetails.push(titleSummary);
    }

    // summary details
    const summaryDetails: SummaryDetail[] = setSummary(selectedProductDetails, extendedData);

    // concession card
    let concessionCardDetails: ConcessionCard | null = null;
    if (concessionCard) {
      const expiry = concessionCard.expiry!.split('/');
      concessionCardDetails = {
        name: concessionCard.name,
        cardNumber: concessionCard.cardNumber,
        cardType: concessionCard.cardType,
        expiryMonth: expiry[0],
        expiryYear: expiry[1],
      };
    }
    const transactionId =
      selectedPlan.suppliers![0].plans[0].cartData &&
      selectedPlan.suppliers![0].plans[0].cartData.transactionId
        ? selectedPlan.suppliers![0].plans[0].cartData.transactionId
        : uuid();

    const data: ServiceDetailsData = {
      lookupDetails: null,
      serviceabilityAddress: property,
      acceptTermAndCondition: isTermsConditionAccepted,
      connectionDateDetails: null,
      paymentRefId:
        selectedPlan.suppliers![0].plans[0].cartData &&
        selectedPlan.suppliers![0].plans[0].cartData.paymentRefId
          ? selectedPlan.suppliers![0].plans[0].cartData.paymentRefId
          : null,
      personalDetails,
      planDetails: {
        productDetails: selectedProductDetails,
        featureDetails,
        summaryDetails,
        attributes: {},
        transactionId: transactionId,
        concessionCard: concessionCardDetails,
        isAcceptTermAndCondition: true,
        skipPaymentStep: isEmpty(extendedDataPlan)
          ? true
          : !(extendedDataPlan as PlanDetailObject).PaymentInformation.Required,
        paymentType: isEmpty(extendedDataPlan)
          ? null
          : (extendedDataPlan as PlanDetailObject).PaymentInformation.PaymentGateway,
      },
      transactionId: transactionId,
    };
    const updatedAvailableServices = updateCardDataInAvailableServices(
      availableServices,
      selectedPlan.type,
      selectedPlan.suppliers![0].providerId,
      selectedPlan.suppliers![0].plans[0].productId,
      data,
    );

    updateCart({
      availableServices: updatedAvailableServices,
      propertyId: `${property.id}`,
    });
  };

  return (
    <>
      <div className={classes.primaryContainer}>
        {cartItems.length > 1 && (
          <div className={classes.serviceTabs}>
            <ServiceTabs
              activeServices={cartItems}
              selectedTab={activeTab}
              setSelectedTab={(value) => {
                submitPlanDetails();
                setActiveTab(value);
              }}
              pageName={PageName.CHECKOUTCONFIGURE}
              overrideCompleted={
                isPlanDetailsCompleted && isTermsConditionAccepted && stopSaleList.length === 0
              }
            />
            <div style={{ marginTop: -16 }}>
              <CartItem
                hideAction
                hidePrice
                cartItem={cartItems[activeTab]}
                key={`${cartItems[activeTab].type}`}
                handleAction={() => null}
                handleTap={() => null}
              />
            </div>
          </div>
        )}
        <div className={classes.title} style={{ marginTop: 16, marginBottom: 32 }}>
          {LABELS.CONFIRM_DETAILS}
        </div>
        {cartItems.length > 1 && (
          <div className={classes.mobileActiveItem}>
            <CartItem
              hideAction
              hidePrice
              cartItem={cartItems[activeTab]}
              key={`${cartItems[activeTab].type}`}
              handleAction={() => null}
              handleTap={() => null}
            />
          </div>
        )}

        <div key={`${activeTab}_select_plan`} className={classes.section}>
          <SelectOptions
            setPlanSummary={setPlanSummary}
            selectedSupplierOptions={selectedPlan.suppliers![0].extendedData!.SupplierOptions}
            planOptions={planOptions}
            setConcessionCard={setConcessionCard}
            stopSaleList={stopSaleList}
            setStopSaleList={setStopSaleList}
            cartData={cartData}
            cartItems={cartItems}
            extendedDataPlan={extendedDataPlan as PlanDetailObject}
            setProductDetails={setProductDetails}
            key={`${selectedPlan.suppliers![0].providerId}`}
            publicHolidays={extendedData.PublicHolidays}
          />
          {/* Plan terms --------------------------------------------------------------- */}
          <div className={classes.termsTitle}>{LABELS.TERMS}</div>
          <SelectTerms
            setTermsAndConditionsFlag={(flag: boolean) => {
              setTermsAndConditionsAccepted(flag);
            }}
            preAgreementSummary={getPreAgreementSummary(
              selectedPlan.suppliers![0].extendedData!.PreAgreementSummary,
              extendedDataPlan as PlanDetailObject,
            )}
            uiModelAttributes={selectedPlan.suppliers![0].extendedData!.UiModelAttributes}
            cartData={cartData}
            isExternal={isExternal}
            planLinks={planLinks}
          />
          <div className={classes.buttonContainer}>
            <Button
              onPress={() => {
                submitPlanDetails();
                onNext();
              }}
              parentStyles={classes.button}
              disabled={(() => {
                // If last tab
                if (activeTab + 1 === cartCount) {
                  return !areAllServicesCompleted;
                } else {
                  return !(
                    isPlanDetailsCompleted &&
                    isTermsConditionAccepted &&
                    stopSaleList.length === 0
                  );
                }
              })()}
            >
              {activeTab === cartItems.length - 1 ? LABELS.NEXT : LABELS.NEXT_SERVICE}
            </Button>
          </div>
        </div>
        {!!cartServices.length && (
          <>
            <div className={classes.disabledTitle}>{LABELS.PERSONAL_DETAILS}</div>
            {hasAnyPayments(cartItems) && (
              <div className={classes.disabledTitle}>{LABELS.PAYMENT}</div>
            )}
          </>
        )}
        <div className={classes.disabledTitle}>{LABELS.SUMMARY}</div>
      </div>
      <div className={classes.secondaryContainer}>
        <div className={classes.summaryContainer}>
          <div className={classes.summaryTitle}>{LABELS.SUMMARY}</div>
          <div className={classes.cards}>
            {!!cartItems.length && (
              <>
                {!!cartItems.length &&
                  cartItems.map((cartItem, i) => (
                    <CartItem
                      hideAction
                      hidePrice
                      cartItem={cartItem}
                      selected={i === activeTab}
                      key={`${cartItem.type}`}
                      handleTap={() => {
                        submitPlanDetails();
                        setActiveTab(i);
                      }}
                      handleAction={() => null}
                      quote={!essentialServices.includes(cartItem.type as ServiceTypes)}
                      showTick={
                        cartItem.complete && cartItem.complete!.options && cartItem.complete!.terms
                      }
                      selectable
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedPlan;
