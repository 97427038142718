/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { get, isNil } from 'lodash';
import {
  AvailableServicesResponse,
  SERVICE_TYPE_TITLE,
  Services,
  ServiceTypes,
  STATUS_TITLE,
} from '../../models/services/services';
import { AccountStatus } from '../../constants/AccountStatus';
import { onboardingUrls } from '../../constants/OnboardingURLs';
import { createPreopen } from '../../helpers/CheckoutHelper';
import Tile from '../tile/Tile';
import ServiceIcon from '../serviceIcon/ServiceIcon';
import EmptyServiceCard from '../emptyServiceCard/EmptyServiceCard';
import ICON_ADD_SERVICE from '../../assets/plus.png';
import ServiceCard from '../serviceCard/ServiceCard';
import { findExtraServiceInfo } from '../../helpers/ServiceInfoHelper';
import { resetService, shouldResetService } from '../../helpers/ResetServiceHelper';
import { IntercomChat } from '../../helpers/IntercomChat';
import { Route, navigate } from '../../navigation/NavigationUtils';

interface OtherServiceCardsProps {
  addressConfirmed: boolean;
  services: Services[];
  availableServices: AvailableServicesResponse[];
  propertyId: number;
  showTile?: boolean;
  showIconOnly?: boolean;
  showSearchIcon?: boolean;
  small?: boolean;
  smaller?: boolean;
  parentStyles?: string;
  coloured?: boolean;
}

const OtherServiceCards: FC<OtherServiceCardsProps> = ({
  addressConfirmed,
  services,
  availableServices,
  propertyId,
  showTile,
  showIconOnly,
  showSearchIcon,
  small,
  smaller,
  parentStyles,
  coloured,
}) => {
  return (
    <>
      {services.map((service: Services, index) => {
        const extraServiceInfo = findExtraServiceInfo(service, availableServices);
        const shouldReset = shouldResetService(service, extraServiceInfo.Suppliers[0]);

        if (service.status === AccountStatus.NEW) {
          const showOptions = extraServiceInfo.Suppliers && extraServiceInfo.Suppliers.length > 1;

          return (
            <>
              {showTile ? (
                <Tile
                  key={index}
                  icon={extraServiceInfo.Logo.Dark}
                  title={SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType}
                  subtitle="Add"
                  handlePress={() => {
                    if (
                      showOptions &&
                      (service.serviceType === ServiceTypes.Removals ||
                        service.serviceType === ServiceTypes.Insurance)
                    ) {
                      extraServiceInfo.Suppliers.map((supplier) => {
                        supplier.ServiceabilityRequired = extraServiceInfo.ServiceabilityRequired;
                      });
                      navigate('suppliersScreen', {
                        service,
                        suppliers: extraServiceInfo.Suppliers,
                      });
                    } else {
                      if (shouldReset) {
                        resetService(service);
                      }
                      navigate('checkoutSelect', {
                        requiredServiceTypes: service.serviceType,
                      });
                    }
                  }}
                />
              ) : (
                <>
                  {showIconOnly ? (
                    <ServiceIcon
                      key={index}
                      icon={extraServiceInfo.Logo.Dark}
                      name={SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType}
                      parentStyles={parentStyles}
                      coloured={coloured}
                      handlePress={() => {
                        if (
                          showOptions &&
                          (service.serviceType === ServiceTypes.Removals ||
                            service.serviceType === ServiceTypes.Insurance)
                        ) {
                          extraServiceInfo.Suppliers.map((supplier) => {
                            supplier.ServiceabilityRequired =
                              extraServiceInfo.ServiceabilityRequired;
                          });
                          navigate('suppliersScreen', {
                            service,
                            suppliers: extraServiceInfo.Suppliers,
                          });
                        } else {
                          if (shouldReset) {
                            resetService(service);
                          }
                          navigate('checkoutSelect', {
                            requiredServiceTypes: service.serviceType,
                          });
                        }
                      }}
                    />
                  ) : (
                    <EmptyServiceCard
                      key={index}
                      serviceName={SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType}
                      leftIcon={extraServiceInfo.Logo.Dark}
                      suppliers={extraServiceInfo.Suppliers}
                      serviceInfo={service}
                      rightIcon={ICON_ADD_SERVICE}
                      redirectTo="checkoutSelect"
                      redirectProps={{
                        requiredServiceTypes: service.serviceType,
                      }}
                    />
                  )}
                </>
              )}
            </>
          );
        } else {
          let url = '';
          if (service.status === AccountStatus.INPROGRESS) {
            const lookupRequired = extraServiceInfo.ServiceabilityRequired;
            const addressConfirmed = false;
            if (!isNil(extraServiceInfo.Suppliers)) {
              if (extraServiceInfo.Suppliers.length === 1) {
                if (extraServiceInfo.Suppliers[0].SignupFlow === 'ReferralIframe') {
                  url = onboardingUrls.iframeService(
                    service.serviceType,
                    propertyId.toString(),
                    get(extraServiceInfo, 'Suppliers.0.SupplierId', ''),
                    addressConfirmed.toString(),
                    lookupRequired.toString(),
                    service ? service.serviceAccountId.toString() : '',
                  );
                } else if (extraServiceInfo.Suppliers[0].SignupFlow === 'V1') {
                  url = onboardingUrls.otherServiceSignUpUrl(
                    service.serviceType.replace(' ', ''),
                    propertyId,
                    get(extraServiceInfo, 'Suppliers.0.SupplierId', ''),
                  );
                } else {
                  url = onboardingUrls.commonServiceOverview(
                    service.serviceType,
                    propertyId.toString(),
                    service.provider,
                    addressConfirmed.toString(),
                    lookupRequired.toString(),
                    get(service, 'serviceAccountId', null),
                  );
                }
              } else if (service.category === 'REFERRAL_IFRAME') {
                url = onboardingUrls.iframeService(
                  service.serviceType,
                  propertyId.toString(),
                  service.provider,
                  addressConfirmed.toString(),
                  lookupRequired.toString(),
                  service ? service.serviceAccountId.toString() : '',
                );
              } else if (service.category !== 'USAGE') {
                url = onboardingUrls.otherServiceSignUpUrl(
                  service.serviceType.replace(' ', ''),
                  propertyId,
                  get(extraServiceInfo, 'Suppliers.0.SupplierId', ''),
                );
              } else {
                url = onboardingUrls.commonServiceOverview(
                  service.serviceType,
                  propertyId.toString(),
                  service.provider,
                  addressConfirmed.toString(),
                  lookupRequired.toString(),
                  get(service, 'serviceAccountId', null),
                );
              }
            } else if (isNil(service.category) || service.category !== 'USAGE') {
              url = onboardingUrls.otherServiceSignUpUrl(
                service.serviceType.replace(' ', ''),
                propertyId,
                get(extraServiceInfo, 'Suppliers.0.SupplierId', ''),
              );
            } else {
              url = onboardingUrls.commonServiceOverview(
                service.serviceType,
                propertyId.toString(),
                service.provider,
                addressConfirmed.toString(),
                lookupRequired.toString(),
                get(service, 'serviceAccountId', null),
              );
            }
          } else {
            url =
              service.serviceType === ServiceTypes.Gas
                ? onboardingUrls.commonServiceSummary(propertyId, service.serviceAccountId)
                : onboardingUrls.otherServiceInfo(
                    service.serviceType.replace(' ', ''),
                    propertyId,
                    service.serviceAccountId,
                  );
          }
          const showOptions = extraServiceInfo.Suppliers && extraServiceInfo.Suppliers.length > 1;

          return (
            <>
              {showTile ? (
                <Tile
                  key={index}
                  icon={extraServiceInfo.Logo.Dark}
                  active
                  status={STATUS_TITLE[service.serviceType] || undefined}
                  title={SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType}
                  subtitle={service.provider}
                  handlePress={() => {
                    navigate(
                      service.status === AccountStatus.INPROGRESS
                        ? 'onboarding'
                        : service.category === 'USAGE' || service.category === 'REFERRAL_IFRAME'
                        ? 'genericServiceSummaryScreen'
                        : 'onboarding',
                      service.category === 'USAGE' || service.category === 'REFERRAL_IFRAME'
                        ? {
                            serviceAccountId: service.serviceAccountId,
                            url,
                            propertyId,
                            serviceType: service.serviceType,
                          }
                        : {
                            url,
                          },
                    );
                  }}
                />
              ) : (
                <>
                  {showIconOnly ? (
                    <ServiceIcon
                      key={index}
                      icon={extraServiceInfo.Logo.Dark}
                      name={SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType}
                      parentStyles={parentStyles}
                      coloured={coloured}
                      handlePress={() => {
                        if (
                          showOptions &&
                          (service.serviceType === ServiceTypes.Removals ||
                            service.serviceType === ServiceTypes.Insurance)
                        ) {
                          IntercomChat.logIntercomEvent(service.serviceType, {
                            dashboard_tile: service.serviceType,
                          });
                          extraServiceInfo.Suppliers.map((supplier) => {
                            supplier.ServiceabilityRequired =
                              extraServiceInfo.ServiceabilityRequired;
                          });
                          navigate('suppliersScreen', {
                            service,
                            suppliers: extraServiceInfo.Suppliers,
                          });
                        } else {
                          IntercomChat.logIntercomEvent(service.serviceType, {
                            dashboard_tile: service.serviceType,
                          });
                          IntercomChat.logIntercomEvent(extraServiceInfo.Suppliers[0].SupplierId, {
                            dashboard_tile: service.serviceType,
                          });
                          navigate('onboarding', {
                            url,
                          });
                        }
                      }}
                    />
                  ) : (
                    <>
                      {showSearchIcon ? (
                        <EmptyServiceCard
                          key={index}
                          serviceName={
                            SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType
                          }
                          leftIcon={extraServiceInfo.Logo.Dark}
                          suppliers={extraServiceInfo.Suppliers}
                          serviceInfo={service}
                          rightIcon={ICON_ADD_SERVICE}
                          redirectTo={
                            service.status === AccountStatus.INPROGRESS
                              ? 'onboarding'
                              : service.category === 'USAGE' ||
                                service.category === 'REFERRAL_IFRAME'
                              ? 'genericServiceSummaryScreen'
                              : 'onboarding'
                          }
                          redirectProps={
                            service.category === 'USAGE' || service.category === 'REFERRAL_IFRAME'
                              ? {
                                  serviceAccountId: service.serviceAccountId,
                                  url,
                                  propertyId,
                                  serviceType: service.serviceType,
                                }
                              : {
                                  url,
                                }
                          }
                        />
                      ) : (
                        <ServiceCard
                          key={index}
                          serviceName={
                            SERVICE_TYPE_TITLE[service.serviceType] || service.serviceType
                          }
                          isOtherService
                          icon={extraServiceInfo.Logo.Dark}
                          iconFilled={extraServiceInfo.Logo.Dark}
                          serviceProviderDetail={service.provider}
                          cost={null}
                          status={service.status}
                          billingPeriod={service.paymentFrequency}
                          // If category is USAGE, go new generic summary screen flow, else go old flow
                          redirectTo={
                            service.status === AccountStatus.INPROGRESS
                              ? 'onboarding'
                              : service.category === 'USAGE' ||
                                service.category === 'REFERRAL_IFRAME'
                              ? 'genericServiceSummaryScreen'
                              : 'onboarding'
                          }
                          redirectProps={
                            service.category === 'USAGE' || service.category === 'REFERRAL_IFRAME'
                              ? {
                                  serviceAccountId: service.serviceAccountId,
                                  url,
                                  propertyId,
                                  serviceType: service.serviceType,
                                }
                              : {
                                  url,
                                }
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          );
        }
      })}
    </>
  );
};

export default OtherServiceCards;
