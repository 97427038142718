import { Services, Suppliers } from '../models/services/services';
import { ServiceActions } from '../store/actions/ServiceActions';
import { store } from '../store/Store';

export const shouldResetService = (service: Services, suppliers: Suppliers): boolean => {
  return service.category === 'USAGE' && service.providerId !== suppliers.SupplierId;
};

export const resetService = (service: Services) => {
  store.dispatch(
    ServiceActions.resetServicePlanRequest({ serviceAccountId: service.serviceAccountId }),
  );
};
