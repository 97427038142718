import { MaskDetails } from '../AddPaymentUtils';

export const bsbValidatorPattern = [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/];
export const bsbNumberRegex = /^\d{3}-?\d{3}$/;
export const accountNameRegex = /^[a-zA-Z\s]*$/;
export const accountNumberRegex = /[!@#$%^&*(),.?":{}|<>\s]/g;
export const errorMessages = {
  required: 'Required field',
  bsbFormat: 'Should match format [xxx-xxx]',
  invalidBsb: 'Invalid BSB',
  accNumMin: 'Should be minimum 6 characters',
  accNumMax: 'Should not exceed 10 characters',
  accName: 'Only alphabets allowed',
  accNameLength: 'Cannot exceed 30 characters',
};

export interface PaymentSummary {
  paymentRefId: string;
}

export interface CommonPaymentSummary {
  serviceDetails: PaymentSummary;
  step: string;
  propertyId?: string;
  serviceType?: string;
  providerId?: string;
  serviceAccountId?: string;
}

export const lengths = {
  accNumMin: 6,
  accNumMax: 10,
  accNameMax: 30,
};

export const LABELS = {
  NAV_TITLE: 'Add bank account',
  NAV_SUBTITLE:
    'Please add in your bank account details below — we’ll safely store these details for you.',
  ACCOUNT_NAME: 'Account name',
  BSB: 'BSB',
  ACCOUNT_NUMBER: 'Account number',
  LOADING: 'Processing...',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SECURE_CONNECTION: 'Secure',
};

export interface MatchParams {
  propertyId: string;
  serviceType: string;
  serviceAccountId: string;
  paymentRefId: string;
}

export interface InitialBankValues {
  readonly accountName: string;
  readonly bsbNumber: string;
  readonly accountNumber: string;
  readonly maskedBankAccountDetails: MaskDetails;
}

export const initialBankValues: InitialBankValues = {
  accountName: '',
  accountNumber: '',
  bsbNumber: '',
  maskedBankAccountDetails: {
    paymentDetails: '',
    logo: '',
  },
};
