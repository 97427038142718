export const LABELS = {
  TITLE: 'Get all your home services connected in minutes',
  SUBTITLE: (agencyCode?: string) =>
    `Let's make your house a home. ${
      agencyCode === 'NAX' || agencyCode === 'AU_SWIT' ? 'We partner with' : 'Sorted is'
    } Australia's first digital home services marketplace`,
  BUTTON: (agencyCode?: string) =>
    `Download ${agencyCode === 'NAX' || agencyCode === 'AU_SWIT' ? 'the app' : 'the app'}`,
};

export const links = {
  appleStore:
    'https://apps.apple.com/app/apple-store/id1232960956?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreNAX:
    'https://apps.apple.com/app/apple-store/id1489135381?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreTA:
    'https://apps.apple.com/app/apple-store/id1520540329?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreSwitch: 'https://apps.apple.com/us/app/the-switch/id6447742526',
  playstore:
    'https://play.google.com/store/apps/details?id=com.sortedservices.sorted&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreNAX:
    'https://play.google.com/store/apps/details?id=au.com.nelsonalexander.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreTA:
    'https://play.google.com/store/apps/details?id=au.com.theagency.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreSwitch: 'https://play.google.com/store/apps/details?id=com.theswitch.app',
};

export const openAndroidLink = (agencyCode?: string) => {
  switch (agencyCode) {
    case 'AU_SWIT':
      window.open(links.playstoreSwitch, '_blank');
      break;
    case 'NAX':
      window.open(links.playstoreNAX, '_blank');
      break;
    default:
      window.open(links.playstore, '_blank');
      break;
  }
};

export const openAppleLink = (agencyCode?: string) => {
  switch (agencyCode) {
    case 'AU_SWIT':
      window.open(links.appleStoreSwitch, '_blank');
      break;
    case 'NAX':
      window.open(links.appleStoreNAX, '_blank');
      break;
    default:
      window.open(links.appleStore, '_blank');
      break;
  }
};
