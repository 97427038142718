/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import value from 'card-validator';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import lockIcon from '../../../../assets/lock-24-px.svg';
import { dateValidator } from '../../../../helpers/DateFormatter';
import {
  CreditCardRequest,
  GetPaymentMethodsResponse,
  PostEditPaymentPayload,
  PostPaymentToken,
  PostPaymentUUIDRequest,
  SaveSelectedPaymentMethodPayload,
} from '../../../../models/payment/Payment';
import { AddCardParams } from '../../../../navigation/NavigationConstants';
import { extractParams, navigate, navigateBack } from '../../../../navigation/NavigationUtils';
import { ApplicationState } from '../../../../store/RootReducer';
import { PaymentActionTypes, PaymentActions } from '../../../../store/actions/PaymentActions';
import { SpinnerActions } from '../../../../store/actions/SpinnerActions';
import { PaymentServiceType } from '../../selectPayment/PaymentDetailsConstants';
import { MaskDetails, createEditPaymentObject, maskCardDetails } from '../AddPaymentUtils';
import {
  CardType,
  InitialCardValues,
  LABELS,
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardTypeNotSupportedError,
  expiryDateLength,
  initialCardValues,
  invalidCardError,
  nameFormatError,
  nameMaxLengthError,
  requiredFieldError,
} from './AddCardConstants';
import { useStyles } from './AddCardStyles';
import { handlePaymentSuccess, renderCardIcon } from './AddCardUtils';
import Input from '../../../../components/input/Input';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import InputDate from '../../../../components/inputDate/InputDate';
import { CardHelper } from '../../../../helpers/CardHelper';
import arrowLeft from '../../../../assets/navigation/arrow-left.png';
import Text from '../../../../components/text/Text';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { Snackbar } from '@mui/material';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';

interface AddCardProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AddCard: FC<AddCardProps> = ({
  loading,
  paymentMethodId,
  paymentErrorState,
  saveSelectedPaymentMethod,
  getPaymentAuthKey,
  postEditPayment,
  getPaymentAuthKeyReset,
}) => {
  const classes = useStyles();
  const { propertyId, serviceType, paymentRefId, serviceAccountId } =
    extractParams<AddCardParams>();
  const [cardType, setCardType] = useState<string>('');
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>('');
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);
  const [maskedCardNumber, setMaskedCardNumber] = useState<MaskDetails>({
    paymentDetails: '',
    logo: '',
  });

  useEffect(() => {
    getPaymentAuthKeyReset();
    saveSelectedPaymentMethod({
      paymentMethodId,
      maskedDetails: maskedCardNumber,
    });
  }, [paymentMethodId]);

  const handleSubmit = (paymentDetails: InitialCardValues) => {
    const expiryDate = paymentDetails.expiryDate.split('/');
    const paymentRequest: CreditCardRequest = {
      type: 'CREDITCARD',
      cardHolderName: paymentDetails.cardHolderName,
      cardNumber: paymentDetails.cardNumber,
      email: '',
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    getPaymentAuthKey(paymentRequest, handlePayment);
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment({ data: requestData, onSuccess: () => handlePaymentSuccess() });
  };

  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType('');
      setCardTypeValidation(true);
    } else if (cardType === '' && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation = type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, '')).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(`${cardType ? cardType : ''} ${cardTypeNotSupportedError}`);
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue('');
        break;
    }
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, 'paymentCard'));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  const saveNewCard = (data: InitialCardValues) => {
    handleSubmit(data);
    const maskedCardNumber = maskCardDetails(data.cardNumber.slice(-4), cardType);
    setMaskedCardNumber(maskedCardNumber);
    saveSelectedPaymentMethod({
      paymentMethodId: '1',
      maskedDetails: maskedCardNumber,
    });
  };

  const handlePayment = (payment: GetPaymentMethodsResponse) => {
    switch (serviceType.toLowerCase()) {
      case PaymentServiceType.Rent:
        navigate('rentSummary');
        break;
      case PaymentServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.Wallet:
        navigate('viewPaymentMethods', { serviceType: 'wallet' });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>
            <Formik
              initialValues={initialCardValues}
              isInitialValid={false}
              onSubmit={(data) => saveNewCard(data)}
              validationSchema={Yup.object().shape({
                cardHolderName: Yup.string()
                  .max(cardHolderNameMaxLength, nameMaxLengthError)
                  .matches(cardHolderNameRegex, nameFormatError)
                  .required(requiredFieldError),
                cardNumber: Yup.string().min(19).required(),
                expiryDate: Yup.string().min(5).required(),
                cvv: Yup.string().min(cvvLength).required(),
              })}
              render={({
                values,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isValid,
                touched,
                errors,
              }) => (
                <>
                  <Input
                    placeholder={LABELS.CARD_NAME}
                    value={values.cardHolderName}
                    setValue={(value: string) => {
                      setFieldValue('cardHolderName', value);
                    }}
                    onBlur={() => setFieldTouched('cardHolderName')}
                    touched={touched.cardHolderName}
                    error={
                      errors.cardHolderName ||
                      (paymentErrorState.includes('cardholder name') && paymentErrorState)
                    }
                    title={LABELS.CARD_NAME}
                    mandatory
                    big
                  />
                  <Input
                    placeholder={LABELS.CARD_NUMBER}
                    value={values.cardNumber}
                    setValue={(value: string) => {
                      setFieldValue('cardNumber', value);
                      handleCardNumber(value);
                    }}
                    onBlur={() => setFieldTouched('cardNumber')}
                    touched={touched.cardNumber}
                    error={
                      errors.cardNumber ||
                      cardErrorValue ||
                      (paymentErrorState.includes('card number') && paymentErrorState)
                    }
                    title={LABELS.CARD_NUMBER}
                    mandatory
                    big
                    endElement={
                      <img src={cardError ? errorIcon : renderCardIcon(cardType)} alt="" />
                    }
                  />
                  <div className={classes.twoColumn}>
                    <InputDate
                      placeholder="Expiry (MM/YY)"
                      yearMonthOnly
                      value={values.expiryDate}
                      setValue={(value: string) => {
                        expiryDateValidation(value);
                        setFieldValue('expiryDate', value);
                      }}
                      onBlur={() => setFieldTouched('expiryDate')}
                      touched={touched.expiryDate}
                      error={
                        errors.expiryDate ||
                        (paymentErrorState.includes('expiry date') && paymentErrorState)
                      }
                      title={LABELS.EXPIRY}
                      minDate={new Date()}
                      blockedDates={[]}
                      mandatory
                      big
                    />
                    <Input
                      placeholder={LABELS.CSV}
                      value={values.cvv}
                      setValue={(value: string) => {
                        setFieldValue('cvv', CardHelper.formatCvvNumber(value));
                      }}
                      onBlur={() => setFieldTouched('cvv')}
                      touched={touched.cvv}
                      error={errors.cvv || (paymentErrorState.includes('CVN') && paymentErrorState)}
                      title={LABELS.CSV}
                      mandatory
                      big
                      endElement={<img src={lockIcon} alt="" />}
                    />
                  </div>
                  {!!paymentErrorState && (
                    <div className={classes.error}>
                      Oops! Please double check above for any errors
                    </div>
                  )}
                  <div className={classes.addNewPaymentFooter}>
                    <div className={classes.secureBoxStyle}>
                      <img src={lockIcon} className={classes.secureLockStyle} alt="lock" />
                      <div className={classes.secureText}>{LABELS.SECURE_CONNECTION}</div>
                    </div>
                    <div className={classes.cancelNewOptionSave} onClick={() => navigateBack()}>
                      {LABELS.CANCEL}
                    </div>
                    <div
                      className={classNameGenerator([
                        classes.addNewOptionSave,
                        (cardError || expiryError || loading || !isValid) && classes.disabledButton,
                      ])}
                      onClick={() => {
                        if (!(cardError || expiryError || loading || !isValid)) {
                          handleSubmit();
                        }
                      }}
                    >
                      {loading ? LABELS.LOADING : LABELS.SAVE}
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const error = errorMessageSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);
const loading = loadingSelector([PaymentActionTypes.GET_PAYMENT_AUTH_KEY]);

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethodId: state.payment.selectedPaymentMethod.paymentMethodId,
  paymentErrorState: error(state) as any,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentAuthKeyReset: () => dispatch(PaymentActions.getPaymentAuthKeyReset()),
  saveSelectedPaymentMethod: (data: SaveSelectedPaymentMethodPayload) => {
    dispatch(PaymentActions.saveSelectedPaymentMethod(data));
  },
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  postPaymentUUID: (data: PostPaymentUUIDRequest) => {
    dispatch(PaymentActions.postPaymentMethodUUIDStart(data));
  },
  getPaymentAuthKey: (
    data: CreditCardRequest,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentAuthKeyStart({
        data,
        onSuccess: (token: string) => {
          dispatch(
            PaymentActions.postPaymentTokenStart({
              data: { resultKey: token },
              onSuccess: (payment: GetPaymentMethodsResponse) => {
                handlePayment(payment);
              },
            }),
          );
        },
        onError: (error: Error) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          dispatch(SpinnerActions.hide());
        },
      }),
    );
  },
  postPaymentToken: (
    data: PostPaymentToken,
    handlePayment: (payment: GetPaymentMethodsResponse) => void,
  ) => {
    dispatch(
      PaymentActions.postPaymentTokenStart({
        data,
        onSuccess: (payment: GetPaymentMethodsResponse) => {
          handlePayment(payment);
        },
      }),
    );
  },
  postEditPayment: (data: PostEditPaymentPayload) => {
    dispatch(PaymentActions.postEditPaymentStart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
