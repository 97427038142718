/* istanbul ignore next */

import {
  InvoiceDownloadRequest,
  InvoiceRequest,
  InvoiceResponse,
} from '../../models/invoices/invoices';
import {
  LocationData,
  MaintenanceResponse,
  MaintenanceSummaryRequest,
} from '../../models/maintenance/maintenance';
import { CheckBillData, PostCheckBillDocumentDataRequest } from '../../models/services/checkBill';
import {
  GenericServiceSummaryRequest,
  GenericServiceSummaryResponse,
} from '../../models/services/genericServiceSummary';
import { RentService } from '../../models/services/rentService';
import {
  ActiveServicesPaymentSummaryRequest,
  ActiveServicesPaymentSummaryResponse,
  ResetServicePlanRequest,
} from '../../models/services/services';
import ApiService from '../ApiService';
import { MultipartFormData } from '../MultipartFormData.data';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class SortedService {
  public fetchService(SummaryAccountId: string): Promise<RentService> {
    const endpoint = ServiceType.rentServiceSummary;
    const path: QueryPath = {
      route: [SummaryAccountId],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }

  public downloadDocument(documentId: string): any {
    const endpoint = ServiceType.documentDownload;
    const service: ApiService = new ApiService(endpoint);

    return service.get({
      route: [`${documentId}`],
    });
  }

  public fetchInvoices(requestObject: InvoiceRequest): Promise<InvoiceResponse[]> {
    const endpoint = ServiceType.invoices;
    const service: ApiService = new ApiService(endpoint);

    return service.post({}, requestObject);
  }

  public downloadInvoice(payload: InvoiceDownloadRequest): any {
    const endpoint = ServiceType.downloadInvoice;
    const service: ApiService = new ApiService(endpoint);

    return service.post({}, payload);
  }

  public getServiceSummary(ServiceAccountId: string): Promise<RentService> {
    const endpoint = ServiceType.serviceSummary;
    const path: QueryPath = {
      route: [ServiceAccountId],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }

  public getGenericServiceSummary(
    params: GenericServiceSummaryRequest,
  ): Promise<GenericServiceSummaryResponse> {
    const endpoint = ServiceType.genericServiceSummary;
    const path: QueryPath = {
      route: [`${params.serviceAccountId}`],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }

  public getLocationData(): Promise<LocationData> {
    const endpoint = ServiceType.getMaintenanceLocation;
    const path: QueryPath = {
      route: [],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }

  public getMaintenanceSummary(params: MaintenanceSummaryRequest): Promise<MaintenanceResponse> {
    const endpoint = ServiceType.getMaintenanceSummary;
    const path: QueryPath = {
      route: [`${params.maintenanceRequestId}/job-details`],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }

  public postCheckBill(docData: CheckBillData) {
    const endpoint = ServiceType.checkBill;
    const checkbillStringified: PostCheckBillDocumentDataRequest = {
      file: docData.file,
      values: JSON.stringify({ ...docData.data }),
    };

    const requestObj: MultipartFormData[] = [];
    for (const key in checkbillStringified) {
      if (checkbillStringified.hasOwnProperty(key)) {
        requestObj.push({
          name: key,
          content: checkbillStringified[key],
        });
      }
    }
    const path: QueryPath = {
      route: [],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.postMultipart(path, requestObj);
  }

  public resetServicePlan(payload: ResetServicePlanRequest): Promise<void> {
    const endpoint = ServiceType.serviceSummary;
    const path: QueryPath = {
      route: [payload.serviceAccountId.toString()],
    };
    const service: ApiService = new ApiService(endpoint);
    return service.delete(path);
  }

  public getActiveServicesPaymentSummaryRequest(
    payload: ActiveServicesPaymentSummaryRequest,
  ): Promise<ActiveServicesPaymentSummaryResponse[]> {
    const endpoint = ServiceType.activeServicesPaymentSummary;
    const path: QueryPath = {
      query: {
        serviceAccountIds: payload.serviceAccountIds.join(','),
      },
    };
    const service: ApiService = new ApiService(endpoint);
    return service.get(path);
  }
}

const sortedService = new SortedService();
export default sortedService;
