import {
  DeleteFileRequest,
  MultiFileUploadRequest,
  MultiFileUploadResponse,
} from '../../models/files/files';
import { StoreAction } from '../StoreHelper';

export enum FileUploadActionTypes {
  DELETE_FILE = 'DELETE_FILE',
  DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = 'DELETE_FILE_ERROR',

  POST_MULTI_FILE_UPLOAD = 'POST_MULTI_FILE_UPLOAD',
  POST_MULTI_FILE_UPLOAD_REQUEST = 'POST_MULTI_FILE_UPLOAD_REQUEST',
  POST_MULTI_FILE_UPLOAD_SUCCESS = 'POST_MULTI_FILE_UPLOAD_SUCCESS',
  POST_MULTI_FILE_UPLOAD_ERROR = 'POST_MULTI_FILE_UPLOAD_ERROR',

  RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE',

  SHOW_FILE_LOADING = 'SHOW_FILE_LOADING',
  HIDE_FILE_LOADING = 'HIDE_FILE_LOADING',
}

export type FileUploadActionPayload =
  | DeleteFileRequest
  | MultiFileUploadRequest
  | MultiFileUploadResponse
  | Error
  | null;

export type FileUploadAction = StoreAction<FileUploadActionTypes, FileUploadActionPayload>;

export class FileUploadActions {
  public static deleteFileRequest(data: DeleteFileRequest): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE_REQUEST,
      data,
    };
  }
  public static deleteFileSuccess(): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE_SUCCESS,
      data: null,
    };
  }
  public static deleteFileError(data: Error): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE_ERROR,
      data,
    };
  }

  public static postMultiFileUploadRequest(data: MultiFileUploadRequest): FileUploadAction {
    return { type: FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_REQUEST, data };
  }

  public static postMultiFileUploadSuccess(data: MultiFileUploadResponse): FileUploadAction {
    return { type: FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_SUCCESS, data };
  }

  public static postMultiFileUploadError(data: Error): FileUploadAction {
    return { type: FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_ERROR, data };
  }

  public static resetFileState() {
    return { type: FileUploadActionTypes.RESET_FILE_UPLOAD_STATE };
  }

  public static showFileLoading(): FileUploadAction {
    return { type: FileUploadActionTypes.SHOW_FILE_LOADING, data: null };
  }
  public static hideFileLoading(): FileUploadAction {
    return { type: FileUploadActionTypes.HIDE_FILE_LOADING, data: null };
  }
}
