import { takeEvery, put, call } from 'redux-saga/effects';
import { history } from '../../helpers/HistoryHelper';
import { FetchDashboardRequest, LeaseResponse } from '../../models/dashboard/dashboard';
import { routes } from '../../Routes';
import DashboardService from '../../services/dashboard/DashboardService';
import {
  DashboardAction,
  DashboardActions,
  DashboardActionTypes,
} from '../actions/DashboardActions';
import { SpinnerActions } from '../actions/SpinnerActions';
import { OffersRequest, OffersResponse } from '../../models/offers/Offers';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { navigate } from '../../navigation/NavigationUtils';

function* fetchCompletedRequest(action: DashboardAction) {
  try {
    if (action.data === undefined || !(action.data as FetchDashboardRequest).skipReset) {
      history.replace(routes.home);
    }
    const response: LeaseResponse[] = yield DashboardService.fetchProperties(true);
    yield put(DashboardActions.fetchCompletedSuccess(response));

    // response.map((onboardedProperty) => {
    //   if (onboardedProperty.services && !!onboardedProperty.services.length) {
    //     onboardedProperty.services.map((service) => {
    //       if (graphApiValidStates.includes(service.status) && showCharts(service)) {
    //         store.dispatch(
    //           UsageGraphActions.getUsageGraphDataRequest({
    //             serviceNumber: service.serviceAccountId,
    //             options: {
    //               serviceType: service.serviceType,
    //               metric: Metric.USAGE,
    //               aggregate: Aggregate.MONTH,
    //             },
    //           }),
    //         );
    //       }
    //     });
    //   }
    //   if (onboardedProperty.property) {
    //     store.dispatch(
    //       CheckoutActions.getCartStart({ propertyId: onboardedProperty.property.id.toString() }),
    //     );
    //   }
    // });

    yield put(DashboardActions.noneOnboarded(response.length === 0));
    if (response.length === 0) {
      navigate('browse');
    }
    yield put(DashboardActions.fetchPendingRequest());
  } catch (error) {
    yield put(DashboardActions.fetchCompletedError(error as Error));
    console.log('error', error);
  }
}

function* refreshRequest() {
  try {
    const response: LeaseResponse[] = yield DashboardService.fetchProperties(false);
    yield put(DashboardActions.refreshSuccess(response));
    if (response.length > 0) {
      yield put(DashboardActions.switchCta(true));
    } else {
      yield put(DashboardActions.switchCta(false));
    }
  } catch (error) {
    yield put(DashboardActions.refreshError(error as Error));
  }
}

function* fetchPendingRequest() {
  try {
    const response: LeaseResponse[] = yield DashboardService.fetchProperties(false);
    yield put(DashboardActions.fetchPendingSuccess(response));
    if (response.length > 0) {
      yield put(DashboardActions.switchCta(true));
    } else {
      yield put(DashboardActions.switchCta(false));
    }
  } catch (error) {
    yield put(DashboardActions.fetchPendingError(error as Error));
  }
}

export function* getPropertyMaintenanceSaga(action: DashboardAction) {
  try {
    const propertyId = action.data as string;
    const response: MaintenanceResponse[] = yield DashboardService.getPropertyMaintenance(
      propertyId,
    );
    yield put(DashboardActions.getPropertyMaintenanceSuccess(response));
  } catch (error) {
    yield put(DashboardActions.getPropertyMaintenanceError(error as Error));
  }
}

export function* getOffers(action: DashboardAction) {
  try {
    const response: OffersResponse[] = yield DashboardService.getOffers(
      action.data as OffersRequest,
    );
    yield put(DashboardActions.getOffersSuccess(response));
  } catch (error) {
    yield put(DashboardActions.getOffersError(error as Error));
  }
}

function* dashboardWatcher() {
  yield takeEvery(DashboardActionTypes.FETCH_COMPLETED_REQUEST, (action: DashboardAction) =>
    fetchCompletedRequest(action),
  );
  yield takeEvery(DashboardActionTypes.REFRESH_REQUEST, () => refreshRequest());
  yield takeEvery(DashboardActionTypes.FETCH_PENDING_REQUEST, () => fetchPendingRequest());
  yield takeEvery(DashboardActionTypes.GET_PROPERTY_MAINTENANCE_REQUEST, (action) =>
    getPropertyMaintenanceSaga(action as DashboardAction),
  );
  yield takeEvery(DashboardActionTypes.GET_OFFERS_REQUEST, (action) =>
    getOffers(action as DashboardAction),
  );
}

export default dashboardWatcher;
