import BANK_ACCOUNT_LOGO from '../../../../assets/payment/account-balance-24-px.svg';
import MASTER_CARD_LOGO from '../../../../assets/payment/mc-symbol.svg';
import VISA_CARD_LOGO from '../../../../assets/payment/visa-credit-card.svg';

export const LABELS = {
  PAYMENT: 'Please select a payment method from below',
  PAYMENT_METHODS: 'Payment methods',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SECURE_CONNECTION: 'Secure',
  PLEASE_INPUT_DETAILS: 'Please fill out the following details below',
  PAY: 'Pay',
  PROCESSING: 'Processing',
  TITLE: 'Total to pay today',
  PAID: 'Total paid',
  SUCCESS: 'Payment success',
  CAPTURED: 'Payment method saved',
  SUBTITLE:
    "This service requires an upfront payment that includes your first month's bill plus any extra addons",
  CARD_NAME: 'Name on card',
  CARD_NUMBER: 'Card number',
  EXPIRY: 'Expiry',
  CSV: 'CSV',
  ACCOUNT_NAME: 'Account name',
  BSB: 'BSB',
  ACCOUNT_NUMBER: 'Account number',
  LOADING: 'Processing...',
};

export enum PaymentType {
  Bank = 'Bank',
  Credit = 'Credit',
}

export const deletePaymentSuccessMessage = 'Payment type deleted';

export interface PaymentMethod {
  name: string;
  cardType?: string;
  logo: any;
}
export interface PaymentMethods {
  Bank: PaymentMethod;
  Credit: PaymentMethod[];
}
export const paymentMethod: PaymentMethods = {
  Bank: {
    name: 'Bank Account',
    logo: BANK_ACCOUNT_LOGO,
  },
  Credit: [
    {
      name: 'Credit Card',
      cardType: 'Mastercard',
      logo: MASTER_CARD_LOGO,
    },
    {
      name: 'Credit Card',
      cardType: 'Visa',
      logo: VISA_CARD_LOGO,
    },
  ],
};
