import LogRocket from 'logrocket';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export interface IntercomData {
  email: string;
  name: string;
  surname: string;
  intercomId: string;
}

export class IntercomChat {
  public static registerUser(data: IntercomData) {
    (window as any).Intercom('update', {
      email: data.email,
      user_id: data.intercomId,
      name: `${data.name} ${data.surname}`,
    });
  }

  public static log(tag: string, intercomId: string) {
    (window as any).Intercom('trackEvent', tag, { user_id: intercomId });
  }

  public static logIntercomEvent(serviceName: string, event: any) {
    LogRocket.getSessionURL((url: string) => {
      (window as any).Intercom.logEvent(serviceName, { ...event, logrocketURL: url });
    });
  }
}
