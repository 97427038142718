export enum MR_QUOTE_STATUS {
  ACCEPT = 'approved',
  REJECT = 'rejected',
}

export enum Status {
  REQUESTED = 'REQUESTED',
  REVIEW = 'REVIEW',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
}

export interface TenantRequest {
  propertyId: number;
  status: Status;
}

export interface CancelRequest {
  maintenanceId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface RequestQuote {
  quoteId: string;
  status: MR_QUOTE_STATUS;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ReviewRequest {
  officeId: number;
  user: number;
  rating: number;
  review: string;
  jobId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ReviewResponse {
  review: string;
  rating: number;
}
