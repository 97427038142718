import { routes } from '../../Routes';
import { Route } from '../../navigation/NavigationUtils';

export const LABELS = {
  YOU: 'You',
  LOGIN: 'Log in',
};

export interface LinkItem {
  name: string;
  route: string;
}

export const LINKS = [
  // {
  //   name: 'Services',
  //   route: '/services',
  // },
  {
    name: 'About',
    route: '/about',
  },
  {
    name: 'Blog',
    route: '/blog',
  },
];

export const hideRightLinkUrls: Route[] = [
  'profileSelection',
  'cimet',
  'download',
  'rentWelcome',
  'rentConfirmation',
  'rentSummary',
  'leaseServiceability',
  'selectPayment',
  'addPayment',
  'addCard',
  'addBank',
];
export const hideAddressBarUrls: Route[] = [
  'checkoutSelect',
  'checkoutConfigure',
  'checkoutPersonalDetails',
  'checkoutFinalize',
  'checkoutSummary',
  'checkoutCompleteCart',
];

export const cartUrls: Route[] = ['checkoutSelect', 'checkoutConfigure', 'checkoutPersonalDetails'];
