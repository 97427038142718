import { FileData } from './FileUploadState';

export interface ProfileQuestion {
  readonly type: QuestionType;
  readonly question: string;
  answer: string | null;
}

export enum QuestionType {
  BUTTON = 'BUTTON',
  TEXT = 'TEXT',
}

export interface PostPersonalDetailsFormData {
  key?: number | undefined;
  firstName: string | null | undefined;
  middleName: string | null | undefined;
  title: string | null | undefined;
  email: string | null | undefined;
  lastName: string | null | undefined;
  mobile: string;
  selectedDateOfBirth: Date | null | undefined;
  hasGuarantor: boolean | null;
  guarantorName: string | null | undefined;
  guarantorContactNumber: string | null | undefined;
  guarantorEmail: string | null | undefined;
  isManaged?: boolean | null | undefined;
  questions: ProfileQuestion[] | null | undefined;
  introText: string | null | undefined;
  emergencyContactName: string;
  emergencyContactEmail: string;
  emergencyContactMobile: string;
  emergencyContactRelationship: string;
}

export interface CurrentIncome {
  source: string | null;
  netWeeklyAmount: string | null;
  savingsAmount: string | null;
  makeGuarantor: boolean | null;
  guarantorName: string | null;
  guarantorContactNumber: string | null;
  guarantorEmail: string | null;
  payslipData: FileData[];
  proofOfBenefitData: FileData[];
  proofOfIncomeData: FileData[];
  bankStatementData: FileData[];
  centrePayReference: string | null;
}

export interface PostCurrentIncomeFormData {
  incomes: CurrentIncome[];
}

export interface Employment {
  applyReferenceId?: string | null;
  jobStartMonthAndYear: any;
  jobEndMonthAndYear: any;
  inJob: boolean | null;
  companyName: string | null;
  position: string | null;
  grossAnnualSalary: string | null;
  managerName: string | null;
  managerContactNumber: string | null;
  email: string | null;
  copyOfEmploymentLetter: any;
  employmentLetterFileName: string | null;
  isDisabled?: boolean;
  submissionStatus?: string | null;
  mailSent?: boolean;
  address: string | null;
  addressComponents: ManualAddressData | null;
  rank: number | null;
}

export interface PostEmploymentFormData {
  hasBeenEmployed?: boolean | null;
  employments: Employment[];
}

export interface ManualAddressData {
  unitNumber?: string | null;
  streetNumber: string;
  streetName?: string | null;
  suburb: string;
  state: string;
  postcode: string;
  country?: string | null;
  streetType?: string | null;
}
export interface Address {
  applyReferenceId?: string | null;
  livingArrangement: string | null;
  address: string | null;
  addressComponents: ManualAddressData | null;
  agencyName: string | null;
  propertyManagerName: string | null;
  contactNumber: string;
  monthlyRent: string | null;
  durationOfStayYear: number | undefined | null;
  durationOfStayMonth: number | undefined | null;
  reasonForLeaving: string | null;
  postalAddressDifferentFromCurrentAddress: boolean | null;
  postalAddress: string | null;
  isCurrent: boolean | null;
  email: string | null;
  isDisabled?: boolean;
  submissionStatus?: string | null;
  mailSent?: boolean;
  additionalAddressDocuments: FileData[];
  rank: number | null;
}

export interface PostAddressFormData {
  addresses: Address[];
}

export interface PostIntroductionFormData {
  introText: string | undefined | null;
}

export interface Reference {
  applyReferenceId?: string | null;
  name: string | null;
  relationship: string | null;
  mobile: string | null;
  email: string | null;
  isDisabled?: boolean;
  submissionStatus?: string | null;
  mailSent?: boolean;
  rank: number | null;
}

export interface PostReferencesFormData {
  references: Reference[];
  addReference?: boolean | null;
}

export enum LivingArrangement {
  OWNER = "I'm the owner",
  RENTING_VIA_AGENT = 'Renting through an agent',
  RENTING_VIA_PRIVATE_LANDLORD = 'Renting through a private landlord',
  PARENTS = 'With parents',
  SHARING = 'Sharing',
  OTHER = 'Other',
}

export enum SourceOfIncome {
  I_AM_WORKING_FULL_TIME = 'I am working full time',
  I_AM_WORKING_PART_TIME = 'I am working part time',
  I_HAVE_CASUAL_WORK = 'I have casual work',
  I_RECEIVE_A_BENEFIT = 'I receive a benefit',
  I_RECEIVE_MONEY_FROM_MY_FAMILY = 'I receive money from my family',
  I_RECEIVE_SAVINGS = 'I have savings',
  SELF_EMPLOYED = 'Self Employed',
  OTHER = 'Other',
}

export const hasIncomeArray = [
  SourceOfIncome.I_AM_WORKING_FULL_TIME,
  SourceOfIncome.I_AM_WORKING_PART_TIME,
  SourceOfIncome.I_HAVE_CASUAL_WORK,
  SourceOfIncome.SELF_EMPLOYED,
];

export enum Relationship {
  FRIEND = 'Friend',
  COLLEAGUE = 'Colleague',
  NON_IMMEDIATE_RELATIVE = 'Non Immediate Relative',
  OTHER = 'Other',
}
export enum EmergencyContactRelationship {
  FAMILY = 'Family',
  FRIEND = 'Friend',
  COLLEAGUE = 'Colleague',
  RELATIVE = 'Relative',
  PARTNER = 'Partner',
  OTHER = 'Other',
}
export enum IdentificationTypes {
  DRIVING_LICENSE = 'Australian Drivers Licence',
  PASSPORT = 'Passport',
  BIRTH_CERTIFICATE = 'Birth Certificate',
  MEDICARE = 'Medicare',
  OTHER = 'Other',
}

export const StateOfIssueDL = [
  { code: 'NSW', value: 'New South Wales' },
  { code: 'QLD', value: 'Queensland' },
  { code: 'SA', value: 'South Australia' },
  { code: 'TAS', value: 'Tasmania' },
  { code: 'VIC', value: 'Victoria' },
  { code: 'WA', value: 'Western Australia' },
  { code: 'ACT', value: 'Australian Capital Territory' },
  { code: 'NT', value: 'Northen Territory' },
];

export enum MedicareColours {
  BLUE = 'Blue',
  YELLOW = 'Yellow',
  GREEN = 'Green',
}

export enum PetTypes {
  Dog = 'Dog',
  Cat = 'Cat',
  Other = 'Other',
}
export enum Desexed {
  isDesexed = 'This pet has been desexed',
  isNotDesexed = 'This pet has not been desexed',
}
export enum PetSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum VehicleTypes {
  Car = 'CAR',
  Motorcycle = 'MOTORCYCLE',
}

export interface Identification {
  type: IdentificationTypes | undefined | null | string;
  identificationLink: string | null;
  fileName?: string | null;
  idNumber?: string | null;
  extendedData?: {
    stateOfIssueDL?: null | string;
    countryOfOrigin?: string | null;
    cardNumber?: string | null;
    medicareColour?: MedicareColours | null | string;
    expiryDate?: Date | string | null;
  };
  stateOfIssueDL?: null | string;
  licenseNumber?: string | null;
  countryOfOrigin?: string | null;
  passportNumber?: string | null;
  cardNumber?: string | null;
  referenceNumber?: string | null;
  medicareColour?: MedicareColours | null | string;
  expiryDate?: Date | string | null;
}

export interface PostIdentificationFormData {
  identification: Identification[];
  IDPoints?: number | null;
  ntdPurchased: boolean | null;
  ntdActive: boolean | null;
  ntdDate: string | null;
}

export interface Vehicles {
  type: string | null;
  year: number | null;
  make: string | null;
  model: string | null;
  state: string | null;
  identificationLink: string | null;
  registrationNumber: string | null;
}

export interface PostVehiclesFormData {
  hasVehicles?: boolean | null;
  vehicles: Vehicles[];
}

export interface Pets {
  type: string | null;
  specifyOtherType: string | null;
  breed: string | null;
  desexed: boolean | null;
  size: string | null;
  additionalDocuments: FileData[];
  registrationNumber: string | null;
}
export interface PostPetsFormData {
  hasPets?: boolean | null;
  pets: Pets[];
}

export type DigitalSignature = string | undefined;
export interface PostDeclarationFormData {
  isSortedTermsAccepted: boolean | undefined | null;
  digitalSignature: DigitalSignature;
}

export interface IntroFormData {
  introText: string | undefined | null;
}

export type PostFormData =
  | PostPersonalDetailsFormData
  | PostReferencesFormData
  | PostIdentificationFormData
  | PostCurrentIncomeFormData
  | PostEmploymentFormData
  | PostAddressFormData
  | PostPetsFormData
  | PostVehiclesFormData
  | PostDeclarationFormData
  | IntroFormData
  | string
  | null
  | boolean;

export class ApplicantProfile {
  public refId?: string | null;
  public id: number | null | undefined;
  public personalDetails: PostPersonalDetailsFormData | undefined;
  public referencesDetails: PostReferencesFormData | undefined;
  public identificationDetails: PostIdentificationFormData | undefined;
  public currentIncomeDetails: PostCurrentIncomeFormData | undefined;
  public employmentDetails: PostEmploymentFormData | undefined;
  public addressDetails: PostAddressFormData | undefined;
  public petsDetails: PostPetsFormData | undefined;
  public vehiclesDetails: PostVehiclesFormData | undefined;
  public declarationDetails: PostDeclarationFormData | undefined;
  public introDetails: IntroFormData | undefined;
  public myAccountFlag: boolean;
}

export interface ReferenceState {
  referencesDetails: PostReferencesFormData | undefined;
  employmentDetails: PostEmploymentFormData | undefined;
  addressDetails: PostAddressFormData | undefined;
}
