import {
  User,
  ProfileRequest,
  Roles,
  ProfileType,
  UpdateProfile,
  Profile,
} from '../../models/user/user';
import { StoreAction } from '../StoreHelper';

export enum ProfileActionTypes {
  GET_PROFILE = 'GET_PROFILE',
  GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',

  UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR',

  GET_USER_ROLES = 'GET_USER_ROLES',
  GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR',

  SWITCH_PROFILE_TYPE = 'SWITCH_PROFILE_TYPE',
}

export type ProfileActionPayload =
  | ProfileRequest
  | UpdateProfile
  | Profile
  | ProfileType
  | User
  | Roles
  | Error;

export type ProfileAction = StoreAction<ProfileActionTypes, ProfileActionPayload>;

export class ProfileActions {
  public static switchProfileType(data: ProfileType) {
    return { type: ProfileActionTypes.SWITCH_PROFILE_TYPE, data };
  }

  public static getProfileRequest(data: ProfileRequest): ProfileAction {
    return {
      type: ProfileActionTypes.GET_PROFILE_REQUEST,
      data,
    };
  }

  public static getProfileSuccess(data: Profile): ProfileAction {
    return {
      type: ProfileActionTypes.GET_PROFILE_SUCCESS,
      data,
    };
  }

  public static getProfileError(data: Error): ProfileAction {
    return {
      type: ProfileActionTypes.GET_PROFILE_ERROR,
      data,
    };
  }

  public static getUserRolesRequest(): ProfileAction {
    return {
      type: ProfileActionTypes.GET_USER_ROLES_REQUEST,
      data: null,
    };
  }

  public static getUserRolesSuccess(data: Roles): ProfileAction {
    return {
      type: ProfileActionTypes.GET_USER_ROLES_SUCCESS,
      data,
    };
  }

  public static getUserRolesError(data: Error): ProfileAction {
    return {
      type: ProfileActionTypes.GET_USER_ROLES_ERROR,
      data,
    };
  }

  public static updateProfileRequest(data: UpdateProfile): ProfileAction {
    return {
      type: ProfileActionTypes.UPDATE_PROFILE_REQUEST,
      data,
    };
  }

  // TODO test the typing of this response
  public static updateProfileSuccess(data: Profile): ProfileAction {
    return {
      type: ProfileActionTypes.UPDATE_PROFILE_SUCCESS,
      data,
    };
  }

  public static updateProfileError(data: Error): ProfileAction {
    return {
      type: ProfileActionTypes.UPDATE_PROFILE_ERROR,
      data,
    };
  }
}
