import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    imageBannerContainer: {
      height: 200,
      position: 'relative',
      borderRadius: theme.units.borderRadius,
      marginBottom: theme.units.spacing * 12,
    },
    banner: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.borderColorLight,
      borderRadius: theme.units.borderRadius,
    },
    contentContainer: {
      position: 'relative',
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    icon: {
      position: 'absolute',
      bottom: -40,
      right: 16,
      width: 80,
      height: 80,
      resizeMode: 'contain',
      borderRadius: 5000,
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    offer: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.buttonPrimary,
      marginBottom: 6,
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 32,
      color: theme.colors.black87,
      marginBottom: 16,
    },
    subtitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
      marginBottom: 16,
    },
    description: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      lineHeight: 1.5,
      color: theme.colors.black60,
      marginBottom: 24,
      whiteSpace: 'pre-wrap',
    },
    stepsTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
      marginBottom: 16,
    },
    step: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.black60,
      marginBottom: 12,
    },
    links: {
      marginTop: 24,
      marginBottom: 24,
    },
    link: {
      marginBottom: 12,
      paddingBottom: 12,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    linkText: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.buttonPrimary,
    },
    linkIcon: {
      width: 16,
      height: 16,
      resizeMode: 'contain',
      tintColor: theme.colors.buttonPrimary,
    },
    actionContainer: {
      display: 'flex',
    },
    actionButton: {
      flex: 1,
    },
  })();
};
