import { AccountStatus } from '../../../../constants/AccountStatus';
import { findExtraServiceInfo } from '../../../../helpers/ServiceInfoHelper';
import { CimetPurchasedServices } from '../../../../models/cimet/Cimet';
import { LeaseResponse } from '../../../../models/dashboard/dashboard';
import { MaintenanceResponse } from '../../../../models/landlord/LandlordProperty';
import {
  AvailableServicesResponse,
  ServiceTypes,
  Services,
} from '../../../../models/services/services';
import { getURL } from '../../../suppliersScreen/SuppliersScreenUtils';
import { TASK } from './DashboardConstants';

export const getStatus = (maintenance: MaintenanceResponse) => {
  const progressStatus =
    maintenance.status === AccountStatus.IN_PROGRESS
      ? 'In Progress'
      : maintenance.status === AccountStatus.LANDLORD
      ? 'Landlord Attending'
      : maintenance.status === AccountStatus.CLOSE
      ? 'Closed'
      : maintenance.status === AccountStatus.RECIEVED
      ? 'Submitted'
      : maintenance.status === AccountStatus.COMPLETE
      ? 'Complete'
      : maintenance.status.toUpperCase();

  return progressStatus;
};

export const filterServicesByStatus = (
  status: AccountStatus[],
  services: Services[],
  serviceTypes?: string[],
) => {
  if (serviceTypes) {
    return services.filter(
      (data) =>
        status.includes(data.status) && serviceTypes.includes(data.serviceType.replace(' ', '')),
    );
  } else {
    return services.filter((data) => status.includes(data.status));
  }
};

export const filterServiceByStatus = (status: AccountStatus[], service: Services): boolean => {
  return status.includes(service.status);
};

export const filterSortedElecService = (services: Services[]) => {
  return services.find(
    (service) =>
      service.serviceType.toUpperCase() === 'ELECTRICITY' &&
      service.providerId !== null &&
      service.providerId.toUpperCase() === 'BILLCAP',
  );
};

export const getTaskURL = (
  service: Services,
  availableServices: AvailableServicesResponse[],
  propertyId: number,
  onboardedProperty: LeaseResponse,
): string => {
  return getURL(
    service,
    findExtraServiceInfo(service, availableServices).Suppliers,
    findExtraServiceInfo(service, availableServices).Suppliers.findIndex(
      (supplier) => supplier.SupplierName === 'Sorted Services',
    ),
    propertyId,
    onboardedProperty,
  );
};

export const getFocusedTask = (
  cartForgot: boolean,
  cartReminder: boolean,
  noServices: boolean,
  billCheckAvailable: boolean,
  electricityAvailable: boolean,
  gasAvailable: boolean,
  broadbandAvailable: boolean,
  cimetServices: ServiceTypes[],
) => {
  if (!!cimetServices.length) {
    const service = cimetServices[0];
    if (service === ServiceTypes.Electricity) return TASK.ELECTRICITY;
    if (service === ServiceTypes.Gas) return TASK.GAS;
    if (service === ServiceTypes.Broadband) return TASK.BROADBAND;
  }
  if (electricityAvailable) return TASK.ELECTRICITY;
  if (gasAvailable) return TASK.GAS;
  if (broadbandAvailable) return TASK.BROADBAND;
  return TASK.MAINTENANCE;
};

export const removeDuplicatedCimetServices = (
  activeServices: Services[],
  cimetServices: ServiceTypes[],
): ServiceTypes[] => {
  const activeServiceTypes = activeServices.map((data) => data.serviceType);
  return cimetServices.filter((service) => !activeServiceTypes.includes(service));
};

export const filterCimetPurchasedServices = (
  status: AccountStatus[],
  cimetServices: CimetPurchasedServices[],
) => {
  return cimetServices.filter(
    (service) => service.saleStatus && status.includes(service.saleStatus),
  );
};
