import * as Yup from 'yup';

export const LABELS = {
  ACCOUNT: 'Account',
  PERSONAL_DETAILS: 'Personal details',
  HIDE_PERSONAL: 'Hide personal information',
  SHOW_PERSONAL: 'Show personal information',
  EMAIL: 'Email address',
  TITLE: 'Title',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  MOBILE: 'Mobile number',
  DOB: 'Date of birth',
  ID_EXPIRY: 'Id Expiry',
  ID_TYPE: 'ID Type',
  STATE_ISSUE: 'State of Issue',
  MEDICARE_CARD_COLOR: 'Medicare Card Colour',
  COUNTRY_OF_ISSUE: 'Country Of Issue',
  REFERENCE_NUMBER: 'Reference Number',
  ID_NUMBER: 'ID Number',
};

export const TITLES = [
  { name: 'Mr.', value: 'Mr.' },
  { name: 'Ms.', value: 'Ms.' },
  { name: 'Mrs.', value: 'Mrs.' },
  { name: 'Dr.', value: 'Dr.' },
];

export const ID_TYPES = [
  { name: 'Drivers Licence', value: 'DriversLicence' },
  { name: 'Medicare', value: 'Medicare' },
  { name: 'Passport', value: 'Passport' },
];

export const STATE_OF_ISSUE = [
  { name: 'ACT', value: 'ACT' },
  { name: 'VIC', value: 'VIC' },
  { name: 'NSW', value: 'NSW' },
  { name: 'QLD', value: 'QLD' },
  { name: 'SA', value: 'SA' },
  { name: 'TAS', value: 'TAS' },
  { name: 'NT', value: 'NT' },
  { name: 'WA', value: 'WA' },
  { name: 'Other', value: 'Other' },
];

export const MEDICARE_CARD_COLOR = [
  { name: 'green', value: 'green' },
  { name: 'blue', value: 'blue' },
  { name: 'yellow', value: 'yellow' },
];

export const REFERENCE_NUMBER = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
];

const EMAIL_REGEX = /\S+@\S+\.\S+/;
const ID_REGEX = /^[0-9\b]+$/;
const ID_REGEX2 = /^\S+$/;
export const MOBILE_NUMBER_REGEX =
  /^$|^(04|614|\+614)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const DATE_FORMAT_PICKER = 'dd/MM/yyyy';
export const MINIMUM_AGE = 18;

const LIMITS = {
  MaxEmail: 40,
  MaxFirstName: 20,
  MaxLastName: 20,
  MaxMobile: 10,
  MinIdNumberLengthPassport: 7,
  MaxIdNumberLengthPassport: 15,
  MinIdNumberLength: 8,
  MinIdNumberLengthDL: 5,
  MaxIdNumberLength: 12,
};

export const ERRORS = {
  Required: 'This field is mandatory',
  InvalidEmailFormat: 'Invalid email format',
  InvalidName: 'Invalid name',
  InvalidMobileNumber: 'Wrong mobile number format',
  MaxLimitMessage: (limit: number) => `Should not be more than ${limit} characters`,
  InvalidNumber: 'Should only contain numbers',
  InvalidAlphaNumeric: 'Should contain alphanumeric characters',
  MinLimitMessage: (limit: number) => `Should  be atleast ${limit} characters`,
};

export const validationSchemaNoIdExpiry = Yup.object().shape({
  email: Yup.string()
    .required(ERRORS.Required)
    .matches(EMAIL_REGEX, ERRORS.InvalidEmailFormat)
    .max(LIMITS.MaxEmail, ERRORS.MaxLimitMessage(LIMITS.MaxEmail)),
  title: Yup.string().required(ERRORS.Required),
  firstName: Yup.string().required(ERRORS.Required),
  lastName: Yup.string().required(ERRORS.Required),
  mobile: Yup.string().required(ERRORS.Required),
  dob: Yup.string().nullable(true).required(ERRORS.Required),
  idType: Yup.string().required(ERRORS.Required),
  idNumber: Yup.string()
    .required(ERRORS.Required)
    .matches(ID_REGEX)
    .min(LIMITS.MinIdNumberLength, ERRORS.MinLimitMessage(LIMITS.MinIdNumberLength))
    .max(LIMITS.MaxIdNumberLength, ERRORS.MaxLimitMessage(LIMITS.MaxIdNumberLength)),
});

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(ERRORS.Required)
    .matches(EMAIL_REGEX, ERRORS.InvalidEmailFormat)
    .max(LIMITS.MaxEmail, ERRORS.MaxLimitMessage(LIMITS.MaxEmail)),
  title: Yup.string().required(ERRORS.Required),
  firstName: Yup.string().required(ERRORS.Required),
  idType: Yup.string().required(ERRORS.Required),
  lastName: Yup.string().required(ERRORS.Required),
  stateIssue: Yup.string().when('idType', {
    is: (value: string) => {
      return value === 'DriversLicence';
    },
    then: Yup.string().required(ERRORS.Required).nullable(true),
    otherwise: Yup.string().notRequired().nullable(true),
  }),
  medicareCardColor: Yup.string().when('idType', {
    is: (value: string) => {
      return value === 'Medicare';
    },
    then: Yup.string().required(ERRORS.Required).nullable(true),
    otherwise: Yup.string().notRequired().nullable(true),
  }),
  countryOfIssue: Yup.string().when('idType', {
    is: (value: string) => {
      return value === 'Passport';
    },
    then: Yup.string().required(ERRORS.Required).nullable(true),
    otherwise: Yup.string().notRequired().nullable(true),
  }),
  referenceNumber: Yup.string().when('idType', {
    is: (value: string) => {
      return value === 'Medicare';
    },
    then: Yup.string().required(ERRORS.Required).nullable(true),
    otherwise: Yup.string().notRequired().nullable(true),
  }),
  mobile: Yup.string().required(ERRORS.Required),
  dob: Yup.string().nullable(true).required(ERRORS.Required),
  idExpiry: Yup.string().nullable(true).required(ERRORS.Required),
  idNumber: Yup.string().when('idType', (value: string) => {
    if (value === 'Passport') {
      return Yup.string()
        .required(ERRORS.Required)
        .matches(ID_REGEX2, ERRORS.InvalidAlphaNumeric)
        .min(
          LIMITS.MinIdNumberLengthPassport,
          ERRORS.MinLimitMessage(LIMITS.MinIdNumberLengthPassport),
        )
        .max(
          LIMITS.MaxIdNumberLengthPassport,
          ERRORS.MaxLimitMessage(LIMITS.MaxIdNumberLengthPassport),
        );
    } else if (value === 'Medicare') {
      return Yup.string()
        .required(ERRORS.Required)
        .matches(ID_REGEX, ERRORS.InvalidNumber)
        .min(LIMITS.MinIdNumberLength, ERRORS.MinLimitMessage(LIMITS.MinIdNumberLength))
        .max(LIMITS.MaxIdNumberLength, ERRORS.MaxLimitMessage(LIMITS.MaxIdNumberLength));
    } else if (value === 'DriversLicence') {
      return Yup.string()
        .required(ERRORS.Required)
        .matches(ID_REGEX, ERRORS.InvalidNumber)
        .min(LIMITS.MinIdNumberLengthDL, ERRORS.MinLimitMessage(LIMITS.MinIdNumberLengthDL))
        .max(LIMITS.MaxIdNumberLength, ERRORS.MaxLimitMessage(LIMITS.MaxIdNumberLength));
    } else {
      return Yup.string().notRequired().nullable(true);
    }
  }),
});

export const COUNTRY_CODES = [
  {
    name: 'Afghanistan',
    value: 'AFG',
  },
  {
    name: 'Åland Islands',
    value: 'ALA',
  },
  {
    name: 'Albania',
    value: 'ALB',
  },
  {
    name: 'Algeria',
    value: 'DZA',
  },
  {
    name: 'American Samoa',
    value: 'ASM',
  },
  {
    name: 'Andorra',
    value: 'AND',
  },
  {
    name: 'Angola',
    value: 'AGO',
  },
  {
    name: 'Anguilla',
    value: 'AIA',
  },
  {
    name: 'Antarctica',
    value: 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    value: 'ATG',
  },
  {
    name: 'Argentina',
    value: 'ARG',
  },
  {
    name: 'Armenia',
    value: 'ARM',
  },
  {
    name: 'Aruba',
    value: 'ABW',
  },
  {
    name: 'Australia',
    value: 'AUS',
  },
  {
    name: 'Austria',
    value: 'AUT',
  },
  {
    name: 'Azerbaijan',
    value: 'AZE',
  },
  {
    name: 'Bahamas',
    value: 'BHS',
  },
  {
    name: 'Bahrain',
    value: 'BHR',
  },
  {
    name: 'Bangladesh',
    value: 'BGD',
  },
  {
    name: 'Barbados',
    value: 'BRB',
  },
  {
    name: 'Belarus',
    value: 'BLR',
  },
  {
    name: 'Belgium',
    value: 'BEL',
  },
  {
    name: 'Belize',
    value: 'BLZ',
  },
  {
    name: 'Benin',
    value: 'BEN',
  },
  {
    name: 'Bermuda',
    value: 'BMU',
  },
  {
    name: 'Bhutan',
    value: 'BTN',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    value: 'BOL',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BIH',
  },
  {
    name: 'Botswana',
    value: 'BWA',
  },
  {
    name: 'Bouvet Island',
    value: 'BVT',
  },
  {
    name: 'Brazil',
    value: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IOT',
  },
  {
    name: 'United States Minor Outlying Islands',
    value: 'UMI',
  },
  {
    name: 'Virgin Islands (British)',
    value: 'VGB',
  },
  {
    name: 'Virgin Islands (U.S.)',
    value: 'VIR',
  },
  {
    name: 'Brunei Darussalam',
    value: 'BRN',
  },
  {
    name: 'Bulgaria',
    value: 'BGR',
  },
  {
    name: 'Burkina Faso',
    value: 'BFA',
  },
  {
    name: 'Burundi',
    value: 'BDI',
  },
  {
    name: 'Cambodia',
    value: 'KHM',
  },
  {
    name: 'Cameroon',
    value: 'CMR',
  },
  {
    name: 'Canada',
    value: 'CAN',
  },
  {
    name: 'Cabo Verde',
    value: 'CPV',
  },
  {
    name: 'Cayman Islands',
    value: 'CYM',
  },
  {
    name: 'Central African Republic',
    value: 'CAF',
  },
  {
    name: 'Chad',
    value: 'TCD',
  },
  {
    name: 'Chile',
    value: 'CHL',
  },
  {
    name: 'China',
    value: 'CHN',
  },
  {
    name: 'Christmas Island',
    value: 'CXR',
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: 'CCK',
  },
  {
    name: 'Colombia',
    value: 'COL',
  },
  {
    name: 'Comoros',
    value: 'COM',
  },
  {
    name: 'Congo',
    value: 'COG',
  },
  {
    name: 'Congo (Democratic Republic of the)',
    value: 'COD',
  },
  {
    name: 'Cook Islands',
    value: 'COK',
  },
  {
    name: 'Costa Rica',
    value: 'CRI',
  },
  {
    name: 'Croatia',
    value: 'HRV',
  },
  {
    name: 'Cuba',
    value: 'CUB',
  },
  {
    name: 'Curaçao',
    value: 'CUW',
  },
  {
    name: 'Cyprus',
    value: 'CYP',
  },
  {
    name: 'Czech Republic',
    value: 'CZE',
  },
  {
    name: 'Denmark',
    value: 'DNK',
  },
  {
    name: 'Djibouti',
    value: 'DJI',
  },
  {
    name: 'Dominica',
    value: 'DMA',
  },
  {
    name: 'Dominican Republic',
    value: 'DOM',
  },
  {
    name: 'Ecuador',
    value: 'ECU',
  },
  {
    name: 'Egypt',
    value: 'EGY',
  },
  {
    name: 'El Salvador',
    value: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    value: 'GNQ',
  },
  {
    name: 'Eritrea',
    value: 'ERI',
  },
  {
    name: 'Estonia',
    value: 'EST',
  },
  {
    name: 'Ethiopia',
    value: 'ETH',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    value: 'FLK',
  },
  {
    name: 'Faroe Islands',
    value: 'FRO',
  },
  {
    name: 'Fiji',
    value: 'FJI',
  },
  {
    name: 'Finland',
    value: 'FIN',
  },
  {
    name: 'France',
    value: 'FRA',
  },
  {
    name: 'French Guiana',
    value: 'GUF',
  },
  {
    name: 'French Polynesia',
    value: 'PYF',
  },
  {
    name: 'French Southern Territories',
    value: 'ATF',
  },
  {
    name: 'Gabon',
    value: 'GAB',
  },
  {
    name: 'Gambia',
    value: 'GMB',
  },
  {
    name: 'Georgia',
    value: 'GEO',
  },
  {
    name: 'Germany',
    value: 'DEU',
  },
  {
    name: 'Ghana',
    value: 'GHA',
  },
  {
    name: 'Gibraltar',
    value: 'GIB',
  },
  {
    name: 'Greece',
    value: 'GRC',
  },
  {
    name: 'Greenland',
    value: 'GRL',
  },
  {
    name: 'Grenada',
    value: 'GRD',
  },
  {
    name: 'Guadeloupe',
    value: 'GLP',
  },
  {
    name: 'Guam',
    value: 'GUM',
  },
  {
    name: 'Guatemala',
    value: 'GTM',
  },
  {
    name: 'Guernsey',
    value: 'GGY',
  },
  {
    name: 'Guinea',
    value: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    value: 'GNB',
  },
  {
    name: 'Guyana',
    value: 'GUY',
  },
  {
    name: 'Haiti',
    value: 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HMD',
  },
  {
    name: 'Holy See',
    value: 'VAT',
  },
  {
    name: 'Honduras',
    value: 'HND',
  },
  {
    name: 'Hong Kong',
    value: 'HKG',
  },
  {
    name: 'Hungary',
    value: 'HUN',
  },
  {
    name: 'Iceland',
    value: 'ISL',
  },
  {
    name: 'India',
    value: 'IND',
  },
  {
    name: 'Indonesia',
    value: 'IDN',
  },
  {
    name: "Côte d'Ivoire",
    value: 'CIV',
  },
  {
    name: 'Iran (Islamic Republic of)',
    value: 'IRN',
  },
  {
    name: 'Iraq',
    value: 'IRQ',
  },
  {
    name: 'Ireland',
    value: 'IRL',
  },
  {
    name: 'Isle of Man',
    value: 'IMN',
  },
  {
    name: 'Israel',
    value: 'ISR',
  },
  {
    name: 'Italy',
    value: 'ITA',
  },
  {
    name: 'Jamaica',
    value: 'JAM',
  },
  {
    name: 'Japan',
    value: 'JPN',
  },
  {
    name: 'Jersey',
    value: 'JEY',
  },
  {
    name: 'Jordan',
    value: 'JOR',
  },
  {
    name: 'Kazakhstan',
    value: 'KAZ',
  },
  {
    name: 'Kenya',
    value: 'KEN',
  },
  {
    name: 'Kiribati',
    value: 'KIR',
  },
  {
    name: 'Kuwait',
    value: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    value: 'KGZ',
  },
  {
    name: "Lao People's Democratic Republic",
    value: 'LAO',
  },
  {
    name: 'Latvia',
    value: 'LVA',
  },
  {
    name: 'Lebanon',
    value: 'LBN',
  },
  {
    name: 'Lesotho',
    value: 'LSO',
  },
  {
    name: 'Liberia',
    value: 'LBR',
  },
  {
    name: 'Libya',
    value: 'LBY',
  },
  {
    name: 'Liechtenstein',
    value: 'LIE',
  },
  {
    name: 'Lithuania',
    value: 'LTU',
  },
  {
    name: 'Luxembourg',
    value: 'LUX',
  },
  {
    name: 'Macao',
    value: 'MAC',
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    value: 'MKD',
  },
  {
    name: 'Madagascar',
    value: 'MDG',
  },
  {
    name: 'Malawi',
    value: 'MWI',
  },
  {
    name: 'Malaysia',
    value: 'MYS',
  },
  {
    name: 'Maldives',
    value: 'MDV',
  },
  {
    name: 'Mali',
    value: 'MLI',
  },
  {
    name: 'Malta',
    value: 'MLT',
  },
  {
    name: 'Marshall Islands',
    value: 'MHL',
  },
  {
    name: 'Martinique',
    value: 'MTQ',
  },
  {
    name: 'Mauritania',
    value: 'MRT',
  },
  {
    name: 'Mauritius',
    value: 'MUS',
  },
  {
    name: 'Mayotte',
    value: 'MYT',
  },
  {
    name: 'Mexico',
    value: 'MEX',
  },
  {
    name: 'Micronesia (Federated States of)',
    value: 'FSM',
  },
  {
    name: 'Moldova (Republic of)',
    value: 'MDA',
  },
  {
    name: 'Monaco',
    value: 'MCO',
  },
  {
    name: 'Mongolia',
    value: 'MNG',
  },
  {
    name: 'Montenegro',
    value: 'MNE',
  },
  {
    name: 'Montserrat',
    value: 'MSR',
  },
  {
    name: 'Morocco',
    value: 'MAR',
  },
  {
    name: 'Mozambique',
    value: 'MOZ',
  },
  {
    name: 'Myanmar',
    value: 'MMR',
  },
  {
    name: 'Namibia',
    value: 'NAM',
  },
  {
    name: 'Nauru',
    value: 'NRU',
  },
  {
    name: 'Nepal',
    value: 'NPL',
  },
  {
    name: 'Netherlands',
    value: 'NLD',
  },
  {
    name: 'New Caledonia',
    value: 'NCL',
  },
  {
    name: 'New Zealand',
    value: 'NZL',
  },
  {
    name: 'Nicaragua',
    value: 'NIC',
  },
  {
    name: 'Niger',
    value: 'NER',
  },
  {
    name: 'Nigeria',
    value: 'NGA',
  },
  {
    name: 'Niue',
    value: 'NIU',
  },
  {
    name: 'Norfolk Island',
    value: 'NFK',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    value: 'PRK',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MNP',
  },
  {
    name: 'Norway',
    value: 'NOR',
  },
  {
    name: 'Oman',
    value: 'OMN',
  },
  {
    name: 'Pakistan',
    value: 'PAK',
  },
  {
    name: 'Palau',
    value: 'PLW',
  },
  {
    name: 'Palestine, State of',
    value: 'PSE',
  },
  {
    name: 'Panama',
    value: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    value: 'PNG',
  },
  {
    name: 'Paraguay',
    value: 'PRY',
  },
  {
    name: 'Peru',
    value: 'PER',
  },
  {
    name: 'Philippines',
    value: 'PHL',
  },
  {
    name: 'Pitcairn',
    value: 'PCN',
  },
  {
    name: 'Poland',
    value: 'POL',
  },
  {
    name: 'Portugal',
    value: 'PRT',
  },
  {
    name: 'Puerto Rico',
    value: 'PRI',
  },
  {
    name: 'Qatar',
    value: 'QAT',
  },
  {
    name: 'Republic of Kosovo',
    value: 'KOS',
  },
  {
    name: 'Réunion',
    value: 'REU',
  },
  {
    name: 'Romania',
    value: 'ROU',
  },
  {
    name: 'Russian Federation',
    value: 'RUS',
  },
  {
    name: 'Rwanda',
    value: 'RWA',
  },
  {
    name: 'Saint Barthélemy',
    value: 'BLM',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KNA',
  },
  {
    name: 'Saint Lucia',
    value: 'LCA',
  },
  {
    name: 'Saint Martin (French part)',
    value: 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VCT',
  },
  {
    name: 'Samoa',
    value: 'WSM',
  },
  {
    name: 'San Marino',
    value: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'STP',
  },
  {
    name: 'Saudi Arabia',
    value: 'SAU',
  },
  {
    name: 'Senegal',
    value: 'SEN',
  },
  {
    name: 'Serbia',
    value: 'SRB',
  },
  {
    name: 'Seychelles',
    value: 'SYC',
  },
  {
    name: 'Sierra Leone',
    value: 'SLE',
  },
  {
    name: 'Singapore',
    value: 'SGP',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SXM',
  },
  {
    name: 'Slovakia',
    value: 'SVK',
  },
  {
    name: 'Slovenia',
    value: 'SVN',
  },
  {
    name: 'Solomon Islands',
    value: 'SLB',
  },
  {
    name: 'Somalia',
    value: 'SOM',
  },
  {
    name: 'South Africa',
    value: 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
  },
  {
    name: 'Korea (Republic of)',
    value: 'KOR',
  },
  {
    name: 'South Sudan',
    value: 'SSD',
  },
  {
    name: 'Spain',
    value: 'ESP',
  },
  {
    name: 'Sri Lanka',
    value: 'LKA',
  },
  {
    name: 'Sudan',
    value: 'SDN',
  },
  {
    name: 'Suriname',
    value: 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'SJM',
  },
  {
    name: 'Swaziland',
    value: 'SWZ',
  },
  {
    name: 'Sweden',
    value: 'SWE',
  },
  {
    name: 'Switzerland',
    value: 'CHE',
  },
  {
    name: 'Syrian Arab Republic',
    value: 'SYR',
  },
  {
    name: 'Taiwan',
    value: 'TWN',
  },
  {
    name: 'Tajikistan',
    value: 'TJK',
  },
  {
    name: 'Tanzania, United Republic of',
    value: 'TZA',
  },
  {
    name: 'Thailand',
    value: 'THA',
  },
  {
    name: 'Timor-Leste',
    value: 'TLS',
  },
  {
    name: 'Togo',
    value: 'TGO',
  },
  {
    name: 'Tokelau',
    value: 'TKL',
  },
  {
    name: 'Tonga',
    value: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TTO',
  },
  {
    name: 'Tunisia',
    value: 'TUN',
  },
  {
    name: 'Turkey',
    value: 'TUR',
  },
  {
    name: 'Turkmenistan',
    value: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TCA',
  },
  {
    name: 'Tuvalu',
    value: 'TUV',
  },
  {
    name: 'Uganda',
    value: 'UGA',
  },
  {
    name: 'Ukraine',
    value: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    value: 'ARE',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
  },
  {
    name: 'United States of America',
    value: 'USA',
  },
  {
    name: 'Uruguay',
    value: 'URY',
  },
  {
    name: 'Uzbekistan',
    value: 'UZB',
  },
  {
    name: 'Vanuatu',
    value: 'VUT',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
  },
  {
    name: 'Viet Nam',
    value: 'VNM',
  },
  {
    name: 'Wallis and Futuna',
    value: 'WLF',
  },
  {
    name: 'Western Sahara',
    value: 'ESH',
  },
  {
    name: 'Yemen',
    value: 'YEM',
  },
  {
    name: 'Zambia',
    value: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    value: 'ZWE',
  },
];
