/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { onboardingUrls } from '../../constants/OnboardingURLs';
import { LABELS } from './AddPropertyPopupConstants';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { navigate } from '../../navigation/NavigationUtils';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AddPropertyPopupStyles';
import Button from '../button/Button';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface PropertySwitchPopupProps {
  showModal: boolean;
  isGuest: boolean;
  toggleModal: (showModal: boolean) => void;
}

const AddPropertyPopup: FC<PropertySwitchPopupProps> = ({ showModal, isGuest, toggleModal }) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  return (
    <Dialog
      open={!!showModal}
      maxWidth="md"
      onClose={() => toggleModal(false)}
      PaperProps={{ sx: { borderRadius: '16px' } }}
    >
      <div className={classes.modalView}>
        {themeKey !== ThemeKey.NAX && (
          <div className={classes.title}>{isGuest ? LABELS.LOGIN_TITLE : LABELS.TITLE}</div>
        )}
        <div className={classes.descriptionContainer}>
          <div className={classes.description}>
            {isGuest
              ? LABELS.LOGIN_DESCRIPTION
              : themeKey === ThemeKey.NAX
              ? LABELS.DESCRIPTION_NAX
              : LABELS.DESCRIPTION}
          </div>
        </div>
        {themeKey !== ThemeKey.NAX && (
          <div className={classes.buttonContainer}>
            <Button
              parentStyles={classes.button}
              onPress={() => {
                toggleModal(false);
                if (isGuest) {
                  navigate('login');
                } else {
                  navigate('propertyOnboarding');
                }
              }}
            >
              {isGuest ? LABELS.LOGIN : LABELS.PRIMARY_BUTTON}
            </Button>
          </div>
        )}
        <div
          className={classes.secondaryTextContainer}
          onClick={() => {
            if (isGuest) {
              navigate('login');
            }
            toggleModal(false);
          }}
        >
          <div className={classes.secondaryText}>
            {isGuest ? LABELS.SIGNUP : LABELS.SECONDARY_BUTTON}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isGuest: state.authState.guest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddPropertyPopup);
