import React, { FC, useEffect, useState, useMemo, useRef, ChangeEvent } from 'react';
import { useStyles } from './DaysSelectorStyles';

interface DaysSelectorProps {
  options: string[];
  selected: number[];
  onChange: (option: string[] | number[]) => void;
}

const DaysSelector: FC<DaysSelectorProps> = ({ options, selected, onChange }) => {
  const classes = useStyles();

  const handleClick = (index: number, option: string | boolean | number) => {
    if (selected.includes(index)) {
      const updatedSelected = selected.filter((selectedIndex) => selectedIndex !== index);
      onChange(updatedSelected);
    } else {
      const updatedSelected = [...selected, index];
      onChange(updatedSelected);
    }
  };

  return (
    <div className={classes.daysGrid}>
      {options.map((option: string | boolean, index: number) => (
        <div
          className={selected.includes(index) ? classes.daySelected : classes.dayUnselected}
          key={index}
          onClick={() => handleClick(index, option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};
export default DaysSelector;
