import React, { FC, useMemo, useEffect, useState, useRef } from 'react';
import { History } from 'history';
import { useStyles } from './CartStyles';
import { LABELS } from './CartConstants';
import closeIcon from '../../assets/navigation/close.png';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import {
  AvailableServicesResponse,
  DeleteCartItemActionRequest,
  PromoCode,
} from '../../models/checkout/Checkout';
import CartItem from './components/cartItem/CartItem';
import tick from '../../assets/blueTick.png';
import downArrow from '../../assets/navigation/accordian.png';
import emptyCart from '../../assets/empty-cart.png';
import chatMessageBlack from '../../assets/chat-black.svg';
import { getFrequency } from '../checkoutSelect/components/plan/PlanUtils';
import { getProductDetails } from '../checkoutConfigure/components/selectOptions/SelectOptionsUtils';
import { routes } from '../../Routes';
import { getMonthlyDefault } from './CartUtils';
import { updateAvailableServicesOnSelect, useCart } from '../../helpers/CheckoutHelper';
import { ApplicationState } from '../../store/RootReducer';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { FooterPricing } from '../../models/billing/Billing';
import { dollarFormatter } from '../../helpers/CurrencyHelper';
import { navigate } from '../../navigation/NavigationUtils';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import InputSelect from '../../components/inputSelect/InputSelect';
import { Property } from '../../models/property/property';

interface CartProps {
  summary?: boolean;
  configure?: boolean;
  promoCode: PromoCode | undefined;
  isTradeRequest: boolean;
  property: Property;
  availableServices: AvailableServicesResponse[];
  showReferralSummary?: boolean;
  toggleCart: () => void;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
}

const Cart: FC<CartProps> = ({
  summary,
  configure,
  promoCode,
  isTradeRequest,
  property,
  availableServices,
  showReferralSummary,
  toggleCart,
  deleteCartItem,
}) => {
  const { cartItems, cartServices, cartReferrals, cartPricing, feeItems, feeItemsHelperTextList } =
    useCart();
  const [frequency, setFrequency] = useState<string>(getMonthlyDefault(cartPricing));

  const summaryRef = useRef<HTMLDivElement>(null);
  const [summaryHeight, setSummaryHeight] = useState<number>(0);

  const estimateCostRef = useRef<HTMLDivElement>(null);
  const [estimateCostHeight, setEstimateCostHeight] = useState<number>(0);

  const totalCostRef = useRef<HTMLDivElement>(null);
  const [totalCostHeight, setTotalCostHeight] = useState<number>(0);

  const otherChargesRef = useRef<HTMLDivElement>(null);
  const [otherChargesHeight, setOtherChargesHeight] = useState<number>(0);

  const classes = useStyles();

  const estimateTotal = useMemo<number>(() => {
    let result = 0;
    cartServices.map((item) =>
      item.suppliers!.map((supplier) =>
        supplier.plans.map((plan) => {
          let check = Number(
            getProductDetails(supplier.extendedData!, plan.selectedProductId).productOptions[
              frequency
            ],
          );
          if (Number.isNaN(check)) {
            result += plan.price;
          } else {
            result += check;
          }
        }),
      ),
    );
    return result;
  }, [cartServices]);

  useEffect(() => {
    document.addEventListener('backbutton', () => toggleCart());
    return () => document.removeEventListener('backbutton', () => null);
  }, []);

  const handleDelete = (cartItem: AvailableServicesResponse) => {
    const updatedAvailableServices = updateAvailableServicesOnSelect(
      true,
      cartItem.type,
      cartItem.suppliers![0].providerId,
      cartItem.suppliers![0].plans[0].productId,
    );

    deleteCartItem({
      availableServices: updatedAvailableServices,
      deleteCartItemRequest: {
        propertyId: property.id.toString(),
        serviceType: cartItem.type,
        supplierId: cartItem.suppliers![0].providerId,
        planId: cartItem.suppliers![0].plans[0].productId,
      },
    });
  };

  return (
    <>
      {!summary && (
        <div className={classes.popupHeader}>
          <div className={classes.popupLabel}>{LABELS.TITLE}</div>
          <div className={classes.closeContainer} onClick={() => toggleCart()}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
      )}
      {cartPricing && cartPricing.length > 1 ? (
        <div className={classes.showPricingContainer}>
          <div className={classes.estimateLabel}>{LABELS.ESTIMATED}</div>
          <InputSelect
            value={cartPricing.find((p) => p.PriceField === frequency)!.UnitOfMeasure}
            values={cartPricing.map((footerPricing: FooterPricing) => ({
              display: footerPricing.UnitOfMeasure,
              value: footerPricing.PriceField,
            }))}
            setValue={(value: string) => setFrequency(value)}
            placeholder="Frequency"
            parentStyles={classes.estimateStyles}
            hideEndElement
          />
        </div>
      ) : undefined}

      <div className={classes.cartScroll}>
        <div className={classes.cartContainer}>
          {!!cartItems.length ? (
            <>
              {!!cartServices.length && (
                <div className={classes.cartSection}>
                  <div className={classes.cartItemsHeadingRow}>
                    <div className={classes.cartItemsHeading}>
                      {LABELS.SERVICES(cartServices.length)}
                    </div>
                  </div>
                  {cartServices.map((cartItem) => (
                    <CartItem
                      hideAction={summary}
                      cartItem={cartItem}
                      frequency={frequency}
                      key={`${cartItem.type}`}
                      handleAction={() => handleDelete(cartItem)}
                      handleTap={() => null}
                      hover
                    />
                  ))}
                </div>
              )}
              {!!cartReferrals.length && (
                <div className={classes.cartSection}>
                  <div className={classes.cartItemsHeadingRow}>
                    <div className={classes.cartItemsHeading}>
                      {LABELS.REFERRALS(cartReferrals.length)}
                    </div>
                  </div>
                  {cartReferrals.map((cartItem) => (
                    <CartItem
                      hideAction={summary}
                      cartItem={cartItem}
                      frequency={frequency}
                      key={`${cartItem.type}`}
                      handleAction={() => handleDelete(cartItem)}
                      handleTap={() => null}
                      quote
                      hover
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className={classes.emptyCartContainer}>
              <div className={classes.emptyCartIconContainer}>
                <img className={classes.emptyCartIcon} src={emptyCart} alt="empty cart" />
              </div>
              <div className={classes.emptyCartTitle}>{LABELS.EMPTY_CART_TITLE}</div>
              <div className={classes.emptyCartText}>{LABELS.EMPTY_CART_TEXT}</div>
            </div>
          )}
        </div>
        <div className={classes.cartSummary}>
          {!!cartServices.length && (
            <>
              <div className={classes.summaryContainer}>
                <div className={classes.summaryRow}>
                  <div className={classes.summaryBlackKey}>
                    {!!cartServices.filter(
                      (item) => item.suppliers![0].plans[0].pricingType === 'Estimated',
                    ).length && 'Estimated '}
                    {getFrequency(frequency)} cost
                  </div>
                  <div className={classes.summaryBlueValue}>{`$${estimateTotal.toFixed(2)}`}</div>
                </div>
                <div
                  className={classes.content}
                  ref={estimateCostRef}
                  style={{
                    maxHeight: `${estimateCostHeight}px`,
                    opacity: estimateCostHeight > 0 ? 1 : 0,
                  }}
                >
                  <>
                    {cartServices.map((item) =>
                      item.suppliers!.map((supplier) =>
                        supplier.plans.map((plan) => (
                          <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                            <div className={classes.summaryGreyKey}>
                              {item.type} - {supplier.name}
                            </div>
                            <div className={classes.summaryBlackValue}>
                              {dollarFormatter(
                                getProductDetails(supplier.extendedData!, plan.selectedProductId)
                                  .productOptions[frequency],
                              )}
                            </div>
                          </div>
                        )),
                      ),
                    )}
                  </>
                </div>
              </div>
              <div
                className={classes.actionContainer}
                onClick={() => {
                  setEstimateCostHeight(
                    estimateCostHeight === 0 ? estimateCostRef.current!.scrollHeight : 0,
                  );
                }}
              >
                <div className={classes.actionTitle}>
                  {estimateCostHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                </div>
                <div
                  className={`${classes.actionIconContainer} ${
                    estimateCostHeight > 0 && classes.rotate
                  }`}
                >
                  <img className={classes.actionIcon} src={downArrow} alt="status" />
                </div>
              </div>

              {/* --------------------------------------------------------------------------------------- */}
              {!!feeItems.filter(
                (feeItem) => feeItem.Type === 'MinimumFee' || feeItem.Type === 'Setup',
              ).length && (
                <>
                  <div className={classes.summaryContainer} style={{ paddingTop: 24 }}>
                    <div className={classes.summaryRow}>
                      <div className={classes.summaryBlackKey}>
                        Estimated connection or set up fees
                      </div>
                      <div className={classes.summaryBlueValue}>
                        $
                        {feeItems
                          .filter((f) => f.Type === 'Setup' || f.Type === 'MinimumFee')
                          .reduce((a, b) => a + b.FeeItems[0].Rate, 0)
                          .toFixed(2)}
                      </div>
                    </div>
                    <div className={classes.summaryGreyKey} style={{ marginTop: 10 }}>
                      These charges will be confirmed and usually appear on your first bill
                    </div>
                    <div
                      className={classes.content}
                      ref={totalCostRef}
                      style={{
                        maxHeight: `${totalCostHeight}px`,
                        opacity: totalCostHeight > 0 ? 1 : 0,
                      }}
                    >
                      <>
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'Setup' ? (
                              <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                                <div
                                  className={classes.summaryGreyKey}
                                >{`${feeItem.ServiceType!} - ${feeItem.FeeItems[0].Label}`}</div>
                                <div className={classes.summaryBlackValue}>{`$${
                                  feeItem.FeeItems[0].Rate ? feeItem.FeeItems[0].Rate.toFixed(2) : 0
                                }`}</div>
                              </div>
                            ) : null}
                          </>
                        ))}
                        {!!feeItems.filter((feeItem) => feeItem.Type === 'MinimumFee').length && (
                          <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                            <div className={classes.summaryBlackKey}>Early termination fees</div>
                          </div>
                        )}
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'MinimumFee' ? (
                              <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                                <div
                                  className={classes.summaryGreyKey}
                                >{`${feeItem.ServiceType!} - ${feeItem.FeeItems[0].Label}`}</div>
                                <div className={classes.summaryBlackValue}>{`$${
                                  feeItem.FeeItems[0].Rate ? feeItem.FeeItems[0].Rate.toFixed(2) : 0
                                }`}</div>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </>
                    </div>
                  </div>
                  <div
                    className={classes.actionContainer}
                    onClick={() => {
                      setTotalCostHeight(
                        totalCostHeight === 0 ? totalCostRef.current!.scrollHeight : 0,
                      );
                    }}
                  >
                    <div className={classes.actionTitle}>
                      {totalCostHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                    </div>
                    <div
                      className={`${classes.actionIconContainer} ${
                        totalCostHeight > 0 && classes.rotate
                      }`}
                    >
                      <img className={classes.actionIcon} src={downArrow} alt="status" />
                    </div>
                  </div>

                  {totalCostHeight > 0 &&
                    feeItemsHelperTextList.map((helperText, index) => (
                      <>
                        {helperText ? (
                          <div className={classes.disclamerText}>{helperText}</div>
                        ) : null}
                      </>
                    ))}
                </>
              )}

              {/* --------------------------------------------------------------------------------------- */}

              {/* --------------------------------------------------------------------------------------- */}
              {!!feeItems.filter((feeItem) => feeItem.Type === 'OtherCharges').length && (
                <>
                  <div className={classes.summaryContainer} style={{ marginTop: 15 }}>
                    <div className={classes.summaryRow}>
                      <div className={classes.summaryBlackKey}>Other charges</div>
                      <div className={classes.summaryBlueValue}>
                        $
                        {feeItems
                          .filter((f) => f.Type === 'OtherCharges')
                          .reduce((a, b) => a + b.FeeItems[0].Rate, 0)}
                      </div>
                    </div>
                    <div
                      className={classes.content}
                      ref={otherChargesRef}
                      style={{
                        maxHeight: `${otherChargesHeight}px`,
                        opacity: otherChargesHeight > 0 ? 1 : 0,
                      }}
                    >
                      <>
                        {feeItems.map((feeItem, index) => (
                          <>
                            {feeItem.Type === 'OtherCharges' ? (
                              <div style={{ marginTop: 15 }}>
                                <div className={classes.summaryBlackKey} style={{ marginTop: 5 }}>
                                  {`${feeItem.ServiceType!}`}
                                </div>
                                <div className={classes.summaryGreyKey} style={{ marginTop: 5 }}>
                                  {`${
                                    feeItem.ChargesFeesText ||
                                    'These charges will be confirmed and may appear on your first bill'
                                  }`}
                                </div>
                                <div className={classes.summaryRow} style={{ marginTop: 5 }}>
                                  <div className={classes.summaryGreyKey}>
                                    {`${feeItem.FeeItems[0].Label}`}
                                  </div>
                                  <div className={classes.summaryBlackValue}>
                                    {`$${
                                      feeItem.FeeItems[0].Rate
                                        ? feeItem.FeeItems[0].Rate.toFixed(2)
                                        : 0
                                    }`}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </>
                    </div>
                  </div>
                  <div
                    className={classes.actionContainer}
                    onClick={() => {
                      setOtherChargesHeight(
                        otherChargesHeight === 0 ? otherChargesRef.current!.scrollHeight : 0,
                      );
                    }}
                  >
                    <div className={classes.actionTitle}>
                      {otherChargesHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                    </div>
                    <div
                      className={`${classes.actionIconContainer} ${
                        otherChargesHeight > 0 && classes.rotate
                      }`}
                    >
                      <img className={classes.actionIcon} src={downArrow} alt="status" />
                    </div>
                  </div>
                </>
              )}

              {/* --------------------------------------------------------------------------------------- */}
            </>
          )}
          {showReferralSummary &&
            !!cartReferrals.length &&
            !!cartReferrals.some((r) =>
              r.suppliers!.some((s) => s.plans.some((p) => !!p.cartData)),
            ) && (
              <>
                <div className={classes.summaryContainer}>
                  <div className={classes.summaryRow}>
                    <div className={classes.summaryBlackKey}>Referral summary</div>
                  </div>
                  <div
                    className={classes.content}
                    ref={summaryRef}
                    style={{
                      maxHeight: `${summaryHeight}px`,
                      opacity: summaryHeight > 0 ? 1 : 0,
                    }}
                  >
                    <>
                      {cartReferrals.map((item) =>
                        item.suppliers!.map((supplier) =>
                          supplier.plans.map((plan) => (
                            <>
                              <div className={classes.summaryRow} style={{ marginTop: 30 }}>
                                <div className={classes.summaryBlackValue}>
                                  {supplier.name} - {plan.title}
                                </div>
                              </div>
                              {plan.cartData &&
                                plan.cartData.planDetails
                                  .featureDetails!.filter((f) => f.type === 'answers')
                                  .filter((data) => data.labelId !== 'MediaUpload')
                                  .map((f) => (
                                    <div className={classes.summaryRow} style={{ marginTop: 15 }}>
                                      <div className={classes.summaryGreyKey}>{f.label!}</div>
                                      <div className={classes.summaryBlackValue}>{f.value}</div>
                                    </div>
                                  ))}
                            </>
                          )),
                        ),
                      )}
                    </>
                  </div>
                </div>
                <div
                  className={classes.actionContainer}
                  onClick={() => {
                    setSummaryHeight(summaryHeight === 0 ? summaryRef.current!.scrollHeight : 0);
                  }}
                >
                  <div className={classes.actionTitle}>
                    {summaryHeight > 0 ? LABELS.HIDE : LABELS.SHOW}
                  </div>
                  <div
                    className={`${classes.actionIconContainer} ${
                      summaryHeight > 0 && classes.rotate
                    }`}
                  >
                    <img className={classes.actionIcon} src={downArrow} alt="status" />
                  </div>
                </div>
              </>
            )}
          {!summary && !configure && (
            <div
              className={classes.buttonContainer}
              onClick={() => {
                navigate('checkoutConfigure');
                toggleCart();
              }}
            >
              <div className={classes.primaryButton}>
                {cartServices.length === 0 && cartReferrals.length > 0
                  ? LABELS.GET_QUOTE
                  : LABELS.CHECKOUT}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  availableServices: state.checkout.availableServices,
  property: state.checkout.property,
  promoCode: state.checkout.promoCode,
  isTradeRequest: state.checkout.isTradeRequest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCart: () => dispatch(CheckoutActions.toggleCart()),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
