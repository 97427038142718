/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { put, call, takeEvery } from 'redux-saga/effects';
import {
  AddPaymentTagsResponse,
  AddTagStatus,
  BankAccountRequest,
  CheckoutStripeToken,
  CreditCardRequest,
  DeletePaymentRequest,
  GetFastPaymentRequest,
  GetFastPayResponse,
  GetPaymentAuthKey,
  GetPaymentAuthKeyRequest,
  GetPaymentAuthKeyResponse,
  GetPaymentMethodsPayLoad,
  GetPaymentMethodsResponse,
  GetPaymentRefId,
  GetPaymentTypeResponse,
  GetPaymentTypes,
  GetRefIdResponse,
  GetStripePaymentIntentRequest,
  GetStripePaymentIntentResponse,
  PayIdPayLoad,
  PaymentGateway,
  PostEditPaymentPayload,
  PostPaymentRequest,
  PostPaymentTokenResponse,
  PostPaymentUUIDRequest,
  PostPaymentUUIDResponse,
  QuickStreamBankAccountRequest,
  QuickStreamCreditCardRequest,
} from '../../models/payment/Payment';
import PaymentService from '../../services/payment/PaymentService';
import { DashboardActions } from '../actions/DashboardActions';
import camelCaseKeys from 'camelcase-keys';
import { PaymentAction, PaymentActions, PaymentActionTypes } from '../actions/PaymentActions';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import pascalCase from '../../helpers/ConvertPascal';
import camelizeCase from '../../helpers/ConvertCase';

function* addTagRequest(action: PaymentAction) {
  try {
    const response: AddPaymentTagsResponse = yield PaymentService.postTagPayment(
      camelCaseKeys(action.data as AddTagStatus, {
        deep: true,
        pascalCase: true,
      }),
    );
    yield put(PaymentActions.paymentTagSuccess(camelCaseKeys(response)));
    if ((response as any).length > 0) {
      yield put(DashboardActions.switchCta(true));
    } else {
      yield put(DashboardActions.switchCta(false));
    }
  } catch (error) {
    yield put(PaymentActions.paymentTagError(error as Error));
  }
}

function* paymentRequest(action: PaymentAction) {
  const { onFailure } = action.data as GetFastPaymentRequest;
  try {
    const response: GetFastPayResponse = yield PaymentService.fetchPayment(
      camelCaseKeys((action.data as GetFastPaymentRequest).data, {
        deep: true,
        pascalCase: true,
      }),
    );
    const camelCasekeys: GetFastPayResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(PaymentActions.fetchPaymentSuccess(camelCasekeys));
    if ((response as any).length > 0) {
      yield put(DashboardActions.switchCta(true));
    } else {
      yield put(DashboardActions.switchCta(false));
    }
  } catch (error) {
    if (onFailure) {
      onFailure();
    }
    yield put(PaymentActions.fetchPaymentError(error as Error));
  }
}

export function* getPaymentTypesWorker(action: PaymentAction) {
  try {
    const { data, onSuccess } = action.data as GetPaymentTypes;
    const response: GetPaymentTypeResponse[] = yield PaymentService.getPaymentTypes(data);
    yield put(PaymentActions.getPaymentTypesSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentTypesError(error as Error));
  }
}

export function* getPaymentPayIdWorker(action: PaymentAction) {
  try {
    const { onSuccess } = action.data as any;
    const response: GetFastPayResponse = yield PaymentService.getPaymentPayId(
      pascalCase(action.data as PayIdPayLoad),
    );
    const camelCasekeys: GetFastPayResponse = camelizeCase(response);
    yield put(PaymentActions.getPaymentPayIdSuccess(camelCasekeys));
    if (onSuccess) {
      onSuccess(camelCasekeys);
    }
  } catch (error) {
    // sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentMethodsError(error as Error));
  }
}

export function* getPaymentMethodsWorker(action: PaymentAction) {
  try {
    const { onSuccess } = action.data as GetPaymentMethodsPayLoad;
    const response: GetPaymentMethodsResponse[] = yield PaymentService.getPaymentMethods();
    yield put(PaymentActions.getPaymentMethodsSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentMethodsError(error as Error));
  }
}

export function* postPaymentUUIDWorker(action: PaymentAction) {
  try {
    const response: PostPaymentUUIDResponse = yield PaymentService.postPaymentUUID(
      action.data as PostPaymentUUIDRequest,
    );
    yield put(PaymentActions.postPaymentMethodUUIDSuccess(response));
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
  }
}

export function* getPaymentRefId(action: PaymentAction) {
  try {
    const actionData = action.data as GetPaymentRefId;
    const response: GetRefIdResponse = yield PaymentService.getPaymentRefId(
      actionData.token,
      actionData.providerId,
      actionData.transactionId,
    );

    actionData.onSuccess(response.accountToken);
    yield put(PaymentActions.getRefIdSuccess(response));
  } catch (error) {
    yield put(PaymentActions.getRefIdError(error as Error));
  }
}

export function* getPaymentAuthKeySaga(action: PaymentAction) {
  try {
    const actionData = action.data as GetPaymentAuthKeyRequest;
    const response: GetPaymentAuthKey = yield PaymentService.getPaymentAuthKey(
      actionData.paymentGateway,
      actionData.data.type === 'CREDITCARD' ? 'card' : 'bank',
      actionData.providerId,
    );
    if (response.status === 0) {
      // IF WESTPAC QUICK STREAM ==============================================================
      if (
        !!actionData.paymentGateway &&
        actionData.paymentGateway === PaymentGateway.WESTPAC_QUICK
      ) {
        QuickstreamAPI.init({
          publishableApiKey: response.authKey,
        });
        if (actionData.data.type === 'CREDITCARD') {
          const paymentDetails = actionData.data as CreditCardRequest;
          const quickStreamRequest: QuickStreamCreditCardRequest = {
            accountType: 'CREDIT_CARD',
            cardHolderName: paymentDetails.cardHolderName,
            cardNumber: paymentDetails.cardNumber.replace(/\s+/g, ''),
            expiryDateMonth: paymentDetails.expiryMonth,
            expiryDateYear: '20' + paymentDetails.expiryYear,
            supplierBusinessCode: response.businessCode!,
          };
          const form = document.createElement('form');
          for (const [key, value] of Object.entries(quickStreamRequest)) {
            const input = document.createElement('input');
            input.setAttribute('data-quickstream-api', key);
            input.value = value;
            form.appendChild(input);
          }
          QuickstreamAPI.creditCards.getToken(
            form as any,
            response.businessCode!,
            (errors, data) => {
              if (errors) {
                actionData.onError(errors[0].messages[0] as unknown as Error);
                console.log('Errors: ' + JSON.stringify(errors));
              } else {
                actionData.onSuccess(data.singleUseToken.singleUseTokenId);
              }
            },
          );
        } else {
          const paymentDetails = actionData.data as BankAccountRequest;
          const quickStreamRequest: QuickStreamBankAccountRequest = {
            accountType: 'DIRECT_DEBIT',
            accountName: paymentDetails.accountName,
            bsb: paymentDetails.bsbNumber.replace('-', ''),
            accountNumber: paymentDetails.accountNumber,
            supplierBusinessCode: response.businessCode!,
          };
          const form = document.createElement('form');
          for (const [key, value] of Object.entries(quickStreamRequest)) {
            const input = document.createElement('input');
            input.setAttribute('data-quickstream-api', key);
            input.value = value;
            form.appendChild(input);
          }
          QuickstreamAPI.bankAccounts.getToken(
            form as any,
            response.businessCode!,
            (errors, data) => {
              if (errors) {
                actionData.onError(errors[0].messages[0] as unknown as Error);
                console.log('Errors: ' + JSON.stringify(errors));
              } else {
                actionData.onSuccess(data.singleUseToken.singleUseTokenId);
              }
            },
          );
        }
        // IF BPOINT =============================================================================
      } else {
        let requestObject = {};
        if (actionData.data.type === 'CREDITCARD') {
          const paymentDetails = actionData.data as CreditCardRequest;
          requestObject = {
            Type: paymentDetails.type,
            CardHolderName: paymentDetails.cardHolderName,
            CardNumber: paymentDetails.cardNumber.replace(/\s+/g, ''),
            ExpiryMonth: paymentDetails.expiryMonth,
            ExpiryYear: paymentDetails.expiryYear,
          };
        } else {
          const paymentDetails = actionData.data as BankAccountRequest;
          requestObject = {
            Type: paymentDetails.type,
            BankAccountName: paymentDetails.accountName,
            BankAccountNumber: paymentDetails.accountNumber,
            BSBNumber: paymentDetails.bsbNumber.replace('-', ''),
            AcceptBankAccountTerms: true,
          };
        }
        const paymentDetails = actionData.data;
        // @ts-ignore
        CBA.ProcessAddToken({
          AuthKey: response.authKey,
          Crn1: paymentDetails.email,
          EmailAddress: paymentDetails.email,
          ...requestObject,
          CallbackFunction: (result: any) =>
            creditCardCallBackFunction(result, actionData.onSuccess, actionData.onError),
        });
      }
    } else {
      yield put(
        PaymentActions.getPaymentAuthKeyError({
          error: 'error to fetch auth key',
        } as unknown as Error),
      );
    }
    yield put(PaymentActions.getPaymentAuthKeySuccess(response));
  } catch (error) {
    yield call(console.log, error);
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentAuthKeyError(error as Error));
  }
}

const creditCardCallBackFunction = (
  response: any,
  onSuccess: (token: string) => void,
  onError: (error: Error) => void,
) => {
  if (response.AjaxResponseType === 0 && response.ApiResponseCode === 0) {
    const dvtoken = response.ResultKey;
    onSuccess(dvtoken);
  } else {
    console.log('error: ', response);
    onError(response.Errors[0].Message as unknown as Error);
  }
};

export function* postPaymentTokenSaga(action: PaymentAction) {
  try {
    const actionData = action.data as PostPaymentRequest;
    const response: PostPaymentTokenResponse = yield PaymentService.postPaymentToken(
      actionData.data,
    );
    yield put(PaymentActions.postPaymentTokenSuccess(response));
    if (actionData.onSuccess) {
      actionData.onSuccess(response);
    }
  } catch (error) {
    yield call(console.log, error);
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.getPaymentAuthKeyError(error as Error));
  }
}

export function* getStripePaymentIntent(action: PaymentAction) {
  try {
    const response: GetStripePaymentIntentResponse = yield PaymentService.getStripePaymentIntent(
      action.data as GetStripePaymentIntentRequest,
    );
    const shapedResponse = {
      clientSecret: response.clientSecret,
      stripePromise: response.publicKey,
      serviceType: (action.data as GetStripePaymentIntentRequest).serviceType,
      providerId: (action.data as GetStripePaymentIntentRequest).providerId,
      productId: (action.data as GetStripePaymentIntentRequest).productId,
    } as CheckoutStripeToken;
    yield put(PaymentActions.getStripeCheckoutTokenSuccess(shapedResponse));
  } catch (error) {
    yield call(console.log, error);
    yield put(PaymentActions.getStripeCheckoutTokenError(error as any));
  }
}

export function* editPaymentSaga(action: PaymentAction) {
  try {
    const { data, onSuccess } = action.data as PostEditPaymentPayload;
    yield PaymentService.postEditPaymentService(data);
    if (onSuccess) {
      onSuccess();
    }
    yield put(PaymentActions.postEditPaymentSuccess());
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.postEditPaymentError(error as Error));
  }
}

export function* deletePaymentSaga(action: PaymentAction) {
  try {
    const { paymentRefId, onSuccess } = action.data as DeletePaymentRequest;
    const response: string[] = yield PaymentService.deletePayment(paymentRefId);
    yield put(PaymentActions.deletePaymentSuccess(response));
    if (onSuccess) {
      onSuccess(response);
    }
  } catch (error) {
    sendMessageToApp(ValidMessages.ErrorScreen);
    yield put(PaymentActions.deletePaymentError(error as Error));
  }
}

function* paymentWatcher() {
  yield takeEvery(PaymentActionTypes.FETCH_PAYMENT_REQUEST, (action) =>
    paymentRequest(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.CHANGE_PAYMENT_TAG_REQUEST, (action) =>
    addTagRequest(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.POST_EDIT_PAYMENT_REQUEST, (action) =>
    editPaymentSaga(action as PaymentAction),
  );
  //
  yield takeEvery(PaymentActionTypes.GET_AVAILABLE_PAYMENT_TYPES_REQUEST, (action) =>
    getPaymentTypesWorker(action as PaymentAction),
  );

  yield takeEvery(PaymentActionTypes.POST_PAYMENT_METHOD_UUID_REQUEST, (action) =>
    postPaymentUUIDWorker(action as PaymentAction),
  );

  yield takeEvery(PaymentActionTypes.GET_SAVED_PAYMENT_METHODS_REQUEST, (action) =>
    getPaymentMethodsWorker(action as PaymentAction),
  );

  yield takeEvery(PaymentActionTypes.GET_FAST_PAYID_REQUEST, (action) =>
    getPaymentPayIdWorker(action as PaymentAction),
  );

  yield takeEvery(PaymentActionTypes.GET_PAYMENT_AUTH_KEY_REQUEST, (action) =>
    getPaymentAuthKeySaga(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.POST_PAYMENT_TOKEN_REQUEST, (action) =>
    postPaymentTokenSaga(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.GET_STRIPE_CHECKOUT_TOKEN_REQUEST, (action) =>
    getStripePaymentIntent(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.GET_REFID_REQUEST, (action) =>
    getPaymentRefId(action as PaymentAction),
  );
  yield takeEvery(PaymentActionTypes.DELETE_PAYMENT_REQUEST, (action) =>
    deletePaymentSaga(action as PaymentAction),
  );
}

export default paymentWatcher;
