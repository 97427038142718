import * as axios from 'axios';
import { get } from 'lodash';
import { AppSettings } from '../AppSettings';
import AxiosInstance from './AxiosInstance';
import { HttpContentType } from './HttpContentType';
import { QueryPath } from './QueryPath.data';
import { ServiceType } from './ServiceType.data';
import localStorage from './LocalStorage';
import { RefreshTokenRequest } from '../models/auth/RefreshTokenRequest';
import { RefreshTokenResponse } from '../models/auth/RefreshTokenResponse';
import { sendMessageToApp, ValidMessages } from '../helpers/MessageHelper';
import { store } from '../store/Store';
import { MultipartFileData, MultipartFormData } from './MultipartFormData.data';
import { TokenAction, TokenActionTypes } from '../store/actions/TokenActions';
import { navigate, resetNavigation } from '../navigation/NavigationUtils';
import { ContentTypeHelper } from '../helpers/Content/ContentType.data';
import { ToastMessage } from '../helpers/ToastMessage';
import { AuthActions } from '../store/actions/AuthActions';

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly customURL: string | undefined;
  protected readonly customHeaders: any | undefined;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType, customURL?: string, customHeaders?: any) {
    this.serviceType = serviceType;
    this.customURL = customURL;
    this.customHeaders = customHeaders;
  }

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract get<T = void>(path: QueryPath): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract postOtherService<T = void>(
    path: string,
    body: any,
    token: string,
  ): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract delete<T = void>(path: QueryPath): Promise<T>;

  public abstract patch<T = void>(path: QueryPath, body?: any): Promise<T> | T;

  public abstract postMultipartFileData<T = void>(
    path: QueryPath,
    data: MultipartFileData[],
  ): Promise<T> | T;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    if (error && error.message === 'Network Error') {
      return new Error('Network Error');
    }
    const errorCode = error.response ? error.response.status || 500 : 500;
    const response = { data: error.response.data, status: error.response.data.httpStatusCode };
    switch (errorCode) {
      case 500:
      case 404:
      case 400:
      case 401:
      case 403:
      case 406:
      case 409: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err;

            if (errArr.length > 0 && errArr[0]) {
              if (errArr[0].message) {
                return new Error(errArr[0].message.toString());
              } else if (errArr[0].Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if (err.message) {
            return new Error(err.message.toString());
          } else if (err.Message) {
            return new Error(err.Message.toString());
          } else {
            return new Error(err.toString());
          }
        }
        if (error.response.data.message) {
          return new Error(error.response.data.message);
        }

        return new Error('Internal server error');
      }
      case 502:
      case 503: {
        navigate('maintenanceScreen');
        return;
      }
    }
    return { response };
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const authToken = store.getState().token.accessToken;
    if (!!this.customHeaders) {
      return {
        headers: this.customHeaders,
      };
    }
    const headers = this.serviceType.includes('api')
      ? {
          'Content-Type': contentType.toString(),
          'Authorization': `Bearer ${authToken}`,
        }
      : {
          'Content-Type': contentType.toString(),
        };

    return {
      headers,
    };
  }

  /* tslint:enable */
  protected getConfigOtherService(
    contentType: HttpContentType,
    token: string,
  ): axios.AxiosRequestConfig {
    const headers = {
      'Content-Type': contentType.toString(),
      'Authorization': `Bearer ${token}`,
    };
    return {
      headers,
    };
  }

  protected isAuthTokenRequired(path: string): boolean {
    return path.includes('/api');
  }
  protected getAxiosInstanceOther(): axios.AxiosInstance {
    const instance = AxiosInstance.create();

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        return Promise.reject(error);
      },
    );
    return instance;
  }

  protected getAxiosInstance(auth = false): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    let triedRefreshingToken = false;
    const baseUrl = auth
      ? AppSettings.server.authBaseUrl
      : this.customURL || AppSettings.server.baseUrl;
    const { isWebView } = store.getState().token;

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        const originalRequest = error.config;
        if (error.response.status === 401 && this.isAuthTokenRequired(error.config.url)) {
          if (!triedRefreshingToken) {
            sendMessageToApp(ValidMessages.Log, 'Auth token expired. Attempting refresh.');
            triedRefreshingToken = true;
            const { refreshToken } = store.getState().token;
            const tokenRequestData: RefreshTokenRequest = {
              refreshToken,
            };
            return instance.post(`${baseUrl}/${ServiceType.RefreshToken}`, tokenRequestData).then(
              (response) => {
                const refreshedTokens: RefreshTokenResponse = {
                  authToken: response.data.authToken,
                  refreshToken: response.data.refreshToken,
                };
                // UPDATING TOKENS IN APP AND WEB
                sendMessageToApp(ValidMessages.UpdateToken, refreshedTokens);

                const authTokenEvent: TokenAction = {
                  type: TokenActionTypes.SET_ACCESS_TOKEN,
                  data: refreshedTokens.authToken,
                };
                const refreshTokenEvent: TokenAction = {
                  type: TokenActionTypes.SET_REFRESH_TOKEN,
                  data: refreshedTokens.refreshToken,
                };
                store.dispatch(authTokenEvent);
                store.dispatch(refreshTokenEvent);

                originalRequest.headers.Authorization = `Bearer ${refreshedTokens.authToken}`;
                return instance(originalRequest);
              },
              (ReAttemptError: any) => {
                if (isWebView) {
                  sendMessageToApp(ValidMessages.Log, 'Token refresh attemp failed.');
                  sendMessageToApp(ValidMessages.SessionExpired);
                } else {
                  this.forcedLogout();
                  console.error(
                    'Api interceptor - Fetch refreshToken attempt failed with ',
                    ReAttemptError,
                  );
                }
              },
            );
          } else {
            if (isWebView) {
              sendMessageToApp(ValidMessages.SessionExpired);
            } else {
              this.forcedLogout();
            }
          }
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  protected forcedLogout() {
    ToastMessage.showMessage('Session expired! Please login again.', ' ', 'danger', 3000);
    localStorage.clearAll();
    resetNavigation('login');
  }

  /// Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  /// Need this to be able to write the mocks properly. Don't want to parse urls.
  /// Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath, auth = false): string {
    const baseUrl = auth
      ? AppSettings.server.authBaseUrl
      : this.customURL || AppSettings.server.baseUrl;
    let url = `${baseUrl}/${this.serviceType}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route) {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = '?';

        for (const name in path.query) {
          if (path.query[name] !== undefined && path.query[name] !== null) {
            url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
            separator = '&';
          }
        }
      }
    }

    return url;
  }

  private async processFile(file: MultipartFileData, newFiles: MultipartFormData[]): Promise<void> {
    const ctype = ContentTypeHelper.parseContentType(file.file.type);
    newFiles.push({
      name: file.name,
      content: {
        contentRef: { contentType: ctype!, name: file.file.name },
        size: file.file.size,
        data: file.file,
        dataBase64: await ContentTypeHelper.convertBlobToBase64(file.file),
      },
    });
  }

  protected async prepareMultiPartFileForm(
    data: MultipartFileData | MultipartFileData[],
  ): Promise<any> {
    const promises = new Array<Promise<void>>();
    const files: MultipartFormData[] = [];
    if (Array.isArray(data)) {
      data.forEach((file) => promises.push(this.processFile(file, files)));
    } else {
      promises.push(this.processFile(data, files));
    }
    await Promise.all(promises);
    // const item = { name: 'file', content: files[0] };
    const formData = new FormData();
    for (const item of files) {
      if (typeof item.content === 'string') {
        // Json string
        formData.append(item.name, item.content);
      } else {
        // Blob
        formData.append(item.name, item.content!.data!, item.content!.contentRef.name);
      }
    }
    return formData;
  }

  protected prepareMultiPartForm(data: MultipartFormData[]): FormData {
    const formData = new FormData();

    for (const item of data) {
      if (typeof item.content === 'string') {
        // Json string
        formData.append(item.name, item.content);
      } else {
        // Blob
        formData.append(item.name, item.content.data!, item.content.contentRef.name);
      }
    }

    return formData;
  }
}
