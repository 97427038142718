import React, { FC } from 'react';
import { useStyles } from './MaintenanceCardStyles';
import houseAvatar from '../../assets/homePlaceholder.png';
import moment from 'moment';
import { getMaintenanceDetailTitle, getMaintenanceLocation } from './MaintenanceCardUtils';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface MaintenanceCardProps {
  maintenance: MaintenanceResponse;
  title?: string;
  onSelect?: (data: MaintenanceResponse) => void;
  showMRInfo?: boolean;
  status?: string;
}

const MaintenanceCard: FC<MaintenanceCardProps> = ({
  maintenance,
  title,
  onSelect,
  showMRInfo,
  status,
}) => {
  const classes = useStyles();
  let address1 = ``;
  let address2 = ``;
  const titleLabel = getMaintenanceDetailTitle(maintenance);

  if (maintenance.propertyDetails) {
    const propertyDetails = maintenance.propertyDetails.address
      ? maintenance.propertyDetails.address.split(',')
      : [];
    address1 = propertyDetails.length > 0 ? propertyDetails[0].replace(/undefined/g, '') : '';
    address2 = propertyDetails.length > 1 ? propertyDetails[1] : '';
  }
  const receviedDate =
    maintenance.audit && maintenance.audit.createdDate
      ? moment(maintenance.audit.createdDate).format('DD/MM/YYYY')
      : '';
  return (
    <div
      className={classes.propertyContainer}
      onClick={() => {
        if (onSelect) {
          onSelect(maintenance);
        }
      }}
    >
      <div className={classes.details}>
        <div className={classes.topRow}>
          <div className={classes.titleContainer}>
            <div className={classes.address}>
              {title
                ? title
                : `${address1.length > 30 ? `${address1.substring(0, 30)}...` : address1}`}
            </div>
            <div className={classes.address2}>
              {title
                ? `${address1}, ${address2}`
                : `${
                    address2.length > 30
                      ? `${address2.trim().substring(0, 30)}...`
                      : address2.trim()
                  }`}
            </div>
          </div>
        </div>

        {showMRInfo && (
          <div className={classes.maintenanceContainer}>
            <div className={classes.maintenanceRow}>
              <div className={classes.maintenanceKey}>Location: </div>
              <div className={classes.maintenanceValue}>
                {getMaintenanceLocation(maintenance.location)}
              </div>
            </div>
            <div className={classes.maintenanceRow}>
              <div className={classes.maintenanceKey}>{titleLabel}</div>
              <div className={classes.maintenanceValue}>{maintenance.details.title}</div>
            </div>
            <div className={classes.maintenanceRow}>
              <div className={classes.maintenanceKey}>Date received:</div>
              <div className={classes.maintenanceValue}>{receviedDate}</div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.rightContainer}>
        <img src={houseAvatar} className={classes.house} />
        {showMRInfo && status && (
          <div className={classNameGenerator([classes.statusContainer, classes.completeLabel])}>
            <div className={classes.statusTextBlack}>{status}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MaintenanceCard;
