/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    offerContainer: {
      marginRight: theme.units.spacing * 4,
      borderRadius: 12,
      overflow: 'hidden',
      borderWidth: 1,
      borderColor: theme.colors.borderColorLight,
      minHeight: 360,
      display: 'flex',
      flexDirection: 'column',
    },
    imageContainer: {
      borderRadius: 8,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'hidden',
      // backgroundColor: 'red',
      height: 180,
      width: '100%',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    textContainer: {
      flex: 1,
      height: '100%',
      padding: 12,
      background: `linear-gradient(45deg, ${theme.colors.gradient1}, ${theme.colors.gradient2})`,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
    },
    description: {
      marginTop: 8,
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.light,
      width: '85%',
    },
    arrow: {
      width: 22,
      height: 22,
      opacity: 0.5,
    },
    button: {
      marginTop: 'auto',
      padding: 6,
      backgroundColor: theme.colors.light,
      alignSelf: 'flex-start',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 6,
      width: 'max-content',
    },
    buttonText: {
      color: theme.colors.actionButtonSecondary,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
    },
  })();
};
