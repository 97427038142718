/* eslint-disable import/prefer-default-export */
export const LABELS = {
  summary: 'Application Summary',
  rent: 'Rent offer',
  income: 'Income %',
  occupants: 'Occupants',
  pets: 'Pets',
  move: 'Move in',
  duration: 'Duration',
  noApplicationReview: 'No applications to review!',
  noApplicationSubtext: 'Take a break, you are all sorted!',
  affordability: 'Affordability',
  moveInDate: 'Move in date',
  apply: 'Apply',
  clearAll: 'Clear',
  sortBy: 'Sort by',
  noFiltersApplied: 'No filters applied',
  rentOffer: 'Rent offer',
};

export enum AffordabilityFilter {
  HighToLow = 'HIGH_TO_LOW',
  LowToHigh = 'LOW_TO_HIGH',
}

export enum MoveInDateFilter {
  EarliestToLatest = 'EARLIEST_TO_LATEST',
  LatestToEarliest = 'LATEST_TO_EARLIEST',
}

export enum RentOfferFilter {
  HighToLow = 'RENT_OFFER_HIGH_TO_LOW',
  LowToHigh = 'RENT_OFFER_LOW_TO_HIGH',
}

export const affordabilityFilters = [
  {
    label: 'High to low',
    value: AffordabilityFilter.HighToLow,
  },
  {
    label: 'Low to high',
    value: AffordabilityFilter.LowToHigh,
  },
];

export const moveInFilters = [
  {
    label: 'Earliest to latest',
    value: MoveInDateFilter.EarliestToLatest,
  },
  {
    label: 'Latest to earliest',
    value: MoveInDateFilter.LatestToEarliest,
  },
];

export const rentOfferFilters = [
  {
    label: 'High to low',
    value: RentOfferFilter.HighToLow,
  },
  {
    label: 'Low to high',
    value: RentOfferFilter.LowToHigh,
  },
];

export type ApplicationFilter = AffordabilityFilter | MoveInDateFilter | RentOfferFilter;

export interface Filter {
  label: string;
  value: ApplicationFilter;
}

export interface Positions {
  [id: string]: number;
}
