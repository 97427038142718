export interface EquifaxRequest {
  resultKey: string | null;
  ntdRequest: NTDRequest;
}

export interface EquifaxRetryPaymentRequest {
  resultKey: string;
  userRefId: string;
}

export enum Verified {
  NOT_VERIFIED,
  VERIFIED,
  EXPIRED,
}

export interface NTDRequest {
  profile: {
    branchCode: string | null | undefined;
    refId: string | null | undefined;
    rentalAddress: {
      address: string;
      addressComponent: {
        postcode: string | null | undefined;
        state: string | null | undefined;
        streetName: string | null | undefined;
        streetNumber: string | null | undefined;
        suburb: string | null | undefined;
        unitNumber: string | null | undefined;
        country: string | null | undefined;
      };
    };
    agentId: string | null;
    currentAddress: {
      address: string;
      addressComponent: {
        postcode: string | null | undefined;
        state: string | null | undefined;
        streetName: string | null | undefined;
        streetNumber: string | null | undefined;
        suburb: string | null | undefined;
        unitNumber: string | null | undefined;
        country: string | null | undefined;
      };
    };
    updateRequired: boolean;
  };
}

export interface EquifaxResponse {
  userRefId?: string; // supplied by the ui
  status: EquifaxStatus | null;
  data: EquifaxDataPayload | null;
  pdfLink: string | null;
  error: string | Error | null;
  payment?: EquifaxPayment | null;
}

export interface EquifaxPayment {
  status: EquifaxPaymentStatus;
  bankTransactionId: number | null;
  transactionDate: string | Date;
  rejectReason: string | null;
}

export enum EquifaxPaymentStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export interface EquifaxResponseDictionary {
  [userRefId: string]: EquifaxResponse;
}

export enum EquifaxStatus {
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  ARCHIVED = 'ARCHIVED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export interface EquifaxDataPayload {
  clientReference: null | string;
  reportId: string | null;
  reportDateTime: string | Date | null;
  reportContentType: string | null;
  consumerTenantCheckBody: ConsumerTenantCheckBodyPayload;
}

export interface ConsumerTenantCheckBodyPayload {
  tenantIdentityInformation: TenantIdentityInformationPayload;
  currentAddress: Address;
  rentalPropertyAddress: Address;
  summary: SummaryPayload;
}
export interface TenantIdentityInformationPayload {
  tenantId: number | null;
  individualName: IndividualNamePayload;
  driversLicenceNumber: null | string;
  passportNumber: null | string;
  dateOfBirth: null | string;
}
export interface IndividualNamePayload {
  familyName: string | null;
  firstGivenName: string | null;
  otherGivenName: string | null;
}

export interface Address {
  unitNumber: string | null;
  streetNumber: string | null;
  streetName: string | null;
  streetType: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  country: string | null;
}

export interface SummaryPayload {
  identityVerificationResult: string | null;
  identityVerificationMessage: string | null;
  ntdRecordCount: string | null;
  bankruptyRecordCount: string | null;
  courtRecordCount: string | null;
  directorshipProprietorshipCount: string | null;
  disqualifiedDirectorshipCount: string | null;
}
