import React, { FC, useEffect, useState, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './RequestStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TagBarItem } from '../../../../components/tagBar/TagBarConstants';
import { useDashboardServices } from '../../../../helpers/ServiceInfoHelper';
import { MaintenanceResponse } from '../../../../models/landlord/LandlordProperty';
import { Property } from '../../../../models/property/property';
import { TenantRequest, Status } from '../../../../models/tenantRequest/Request';
import { LandlordActions } from '../../../../store/actions/LandlordActions';
import { RequestActions } from '../../../../store/actions/RequestActions';
import { RequestState } from '../../../../store/state/RequestState';
import { LABELS, Tab, initialTags } from './RequestConstants';
import LoginPrompt from '../../../../components/loginPrompt/LoginPrompt';
import requestEmptyIcon from '../../../../assets/applicationEmpty.png';
import TagBar from '../../../../components/tagBar/TagBar';
import { navigate } from '../../../../navigation/NavigationUtils';
import MaintenanceCard from '../../../../components/maintenanceCard/MaintenanceCard';
import Box from '../../../../components/box/Box';

interface RequestProps extends RouteComponentProps {
  requestState: RequestState;
  isGuest: boolean;
  getRequests: (data: TenantRequest) => void;
  setSelectedMaintenance: (data: MaintenanceResponse) => void;
}

const Request: FC<RequestProps> = ({
  requestState,
  isGuest,
  getRequests,
  setSelectedMaintenance,
}) => {
  const classes = useStyles();
  const [tags, setTags] = useState<TagBarItem[]>(initialTags(undefined));
  const { property } = useDashboardServices();
  const { requests, requestLoader, completedRequests, reviewRequests, scheduledRequests } =
    requestState;

  useEffect(() => {
    if (property !== undefined) {
      getRequestTab(property);
    }
  }, [tags, property]);

  const getRequestTab = (property: Property) => {
    const selectedTab = tags.findIndex((tab) => tab.active);
    switch (tags[selectedTab].value) {
      case Tab.Request:
        getRequests({
          status: Status.REQUESTED,
          propertyId: property.id,
        });
        break;
      case Tab.Review:
        getRequests({
          status: Status.REVIEW,
          propertyId: property.id,
        });
        break;
      case Tab.Scheduled:
        getRequests({
          status: Status.SCHEDULED,
          propertyId: property.id,
        });
        break;
      case Tab.Completed:
        getRequests({
          status: Status.COMPLETED,
          propertyId: property.id,
        });

        break;
      default:
        getRequests({
          status: Status.REQUESTED,
          propertyId: property.id,
        });
        break;
    }
  };

  const activeMaintenances = useMemo<MaintenanceResponse[]>(() => {
    const selectedTab = tags.findIndex((tab) => tab.active);

    switch (tags[selectedTab].value) {
      case Tab.Review:
        return reviewRequests;
      case Tab.Scheduled:
        return scheduledRequests;
      case Tab.Completed:
        return completedRequests;
      default:
        return requests;
    }
  }, [requests, reviewRequests, scheduledRequests, completedRequests, tags]);

  const getStatus = (maintenance: MaintenanceResponse) => {
    const privateWorks = maintenance.privateWorksDetails;
    const isPrivateWorks = !!(
      maintenance.privateWorksDetails && maintenance.privateWorksDetails.private
    );
    if (isPrivateWorks && privateWorks) {
      return privateWorks.workflowType === 'PACKAGE' ? 'Package' : 'Service';
    } else {
      return 'Maintenance';
    }
  };
  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        {isGuest ? (
          <div className={classes.content}>
            <LoginPrompt text={LABELS.LOGIN} />
          </div>
        ) : (
          <>
            <TagBar tags={tags} handlePress={setTags} />
            {requestLoader ? (
              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                {[...Array(3).fill(null)].map((service, idx) => (
                  <Box loading parentStyles={classes.loadingBox} />
                ))}
              </div>
            ) : (
              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                {activeMaintenances && activeMaintenances.length > 0 ? (
                  activeMaintenances.map((maintenance, index) => (
                    <div style={{ marginTop: 12 }} key={index}>
                      <MaintenanceCard
                        key={`${maintenance.id}_${index}`}
                        maintenance={{
                          ...maintenance,
                        }}
                        title={maintenance.details.title}
                        showMRInfo
                        onSelect={(data: MaintenanceResponse) => {
                          const selectedTab = tags.find((tab) => tab.active);
                          if (selectedTab) {
                            setSelectedMaintenance({
                              ...{
                                ...data,
                              },
                              isTenantRequested: selectedTab.value === Tab.Request,
                              isTenantReviewed: selectedTab.value === Tab.Review,
                              isTenantScheduled: selectedTab.value === Tab.Scheduled,
                              isTenantCompleted: selectedTab.value === Tab.Completed,
                            });
                            navigate('tenantRequestInfo');
                          }
                        }}
                        status={getStatus(maintenance)}
                      />
                    </div>
                  ))
                ) : (
                  <div className={classes.contentBox}>
                    <div className={classes.noMaintenanceView}>
                      <img src={requestEmptyIcon} className={classes.noMaintenanceImage} />
                      <div className={classes.noMaintenanceText}>{LABELS.noRequestReview}</div>
                      <div className={classes.noMaintenanceSubText}>
                        {LABELS.noRequestReviewSubText}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  requestState: state.request,
  isGuest: state.authState.guest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRequests: (data: TenantRequest) => dispatch(RequestActions.getRequests(data)),
  setSelectedMaintenance: (data: MaintenanceResponse) =>
    dispatch(LandlordActions.setSelectedMaintenance(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Request));
