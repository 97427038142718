import {
  InvoiceDownloadRequest,
  InvoiceRequest,
  InvoiceResponse,
} from '../../models/invoices/invoices';
import {
  JobDetailResponse,
  LocationData,
  MaintenanceResponse,
  MaintenanceSummaryRequest,
} from '../../models/maintenance/maintenance';
import { CheckBillData } from '../../models/services/checkBill';
import {
  GenericServiceSummaryRequest,
  GenericServiceSummaryResponse,
} from '../../models/services/genericServiceSummary';
import { RentService } from '../../models/services/rentService';
import {
  ActiveServicesPaymentSummaryRequest,
  ActiveServicesPaymentSummaryResponse,
  ResetServicePlanRequest,
  ServiceSummaryRequest,
  ServiceSummaryResponse,
} from '../../models/services/services';
import { StoreAction } from '../StoreHelper';

export enum ServiceActionTypes {
  GET_RENT_SERVICE = 'GET_RENT_SERVICE',
  GET_RENT_SERVICE_REQUEST = 'GET_RENT_SERVICE_REQUEST',
  GET_RENT_SERVICE_SUCCESS = 'GET_RENT_SERVICE_SUCCESS',
  GET_RENT_SERVICE_ERROR = 'GET_RENT_SERVICE_ERROR',

  GET_OTHER_SERVICE_REQUEST = 'GET_OTHER_SERVICE_REQUEST',
  GET_OTHER_SERVICE_SUCCESS = 'GET_OTHER_SERVICE_SUCCESS',
  GET_OTHER_SERVICE_ERROR = 'GET_OTHER_SERVICE_ERROR',

  DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST',
  DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR',

  FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST',
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR',

  DOWNLOAD_INVOICE_REQUEST = 'DOWNLOAD_INVOICE_REQUEST',
  DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS',
  DOWNLOAD_INVOICE_ERROR = 'DOWNLOAD_INVOICE_ERROR',

  GET_SERVICE_SUMMARY_REQUEST = 'GET_SERVICE_SUMMARY_REQUEST',
  GET_SERVICE_SUMMARY_SUCCESS = 'GET_SERVICE_SUMMARY_SUCCESS',
  GET_SERVICE_SUMMARY_ERROR = 'GET_SERVICE_SUMMARY_ERROR',

  GET_GENERIC_SERVICE_SUMMARY_REQUEST = 'GET_GENERIC_SERVICE_SUMMARY_REQUEST',
  GET_GENERIC_SERVICE_SUMMARY_SUCCESS = 'GET_GENERIC_SERVICE_SUMMARY_SUCCESS',
  GET_GENERIC_SERVICE_SUMMARY_ERROR = 'GET_GENERIC_SERVICE_SUMMARY_ERROR',

  GET_LOCATION_DATA = 'GET_LOCATION_DATA',
  GET_LOCATION_DATA_REQUEST = 'GET_LOCATION_DATA_REQUEST',
  GET_LOCATION_DATA_SUCCESS = 'GET_LOCATION_DATA_SUCCESS',
  GET_LOCATION_DATA_ERROR = 'GET_LOCATION_DATA_ERROR',

  SET_MAINTENANCE_SUMMARY_REQUEST = 'SET_MAINTENANCE_SUMMARY_REQUEST',

  GET_MAINTENANCE_SUMMARY_REQUEST = 'GET_MAINTENANCE_SUMMARY_REQUEST',
  GET_MAINTENANCE_SUMMARY_SUCCESS = 'GET_MAINTENANCE_SUMMARY_SUCCESS',
  GET_MAINTENANCE_SUMMARY_ERROR = 'GET_MAINTENANCE_SUMMARY_ERROR',

  POST_CHECK_BILL_REQUEST = 'POST_CHECK_BILL_REQUEST',
  POST_CHECK_BILL_SUCCESS = 'POST_CHECK_BILL_SUCCESS',
  POST_CHECK_BILL_ERROR = 'POST_CHECK_BILL_ERROR',

  RESET_CHECK_BILL = 'RESET_CHECK_BILL',

  RESET_SERVICE_PLAN_REQUEST = 'RESET_SERVICE_PLAN_REQUEST',
  RESET_SERVICE_PLAN_SUCCESS = 'RESET_SERVICE_PLAN_SUCCESS',
  RESET_SERVICE_PLAN_ERROR = 'RESET_SERVICE_PLAN_ERROR',

  GET_ACTIVE_SERVICES_PAYMENT_SUMMARY = 'GET_ACTIVE_SERVICES_PAYMENT_SUMMARY',
  GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_REQUEST = 'GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_REQUEST',
  GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_SUCCESS = 'GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_SUCCESS',
  GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_ERROR = 'GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_ERROR',
}

export type ServiceActionPayload =
  | RentService
  | InvoiceDownloadRequest
  | LocationData
  | InvoiceRequest
  | InvoiceResponse[]
  | Error
  | string
  | null
  | ServiceSummaryRequest
  | MaintenanceResponse
  | MaintenanceSummaryRequest
  | JobDetailResponse
  | ServiceSummaryResponse
  | GenericServiceSummaryRequest
  | GenericServiceSummaryResponse
  | ActiveServicesPaymentSummaryRequest
  | ActiveServicesPaymentSummaryResponse[]
  | CheckBillData;

export type ServiceAction = StoreAction<ServiceActionTypes, ServiceActionPayload>;

export class ServiceActions {
  public static getGenericServiceSummaryRequest(
    serviceAccountId: GenericServiceSummaryRequest,
  ): ServiceAction {
    return {
      type: ServiceActionTypes.GET_GENERIC_SERVICE_SUMMARY_REQUEST,
      data: serviceAccountId,
    };
  }

  public static getLocationData(): ServiceAction {
    return {
      type: ServiceActionTypes.GET_LOCATION_DATA_REQUEST,
      data: null,
    };
  }

  public static getLocationDataSuccess(data: LocationData): ServiceAction {
    return {
      type: ServiceActionTypes.GET_LOCATION_DATA_SUCCESS,
      data: data,
    };
  }

  public static getGenericServiceSummarySuccess(
    data: GenericServiceSummaryResponse,
  ): ServiceAction {
    return {
      type: ServiceActionTypes.GET_GENERIC_SERVICE_SUMMARY_SUCCESS,
      data: data,
    };
  }

  public static getLocationDataError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_LOCATION_DATA_ERROR,
      data: error,
    };
  }

  public static getGenericServiceSummaryError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_GENERIC_SERVICE_SUMMARY_ERROR,
      data: error,
    };
  }

  public static setMaintenanceSummaryRequest(): ServiceAction {
    return {
      type: ServiceActionTypes.SET_MAINTENANCE_SUMMARY_REQUEST,
      data: '',
    };
  }

  public static getMaintenanceSummaryRequest(
    maintenanceRequestId: MaintenanceSummaryRequest,
  ): ServiceAction {
    return {
      type: ServiceActionTypes.GET_MAINTENANCE_SUMMARY_REQUEST,
      data: maintenanceRequestId,
    };
  }

  public static getMaintenanceSummarySuccess(data: JobDetailResponse): ServiceAction {
    return {
      type: ServiceActionTypes.GET_MAINTENANCE_SUMMARY_SUCCESS,
      data: data,
    };
  }

  public static getMaintenanceSummaryError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_MAINTENANCE_SUMMARY_ERROR,
      data: error,
    };
  }

  public static getRentServiceRequest(summaryAccountId: string): ServiceAction {
    return {
      type: ServiceActionTypes.GET_RENT_SERVICE_REQUEST,
      data: summaryAccountId,
    };
  }

  public static getRentServiceSuccess(rentData: RentService): ServiceAction {
    return {
      type: ServiceActionTypes.GET_RENT_SERVICE_SUCCESS,
      data: rentData,
    };
  }

  public static getRentServiceError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_RENT_SERVICE_ERROR,
      data: error,
    };
  }

  public static getOtherServiceRequest(summaryAccountId: string): ServiceAction {
    return {
      type: ServiceActionTypes.GET_OTHER_SERVICE_REQUEST,
      data: summaryAccountId,
    };
  }

  public static getOtherServiceSuccess(serviceData: RentService): ServiceAction {
    return {
      type: ServiceActionTypes.GET_OTHER_SERVICE_SUCCESS,
      data: serviceData,
    };
  }

  public static getOtherServiceError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_OTHER_SERVICE_ERROR,
      data: error,
    };
  }

  public static downloadDocumentRequest(documentId: string): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_DOCUMENT_REQUEST,
      data: documentId,
    };
  }

  public static downloadDocumentSuccess(): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
      data: null,
    };
  }

  public static downloadDocumentError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_DOCUMENT_ERROR,
      data: error,
    };
  }

  public static fetchInvoicesRequest(request: InvoiceRequest): ServiceAction {
    return {
      type: ServiceActionTypes.FETCH_INVOICES_REQUEST,
      data: request,
    };
  }

  public static fetchInvoicesSuccess(invoices: InvoiceResponse[]): ServiceAction {
    return {
      type: ServiceActionTypes.FETCH_INVOICES_SUCCESS,
      data: invoices,
    };
  }

  public static fetchInvoicesError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.FETCH_INVOICES_ERROR,
      data: error,
    };
  }

  public static downloadInvoiceRequest(data: InvoiceDownloadRequest): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_INVOICE_REQUEST,
      data,
    };
  }

  public static downloadInvoiceSuccess(): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_INVOICE_SUCCESS,
      data: null,
    };
  }

  public static downloadInvoiceError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.DOWNLOAD_INVOICE_ERROR,
      data: error,
    };
  }

  public static getServiceSummaryRequest(serviceAccountId: string): ServiceAction {
    return {
      type: ServiceActionTypes.GET_SERVICE_SUMMARY_REQUEST,
      data: serviceAccountId,
    };
  }

  public static getServiceSummarySuccess(data: ServiceSummaryResponse): ServiceAction {
    return {
      type: ServiceActionTypes.GET_SERVICE_SUMMARY_SUCCESS,
      data: data,
    };
  }

  public static getServiceSummaryError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_SERVICE_SUMMARY_ERROR,
      data: error,
    };
  }

  public static postCheckBillRequest(data: CheckBillData): ServiceAction {
    return {
      type: ServiceActionTypes.POST_CHECK_BILL_REQUEST,
      data: data,
    };
  }

  public static postCheckBillSuccess(): ServiceAction {
    return {
      type: ServiceActionTypes.POST_CHECK_BILL_SUCCESS,
      data: null,
    };
  }

  public static postCheckBillError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.POST_CHECK_BILL_ERROR,
      data: error,
    };
  }

  public static resetCheckBill(): ServiceAction {
    return {
      type: ServiceActionTypes.RESET_CHECK_BILL,
      data: null,
    };
  }

  public static resetServicePlanRequest(data: ResetServicePlanRequest): ServiceAction {
    return {
      type: ServiceActionTypes.RESET_SERVICE_PLAN_REQUEST,
      data: data,
    };
  }

  public static resetServicePlanSuccess(): ServiceAction {
    return {
      type: ServiceActionTypes.RESET_SERVICE_PLAN_SUCCESS,
      data: null,
    };
  }

  public static resetServicePlanError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.RESET_SERVICE_PLAN_ERROR,
      data: error,
    };
  }

  public static getActiveServicesPaymentSummaryRequest(
    data: ActiveServicesPaymentSummaryRequest,
  ): ServiceAction {
    return {
      type: ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_REQUEST,
      data,
    };
  }

  public static getActiveServicesPaymentSummarySuccess(
    data: ActiveServicesPaymentSummaryResponse[],
  ): ServiceAction {
    return {
      type: ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_SUCCESS,
      data,
    };
  }

  public static getActiveServicesPaymentSummaryError(error: Error): ServiceAction {
    return {
      type: ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_ERROR,
      data: error,
    };
  }
}
