import { getCurrentTheme } from '../../../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../../../helpers/GetFonts';
import { SIZES } from '../../../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    seperator: {
      height: 1,
      margin: 4,
      marginBottom: 12,
      background: '#eee',
    },
    headerText2: {
      fontSize: 15,
      marginTop: 5,
      marginBottom: 10,
    },
    gtwLight: {
      fontFamily: 'GT Walsheim Light',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    toggleContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      height: 40,
      borderRadius: 2,
      margin: '10px 0px',
      boxShadow: 'none',
      borderRight: `1px solid ${theme.colors.buttonPrimary}`,
    },
    toggleButton: {
      flex: 1,
      color: theme.colors.buttonPrimary,
      borderTop: `1px solid ${theme.colors.buttonPrimary}`,
      borderBottom: `1px solid ${theme.colors.buttonPrimary}`,
      borderLeft: `1px solid ${theme.colors.buttonPrimary}`,
      fontSize: 15,
      height: '100%',
    },
    toggleButtonActive: {
      flex: 1,
      background: `${theme.colors.buttonPrimary} !important`,
      color: `#fff !important`,
      height: '100%',
    },
    footer: {
      marginTop: 10,
    },
    infoText: {
      marginTop: theme.units.spacing * 2,
      fontWeight: getFonts('Regular'),
      color: theme.colors.black38,
      fontSize: 14,
    },
    questionContainer: {
      marginBottom: theme.units.spacing * 12,
    },
  })();
};
