import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
  pageTitleContainer,
  secondaryContent,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    secondaryContainer: {
      ...secondaryContent,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        marginLeft: 0,
        marginTop: theme.units.spacing * 12,
        maxWidth: '100%',
      },
    },
    boxContainer: {
      marginBottom: theme.units.spacing * 4,
    },
    titleContainer: {
      ...pageTitleContainer,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxxl,
      fontWeight: getFonts('Medium'),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    submitSubtitle: {
      marginTop: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 12,
    },
    contentTitleContainer: {
      marginBottom: theme.units.spacing * 4,
    },
    bottomContainer: {
      marginTop: theme.units.spacing * 16,
    },
    taskGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    offerContainer: {
      position: 'relative',
      borderRadius: theme.units.borderRadius,
      minHeight: 140,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      cursor: 'pointer',
      marginBottom: theme.units.spacing * 4,
    },
    offerTitle: {
      height: 70,
      objectFit: 'contain',
      objectPosition: 'left',
      marginBottom: theme.units.spacing * 6,
      marginTop: theme.units.spacing * 4,
      marginLeft: theme.units.spacing * 4,
    },
    offerSubtitle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      marginBottom: theme.units.spacing * 5,
      marginLeft: theme.units.spacing * 4,
    },
    offerButton: {
      marginLeft: theme.units.spacing * 4,
      minWidth: 60,
      marginBottom: theme.units.spacing * 4,
    },
    helloFresh: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 200,
      objectFit: 'contain',
      objectPosition: 'top right',
    },
    blys: {
      position: 'absolute',
      top: theme.units.spacing * 4,
      right: theme.units.spacing * 4,
      width: 100,
      objectFit: 'contain',
    },
    cartSection: {
      marginBottom: theme.units.spacing * 8,
    },
    cartItem: {
      display: 'block',
      backgroundColor: theme.colors.lighterGrey,
      borderRadius: theme.units.borderRadius,
    },
  })();
};
