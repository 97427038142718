import { History } from 'history';
import { routes } from '../../../../Routes';
import { MatchParams } from './AddCardConstants';
import cardLogo from '../../../../assets/credit-card-24-px.svg';
import visaIcon from '../../../../assets/group-82.svg';
import masterCardIcon from '../../../../assets/mc-symbol.svg';
import { ValidMessages, sendMessageToApp } from '../../../../helpers/MessageHelper';
import { PaymentServiceType } from '../../selectPayment/PaymentDetailsConstants';
import { navigate } from '../../../../navigation/NavigationUtils';
import { ToastMessage } from '../../../../helpers/ToastMessage';

export const handlePaymentSuccess = () => {
  ToastMessage.showMessage('Success', '', 'success', 3000);
  navigate('home');
};

export const renderCardIcon = (cardType: string) => {
  switch (cardType) {
    case 'visa':
      return visaIcon;
    case 'mastercard':
      return masterCardIcon;
    default:
      return cardLogo;
  }
};
