import { ThemeKey } from '../../theme/Theme';
import { StoreAction } from '../StoreHelper';

export enum ThemeActionTypes {
  SET_THEME = 'SET_THEME',
}

export type ThemeActionPayload = ThemeKey;

export type ThemeAction = StoreAction<ThemeActionTypes, ThemeActionPayload>;

export class ThemeActions {
  public static setTheme(data: ThemeKey): ThemeAction {
    return {
      type: ThemeActionTypes.SET_THEME,
      data,
    };
  }
}
