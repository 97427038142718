export const LABELS = {
  TITLE: 'Your cart',
  ESTIMATED_MONTHLY: 'Estimated monthly cost',
  TOTAL_UPFRONT: 'Total upfront cost',
  CHECKOUT: 'Checkout',
  GET_QUOTE: 'Get a free quote',
  NEED_SUPPORT: 'Need support?',
  CHAT: 'Chat now',
  EMPTY_CART_TITLE: 'Your cart is empty',
  EMPTY_CART_TEXT: 'Add services from the marketplace',
  ESTIMATED: 'Show estimated prices',
  SHOW: 'Show details',
  HIDE: 'Hide details',
  CLEAR_ALL: 'Clear all',
  SERVICES: (n: number) => `${n} service${n === 1 ? '' : 's'}`,
  REFERRALS: (n: number) => `${n} referral${n === 1 ? '' : 's'}`,
};
