/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import {
  AddressComponents,
  OnboardingPropertyDetails,
  PropertyClass,
  PropertyType,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/property';
import { useStyles } from './SummaryStyles';
import tick from '../../../../assets/tick.png';
import { LABELS } from './SummaryConstants';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { createAddressString } from '../../PropertyOnboardingUtils';

interface SummaryProps {
  address: AddressComponents;
}

const Summary: FC<SummaryProps> = ({ address }) => {
  const classes = useStyles();
  const addressString = createAddressString(address);

  return (
    <div className={classes.contentContainer}>
      <div className={classes.summaryCard}>
        <div className={classes.address}>{addressString.split(',')[0]}</div>
        <div className={classes.address2}>
          {`${addressString.split(',')[1]}, ${addressString.split(',')[2]}`}
        </div>
      </div>
    </div>
  );
};

export default Summary;
