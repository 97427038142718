import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material';
import { useStyles } from './UserConfirmationDialogStyles';
import Cancel from '../../assets/cancelIcon.svg';
import Button from '../button/Button';

interface UserConfirmationDialogProps {
  title: string;
  content: JSX.Element;
  primaryButtonTitle?: string | undefined | null;
  secondaryButtonTitle?: string | undefined | null;
  isOpen: boolean;
  onClose: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
}

const UserConfirmationDialog: FC<UserConfirmationDialogProps> = ({
  isOpen,
  title,
  content,
  primaryButtonTitle,
  secondaryButtonTitle,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogActions className={classes.cancelButtonContainer}>
        <IconButton onClick={() => onClose()}>
          <img src={Cancel} alt="Close Dialog" />
        </IconButton>
      </DialogActions>
      {title && (
        <DialogTitle className={classes.titleContainerStyle}>
          <Typography variant="h6" className={classes.titleStyle}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      {content && (
        <DialogContent>
          <Typography variant="body2" className={classes.contentStyle}>
            {content}
          </Typography>
        </DialogContent>
      )}
      {primaryButtonTitle && !secondaryButtonTitle && (
        <DialogActions className={classes.buttonContainer}>
          <Button parentStyles={classes.buttonStyle} onPress={() => onPrimaryClick()}>
            {primaryButtonTitle}
          </Button>
        </DialogActions>
      )}
      {primaryButtonTitle && secondaryButtonTitle && onSecondaryClick && (
        <DialogActions className={classes.buttonsPosition}>
          {secondaryButtonTitle && (
            <Button parentStyles={classes.buttonStyle} onPress={() => onSecondaryClick()} outline>
              {secondaryButtonTitle}
            </Button>
          )}
          {primaryButtonTitle && (
            <Button parentStyles={classes.buttonStyle} onPress={() => onPrimaryClick()}>
              {primaryButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UserConfirmationDialog;
