import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './WalletStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WalletActions, WalletActionTypes } from '../../../../store/actions/WalletActions';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { WalletProfileResponse } from '../../../../models/wallet/wallet';
import { LABELS } from './WalletConstants';
import { navigate } from '../../../../navigation/NavigationUtils';
import LoginPrompt from '../../../../components/loginPrompt/LoginPrompt';
import rightArrowIcon from '../../../../assets/navigation/accordian.png';
import paymentMethodsIcon from '../../../../assets/card.png';
import transactionsIcon from '../../../../assets/transactions.png';
import promotionsIcon from '../../../../assets/promotion.png';
import referIcon from '../../../../assets/refer.png';

interface WalletProps extends RouteComponentProps {
  walletProfile: WalletProfileResponse;
  error: Error;
  isGuest: boolean;
  getWalletProfile: () => void;
}

const Wallet: FC<WalletProps> = ({ walletProfile, isGuest, getWalletProfile, error }) => {
  const classes = useStyles();

  useEffect(() => {
    getWalletProfile();
  }, []);

  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        {isGuest ? (
          <LoginPrompt text={LABELS.LOGIN} />
        ) : (
          <>
            {/* {error ? (
              <div className={classes.balanceCard}>
                <div className={classes.notAvailableText}>
                  Wallet not available, try again later
                </div>
              </div>
            ) : (
              <div
                className={classes.balanceCard}
                onClick={() => navigate('walletBreakDownScreen')}
              >
                {
                  walletProfile ? (
                    <>
                      <div className={classes.splitContainer}>
                        <div className={classes.breakdownContainer}>
                          <div className={classes.breakdownAmount}>
                            ${walletProfile.balance.cash.toFixed(2)}
                          </div>
                          <div className={classes.breakdownSubtext}>Cash Balance</div>
                        </div>

                        <div className={classes.line} />

                        <div className={classes.breakdownContainer}>
                          <div className={classes.breakdownAmount}>
                            ${walletProfile.balance.promotions.toFixed(2)}
                          </div>
                          <div className={classes.breakdownSubtext}>Promo Credits</div>
                        </div>
                      </div>
                      <div className={classes.availableContainer}>
                        <div className={classes.balanceSubtext}>Spend</div>
                        <img src={rightArrowIcon} className={classes.iconStyleArrow} />
                      </div>
                    </>
                  ) : null
                  // <ActivityIndicator size="large" />
                }
              </div>
            )} */}
            <div className={classes.listItemsContainer}>
              {/* <div className={classes.listItem} onClick={() => navigate('redeemCodeSelectScreen')}>
                <img src={promotionsIcon} className={classes.iconStyle} />
                <div className={classes.listItemText}>Redeem a code</div>
                <img src={rightArrowIcon} className={classes.iconStyleArrow} />
              </div>

              {walletProfile && (
                <div
                  className={classes.listItem}
                  onClick={() =>
                    navigate('referAFriendScreen', {
                      code: walletProfile.referralCode,
                    })
                  }
                >
                  <img src={referIcon} className={classes.iconStyle} />
                  <div className={classes.listItemText}>Refer a friend</div>
                  <img src={rightArrowIcon} className={classes.iconStyleArrow} />
                </div>
              )}

              <div className={classes.listItem} onClick={() => navigate('transactionScreen')}>
                <img src={transactionsIcon} className={classes.iconStyle} />
                <div className={classes.listItemText}>Transactions</div>
                <img src={rightArrowIcon} className={classes.iconStyleArrow} />
              </div> */}

              <div
                className={classes.listItem}
                onClick={() => {
                  navigate('viewPaymentMethods', { serviceType: 'wallet' });
                }}
              >
                <img src={paymentMethodsIcon} className={classes.iconStyle} />
                <div className={classes.listItemText}>Payment methods</div>
                <img src={rightArrowIcon} className={classes.iconStyleArrow} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const error = errorMessageSelector([
  WalletActionTypes.GET_WALLET_PROFILE,
  WalletActionTypes.GET_WALLET_BREAKDOWN,
]);

const mapStateToProps = (state: ApplicationState) => ({
  walletProfile: state.wallet.walletProfile,
  error: error(state),
  isGuest: state.authState.guest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getWalletProfile: () => dispatch(WalletActions.getWalletProfileRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wallet));
