import { User } from '../user/user';

export interface CreditCardRequest {
  type: string;
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  email: string;
}

export interface BankAccountRequest {
  type: string;
  accountName: string;
  accountNumber: string;
  bsbNumber: string;
  email: string;
}

export interface GetPaymentAuthKeyRequest {
  data: CreditCardRequest | BankAccountRequest;
  onSuccess: (token: string) => void;
  onError: (error: Error) => void;
}

export interface GetPaymentAuthKeyResponse {
  authKey: string;
  status: number;
  readonly businessCode?: string;
}

export interface PaymentToken {
  resultKey: string;
}

export class PaymentDetails {
  refId: string;
  paymentMethod: string;
  cardType: string;
  cardName: string;
  cardNumber: string;
  cardExpiryYear: string;
  cardExpiryMonth: string;
  accountName: string;
  accountNumber: string;
  bsb: string;
}

export interface AddPaymentTagsResponse {
  payloadVersion?: string;
  response: boolean;
  success: boolean;
}

export interface AddTagStatus {
  userId: number | number;
  platformPropertyId: number;
  tag: string;
  unTag: string;
  transactionRunScheduleIds: number[];
}

export interface FastPayPayment {
  amount: string;
  clientCode: string;
  customerNumber: string;
  fastpayReferenceNumber: string;
  paymentDate: string;
  paymentTags: string;
  platformPropertyId: number;
  requestedBy?: string;
  serviceReferenceNumber: string;
  serviceType: string;
  transactionRunScheduleId: number;
  userAddressId: number;
  userId: number;
  userServiceAccountId: number;
}

export interface GetFastPayment {
  userId: number | number;
}

export interface GetFastPaymentRequest {
  data: GetFastPayment;
  onFailure?: () => void;
}

export interface PayID {
  id: string;
  label: string;
}
export interface PayIdInformation {
  title: string;
  body: string[];
}

export interface Reference {
  label: string;
  id: string;
}
export interface SortedPayIdDetails {
  footer: string;
  payId: PayID;
  payIdInformation: PayIdInformation;
  reference: Reference;
  title: string;
  userId: number;
}
export interface PaymentDetailsBase {
  notificationBody: string;
  notificationTitle: string;
  payments: FastPayPayment[];
  sortedPayId: string;
  sortedPayIdDetails: SortedPayIdDetails;
  timeStamp: string;
}

export interface GetFastPayResponse {
  errors: [];
  payloadVersion?: string;
  response: PaymentDetailsBase;
  success: boolean;
}

export interface PostPaymentTokenRequest {
  data: PaymentToken;
  onSuccess: (response: PaymentDetails) => void;
}

export class GetPaymentTypeResponse {
  public readonly paymentMethod: string;
  public readonly transactionFeeType: string;
  public readonly transactionFeeValue: number;
  public readonly internationalFeeValue: number;
}

export type PaymentMethod = 'BANK_ACCOUNT' | 'CREDIT_CARD' | 'DEBIT_CARD';

export class PostPaymentUUIDRequest {
  public readonly paymentMethod: PaymentMethod;
  public readonly paymentMethodUuid: string;
}

export class PostPaymentUUIDResponse {
  public readonly id: string;
  public readonly user: User;
  public readonly paymentMethodResponse: PaymentMethod;
  public readonly paymentMethodUUID: string;
}

export class GetPaymentMethodsResponse {
  refId: string;
  paymentMethod: string;
  cardType: string | null;
  cardName: string | null;
  cardNumber: string | null;
  cardExpiryYear: string | null;
  cardExpiryMonth: string | null;
  accountName: string | null;
  accountNumber: string | null;
  bsb: string | null;
}

export interface MaskDetails {
  paymentDetails: string;
  logo: any;
}

export interface GetPaymentAuthKey {
  readonly authKey: string;
  readonly status: number;
  readonly businessCode?: string;
}

export interface CreditCardRequest {
  type: string;
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  email: string;
}

export interface BankAccountRequest {
  type: string;
  accountName: string;
  accountNumber: string;
  bsbNumber: string;
  email: string;
}

export interface QuickStreamCreditCardRequest {
  accountType: string;
  cardHolderName: string;
  cardNumber: string;
  expiryDateMonth: string;
  expiryDateYear: string;
  cvn?: string | null;
  supplierBusinessCode: string;
}

export interface QuickStreamBankAccountRequest {
  accountType: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
  supplierBusinessCode: string;
}

export interface GetRefIdResponse {
  accountToken: string;
}

export interface PostPaymentToken {
  readonly resultKey: string;
}

export interface PostPaymentTokenResponse {
  refId: string;
  paymentMethod: string;
  cardType: string | null;
  cardName: string | null;
  cardNumber: string | null;
  cardExpiryYear: string | null;
  cardExpiryMonth: string | null;
  accountName: string | null;
  accountNumber: string | null;
  bsb: string | null;
}

export class GetPaymentType {
  public propertyId: string;
  public serviceType: string;
}

export interface FastPayPayment {
  amount: string;
  clientCode: string;
  customerNumber: string;
  fastpayReferenceNumber: string;
  paymentDate: string;
  paymentTags: string;
  platformPropertyId: number;
  requestedBy?: string;
  serviceReferenceNumber: string;
  serviceType: string;
  transactionRunScheduleId: number;
  userAddressId: number;
  userId: number;
  userServiceAccountId: number;
}

export interface PayID {
  id: string;
  label: string;
}

export interface PayIdInformation {
  title: string;
  body: string[];
}

export interface Reference {
  label: string;
  id: string;
}

export interface SortedPayIdDetails {
  footer: string;
  payId: PayID;
  payIdInformation: PayIdInformation;
  reference: Reference;
  title: string;
  userId: number;
}

export interface PaymentDetailsBase {
  notificationBody: string;
  notificationTitle: string;
  payments: FastPayPayment[];
  sortedPayId: string;
  sortedPayIdDetails: SortedPayIdDetails;
  timeStamp: string;
}

export interface GetFastPayResponse {
  errors: [];
  payloadVersion?: string;
  response: PaymentDetailsBase;
  success: boolean;
}

export interface PayIdPayLoad {
  userId: string;
}

export interface GetStripePaymentIntentRequest {
  totalAmount: number;
  cartItemId: number;
  serviceType: string;
  providerId: string;
  productId: string;
}

export interface GetStripePaymentIntentResponse {
  clientSecret: string;
  publicKey: string;
}

export interface CheckoutStripeToken {
  clientSecret: string;
  stripePromise: string;
  serviceType: string;
  providerId: string;
  productId: string;
}

export enum PaymentGateway {
  STRIPE = 'stripe',
  BPOINT = 'bpoint',
  SPLIT = 'split',
  WESTPAC_QUICK = 'westpac-quickstream',
}

export interface SaveSelectedPaymentMethodPayload {
  maskedDetails: MaskDetails;
  paymentMethodId: string;
}
export interface GetPaymentMethodsPayLoad {
  onSuccess: (response: GetPaymentMethodsResponse[]) => void;
}

export interface GetPaymentTypes {
  data: GetPaymentType;
  onSuccess: () => void;
}

export interface GetPaymentAuthKeyRequest {
  data: CreditCardRequest | BankAccountRequest;
  paymentGateway?: PaymentGateway;
  providerId?: string;
  onSuccess: (token: string) => void;
  onError: (error: Error) => void;
}

export interface GetPaymentRefId {
  token: string;
  providerId?: string;
  transactionId?: string;
  onSuccess: (refId: string) => void;
}

export interface PostPaymentRequest {
  data: PostPaymentToken;
  onSuccess: (response: GetPaymentMethodsResponse) => void;
}

export interface EditPaymentRequest {
  readonly serviceAccountId: number;
  readonly paymentRefId: string;
}

export interface PostEditPaymentPayload {
  data: EditPaymentRequest;
  onSuccess?: () => void;
}

export interface DeletePaymentRequest {
  paymentRefId: string;
  onSuccess: (response: string[]) => void;
}
