import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    packContainer: {
      marginRight: theme.units.spacing * 4,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      minHeight: 400,
    },
    packBackgroundContainer: {
      height: 160,
      width: '100%',
      overflow: 'hidden',
      borderTopLeftRadius: theme.units.borderRadius,
      borderTopRightRadius: theme.units.borderRadius,
    },
    background: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    content: {
      borderBottomLeftRadius: theme.units.borderRadius,
      borderBottomRightRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderTop: 'none',
      padding: theme.units.spacing * 4,
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.black60,
      marginBottom: theme.units.spacing,
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 24,
      color: theme.colors.black87,
      marginBottom: theme.units.spacing * 4,
    },
    description: {
      fontWeight: getFonts('Light'),
      fontSize: 16,
      color: theme.colors.black87,
      marginBottom: theme.units.spacing * 4,
    },
    footer: {
      borderTop: `${theme.colors.borderColorLight} 1px solid`,
      paddingTop: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 'auto',
    },
    icons: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      borderRadius: 5000,
      padding: theme.units.spacing * 2,
      backgroundColor: theme.colors.lightGrey,
      flexShrink: 0,
      marginRight: theme.units.spacing * 2,
      display: 'flex',
    },
    icon: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    button: {
      minWidth: 80,
    },
  })();
};
