import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    container: {
      minHeight: 240,
    },
    focusContainer: {
      borderColor: `${theme.colors.darkOrange} !important`,
      backgroundColor: `${theme.colors.lightOrange} !important`,
    },
    nextTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.units.spacing * 6,
    },
    nextIcon: {
      width: theme.units.iconSize * 1.2,
      height: theme.units.iconSize * 1.2,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    nextTime: {
      padding: '4px 8px',
      backgroundColor: theme.colors.lightPurple,
      borderRadius: 4,
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 14,
    },
    nextTimeGreen: {
      backgroundColor: theme.colors.lightGreen,
    },
    nextTitle: {
      marginBottom: theme.units.spacing * 3,
    },
  })();
};
