import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 6,
      },
    },
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: theme.units.spacing * 6,
        paddingRight: theme.units.spacing * 6,
      },
    },
    primaryContainer: {
      ...primaryContent,
      marginTop: -theme.units.spacing * 10,
      zIndex: 1,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    banner: {
      backgroundColor: `${theme.colors.lightOrange} !important`,
    },
    serviceTypeCopy: {
      whiteSpace: 'pre-wrap',
      fontWeight: getFonts('Regular'),
      color: theme.colors.black60,
      fontSize: 14,
      marginBottom: theme.units.spacing * 6,
    },
    noServiceContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.black38,
      textAlign: 'center',
      height: '40vh',
      flexDirection: 'column',
    },
    noServiceImage: {
      width: 80,
      heigh: 65,
    },
    noServiceTitleStyle: {
      color: theme.colors.black87,
      textAlign: 'center',
      fontFamily: 'GT Walsheim',
      fontSize: 16,
      marginTop: 24,
    },
    noServiceSubtextSyle: {
      color: theme.colors.black87,
      textAlign: 'center',
      fontFamily: 'GT Walsheim',
      fontSize: 14,
      marginTop: 12,
    },
    contactSupportButtonStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 312,
      height: 40,
      color: theme.colors.black87,
      textAlign: 'center',
      fontFamily: 'GT Walsheim',
      fontSize: 15,
      border: `${theme.colors.borderColor} 2px solid`,
      borderRadius: 6,
      marginTop: 32,
      cursor: 'pointer',
    },
    popupContainer: {
      backgroundColor: theme.colors.light,
      height: '100%',
    },
  })();
};
