import { Profile, ProfileType, Roles, User } from '../../models/user/user';
import { ProfileAction, ProfileActionTypes } from '../actions/ProfileActions';
import { ProfileState } from '../state/ProfileState';

export const initialState: ProfileState = {
  profile: undefined,
  profileType: undefined,
  roles: {
    landlord: false,
    tenant: false,
  },
};

export const profileReducer = (state = initialState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data as Profile,
      };
    case ProfileActionTypes.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.data as Roles,
      };
    case ProfileActionTypes.SWITCH_PROFILE_TYPE:
      return {
        ...state,
        profileType: action.data as ProfileType,
      };
    case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data as Profile,
      };
    default:
      return state;
  }
};
