import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { actionGreen } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  boxContainer: {
    marginBottom: theme.units.spacing * 4,
    minHeight: 300,
  },
  goodToKnowRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 4,
  },
  icon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginRight: theme.units.spacing * 3,
    objectFit: 'contain',
    ...actionGreen,
    flexShrink: 1,
    position: 'relative',
    top: -2,
  },
  image: {
    borderRadius: theme.units.borderRadius,
    width: '100%',
    height: 200,
    objectFit: 'cover',
    objectPosition: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  titleStyles: {
    width: '100%',
    fontSize: 28,
  },
  bodyStyles: {
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 8,
  },
  link: {
    marginTop: 'auto',
  },
});
