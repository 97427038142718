import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import {
  actionGreen,
  maxContentWidth,
  primaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    imageContainer: {
      backgroundColor: theme.colors.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: 200,
      borderRadius: theme.units.borderRadius,
      overflow: 'hidden',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: theme.units.borderRadius,
    },
    headerBottom: {
      marginTop: -16,
      height: 200,
      width: '100%',
      backgroundColor: theme.colors.borderColorLight,
    },
    contentContainer: {
      marginTop: -45,
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 40,
    },
    cardContainer: {
      padding: 16,
      marginTop: 12,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    titleContainer: {
      paddingBottom: 12,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    title: {
      fontSize: 18,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    link: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.success,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: 16,
    },
    key: {
      fontSize: 16,
      color: theme.colors.black60,
      fontWeight: getFonts('Medium'),
    },
    value: {
      fontSize: 16,
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
    },
    gravatar: {
      height: 40,
      width: 40,
      borderRadius: 5000,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.borderColorLight,
      flexShrink: 0,
      alignSelf: 'center',
    },
    gravatarText: {
      color: theme.colors.light,
      fontSize: 16,
    },
    textContainer: {
      marginLeft: 12,
      marginRight: 'auto',
    },
    text: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    description: {
      marginTop: 4,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
    },
    icon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    serviceType: {
      marginLeft: 16,
      marginRight: 'auto',
      fontSize: 16,
      color: theme.colors.black87,
    },
    addMore: {
      marginLeft: 16,
      marginRight: 'auto',
      fontSize: 15,
      color: theme.colors.black38,
    },
    tick: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    row2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingVertical: 16,
      borderBottomColor: theme.colors.borderColorLight,
      borderBottomWidth: 1,
    },
    noBorder: {
      borderBottomWidth: 0,
    },
    documentRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: 16,
      paddingHorizontal: 12,
    },
    documentIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    documentTitle: {
      marginRight: 'auto',
      marginLeft: 12,
    },
    accordian: {
      width: theme.units.iconSize * 0.5,
      height: theme.units.iconSize * 0.5,
      transform: 'rotate(270deg)',
      objectFit: 'contain',
      objectPosition: 'center',
      opacity: 0.4,
    },
  })();
};
