import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    title: {
      marginBottom: theme.units.spacing * 8,
    },
    section: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: 16,
    },
    titleContainer: {
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    sectionTitle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 18,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.units.spacing * 4,
    },
    col: {
      marginBottom: theme.units.spacing * 4,
    },
    key: {
      fontWeight: getFonts('Light'),
      color: theme.colors.black87,
    },
    keybold: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginBottom: 4,
    },
    value: {
      fontWeight: getFonts('Light'),
      color: theme.colors.black87,
    },
    buttonGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
    },
  })();
};
