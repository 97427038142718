/* eslint-disable @typescript-eslint/no-unsafe-return */
import { get } from 'lodash';
import { LandingScreenState } from '../state/LandingScreenState';
import { LandingScreenAction, LandingScreenActionTypes } from '../actions/LandingScreenAction';
import { OverviewFormData } from '../../models/landingScreen/OverviewForm';

export const initialState: LandingScreenState = {
  tenantDetails: undefined,
  refId: undefined,
  properties: undefined,
};

export function landingScreenReducer(state = initialState, action: LandingScreenAction) {
  state = postFormDetailsReducer(state, action);
  return state;
}

function postFormDetailsReducer(state: any, action: LandingScreenAction) {
  switch (action.type) {
    case LandingScreenActionTypes.POST_OVERVIEW_FORM_REQUEST:
      return {
        ...state,
        tenantDetails: action.data as OverviewFormData,
      };
    case LandingScreenActionTypes.POST_OVERVIEW_FORM_SUCCESS:
      return {
        ...state,
        tenantDetails: action.data as OverviewFormData,
      };
    case LandingScreenActionTypes.SAVE_REF_ID:
      return {
        ...state,
        refId: action.data,
      };
    case LandingScreenActionTypes.SAVE_DETAILS_ON_LOGIN:
      return {
        ...state,
        tenantDetails: action.data as OverviewFormData,
      };
    case LandingScreenActionTypes.SAVE_PROPERTIES:
      return {
        ...state,
        properties: action.data,
      };
    case LandingScreenActionTypes.LANDING_SCRREN_CLEAR_PROPERTIES:
      return {
        ...state,
        properties: undefined,
      };
    case LandingScreenActionTypes.DELETE_OTHER_APPLICANT: {
      let otherApplicants = get(state, 'tenantDetails.otherApplicants', []);
      otherApplicants = otherApplicants.filter((a: any) => a.key !== action.data);
      const tenantDetails: any = get(state, 'tenantDetails');
      if (tenantDetails.noOfPeople === 1) {
        tenantDetails.livingWithOthers = false;
        tenantDetails.otherApplicants = [];
      } else {
        tenantDetails.noOfPeople -= 1;
        tenantDetails.otherApplicants = otherApplicants;
      }
      return {
        ...state,
        tenantDetails,
      };
    }

    case LandingScreenActionTypes.DELETE_ALL_OTHER_APPLICANTS: {
      const tenantDetails = get(state, 'tenantDetails');
      if (tenantDetails) {
        tenantDetails.livingWithOthers = false;
        tenantDetails.otherApplicants = [];
        tenantDetails.noOfPeople = 1;
        return {
          ...state,
          tenantDetails,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
}
