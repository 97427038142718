import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './MainBannerStyles';
import sortedLogo from '../../assets/sorted.png';
import naxLogo from '../../assets/nax.png';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface MainBannerProps {
  title: string;
  subtitle: string;
  image: string;
  parentStyles?: string;
  backgroundColor?: string;
}

const MainBanner: FC<MainBannerProps> = ({
  title,
  subtitle,
  image,
  parentStyles,
  backgroundColor,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  return (
    <div
      className={classNameGenerator([classes.marketplaceBanner, parentStyles])}
      style={{ ...(backgroundColor && { backgroundColor }) }}
    >
      <img
        src={themeKey === ThemeKey.NAX ? naxLogo : sortedLogo}
        className={themeKey === ThemeKey.NAX ? classes.naxLogo : classes.logo}
        alt="sorted logo"
      />
      <div className={classes.titleContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
      <div className={classes.bannerImageContainer}>
        <img src={image} className={classes.bannerImage} alt="banner img" />
      </div>
    </div>
  );
};

export default MainBanner;
