import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    serviceCardItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
      borderRadius: 6,
      borderColor: theme.colors.borderColor,
      borderWidth: 1.5,
      borderStyle: 'solid',
      padding: 16,
      backgroundColor: theme.colors.secondary,
      width: '100%',
    },
    cardLeftView: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardLeftVerticalView: {
      marginLeft: 16,
    },
    serviceIcon: {
      alignSelf: 'center',
      height: 26,
      width: 26,
      padding: 0,
      borderRadius: 13,
    },
    supplierCountArea: {
      display: 'flex',
      flexDirection: 'row',
    },
    supplierCount: {
      backgroundColor: '#eee',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 7,
      paddingRight: 7,
      borderRadius: 50,
    },
    serviceStatusMaintenance: {
      fontSize: 13,
      letterSpacing: 1.5,
      textAlign: 'right',
      alignSelf: 'stretch',
    },
    serviceCost: {
      textAlign: 'right',
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    serviceBillingCycle: {
      marginTop: 4,
      fontSize: 13,
      fontWeight: getFonts('Medium'),
      color: theme.colors.subtitle,
    },
    serviceStatusIcon: {
      fontSize: 13,
      height: 24,
      width: 24,
      letterSpacing: 1.5,
    },
    serviceStatus: {
      fontSize: 13,
      height: 16,
      letterSpacing: 1.5,
    },
    serviceProviderDetail: {
      fontSize: 13,
      letterSpacing: 0.4,
      color: 'rgba(0, 0, 0, 0.38)',
    },
    iconContainer: {
      borderRadius: 5000,
      backgroundColor: theme.colors.disabled,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 36,
      height: 36,
    },
    icon: {
      width: 24,
      height: 24,
      objectFit: 'contain',
    },
    activeIcon: {
      width: 24,
      height: 24,
      objectFit: 'contain',
      tintColor: theme.colors.success,
    },
    titleContainer: {
      marginLeft: 16,
      marginRight: 'auto',
    },
    title: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    description: {
      marginTop: 4,
      fontSize: 13,
      fontWeight: getFonts('Medium'),
      color: theme.colors.subtitle,
    },
    statusContainer: {
      paddingHorizontal: 10,
      paddingVertical: 4,
      backgroundColor: theme.colors.completeLabel,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 75,
    },
    status: {
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
      textTransform: 'capitalize',
    },
  })();
};
