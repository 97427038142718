import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    marginTop: {
      marginTop: 24,
    },
    sectionKey: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      fontSize: 14,
      marginBottom: 6,
    },
    sectionValue: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 16,
    },
    iconStyle: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    logoStyle: {
      width: 40,
      height: 40,
      objectFit: 'contain',
      objectPosition: 'center',
      borderRadius: 5000,
    },
    supplierDetailsRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 24,
    },
    titleContainer: {
      marginRight: 'auto',
    },
    supplierName: {
      fontWeight: getFonts('Light'),
      fontSize: 16,
      marginLeft: 12,
    },
  })();
};
