import React, { FC } from 'react';
import { IconButton, Button } from '@mui/material';
import { useStyles } from './InputToggleStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface InputToggleProps {
  setValue: (value: boolean | number) => void;
  parentStyles?: any;
  value: boolean | number | null | undefined;
  hideNo?: boolean;
  isDisabled?: boolean;
  wide?: boolean;
  number?: boolean;
}

const InputToggle: FC<InputToggleProps> = ({
  setValue,
  value,
  isDisabled,
  parentStyles,
  wide,
  hideNo,
  number,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      style={parentStyles}
      disabled={isDisabled}
      disableRipple
      className={classNameGenerator(['no-hover', classes.noPadding])}
    >
      <Button
        onClick={() => {
          setValue(number ? 1 : true);
        }}
        style={wide ? { minWidth: '150px', marginRight: '10px' } : { minWidth: '100px' }}
        className={
          value !== null && (number ? value === 1 : value)
            ? classes.activeButton
            : classes.inactiveButton
        }
      >
        Yes
      </Button>
      {!hideNo && (
        <Button
          onClick={() => {
            setValue(number ? 0 : false);
          }}
          style={wide ? { minWidth: '150px', marginRight: '10px' } : { minWidth: '100px' }}
          className={
            value === null || (number ? value === 1 : value)
              ? classes.inactiveButton
              : classes.activeButton
          }
        >
          No
        </Button>
      )}
    </IconButton>
  );
};

export default InputToggle;
