import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  GetRentOnboardingResponse,
  PostRentOnboardingRequest,
  PostRentOnboardingResponse,
  PostServiceabilityRequest,
  Services,
} from '../../models/rentOnboarding/rentOnboarding';

const serviceType = ServiceType.transaction;

export class RentOnboardingService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  public getRentOnboarding(): Promise<GetRentOnboardingResponse[]> | GetRentOnboardingResponse[] {
    return this.service.get({
      route: ['rental-service'],
      query: {
        app: 'web',
      },
    });
  }
  public postRentOnboarding(
    data: PostRentOnboardingRequest,
  ): Promise<PostRentOnboardingResponse> | PostRentOnboardingResponse {
    return this.service.post(
      {
        route: ['rental-service'],
      },
      data,
    );
  }
  public postServiceability(
    data: PostServiceabilityRequest[],
    leaseId: number,
  ): Promise<Services[]> | Services[] {
    return this.service.post(
      {
        route: ['optin-service', leaseId.toString()],
      },
      data,
    );
  }
}

const rentOnboardingService = new RentOnboardingService();
export default rentOnboardingService;
