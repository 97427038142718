import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { SIZES } from '../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    sectionContainer: {
      backgroundColor: theme.colors.lighterGrey,
      borderRadius: theme.units.borderRadius,
      padding: 24,
      marginBottom: theme.units.spacing * 4,
      marginTop: -16,
    },
    titleContainer: {
      display: 'flex',
    },
    title: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 20,
      marginBottom: theme.units.spacing,
    },
    price: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
      fontSize: 20,
      marginLeft: 6,
    },
    subtitle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      fontSize: 14,
      marginTop: 6,
      marginBottom: 20,
    },
    line: {
      marginTop: 8,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: 8,
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.light,
      padding: '12px',
      paddingLeft: 16,
      alignItems: 'center',
      borderRadius: 8,
      minHeight: 40,
      border: `${theme.colors.borderColorLight} 1px solid`,
      '& > div': {
        flex: 1,
      },
    },
    addNewPaymentFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 15,
    },
    secureBoxStyle: {
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    secureLockStyle: {
      marginRight: 16,
    },
    secureText: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      fontSize: '14px',
    },
    addNewOptionSave: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: 8,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
      fontSize: '14px',
      minWidth: '60px',
    },
    disabledButton: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.colors.disabled,
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: 8,
      border: `${theme.colors.disabled} 2px solid`,
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      minWidth: '60px',
    },
    successContainer: {
      display: 'flex',
      backgroundColor: theme.colors.fadedBlue,
      padding: '12px',
      paddingLeft: 16,
      alignItems: 'center',
      borderRadius: 8,
      minHeight: 40,
      border: `${theme.colors.fadedBlue} 2px solid`,
    },
    successIcon: {
      width: 22,
      height: 22,
      marginRight: 12,
    },
    successText: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 16,
    },
    errorText: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.error,
      fontSize: 16,
    },
    costBreakdown: {
      marginBottom: 12,
      backgroundColor: theme.colors.light,
      padding: '15px 15px 0px 15px',
    },
    breakdownItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 15,
    },
    itemName: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black38,
    },
    itemCost: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black87,
    },
  })();
};
