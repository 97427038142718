import React, { FC, useEffect, useState, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CimetNeedsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ProfileType } from '../../models/user/user';
import { ProfileActions } from '../../store/actions/ProfileActions';
import Box from '../../components/box/Box';
import Text from '../../components/text/Text';
import { CIMET_SERVICES, ERRORS, LABELS, links, validationSchema } from './CimetNeedsConstants';
import Button from '../../components/button/Button';
import { DashboardActions } from '../../store/actions/DashboardActions';
import peopleWide from '../../assets/people-wide.png';
import playstore from '../../assets/playstore.png';
import appstore from '../../assets/applestore.png';
import helpIcon from '../../assets/info.png';
import closeIcon from '../../assets/navigation/close.png';
import { convertDates, getPartnersImages, getServiceIcon, isFormComplete } from './CimetNeedsUtils';
import {
  CimetBroadbandNeeds,
  CimetDatesRequest,
  CimetDatesResponse,
  CimetElectricityNeeds,
  CimetNeedsRequest,
  CimetServices,
  CimetServicesRequest,
  CimetVisitRequest,
} from '../../models/cimet/Cimet';
import { CimetActionTypes, CimetActions } from '../../store/actions/CimetActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { ServiceTypes } from '../../models/services/services';
import { FormikTouched, useFormik } from 'formik';
import InputToggle from '../../components/inputToggle/InputToggle';
import InputDate from '../../components/inputDate/InputDate';
import moment from 'moment';
import { ThemeKey, getCurrentThemeKey, theme } from '../../theme/Theme';
import { extractParams } from '../../navigation/NavigationUtils';
import { CimetNeedsParams } from '../../navigation/NavigationConstants';

interface CimetNeedsProps {
  accessToken: string | null;
  loadingServices: boolean;
  loadingNeeds: boolean;
  cimetServices: CimetServices | undefined;
  cimetUrl: string | undefined;
  blockedDates: CimetDatesResponse[] | undefined;
  getCimetServices: (data: CimetServicesRequest) => void;
  postCimetVisit: (data: CimetVisitRequest) => void;
  postCimetNeeds: (data: CimetNeedsRequest) => void;
  getCimetDates: (data: CimetDatesRequest) => void;
  resetCimetUrl: () => void;
  resetCimetServices: () => void;
}

const CimetNeeds: FC<CimetNeedsProps> = ({
  accessToken,
  loadingServices,
  loadingNeeds,
  cimetServices,
  cimetUrl,
  blockedDates,
  getCimetServices,
  postCimetVisit,
  postCimetNeeds,
  getCimetDates,
  resetCimetUrl,
  resetCimetServices,
}) => {
  const [selectedService, setSelectedService] = useState<ServiceTypes | null>(null);
  const [isServicePreselected, setIsServicePreselected] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
  const themeKey = getCurrentThemeKey();
  const queryParams = useMemo<CimetNeedsParams>(() => {
    const result = extractParams<CimetNeedsParams>();
    if (result.serviceType) {
      setIsServicePreselected(true);
      setSelectedService(result.serviceType as ServiceTypes);
    }
    return result;
  }, []);

  const cimetAvailableServices = useMemo<ServiceTypes[]>(() => {
    if (
      queryParams.propertyId === undefined ||
      cimetServices === undefined ||
      cimetServices[queryParams.propertyId] === undefined
    )
      return [];
    return cimetServices[queryParams.propertyId].availableServices;
  }, [queryParams, cimetServices]);

  useEffect(() => {
    if (accessToken && selectedService !== null && !isServicePreselected) {
      postCimetVisit({
        propertyId: parseInt(queryParams.propertyId),
        serviceType: selectedService,
      });
    }
  }, [accessToken, selectedService, isServicePreselected]);

  useEffect(() => {
    if (
      accessToken &&
      !isServicePreselected &&
      (cimetAvailableServices.length === 0 ||
        (cimetServices !== undefined && cimetServices[queryParams.propertyId] === undefined)) &&
      !transitioning
    ) {
      // If there is an access token, the service isn't preselected,
      // there are no services or if there are the property id isn't present,
      // not loading or transitioning, call this API.
      getCimetServices({ propertyId: parseInt(queryParams.propertyId) });
    }
  }, [accessToken, isServicePreselected, queryParams]);

  useEffect(() => {
    if (accessToken && selectedService) {
      getCimetDates({ propertyId: parseInt(queryParams.propertyId), serviceType: selectedService });
    }
  }, [accessToken, queryParams, selectedService]);

  useEffect(() => {
    if (!!cimetUrl && !loadingNeeds) {
      setTransitioning(true);
      resetCimetUrl();
      resetCimetServices();
      window.location.href = cimetUrl;
    }
  }, [loadingNeeds, cimetUrl]);

  const classes = useStyles();

  const formik = useFormik<CimetElectricityNeeds | CimetBroadbandNeeds>({
    initialValues: {
      propertyType: 0,
      lifeSupport: null,
      movingType: 1,
      movingDate: '',
      solarPanel: 0,
      energyType: 0,
      electricityBill: 0,
      gasBill: 0,
      postcode: '',
    },
    onSubmit: (values) => {
      postCimetNeeds({
        propertyId: parseInt(queryParams.propertyId),
        serviceType: selectedService!,
        needs: {
          ...values,
          movingDate: !!values.movingDate ? moment(values.movingDate).format('DD/MM/YYYY') : null,
        },
      });
    },
    validationSchema,
  });

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <Box
              parentStyles={classes.selectServiceContainer}
              innerStyles={classes.selectServiceWrapper}
              lightBorder
            >
              <div className={classes.headerContainer}>
                <div className={classes.headerTextContent}>
                  <Text parentStyles={classes.headerTitle} textVariant="title" size="xxl">
                    {LABELS.TITLE}
                  </Text>
                  <Text parentStyles={classes.headerSubtitle} textVariant="info" size="l">
                    {LABELS.SUBTITLE}
                  </Text>
                </div>
                <img className={classes.headerImage} src={peopleWide} alt="people-wide" />
              </div>
              {/* If API is being called display loading */}
              {/* If cimetServices ARE undefined display loading */}
              {/* If coming from the app preselected === true, therefore the above condition is not relevant */}
              {loadingServices || (cimetServices === undefined && !isServicePreselected) ? (
                <div className={classes.selectSection}>
                  <Text parentStyles={classes.selectTitle} textVariant="title" size="xxl">
                    {LABELS.SELECT}
                  </Text>
                  <div className={classes.selectItems}>
                    {[...Array(3)].map((_, idx) => (
                      <Box parentStyles={classes.loadingItemService} loading />
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {!isServicePreselected && (
                    <div className={classes.selectSection}>
                      <Text parentStyles={classes.selectTitle} textVariant="title" size="xxl">
                        {!!cimetAvailableServices.length ? LABELS.SELECT : LABELS.NO_SERVICES}
                      </Text>
                      {!!!cimetAvailableServices.length && (
                        <Text parentStyles={classes.selectSubtitle} textVariant="info" size="l">
                          {themeKey === ThemeKey.NAX
                            ? LABELS.DOWNLOAD_NAX_APP
                            : LABELS.DOWNLOAD_APP}
                        </Text>
                      )}
                      <div className={classes.selectItems}>
                        {CIMET_SERVICES.map((service, idx) => {
                          return !!cimetAvailableServices.length &&
                            cimetAvailableServices.includes(service as ServiceTypes) ? (
                            <Box
                              parentStyles={
                                selectedService === service
                                  ? classes.selectItemContainerActive
                                  : classes.selectItemContainer
                              }
                              innerStyles={classes.selectItemWrapper}
                              key={idx}
                              highlight
                              selected={selectedService === service}
                              onPress={() => setSelectedService(service as ServiceTypes)}
                            >
                              <img
                                className={classes.selectItemIcon}
                                src={getServiceIcon(service as ServiceTypes, selectedService)}
                                alt="service-icon"
                              />
                              <Text parentStyles={classes.selectItemText}>{service}</Text>
                            </Box>
                          ) : (
                            <Box
                              parentStyles={classes.inactiveServiceContainer}
                              innerStyles={classes.selectItemWrapper}
                              key={idx}
                            >
                              <img
                                className={classes.inactiveItemIcon}
                                src={getServiceIcon(service as ServiceTypes, selectedService)}
                                alt="service-icon"
                              />
                              <div className={classes.inactiveServiceContent}>
                                <Text parentStyles={classes.inactiveItemText}>{service}</Text>
                                <Text parentStyles={classes.inactiveItemSubText}>
                                  {LABELS.CONNECTED}
                                </Text>
                              </div>
                            </Box>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}

              {!!!cimetAvailableServices.length && !isServicePreselected && !loadingServices ? (
                <div className={classes.downloadContainer}>
                  <div className={classes.downloadLinks}>
                    <img
                      src={appstore}
                      className={classes.downloadLink}
                      alt="app-link"
                      onClick={() => {
                        if (themeKey === ThemeKey.NAX) {
                          window.open(links.appleStoreNAX, '_blank');
                        } else {
                          window.open(links.appleStore, '_blank');
                        }
                      }}
                    />
                    <img
                      src={playstore}
                      className={classes.downloadLink}
                      alt="app-link"
                      onClick={() => {
                        if (themeKey === ThemeKey.NAX) {
                          window.open(links.playstoreNAX, '_blank');
                        } else {
                          window.open(links.playstore, '_blank');
                        }
                      }}
                    />
                  </div>
                  <Button
                    parentStyles={classes.downloadButton}
                    onPress={() => {
                      window.open(links.playstoreNAX, 'https://www.sortedservices.com/try');
                    }}
                  >
                    {LABELS.DOWNLOAD_SORTED}
                  </Button>
                </div>
              ) : (
                <div className={classes.partnersSection}>
                  <Text parentStyles={classes.partnersTitle} textVariant="title" size="l">
                    {LABELS.PROVIDERS}
                  </Text>
                  <div className={classes.partners}>
                    {getPartnersImages(selectedService).map((partner, idx) => (
                      <img
                        className={classes.partnerImage}
                        src={partner}
                        key={idx}
                        alt="partner-image"
                      />
                    ))}
                  </div>
                </div>
              )}
            </Box>

            {selectedService !== null && (
              <>
                <Box
                  parentStyles={classes.needsContainer}
                  innerStyles={classes.needWrapper}
                  lightBorder
                >
                  <Text parentStyles={classes.selectTitle} textVariant="title" size="xxl">
                    {LABELS.BASIC_INFORMATION}
                  </Text>
                  <div className={classes.question}>
                    <div className={classes.questionContainer}>
                      <Text parentStyles={classes.questionText} textVariant="info">
                        {LABELS.ARE_YOU_MOVING}
                      </Text>
                      <InputToggle
                        value={formik.values.movingType}
                        number
                        setValue={(value: number) => formik.setFieldValue('movingType', value)}
                      />
                      {formik.touched.movingType && formik.values.movingType === null && (
                        <Text parentStyles={classes.errorMessage} textVariant="error">
                          {ERRORS.required}
                        </Text>
                      )}
                    </div>
                    {formik.values.movingType === 1 && (
                      <>
                        <InputDate
                          placeholder={LABELS.MOVING_DATE}
                          key={LABELS.MOVING_DATE}
                          value={formik.values.movingDate}
                          setValue={(newValue: string) =>
                            formik.setFieldValue('movingDate', newValue)
                          }
                          onBlur={() => formik.setFieldTouched('movingDate')}
                          touched={formik.touched.movingDate}
                          error={formik.errors.movingDate}
                          title={LABELS.MOVING_DATE}
                          minDate={moment().add(1, 'day').toDate()}
                          mandatory
                          blockedDates={convertDates(blockedDates).concat(
                            moment().format('DD/MM/YYYY'),
                          )}
                          disableWeekends
                        />
                        {selectedService === ServiceTypes.Electricity && (
                          <div className={classes.movingInfo}>{LABELS.MOVING_INFO}</div>
                        )}
                      </>
                    )}
                  </div>
                  {selectedService === ServiceTypes.Electricity && (
                    <div className={classes.question}>
                      <div className={classes.questionContainer}>
                        <Text parentStyles={classes.questionText} textVariant="info">
                          {LABELS.SOLAR}
                        </Text>
                        <InputToggle
                          value={(formik.values as CimetElectricityNeeds).solarPanel}
                          number
                          setValue={(value: number) => formik.setFieldValue('solarPanel', value)}
                        />
                        {(formik.touched as FormikTouched<CimetElectricityNeeds>).solarPanel &&
                          (formik.values as CimetElectricityNeeds).solarPanel === null && (
                            <Text parentStyles={classes.errorMessage} textVariant="error">
                              {ERRORS.required}
                            </Text>
                          )}
                      </div>
                    </div>
                  )}
                  {(selectedService === ServiceTypes.Electricity ||
                    selectedService === ServiceTypes.Gas) && (
                    <div className={classes.question}>
                      <div className={classes.questionContainer}>
                        <img
                          src={helpIcon}
                          className={classes.helpIcon}
                          alt="help"
                          onClick={() => setShowInfoPopup(true)}
                        />
                        <Text parentStyles={classes.helpText} textVariant="info">
                          {LABELS.LIFE_SUPPORT}
                        </Text>
                        <InputToggle
                          value={(formik.values as CimetElectricityNeeds).lifeSupport}
                          number
                          setValue={(value: number) => formik.setFieldValue('lifeSupport', value)}
                        />
                        {(formik.touched as FormikTouched<CimetElectricityNeeds>).lifeSupport &&
                          (formik.values as CimetElectricityNeeds).lifeSupport === null && (
                            <Text parentStyles={classes.errorMessage} textVariant="error">
                              {ERRORS.required}
                            </Text>
                          )}
                      </div>
                    </div>
                  )}
                </Box>
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    style={{ color: theme.colors.buttonPrimary, flexShrink: 0 }}
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: LABELS.TERMS(selectedService) }}
                    className={classes.checkBoxText}
                    onClick={() => setIsChecked(!isChecked)}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    loading={loadingNeeds || transitioning}
                    type="submit"
                    disabled={
                      loadingNeeds ||
                      !isFormComplete(selectedService, formik.values, isChecked) ||
                      transitioning
                    }
                    parentStyles={classes.submitButton}
                    onPress={() => {
                      formik.handleSubmit();
                    }}
                  >
                    {LABELS.NEXT}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog open={showInfoPopup} onClose={() => setShowInfoPopup(false)}>
        <div className={classes.popupContainer}>
          <div className={classes.popupTitleContainer}>
            <div className={classes.popupTitle}>Life Support Terms</div>
            <img
              className={classes.popupAction}
              src={closeIcon}
              alt="Close Dialog"
              onClick={() => setShowInfoPopup(false)}
            />
          </div>
          <div
            className={classes.popupContent}
            dangerouslySetInnerHTML={{ __html: LABELS.LIFE_POPUP }}
          />
        </div>
      </Dialog>
    </div>
  );
};

const loadingServices = loadingSelector([CimetActionTypes.GET_CIMET_SERVICES]);

const loadingNeeds = loadingSelector([CimetActionTypes.POST_CIMET_NEEDS]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingServices: loadingServices(state),
  loadingNeeds: loadingNeeds(state),
  cimetServices: state.cimet.services,
  cimetUrl: state.cimet.url,
  accessToken: state.token.accessToken,
  blockedDates: state.cimet.blockedDates,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCimetServices: (data: CimetServicesRequest) =>
    dispatch(CimetActions.getCimetServicesRequest(data)),
  postCimetVisit: (data: CimetVisitRequest) => dispatch(CimetActions.postCimetVisitRequest(data)),
  postCimetNeeds: (data: CimetNeedsRequest) => dispatch(CimetActions.postCimetNeedsRequest(data)),
  getCimetDates: (data: CimetDatesRequest) => dispatch(CimetActions.getCimetDatesRequest(data)),
  resetCimetUrl: () => dispatch(CimetActions.resetCimetURL()),
  resetCimetServices: () => dispatch(CimetActions.resetCimetServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CimetNeeds);
