import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import { loadingAnimation } from '../../theme/GlobalStyles';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    tileContainer: {
      borderRadius: theme.units.borderRadius,
      backgroundColor: theme.colors.light,
      border: `1px solid ${theme.colors.borderColorLight}`,
      position: 'relative',
      cursor: 'pointer',
      minHeight: 132,
      minWidth: 240,
      overflow: 'hidden',
      display: 'flex',
    },
    activeContainer: {
      backgroundColor: theme.colors.buttonPrimary,
      border: `1px solid ${theme.colors.buttonPrimary}`,
      display: 'flex',
      flexDirection: 'column',
    },
    tileWrapper: {
      padding: theme.units.spacing * 4,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: theme.colors.black60,
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.xs,
      marginBottom: theme.units.spacing,
    },
    activeTitle: {
      color: theme.colors.light,
      opacity: 0.6,
    },
    subtitle: {
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.l,
    },
    activeSubtitle: {
      color: theme.colors.light,
    },
    bottomRow: {
      marginTop: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    iconCircle: {
      borderRadius: 5000,
      width: theme.units.iconSize * 2,
      height: theme.units.iconSize * 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.lightGrey,
    },
    activeIconCircle: {
      backgroundColor: theme.colors.light,
    },
    icon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    addServicesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      flex: 1,
      paddingLeft: theme.units.spacing * 8,
      paddingRight: theme.units.spacing * 8,
    },
    servicesText: {
      color: theme.colors.black38,
      fontWeight: getFonts('Medium'),
      fontSize: theme.font.m,
    },
    addIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    ribbonBorder: {
      borderColor: theme.colors.lightPurple,
    },
    ribbonBorderGreen: {
      borderColor: theme.colors.lightGreen,
    },
    ribbon: {
      backgroundColor: theme.colors.lightPurple,
      width: 85,
      height: 20,
      position: 'absolute',
      top: 12,
      right: -20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'rotate(45deg)',
    },
    ribbonGreen: {
      backgroundColor: theme.colors.lightGreen,
    },
    ribbonText: {
      fontSize: 12,
    },
    loading: {
      border: 'none',
      ...loadingAnimation,
    },
    percentageStyles: {
      width: `58px !important`,
      height: `58px !important`,
    },
    middleStyles: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      width: `46px !important`,
      height: `46px !important`,
    },
    status: {
      padding: '4px 8px',
      backgroundColor: theme.colors.lightPurple,
      borderRadius: 4,
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 14,
    },
    dot: {
      position: 'absolute',
      top: 12,
      right: 12,
      width: 8,
      height: 8,
      borderRadius: 5000,
      backgroundColor: theme.colors.buttonPrimary,
    },
  })();
};
