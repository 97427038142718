export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

//setting baseUrl
//if env vars have been set by envconsul it will use that otherwise it will use dev url
const BaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://sorted-app-bff.uat.sortedservices.com/sorted'
    : 'https://sorted-app-bff.uat.sortedservices.com/sorted';

const AuthBaseUrl = BaseUrl.includes('prodau')
  ? 'https://auth.prodau.sleevesup.com.au'
  : 'https://auth.uat.sortedservices.com';

const ApplyUILink = BaseUrl.includes('prodau')
  ? 'https://apply.sortedservices.com'
  : 'https://apply.uat.sortedservices.com';

const quickStream = BaseUrl.includes('prodau')
  ? 'https://api.quickstream.westpac.com.au/rest/v1/quickstream-api-1.0.min.js'
  : 'https://api.quickstream.support.qvalent.com/rest/v1/quickstream-api-1.0.min.js';

const BPoint = BaseUrl.includes('prodau')
  ? 'https://www.bpoint.com.au/webapi/CBA/api.js?v=2'
  : 'https://bpoint.uat.linkly.com.au/webapi/CBA/api.js?v=2';

export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

const logRocketGroup = BaseUrl.includes('prodau')
  ? 'sup/sorted-web-ui-prod'
  : 'sup/sorted-web-ui-uat';

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl, // DO NOT end with '/'!
    authBaseUrl: AuthBaseUrl,
    applyUILink: ApplyUILink,
    mockServices: false, // test use mocks by using jest.mock(), this is for manual testing
    quickStream,
    BPoint,
  };

  public static readonly logging = {
    logLevel: LogLevel.Trace,
  };

  public static initTesting() {
    AppSettings.logging.logLevel = AppSettings.testSettings.logLevel;

    this.testSettings = {
      ...this.testSettings,
      testing: true,
    };
  }

  public static get currentTestSetting(): TestSettings {
    return AppSettings.testSettings;
  }

  private static testSettings: TestSettings = {
    testing: false,
    logLevel: LogLevel.Debug,
  };

  public static readonly logRocket = {
    group: logRocketGroup,
  };
}
