/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 6,
      },
    },
    titleContainer: {
      ...pageTitleContainer,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black38,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
    },
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    secondaryContainer: {
      ...secondaryContent,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        marginLeft: 0,
        marginTop: theme.units.spacing * 12,
        maxWidth: '100%',
      },
    },
    banner: {
      marginBottom: theme.units.spacing * 8,
      backgroundColor: `${theme.colors.lightRed} !important`,
    },
    tabContainer: {
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 'normal',
      },
    },
  })();
};
