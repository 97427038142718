import moment from 'moment';
import { SupplierOptions } from '../../../../../../models/billing/Billing';

export const excludeDates = (option: SupplierOptions, publicHolidays?: string[]) => {
  const excludedDates: string[] = [];
  const disableWeekend: boolean = !!option.ComponentDateExcludeWeekend;

  if (option.PublicHolidayExclusion && publicHolidays) {
    for (const date of publicHolidays) {
      excludedDates.push(moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));
    }
  }

  if (option.ComponentDateExcludeCurrentDay) {
    const currentDate = moment();
    const nextValidDate = moment(currentDate).add(1, 'days');

    while (nextValidDate.day() === 0 || nextValidDate.day() === 6) {
      nextValidDate.add(1, 'day');
    }

    excludedDates.push(currentDate.format('DD/MM/YYYY'));
    excludedDates.push(nextValidDate.format('DD/MM/YYYY'));
  }

  return {
    excludedDates,
    disableWeekend,
  };
};
