import React, { FC } from 'react';
import { useStyles } from './DownloadSortedStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../text/Text';
import Button from '../button/Button';
import { LABELS } from './DownloadSortedConstants';
import phones from '../../assets/devices.png';
import switchPhones from '../../assets/switch-devices.png';
import naxPhones from '../../assets/nax-devices.png';
import sorted from '../../assets/sortedLogoHeader.png';
import apple from '../../assets/applestore.png';
import google from '../../assets/playstore.png';
import { openAndroidLink, openAppleLink } from './DownloadSortedConstants';

interface DownloadSortedProps {
  title?: string;
  agencyCode?: string;
}

const DownloadSorted: FC<DownloadSortedProps> = ({ title, agencyCode }) => {
  const classes = useStyles();
  return (
    <div className={classes.downloadSortedContainer}>
      <div className={classes.topRow}>
        <div className={classes.textContent}>
          <Text parentStyles={classes.title} textVariant="title">
            {title || LABELS.TITLE}
          </Text>
          <Text parentStyles={classes.subtitle} size="l" textVariant="info">
            {LABELS.SUBTITLE(agencyCode)}
          </Text>
          <Button onPress={() => openAndroidLink(agencyCode)} parentStyles={classes.button}>
            {LABELS.BUTTON(agencyCode)}
          </Button>
        </div>
        <img
          src={agencyCode === 'NAX' ? naxPhones : agencyCode === 'AU_SWIT' ? switchPhones : phones}
          className={classes.imageBg}
          alt="phones"
        />
      </div>
      <div className={classes.bottomRow}>
        {agencyCode === undefined ||
          (agencyCode === 'SORTED' && (
            <img src={sorted} className={classes.sortedLogo} alt="sorted logo" />
          ))}
        <img
          src={apple}
          onClick={() => openAppleLink(agencyCode)}
          className={classes.apple}
          alt="apple"
        />
        <img
          src={google}
          onClick={() => openAndroidLink()}
          className={classes.googlePlay}
          alt="google"
        />
      </div>
    </div>
  );
};

export default DownloadSorted;
