import { WalletState } from '../state/WalletState';
import { WalletAction, WalletActionTypes } from '../actions/WalletActions';
import {
  WalletProfileResponse,
  WalletBreakdownResponse,
  AddPromoCodeResponse,
} from '../../models/wallet/wallet';

export const initialState: WalletState = {
  walletProfile: undefined,
  walletBreakdown: undefined,
  addPromoResponse: undefined,
  applyWalletSpendResponse: undefined,
};

export const walletReducer = (state = initialState, action: WalletAction): WalletState => {
  switch (action.type) {
    case WalletActionTypes.GET_WALLET_PROFILE_SUCCESS:
      return {
        ...state,
        walletProfile: action.data as WalletProfileResponse,
      };
    case WalletActionTypes.GET_WALLET_BREAKDOWN_SUCCESS:
      return {
        ...state,
        walletBreakdown: action.data as WalletBreakdownResponse,
      };
    case WalletActionTypes.APPLY_WALLET_SPEND_SUCCESS:
      return {
        ...state,
        walletProfile: action.data as WalletProfileResponse,
        applyWalletSpendResponse: action.data as WalletProfileResponse,
      };
    case WalletActionTypes.ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        addPromoResponse: action.data as AddPromoCodeResponse,
      };
    case WalletActionTypes.ADD_PROMO_CODE_RESET:
      return {
        ...state,
        addPromoResponse: undefined,
      };
    case WalletActionTypes.APPLY_WALLET_SPEND_RESET:
      return {
        ...state,
        applyWalletSpendResponse: undefined,
      };
    default:
      return state;
  }
};
