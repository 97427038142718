import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';
import { SIZES } from '../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    sectionContainer: {
      backgroundColor: theme.colors.light,
    },
    sectionTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
    },
    sectionTitle: {
      marginRight: 'auto',
      fontWeight: getFonts('Regular'),
      fontSize: '14px',
      color: theme.colors.black60,
    },
    sectionIconContainer: {
      width: 22,
      height: 22,
    },
    sectionIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    titleContainer: {
      marginBottom: 12,
    },
    title: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 20,
    },
    subtitle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      fontSize: 14,
      marginTop: 6,
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.colors.light,
      padding: '16px',
      height: 40,
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      minHeight: '40px',
      cursor: 'pointer',
    },
    menuRow: {
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      width: '26px',
      height: '26px',
    },
    iconImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    optionContainer: {
      marginRight: 'auto',
    },
    optionTitle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: '16px',
      marginTop: 6,
    },
    optionSubtitle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      fontSize: '13px',
    },
    tickContainer: {
      width: '22px',
      height: '22px',
      marginLeft: 'auto',
      marginRight: '12px',
    },
    tick: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    addNewOption: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.light,
      padding: '16px',
      paddingLeft: 24,
      alignItems: 'center',
      height: 40,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: theme.units.spacing * 2,
      cursor: 'pointer',
    },
    addNewOptionSelected: {
      border: `${theme.colors.buttonPrimary} 1px solid`,
    },
    addNewPaymentFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 15,
    },
    secureBoxStyle: {
      height: 56,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    secureLockStyle: {
      marginRight: 16,
    },
    secureText: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      fontSize: '14px',
    },
    cancelNewOptionSave: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.colors.light,
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: 8,
      border: `${theme.colors.borderColorLight} 1px solid`,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: '14px',
      marginLeft: 'auto',
      marginRight: '12px',
      minWidth: '60px',
      cursor: 'pointer',
    },
    addNewOptionSave: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: 8,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
      fontSize: '14px',
      minWidth: '60px',
      cursor: 'pointer',
    },
    disabledButton: {
      opacity: 0.4,
    },
    // CARD ------------------------------------------------------------ //
    inputContainer: {
      borderRadius: 8,
      border: `${theme.colors.borderColorLight} 1px solid`,
      padding: 12,
      marginTop: 15,
    },
    cardNumberInputStyle: {
      flexGrow: 1,
      marginTop: 15,
      borderRadius: 8,
      border: `${theme.colors.borderColorLight} 1px solid`,
      padding: 12,
      height: 35,
    },
    inputBoxStyle: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 15,
    },
    expiryInputStyle: {
      flex: 1,
      marginRight: 7.5,
      borderRadius: 8,
      border: `${theme.colors.borderColorLight} 1px solid`,
      padding: 12,
    },
    cvvInputStyle: {
      flex: 1,
      marginLeft: 7.5,
      borderRadius: 8,
      border: `${theme.colors.borderColorLight} 1px solid`,
      padding: 12,
    },
    icon: {
      height: 24,
      width: 24,
    },
    successContainer: {
      display: 'flex',
      backgroundColor: theme.colors.fadedBlue,
      padding: '12px',
      paddingLeft: 16,
      alignItems: 'center',
      borderRadius: 8,
      minHeight: 40,
      border: `${theme.colors.fadedBlue} 1px solid`,
    },
    successIcon: {
      width: 22,
      height: 22,
      marginRight: 12,
    },
    successText: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      fontSize: 16,
    },
    twoColumn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    twoColumnGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr',
        gridGap: 0,
      },
    },
    paymentOptionsGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 2,
    },
    paymentDetailsTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
      marginBottom: theme.units.spacing * 6,
    },
    paymentSection: { marginBottom: theme.units.spacing * 8 },
    paymentSectionTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
      marginBottom: theme.units.spacing * 6,
      marginTop: theme.units.spacing * 2,
    },
    addPaymentSection: {
      marginTop: theme.units.spacing * 6,
      backgroundColor: theme.colors.lighterGrey,
      padding: theme.units.spacing * 6,
      borderRadius: theme.units.borderRadius,
    },
    error: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.darkRed,
      backgroundColor: theme.colors.lightRed,
      padding: theme.units.spacing * 2,
      borderRadius: 8,
    },
  })();
};
