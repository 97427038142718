import {
  WalletProfileResponse,
  WalletBreakdownRequest,
  WalletBreakdownResponse,
  WalletSpendRequest,
  AddPromoCodeRequest,
  AddPromoCodeResponse,
} from '../../models/wallet/wallet';
import { StoreAction } from '../StoreHelper';

export enum WalletActionTypes {
  GET_WALLET_PROFILE = 'GET_WALLET_PROFILE',
  GET_WALLET_PROFILE_REQUEST = 'GET_WALLET_PROFILE_REQUEST',
  GET_WALLET_PROFILE_SUCCESS = 'GET_WALLET_PROFILE_SUCCESS',
  GET_WALLET_PROFILE_ERROR = 'GET_WALLET_PROFILE_ERROR',

  GET_WALLET_BREAKDOWN = 'GET_WALLET_BREAKDOWN',
  GET_WALLET_BREAKDOWN_REQUEST = 'GET_WALLET_BREAKDOWN_REQUEST',
  GET_WALLET_BREAKDOWN_SUCCESS = 'GET_WALLET_BREAKDOWN_SUCCESS',
  GET_WALLET_BREAKDOWN_ERROR = 'GET_WALLET_BREAKDOWN_ERROR',

  APPLY_WALLET_SPEND_REQUEST = 'APPLY_WALLET_SPEND_REQUEST',
  APPLY_WALLET_SPEND_SUCCESS = 'APPLY_WALLET_SPEND_SUCCESS',
  APPLY_WALLET_SPEND_ERROR = 'APPLY_WALLET_SPEND_ERROR',
  APPLY_WALLET_SPEND_RESET = 'APPLY_WALLET_SPEND_RESET',

  ADD_PROMO_CODE_REQUEST = 'ADD_PROMO_CODE_REQUEST',
  ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS',
  ADD_PROMO_CODE_ERROR = 'ADD_PROMO_CODE_ERROR',
  ADD_PROMO_CODE_RESET = 'ADD_PROMO_CODE_RESET',
}

export type WalletActionPayload =
  | number
  | WalletProfileResponse
  | WalletBreakdownRequest
  | WalletBreakdownResponse
  | WalletSpendRequest[]
  | AddPromoCodeRequest
  | AddPromoCodeResponse
  | null
  | Error;

export type WalletAction = StoreAction<WalletActionTypes, WalletActionPayload>;

export class WalletActions {
  // This will get the wallet profile consisting of the total, cash and promo
  public static getWalletProfileRequest(): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_PROFILE_REQUEST,
      data: null,
    };
  }

  public static getWalletProfileSuccess(response: WalletProfileResponse): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_PROFILE_SUCCESS,
      data: response,
    };
  }

  public static getWalletProfileError(error: Error): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_PROFILE_ERROR,
      data: error,
    };
  }

  // This will get the wallet breakdown
  public static getWalletBreakdownRequest(params: WalletBreakdownRequest): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_BREAKDOWN_REQUEST,
      data: params,
    };
  }

  public static getWalletBreakdownSuccess(response: WalletBreakdownResponse): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_BREAKDOWN_SUCCESS,
      data: response,
    };
  }

  public static getWalletBreakdownError(error: Error): WalletAction {
    return {
      type: WalletActionTypes.GET_WALLET_BREAKDOWN_ERROR,
      data: error,
    };
  }

  // This will allow users to apply wallet and promo credits
  public static applyWalletSpendRequest(params: WalletSpendRequest[]): WalletAction {
    return {
      type: WalletActionTypes.APPLY_WALLET_SPEND_REQUEST,
      data: params,
    };
  }

  public static applyWalletSpendSuccess(response: WalletProfileResponse): WalletAction {
    return {
      type: WalletActionTypes.APPLY_WALLET_SPEND_SUCCESS,
      data: response,
    };
  }

  public static applyWalletSpendError(error: Error): WalletAction {
    return {
      type: WalletActionTypes.APPLY_WALLET_SPEND_ERROR,
      data: error,
    };
  }

  public static applyWalletSpendReset(): WalletAction {
    return {
      type: WalletActionTypes.APPLY_WALLET_SPEND_RESET,
      data: null,
    };
  }

  // This will allow users to add a promo code
  public static addPromoCodeRequest(params: AddPromoCodeRequest): WalletAction {
    return {
      type: WalletActionTypes.ADD_PROMO_CODE_REQUEST,
      data: params,
    };
  }

  public static addPromoCodeSuccess(response: AddPromoCodeResponse): WalletAction {
    return {
      type: WalletActionTypes.ADD_PROMO_CODE_SUCCESS,
      data: response,
    };
  }

  public static addPromoCodeError(error: Error): WalletAction {
    return {
      type: WalletActionTypes.ADD_PROMO_CODE_ERROR,
      data: error,
    };
  }

  public static addPromoCodeReset(): WalletAction {
    return {
      type: WalletActionTypes.ADD_PROMO_CODE_RESET,
      data: null,
    };
  }
}
