import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { MultipartFormData } from '../MultipartFormData.data';
import { createAddressString } from '../../containers/propertyOnboarding/PropertyOnboardingUtils';
import { getStreetName } from '../../containers/propertyOnboarding/PropertyOnboardingConstants';
import { PropertyOnboardingRequest } from '../../models/property/property';

interface PostPropertyRequest {
  type: string;
  propertyClass: string;
  address: string;
  unitNumber: string;
  streetType?: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export class PropertyOnboardService {
  public postProperty(data: PropertyOnboardingRequest): Promise<void> | void {
    const service = new ApiService(ServiceType.propertyOnboard);
    const postBody: PostPropertyRequest = {
      ...{
        type: data.selectedType,
        propertyClass: data.selectedClass,
        address: createAddressString(data.address),
      },
      ...data.address,
    };
    postBody.streetName = getStreetName(postBody.streetName, postBody.streetType!);
    delete postBody.streetType;
    const multipartData: MultipartFormData[] = [
      {
        name: 'property',
        content: JSON.stringify(postBody),
      },
    ];
    return service.postMultipart({}, multipartData);
  }
}

const propertyService = new PropertyOnboardService();
export default propertyService;
