/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { Services, Suppliers } from '../../models/services/services';
import { useStyles } from './EmptyServiceCardStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { resetService, shouldResetService } from '../../helpers/ResetServiceHelper';
import ICON_CHEVRON from '../../assets/navigation/accordian.png';
import { IntercomChat } from '../../helpers/IntercomChat';
import { Route, navigate } from '../../navigation/NavigationUtils';

interface EmptyServiceCardProps {
  serviceName: string;
  leftIcon: any;
  rightIcon: any;
  redirectTo: Route;
  redirectProps?: any;
  disabled?: boolean;
  serviceInfo?: Services;
  suppliers?: Suppliers[];
}

const EmptyServiceCard: FC<EmptyServiceCardProps> = ({
  serviceName,
  leftIcon,
  rightIcon,
  redirectTo,
  redirectProps,
  disabled,
  serviceInfo,
  suppliers,
}) => {
  const showOptions = suppliers && suppliers.length > 1;
  const classes = useStyles();

  return (
    <div
      onClick={() => {
        if (!disabled) {
          IntercomChat.logIntercomEvent(serviceName, { dashboard_tile: serviceName });
          IntercomChat.logIntercomEvent(suppliers![0].SupplierId, {
            dashboard_tile: serviceName,
          });
          if (serviceInfo && shouldResetService(serviceInfo, suppliers![0])) {
            resetService(serviceInfo);
          }
          navigate(redirectTo, !!redirectProps);
        }
      }}
      className={classes.serviceCardItem}
    >
      <div className={classes.cardLeftView}>
        <img className={classes.serviceIcon} src={leftIcon} />
        <div className={classes.serviceName}>{serviceName}</div>
      </div>
      <div className={classes.supplierCountArea}>
        {/* {showOptions && (
              <View className={classes.supplierCount}>
                <CustomText>+{suppliers!.length}</CustomText>
              </View>
            )} */}
        <img className={classes.icon} src={ICON_CHEVRON} />
      </div>
    </div>
  );
};

export default EmptyServiceCard;
