/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    gravatar: {
      height: 50,
      width: 50,
      borderRadius: 5000,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.borderColorLight,
      flexShrink: 0,
      alignSelf: 'center',
    },
    gravatarText: {
      color: theme.colors.light,
      fontSize: 20,
    },
    cardContainer: {
      padding: theme.units.spacing * 4,
      marginTop: 12,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    titleContainer: {
      marginLeft: 12,
      marginRight: 'auto',
    },
    title: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    description: {
      marginTop: 4,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    detailsTitle: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
      marginTop: theme.units.spacing * 8,
      marginLeft: theme.units.spacing * 4,
    },
    detailRow: {
      marginBottom: 16,
    },
    detailTitle: {
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    detailsTitle2: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
      marginTop: theme.units.spacing * 8,
      marginLeft: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    detailDescription: {
      marginTop: 6,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    editButton: {
      minWidth: 60,
    },
    cancelButton: {
      marginRight: theme.units.spacing * 4,
      minWidth: 60,
    },
    redLink: {
      color: theme.colors.error,
    },
    inputStyle: {
      minHeight: 40,
      fontSize: 14,
      borderBottomColor: theme.colors.black38,
      borderBottomWidth: 1,
      display: 'flex',
      flex: 1,
      paddingHorizontal: 12,
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 0,
    },
    inputErrorStyle: {
      borderBottomColor: theme.colors.error,
      borderBottomWidth: 1,
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
    },
    phoneContainerStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    phoneTextStyle: {},
    phoneInputStyles: {
      marginTop: 6,
      marginBottom: 0,
    },
  })();
};
