import { InvoiceResponse } from '../../models/invoices/invoices';

export const LABELS = {
  BANK_ACCOUNT: 'BANK ACCOUNT',
  CREDIT_CARD: 'CREDIT CARD',
  NOTAVAILABLE: 'PAYMENT METHOD',
  ADDPAYMENTLABEL: 'Add a payment method',
  ADJUST: 'Adjust',
  EDIT: 'Edit',
  ADD: 'Add',
  PAYMENTS: 'PAYMENTS',
  NEXT_PAYMEMT_DATE: 'NEXT PAYMEMT DATE',
  INACTIVE: '(Closed)',
  PAYMENT_SECTION: 'Payment details',
  SERVICE_SECTION: 'Your current plan',
  SERVICE_SECTION_INACTIVE: 'Your current plan (Ended)',
};

export const DEFAULT_SERVICE_PROVIDER = 'Sorted Services';
export const DETAIL_CARD_DATE_FORMAT = 'DD MMM YYYY';

export interface InvoiceData {
  [key: string]: InvoiceResponse[];
}
