/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import {
  maxContentWidth,
  pageContainer,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingTop: theme.units.spacing * 6,
      },
    },

    pageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    primaryContainer: {
      ...primaryContent,
      marginTop: -theme.units.spacing * 8,
      zIndex: 1,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    marketplaceBanner: {
      position: 'relative',
      borderRadius: theme.units.borderRadius * 2,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.lightGreen,
      paddingTop: theme.units.spacing * 4,
      minHeight: 400,
      maxWidth: 1200,
    },
    logo: {
      position: 'absolute',
      top: theme.units.spacing * 10,
      left: theme.units.spacing * 12,
      height: theme.units.iconSize,
      width: 100,
      objectPosition: 'center',
      objectFit: 'contain',
    },
    titleContainer: {
      marginRight: theme.units.spacing * 8,
      marginLeft: theme.units.spacing * 12,
      alignSelf: 'center',
      maxWidth: 440,
    },
    title: {
      color: theme.colors.title,
      fontSize: 60,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black60,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      lineHeight: 1.5,
    },
    bannerImageContainer: {
      height: '100%',
      width: 400,
      display: 'flex',
      flexShrink: 0,
    },
    bannerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'bottom',
    },
    noServices: {
      marginTop: 8,
      fontSize: 14,
      fontWeight: getFonts('Light'),
      lineHeight: 18,
      color: theme.colors.black38,
    },
    grid: {
      marginTop: theme.units.spacing * 4,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.units.spacing * 4,
      minWidth: undefined,
    },
    banner: {
      marginRight: theme.units.spacing * 4,
    },
    servicesGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    offerContainer: {
      position: 'relative',
      borderRadius: theme.units.borderRadius,
      minHeight: 140,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    offerTitle: {
      height: 80,
      objectFit: 'contain',
      objectPosition: 'left',
      marginBottom: theme.units.spacing * 6,
      marginTop: theme.units.spacing * 4,
      marginLeft: theme.units.spacing * 4,
    },
    offerSubtitle: {
      fontSize: theme.font.s,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      marginBottom: theme.units.spacing * 5,
      marginLeft: theme.units.spacing * 4,
    },
    offerButton: {
      marginLeft: theme.units.spacing * 4,
      minWidth: 60,
      marginBottom: theme.units.spacing * 4,
    },
    helloFresh: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 220,
      objectFit: 'contain',
      objectPosition: 'top right',
    },
    blys: {
      position: 'absolute',
      top: theme.units.spacing * 4,
      right: theme.units.spacing * 4,
      width: 120,
      objectFit: 'contain',
    },
    categoryGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr 1fr',
      },
      gridGap: theme.units.spacing * 4,
      width: '100%',
    },
    categoryContainer: {
      backgroundColor: theme.colors.lightGrey,
      borderRadius: theme.units.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    categoryTitle: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: theme.units.spacing * 4,
    },
    categoryImage: {
      width: 140,
      objectFit: 'contain',
      alignSelf: 'flex-end',
      objectPosition: 'bottom right',
    },
  })();
};
