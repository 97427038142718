/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import { SetOnboardingPropertyDetails } from '../../../../models/property/property';
import Text from '../../../../components/text/Text';
import { useStyles } from './AddPropertyStyles';
import { navigate } from '../../../../navigation/NavigationUtils';
import { PropertyOnboardingStep } from '../../PropertyOnboardingConstants';
import { GooglePlacesHelper } from '../../../../helpers/GooglePlacesHelper';
import { AddressValue } from '../../../../helpers/AddressValue.data';
import AddressPicker from '../../../../components/addressPicker/AddressPicker';

interface AddPropertyProps {
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  goToNextPage: () => void;
}

const AddProperty: FC<AddPropertyProps> = ({ setPropertyDetails, goToNextPage }) => {
  const [showText, setShowText] = useState<boolean>(false);
  const [addressValue, setAddressValue] = useState<any>(undefined);
  const classes = useStyles();

  return (
    <div>
      <AddressPicker
        id="propertyAddressPicker"
        value={addressValue}
        disabled={false}
        placeholder={'Enter your address'}
        onKeyPress={(text: string) => {
          setShowText(!!text.length);
        }}
        handleAddressChanged={(value: AddressValue) => {
          setShowText(false);
          setPropertyDetails({
            field: 'address',
            value: GooglePlacesHelper.getAddressFeildsFromGoogleAPI(value),
          });
          setAddressValue(value);
          goToNextPage();
        }}
      />
      <Text
        onPress={() => {
          setPropertyDetails({
            field: 'address',
            value: {
              state: '',
              country: 'Australia',
              postcode: '',
              streetName: '',
              streetNumber: '',
              streetType: '',
              unitNumber: '',
              suburb: '',
            },
          });
          navigate('propertyOnboarding', { page: PropertyOnboardingStep.ManualEntry });
        }}
        textVariant="link"
        parentStyles={showText ? classes.cantFindText : classes.hide}
      >
        Cant find your address?
      </Text>
    </div>
  );
};

export default AddProperty;
