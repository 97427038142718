/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    root: {
      backgroundColor: '#fff',
      padding: theme.units.spacing * 4,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 2px solid`,
      fontSize: 18,
      fontWeight: getFonts('Medium'),
    },
    searchContainer: {
      top: '0',
      position: 'relative',
      // transition: 'all 0.5s ease 0s',
    },
    searchIcon: {
      marginLeft: 6,
      marginRight: 16,
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    crossIcon: {
      marginLeft: 12,
      marginRight: 6,
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    searchLogo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
