import React, { FC, useState, useMemo, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ServiceActions } from '../../../../store/actions/ServiceActions';
import { useStyles } from './InvoicesStyles';
import { ServiceTypes } from '../../../../models/services/services';
import BarChartIcon from '../../../../assets/bar-chart.png';
import { DateFormatter } from '../../../../helpers/DateFormatter';
import { ApplicationState } from '../../../../store/RootReducer';
import ActivityIndicator from '../../../../components/activityIndicator/ActivityIndicator';
import {
  InvoiceDownloadRequest,
  InvoiceRequest,
  InvoiceResponse,
} from '../../../../models/invoices/invoices';
import { Dialog } from '@mui/material';
import Button from '../../../../components/button/Button';

interface InvoiceData {
  [key: string]: InvoiceResponse[];
}

interface InvoicesProps {
  invoices: InvoiceResponse[];
  invoicesLoading: boolean;
  serviceAccountId: number;
  serviceType: ServiceTypes;
  getInvoices: (params: InvoiceRequest) => void;
  downloadInvoice: (params: InvoiceDownloadRequest) => void;
}

const Invoices: FC<InvoicesProps> = ({
  invoices,
  invoicesLoading,
  serviceAccountId,
  serviceType,
  getInvoices,
  downloadInvoice,
}) => {
  const classes = useStyles();

  const getUsableServiceName = (serviceName: ServiceTypes) =>
    serviceName === ServiceTypes.Electricity ? 'Electricity' : 'Broadband';

  const groupedInvoices = useMemo<InvoiceData | undefined>(() => {
    if (invoices.length > 0) {
      const parsedData = invoices.map((invoice) => {
        const newInvoice = invoice;
        newInvoice.formattedBillDate = DateFormatter.format(invoice.BillDate, 'YYYY');
        return newInvoice;
      });
      return parsedData.reduce((invoice, index) => {
        (invoice[index.formattedBillDate] = invoice[index.formattedBillDate] || []).push(index);

        return invoice;
      }, {});
    } else {
      return undefined;
    }
  }, [invoices]);

  useEffect(() => {
    getInvoices({
      OrderBy: 'Descending',
      ServiceType: getUsableServiceName(serviceType),
      serviceAccountId,
    });
  }, []);

  const handleInvoiceTap = (documentId: number, periodId: string) => {
    serviceType === ServiceTypes.Electricity
      ? downloadInvoice({
          serviceType,
          documentId: `${documentId}`,
          periodId: `${documentId}`,
        })
      : downloadInvoice({ serviceType, documentId: periodId, periodId });
  };

  return (
    <>
      {invoicesLoading ? (
        <div className={classes.emptyInvoiceCard}>
          <ActivityIndicator size="large" />
        </div>
      ) : (
        <>
          {groupedInvoices ? (
            <>
              {Object.keys(groupedInvoices)
                .reverse()
                .map((key: string) => (
                  <div className={classes.invoiceCard}>
                    <div className={classes.flexBox}>
                      <div className={classes.invoiceYear}>{key}</div>
                    </div>
                    {groupedInvoices[key].map((invoice: InvoiceResponse) => (
                      <div
                        className={classes.invoiceList}
                        onClick={() => handleInvoiceTap(invoice.DocumentId, invoice.PeriodId)}
                      >
                        <img src={BarChartIcon} className={classes.invoiceImage} />
                        <div className={classes.invoiceVerticalBox}>
                          <div className={classes.invoiceTitle}>{serviceType.toString()}</div>
                          <div className={classes.invoiceService}>Sorted Services</div>
                        </div>
                        <div className={classes.invoiceAmountBox}>
                          <div className={classes.invoiceAmount}>{`$${invoice.Cost}`}</div>
                          <div className={classes.invoiceService}>
                            {DateFormatter.format(invoice.BillDate, 'DD MMMM')}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </>
          ) : (
            <div className={classes.emptyInvoiceCard}>
              <div>No invoices to show</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    genericServiceSummary: state.services.genericServiceSummary,
    invoices: state.services.invoices,
    invoicesLoading: state.services.fetchingInvoices,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getInvoices: (params: InvoiceRequest) => dispatch(ServiceActions.fetchInvoicesRequest(params)),
  downloadInvoice: (params: InvoiceDownloadRequest) =>
    dispatch(ServiceActions.downloadInvoiceRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
