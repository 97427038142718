import React, { FC, useEffect, useState, useContext, createContext } from 'react';
import { ThemeOptions, createTheme } from '@mui/material/styles';
import SORTED from './themes/SORTED';
import NAX from './themes/NAX';
import SWITCH from './themes/SWITCH';
import localStorage, { LocalStorageKeys } from '../services/LocalStorage';
import { store } from '../store/Store';
import { ApplicationState } from '../store/RootReducer';
import { useSelector } from 'react-redux';

export type ThemeType = typeof SORTED;

const themeCollection: ThemeType[] = [
  SORTED,
  NAX,
  SWITCH,
  {
    ...SWITCH,
    key: 'SWITCH_VIC',
    colors: {
      ...SWITCH.colors,
      actionButtonSecondary: '#F22E5B',
      gradient1: '#782A8F',
      gradient2: '#F22E5B',
    },
  },
  {
    ...SWITCH,
    key: 'SWITCH_SA',
    colors: {
      ...SWITCH.colors,
      actionButtonSecondary: '#DC6B19',
      gradient1: '#B91A01',
      gradient2: '#DC6B19',
    },
  },
  {
    ...SWITCH,
    key: 'SWITCH_WA',
    colors: {
      ...SWITCH.colors,
      actionButtonSecondary: '#69C39A',
      gradient1: '#69C39A',
      gradient2: '#66BC46',
    },
  },
  {
    ...SWITCH,
    key: 'SWITCH_NSW',
    colors: {
      ...SWITCH.colors,
      actionButtonSecondary: '#264294',
      gradient1: '#264294',
      gradient2: '#03866F',
    },
  },
];
const defaultTheme = themeCollection[0];
export enum ThemeKey {
  SORTED = 'SORTED',
  NAX = 'NAX',
  MM = 'MM',
  SWITCH = 'SWITCH',
  SWITCH_VIC = 'SWITCH_VIC',
  SWITCH_SA = 'SWITCH_SA',
  SWITCH_WA = 'SWITCH_WA',
  SWITCH_NSW = 'SWITCH_NSW',
}

export const getTheme = (themeKey?: ThemeKey): ThemeType => {
  return {
    ...(themeCollection.find((theme) => theme.key === themeKey) || defaultTheme),
  };
};

// static
export let theme = getTheme();
export let getThemeKey = () => store.getState().themeState.themeKey;

// dynamic
export const getCurrentThemeKey = () => {
  const getCurrentThemeKey = (state: ApplicationState) => state.themeState.themeKey;
  return useSelector(getCurrentThemeKey);
};

export const getCurrentTheme = () => {
  const themeKey = getCurrentThemeKey();
  return getTheme(themeKey);
};

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'GT Walsheim',
  },
};

const appTheme = createTheme(themeOptions);

export default appTheme;
