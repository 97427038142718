/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    headerContainer: {
      position: 'relative',
      height: 86,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.colors.borderColorLight}`,
      paddingLeft: 96,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        paddingLeft: 48,
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        paddingLeft: 12,
      },
    },
    sortedLogoContainer: {
      flexShrink: 0,
      position: 'relative',
      marginRight: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      [`@media (max-width: ${SIZES.md}px)`]: {
        marginRight: '16px',
      },
      transition: 'all 0.5s ease 0s',
    },
    sortedLogo: {
      position: 'relative',
      height: '30px',
      left: 0,
      transition: 'all 0.5s ease 0s',
    },
    naxLogo: {
      position: 'relative',
      height: '35px',
      left: 0,
      transition: 'all 0.5s ease 0s',
    },
    poweredBySortedLogo: {
      height: '26px',
      left: 76,
      transition: 'all 0.5s ease 0s',
    },
    sortedLogoText: {
      position: 'absolute',
      opacity: 0,
      top: 6,
      bottom: 0,
      left: 0,
      margin: 'auto',
      height: '9px',
      transition: 'all 0.5s ease 0s',
    },
    poweredByLogoText: {
      transition: 'all 0.5s ease 0s',
      // left: -76,
      opacity: 1,
    },
    divider: {
      height: 27,
      width: 2,
      backgroundColor: theme.colors.borderColor,
      flexShrink: 0,
    },
    profileContainer: {
      paddingLeft: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      height: 42,
      border: `${theme.colors.borderColor} 1px solid`,
      borderRadius: 8,
      marginRight: theme.units.spacing * 4,
      width: 100,
    },
    profileIcon: {
      height: theme.units.iconSize,
      width: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    profileText: {
      color: theme.colors.black87,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginRight: theme.units.spacing * 2,
    },
    selected: {
      borderBottom: `${theme.colors.error} 3px solid`,
    },
    propertyMenu: {
      position: 'absolute',
      top: 104,
      right: 116,
      width: 600,
      minWidth: 'max-content',
      paddingTop: theme.units.spacing * 2,
      paddingBottom: theme.units.spacing * 2,
      backgroundColor: theme.colors.inputBackground,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 2px solid`,
      zIndex: 10,
      [`@media (max-width: ${SIZES.md}px)`]: {
        right: 16,
        width: 'calc(100% - 32px)',
      },
    },
    menu: {
      position: 'absolute',
      top: 104,
      right: 16,
      width: 200,
      minWidth: 'max-content',
      paddingTop: theme.units.spacing * 2,
      paddingBottom: theme.units.spacing * 2,
      backgroundColor: theme.colors.inputBackground,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 2px solid`,
      zIndex: 10,
    },
    menuDivider: {
      height: 1,
      width: '100%',
      backgroundColor: theme.colors.borderColorLight,
      marginTop: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 2,
    },
    menuItem: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Medium'),
      color: theme.colors.inputText,
      padding: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    menuItemLight: {
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
      color: theme.colors.inputText,
      padding: theme.units.spacing * 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
        fontWeight: getFonts('Medium'),
      },
    },
    selectedMenuItem: {
      color: theme.colors.buttonPrimary,
      fontWeight: getFonts('Medium'),
    },
    rightContent: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      height: '100%',
    },
    propertyContainer: {
      border: `${theme.colors.borderColor} 1px solid`,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 240,
      height: 42,
      cursor: 'pointer',
      marginRight: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.md}px)`]: {
        minWidth: 0,
      },
    },
    propertyText: {
      color: theme.colors.black87,
      marginRight: 8,
      marginLeft: 16,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      [`@media (max-width: ${SIZES.md}px)`]: {
        fontSize: 14,
        marginRight: 0,
        display: 'none',
      },
    },
    house: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: 12,
      marginLeft: 12,
    },
    /// CART ---------------------------------------------------------- //
    cartContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 8,
      padding: theme.units.spacing * 2,
      marginRight: theme.units.spacing * 4,
      cursor: 'pointer',
      position: 'relative',
    },
    cartIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      objectFit: 'contain',
      objectPosition: 'center',
      filter: 'invert(1)',
    },
    cartCount: {
      position: 'absolute',
      right: -4,
      top: -4,
      width: 14,
      height: 14,
      backgroundColor: theme.colors.lightPurple,
      border: `${theme.colors.light} 1px solid`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: theme.colors.sortedPurple,
      fontWeight: getFonts('Medium'),
      fontSize: 11,
    },
    dialog: {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      overflow: 'hidden',
    },
  })();
};
