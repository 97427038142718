import React, { FC } from 'react';
import { useStyles } from './AccountDetailsStyles';
import { KeyValuePair } from '../../../../models/services/genericServiceSummary';

interface AccountDetailsProps {
  data: KeyValuePair[];
}

const AccountDetails: FC<AccountDetailsProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={classes.sectionKey}>{data[0].key}</div>
        <div className={classes.sectionValue}>{data[0].value}</div>
      </div>
      <div className={classes.marginTop}>
        <div className={classes.sectionKey}>{data[1].key}</div>
        <div className={classes.sectionValue}>{data[1].value}</div>
      </div>
    </>
  );
};

export default AccountDetails;
