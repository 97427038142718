export const LABELS = {
  NEW_PROPERTY_FOUND: 'New property found',
  NEW_PROPERTY_FOUND_INFO: 'Tap the button below to set up rent payments for your new property',
  NEW_PROPERTY_FOUND_BUTTON: 'Add property',
  NO_PROPERTY_NAX:
    "It doesn't look like you have a property associated to your profile. If you're a homeowner, get started by tapping on Add property via dashboard, else a property will be added once your rental application is successful.",
  LOGIN: 'Log in to start getting your place Sorted.',
};

export const NO_PROPERTY = {
  TITLE: "It doesn't look like you have added your address yet. ",
  TEXT: "It doesn't look like you have added your address yet. Add your place to start adding services and more.",
  BUTTON: 'Add property',
};
