import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { getFonts } from '../../../../helpers/GetFonts';
import { SIZES } from '../../../../constants/Sizes';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    popupContainer: {
      backgroundColor: theme.colors.light,
      height: '100%',
    },
    popupHeader: {
      margin: theme.units.spacing * 6,
      paddingBottom: theme.units.spacing * 6,
      backgroundColor: theme.colors.light,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.units.spacing * 4,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
    },
    popupLabel: {
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 24,
    },
    informationContainer: {
      display: 'flex',
      overflow: 'scroll',
      width: '100%',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      marginLeft: theme.units.spacing * 6,
      marginRight: theme.units.spacing * 6,
      gap: theme.units.spacing * 4,
    },
    closeContainer: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      cursor: 'pointer',
    },
    closeIcon: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
  })();
};
