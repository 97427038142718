export interface LoginRequest {
  password: string;
  username: string;
  // fcm_token: string;
}

export interface AuthDetails {
  userId: number;
  email: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  resetRequired?: boolean;
  intercomId: string;
  superUser: boolean;
}

export interface AuthDetailsResponse {
  userId: number;
  email: string;
  authToken: string;
  refreshToken: string;
  expires_in: string;
  passwordResetRequired?: boolean;
  intercomId: string;
  superUser: boolean;
}

export interface FindUserPayload {
  readonly username: string;
}

export interface FindUserResponse {
  readonly id: number | null;
  readonly username: string | null;
  readonly authId: string | null;
  readonly active: boolean | null;
  readonly createdDate: string | null;
  readonly lastModifiedDate: string | null;
}

export interface ForgotPasswordRequest {
  readonly application_name: 'TenancyPortal';
  readonly product_name?: string;
  username: string;
  onSuccess?: () => void;
}

export interface EmailExists {
  email: string;
  registered: boolean;
  exists: boolean; // Not being used now
  active: boolean;
}

export interface RegisterUserRequest {
  email: string;
  password: string;
  name: string;
  phone: string;
  surname: string;
  readonly onSuccess?: () => void;
}

export interface RegisterUserPayload {
  readonly email: string;
  readonly password: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly redirectionParams?: string;
}

export interface SessionTokenResponse {
  access_token: string;
  refresh_token: string;
}

export interface PasswordRequest {
  password: string;
}

export enum ForgotPasswordToastMesssages {
  wrongEmailResponse = 'User not found',
  emailSuccess = 'Details sent to registered email',
  emailError = 'Something went wrong!',
  buttonText = 'Okay',
}
