import { takeEvery, put } from 'redux-saga/effects';
import CimetService from '../../services/cimet/CimetService';
import { CimetAction, CimetActionTypes, CimetActions } from '../actions/CimetActions';
import {
  CimetDatesRequest,
  CimetDatesResponse,
  CimetNeedsRequest,
  CimetNeedsResponse,
  CimetServicesRequest,
  CimetServicesResponse,
  CimetVisitRequest,
} from '../../models/cimet/Cimet';
import { ToastMessage } from '../../helpers/ToastMessage';

function* getCimetServicesRequest(action: CimetAction) {
  try {
    const response: CimetServicesResponse = yield CimetService.getCimetServices(
      action.data as CimetServicesRequest,
    );
    yield put(
      CimetActions.getCimetServicesSuccess({
        propertyId: (action.data as CimetServicesRequest).propertyId,
        data: response,
      }),
    );
  } catch (error) {
    yield put(CimetActions.getCimetServicesError(error as Error));
  }
}

function* postCimetVisitRequest(action: CimetAction) {
  try {
    yield CimetService.postCimetVisit(action.data as CimetVisitRequest);
    yield put(CimetActions.postCimetVisitSuccess(null));
  } catch (error) {
    yield put(CimetActions.postCimetVisitError(error as Error));
  }
}

function* postCimetNeedsRequest(action: CimetAction) {
  try {
    const response: CimetNeedsResponse = yield CimetService.postCimetNeeds(
      action.data as CimetNeedsRequest,
    );
    yield put(CimetActions.postCimetNeedsSuccess(response));
  } catch (error) {
    ToastMessage.showMessage((error as Error).message, '', 'danger');
    yield put(CimetActions.postCimetNeedsError(error as Error));
  }
}

function* getCimetDatesRequest(action: CimetAction) {
  try {
    const response: CimetDatesResponse[] = yield CimetService.getCimetDates(
      action.data as CimetDatesRequest,
    );
    yield put(CimetActions.getCimetDatesSuccess(response));
  } catch (error) {
    yield put(CimetActions.getCimetDatesError(error as Error));
  }
}

function* cimetWatcher() {
  yield takeEvery(CimetActionTypes.GET_CIMET_SERVICES_REQUEST, (action: CimetAction) =>
    getCimetServicesRequest(action),
  );
  yield takeEvery(CimetActionTypes.POST_CIMET_VISIT_REQUEST, (action: CimetAction) =>
    postCimetVisitRequest(action),
  );
  yield takeEvery(CimetActionTypes.POST_CIMET_NEEDS_REQUEST, (action: CimetAction) =>
    postCimetNeedsRequest(action),
  );
  yield takeEvery(CimetActionTypes.GET_CIMET_DATES_REQUEST, (action: CimetAction) =>
    getCimetDatesRequest(action),
  );
}

export default cimetWatcher;
