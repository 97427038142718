export const LABELS = {
  NAV_TITLE: 'Bank account',
  NAV_SUBTITLE:
    'Please see your bank account details below — we have safely stored these details for you.',
  ACCOUNT_NAME: 'Account name',
  BSB: 'BSB',
  ACCOUNT_NUMBER: 'Account number',
  DELETE: 'Delete account',
  DELETE_DRAWER_TITLE: 'Delete payment method',
  DELETE_DRAWER_SUBTITLE: 'Are you sure you want to delete this payment method from your wallet?',
  WARNING_DRAWER_TITLE: 'Payment method is in use',
  WARNING_DRAWER_SUBTITLE1:
    "You can't delete a payment method linked to an active service.Select a service from the dasboard, then edit, or add a payment type under 'payment details'",
};
