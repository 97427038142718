/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState } from 'react';
import verified from '../../assets/verified.png';
import { History } from 'history';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { routes } from '../../Routes';
import { GroupRequest } from '../../models/groups/Groups';
import {
  ApplicantProfile,
  PostPersonalDetailsFormData,
} from '../../models/masterProfile/MyProfileFormState';
import { ApplicationState } from '../../store/RootReducer';
import {
  DependentProfile,
  SubmittedApplicationResponse,
} from '../../models/masterProfile/MasterProfile';
import { LandingScreenState } from '../../store/state/LandingScreenState';
import { useStyles } from './ManagedProfilesStyles';
import { Verified } from '../../models/equifax/equifax';
import Box from '../box/Box';
import Text from '../text/Text';
import Button from '../button/Button';
import { stringToHslColor } from '../../helpers/GravatarHelper';
import { classNameGenerator } from '../../theme/GlobalStyles';
import PercentageWheel from '../percentageWheel/PercentageWheel';
import { LABELS } from './ManagedProfilesConstants';
import {
  dependentProfileStatus,
  myProfileStatus,
  secondaryProfileStatus,
} from './ManagedProfilesUtils';
import { isVerified } from '../../helpers/NTDHelper';
import { GroupsActions } from '../../store/actions/GroupsActions';
import { MasterProfileActions } from '../../store/actions/MasterProfileActions';
import { prefillForms } from '../../helpers/PrefillFormHelper';
import { ProfileState } from '../../store/state/ProfileState';
import { AppSettings } from '../../AppSettings';

interface ManagedProfilesProps {
  profile: ProfileState;
  tenantDetails: LandingScreenState;
  myProfileDetails: ApplicantProfile;
  secondaryApplicantProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  masterProfile: SubmittedApplicationResponse;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null,
  ) => void;
  getMasterProfile: () => void;
}

const ManagedProfiles: FC<ManagedProfilesProps> = ({
  profile,
  tenantDetails,
  myProfileDetails,
  secondaryApplicantProfiles,
  dependentProfiles,
  masterProfile,
  getMasterProfile,
  prefillData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getMasterProfile();
  }, [secondaryApplicantProfiles.length, dependentProfiles.length]);

  useEffect(() => {
    if (masterProfile) {
      prefillData(masterProfile, false, false, null);
    }
  }, [masterProfile]);

  const renderProfileCard = (
    onClick: () => void | null,
    profileName: string,
    profileContext: string,
    statusText: string,
    statusClass: string,
    percentage: number,
    actions?: any,
    verifiedBadge?: Verified,
  ) => {
    return (
      <Box lightBorder parentStyles={classes.applicationProfileCard}>
        <div className={classes.profileRow}>
          <div
            className={classes.gravatar}
            style={{ backgroundColor: stringToHslColor(`${profileName}`, 70, 70) }}
          >
            {profileName.split(' ')[0].charAt(0)}
            {profileName.split(' ')[1].charAt(0)}
          </div>
          <div className={classes.applicationProfileDetails}>
            <div className={classes.titleRow}>
              <Text textVariant="title" size="xl">
                {profileContext}
              </Text>
              {verifiedBadge !== undefined &&
                [Verified.VERIFIED, Verified.EXPIRED].includes(verifiedBadge) && (
                  <div className={classes.verifiedBadge}>
                    <img
                      src={verified}
                      className={classNameGenerator([
                        classes.verifiedIcon,
                        verifiedBadge === Verified.EXPIRED && classes.unverifiedBadge,
                      ])}
                      alt="badge"
                    />
                    <div className={classes.verifiedText}>
                      {verifiedBadge === Verified.EXPIRED ? LABELS.EXPIRED : LABELS.VERIFIED}
                    </div>
                  </div>
                )}
              <div
                className={`${classes.statusContainer} ${
                  statusClass === 'incompleteLabel'
                    ? classes.incompleteLabel
                    : classes.completeLabel
                }`}
              >
                <div className={classes.statusText}>{statusText}</div>
              </div>
            </div>
            <Text textVariant="info">{profileName}</Text>
          </div>
          <PercentageWheel completed={percentage} parentStyles={classes.percentageWheel} />
        </div>
        <div className={classes.lineRow}>
          {actions}
          <Button muted parentStyles={classes.buttonMarginRight} onPress={() => onClick()}>
            {LABELS.UPDATE}
          </Button>
        </div>
      </Box>
    );
  };

  // MY PROFILE ------------------------------------------------------------------- //
  const renderMyProfile = () => {
    const { statusText, statusClass, percentage } = myProfileStatus(myProfileDetails);
    const verifiedStatus = isVerified(myProfileDetails.identificationDetails);

    if (myProfileDetails.personalDetails) {
      const { firstName, middleName, lastName } = myProfileDetails.personalDetails;
      return (
        <>
          {renderProfileCard(
            () => {
              window.open(`${AppSettings.server.applyUILink}/#/my-profile`, '_blank');
            },
            `${firstName} ${middleName ? `${middleName} ` : ''}${lastName}`,
            LABELS.MY_PROFILE,
            statusText,
            statusClass,
            percentage,
            undefined,
            verifiedStatus,
          )}
        </>
      );
    } else {
      return null;
    }
  };

  // SECONDARY MANAGED PROFILES --------------------------------------------------- //
  const renderSecondaryApplicantProfiles = () => {
    return secondaryApplicantProfiles && secondaryApplicantProfiles.length
      ? secondaryApplicantProfiles.map((tenant, key) => {
          if (!(tenant.personalDetails && tenant.personalDetails.isManaged)) return null;

          const { statusText, statusClass, percentage } = secondaryProfileStatus(
            secondaryApplicantProfiles,
            tenant,
            key,
          );
          const verifiedStatus = isVerified(tenant.identificationDetails);

          return (
            <>
              {renderProfileCard(
                () => {
                  window.open(
                    `${
                      AppSettings.server.applyUILink
                    }/#/additional-profile/${tenant.personalDetails!.key!}`,
                    '_blank',
                  );
                },
                `${tenant.personalDetails.firstName} ${
                  tenant.personalDetails.middleName ? `${tenant.personalDetails.middleName} ` : ''
                }${tenant.personalDetails.lastName}`,
                LABELS.MANAGED_PROFILE,
                statusText,
                statusClass,
                percentage,
                undefined,
                verifiedStatus,
              )}
            </>
          );
        })
      : null;
  };

  // DEPENDENT MANAGED PROFILES --------------------------------------------------- //
  const renderDependentProfiles = () => {
    return dependentProfiles && dependentProfiles.length
      ? dependentProfiles.map((tenant, key) => {
          const { statusText, statusClass, percentage } = dependentProfileStatus(tenant);

          return (
            <>
              {renderProfileCard(
                () => {
                  window.open(
                    `${AppSettings.server.applyUILink}/#/additional-profile/${tenant.key!}`,
                    '_blank',
                  );
                },
                `${tenant.firstName} ${tenant.middleName ? `${tenant.middleName} ` : ''}${
                  tenant.lastName
                }${
                  tenant.selectedDateOfBirth
                    ? `, Born ${moment(tenant.selectedDateOfBirth).format('DD/MM/YYYY')}`
                    : ''
                }`,
                `${LABELS.MANAGED_DEPENDENT}`,
                statusText,
                statusClass,
                percentage,
                undefined,
              )}
            </>
          );
        })
      : null;
  };

  return (
    <div style={{ marginBottom: 72 }}>
      <div className={classes.detailsTitle2}>{LABELS.YOUR_APPLICATION_PROFILES}</div>
      {renderMyProfile()}
      {renderSecondaryApplicantProfiles()}
      {renderDependentProfiles()}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profile: state.profileState,
  tenantDetails: state.landingScreen,
  myProfileDetails: state.myProfileForm,
  secondaryApplicantProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
  masterProfile: state.masterProfile.masterProfileData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMasterProfile: () => dispatch(MasterProfileActions.getMasterProfileRequest()),
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagedProfiles);
