/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useState, useEffect, useMemo } from 'react';
// import './App.css';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ApplicationState } from './store/RootReducer';
import { TokenActions } from './store/actions/TokenActions';
import { getQueryParams } from './helpers/QueryHelper';
import { AuthDetail, saveAuthDetails } from './helpers/AuthHelper';
import { routes } from './Routes';
import { sendMessageToApp, ValidMessages } from './helpers/MessageHelper';
import { getPageTitle } from './helpers/AppNameHelper';
import Header from './components/header/Header';
import Menu from './components/menu/Menu';
import { useStyles } from './AppStyles';
import Home from './containers/home/Home';
import Login from './containers/login/Login';
import Account from './containers/account/Account';
import Browse from './containers/browse/Browse';
import ProfileSelection from './containers/profileSelection/ProfileSelection';
import CimetNeeds from './containers/cimetNeeds/CimetNeeds';
import LogRocket from 'logrocket';
import { AppSettings } from './AppSettings';
import DownloadScreen from './containers/downloadScreen/DownloadScreen';
import EmailSent from './containers/emailSent/EmailSent';
import PasswordSetup from './containers/passwordSetup/PasswordSetup';
import CreatingAccount from './containers/creatingAccount/CreatingAccount';
import ErrorScreen from './containers/errorScreen/ErrorScreen';
import TenantRequestInfo from './containers/tenantRequestInfo/TenantRequestInfo';
import Maintenance from './containers/maintenance/Maintenance';
import MaintenanceSuccess from './containers/maintenanceSuccess/MaintenanceSuccess';
import PropertyInfo from './containers/propertyInfo/PropertyInfo';
import Properties from './containers/properties/Properties';
import PropertyRent from './containers/propertyRent/PropertyRent';
import GenericServiceSummary from './containers/genericServiceSummary/GenericServiceSummary';
import ServiceSummary from './containers/serviceSummary/ServiceSummary';
import OtherServiceSummary from './containers/otherServiceSummary/OtherServiceSummary';
import Support from './containers/support/Support';
import PlanDetails from './containers/planDetails/PlanDetails';
import OfferScreen from './containers/offerScreen/OfferScreen';
import EssentialsScreen from './containers/essentialsScreen/EssentialsScreen';
import ProtectScreen from './containers/protectScreen/ProtectScreen';
import MaintainScreen from './containers/maintainScreen/MaintainScreen';
import OptimiseScreen from './containers/optimiseScreen/OptimiseScreen';
import SuppliersScreen from './containers/suppliersScreen/SuppliersScreen';
import SortedFooter from './components/sortedFooter/SortedFooter';
import { ThemeKey } from './theme/Theme';
import { store } from './store/Store';
import { ThemeActions } from './store/actions/ThemeActions';
import DebuggingBox from './components/debuggingBox/DebuggingBox';
import CheckoutCompleteCart from './containers/checkoutCompleteCart/CheckoutCompleteCart';
import CheckoutConfigure from './containers/checkoutConfigure/CheckoutConfigure';
import CheckoutFinalize from './containers/checkoutFinalize/CheckoutFinalize';
import CheckoutSelect from './containers/checkoutSelect/CheckoutSelect';
import CheckoutPersonalDetails from './containers/checkoutPersonalDetails/CheckoutPersonalDetails';
import CheckoutSummary from './containers/checkoutSummary/CheckoutSummary';
import PropertyOnboarding from './containers/propertyOnboarding/PropertyOnboarding';
import PaymentDetails from './containers/payment/selectPayment/PaymentDetails';
import { RentOnboardingActions } from './store/actions/RentOnboarding';
import AddPayment from './containers/payment/addPayment/AddPayment';
import AddCard from './containers/payment/addPayment/addCard/AddCard';
import AddBank from './containers/payment/addPayment/addBank/AddBank';
import ViewPaymentMethods from './containers/payment/viewPaymentMethods/ViewPaymentMethods';
import ViewCard from './containers/payment/viewPayment/viewCard/ViewCard';
import ViewBank from './containers/payment/viewPayment/viewBank/ViewBank';
import RentOnboardingSummary from './containers/rentOnboardingSummary/RentOnboardingSummary';
import RentOnboardingConfirmation from './containers/rentOnboardingConfirmation/RentOnboardingConfirmation';
import RentOnboardingWelcome from './containers/rentOnboardingWelcome/RentOnboardingWelcome';
import LeaseServiceability from './containers/leaseServiceability/LeaseServiceability';
import jwtDecode from 'jwt-decode';

interface AppProps extends RouteComponentProps {
  accessToken: string | null;
  theme: ThemeKey;
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => void;
  setExternal: (value: boolean) => void;
  getSessionToken: (value: string) => void;
  savePropertyId: (value: number) => void;
}

const App: FC<AppProps> = ({
  accessToken,
  history,
  location,
  theme,
  saveTokenDetails,
  setExternal,
  getSessionToken,
  savePropertyId,
}) => {
  const accessTokenURL = getQueryParams(location, 'accessToken');
  const refreshTokenURL = getQueryParams(location, 'refreshToken');
  const appNameURL = getQueryParams(location, 'appName');
  const isExternalURL = getQueryParams(location, 'isExternal');
  const sessionURL = getQueryParams(location, 'session');
  const emailURL = getQueryParams(location, 'email');
  const userIdURL = getQueryParams(location, 'userId');
  const propertyIdURL = getQueryParams(location, 'propertyId');
  const classes = useStyles();

  const userEmail = localStorage.getItem('email');
  const userId = useMemo<string | null>(() => {
    return localStorage.getItem('userId');
  }, [accessToken]);

  if (userEmail && userId) {
    LogRocket.identify(userId, {
      email: userEmail,
    });
  }

  useEffect(() => {
    if (propertyIdURL) {
      savePropertyId(Number(propertyIdURL));
    }
  }, [propertyIdURL]);

  useEffect(() => {
    if (accessTokenURL && refreshTokenURL && appNameURL) {
      saveTokenDetails(accessTokenURL, refreshTokenURL, appNameURL);
      const decoded: { user_id: string } = jwtDecode(accessTokenURL);
      localStorage.setItem('userId', decoded.user_id);
    }
  }, [accessTokenURL, refreshTokenURL, appNameURL]);

  useEffect(() => {
    if (emailURL && userIdURL) {
      LogRocket.identify(userIdURL, {
        email: emailURL,
      });
    }
  }, [accessTokenURL, refreshTokenURL]);

  useEffect(() => {
    if (sessionURL) {
      getSessionToken(sessionURL);
    }
  }, [sessionURL]);

  useEffect(() => {
    if (isExternalURL) {
      setExternal(isExternalURL === 'true');
    }
  }, [isExternalURL]);

  useEffect(() => {
    LogRocket.init(AppSettings.logRocket.group, {});
    sendMessageToApp(ValidMessages.token);

    const bpointScript = document.createElement('script');
    const bpointScriptSrc = AppSettings.server.BPoint;
    bpointScript.type = 'text/javascript';
    bpointScript.src = bpointScriptSrc as string;
    document.head.appendChild(bpointScript);

    const westpacQuickStream = document.createElement('script');
    const westpacQuickStreamSrc = AppSettings.server.quickStream;
    westpacQuickStream.type = 'text/javascript';
    westpacQuickStream.src = westpacQuickStreamSrc as string;
    document.head.appendChild(westpacQuickStream);
  }, []);

  useEffect(() => {
    document.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
    });
    window.addEventListener('message', (data) => {
      saveAuthDetails((data as unknown as AuthDetail).data);
    });

    return () => {
      document.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
      window.removeEventListener('message', (data) => {
        saveAuthDetails((data as unknown as AuthDetail).data);
      });
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>
      <Header />
      <DebuggingBox active />
      <div className={classes.layout}>
        <Menu />
        <div className={classes.content}>
          {!userId ? (
            <Switch>
              <Route exact path={routes.download} component={DownloadScreen} />
              <Route exact path={routes.cimet} component={CimetNeeds} />
              <Route exact path={routes.emailSent} component={EmailSent} />
              <Route exact path={routes.passwordSetup} component={PasswordSetup} />
              <Route exact path={routes.creatingAccount} component={CreatingAccount} />
              <Route exact path={routes.login} component={Login} />
              <Route exact path={routes.support} component={Support} />
              <Route exact path={routes.errorScreen} component={ErrorScreen} />
              <Route component={DownloadScreen} />
            </Switch>
          ) : (
            <Switch>
              <Redirect exact from="/" to={routes.home} />
              <Route exact path={routes.home} component={Home} />
              <Route exact path={routes.browse} component={Browse} />
              <Route exact path={routes.account} component={Account} />
              <Route exact path={routes.login} component={Login} />
              <Route exact path={routes.profileSelection} component={ProfileSelection} />
              <Route exact path={routes.cimet} component={CimetNeeds} />
              <Route exact path={routes.passwordSetup} component={PasswordSetup} />
              <Route exact path={routes.creatingAccount} component={CreatingAccount} />
              <Route exact path={routes.emailSent} component={EmailSent} />
              <Route exact path={routes.errorScreen} component={ErrorScreen} />
              <Route exact path={routes.maintenance} component={Maintenance} />
              <Route exact path={routes.maintenanceSuccessScreen} component={MaintenanceSuccess} />
              <Route exact path={routes.tenantRequestInfo} component={TenantRequestInfo} />
              <Route exact path={routes.propertyInfo} component={PropertyInfo} />
              <Route exact path={routes.properties} component={Properties} />
              <Route exact path={routes.propertyRent} component={PropertyRent} />
              <Route exact path={routes.otherServicesSummary} component={OtherServiceSummary} />
              <Route exact path={routes.serviceSummary} component={ServiceSummary} />
              <Route exact path={routes.support} component={Support} />
              <Route exact path={routes.planDetails} component={PlanDetails} />
              <Route exact path={routes.offerScreen} component={OfferScreen} />
              <Route exact path={routes.essentialsScreen} component={EssentialsScreen} />
              <Route exact path={routes.protectScreen} component={ProtectScreen} />
              <Route exact path={routes.maintainScreen} component={MaintainScreen} />
              <Route exact path={routes.optimiseScreen} component={OptimiseScreen} />
              <Route exact path={routes.suppliersScreen} component={SuppliersScreen} />

              <Route exact path={routes.checkoutSelect} component={CheckoutSelect} />
              <Route exact path={routes.checkoutConfigure} component={CheckoutConfigure} />
              <Route
                exact
                path={routes.checkoutPersonalDetails}
                component={CheckoutPersonalDetails}
              />
              <Route exact path={routes.checkoutFinalize} component={CheckoutFinalize} />
              <Route exact path={routes.checkoutSummary} component={CheckoutSummary} />
              <Route exact path={routes.checkoutCompleteCart} component={CheckoutCompleteCart} />

              <Route
                exact
                path={routes.genericServiceSummaryScreen}
                component={GenericServiceSummary}
              />
              <Route exact path={routes.propertyOnboarding} component={PropertyOnboarding} />
              <Route exact path={routes.selectPayment} component={PaymentDetails} />
              <Route exact path={routes.addPayment} component={AddPayment} />
              <Route exact path={routes.addBank} component={AddBank} />
              <Route exact path={routes.addCard} component={AddCard} />
              <Route exact path={routes.viewPaymentMethods} component={ViewPaymentMethods} />
              <Route exact path={routes.viewCard} component={ViewCard} />
              <Route exact path={routes.viewBank} component={ViewBank} />

              <Route exact path={routes.rentWelcome} component={RentOnboardingWelcome} />
              <Route exact path={routes.rentConfirmation} component={RentOnboardingConfirmation} />
              <Route exact path={routes.rentSummary} component={RentOnboardingSummary} />
              <Route exact path={routes.leaseServiceability} component={LeaseServiceability} />

              <Route component={DownloadScreen} />
            </Switch>
          )}
        </div>
      </div>
      <SortedFooter mobileOnly />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  accessToken: state.token.accessToken,
  theme: state.themeState.themeKey,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTokenDetails: (accessToken: string, refreshToken: string, appName: string) => {
    dispatch(TokenActions.setAccessToken(accessToken));
    dispatch(TokenActions.setRefreshToken(refreshToken));
    dispatch(TokenActions.setAppName(appName));
  },
  savePropertyId: (value: number) => dispatch(RentOnboardingActions.savePropertyId(value)),
  setExternal: (value: boolean) => dispatch(TokenActions.setExternal(value)),
  getSessionToken: (value: string) => dispatch(TokenActions.getSessionTokenRequest(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
