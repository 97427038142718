import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    daysGrid: {
      display: 'grid',
      gridTemplateColumns: '100px 100px 100px 100px',
      gridGap: theme.units.spacing * 2,
    },
    daySelected: {
      backgroundColor: theme.colors.buttonPrimary,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.light,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      height: 30,
      borderRadius: theme.units.borderRadius / 2,
    },
    dayUnselected: {
      backgroundColor: theme.colors.light,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.buttonPrimary,
      fontSize: 14,
      fontWeight: getFonts('Medium'),
      cursor: 'pointer',
      height: 30,
      borderRadius: theme.units.borderRadius / 2,
    },
  })();
};
