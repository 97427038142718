import {
  GroupRequest,
  GroupResponse,
  CoApplicantRequest,
  RemoveCoApplicantRequest,
  GroupApplicationRequest,
  ApplicationsRequest,
  ApplicationsResponse,
  SyncApplicationRequest,
  InvitationRequest,
  UpdateCoApplicantRequest,
  PostGroupApplicationRequest,
  GroupNameRequest,
  CreateNewGroupRequest,
  WithdrawRequest,
  WithdrawResponse,
  DeleteDraftRequest,
} from '../../models/groups/Groups';
import { StoreAction } from '../StoreHelper';

export enum GroupsActionTypes {
  GET_GROUP_MASTER_PROFILE = 'GET_GROUP_MASTER_PROFILE',
  GET_GROUP_MASTER_PROFILE_REQUEST = 'GET_GROUP_MASTER_PROFILE_REQUEST',
  GET_GROUP_MASTER_PROFILE_SUCCESS = 'GET_GROUP_MASTER_PROFILE_SUCCESS',
  GET_GROUP_MASTER_PROFILE_ERROR = 'GET_GROUP_MASTER_PROFILE_ERROR',

  POST_CREATE_NEW_GROUP_REQUEST = 'POST_CREATE_NEW_GROUP_REQUEST',
  POST_CREATE_NEW_GROUP_SUCCESS = 'POST_CREATE_NEW_GROUP_SUCCESS',
  POST_CREATE_NEW_GROUP_ERROR = 'POST_CREATE_NEW_GROUP_ERROR',

  ADD_CO_APPLICANT_REQUEST = 'ADD_CO_APPLICANT_REQUEST',
  ADD_CO_APPLICANT_SUCCESS = 'ADD_CO_APPLICANT_SUCCESS',
  ADD_CO_APPLICANT_ERROR = 'ADD_CO_APPLICANT_ERROR',

  UPDATE_CO_APPLICANT_REQUEST = 'UPDATE_CO_APPLICANT_REQUEST',
  UPDATE_CO_APPLICANT_SUCCESS = 'UPDATE_CO_APPLICANT_SUCCESS',
  UPDATE_CO_APPLICANT_ERROR = 'UPDATE_CO_APPLICANT_ERROR',

  REMOVE_CO_APPLICANT_REQUEST = 'REMOVE_CO_APPLICANT_REQUEST',
  REMOVE_CO_APPLICANT_SUCCESS = 'REMOVE_CO_APPLICANT_SUCCESS',
  REMOVE_CO_APPLICANT_ERROR = 'REMOVE_CO_APPLICANT_ERROR',

  POST_GROUP_APPLICATION = 'POST_GROUP_APPLICATION',
  POST_GROUP_APPLICATION_REQUEST = 'POST_GROUP_APPLICATION_REQUEST',
  POST_GROUP_APPLICATION_SUCCESS = 'POST_GROUP_APPLICATION_SUCCESS',
  POST_GROUP_APPLICATION_ERROR = 'POST_GROUP_APPLICATION_ERROR',

  UPDATE_GROUP_APPLICATION = 'UPDATE_GROUP_APPLICATION',
  UPDATE_GROUP_APPLICATION_REQUEST = 'UPDATE_GROUP_APPLICATION_REQUEST',
  UPDATE_GROUP_APPLICATION_SUCCESS = 'UPDATE_GROUP_APPLICATION_SUCCESS',
  UPDATE_GROUP_APPLICATION_ERROR = 'UPDATE_GROUP_APPLICATION_ERROR',

  GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS',
  GET_ALL_APPLICATIONS_REQUEST = 'GET_ALL_APPLICATIONS_REQUEST',
  GET_ALL_APPLICATIONS_SUCCESS = 'GET_ALL_APPLICATIONS_SUCCESS',
  GET_ALL_APPLICATIONS_ERROR = 'GET_ALL_APPLICATIONS_ERROR',

  SYNC_APPLICATIONS = 'SYNC_APPLICATIONS',
  SYNC_APPLICATIONS_REQUEST = 'SYNC_APPLICATIONS_REQUEST',
  SYNC_APPLICATIONS_SUCCESS = 'SYNC_APPLICATIONS_SUCCESS',
  SYNC_APPLICATIONS_ERROR = 'SYNC_APPLICATIONS_ERROR',

  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST',
  ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR',

  UPDATE_GROUP_NAME_REQUEST = 'UPDATE_GROUP_NAME_REQUEST',
  UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS',
  UPDATE_GROUP_NAME_ERROR = 'UPDATE_GROUP_NAME_ERROR',

  DELETE_GROUP = 'DELETE_GROUP',
  DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR',

  DECLINE_INVITATION = 'DECLINE_INVITATION',
  DECLINE_INVITATION_REQUEST = 'DECLINE_INVITATION_REQUEST',
  DECLINE_INVITATION_SUCCESS = 'DECLINE_INVITATION_SUCCESS',
  DECLINE_INVITATION_ERROR = 'DECLINE_INVITATION_ERROR',

  WITHDRAW_APPLICATION = 'WITHDRAW_APPLICATION',
  WITHDRAW_APPLICATION_REQUEST = 'WITHDRAW_APPLICATION_REQUEST',
  WITHDRAW_APPLICATION_SUCCESS = 'WITHDRAW_APPLICATION_SUCCESS',
  WITHDRAW_APPLICATION_ERROR = 'WITHDRAW_APPLICATION_ERROR',

  WITHDRAW_MYSELF = 'WITHDRAW_MYSELF',
  WITHDRAW_MYSELF_REQUEST = 'WITHDRAW_MYSELF_REQUEST',
  WITHDRAW_MYSELF_SUCCESS = 'WITHDRAW_MYSELF_SUCCESS',
  WITHDRAW_MYSELF_ERROR = 'WITHDRAW_MYSELF_ERROR',

  DELETE_APPLY_DRAFT_REQUEST = 'DELETE_APPLY_DRAFT_REQUEST',
  DELETE_APPLY_DRAFT_SUCCESS = 'DELETE_APPLY_DRAFT_SUCCESS',
  DELETE_APPLY_DRAFT_ERROR = 'DELETE_APPLY_DRAFT_ERROR',

  SET_SELECTED_GROUP_REF = 'SET_SELECTED_GROUP_REF',

  RESET_GROUPSUBMIT_SUCCESS = 'RESET_GROUPSUBMIT_SUCCESS',
}

export type GroupsActionPayload =
  | GroupRequest
  | PostGroupApplicationRequest
  | GroupResponse[]
  | CoApplicantRequest
  | UpdateCoApplicantRequest
  | RemoveCoApplicantRequest
  | ApplicationsRequest
  | ApplicationsResponse
  | SyncApplicationRequest
  | InvitationRequest
  | DeleteDraftRequest
  | GroupApplicationRequest
  | GroupNameRequest
  | CreateNewGroupRequest
  | WithdrawRequest
  | WithdrawResponse
  | Error
  | string
  | null;

export type GroupsAction = StoreAction<GroupsActionTypes, GroupsActionPayload>;

export class GroupsActions {
  // Get master profile with group context for logged-in User
  public static getGroupMasterProfileRequest(data: GroupRequest) {
    return { type: GroupsActionTypes.GET_GROUP_MASTER_PROFILE_REQUEST, data };
  }
  public static getGroupMasterProfileSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.GET_GROUP_MASTER_PROFILE_SUCCESS, data };
  }
  public static getGroupMasterProfileError(data: Error) {
    return { type: GroupsActionTypes.GET_GROUP_MASTER_PROFILE_ERROR, data };
  }

  // Create a new group for logged-in User
  public static postCreateNewGroupRequest(data: CreateNewGroupRequest) {
    return { type: GroupsActionTypes.POST_CREATE_NEW_GROUP_REQUEST, data };
  }
  public static postCreateNewGroupSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.POST_CREATE_NEW_GROUP_SUCCESS, data };
  }
  public static postCreateNewGroupError(data: Error) {
    return { type: GroupsActionTypes.POST_CREATE_NEW_GROUP_ERROR, data };
  }

  // Add co-applicant to a group of logged-in User
  public static addCoApplicantRequest(data: CoApplicantRequest[]) {
    return { type: GroupsActionTypes.ADD_CO_APPLICANT_REQUEST, data };
  }
  public static addCoApplicantSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.ADD_CO_APPLICANT_SUCCESS, data };
  }
  public static addCoApplicantError(data: Error) {
    return { type: GroupsActionTypes.ADD_CO_APPLICANT_ERROR, data };
  }

  // Update co-applicant
  public static updateCoApplicantRequest(data: UpdateCoApplicantRequest) {
    return { type: GroupsActionTypes.UPDATE_CO_APPLICANT_REQUEST, data };
  }
  public static updateCoApplicantSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.UPDATE_CO_APPLICANT_SUCCESS, data };
  }
  public static updateCoApplicantError(data: Error) {
    return { type: GroupsActionTypes.UPDATE_CO_APPLICANT_ERROR, data };
  }

  // remove co-applicant from a group of logged-in User
  public static removeCoApplicantRequest(data: RemoveCoApplicantRequest) {
    return { type: GroupsActionTypes.REMOVE_CO_APPLICANT_REQUEST, data };
  }
  public static removeCoApplicantSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.REMOVE_CO_APPLICANT_SUCCESS, data };
  }
  public static removeCoApplicantError(data: Error) {
    return { type: GroupsActionTypes.REMOVE_CO_APPLICANT_ERROR, data };
  }

  // Submit one application against multiple properties
  public static postGroupApplicationRequest(data: PostGroupApplicationRequest) {
    return { type: GroupsActionTypes.POST_GROUP_APPLICATION_REQUEST, data };
  }
  public static postGroupApplicationSuccess(data: ApplicationsResponse) {
    return { type: GroupsActionTypes.POST_GROUP_APPLICATION_SUCCESS, data };
  }
  public static postGroupApplicationError(data: Error) {
    return { type: GroupsActionTypes.POST_GROUP_APPLICATION_ERROR, data };
  }

  // Get all Applications
  public static getAllApplicationsRequest(data: ApplicationsRequest) {
    return { type: GroupsActionTypes.GET_ALL_APPLICATIONS_REQUEST, data };
  }
  public static getAllApplicationsSuccess(data: ApplicationsResponse) {
    return { type: GroupsActionTypes.GET_ALL_APPLICATIONS_SUCCESS, data };
  }
  public static getAllApplicationsError(data: Error) {
    return { type: GroupsActionTypes.GET_ALL_APPLICATIONS_ERROR, data };
  }

  // Sync Applications
  public static syncApplicationsRequest(data: SyncApplicationRequest) {
    return { type: GroupsActionTypes.SYNC_APPLICATIONS_REQUEST, data };
  }
  public static syncApplicationsSuccess(data: ApplicationsResponse) {
    return { type: GroupsActionTypes.SYNC_APPLICATIONS_SUCCESS, data };
  }
  public static syncApplicationsError(data: Error) {
    return { type: GroupsActionTypes.SYNC_APPLICATIONS_ERROR, data };
  }

  // Co-Applicant accepts group invitation
  public static acceptInvitationRequest(data: InvitationRequest) {
    return { type: GroupsActionTypes.ACCEPT_INVITATION_REQUEST, data };
  }
  public static acceptInvitationSuccess(data: WithdrawResponse) {
    return { type: GroupsActionTypes.ACCEPT_INVITATION_SUCCESS, data };
  }
  public static acceptInvitationError(data: Error) {
    return { type: GroupsActionTypes.ACCEPT_INVITATION_ERROR, data };
  }

  // Update group name
  public static updateGroupNameRequest(data: GroupNameRequest) {
    return { type: GroupsActionTypes.UPDATE_GROUP_NAME_REQUEST, data };
  }
  public static updateGroupNameSuccess(data: GroupResponse[]) {
    return { type: GroupsActionTypes.UPDATE_GROUP_NAME_SUCCESS, data };
  }
  public static updateGroupNameError(data: Error) {
    return { type: GroupsActionTypes.UPDATE_GROUP_NAME_ERROR, data };
  }

  // decline invitation
  public static declineInvitationRequest(data: InvitationRequest) {
    return { type: GroupsActionTypes.DECLINE_INVITATION_REQUEST, data };
  }
  public static declineInvitationSuccess(data: WithdrawResponse) {
    return { type: GroupsActionTypes.DECLINE_INVITATION_SUCCESS, data };
  }
  public static declineInvitationError(data: Error) {
    return { type: GroupsActionTypes.DECLINE_INVITATION_ERROR, data };
  }

  // Withdraw application
  public static withdrawApplicationRequest(data: WithdrawRequest) {
    return { type: GroupsActionTypes.WITHDRAW_APPLICATION_REQUEST, data };
  }
  public static withdrawApplicationSuccess(data: WithdrawResponse) {
    return { type: GroupsActionTypes.WITHDRAW_APPLICATION_SUCCESS, data };
  }
  public static withdrawApplicationError(data: Error) {
    return { type: GroupsActionTypes.WITHDRAW_APPLICATION_ERROR, data };
  }

  // withdrawMyself
  public static withdrawMyselfRequest(data: WithdrawRequest) {
    return { type: GroupsActionTypes.WITHDRAW_MYSELF_REQUEST, data };
  }
  public static withdrawMyselfSuccess(data: WithdrawResponse) {
    return { type: GroupsActionTypes.WITHDRAW_MYSELF_SUCCESS, data };
  }
  public static withdrawMyselfError(data: Error) {
    return { type: GroupsActionTypes.WITHDRAW_MYSELF_ERROR, data };
  }

  public static deleteDraftRequest(data: DeleteDraftRequest) {
    return { type: GroupsActionTypes.DELETE_APPLY_DRAFT_REQUEST, data };
  }
  public static deleteDraftSuccess(data: ApplicationsResponse) {
    return { type: GroupsActionTypes.DELETE_APPLY_DRAFT_SUCCESS, data };
  }
  public static deleteDraftError(data: Error) {
    return { type: GroupsActionTypes.DELETE_APPLY_DRAFT_ERROR, data };
  }

  // Set selected GroupRefId
  public static setSelectedGroupRef(data: string | null) {
    return { type: GroupsActionTypes.SET_SELECTED_GROUP_REF, data };
  }

  // Reset success
  public static resetSuccess() {
    return { type: GroupsActionTypes.RESET_GROUPSUBMIT_SUCCESS };
  }
}
