const SORTED = {
  key: 'SWITCH',
  colors: {
    light: '#FFF',
    lightFaded: 'rgba(255,255,255,0.7)',
    lightGrey: '#f6f6f6',
    lighterGrey: '#fafafa',
    grey: '#9b9b9b',
    darkGrey: '#464343',
    black38: 'rgba(0, 0, 0, 0.38)',
    black: '#000',
    label: '#c1bed0',
    black87: 'rgba(0,0,0,0.87)',
    secondary: '#0C7181',
    borderColor: '#d2cfd6',
    borderColorLight: '#edecef',
    disabled: '#f6f6f6',
    filterBackground: '#f6f6f6',
    textarea: 'rgba(0, 0, 0, 0.07)',
    fadedBlue: 'rgba(27, 173, 193, 0.1)',
    fadedPurple: '#ECE0FF',
    disabledText: '#808080',
    buttonPrimary: '#0C7181',
    black60: 'rgba(0, 0, 0, 0.6)',
    offerOverlay: '#242733',
    inputBackground: '#FFFFFF',
    inputText: '#000000',
    title: '#1f0d33',
    subtitle: '#796e85',
    error: '#FF525B',
    info: '#7C8EFD',
    success: '#1BADC1',
    warning: '#FF8957',
    focus: '#ECE0FF',
    averageUsage: '#FEB741',
    budgetUsage: '#FD4176',
    sortedPurple: '#2d1b49',
    statusRequested: '#f7f7f9',
    statusReviewed: '#fef2de',
    statusScheduled: '#e4dfec',
    statusCompleted: '#daedf1',
    completeLabel: '#DDF0F3',
    incompleteLabel: '#FFD9E4',
    yellowLabel: '#FFEFE5',
    lightBlue: '#EEF4FF',
    darkBlue: '#5699FF',
    lightGreen: '#DDF0F3',
    darkGreen: '#1CADC1',
    lightPurple: '#ECE0FF',
    darkPurple: '#7C8EFD',
    lightOrange: '#FFEFE5',
    darkOrange: '#FF8957',
    lightRed: '#FFD9E4',
    darkRed: '#FD4176',
    highlightButtonColor: '#F3FBFC',
    gradient1: '#4CDFAC',
    gradient2: '#147FCA',
    actionButtonSecondary: '#4CDFAC',
  },
  font: {
    family: 'GT Walsheim',
    family2: 'GT Walsheim',
    xxs: 10,
    xs: 12,
    s: 14,
    m: 16,
    l: 18,
    xl: 24,
    xxl: 36,
    xxxl: 44,
    buttonCase: 'capitalize',
  },
  units: {
    spacing: 4,
    borderRadius: 12,
    iconSize: 24,
  },
};

export default SORTED;
