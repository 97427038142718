import React, { FC, useEffect, useState, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './DashboardStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DashboardActionTypes, DashboardActions } from '../../../../store/actions/DashboardActions';
import { BottomNavValue, TabProps } from '../../../../models/dashboard/dashboard';
import { AuthDetails } from '../../../../models/auth/auth';
import { DashboardState } from '../../../../store/state/DashboardState';
import { AddTagStatus } from '../../../../models/payment/Payment';
import { PaymentActions } from '../../../../store/actions/PaymentActions';
import { RequestActions } from '../../../../store/actions/RequestActions';
import { findExtraServiceInfo, useDashboardServices } from '../../../../helpers/ServiceInfoHelper';
import {
  filterCimetPurchasedServices,
  filterServicesByStatus,
  getFocusedTask,
  getStatus,
  getTaskURL,
  removeDuplicatedCimetServices,
} from './DashboardUtils';
import {
  activeService,
  AccountStatus,
  activeAccountStatus,
  onboardingScreenValidStates,
  onboardingScreenValidStatesPlusPending,
} from '../../../../constants/AccountStatus';
import ActionCard from '../../../../components/actionCard/ActionCard';
import {
  CART_BANNER,
  DONE_TASKS,
  FAKE_ESSENTIAL_SERVICES,
  FAKE_HOME_SERVICES,
  LABELS,
  LINKS,
  NO_SERVICES,
  SWITCH_SERVICES,
  TASK,
  getDarkServiceTypeIcon,
  getLightServiceTypeIcon,
  readMore,
} from './DashboardConstants';
import alertLarge from '../../../../assets/alert-large.png';
import house from '../../../../assets/navigation/home.png';
import maintenanceIcon from '../../../../assets/marketplace/dark/maintain.png';
import search from '../../../../assets/navigation/search.png';
import clock from '../../../../assets/clock.png';
import plusGrey from '../../../../assets/plus-grey.png';
import arrowRight from '../../../../assets/navigation/arrow-right.png';
import arrowLeft from '../../../../assets/navigation/arrow-left.png';
import billCheckWhite from '../../../../assets/billcheck-white.png';
import Text from '../../../../components/text/Text';
import { banners } from '../../../browse/components/marketplace/MarketplaceConstants';
import PayIdBanner from '../../../../components/payIdBanner/PayIdBanner';
import TileContainer from '../../../../components/tileContainer/TileContainer';
import PropertyCard from '../../../../components/propertyCard/PropertyCard';
import ElectricityCard from '../../../../components/electricityCard/ElectricityCard';
import BroadbandCard from '../../../../components/broadbandCard/BroadbandCard';
import OtherServiceCards from '../../../../components/otherServiceCards/OtherServiceCards';
import RentCards from '../../../../components/rentCards/RentCards';
import Task from '../../../../components/task/Task';
import { onboardingUrls } from '../../../../constants/OnboardingURLs';
import {
  createPreopen,
  createRequiredServiceTypes,
  DEFAULT_SERVICES,
} from '../../../../helpers/CheckoutHelper';
import { resetService, shouldResetService } from '../../../../helpers/ResetServiceHelper';
import Tile from '../../../../components/tile/Tile';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { ServiceTypes } from '../../../../models/services/services';
import { AustraliaState } from '../../../../models/australiaStates/australiaStates';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { ThemeKey, getCurrentThemeKey, theme } from '../../../../theme/Theme';
import { Status } from '../../../../models/tenantRequest/Request';
import { CimetServicesRequest, CimetVisitRequest } from '../../../../models/cimet/Cimet';
import { CimetActionTypes, CimetActions } from '../../../../store/actions/CimetActions';
import { Ribbon } from '../../../../components/tile/TileConstants';
import ActionCardSlim from '../../../../components/actionCardSlim/ActionCardSlim';
import { extractParams, navigate } from '../../../../navigation/NavigationUtils';
import { routes } from '../../../../Routes';
import ActiveServices from './components/activeServices/ActiveServices';
import SwipeableViews from 'react-swipeable-views';
import { LandlordActions } from '../../../../store/actions/LandlordActions';
import { MaintenanceResponse } from '../../../../models/landlord/LandlordProperty';
import AddPropertyPopup from '../../../../components/addPropertyPopup/AddPropertyPopup';
import PrivateWorksCards from './components/privateWorksCards/PrivateWorksCards';
import { BundleResponse } from '../../../../models/bundles/Bundles';
import { getBanners } from '../../../browse/components/marketplace/MarketplaceUtils';
import { ThemeActions } from '../../../../store/actions/ThemeActions';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
} from '../../../../models/checkout/Checkout';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { Property } from '../../../../models/property/property';
import { TabParams } from '../../../../navigation/NavigationConstants';

interface DashboardProps extends RouteComponentProps {
  authDetails: AuthDetails;
  dashboardState: DashboardState;
  isGuest: boolean;
  loading: boolean;
  bundleResponse: BundleResponse | undefined;
  availableServices: AvailableServicesResponse[] | undefined;
  checkoutProperty: Property | null | undefined;
  switchFooterTab: (tabName: string, tabProps?: TabProps) => void;
  setSelectedProperty: (index: number) => void;
  getDashboardDetails: () => void;
  fetchDashboardDetails: () => void;
  getPackages: () => void;
  fetchPaymentDetails: (body: AddTagStatus) => void;
  getPropertyMaintenance: (propertyId: string) => void;
  getCimetServices: (data: CimetServicesRequest) => void;
  postCimetVisit: (data: CimetVisitRequest) => void;
  setSelectedMaintenance: (data: MaintenanceResponse) => void;
  setTheme: (theme: ThemeKey) => void;
  getAvailableServices: (data: AvailableServicesRequest) => void;
}

const Dashboard: FC<DashboardProps> = ({
  authDetails,
  isGuest,
  loading,
  dashboardState: {
    onboardedProperties,
    selectedProperty,
    ctaRequired,
    noneOnboarded,
    refreshing,
    maintenances,
    maintenanceLoader,
  },
  bundleResponse,
  availableServices,
  checkoutProperty,
  switchFooterTab,
  setSelectedProperty,
  fetchPaymentDetails,
  getDashboardDetails,
  fetchDashboardDetails,
  getPackages,
  getPropertyMaintenance,
  getCimetServices,
  postCimetVisit,
  setSelectedMaintenance,
  setTheme,
  getAvailableServices,
}) => {
  const classes = useStyles();
  const [dismissPaymentAlert, setDismissPaymentAlert] = useState<boolean>(false);
  const [dismissHaveRent, setDismissHaveRent] = useState<boolean>(false);
  const [dismissRemindMe, setDismissRemindMe] = useState<boolean>(false);
  const [propertyIndex, setPropertyIndex] = useState<number>(selectedProperty);
  const [maintenanceIndex, setMaintenanceIndex] = useState<number>(0);
  const [showAddPropertyPopup, setShowAddPropertyPopup] = useState<boolean>(false);
  const [bannerIndex, setBannerIndex] = useState<number>(0);
  const themeKey = getCurrentThemeKey();

  const {
    property,
    activeServices,
    outstandingPayments,
    homeServices,
    electricityServices,
    broadbandServices,
    gasServices,
    otherServices,
    otherHomeServices,
    cimetAvailableServices,
    cimetPurchasedServices,
    newAndInProgessServices,
  } = useDashboardServices();

  const { refresh } = extractParams<TabParams>();

  useEffect(() => {
    if (refresh) {
      getDashboardDetails();
      fetchDashboardDetails();
    }
  }, [refresh]);

  useEffect(() => {
    if (themeKey === ThemeKey.SWITCH && property !== undefined) {
      const getKey = () => {
        switch (property.state) {
          case 'VIC':
            return ThemeKey.SWITCH_VIC;
          case 'SA':
            return ThemeKey.SWITCH_SA;
          case 'WA':
            return ThemeKey.SWITCH_WA;
          case 'NSW':
            return ThemeKey.SWITCH_NSW;
          default:
            return ThemeKey.SWITCH;
        }
      };
      setTheme(getKey());
    }
  }, [property]);

  const electricityAvailable = useMemo<boolean>(() => {
    return (
      !!electricityServices.length &&
      !!filterServicesByStatus(onboardingScreenValidStates, electricityServices).length
    );
  }, [electricityServices]);

  const broadbandAvailable = useMemo<boolean>(() => {
    return (
      !!broadbandServices.length &&
      !!filterServicesByStatus(onboardingScreenValidStates, broadbandServices).length
    );
  }, [broadbandServices]);

  const gasAvailable = useMemo<boolean>(() => {
    return (
      !!gasServices.length &&
      !!filterServicesByStatus(onboardingScreenValidStates, gasServices).length
    );
  }, [gasServices]);

  const isMaintenanceAllowed = useMemo<boolean>(() => {
    return (
      !!onboardedProperties &&
      onboardedProperties.length > 0 &&
      !!onboardedProperties[selectedProperty].property!.isMaintenanceAllowed
    );
  }, [onboardedProperties, selectedProperty]);

  const noServices = useMemo<boolean>(() => {
    return (
      activeServices.filter(
        (service) =>
          service.serviceType !== ServiceTypes.Rent &&
          service.serviceType !== ServiceTypes.Callback,
      ).length === 0
    );
  }, [activeServices]);

  const billCheckAvailable = useMemo<boolean>(() => {
    return (
      electricityAvailable &&
      !!onboardedProperties &&
      onboardedProperties.length > 0 &&
      onboardedProperties[selectedProperty].property!.state !== AustraliaState.ACT
    );
  }, [electricityAvailable]);

  const focusedTask = useMemo<TASK>(() => {
    const result = getFocusedTask(
      false,
      false,
      noServices,
      billCheckAvailable,
      electricityAvailable,
      gasAvailable,
      broadbandAvailable,
      removeDuplicatedCimetServices(activeServices, cimetAvailableServices),
    );
    return result;
  }, [
    noServices,
    billCheckAvailable,
    electricityAvailable,
    gasAvailable,
    broadbandAvailable,
    activeServices,
    cimetAvailableServices,
  ]);

  useEffect(() => {
    setPropertyIndex(selectedProperty);
  }, [selectedProperty]);

  useEffect(() => {
    // Putting this in a separate useEffect as it is possible to desync with Cimet services
    if (onboardedProperties && onboardedProperties.length > 0) {
      const property = onboardedProperties[selectedProperty].property;
      if (property) {
        getPackages();
        getPropertyMaintenance(property.id.toString());
        getCimetServices({ propertyId: property.id });
      }
    }
  }, [onboardedProperties, selectedProperty]);

  useEffect(() => {
    if (
      property &&
      (availableServices === undefined ||
        availableServices.length === 0 ||
        (!!checkoutProperty && property.id !== checkoutProperty.id))
    ) {
      getAvailableServices({
        propertyId: property.id.toString(),
        requiredServiceTypes:
          onboardedProperties![selectedProperty].services!.map(
            (s) => s.serviceType.replace(' ', '') as ServiceTypes,
          ) || [],
      });
    }
  }, [property, availableServices, checkoutProperty]);

  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        {ctaRequired && (
          <ActionCardSlim
            title={LABELS.NEW_PROPERTY_FOUND}
            icon={alertLarge}
            buttonText={LABELS.NEW_PROPERTY_FOUND_BUTTON}
            handleButtonPress={() => navigate('rentWelcome')}
          />
        )}
        {!ctaRequired && !!outstandingPayments.length && !dismissPaymentAlert && (
          <PayIdBanner
            userId={authDetails.userId}
            fetchPaymentDetails={(body: AddTagStatus) => fetchPaymentDetails(body)}
            activeServices={activeServices}
            property={property!}
            outstandingPayments={outstandingPayments}
            dismissBanner={(value: boolean) => setDismissPaymentAlert(value)}
          />
        )}
        {!noneOnboarded && onboardedProperties && !!onboardedProperties.length && property ? (
          <>
            <TileContainer
              title="Properties"
              isLink
              noScroll
              noMarginTop
              showRightText="See all"
              handleRightAction={() => navigate('properties')}
              arrowProps={{
                disableLeft: propertyIndex === 0,
                disableRight: onboardedProperties.length - 1 === propertyIndex,
                onLeftArrow: () => {
                  setSelectedProperty(propertyIndex - 1);
                  setPropertyIndex(propertyIndex - 1);
                },
                onRightArrow: () => {
                  setSelectedProperty(propertyIndex + 1);
                  setPropertyIndex(propertyIndex + 1);
                },
              }}
            >
              <SwipeableViews
                enableMouseEvents
                style={{ paddingRight: '55%', position: 'relative' }}
                index={propertyIndex}
                onChangeIndex={(index: number) => {
                  setSelectedProperty(index);
                  setPropertyIndex(index);
                }}
              >
                {onboardedProperties.concat({} as any).map((item, index) => (
                  <PropertyCard
                    property={item.property}
                    selected={propertyIndex === index}
                    addProperty={onboardedProperties.length === index}
                    index={index}
                    snapTo={() => {
                      setSelectedProperty(index);
                      setPropertyIndex(index);
                    }}
                  />
                ))}
              </SwipeableViews>
            </TileContainer>

            <ActiveServices propertyId={property.id} />
            {loading ? (
              <>
                <TileContainer title="Essential services">
                  <div className={classes.servicesGrid}>
                    {[...Array(3).fill(null)].map((service, idx) => (
                      <Tile
                        key={idx}
                        loading={loading}
                        handlePress={() => setShowAddPropertyPopup(true)}
                      />
                    ))}
                  </div>
                </TileContainer>
                <TileContainer title="Home services">
                  <div className={classes.servicesGrid}>
                    {[...Array(4).fill(null)].map((service, idx) => (
                      <Tile
                        key={idx}
                        loading={loading}
                        handlePress={() => setShowAddPropertyPopup(true)}
                      />
                    ))}
                  </div>
                </TileContainer>
                <TileContainer title="Things to do" noScroll>
                  <div className={classes.thingsToDo}>
                    {[...Array(3).fill(null)].map((service, idx) => (
                      <Task icon="" title="" description="" loading handlePress={() => null} />
                    ))}
                  </div>
                </TileContainer>
              </>
            ) : (
              <>
                {((!!homeServices.length &&
                  !!filterServicesByStatus(onboardingScreenValidStatesPlusPending, homeServices)
                    .length) ||
                  (!!cimetAvailableServices.length &&
                    !!removeDuplicatedCimetServices(activeServices, cimetAvailableServices)
                      .length) ||
                  (!!cimetPurchasedServices.length &&
                    !!filterCimetPurchasedServices([AccountStatus.PENDING], cimetPurchasedServices)
                      .length)) && (
                  <TileContainer title="Essential services">
                    <div className={classes.servicesGrid}>
                      {!!electricityServices.length &&
                        filterServicesByStatus(
                          onboardingScreenValidStatesPlusPending,
                          electricityServices,
                        ).map((service, idx) => (
                          <ElectricityCard
                            key={idx}
                            addressConfirmed={
                              onboardedProperties[selectedProperty].addressConfirmed as boolean
                            }
                            service={service}
                            extraServiceInfo={findExtraServiceInfo(
                              service,
                              onboardedProperties[selectedProperty].availableServices,
                            )}
                            propertyId={property!.id}
                            showTile
                            small
                          />
                        ))}
                      {!!broadbandServices.length &&
                        filterServicesByStatus(
                          onboardingScreenValidStatesPlusPending,
                          broadbandServices,
                        ).map((service, idx) => (
                          <BroadbandCard
                            key={idx}
                            addressConfirmed={
                              onboardedProperties[selectedProperty].addressConfirmed as boolean
                            }
                            service={service}
                            extraServiceInfo={findExtraServiceInfo(
                              service,
                              onboardedProperties[selectedProperty].availableServices,
                            )}
                            propertyId={property!.id}
                            showTile
                            small
                          />
                        ))}
                      {!!gasServices.length && (
                        <OtherServiceCards
                          small
                          addressConfirmed={
                            onboardedProperties[selectedProperty].addressConfirmed as boolean
                          }
                          services={filterServicesByStatus(
                            onboardingScreenValidStatesPlusPending,
                            gasServices,
                          )}
                          availableServices={
                            onboardedProperties[selectedProperty].availableServices
                          }
                          propertyId={property!.id}
                          showTile
                        />
                      )}
                      {!!cimetPurchasedServices.length &&
                        !!filterCimetPurchasedServices(
                          [AccountStatus.PENDING],
                          cimetPurchasedServices,
                        ).length &&
                        filterCimetPurchasedServices(
                          [AccountStatus.PENDING],
                          cimetPurchasedServices,
                        ).map((service, idx) => (
                          <Tile
                            key={idx}
                            icon={getDarkServiceTypeIcon(service.serviceType)}
                            active
                            status="Pending"
                            title={service.serviceType}
                            handlePress={() =>
                              navigate('cimetScreen', { serviceType: service.serviceType })
                            }
                            subtitle={service.salesInfo.providerName}
                          />
                        ))}
                      {!!cimetAvailableServices.length &&
                        cimetAvailableServices.map((service, idx) => (
                          <Tile
                            key={idx}
                            icon={getDarkServiceTypeIcon(service)}
                            title={service.toString()}
                            ribbon={Ribbon.NEW}
                            handlePress={() => {
                              postCimetVisit({ propertyId: property.id, serviceType: service });
                              navigate(
                                'cimet',
                                { propertyId: property.id, serviceType: service },
                                true,
                              );
                            }}
                            subtitle="Add"
                          />
                        ))}
                      {[...Array(1)].map((_, idx) => (
                        <Tile
                          key={idx}
                          icon={plusGrey}
                          title="Add a service"
                          handlePress={() => navigate('browse')}
                          add
                        />
                      ))}
                    </div>
                  </TileContainer>
                )}
                {(!!otherHomeServices.length || themeKey === ThemeKey.SWITCH) && (
                  <TileContainer
                    title={themeKey === ThemeKey.SWITCH ? 'Regular services' : 'Home services'}
                  >
                    <div className={classes.servicesGrid}>
                      {themeKey === ThemeKey.SWITCH && (
                        <>
                          {SWITCH_SERVICES.map((s, idx) => (
                            <Tile
                              key={idx}
                              icon={s.icon}
                              title={s.name}
                              handlePress={() => s.action()}
                              subtitle="Add"
                            />
                          ))}
                        </>
                      )}
                      {!!otherServices.length && (
                        <OtherServiceCards
                          smaller
                          addressConfirmed={
                            onboardedProperties[selectedProperty].addressConfirmed as boolean
                          }
                          services={otherHomeServices}
                          availableServices={
                            onboardedProperties[selectedProperty].availableServices
                          }
                          propertyId={property!.id}
                          showTile
                        />
                      )}
                      {[...Array(1)].map((_, idx) => (
                        <Tile
                          key={idx}
                          title="Add a service"
                          handlePress={() => navigate('browse')}
                          add
                        />
                      ))}
                    </div>
                  </TileContainer>
                )}
                {((property && newAndInProgessServices.length) ||
                  (property && themeKey === ThemeKey.SWITCH) ||
                  !property) && (
                  <TileContainer
                    title="For you"
                    noScroll
                    arrowProps={{
                      disableLeft: bannerIndex === 0,
                      disableRight:
                        (property
                          ? getBanners(
                              property,
                              onboardedProperties,
                              selectedProperty,
                              newAndInProgessServices,
                              banners,
                            )
                          : banners
                        ).length -
                          1 ===
                        bannerIndex,
                      onLeftArrow: () => {
                        setBannerIndex(bannerIndex - 1);
                      },
                      onRightArrow: () => {
                        setBannerIndex(bannerIndex + 1);
                      },
                    }}
                  >
                    <SwipeableViews
                      enableMouseEvents
                      style={{ paddingRight: '55%', position: 'relative' }}
                      index={bannerIndex}
                      onChangeIndex={(index: number) => {
                        setBannerIndex(index);
                      }}
                    >
                      {(property
                        ? getBanners(
                            property,
                            onboardedProperties,
                            selectedProperty,
                            newAndInProgessServices,
                            banners,
                          )
                        : banners
                      ).map((item, index) => (
                        <ActionCard
                          title={item.title}
                          image={item.image}
                          text=""
                          buttonText={item.buttonText}
                          service={item.serviceType}
                          parentStyles={classes.banner}
                          dark={item.serviceType !== ServiceTypes.OtherService}
                          coloured={item.serviceType !== ServiceTypes.OtherService}
                          handleButtonPress={() => {
                            if (property) {
                              item.action();
                            } else {
                              setShowAddPropertyPopup(true);
                            }
                          }}
                          card
                          backgroundColor={item.backgroundColor}
                        />
                      ))}
                    </SwipeableViews>
                  </TileContainer>
                )}
                {themeKey !== ThemeKey.SWITCH && (
                  <TileContainer title="Things to do" noScroll>
                    <div className={classes.thingsToDo}>
                      {[
                        noServices,
                        electricityAvailable,
                        broadbandAvailable,
                        gasAvailable,
                        !!removeDuplicatedCimetServices(activeServices, cimetAvailableServices)
                          .length,
                      ].filter((t) => t).length === 0 ? (
                        <Task
                          icon={DONE_TASKS.ICON}
                          title={DONE_TASKS.TITLE}
                          description={DONE_TASKS.TEXT}
                          green
                          time={DONE_TASKS.TIME}
                          handlePress={() => null}
                        />
                      ) : (
                        <>
                          {property && (
                            <>
                              {noServices && (
                                <Task
                                  title={NO_SERVICES.TITLE}
                                  description={NO_SERVICES.DESCRIPTION}
                                  time={NO_SERVICES.TIME}
                                  icon={NO_SERVICES.ICON}
                                  handlePress={() => {
                                    navigate('browse');
                                  }}
                                />
                              )}
                              {billCheckAvailable && (
                                <Task
                                  title="Bill check"
                                  description="Find a better plan. Compare"
                                  icon={clock}
                                  time="5 minutes"
                                  handlePress={() => navigate('checkBillScreen')}
                                  focus
                                />
                              )}

                              {!!removeDuplicatedCimetServices(
                                activeServices,
                                cimetAvailableServices,
                              ).length &&
                                removeDuplicatedCimetServices(
                                  activeServices,
                                  cimetAvailableServices,
                                ).map((service) => {
                                  const title =
                                    service === ServiceTypes.Electricity
                                      ? 'Connect to electricity'
                                      : service === ServiceTypes.Gas
                                      ? 'Connect to gas'
                                      : 'Set up broadband';
                                  const description =
                                    service === ServiceTypes.Electricity
                                      ? 'Totally green energy in just a few taps'
                                      : service === ServiceTypes.Gas
                                      ? 'Get your gas connected in just a few taps'
                                      : 'Fast, reliable internet in under 2 minutes';
                                  const focusedTask2 =
                                    service === ServiceTypes.Electricity
                                      ? TASK.ELECTRICITY
                                      : service === ServiceTypes.Gas
                                      ? TASK.GAS
                                      : TASK.BROADBAND;

                                  return (
                                    <Task
                                      title={title}
                                      description={description}
                                      icon={getDarkServiceTypeIcon(service)}
                                      time="2-4 mins"
                                      focus={focusedTask === focusedTask2}
                                      handlePress={() => {
                                        postCimetVisit({
                                          propertyId: property.id,
                                          serviceType: service,
                                        });
                                        navigate(
                                          'cimet',
                                          {
                                            propertyId: property.id,
                                            serviceType: service,
                                          },
                                          true,
                                        );
                                      }}
                                    />
                                  );
                                })}
                              {electricityAvailable && (
                                <Task
                                  icon={getDarkServiceTypeIcon(ServiceTypes.Electricity)}
                                  title="Connect to electricity"
                                  time="2-4 mins"
                                  description="Totally green energy in just a few taps"
                                  focus={focusedTask === TASK.ELECTRICITY}
                                  handlePress={() => {
                                    const shouldReset = shouldResetService(
                                      electricityServices[0],
                                      findExtraServiceInfo(
                                        electricityServices[0],
                                        onboardedProperties[selectedProperty].availableServices,
                                      ).Suppliers[0],
                                    );
                                    if (shouldReset) {
                                      resetService(electricityServices[0]);
                                    }
                                    navigate('checkoutSelect', {
                                      requiredServiceTypes: createRequiredServiceTypes([
                                        ServiceTypes.Electricity,
                                      ]),
                                    });
                                  }}
                                />
                              )}
                              {gasAvailable && (
                                <Task
                                  icon={getDarkServiceTypeIcon(ServiceTypes.Gas)}
                                  title="Connect to gas"
                                  time="2-4 mins"
                                  description="Get your gas connected in just a few taps"
                                  focus={focusedTask === TASK.GAS}
                                  handlePress={() => {
                                    const shouldReset = shouldResetService(
                                      gasServices[0],
                                      findExtraServiceInfo(
                                        gasServices[0],
                                        onboardedProperties[selectedProperty].availableServices,
                                      ).Suppliers[0],
                                    );
                                    if (shouldReset) {
                                      resetService(gasServices[0]);
                                    }
                                    navigate('checkoutSelect', {
                                      requiredServiceTypes: createRequiredServiceTypes([
                                        ServiceTypes.Gas,
                                      ]),
                                    });
                                  }}
                                />
                              )}
                              {broadbandAvailable && (
                                <Task
                                  icon={getDarkServiceTypeIcon(ServiceTypes.Broadband)}
                                  title="Set up broadband"
                                  time="2-4 mins"
                                  description="Fast, reliable internet in under 2 minutes"
                                  focus={focusedTask === TASK.BROADBAND}
                                  handlePress={() => {
                                    navigate('checkoutSelect', {
                                      requiredServiceTypes: createRequiredServiceTypes([
                                        ServiceTypes.Broadband,
                                      ]),
                                    });
                                  }}
                                />
                              )}
                              {isMaintenanceAllowed && (
                                <Task
                                  icon={maintenanceIcon}
                                  time="3 mins"
                                  title={
                                    themeKey === ThemeKey.NAX
                                      ? 'Lodge a new maintenance request'
                                      : 'Get a quote for maintenance'
                                  }
                                  description="Can we fix it? No, but these guys can!"
                                  handlePress={() => navigate('maintenance')}
                                  focus={focusedTask === TASK.MAINTENANCE}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </TileContainer>
                )}
              </>
            )}

            {isMaintenanceAllowed ? (
              <>
                {maintenanceLoader ? (
                  <TileContainer
                    title={
                      themeKey === ThemeKey.SWITCH
                        ? 'Maintenance'
                        : 'Booking requests & maintenance'
                    }
                    showRightText={maintenances.length >= 3 ? 'View all' : undefined}
                    isLink
                    handleRightAction={() => navigate('home', { index: 2 })}
                  >
                    <div className={classes.thingsToDo}>
                      {[...Array(3).fill(null)].map((_, index) => (
                        <Task
                          icon={maintenanceIcon}
                          key={index}
                          loading
                          title=""
                          description=""
                          handlePress={() => null}
                        />
                      ))}
                    </div>
                  </TileContainer>
                ) : maintenances.length ? (
                  <TileContainer
                    title={
                      themeKey === ThemeKey.SWITCH
                        ? 'Maintenance'
                        : 'Booking requests & maintenance'
                    }
                    showRightText={maintenances.length >= 3 ? 'View all' : undefined}
                    isLink
                    handleRightAction={() => navigate('home', { index: 2 })}
                    arrowProps={{
                      disableLeft: maintenanceIndex === 0,
                      disableRight: maintenances.length - 1 === maintenanceIndex,
                      onLeftArrow: () => setMaintenanceIndex(maintenanceIndex - 1),
                      onRightArrow: () => setMaintenanceIndex(maintenanceIndex + 1),
                    }}
                    leftActionText="Add new +"
                    handleLeftAction={() => navigate('maintenance')}
                  >
                    <SwipeableViews
                      enableMouseEvents
                      style={{ paddingRight: '66%', position: 'relative' }}
                      index={maintenanceIndex}
                      onChangeIndex={(index: number) => {
                        setMaintenanceIndex(index);
                      }}
                    >
                      {maintenances.map((r, index) => {
                        const status = getStatus(r);
                        return (
                          <Task
                            key={index}
                            icon={maintenanceIcon}
                            title={
                              r.details.title.length > 13
                                ? `${r.details.title.substring(0, 13)}...`
                                : r.details.title
                            }
                            description={
                              r.details.description.length > 30
                                ? `${r.details.description.substring(0, 30)}...`
                                : r.details.description
                            }
                            time={status}
                            green={status === 'Complete'}
                            parentStyles={classes.taskStyles}
                            handlePress={() => {
                              setSelectedMaintenance({
                                ...r,
                                isTenantRequested: r.dashboardStatus === Status.REQUESTED,
                                isTenantReviewed: r.dashboardStatus === Status.REVIEW,
                                isTenantScheduled: r.dashboardStatus === Status.SCHEDULED,
                                isTenantCompleted: r.dashboardStatus === Status.COMPLETED,
                              });
                              navigate('tenantRequestInfo');
                            }}
                          />
                        );
                      })}
                      <Task
                        icon={search}
                        title="Raise request"
                        description={themeKey === ThemeKey.SWITCH ? 'Request a fix' : 'Submit now'}
                        loading={loading}
                        handlePress={() => navigate('maintenance')}
                      />
                    </SwipeableViews>
                  </TileContainer>
                ) : (
                  <TileContainer title="Booking requests & maintenance" isLink>
                    <div className={classes.thingsToDo}>
                      <Task
                        icon={search}
                        title="Raise request"
                        description={themeKey === ThemeKey.SWITCH ? 'Request a fix' : 'Submit now'}
                        loading={loading}
                        handlePress={() => {
                          if (isGuest) {
                            setShowAddPropertyPopup(true);
                          } else {
                            navigate('maintenance');
                          }
                        }}
                      />
                    </div>
                  </TileContainer>
                )}
              </>
            ) : null}
            {/* {!isGuest && <PrivateWorksCards />} */}
          </>
        ) : (
          <>
            <TileContainer
              title="Properties"
              showRightText="See all"
              isLink
              handleRightAction={() => navigate('properties')}
              noScroll
            >
              <PropertyCard
                index={0}
                property={undefined}
                selected={false}
                addProperty
                fullWidth
                noProperty
                loading={loading}
                snapTo={() => setShowAddPropertyPopup(true)}
              />
            </TileContainer>

            {loading && (
              <TileContainer title="Active services">
                <div className={classes.activeServicesGrid}>
                  {[...Array(3).fill(null)].map((service, idx) => (
                    <Tile
                      key={idx}
                      loading={loading}
                      handlePress={() => setShowAddPropertyPopup(true)}
                    />
                  ))}
                </div>
              </TileContainer>
            )}
            <TileContainer title="Essential services">
              <div className={classes.servicesGrid}>
                {FAKE_ESSENTIAL_SERVICES.map((service, idx) => (
                  <Tile
                    key={idx}
                    loading={loading}
                    title={service.name}
                    icon={service.icon}
                    handlePress={() => setShowAddPropertyPopup(true)}
                  />
                ))}
              </div>
            </TileContainer>
            <TileContainer title="Home services">
              <div className={classes.servicesGrid}>
                {FAKE_HOME_SERVICES.map((service, idx) => (
                  <Tile
                    key={idx}
                    loading={loading}
                    title={service.name}
                    icon={service.icon}
                    handlePress={() => setShowAddPropertyPopup(true)}
                  />
                ))}
              </div>
            </TileContainer>
            <TileContainer title="Things to do" noScroll>
              <div className={classes.thingsToDo}>
                <Task
                  focus
                  icon={house}
                  title="Add property"
                  description="Let's make your house a home"
                  loading={loading}
                  time="2 mins"
                  handlePress={() => {
                    if (isGuest) {
                      setShowAddPropertyPopup(true);
                    } else {
                      navigate('propertyOnboarding');
                    }
                  }}
                />
                <Task
                  title="Get move in ready"
                  description="Organise and connect your utilities"
                  icon={clock}
                  time="2-4 mins"
                  loading={loading}
                  handlePress={() => setShowAddPropertyPopup(true)}
                />
                <Task
                  title={
                    themeKey === ThemeKey.NAX
                      ? 'Lodge a new maintenance request'
                      : 'Get a quote for maintenance'
                  }
                  icon={maintenanceIcon}
                  description="Can we fix it? No, but these guys can!"
                  time="3 mins"
                  loading={loading}
                  handlePress={() => setShowAddPropertyPopup(true)}
                />
              </div>
            </TileContainer>
          </>
        )}
        <div className={classes.footerContainer}>
          <div className={classes.footerTitle}>{LABELS.QUICK_LINKS}</div>
          {!(noneOnboarded || isGuest) && onboardedProperties && onboardedProperties.length > 0 && (
            <>
              {isMaintenanceAllowed && (
                <div className={classes.footerLinkRow} onClick={() => navigate('maintenance')}>
                  <div className={classes.footerLinkText}>New maintenance request</div>
                  <img src={arrowRight} className={classes.footerLinkArrow} />
                </div>
              )}
              {onboardedProperties[selectedProperty].property!.emergencyMaintenanceLink && (
                <div
                  className={classes.footerLinkRow}
                  onClick={() => navigate('emergencyMaintenance')}
                >
                  <div className={classes.footerLinkText}>Emergency Maintenance</div>
                  <img src={arrowRight} className={classes.footerLinkArrow} />
                </div>
              )}
            </>
          )}
          <div
            className={classes.footerLinkRow}
            style={{ border: 'none' }}
            onClick={() => (window as any).Intercom('show')}
          >
            <div className={classes.footerLinkText}>
              {themeKey === ThemeKey.SWITCH ? 'Say hello to our team' : 'Contact support'}
            </div>
            <img src={arrowRight} className={classes.footerLinkArrow} />
          </div>
          {onboardedProperties &&
          onboardedProperties.length > 0 &&
          onboardedProperties[selectedProperty].property &&
          onboardedProperties[selectedProperty].property!.blogs &&
          onboardedProperties[selectedProperty].property!.blogs.length > 0 ? (
            <div className={classes.thingsToDo}>
              {onboardedProperties[selectedProperty].property!.blogs.map((r, index) => (
                <div className={classes.readMoreContainer} onClick={() => null} key={index}>
                  <div
                    className={classNameGenerator([
                      classes.imageContainer,
                      classes.imageContainerLoader,
                    ])}
                  >
                    <div className={classes.imageViewStyle}>
                      <img className={classes.image} src={r.imageLink} />
                    </div>
                  </div>
                  <div className={classes.textContainer}>
                    <div className={classes.text}>{r.title}</div>
                    <div className={classes.readmore}>Read more</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.thingsToDo}>
              {readMore.map((r, index) => (
                <div className={classes.readMoreContainer} onClick={() => null} key={index}>
                  <div
                    className={classNameGenerator([
                      classes.imageContainer,
                      classes.imageContainerLoader,
                    ])}
                  >
                    <div className={classes.imageViewStyle}>
                      <img className={classes.image} src={r.image} />
                    </div>
                  </div>
                  <div className={classes.textContainer}>
                    <div className={classes.text}>{r.text}</div>
                    <div className={classes.readmore}>Read more</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <AddPropertyPopup
        showModal={showAddPropertyPopup}
        toggleModal={(showModal: boolean) => setShowAddPropertyPopup(showModal)}
      />
    </div>
  );
};

const loading = loadingSelector([
  DashboardActionTypes.FETCH_COMPLETED,
  DashboardActionTypes.FETCH_PENDING,
  CimetActionTypes.GET_CIMET_SERVICES,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  isGuest: state.authState.guest,
  authDetails: state.authState.authDetails,
  dashboardState: state.dashboardState,
  bundleResponse: state.request.bundleResponse,
  availableServices: state.checkout.availableServices,
  checkoutProperty: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTheme: (theme: ThemeKey) => dispatch(ThemeActions.setTheme(theme)),
  fetchDashboardDetails: () =>
    dispatch(DashboardActions.fetchCompletedRequest({ skipReset: true })),
  getDashboardDetails: () => dispatch(DashboardActions.refreshRequest()),
  getPropertyMaintenance: (propertyId: string) =>
    dispatch(DashboardActions.getPropertyMaintenance(propertyId)),
  setSelectedMaintenance: (data: MaintenanceResponse) =>
    dispatch(LandlordActions.setSelectedMaintenance(data)),
  setSelectedProperty: (index: number) => {
    dispatch(DashboardActions.setSelectedProperty(index));
  },
  switchFooterTab: (tabName: BottomNavValue, tabProps?: TabProps) =>
    dispatch(DashboardActions.switchFooterTab(tabName, tabProps)),
  fetchPaymentDetails: (body: AddTagStatus) => dispatch(PaymentActions.postTagRequest(body)),
  getPackages: () => dispatch(RequestActions.getPackages()),
  getCimetServices: (data: CimetServicesRequest) =>
    dispatch(CimetActions.getCimetServicesRequest(data)),
  postCimetVisit: (data: CimetVisitRequest) => dispatch(CimetActions.postCimetVisitRequest(data)),
  getAvailableServices: (data: AvailableServicesRequest) =>
    dispatch(CheckoutActions.getAvailableServicesStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
