/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dialog, Drawer } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ADD_NEW_ICON from '../../../assets/payment/filled.png';
import tick from '../../../assets/tick.png';
import arrowLeft from '../../../assets/navigation/arrow-left.png';
import Button from '../../../components/button/Button';
import Text from '../../../components/text/Text';
import {
  GetPaymentMethodsResponse,
  GetPaymentType,
  GetPaymentTypeResponse,
  PostEditPaymentPayload,
} from '../../../models/payment/Payment';
import { SelectPaymentParams } from '../../../navigation/NavigationConstants';
import { extractParams, navigate, navigateBack } from '../../../navigation/NavigationUtils';
import { ApplicationState } from '../../../store/RootReducer';
import { PaymentActions } from '../../../store/actions/PaymentActions';
import { PaymentType } from '../../checkoutFinalize/components/paymentSection/PaymentSectionConstants';
import { serviceTypeList } from '../addPayment/AddPaymentConstants';
import { createEditPaymentObject } from '../addPayment/AddPaymentUtils';
import { handlePaymentSuccess } from '../addPayment/addCard/AddCardUtils';
import {
  LABELS,
  PAYMENT_LABELS,
  PaymentServiceType,
  modalLables,
  transactionFeeType,
} from './PaymentDetailsConstants';
import { useStyles } from './PaymentDetailsStyles';
import { handlePaymentTypeSuccess, openAddPayments } from './PaymentDetailsUtils';
import { classNameGenerator } from '../../../theme/GlobalStyles';
import info from '../../../assets/info.png';
import SlideLeft from '../../../components/transitionsHelper/SlideLeft';
import closeIcon from '../../../assets/navigation/close.png';

interface PaymentDetailsProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const PaymentDetails: FC<PaymentDetailsProps> = ({
  paymentMethods,
  accessToken,
  paymentTypes,
  getPaymentMethods,
  saveSelectedMethod,
  postEditPayment,
  getPaymentTypes,
}) => {
  const classes = useStyles();
  const [index, setIndex] = useState<number | boolean>(false);
  const [payment, setPayment] = useState<boolean | GetPaymentMethodsResponse>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } =
    extractParams<SelectPaymentParams>();

  useEffect(() => {
    if (accessToken && !paymentTypes.length) {
      getPaymentTypes(
        { propertyId, serviceType: serviceTypeList[serviceType.toLowerCase()] },
        false,
        () => null,
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && !paymentMethods.length) {
      getPaymentMethods((response: GetPaymentMethodsResponse[]) => {
        handlePaymentTypeSuccess(response.length !== 0, {
          propertyId,
          serviceType,
          paymentRefId,
          serviceAccountId,
          agencyName,
        });
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (paymentMethods && paymentRefId) {
      const paymentIndex = paymentMethods.findIndex((payment) => payment.refId === paymentRefId);
      setIndex(paymentIndex);
    }
  }, [paymentRefId, paymentMethods]);

  const selectedPaymentIndex = useMemo<number>(() => {
    return paymentMethods.findIndex((payment) => payment.refId === paymentRefId);
  }, [paymentRefId, paymentMethods]);

  const paymentMethodEnabled = useMemo<GetPaymentTypeResponse | undefined>(() => {
    if (payment) {
      return paymentTypes.find(
        (paymentType: GetPaymentTypeResponse) =>
          paymentType.paymentMethod === (payment as GetPaymentMethodsResponse).paymentMethod,
      );
    }
    return undefined;
  }, [payment, paymentTypes]);

  const paymentMethodDetails = useMemo(() => {
    if (paymentMethodEnabled) {
      return PAYMENT_LABELS.extraPayText.find(
        (data) => data.type === paymentMethodEnabled.paymentMethod,
      );
    }
    return undefined;
  }, [paymentMethodEnabled]);

  const handleNext = (payment: GetPaymentMethodsResponse) => {
    switch (serviceType.toLowerCase()) {
      case PaymentServiceType.Rent:
        saveSelectedMethod(payment);
        navigate('rentSummary');
        break;
      case PaymentServiceType.EditRent:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditElectricity:
        handleEditPayment(payment);
        break;
      case PaymentServiceType.EditBroadband:
        handleEditPayment(payment);
        break;
      default:
        break;
    }
  };

  const handlePaymentChange = (paymentToChange: GetPaymentMethodsResponse, idx: number) => {
    const isPaymentMethodEnabled = paymentTypes.find(
      (paymentType: GetPaymentTypeResponse) =>
        paymentType.paymentMethod === paymentToChange.paymentMethod,
    );
    setPayment(paymentToChange);
    if (isPaymentMethodEnabled) {
      setIndex(idx);
    } else {
      setOpenModal(true);
    }
  };

  const handleEditPayment = (payment: GetPaymentMethodsResponse) => {
    const requestData = createEditPaymentObject(parseInt(serviceAccountId, 10), payment.refId);
    postEditPayment({ data: requestData, onSuccess: () => handlePaymentSuccess() });
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.title}>{LABELS.NAV_TITLE}</div>
            <div className={classes.subtitle}>{LABELS.NAV_SUBTITLE}</div>

            {paymentMethods &&
              paymentMethods.map((paymentMethod: GetPaymentMethodsResponse, idx: number) => {
                const paymentMethodDetails =
                  paymentMethod.paymentMethod === PaymentType.Bank
                    ? PAYMENT_LABELS.paymentMethodDetails.Bank
                    : PAYMENT_LABELS.paymentMethodDetails.Credit.find(
                        (data) => data.cardType === paymentMethod.cardType,
                      ) || PAYMENT_LABELS.paymentMethodDetails.Credit[0];
                return (
                  <div
                    onClick={() => handlePaymentChange(paymentMethod, idx)}
                    className={classNameGenerator([
                      classes.menuItem,
                      idx === index && classes.selectedMenuItem,
                    ])}
                    key={idx}
                  >
                    <div className={classes.iconContainer}>
                      <img src={paymentMethodDetails.logo} className={classes.iconImage} />
                    </div>
                    <div className={classes.optionContainer}>
                      <div className={classes.optionTitle}>{paymentMethodDetails.name}</div>
                      <div className={classes.optionSubtitle}>
                        {paymentMethod.paymentMethod === 'Bank'
                          ? `${paymentMethod.bsb} - ${paymentMethod.accountNumber}`
                          : paymentMethod.paymentMethod === 'Credit'
                          ? `${paymentMethod.cardNumber!.replace(/\./g, '*')}`
                          : '**** **** **** 1234'}
                      </div>
                    </div>
                    <div className={classes.tickContainer}>
                      {idx === index && <img src={tick} className={classes.tick} />}
                    </div>
                  </div>
                );
              })}
            <div
              className={classes.menuItem}
              onClick={() =>
                openAddPayments({
                  propertyId,
                  serviceType,
                  paymentRefId,
                  serviceAccountId,
                  agencyName,
                })
              }
            >
              <div className={classes.iconContainer}>
                <img src={ADD_NEW_ICON} className={classes.iconImage} />
              </div>
              <div className={classes.optionContainer}>
                <div className={classes.optionTitle}>{PAYMENT_LABELS.addNewLabel}</div>
              </div>
            </div>

            {paymentMethodEnabled && (
              <div className={classes.messageBox}>
                <img src={info} className={classes.info} />
                {!paymentMethodEnabled.transactionFeeValue ? (
                  <div className={classes.transactionFeeStyle}>
                    {paymentMethodDetails && paymentMethodDetails.zeroValueMessage}
                  </div>
                ) : (
                  <div className={classes.transactionFeeStyle}>
                    {paymentMethodDetails &&
                      paymentMethodDetails.value(
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.transactionFeeValue}`
                          : `${paymentMethodEnabled.transactionFeeValue}%`,
                        paymentMethodEnabled.transactionFeeType === transactionFeeType.Flat
                          ? `$${paymentMethodEnabled.internationalFeeValue}`
                          : `${paymentMethodEnabled.internationalFeeValue}%`,
                      )}
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttonContainer}>
              <Button
                disabled={selectedPaymentIndex === index || index === false}
                parentStyles={classes.button}
                onPress={() => {
                  if (!(selectedPaymentIndex === index || index === false)) {
                    handleNext(payment as GetPaymentMethodsResponse);
                  }
                }}
              >
                {PAYMENT_LABELS.buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        style={{
          zIndex: 999999,
          marginLeft: screenWidth <= 500 + 15 ? 15 : screenWidth - 500,
        }}
        TransitionComponent={SlideLeft}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className={classes.popupHeader}>
          <div className={classes.popupLabel}>{modalLables.title}</div>
          <div className={classes.closeContainer} onClick={() => setOpenModal(false)}>
            <img className={classes.closeIcon} src={closeIcon} alt="closeIcon" />
          </div>
        </div>
        <div className={classes.bottomDrawer}>
          <div className={classes.disabledMessage}>{modalLables.subTitle}</div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentMethods: state.payment.paymentMethods,
  paymentTypes: state.payment.paymentTypes,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPaymentMethods: (onSuccess: (response: GetPaymentMethodsResponse[]) => void) => {
    dispatch(
      PaymentActions.getPaymentMethodsStart({
        onSuccess,
      }),
    );
  },
  saveSelectedMethod: (payment: GetPaymentMethodsResponse) => {
    dispatch(PaymentActions.postPaymentTokenSuccess(payment));
  },
  postEditPayment: (data: PostEditPaymentPayload) => {
    dispatch(PaymentActions.postEditPaymentStart(data));
  },
  getPaymentTypes: (
    data: GetPaymentType,
    isPaymentMethods: boolean,
    onSuccess: (isPaymentMethods: boolean) => void,
  ) => {
    dispatch(
      PaymentActions.getPaymentTypesStart({
        data,
        onSuccess: () => onSuccess(isPaymentMethods),
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
