/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { useStyles } from './ActionCardSlimStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import errorIcon from '../../assets/alert-large.png';
import arrowRight from '../../assets/navigation/arrow-right.png';
import closeIcon from '../../assets/navigation/close.png';

interface ActionCardSlimProps {
  title: string;
  buttonText: string;
  icon: string;
  backgroundColor?: string;
  handleButtonPress: () => void;
}

const ActionCardSlim: FC<ActionCardSlimProps> = ({
  title,
  buttonText,
  icon,
  backgroundColor,
  handleButtonPress,
}) => {
  const classes = useStyles();

  return (
    <div
      style={!!backgroundColor ? { backgroundColor } : {}}
      className={classNameGenerator([classes.actionCard])}
    >
      <div className={classes.actionCardWrapper}>
        <img src={icon} className={classes.icon} alt="icon" />
        <div className={classes.title}>{title}</div>
        <div className={classes.button} onClick={() => handleButtonPress()}>
          {buttonText}
        </div>
      </div>
    </div>
  );
};

export default ActionCardSlim;
