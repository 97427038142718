import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './DebuggingBoxStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { ThemeKey } from '../../theme/Theme';
import { ThemeActions } from '../../store/actions/ThemeActions';

interface DebuggingBoxProps {
  active?: boolean;
  theme: ThemeKey;
  setTheme: (theme: ThemeKey) => void;
}

const DebuggingBox: FC<DebuggingBoxProps> = ({ active, theme, setTheme }) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <div className={classNameGenerator([classes.container, active && classes.active])}>
      <div className={classes.toggle} onClick={() => setToggle(!toggle)} />
      {toggle && (
        <>
          <div
            className={classNameGenerator([
              classes.themeButton,
              theme === ThemeKey.SORTED && classes.activeThemeButton,
            ])}
            onClick={() => setTheme(ThemeKey.SORTED)}
          >
            SORTED
          </div>
          <div
            className={classNameGenerator([
              classes.themeButton,
              theme === ThemeKey.NAX && classes.activeThemeButton,
            ])}
            onClick={() => setTheme(ThemeKey.NAX)}
          >
            NAX
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  theme: state.themeState.themeKey,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTheme: (theme: ThemeKey) => dispatch(ThemeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebuggingBox);
