import { AvailableServicesResponse, Preopen } from '../../models/checkout/Checkout';
import { Property } from '../../models/property/property';
import { ServiceTypes } from '../../models/services/services';

export const getActiveServices = (
  requiredServiceTypes: ServiceTypes[] | undefined,
  cartItems: AvailableServicesResponse[],
  availableServices: AvailableServicesResponse[] | undefined,
): AvailableServicesResponse[] => {
  if (availableServices && availableServices.length) {
    // If no required serviceTypes we don't want to return anything so we give the UI a change
    // Test case, have available services already there, go essentials -> electricity
    if (!requiredServiceTypes) {
      return [];
    }
    // If nothing in cart, use only required serviceType/s
    if (!cartItems.length) {
      return availableServices.filter((service) =>
        requiredServiceTypes.includes(service.type as ServiceTypes),
      );
    }

    // Otherwise use what's in cart + required serviceType/s
    const cartServiceTypes = cartItems.map((c) => c.type);
    return availableServices.filter((service) =>
      [...cartServiceTypes, ...requiredServiceTypes].includes(service.type as ServiceTypes),
    );
  } else {
    return [];
  }
};

export const getPreopenedServices = (
  preopen: Preopen | undefined,
  cartItems: AvailableServicesResponse[],
): Preopen => {
  // TODO: Phase 2 preopened plans replace cart plans
  const result: Preopen = {};
  // Preopen cart items
  cartItems.map((item) => {
    const providerId = item.suppliers![0].providerId;
    const productId = item.suppliers![0].plans[0].productId;
    result[item.type] = {
      providerId,
      // productId,
    };
  });
  if (preopen) {
    Object.keys(preopen).map((key) => {
      if (preopen[key].providerId) {
        result[key] = {
          providerId: preopen[key].providerId,
        };
      } else {
        result[key] = {};
      }
    });
  }
  return result;
};

export const filterOutHTML = (value: string) => {
  return value.replace(/<[^>]*>?/gm, '');
};

export const isFooterButtonDisabled = (
  selectedServiceType: AvailableServicesResponse | undefined,
) => {
  if (selectedServiceType === undefined) return true;
  return !(
    !!selectedServiceType.suppliers &&
    !!selectedServiceType.suppliers.filter((supplier) => supplier.selected).length
  );
};

export const isRequiredInAvailable = (
  requiredServiceTypes: ServiceTypes[] | undefined,
  availableServices: AvailableServicesResponse[],
) => {
  if (requiredServiceTypes === undefined) return false;
  const toCheck = availableServices.map((a) => a.type);
  return requiredServiceTypes
    .filter((r) => r !== ServiceTypes.Rent && r !== ServiceTypes.Water)
    .every((r) => toCheck.includes(r));
};

export const shouldCallCheckout = (
  availableServices: AvailableServicesResponse[] | undefined,
  requiredServiceTypes: ServiceTypes[] | undefined,
  propertyId: string | undefined,
  checkoutProperty: Property | null | undefined,
) => {
  // If there are no available services OR
  // If there are available services but requiredServiceTypes are not present in them OR
  // If the propertyId !== checkoutProperty.id
  if (propertyId && !!requiredServiceTypes && !!requiredServiceTypes.length) {
    if (availableServices === undefined || availableServices.length === 0) return true;
    console.log('here1');
    console.log('requiredServiceTypes', requiredServiceTypes);
    console.log('availableServices', availableServices);

    // This line is commented out because going from rent onboarding to checkout can cause a loop
    // Do not remember what use case this was addressing
    // if (!isRequiredInAvailable(requiredServiceTypes, availableServices)) return true;
    console.log('here2');
    if (!checkoutProperty) return true;
    console.log('here3');
    if (!!checkoutProperty && propertyId !== checkoutProperty.id.toString()) return true;
  }
  return false;
};
