import { loadingAnimation } from '../../../../theme/GlobalStyles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    card: {
      backgroundColor: theme.colors.light,
      borderRadius: 6,
      marginBottom: 15,
    },
    loadingServiceRow: {
      width: '100%',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      ...loadingAnimation,
      borderRadius: 5000,
      width: 40,
      height: 40,
      marginLeft: 15,
      marginRight: 15,
    },
    title: {
      ...loadingAnimation,
      marginRight: 'auto',
      width: 120,
      height: 25,
      borderRadius: 4,
    },
    action: {
      ...loadingAnimation,
      borderRadius: 5000,
      width: 30,
      height: 30,
      marginRight: 15,
    },
    providerTitle: {
      ...loadingAnimation,
      marginRight: 'auto',
      marginLeft: 15,
      width: 120,
      height: 20,
      borderRadius: 4,
    },
    providerIcons: {
      ...loadingAnimation,
      borderRadius: 5000,
      width: 120,
      height: 30,
      marginRight: 15,
    },
    providerCards: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    providerCard1: {
      ...loadingAnimation,
      height: 240,
      width: '80%',
      borderRadius: 6,
      marginLeft: 15,
      marginRight: 15,
    },
    providerCard2: {
      ...loadingAnimation,
      height: 240,
      width: '20%',
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      marginRight: 15,
    },
    plans: {
      width: '100%',
      paddingBottom: 1,
    },
    plan: {
      ...loadingAnimation,
      marginBottom: 15,
      height: 180,
      width: 'calc(100% - 30px)',
      borderRadius: 6,
      marginLeft: 15,
      marginRight: 15,
    },
  })();
};
