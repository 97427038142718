import { Agency } from '../../models/agency/agency';
import { RentalFrequency } from '../../models/lease/lease';
import { PaymentMethod, PaymentMethods, RentService } from '../../models/services/rentService';
import { LABELS } from './ServiceSummaryConstants';

export interface MappedData {
  serviceName: string;
  serviceProvider: string;
  serviceAmount: number;
  serviceAmountFrequency: RentalFrequency;
  serviceNextPayment: string | null;
  servicePaymentMethod: string | null;
  servicePaymentAccount: string | null;
}

const filterPaymentMethod = (paymentMethod: PaymentMethod | null): string | null => {
  if (paymentMethod === null) {
    return null;
  } else {
    let paymentMethodLabel = '';
    switch (paymentMethod.paymentMethod) {
      case PaymentMethods.bank:
        paymentMethodLabel = LABELS.BANK_ACCOUNT;
        break;
      case PaymentMethods.creditCard:
        paymentMethodLabel = LABELS.CREDIT_CARD;
        break;
      case PaymentMethods.notAvailable:
        paymentMethodLabel = LABELS.NOTAVAILABLE;
        break;
      default:
        break;
    }
    return paymentMethodLabel;
  }
};

const filterPaymentAccount = (paymentMethod: PaymentMethod | null): string | null => {
  if (paymentMethod === null) {
    return null;
  } else {
    let paymentMethodValue = '';
    switch (paymentMethod.paymentMethod) {
      case PaymentMethods.bank:
        paymentMethodValue = `${paymentMethod.accountNumber as string}-${paymentMethod.bsb}`;
        break;
      case PaymentMethods.creditCard:
        paymentMethodValue = paymentMethod.cardNumber as string;
        break;
      case PaymentMethods.notAvailable:
        paymentMethodValue = LABELS.ADDPAYMENTLABEL;
        break;
      default:
        break;
    }
    return paymentMethodValue;
  }
};

export const filterData = (
  agencyDetails: Agency,
  serviceName: string,
  serviceDetails: RentService,
): MappedData => {
  const data: MappedData = {
    serviceName,
    serviceProvider: agencyDetails.tradingName,
    serviceAmount: serviceDetails.cost,
    serviceAmountFrequency: serviceDetails.paymentFrequency,
    serviceNextPayment: serviceDetails.nextPaymentDate,
    servicePaymentMethod: filterPaymentMethod(serviceDetails.paymentMethodResponse),
    servicePaymentAccount: filterPaymentAccount(serviceDetails.paymentMethodResponse),
  };
  return data;
};
