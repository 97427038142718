import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './ApplicationsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  AgencyWiseApplication,
  AgentDetails,
  Application,
  ApplicationProperty,
  ApplicationsRequest,
  ApplicationsResponse,
  DeleteDraftRequest,
  GroupRequest,
  GroupResponse,
} from '../../../../models/groups/Groups';
import SORTED_CIRCULAR_LOGO from '../../../../assets/logosCircle/sorted.png';
import propertyDefaultIcon from '../../../../assets/homePlaceholder.png';
import bedrooms from '../../../../assets/bed.png';
import bathrooms from '../../../../assets/bath.png';
import carspaces from '../../../../assets/car.png';
import accordian from '../../../../assets/navigation/accordian.png';
import { GroupsActionTypes, GroupsActions } from '../../../../store/actions/GroupsActions';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { AuthDetails } from '../../../../helpers/AuthHelper';
import { FindUserResponse } from '../../../../models/auth/auth';
import { ProfileState } from '../../../../store/state/ProfileState';
import { AppSettings } from '../../../../AppSettings';
import { Profile as ProfileModel } from '../../../../models/user/user';
import {
  ApplicationFilter,
  LABELS,
  PopupType,
  ResponseType,
  failedStatuses,
  notInFlightStatus,
} from './ApplicationsConstants';
import { getStatus, isInformationRequested, isSyncRequired } from './ApplicationsUtils';
import Text from '../../../../components/text/Text';
import Button from '../../../../components/button/Button';
import Box from '../../../../components/box/Box';
import InputSelect from '../../../../components/inputSelect/InputSelect';
import moment from 'moment';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { DateFormats } from '../../../../constants/Strings';
import Gravatars from '../../../../components/gravatars/Gravatars';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Dialog } from '@mui/material';
import WithdrawPopup from './components/WithdrawPopup/WithdrawPopup';
import ManagedProfiles from '../../../../components/managedProfiles/ManagedProfiles';
import { ThemeKey, getCurrentThemeKey } from '../../../../theme/Theme';

interface ApplicationsProps extends RouteComponentProps {
  loading: boolean;
  groups: GroupResponse[] | undefined;
  groupApplications: ApplicationsResponse | undefined;
  authDetails: AuthDetails;
  profile: ProfileState;
  applyAuthDetails: FindUserResponse;
  getGroupMasterProfile: (data: GroupRequest) => void;
  getAllApplications: (data: ApplicationsRequest) => void;
  deleteDraft: (data: DeleteDraftRequest) => void;
}

const Applications: FC<ApplicationsProps> = ({
  loading,
  groups,
  groupApplications,
  authDetails,
  profile,
  applyAuthDetails,
  getGroupMasterProfile,
  getAllApplications,
  deleteDraft,
}) => {
  const classes = useStyles();
  const [applicationFilter, setApplicationFilter] = useState<ApplicationFilter>(
    ApplicationFilter.ALL,
  );
  const [groupFilter, setGroupFilter] = useState<string>('All');
  const [showWithdrawPopup, setShowWithdrawPopup] = useState<{
    application: Application;
    property: ApplicationProperty | null;
    responseType: ResponseType | string;
    agencyWiseApplication: AgencyWiseApplication;
    groupRefId: string | null;
  } | null>(null);
  const themeKey = getCurrentThemeKey();
  const [contactInfoRefId, setContactInfoRefId] = useState<string | null>(null);

  // TODO? put in useEffect below too
  // const isVisible = useAppVisibility();

  useEffect(() => {
    getGroupMasterProfile({});
    getAllApplications({});
  }, []);

  const handleSubmit = (
    application: Application | null,
    responseType: ResponseType | string,
    agencyWiseApplication: AgencyWiseApplication,
    groupRefId: string | null,
    updatingOne?: boolean,
  ) => {
    const { agency } = agencyWiseApplication;
    let url = `${AppSettings.server.applyUILink}/#/applicationPreferences?agency=${agency}`;

    if (responseType === ResponseType.INVITE) {
      url += '&responseType=INVITE';
    }

    if (updatingOne) {
      url += '&updatingOne=true';
    }
    if (responseType === ResponseType.DRAFT) {
      url += '&responseType=DRAFT';
      url += `&draftIds=${application!.draftId!}`;
    } else {
      url += '&responseType=SUBMITTED';
      const applicationRefIds: string[] = [];
      agencyWiseApplication.application.map((app) => {
        app.propertyList.map((property) => {
          if (!notInFlightStatus.includes(getStatus(property.status!))) {
            applicationRefIds.push(app.refId!);
          }
        });
      });
      url += `&applicationRefIds=${applicationRefIds.toString()}`;
    }

    if (groupRefId) {
      url += `&groupRefId=${groupRefId}`;
    }

    url += `&firstname=${(profile.profile! as ProfileModel).firstName!}`;
    url += `&lastname=${(profile.profile! as ProfileModel).lastName!}`;
    url += `&email=${(profile.profile! as ProfileModel).email!}`;
    url += `&userId=${applyAuthDetails.id}`;
    url += `&authToken=${authDetails.accessToken}`;
    url += `&refreshToken=${authDetails.refreshToken}`;

    console.log('URL: ', url);
    window.open(url, '_blank');
  };

  const renderApplications = (
    agencyWiseApplications: AgencyWiseApplication[],
    responseType: ResponseType,
    groupRefId?: string,
  ) => {
    const foundGroup: GroupResponse | undefined = groups
      ? groups.find((group) => group.groupRefId === groupRefId)
      : undefined;

    const sortedCircularLogo = SORTED_CIRCULAR_LOGO;

    if (groupFilter !== 'All' && (foundGroup === undefined || groupFilter !== foundGroup.groupName))
      return null;

    return (
      <>
        {agencyWiseApplications.map((agency, index) => (
          <div
            className={classNameGenerator([
              responseType !== ResponseType.DRAFT && classes.applicationsSection,
            ])}
            key={index}
          >
            {((responseType === ResponseType.SUBMITTED &&
              (isSyncRequired(agency.application, groupApplications) ||
                isInformationRequested(agency.application, groupApplications))) ||
              responseType === ResponseType.INVITE) && (
              <div className={classes.applicationsRow}>
                <div className={classes.applicationsGroupNameRow}>
                  {/* <Gravatars group={foundGroup!} parentStyles={classes.applicationsGravatars} /> */}
                  <Text parentStyles={classes.applicationsGroupName}>Action required</Text>
                </div>
                <Button
                  onPress={() => handleSubmit(null, responseType, agency, groupRefId || null)}
                >
                  {responseType === ResponseType.SUBMITTED ? LABELS.SYNC_ALL : LABELS.ACCEPT_ALL}
                </Button>
              </div>
            )}
            {agency.application.map((application: Application, appIndex) => {
              const agentDetails = application.propertyList[0]!.agentDetails;
              const branchDetails = application.branch;
              const accordianActive = application.refId === contactInfoRefId;
              return (
                <Box parentStyles={classes.applicationContainer} key={appIndex}>
                  {application.propertyList.map(
                    (property: ApplicationProperty, propertyIndex: number) => (
                      <>
                        <div
                          className={classes.applicationRow}
                          key={propertyIndex}
                          style={
                            propertyIndex === 0
                              ? { border: 'none', paddingTop: 0, marginTop: 0 }
                              : {}
                          }
                        >
                          <div className={classes.propertyContainer}>
                            <img
                              className={classes.property}
                              src={property.photo ? property.photo : propertyDefaultIcon}
                              alt="property"
                            />
                          </div>
                          <div className={classes.applicationDetails}>
                            <div className={classes.applicationDetailsHeader}>
                              {responseType === ResponseType.DRAFT && (
                                <Text textVariant="info" parentStyles={classes.lastEditedDate}>
                                  Last edited:{' '}
                                  {moment(application.lastModifiedDate).format(
                                    DateFormats.extendedUiFormat,
                                  )}
                                </Text>
                              )}
                              <div className={classes.submissionRow}>
                                {responseType !== ResponseType.DRAFT && (
                                  <div
                                    className={`${classes.statusContainer} 
                              ${
                                !notInFlightStatus.includes(getStatus(property.status!)) &&
                                (groupApplications!.updateRequired.includes(
                                  property.applicationRefId!,
                                ) ||
                                  groupApplications!.informationRequested.includes(
                                    property.applicationRefId!,
                                  )) &&
                                responseType === ResponseType.SUBMITTED
                                  ? classes.yellowLabel
                                  : failedStatuses.includes(getStatus(property.status!))
                                  ? classes.incompleteLabel
                                  : classes.completeLabel
                              }
                              ${
                                failedStatuses.includes(getStatus(property.status!))
                                  ? classes.unsuccessfulLabel
                                  : undefined
                              }
                            `}
                                  >
                                    {!notInFlightStatus.includes(getStatus(property.status!)) &&
                                    responseType === ResponseType.SUBMITTED &&
                                    (groupApplications!.updateRequired.includes(
                                      property.applicationRefId!,
                                    ) ||
                                      groupApplications!.informationRequested.includes(
                                        property.applicationRefId!,
                                      ))
                                      ? `${
                                          groupApplications!.informationRequested.includes(
                                            property.applicationRefId!,
                                          )
                                            ? 'Info Requested'
                                            : 'Sync Profile'
                                        }`
                                      : getStatus(property.status!)}
                                  </div>
                                )}
                                {responseType === ResponseType.SUBMITTED && (
                                  <Text textVariant="info" parentStyles={classes.submittedDate}>
                                    Submitted:{' '}
                                    {moment(application.createdDate).format(
                                      DateFormats.extendedUiFormat,
                                    )}
                                  </Text>
                                )}
                              </div>
                              {foundGroup && (
                                <div className={classes.gravatarsRow}>
                                  <Text textVariant="info" parentStyles={classes.groupName}>
                                    {foundGroup.groupName}
                                  </Text>
                                  <div className={classes.gravatars}>
                                    <Gravatars group={foundGroup} />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={classes.applicationDetailsMiddle}>
                              <div className={classes.propertyAddressContainer}>
                                <Text textVariant="title" parentStyles={classes.address1}>
                                  {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                                    property.streetNumber || ''
                                  } ${property.streetName}`}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.address2}>
                                  {`${property.suburb}, ${property.state} ${property.postcode}`}
                                </Text>
                              </div>
                              <div className={classes.rentAndAgencyContainer}>
                                <div className={classes.agencyContainer}>
                                  <img
                                    className={classes.agencyLogo}
                                    onError={(e) => {
                                      (e.target as HTMLImageElement).src = sortedCircularLogo;
                                    }}
                                    src={agency.agencyLogo}
                                    alt="agency logo"
                                  />
                                </div>
                                <div className={classes.rentContainer}>
                                  <Text textVariant="title" parentStyles={classes.rent}>
                                    {property.rentOffer ? `$${property.rentOffer}` : '-'}
                                  </Text>

                                  <Text textVariant="info" parentStyles={classes.rentInfo}>
                                    Rent pw
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className={classes.applicationDetailsFooter}>
                              <div className={classes.statsRow}>
                                {!!property.bedrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bedrooms}
                                      alt="people stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bedrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        bedrooms
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.bathrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bathrooms}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bathrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        bathrooms
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.carspaces && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={carspaces}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.carspaces}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        car spaces
                                      </Text>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* <div className={classes.hotContainer}>
                              <Text textVariant="link" parentStyles={classes.hotText}>
                                {LABELS.HOT}
                              </Text>
                              <img src={hot} className={classes.hotIcon} alt="hot" />
                            </div> */}
                            </div>
                          </div>
                        </div>
                        {responseType === ResponseType.SUBMITTED && (
                          <>
                            <div className={classes.lineRow} />
                            <div className={classes.questionGrid}>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.DID_YOU_INSPECT}
                                </Text>
                                <Text textVariant="info">{property.inspectionMethod}</Text>
                              </div>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.INSPECTION_DATE}
                                </Text>
                                <Text textVariant="info">
                                  {moment(property.inspectionDate, 'DD/MM/YYYY').format(
                                    'DD/MM/YYYY',
                                  )}
                                </Text>
                              </div>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.PREFERRED_MOVE_IN_DATE}
                                </Text>
                                <Text textVariant="info">
                                  {moment(property.preferredMoveInDate, 'DD/MM/YYYY').format(
                                    'DD/MM/YYYY',
                                  )}
                                </Text>
                              </div>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.PREFERRED_LEASE_DURATION}
                                </Text>
                                <Text textVariant="info">
                                  {property.years ? `${property.years} year(s) ` : ' '}
                                  {property.months ? `${property.months} months(s) ` : ' '}
                                </Text>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ),
                  )}
                  {(agentDetails ||
                    (responseType === ResponseType.SUBMITTED &&
                      !notInFlightStatus.includes(
                        getStatus(application.propertyList[0].status!),
                      )) ||
                    responseType === ResponseType.INVITE ||
                    responseType === ResponseType.DRAFT) && (
                    <div className={classes.lineRow}>
                      {responseType === ResponseType.INVITE && (
                        <Button
                          parentStyles={classes.buttonMarginRight}
                          outline
                          onPress={() =>
                            setShowWithdrawPopup({
                              application,
                              property: application.propertyList[0],
                              responseType,
                              agencyWiseApplication: agency,
                              groupRefId: groupRefId || null,
                            })
                          }
                        >
                          {LABELS.DECLINE}
                        </Button>
                      )}
                      {responseType === ResponseType.DRAFT && (
                        <>
                          <Button
                            parentStyles={classes.buttonMarginRight}
                            outline
                            onPress={() => deleteDraft({ draftId: application.draftId! })}
                          >
                            {LABELS.DELETE}
                          </Button>
                          <Button
                            parentStyles={classes.buttonMarginRight}
                            onPress={() => handleSubmit(application, responseType, agency, null)}
                          >
                            {LABELS.EDIT}
                          </Button>
                        </>
                      )}
                      {responseType === ResponseType.SUBMITTED &&
                        !notInFlightStatus.includes(
                          getStatus(application.propertyList[0].status!),
                        ) && (
                          <>
                            <Button
                              parentStyles={classes.buttonMarginRight}
                              outline
                              onPress={() =>
                                setShowWithdrawPopup({
                                  application,
                                  property: application.propertyList[0],
                                  responseType,
                                  agencyWiseApplication: agency,
                                  groupRefId: groupRefId || null,
                                })
                              }
                            >
                              {LABELS.WITHDRAW}
                            </Button>
                            <Button
                              parentStyles={classes.buttonMarginRight}
                              onPress={() =>
                                handleSubmit(
                                  application,
                                  responseType,
                                  agency,
                                  groupRefId || null,
                                  true,
                                )
                              }
                            >
                              {LABELS.UPDATE}
                            </Button>
                          </>
                        )}
                      {responseType === ResponseType.SUBMITTED && agentDetails && (
                        <div
                          className={classes.actionContainer}
                          onClick={() =>
                            setContactInfoRefId(accordianActive ? null : application.refId!)
                          }
                        >
                          <Text textVariant="link" parentStyles={classes.actionText}>
                            {LABELS.CONTACT_INFO}
                          </Text>
                          <img
                            className={classNameGenerator([
                              classes.actionIcon,
                              accordianActive && classes.actionIconActive,
                            ])}
                            src={accordian}
                            alt="accordian"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {agentDetails && (
                    <div
                      className={classNameGenerator([
                        classes.contactDetailsRow,
                        accordianActive && classes.contactDetailsRowActive,
                      ])}
                    >
                      {agentDetails.name && (
                        <div className={classes.contactDetail}>
                          <PersonOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {agentDetails.name}
                            </Text>
                          </div>
                        </div>
                      )}
                      {branchDetails && (
                        <div className={classes.contactDetail}>
                          <PhoneAndroidOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {branchDetails.phone}
                            </Text>
                          </div>
                        </div>
                      )}
                      {agentDetails.email && (
                        <div className={classes.contactDetail}>
                          <EmailOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {agentDetails.email}
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
              );
            })}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={classes.pageContent}>
      <div className={classes.primaryContainer}>
        {themeKey !== ThemeKey.SWITCH && <ManagedProfiles />}
        <div className={classes.showingFilter}>
          <Text>{LABELS.SHOWING}</Text>
          <div className={classes.filterRow}>
            <InputSelect
              value={
                applicationFilter === 'All'
                  ? `Applications: ${applicationFilter}`
                  : applicationFilter
              }
              values={Object.values(ApplicationFilter).map((v) => ({ display: v, value: v }))}
              placeholder="All"
              parentStyles={classes.filterStyles}
              parentInputStyles={classes.filterInput}
              setValue={(value: string) => setApplicationFilter(value as ApplicationFilter)}
              hideEndElement
            />
            <div className={classes.filterBreak} />
            {groups !== undefined && (
              <InputSelect
                value={groupFilter === 'All' ? `Group: ${groupFilter}` : groupFilter}
                values={groups
                  .map((g) => g.groupName)
                  .concat('All')
                  .map((v) => ({ display: v, value: v }))}
                placeholder="All"
                parentStyles={classes.filterStyles}
                parentInputStyles={classes.filterInput}
                setValue={(value: string) => setGroupFilter(value)}
                hideEndElement
              />
            )}
          </div>
        </div>
        {!groupApplications && loading ? (
          <>
            <div className={classes.section}>
              <Text textVariant="boxTitle">{LABELS.INVITES}</Text>
              {[...Array(2).fill(null)].map((_, i) => (
                <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
              ))}
            </div>
            <div className={classes.section}>
              <Text textVariant="boxTitle">{LABELS.DRAFTS}</Text>
              {[...Array(3).fill(null)].map((_, i) => (
                <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
              ))}
            </div>
            <div className={classes.section}>
              <Text textVariant="boxTitle">{LABELS.SUBMITTED}</Text>
              {[...Array(4).fill(null)].map((_, i) => (
                <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
              ))}
            </div>
          </>
        ) : (
          <>
            {groupApplications &&
            (groupApplications.invitedApplicationGroups.groups.length ||
              groupApplications.draftApplications.agencyWiseApplications.length ||
              groupApplications.submittedApplicationGroups.groups.length) ? (
              <>
                {[ApplicationFilter.ALL, ApplicationFilter.INVITED].includes(applicationFilter) &&
                groupApplications.invitedApplicationGroups.groups.length ? (
                  <div className={classes.section}>
                    <>
                      {(groupFilter === 'All' ||
                        groupApplications.invitedApplicationGroups.groups
                          .map((g) => g.groupRefId)
                          .includes(groupFilter)) && (
                        <Text textVariant="boxTitle">{LABELS.INVITES}</Text>
                      )}
                      {groupApplications.invitedApplicationGroups.groups.map((group) => (
                        <>
                          {renderApplications(
                            group.agencyWiseApplications,
                            ResponseType.INVITE,
                            group.groupRefId,
                          )}
                        </>
                      ))}
                    </>
                  </div>
                ) : null}

                {[ApplicationFilter.ALL, ApplicationFilter.DRAFT].includes(applicationFilter) &&
                groupApplications.draftApplications.agencyWiseApplications.length ? (
                  <div className={classes.section}>
                    <>
                      {groupFilter === 'All' && (
                        <div className={classes.row}>
                          <Text textVariant="boxTitle">{LABELS.DRAFTS}</Text>
                          <Button
                            parentStyles={classes.addGroup}
                            onPress={() => {
                              let url = `${AppSettings.server.applyUILink}/#/properties`;
                              window.open(url, '_blank');
                            }}
                          >
                            {LABELS.ADD_DRAFT}
                          </Button>
                        </div>
                      )}
                      {renderApplications(
                        groupApplications.draftApplications.agencyWiseApplications,
                        ResponseType.DRAFT,
                      )}
                    </>
                  </div>
                ) : null}

                {[ApplicationFilter.ALL, ApplicationFilter.SUBMITTED].includes(applicationFilter) &&
                groupApplications.submittedApplicationGroups.groups.length ? (
                  <div className={classes.section}>
                    <>
                      {(groupFilter === 'All' ||
                        groupApplications.submittedApplicationGroups.groups
                          .map((g) => g.groupRefId)
                          .includes(groupFilter)) && (
                        <Text textVariant="boxTitle">{LABELS.SUBMITTED}</Text>
                      )}
                      {groupApplications.submittedApplicationGroups.groups.map((group) => (
                        <>
                          {renderApplications(
                            group.agencyWiseApplications,
                            ResponseType.SUBMITTED,
                            group.groupRefId,
                          )}
                        </>
                      ))}
                    </>
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Dialog open={!!showWithdrawPopup} maxWidth="md">
          {showWithdrawPopup && showWithdrawPopup.property && (
            <WithdrawPopup
              responseType={showWithdrawPopup.responseType}
              applicationRefId={showWithdrawPopup.application.refId!}
              groupRefId={showWithdrawPopup.groupRefId!}
              propertyAddress={showWithdrawPopup.property.address}
              closeWithdrawPopup={() => setShowWithdrawPopup(null)}
            />
          )}
        </Dialog>
      </div>
    </div>
  );
};

const loading = loadingSelector([
  GroupsActionTypes.GET_GROUP_MASTER_PROFILE,
  GroupsActionTypes.GET_ALL_APPLICATIONS,
  GroupsActionTypes.DECLINE_INVITATION,
  GroupsActionTypes.WITHDRAW_APPLICATION,
  GroupsActionTypes.WITHDRAW_MYSELF,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  groups: state.groups.groups,
  groupApplications: state.groups.groupApplications,
  authDetails: state.authState.authDetails as AuthDetails,
  applyAuthDetails: state.authState.applyAuthDetails,
  profile: state.profileState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteDraft: (data: DeleteDraftRequest) => {
    dispatch(GroupsActions.deleteDraftRequest(data));
  },
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  getAllApplications: (data: ApplicationsRequest) => {
    dispatch(GroupsActions.getAllApplicationsRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Applications));
