import { SERVICE_TYPE_TITLE, ServiceTypes } from '../../models/services/services';
import electricityHero from '../../assets/bannerPeople/electricity.png';
import broadbandHero from '../../assets/bannerPeople/broadband.png';
import gasHero from '../../assets/bannerPeople/gas.png';
import cleaningHero from '../../assets/bannerPeople/cleaning.png';
import removalsHero from '../../assets/bannerPeople/removals.png';
import gardenHero from '../../assets/bannerPeople/garden.png';
import tradesHero from '../../assets/bannerPeople/trades.png';
import localHero from '../../assets/bannerPeople/local.png';
import checkoutHero from '../../assets/bannerPeople/checkout.png';
import { theme } from '../../theme/Theme';

export const LABELS = {
  MARKETPLACE_TITLE: 'Get your move \nSorted in one go!',
  MARKETPLACE_SUBTITLE:
    "We can't all carry the shopping in one go, but we can \norganise your move and connect all of your utilities!",
  NOTHIN:
    'This may be because your place is not in our range or you are already connected to everything we can currently offer',
  NOTHINGTITLE: 'No services available at your address',
  CONTACT_SUPPORT: 'Contact support',
  GET_STARTED: 'Get started',
  FINISH: 'Close',
  REMIND: 'Remind me later',
};

export const getServiceTypeCopy = (serviceType: ServiceTypes | undefined): string | null => {
  switch (serviceType) {
    case ServiceTypes.Electricity:
      return 'Hit the switch: set up electricity \nWe have collated the best options for you \nbased on your house size.';
    case ServiceTypes.Broadband:
      return "Working from home or streaming success? \nWe've got your broadband plans sorted.";
    case ServiceTypes.Gas:
      return "It's cold! Get the heater on ASAP! \nGet your gas connected in just a few taps";
    case ServiceTypes.Cleaning:
      return 'Take your house to the cleaners. \nThese people think laundry is loads of fun';
    case ServiceTypes.Removals:
      return 'Get moving! Find a removalist near you \nEverything you need to feel balanced, \nrefreshed and ready to go.';
    default:
      return null;
  }
};

export interface BannerAsset {
  image: string;
  title: string;
  description: string;
  backgroundColor: string;
}

export const getServiceTypeBanner = (serviceType: ServiceTypes | undefined): BannerAsset => {
  switch (serviceType) {
    case ServiceTypes.Electricity:
      return {
        backgroundColor: theme.colors.lightOrange,
        image: electricityHero,
        title: 'Hit the switch \nSetup electricity',
        description:
          'We have plans to suit all households and budgets, \nget connected in minutes.',
      };
    case ServiceTypes.Broadband:
      return {
        backgroundColor: theme.colors.lightPurple,
        image: broadbandHero,
        title: 'Level up your \nbroadband connection!',
        description:
          'We have plans to suit everyone from casual streaming \nand through to the working from home pros!',
      };
    case ServiceTypes.Gas:
      return {
        backgroundColor: theme.colors.lightRed,
        image: gasHero,
        title: "Now we're cooking. \nGet gas sorted in minutes",
        description:
          'Turn up the heat and find a gas plan to \nsuit your budget in just a few taps.',
      };
    case ServiceTypes.Cleaning:
      return {
        backgroundColor: theme.colors.lightBlue,
        image: cleaningHero,
        title: 'Take your place to \nthe cleaners.',
        description:
          'Treat your house to a spa day: Get fast and \nFREE quotes from trusted suppliers.',
      };
    case ServiceTypes.Removals:
      return {
        backgroundColor: theme.colors.lightOrange,
        image: removalsHero,
        title: "Don't stress, \nLet us carry the load!",
        description:
          'Take some of the pain out of moving with \nremovalists near you, ready to give you a FREE \nquote!',
      };
    case ServiceTypes.Gardening:
      return {
        backgroundColor: theme.colors.lightGreen,
        image: gardenHero,
        title: 'Use your phone thumbs, \n find a green thumb.',
        description:
          'Turn your garden into a masterpiece or give your indoor \njungle some TLC with the help of pro gardeners near you!',
      };

    case ServiceTypes.Locksmith:
    case ServiceTypes.Handyman:
    case ServiceTypes.Electrical:
    case ServiceTypes.Plumbing:
      return {
        backgroundColor: theme.colors.lightOrange,
        image: tradesHero,
        title: 'Can we fix it? No. \nBut these guys can!',
        description:
          'Maintenance jobs, small jobs or building your \ndream home, we have trades ready to give you \na quote.',
      };
    case SERVICE_TYPE_TITLE.TechSupport.replace(' ', ''):
    case SERVICE_TYPE_TITLE.VerticalGardens.replace(' ', ''):
    case SERVICE_TYPE_TITLE.PestControl.replace(' ', ''):
    case SERVICE_TYPE_TITLE.HomeSecurity.replace(' ', ''):
      return {
        backgroundColor: theme.colors.lightGreen,
        image: localHero,
        title: 'All your home needs \nin one place',
        description:
          'The local marketplace is your one stop shop to get \n quotes, connect utilities and find everything you \nneed to ease the burden of managing a household.',
      };

    default:
      return {
        backgroundColor: theme.colors.lightOrange,
        image: checkoutHero,
        title: 'Get your move \nSorted in one go!',
        description:
          "We can't all carry the shopping in one go, but we can \norganise your move and connect all of your utilities!",
      };
  }
};
