import { getCurrentTheme } from '../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../helpers/GetFonts';
import { SIZES } from '../../../constants/Sizes';
import {
  maxContentWidth,
  pageContainer,
  smallPageContainer,
  primaryContent,
  pageTitleContainer,
  actionGreen,
} from '../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...pageContainer,
    },
    titleContainer: {
      ...pageTitleContainer,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      color: theme.colors.title,
      fontSize: theme.font.xxl,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 4,
    },
    subtitle: {
      color: theme.colors.black38,
      fontSize: theme.font.l,
      fontWeight: getFonts('Medium'),
      marginBottom: theme.units.spacing * 8,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    secondaryContainer: {
      flex: 1,
      flexShrink: 0,
      maxWidth: 480,
      minWidth: 400,
      marginLeft: 48,
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        display: 'none',
      },
    },
    banner: {
      marginBottom: theme.units.spacing * 8,
      backgroundColor: `${theme.colors.lightPurple} !important`,
    },
    tabContainer: {
      paddingLeft: theme.units.spacing * 12,
      paddingRight: theme.units.spacing * 12,
      maxWidth: 'calc(1200px - 92px)',
      [`@media (max-width: ${SIZES.lg}px)`]: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 'normal',
      },
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 8,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
      marginLeft: theme.units.spacing * 4,
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    bottomDrawer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.units.spacing * 6,
      paddingTop: 0,
    },
    dialog: {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      overflow: 'hidden',
    },
    bottomDrawerContainer: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    bottomDrawerTitle: {
      fontSize: 24,
      letterSpacing: 0.3,
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
    },
    footerButtonSecondary: {
      marginTop: 12,
      padding: '16px 16px 16px 16px',
      backgroundColor: theme.colors.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      borderRadius: 6,
      border: `${theme.colors.borderColor} 2px solid`,
    },
    disabledMessage: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
      fontSize: '16px',
      marginTop: 4,
      marginBottom: 36,
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.light,
      padding: '12px',
      paddingLeft: 16,
      alignItems: 'center',
      fontWeight: 600,
      color: theme.colors.black87,
      borderRadius: 12,
      border: `${theme.colors.borderColorLight} 2px solid`,
      marginBottom: '12px',
      cursor: 'pointer',
      minHeight: 60,
    },
    selectedMenuItem: {
      border: `${theme.colors.buttonPrimary} 2px solid`,
    },
    iconContainer: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
      marginRight: theme.units.spacing * 4,
    },
    iconImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    optionContainer: {
      marginRight: 'auto',
    },
    optionTitle: {
      fontFamily: 'GT Walsheim',
      color: theme.colors.black87,
      fontSize: '16px',
      fontWeight: 500,
    },
    optionSubtitle: {
      fontFamily: 'GT Walsheim',
      marginTop: 4,
      color: theme.colors.black38,
      fontSize: '13px',
      fontWeight: 500,
    },
    tickContainer: {
      width: '22px',
      height: '22px',
      marginLeft: 'auto',
      marginRight: '12px',
    },
    tick: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      ...actionGreen,
    },
    messageBox: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.colors.fadedBlue,
      padding: theme.units.spacing * 4,
      borderRadius: theme.units.borderRadius,
      marginTop: theme.units.spacing * 4,
    },
    transactionFeeStyle: {
      fontWeight: getFonts('Medium'),
      color: theme.colors.black60,
      fontSize: '14px',
    },
    info: {
      width: 22,
      heigth: 22,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 2,
      ...actionGreen,
    },
    terms: {
      fontWeight: getFonts('Light'),
      fontSize: '16px',
      letterSpacing: '0.15px',
      lineHeight: '20px',
      marginBottom: '24px',
      marginTop: 4,
    },
    downloadButtonText: {
      fontWeight: getFonts('Medium'),
      fontSize: '16px',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      textTransform: 'capitalize',
      color: theme.colors.buttonPrimary,
      textDecoration: 'underline',
      marginBottom: '36px',
      cursor: 'pointer',
    },
    downloadButton: {
      display: 'flex',
      flexDirection: 'row',
      padding: '32px 16px 16px 0',
    },
    checkContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-11px',
      marginBottom: '16px',
    },
    checkboxText: {
      marginTop: 2,
      cursor: 'pointer',
      userSelect: 'none',
    },
    popupHeader: {
      margin: theme.units.spacing * 6,
      paddingBottom: theme.units.spacing * 6,
      backgroundColor: theme.colors.light,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      marginBottom: 0,
    },
    popupLabel: {
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 24,
    },
    closeContainer: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      cursor: 'pointer',
    },
    closeIcon: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
  })();
};
