export interface Percentage {
  outOf: number;
  completed: number;
}

export const getPercentage = (toCalc: Percentage): number => {
  const result = (toCalc.completed / toCalc.outOf) * 100;
  if (isNaN(result)) {
    return 0;
  } else {
    return result;
  }
};
