/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { RequestAction, RequestActions, RequestActionTypes } from '../actions/RequestActions';
import {
  CancelRequest,
  RequestQuote,
  ReviewRequest,
  ReviewResponse,
  Status,
  TenantRequest,
} from '../../models/tenantRequest/Request';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { filterSolarRequest } from '../../helpers/RequestHelper';
import RequestService from '../../services/request/RequestService';
import { BundleResponse } from '../../models/bundles/Bundles';

export function* getRequestSaga(action: RequestAction) {
  try {
    const { status, propertyId } = action.data as TenantRequest;
    const response: MaintenanceResponse[] = yield RequestService.getRequestService(
      status,
      propertyId,
    );
    const filteredResponse = filterSolarRequest(response);
    switch (status) {
      case Status.REVIEW:
        yield put(RequestActions.getReviewRequestsSuccess(filteredResponse));
        break;
      case Status.SCHEDULED:
        yield put(RequestActions.getScheduledRequestsSuccess(filteredResponse));
        break;
      case Status.COMPLETED:
        yield put(RequestActions.getCompletedRequestsSuccess(filteredResponse));
        break;
      default:
        yield put(RequestActions.getRequestsSuccess(filteredResponse));
        break;
    }
  } catch (error) {
    yield put(RequestActions.getRequestsError(error as Error));
  }
}

export function* cancelRequestSaga(action: RequestAction) {
  try {
    const { maintenanceId, onSuccess } = action.data as CancelRequest;
    yield RequestService.cancelRequestService(maintenanceId);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as CancelRequest;
    if (onError) {
      onError();
    }
    yield put(RequestActions.getRequestsError(error as Error));
  }
}

export function* submitQuoteRequestSaga(action: RequestAction) {
  try {
    const { quoteId, status, onSuccess } = action.data as RequestQuote;
    yield RequestService.submitQuoteRequestService(quoteId, status);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as RequestQuote;
    if (onError) {
      onError();
    }
    yield put(RequestActions.getRequestsError(error as Error));
  }
}

export function* getPackagesSaga(action: RequestAction) {
  try {
    const response: BundleResponse = yield RequestService.getBundles();
    yield put(RequestActions.getPackagesSuccess(response));
  } catch (error) {
    yield put(RequestActions.getPackagesError());
  }
}

export function* submitReviewSaga(action: RequestAction) {
  try {
    const { onSuccess } = action.data as ReviewRequest;
    yield RequestService.submitReview(action.data as ReviewRequest);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as ReviewRequest;
    if (onError) {
      onError();
    }
    yield put(RequestActions.getRequestsError(error as Error));
  }
}

export function* getReviewSaga(action: RequestAction) {
  try {
    const response: ReviewResponse = yield RequestService.getReview(action.data as string);
    yield put(RequestActions.getReviewSuccess(response));
  } catch (error) {
    yield put(RequestActions.getReviewError(error as Error));
  }
}

function* requestWatcher() {
  yield takeEvery(RequestActionTypes.GET_REQUESTS, (action) =>
    getRequestSaga(action as RequestAction),
  );
  yield takeEvery(RequestActionTypes.CANCEL_REQUEST, (action) =>
    cancelRequestSaga(action as RequestAction),
  );
  yield takeEvery(RequestActionTypes.SUBMIT_REQUEST_QUOTE, (action) =>
    submitQuoteRequestSaga(action as RequestAction),
  );
  yield takeEvery(RequestActionTypes.GET_PACKAGE_REQUEST, (action) =>
    getPackagesSaga(action as RequestAction),
  );
  yield takeEvery(RequestActionTypes.SUBMIT_REVIEW_REQUEST, (action) =>
    submitReviewSaga(action as RequestAction),
  );
  yield takeEvery(RequestActionTypes.GET_MR_REVIEW_REQUEST, (action) =>
    getReviewSaga(action as RequestAction),
  );
}

export default requestWatcher;
