/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';
import { get } from 'lodash';
import { getDefaultValue } from '../../SelectOptionsUtils';
import { Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import {
  PlanOption,
  FeatureDetails,
  Options,
  SupplierOption,
} from '../../../../../../models/billing/Billing';
import { useStyles } from './PlanOptionsStyles';
import InputMultiSelect from '../../../../../../components/inputMultiSelect/InputMultiSelect';

interface PlanOptionsProps {
  option: PlanOption;
  handlePlanOptionChange: (supplierOption: PlanOption, selection: Options, value?: string) => void;
  supplierSelectionAns: Map<string, FeatureDetails> | {};
}

const PlanOptions: FC<PlanOptionsProps> = ({
  option,
  supplierSelectionAns,
  handlePlanOptionChange,
}) => {
  const classes = useStyles();
  const toggleValue = getDefaultValue(supplierSelectionAns, option.Group);
  const selectedOption =
    option.Options &&
    option.Options.length > 0 &&
    option.Options.find((optionData) => optionData.Label === toggleValue);
  return (
    <div className={classes.questionContainer}>
      <InputMultiSelect
        title={option.OptionsDescription}
        selected={(() => {
          const index = option.Options.findIndex(
            (o) => (o.ProductId ? o.ProductId : o.Label) === toggleValue || o.Label === toggleValue,
          );

          return index !== -1 ? index : null;
        })()}
        mandatory
        values={option.Options.map((o, i) => ({ display: o.Label, value: i }))}
        setValue={(value: number) => {
          handlePlanOptionChange(
            option,
            option.Options[value] as Options,
            option.Options[value].Label,
          );
        }}
      />
      {selectedOption && selectedOption.HelpText && (
        <div className={classes.infoText}>{selectedOption.HelpText}</div>
      )}
      {selectedOption && selectedOption.PricingHelpText && (
        <div className={classes.infoText}>{selectedOption.PricingHelpText}</div>
      )}
    </div>
  );
};

export default PlanOptions;
