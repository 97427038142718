import React, { FC, useEffect, useState, useMemo, CSSProperties } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './ActivityIndicatorStyles';

interface ActivityIndicatorProps {
  outline?: boolean;
  color?: string;
  size?: string;
}

const ActivityIndicator: FC<ActivityIndicatorProps> = ({ outline, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <div
        className={classNameGenerator([outline ? classes.loadingOutline : classes.loading])}
        style={{ ...(color && { borderTopColor: color }) }}
      />
    </div>
  );
};

export default ActivityIndicator;
