import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PaymentActions } from '../../../../store/actions/PaymentActions';
import { AvailableServicesResponse, PersonalDetails } from '../../../../models/checkout/Checkout';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { calculateIntentCost } from './StripeSectionUtils';
import StripeSection from './StripeSection';
import {
  CheckoutStripeToken,
  GetStripePaymentIntentRequest,
} from '../../../../models/payment/Payment';
import { Property } from '../../../../models/property/property';
import { ApplicationState } from '../../../../store/RootReducer';

interface StripeWrapperProps {
  cartItem: AvailableServicesResponse;
  stripeCheckoutTokens: CheckoutStripeToken[];
  personalDetails: PersonalDetails;
  property: Property | undefined | null;
  setPaymentMethod: (refId: string) => void;
  getStripeCheckoutTokenRequest: (data: GetStripePaymentIntentRequest) => void;
}

const StripeWrapper: FC<StripeWrapperProps> = ({
  cartItem,
  stripeCheckoutTokens,
  personalDetails,
  property,
  setPaymentMethod,
  getStripeCheckoutTokenRequest,
}) => {
  const stripeIntent = useMemo<CheckoutStripeToken | undefined>(() => {
    const found = stripeCheckoutTokens.find(
      (token) => token.providerId === cartItem.suppliers![0].providerId,
    );
    return found || undefined;
  }, [stripeCheckoutTokens]);

  const stripePromise = useMemo<Promise<Stripe | null>>(() => {
    return loadStripe(stripeIntent ? stripeIntent.stripePromise : '');
  }, [stripeIntent]);

  useEffect(() => {
    getStripeCheckoutTokenRequest({
      totalAmount: calculateIntentCost(cartItem, true),
      cartItemId: cartItem.suppliers![0].plans[0].cartItemId!,
      serviceType: cartItem.type,
      providerId: cartItem.suppliers![0].providerId,
      productId: cartItem.suppliers![0].plans[0].productId,
    });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <StripeSection
        personalDetails={personalDetails}
        property={property}
        setPaymentMethod={setPaymentMethod}
        cartItem={cartItem}
        stripeIntent={stripeIntent}
      />
    </Elements>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  stripeCheckoutTokens: state.payment.stripeCheckoutTokens,
  personalDetails: state.checkout.personalDetails,
  property: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getStripeCheckoutTokenRequest: (data: GetStripePaymentIntentRequest) =>
    dispatch(PaymentActions.getStripeCheckoutTokenRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeWrapper);
