/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    root: {
      width: '100%',
      cursor: 'pointer',
    },
    inputFileStyle: {
      padding: '5px 2px 10px 0px',
      cursor: 'pointer',
    },
    input: {
      display: 'none',
    },
    fileImage: {
      color: 'grey',
      cursor: 'pointer',
    },
    cancelIcon: {
      cursor: 'pointer',
    },
    displayFile: {
      paddingBottom: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `dotted ${theme.colors.borderColorLight}`,
      marginBottom: '10px',
    },
    uploadFile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `2px solid ${theme.colors.borderColor}`,
      cursor: 'pointer',
    },
    errorLine: {
      borderBottom: `2px solid ${theme.colors.error}`,
    },
  })();
};
