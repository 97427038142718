import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    quoteContainer: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      overflow: 'hidden',
    },
    activeQuote: {
      border: `${theme.colors.buttonPrimary} 1px solid`,
    },
    supplierBackgroundImgContainer: {
      width: '100%',
      height: '100px',
      position: 'relative',
      cursor: 'pointer',
    },
    supplierBackgroundImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    supplierLogoContainer: {
      position: 'absolute',
      right: 15,
      bottom: -25,
      width: '50px',
      height: '50px',
      borderRadius: 5000,
      overflow: 'hidden',
      border: `${theme.colors.light} 1px solid`,
      boxSizing: 'border-box',
      backgroundColor: 'white',
      zIndex: 1,
      cursor: 'pointer',
    },
    supplierLogo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'relative',
    },
    content: {
      padding: theme.units.spacing * 4,
      paddingTop: theme.units.spacing * 2,
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black38,
      marginTop: theme.units.spacing,
      marginBottom: theme.units.spacing,
    },
    description: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
    },
    infoText: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black60,
      marginTop: 12,
      opacity: 0.6,
      lineHeight: 1.5,
    },
    actionContainer: {
      backgroundColor: theme.colors.light,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 6,
      paddingBottom: 10,
      borderTop: `${theme.colors.borderColorLight} 1px solid`,
      marginTop: theme.units.spacing * 4,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
      padding: '6px 16px',
      backgroundColor: theme.colors.light,
      borderRadius: 6,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      cursor: 'pointer',
      marginLeft: 'auto',
      width: 'max-content',
    },
    activeButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
      padding: '6px 16px',
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      cursor: 'pointer',
      marginLeft: 'auto',
      width: 'max-content',
    },
  })();
};
