import React, { FC, useEffect, useState, useMemo } from 'react';
import { ClickAwayListener } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './PropertyBarStyles';
import homePlaceholder from '../../../../assets/homePlaceholder.png';
import { DashboardState } from '../../../../store/state/DashboardState';
import { DashboardActions } from '../../../../store/actions/DashboardActions';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { Property } from '../../../../models/property/property';
import accordian from '../../../../assets/navigation/accordian.png';
import Text from '../../../../components/text/Text';
import { LABELS } from './PropertyBarConstants';
import { CheckoutActionTypes, CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { ServiceTypes } from '../../../../models/services/services';
import { navigate, resetNavigation } from '../../../../navigation/NavigationUtils';
import { createRequiredServiceTypes } from '../../../../helpers/CheckoutHelper';

interface PropertyBarProps {
  loading: boolean;
  property: Property | null | undefined;
  dashboardState: DashboardState;
  setSelectedProperty: (index: number) => void;
  setRequiredServiceTypes: (data: ServiceTypes[]) => void;
}

const PropertyBar: FC<PropertyBarProps> = ({
  loading,
  property,
  dashboardState: { onboardedProperties, selectedProperty },
  setSelectedProperty,
  setRequiredServiceTypes,
}) => {
  const [showPropertyPopup, setShowPropertyPopup] = useState<boolean>(false);
  const classes = useStyles();
  let address1 = ``;
  let address2 = ``;
  if (property) {
    const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property;
    address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
    address2 = `${suburb}, ${state} ${postcode}`;
  }

  const handlePropertySelect = (index: number) => {
    setShowPropertyPopup(false);
    if (selectedProperty !== index) {
      setSelectedProperty(index);
      if (!!onboardedProperties && !!onboardedProperties.length) {
        resetNavigation('checkoutSelect');
        setRequiredServiceTypes(
          onboardedProperties[index]
            .services!.filter(
              (s) =>
                s.status === 'New' &&
                s.serviceType !== ServiceTypes.Rent &&
                s.serviceType !== ServiceTypes.Water,
            )
            .slice(0, 5)
            .map((s) => s.serviceType.replace(' ', '') as ServiceTypes),
        );
      }
    }
  };

  return (
    <div className={classes.propertyContainer}>
      <div className={classes.propertyWrapper}>
        {loading ? (
          <>
            <div className={classes.loadingPhoto} />
            <div className={classes.titleContainer}>
              <div className={classes.loadingAddress} />
              <div className={classes.loadingAddress2} />
            </div>
          </>
        ) : (
          <>
            <div className={classes.photoContainer}>
              <img className={classes.photo} src={homePlaceholder} />
            </div>
            <div className={classes.titleContainer}>
              <div className={classes.address}>{address1}</div>
              <div className={classes.address2}>{address2}</div>
            </div>
            {!!onboardedProperties && !!onboardedProperties.length && (
              <div className={classes.changePicker} onClick={() => setShowPropertyPopup(true)}>
                <Text textVariant="link" size="s">
                  {LABELS.CHANGE_ADDRESS}
                </Text>
                <img src={accordian} className={classes.accordian} alt="accordian" />
              </div>
            )}
          </>
        )}
      </div>
      {showPropertyPopup && (
        <ClickAwayListener onClickAway={() => setShowPropertyPopup(false)}>
          <div className={classes.propertyMenu}>
            <div className={classes.menuItem}>My properties</div>
            <div className={classes.menuDivider} />
            {onboardedProperties &&
              onboardedProperties.map((property, index) => {
                const { unitNumber, streetNumber, streetName, state, suburb, postcode } =
                  property.property as Property;
                const address1 = `${unitNumber ? `${unitNumber}/` : ''}${
                  streetNumber || ''
                } ${streetName}, ${suburb}, ${state} ${postcode}`;

                return (
                  <div
                    key={index}
                    className={classNameGenerator([
                      classes.menuItemLight,
                      selectedProperty === index && classes.selectedMenuItem,
                    ])}
                    onClick={() => handlePropertySelect(index)}
                  >
                    {address1}
                  </div>
                );
              })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

const loading = loadingSelector([CheckoutActionTypes.GET_AVAILABLE_SERVICES]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  property: state.checkout.property,
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedProperty: (index: number) => dispatch(DashboardActions.setSelectedProperty(index)),
  setRequiredServiceTypes: (data: ServiceTypes[]) =>
    dispatch(CheckoutActions.setRequiredServiceTypes(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyBar);
