import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  CimetDatesRequest,
  CimetDatesResponse,
  CimetNeedsRequest,
  CimetNeedsResponse,
  CimetServicesRequest,
  CimetServicesResponse,
  CimetVisitRequest,
} from '../../models/cimet/Cimet';

export class CimetService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Cimet);

  // 1. Ask what services are available for the user
  public getCimetServices(
    payload: CimetServicesRequest,
  ): Promise<CimetServicesResponse> | CimetServicesResponse {
    return this.service.put<CimetServicesResponse>(
      {
        route: ['services'],
        query: { propertyId: payload.propertyId },
      },
      {},
    );
  }

  // 2. Call visit_id API
  public postCimetVisit(payload: CimetVisitRequest): Promise<void> | void {
    return this.service.post<void>(
      {
        route: ['create-visit'],
        query: { propertyId: payload.propertyId, serviceType: payload.serviceType.toUpperCase() },
      },
      {},
    );
  }

  // 3. Ask Rahul to make a URL to hit for CIMET
  public postCimetNeeds(
    payload: CimetNeedsRequest,
  ): Promise<CimetNeedsResponse> | CimetNeedsResponse {
    return this.service.post<CimetNeedsResponse>(
      {
        route: ['generate-url'],
        query: { propertyId: payload.propertyId, serviceType: payload.serviceType.toUpperCase() },
      },
      {
        ...payload.needs,
      },
    );
  }

  public getCimetDates(
    payload: CimetDatesRequest,
  ): Promise<CimetDatesResponse[]> | CimetDatesResponse[] {
    return this.service.get<CimetDatesResponse[]>({
      route: ['holiday-lists'],
      query: { propertyId: payload.propertyId, serviceType: payload.serviceType.toUpperCase() },
    });
  }
}

const cimetService = new CimetService();
export default cimetService;
