import ApiService from '../ApiService';
import { ServiceType } from '../ServiceType.data';
import { QueryPath } from '../QueryPath.data';
import { MultipartFormData } from '../MultipartFormData.data';
import {
  CreateMaintenanceRequestStartRequest,
  CreateMaintenanceRequestSuccessResponse,
  CreateMaintenanceRequestUpdateRequest,
} from '../../models/maintenance/maintenance';

export class MaintenanceService {
  public postMultipartImage(request: FormData) {
    const service = new ApiService(ServiceType.saveMaintenaceImage);
    const path: QueryPath = {
      route: [],
    };
    return service.postMultipart(path, request);
  }

  public postMaintenanceRequest(
    request: CreateMaintenanceRequestStartRequest,
  ): Promise<CreateMaintenanceRequestSuccessResponse> | CreateMaintenanceRequestSuccessResponse {
    const service = new ApiService(ServiceType.getMaintenanceSummary);
    const path: QueryPath = {
      route: [],
    };
    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        if (key === 'images') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          for (const photo of request[key]!) {
            data.push({
              name: key,
              content: photo,
            });
          }
        } else {
          data.push({
            name: key,
            content: request[key],
          });
        }
      }
    }
    return service.postMultipart(path, data);
  }
  public updateMaintenanceRequest(
    request: CreateMaintenanceRequestUpdateRequest,
  ): Promise<CreateMaintenanceRequestSuccessResponse> | CreateMaintenanceRequestSuccessResponse {
    const endpoint = ServiceType.getMaintenanceSummary;
    const path: QueryPath = {
      route: [`${request.id}`],
    };
    const service = new ApiService(endpoint);

    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        if (key === 'images' || key === 'petImages') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          for (const photo of request[key]!) {
            data.push({
              name: key,
              content: photo,
            });
          }
        } else {
          data.push({
            name: key,
            content: request[key],
          });
        }
      }
    }
    return service.putMultipart(path, data);
  }
}

const maintenanceService = new MaintenanceService();
export default maintenanceService;
