import bankIcon from '../../../assets/payment/account-balance-24-px.svg';
import cardIcon from '../../../assets/payment/credit-card-24-px.svg';
import selectedIcon from '../../../assets/payment/filled.svg';
import { GetPaymentTypeResponse } from '../../../models/payment/Payment';

export const LABELS = {
  NAV_TITLE: 'Add payment method',
  NAV_SUBTITLE:
    'Please add in your preferred payment method —  we’ll use these details for when your bills are due',
};

export interface MatchParams {
  propertyId: string;
  serviceType: string;
  paymentRefId: string;
  serviceAccountId: string;
  agencyName: string;
}

export const PAYMENT_TYPES = [
  {
    type: 'Bank',
    name: 'Bank Account',
    icon: bankIcon,
    selected: selectedIcon,
  },
  {
    type: 'Credit',
    name: 'Credit Card',
    icon: cardIcon,
    selected: selectedIcon,
  },
];

export enum DRAWERS {
  termsDrawer = 'termsDrawer',
  paymentTypeDisabledDrawer = 'paymentTypeDisabledDrawer',
}

export const defaultPaymentTypes: GetPaymentTypeResponse[] = [
  {
    paymentMethod: 'Bank',
    transactionFeeType: '',
    transactionFeeValue: 0,
    internationalFeeValue: 0,
  },
  {
    paymentMethod: 'Credit',
    transactionFeeType: '',
    transactionFeeValue: 1.0,
    internationalFeeValue: 2.9,
  },
];

export const serviceTypeList = {
  rent: 'rent',
  electricity: 'electricity',
  broadband: 'broadband',
  editrent: 'rent',
  editelectricity: 'electricity',
  editbroadband: 'broadband',
  wallet: 'wallet',
};

export const termsDrawerTitle = 'Accept terms';

export const paymentDisabledModal = {
  title: 'Payment type disabled',
  info: (agency: string, paymentMethod: string, serviceType: string) =>
    `Sorry, ${agency} doesn't accept ${paymentMethod} payments for ${serviceTypeList[serviceType]}. Please select another option.`,
};

export const termsDrawerSubTitle =
  'By continuing you acknowledge that you have read, understood and accept the Direct Debit Service Agreement available below.';

export const buttonText = {
  cancel: 'Cancel',
  iAccept: 'I accept',
};

export const commonTermsAndConditionText = (
  amount: string,
  serviceProvider: string,
  startDate?: string,
) =>
  `From this account you will be paying ${serviceProvider}, ${amount} ${
    startDate ? `starting ${startDate}` : 'after your service is active'
  }.`;

export const debitCardTermsAnadConditionsText =
  'By continuing you acknowledge that you have read, understood and accept the Direct Debit Service Agreement available below.';

export const downloadText = 'Direct debit service agreement';

export const AGREEMENT_URL =
  'https://sortedservices.com/s/Direct-Debit-Request-Service-Agreement.pdf';
