import * as Yup from 'yup';
import { ServiceTypes } from '../../models/services/services';
export const LABELS = {
  TITLE: 'Get your electricity, gas and broadband connected in minutes',
  SUBTITLE:
    'Easily compare plans from a range of providers - more plans equals more choice which means you have more ways to save! We have partnered with Cimet to give you access to easily compare and sign up to a plan that suits your needs!',
  SELECT: 'Select a service to get started',
  NO_SERVICES: 'Looks like there are no other services for your place',
  DOWNLOAD_APP:
    'Download the Sorted App and login to view your service status and connect to even more services in the Sorted Marketplace.',
  DOWNLOAD_NAX_APP:
    'Download the Nelson Alexander App and login to view your service status and connect to even more services in the Sorted Marketplace.',
  DOWNLOAD_SORTED: 'Download',
  BASIC_INFORMATION: 'Basic information',
  PROVIDERS: 'Providers we partner with',
  CONNECTED: 'Connected or unavailable',
  ARE_YOU_MOVING: 'Are you moving?',
  MOVING_DATE: 'Moving date',
  SOLAR: 'Do you have rooftop solar panels installed?',
  MOVING_INFO:
    'Please remember that the plans will be presented according on the connection date you select. Other offers may be available on various connection dates. Gas offers may not be available for a next-day or two-day connection.',
  NEXT: 'View plans',
  LIFE_SUPPORT:
    'Does anyone residing or intending to reside in the household require the use of medical equipment for life support, where their health may be impacted if there was an outage?',
  // prettier-ignore
  TERMS: (serviceType: ServiceTypes) =>
    `<div>I understand that I am being taken to Sorted’s Partner’s comparison site, and that my details will be used to enable connecting my services. I have read and understood CIMET's <a href="https://www.cimet.com.au/terms-and-conditions" target="_blank">Terms and Conditions</a> & <a href="https://www.cimet.com.au/privacy-policy" target="_blank">Privacy Policy</a>, and acknowledge that the recommended plans are from a range of providers on the <a href=${
      serviceType === ServiceTypes.Broadband
        ? "https://marketplace.sortedservices.com/broadband/approved-providers"
        : "https://marketplace.sortedservices.com/energy/approved-providers"
    } target="_blank">Approved Providers List</a>. I further acknowledge that I may be contacted to discuss any plan that I apply for.</div>`,
  LIFE_POPUP: `<ul><p>According to the National Energy Retail Rules and the Victorian Energy Retail Code, equipment that classifies as being life support dependent, are:</p>

  <ul>
    <li>An oxygen concentrator</li>
    <li>An intermittent peritoneal dialysis machine</li>
    <li>A kidney dialysis machine</li>
    <li>A chronic positive airways pressure respirator(CPAP)</li>
    <li>Crigler-Najjar syndrome phototherapy equipment</li>
    <li>A ventilator for life support</li>
    <li>In relation to a particular customer — Any other equipment (whether fuelled by electricity or gas) that a registered medical practitioner certifies is required for a person residing at the customer’s premises for life support or otherwise where the customer provides a current medical certificate certifying that a person residing at the customer’s premises has a medical condition which requires the continued supply of electricity or gas.</li>
  </ul>
  
  <p>‘Other equipment’ for the purpose of subclause (g) of the above definition of life support equipment may include, but is not limited to, the following:</p>
  
  <p>(i) external heart pumps&nbsp</p>
  
  <p>(ii) respirators (iron lung)</p>
  
  <p>(iii) suction pumps (respiratory or gastric)</p>
  
  <p>(iv) feeding pumps (kangaroo pump, or total parenteral nutrition)</p>
  
  <p>(v) insulin pumps</p>
  
  <p>(vi) airbed vibrator</p>
  
  <p>(vii) hot water</p>
  
  <p>(viii) nebulizer, humidifiers or vaporizers</p>
  
  <p>(ix) apnoea monitors</p>
  
  <p>(x) medically required heating and air conditioning</p>
  
  <p>(xi) medically required refrigeration</p>
  
  <p>(xii) powered wheelchair.</p></ul>`,
};

export const CIMET_SERVICES = ['Electricity', 'Gas', 'Broadband'];

export const ERRORS = {
  required: 'Required',
  requiredMoveInDate: 'Please specify a moving date',
};

export const validationSchema = Yup.object().shape({
  movingType: Yup.number().required(ERRORS.required).nullable(),
  movingDate: Yup.date().when('movingType', {
    is: (movingType: number) => movingType === 1,
    then: Yup.date().required(ERRORS.requiredMoveInDate).nullable(),
  }),
});

export const links = {
  appleStore:
    'https://apps.apple.com/app/apple-store/id1232960956?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreNAX:
    'https://apps.apple.com/app/apple-store/id1489135381?pt=118629506&ct=post-digital-signing&mt=8',
  playstore:
    'https://play.google.com/store/apps/details?id=com.sortedservices.sorted&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreNAX:
    'https://play.google.com/store/apps/details?id=au.com.nelsonalexander.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
};
