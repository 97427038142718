import { LandlordState } from '../state/LandlordState';
import { LandlordAction, LandlordActionTypes } from '../actions/LandlordActions';
import {
  LandlordServices,
  MaintenanceRequest,
  MaintenanceResponse,
  Property,
  PropertyApplication,
  PropertyRequest,
  PropertyResponse,
  TasksResponse,
} from '../../models/landlord/LandlordProperty';

export const initialState: LandlordState = {
  properties: [],
  searchProperties: [],
  applicationLoader: false,
  applications: [],
  propertiesLoader: false,
  tasks: {
    approveTenants: 0,
    approveMaintenanceRequests: 0,
    landlordAttending: 0,
    approveQuotes: 0,
  },
  maintenanceLoader: false,
  maintenanceRequests: [],
  maintenanceQuotes: [],
  maintenanceAttending: [],
  dashboardPropertiesLoader: false,
  dashboardProperties: [],
  totalProperties: 0,
  searchTotalProperties: 0,
  selectedMaintenance: null,
  maintenanceHistory: [],
  maintenanceHistoryLoader: false,
  refreshDashboard: false,
  refreshProperties: false,
  refreshMaintenance: false,
  refreshApplications: false,
  maintenanceRequestCount: 0,
  maintenanceQuoteCount: 0,
  maintenanceAttendingCount: 0,
  servicesLoader: false,
  landlordServices: {
    availableServices: [],
    services: [],
  },
};

export const landlordReducer = (state = initialState, action: LandlordAction): LandlordState => {
  switch (action.type) {
    case LandlordActionTypes.SELECTED_LANDLORD_PROPERTY:
      return {
        ...state,
        selectedLandlordProperty: action.data as Property,
      };
    case LandlordActionTypes.GET_PROPERTIES_REQUEST:
      return {
        ...state,
        propertiesLoader: !(action.data as PropertyRequest).dashboardProperties
          ? true
          : state.propertiesLoader,
        dashboardPropertiesLoader: (action.data as PropertyRequest).dashboardProperties
          ? true
          : state.dashboardPropertiesLoader,
      };
    case LandlordActionTypes.GET_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: (action.data as PropertyResponse).content,
        propertiesLoader: false,
        totalProperties: (action.data as PropertyResponse).totalElements,
        refreshProperties: false,
      };
    case LandlordActionTypes.GET_PROPERTIES_SEARCH_SUCCESS:
      return {
        ...state,
        searchProperties: (action.data as PropertyResponse).content,
        propertiesLoader: false,
        searchTotalProperties: (action.data as PropertyResponse).totalElements,
        refreshProperties: false,
      };
    case LandlordActionTypes.GET_PROPERTIES_ERROR:
      return {
        ...state,
        propertiesLoader: false,
      };
    case LandlordActionTypes.RESET_PROPERTY_SEARCH:
      return {
        ...state,
        searchProperties: [],
      };
    case LandlordActionTypes.GET_DASHBOARD_PROPERTIES_SUCCESS:
      return {
        ...state,
        dashboardProperties: (action.data as PropertyResponse).content,
        propertiesLoader: false,
        dashboardPropertiesLoader: false,
        totalProperties: (action.data as PropertyResponse).totalElements,
        refreshDashboard: false,
      };
    case LandlordActionTypes.GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        applicationLoader: true,
      };
    case LandlordActionTypes.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationLoader: false,
        applications: action.data as PropertyApplication[],
        refreshApplications: false,
      };
    case LandlordActionTypes.GET_APPLICATIONS_ERROR:
      return {
        ...state,
        applicationLoader: false,
      };
    case LandlordActionTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.data as TasksResponse,
        refreshDashboard: false,
      };
    case LandlordActionTypes.GET_MAINTENANCE_REQUEST:
      return {
        ...state,
        maintenanceLoader: !(action.data as MaintenanceRequest).isMaintenanceHistory,
        maintenanceHistoryLoader: !!(action.data as MaintenanceRequest).isMaintenanceHistory,
      };
    case LandlordActionTypes.GET_MAINTENANCE_REQUEST_SUCCESS:
      return {
        ...state,
        maintenanceLoader: false,
        maintenanceRequests: action.data as MaintenanceResponse[],
        maintenanceRequestCount:
          action.data && (action.data as MaintenanceResponse[]).length > 0
            ? (action.data as MaintenanceResponse[]).length
            : 0,
        refreshMaintenance: false,
      };
    case LandlordActionTypes.GET_MAINTENANCE_QUOTE_SUCCESS:
      return {
        ...state,
        maintenanceLoader: false,
        maintenanceQuotes: action.data as MaintenanceResponse[],
        maintenanceQuoteCount:
          action.data && (action.data as MaintenanceResponse[]).length > 0
            ? (action.data as MaintenanceResponse[]).length
            : 0,
        refreshMaintenance: false,
      };
    case LandlordActionTypes.GET_MAINTENANCE_ATTENDING_SUCCESS:
      return {
        ...state,
        maintenanceLoader: false,
        maintenanceAttending: action.data as MaintenanceResponse[],
        maintenanceAttendingCount:
          action.data && (action.data as MaintenanceResponse[]).length > 0
            ? (action.data as MaintenanceResponse[]).length
            : 0,
        refreshMaintenance: false,
      };
    case LandlordActionTypes.GET_MAINTENANCE_ERROR:
      return {
        ...state,
        maintenanceLoader: false,
        maintenanceHistoryLoader: false,
      };
    case LandlordActionTypes.SELECTED_LANDLORD_MAINTENANCE:
      return {
        ...state,
        selectedMaintenance: action.data as MaintenanceResponse,
      };
    case LandlordActionTypes.GET_MAINTENANCE_HISTORY_SUCCESS:
      return {
        ...state,
        maintenanceHistory: action.data as MaintenanceResponse[],
        maintenanceHistoryLoader: false,
      };
    case LandlordActionTypes.SET_LANDLORD_DASHBOARD_REFRESH:
      return {
        ...state,
        refreshDashboard: true,
      };
    case LandlordActionTypes.SET_LANDLORD_PROPERTIES_REFRESH:
      return {
        ...state,
        refreshProperties: true,
      };
    case LandlordActionTypes.SET_LANDLORD_MAINTENANCE_REFRESH:
      return {
        ...state,
        refreshMaintenance: true,
      };
    case LandlordActionTypes.SET_LANDLORD_APPLICATIONS_REFRESH:
      return {
        ...state,
        refreshApplications: true,
      };
    case LandlordActionTypes.UPDATE_MAINTENANCE_HISTORY:
      return {
        ...state,
        maintenanceHistory: action.data as MaintenanceResponse[],
      };
    case LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES:
      return {
        ...state,
        servicesLoader: true,
      };
    case LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES_SUCCESS:
      return {
        ...state,
        landlordServices: action.data as LandlordServices,
        servicesLoader: false,
      };
    case LandlordActionTypes.GET_LANDLORD_AVAILABLE_SERVICES_ERROR:
      return {
        ...state,
        servicesLoader: false,
      };
    default:
      return state;
  }
};
