import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helpers/GetFonts';
import { SIZES } from '../../constants/Sizes';
import {
  maxContentWidth,
  smallPageContainer,
  primaryContent,
  actionGreen,
} from '../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    content: {
      zIndex: 1,
      position: 'relative',
      width: '100%',
      ...maxContentWidth,
    },
    pageContainer: {
      ...smallPageContainer,
    },
    pageContent: {
      marginTop: theme.units.spacing * 12,
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${SIZES.lgMax}px)`]: {
        flexDirection: 'column',
      },
    },
    primaryContainer: {
      ...primaryContent,
      maxWidth: 1200,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        maxWidth: '100%',
      },
    },
    backRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    backArrow: {
      width: theme.units.iconSize * 0.9,
      height: theme.units.iconSize * 0.9,
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: theme.units.spacing * 6,
      ...actionGreen,
    },
    flexBox: {
      flex: 1,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 18,
      marginLeft: 16,
      marginBottom: 8,
    },
    header: {
      height: 180,
      width: '100%',
    },
    body: {
      padding: 8,
    },
    card: {
      border: `${theme.colors.borderColorLight}`,
      borderRadius: theme.units.borderRadius,
      backgroundColor: theme.colors.light,
      padding: 12,
      paddingBottom: 0,
    },
    cardItem: {
      borderRadius: 8,
      marginBottom: 12,
    },
    rentCard: {
      border: `${theme.colors.borderColorLight}`,
      borderRadius: theme.units.borderRadius,
      backgroundColor: theme.colors.light,
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 64,
      marginTop: -70,
      marginRight: 20,
    },
    rentCardVerticalView: {
      marginLeft: 20,
      alignSelf: 'center',
    },
    serviceIcon: {
      alignSelf: 'center',
      marginLeft: 12,
      height: 32,
      width: 32,
      borderRadius: 17,
    },
    itemLabel: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      letterSpacing: 0.88,
      color: theme.colors.black38,
    },
    itemValue: {
      fontWeight: getFonts('Medium'),
      fontSize: 16,
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 5,
    },
    headerText: {
      fontSize: 26,
      fontWeight: getFonts('Medium'),
      letterSpacing: 0.1,
      color: theme.colors.light,
    },
    documentListItemValue: {
      fontSize: 16,
      letterSpacing: 0.1,
      color: theme.colors.black87,
      marginTop: 5,
      flex: 1,
    },
    itemButtonBox: {
      height: '100%',
    },
    itemButton: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: 14,
      letterSpacing: 0.4,
      color: '#2ca7c0',
    },
    tenancyItemBox: {
      flex: 1,
      height: 100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    downloadButton: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      height: 45,
      alignItems: 'center',
    },
    downloadImage: {
      marginRight: 16,
      height: 24,
      width: 24,
    },
    supplierHeader: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 8,
      justifyContent: 'flex-start',
    },
    serviceLogo: {
      alignSelf: 'center',
      marginLeft: 12,
      marginRight: 12,
      height: 32,
      width: 32,
      borderRadius: 16,
    },
  })();
};
