/* eslint-disable import/prefer-default-export */
export const LABELS = {
  MY_PROFILE: 'My Profile',
  PROFILE: 'Profile',
  ADDITIONAL_OCCUPANT_PROFILE_SUBTITLE: 'Dependent',
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  APPLYING_SEPARATELY: 'Applying Separately',
  DECLARATION_REQUIRED: 'Declaration required',
  SUBMITTING_SEPARATELY: 'Submitting their profile separately',
  MANAGED_PROFILE: 'Managed Profile',
  MANAGED_DEPENDENT: 'Managed Dependent',
  ADD_PROFILE: 'Add a profile you manage',
  EDIT: 'Edit',
  UPDATE: 'Edit',
  DELETE: 'Delete',
  VERIFIED: 'Background check',
  EXPIRED: 'Check expired',
  YOUR_APPLICATION_PROFILES: 'My Apply application profiles',
};
