/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
// LOWERCASE ONLY
export enum ServiceType {
  RefreshToken = 'refreshToken',
  offers = 'api/v1/sorted/dashboard',
  TradesAuth = 'trades/auth',
  booking = 'trades/api/private-works',
  property = 'api/trades',
  dashboard = 'bff/api/v1/sorted/dashboard',
  pendingTenancy = 'api/v1/sorted/rental-service',
  login = 'login',
  ForgotPassword = 'user/forgotpassword',
  profile = 'api/users',
  userRoles = 'api/users/roles',
  request = 'api/maintenance-request',
  cancel = 'trades/api/maintenance-request',
  packages = 'trades/api/private-works',
  transaction = 'api/v1/sorted',
  payment = 'bff/api/payment',
  rentServiceSummary = 'api/v1/sorted/summary',
  documentDownload = 'api/leases/download',
  invoices = 'api/v1/sorted/invoices',
  downloadInvoice = 'api/v1/sorted/invoice/download',
  serviceSummary = 'api/v1/sorted/service-account',
  genericServiceSummary = 'bff/api/v1/sorted/service-account',
  getMaintenanceSummary = 'trades/api/maintenance-request',
  getMaintenanceLocation = 'trades/v1/maintenance-request/meta-data',
  checkBill = 'v3/sorted/services/check-bill',
  RegisterAndLogin = 'standalone/users/registerAndLogin',
  activeServicesPaymentSummary = 'api/v1/sorted/service-account-payment-details',
  ApplyUserDetails = 'apply/api/apply/user',
  Cimet = 'api/cimet',
  token = 'guest/session',
  Apply = 'apply/api/apply/application',
  CoApplicant = 'apply/api/coApplicant/update',
  Group = 'apply/api/group',
  Accept = 'apply/api/bulk/accept',
  Decline = 'apply/api/bulk/decline',
  Withdraw = 'apply/api/withdraw',
  MasterProfile = 'apply/api/profile',
  wallet = 'bff/api/wallet',
  Payment = 'bff/api/payment',
  landlord = 'api/sorted/landlord',
  PasswordReset = 'api/resetPassword',
  review = 'trades/api/jobs/ratings',
  saveMaintenaceImage = 'api/maintenance/image',
  postMaintenanceRequest = 'bff/api/maintenance-request',
  cart = 'api/service-cart',
  FileUploadOrDelete = 'api/file/upload',
  propertyOnboard = 'api/properties/add-standalone-property',
}
