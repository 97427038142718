import moment from 'moment';
import {
  Lease,
  leaseStatus,
  Applicant,
  Application,
  PropertyLeaseData,
  LeaseStatus,
} from '../models/landlord/LandlordProperty';
import { API_DATE_FORMAT } from '../containers/home/components/activity/ActivityConstants';
import { ThemeKey, getCurrentThemeKey, getThemeKey } from '../theme/Theme';

export const useLandlordServices = () => {};

export const isCurrentLease = (lease: Lease): boolean => {
  const today = new Date(new Date().toDateString()); // as we only want to compare date part
  const startDateInPast = moment(lease.startDate as string, API_DATE_FORMAT).toDate() <= today;
  const endDateInFuture = moment(lease.endDate as string, API_DATE_FORMAT).toDate() >= today;
  const isNotRenewedOrCancelled =
    lease.status !== leaseStatus.renewed && lease.status !== leaseStatus.cancelled;
  const isChildLeaseCurrentTenancy =
    lease.status === leaseStatus.renewed &&
    !!lease.childLeaseStartDate &&
    moment(lease.childLeaseStartDate, API_DATE_FORMAT).toDate() > today;

  return (
    lease?.leased &&
    !!lease.startDate &&
    startDateInPast &&
    endDateInFuture &&
    (isNotRenewedOrCancelled || isChildLeaseCurrentTenancy)
  );
};

export const getPropertyLeaseStatus = ({
  current,
  daysLeft,
  signed,
  pending,
  advertised,
}: PropertyLeaseData) => {
  if (current && daysLeft >= 0) {
    return LeaseStatus.Leased;
  } else if (current && daysLeft < 0) {
    return LeaseStatus.Periodic;
  } else if (signed || pending || advertised) {
    return signed ? LeaseStatus.Signed : LeaseStatus.Pending;
  } else {
    return 'No lease';
  }
};

export const getApplicantDetails = (applicant: Applicant) => {
  const age = applicant.dob ? moment().diff(moment(applicant.dob, 'DD/MM/YYYY'), 'years') : 0;
  const identityVerified =
    applicant.applicantIdentificationList && applicant.applicantIdentificationList.length > 0
      ? applicant.applicantIdentificationList.findIndex((identity) => !identity.verified) === -1
      : false;
  const currentAddress =
    applicant.applicantAddressHistoryList && applicant.applicantAddressHistoryList.length > 0
      ? applicant.applicantAddressHistoryList.find((address) => address.current)
      : null;
  let rentalAmount = 0;
  let rentalLength = '';
  if (currentAddress) {
    rentalAmount = currentAddress.monthlyRent || 0;
    const rentalYears = currentAddress.years ? `${currentAddress.years} year(s)` : '';
    const rentalMonths = currentAddress.months ? `${currentAddress.months} month(s)` : '';
    rentalLength = rentalYears + rentalMonths;
  }
  const incomeType =
    applicant && applicant.applicantIncome && applicant.applicantIncome.length > 0
      ? applicant.applicantIncome[0].source
      : '';
  const employment =
    applicant &&
    applicant.applicantEmploymentHistoryList &&
    applicant.applicantEmploymentHistoryList.length > 0
      ? applicant.applicantEmploymentHistoryList[0]
      : null;
  let employmentDuration = 0;
  if (employment) {
    employmentDuration =
      employment.endDate && employment.startDate
        ? moment(employment.endDate, 'DD/MM/YYYY').diff(
            moment(employment.startDate, 'DD/MM/YYYY'),
            'months',
          )
        : employment.startDate
        ? moment().diff(moment(employment.startDate, 'DD/MM/YYYY'), 'months')
        : 0;
  }

  return {
    age,
    identityVerified,
    rentalAmount: rentalAmount ? `$${rentalAmount} per month` : 'N/A',
    rentalLength: rentalLength || 'N/A',
    incomeType: incomeType || 'N/A',
    employmentDuration: employmentDuration ? `${employmentDuration} month(s)` : 'N/A',
  };
};

export const getPetsLabel = (application: Application) => {
  let dogCount = 0;
  let catCount = 0;
  let otherCount = 0;
  if (application.noOfPets === 0) {
    return 'Pets';
  } else {
    application.applicants &&
      application.applicants.length > 0 &&
      application.applicants.map((applicant) => {
        applicant.pets &&
          applicant.pets.length > 0 &&
          applicant.pets.map((pet) => {
            if (pet.type.toLowerCase() === 'dog') {
              dogCount += pet.count;
            } else if (pet.type.toLowerCase() === 'cat') {
              catCount += pet.count;
            } else {
              otherCount += pet.count;
            }
          });
      });
    const dogLabel = dogCount > 0 ? `${dogCount} ${dogCount > 1 ? 'Dogs' : 'Dog'}` : '';
    const catLabel = catCount > 0 ? `${catCount} ${catCount > 1 ? 'Cats' : 'Cat'}` : '';
    const otherLabel = otherCount > 0 ? `${otherCount} ${otherCount > 1 ? 'Others' : 'Other'}` : '';
    if (dogLabel || catLabel || otherLabel) {
      return (
        dogLabel +
        (catLabel ? `${dogCount > 0 ? ', ' : ''}${catLabel}` : '') +
        (otherLabel ? `${dogCount > 0 || catCount > 0 ? ', ' : ''}${otherLabel}` : '')
      );
    } else {
      return 'Pets';
    }
  }
};

export const getCostLabel = (cost: number) => {
  return cost ? cost.toFixed(2) : '0.00';
};

export const getAgencyCode = () => {
  const themeKey = getThemeKey();
  switch (themeKey) {
    case ThemeKey.NAX:
      return 'NAX';
    default:
      return null;
  }
};
