import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './GenericServiceSummaryStyles';
import { LABELS } from './GenericServiceSummaryConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  GenericServiceSummaryDictionary,
  GenericServiceSummaryRequest,
  GenericSection as GenericSectionType,
  SectionTitle,
} from '../../models/services/genericServiceSummary';
import { ServiceActions } from '../../store/actions/ServiceActions';
import { GenericServiceSummaryScreenParams } from '../../navigation/NavigationConstants';
import ActivityIndicator from '../../components/activityIndicator/ActivityIndicator';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { ServiceTypes } from '../../models/services/services';
import Invoices from './components/invoices/Invoices';
import AccountDetails from './components/accountDetails/AccountDetails';
import GenericSection from './components/genericSection/GenericSection';
import PaymentDetails from './components/paymentDetails/PaymentDetails';
import SupplierDetails from './components/supplierDetails/SupplierDetails';
import YourCurrentPlan from './components/yourCurrentPlan/YourCurrentPlan';

interface GenericServiceSummaryProps {
  genericServiceSummary: GenericServiceSummaryDictionary | null;
  getGenericServiceSummary: (params: GenericServiceSummaryRequest) => void;
}

const GenericServiceSummary: FC<GenericServiceSummaryProps> = ({
  genericServiceSummary,
  getGenericServiceSummary,
}) => {
  const classes = useStyles();
  const { serviceAccountId, propertyId } = extractParams<GenericServiceSummaryScreenParams>();

  useEffect(() => {
    getGenericServiceSummary({ serviceAccountId });
  }, []);

  const renderSection = (section: GenericSectionType) => {
    switch (section.sectionTitle.toUpperCase()) {
      case SectionTitle.yourCurrentPlan:
        return (
          <YourCurrentPlan
            isChartShown={false}
            data={section.data}
            logo={genericServiceSummary![serviceAccountId].logoUrl}
            servicePageDetails={genericServiceSummary![serviceAccountId].servicePageDetails}
            serviceDetails={genericServiceSummary![serviceAccountId].serviceDetails}
          />
        );
      case SectionTitle.accountDetails:
        return <AccountDetails data={section.data} />;
      case SectionTitle.supplierDetails:
        return (
          <SupplierDetails
            data={section.data}
            logo={genericServiceSummary![serviceAccountId].logoUrl}
            serviceProvider={genericServiceSummary![serviceAccountId].serviceProvider}
          />
        );
      case SectionTitle.paymentDetails:
        return (
          <PaymentDetails
            data={section.data}
            paymentRefId={
              genericServiceSummary![serviceAccountId].extendedResponse.paymentRefId ||
              'random-value'
            }
            serviceAccountId={serviceAccountId}
            propertyId={propertyId}
            serviceType={genericServiceSummary![serviceAccountId].serviceType}
          />
        );
      default:
        return <GenericSection data={section.data} />;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            {genericServiceSummary && !!genericServiceSummary[serviceAccountId] ? (
              <>
                <div className={classes.imageHeader}>
                  <img
                    src={genericServiceSummary[serviceAccountId].backgroundImageUrl as string}
                    className={classes.image}
                  />
                  <div className={classes.serviceTitle}>
                    {genericServiceSummary[serviceAccountId].serviceType}
                  </div>
                  <img
                    src={genericServiceSummary[serviceAccountId].logoUrl}
                    className={classes.logoStyle}
                  />
                </div>
                {genericServiceSummary[serviceAccountId].sections.map(
                  (section, index) =>
                    section.data && (
                      <div className={classes.sectionContainer}>
                        <div className={classes.sectionTitle}>{section.sectionTitle}</div>
                        <div key={index} className={classes.section}>
                          {renderSection(section)}
                        </div>
                      </div>
                    ),
                )}
                {genericServiceSummary[serviceAccountId].links && (
                  <div>
                    <div className={classes.sectionTitle}>Important Links</div>
                    <div className={classNameGenerator([classes.card2, classes.noTopPadding])}>
                      {genericServiceSummary[serviceAccountId].links!.map((link) => (
                        <div
                          className={classes.linkStyle}
                          onClick={() => {
                            window.open(link.link, '_blank');
                          }}
                        >
                          {link.label}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <Invoices
                  serviceAccountId={serviceAccountId}
                  serviceType={
                    genericServiceSummary[serviceAccountId].serviceType === ServiceTypes.Electricity
                      ? ServiceTypes.Electricity
                      : ServiceTypes.Broadband
                  }
                />
              </>
            ) : (
              <div className={classes.activityLoadingContainer}>
                <ActivityIndicator size="large" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  genericServiceSummary: state.services.genericServiceSummary,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getGenericServiceSummary: (params: GenericServiceSummaryRequest) =>
    dispatch(ServiceActions.getGenericServiceSummaryRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericServiceSummary);
