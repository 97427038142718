import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './OfferScreenStyles';
import { LABELS } from './OfferScreenConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { OfferScreenParams } from '../../navigation/NavigationConstants';
import { ApplicationState } from '../../store/RootReducer';
import { DashboardState } from '../../store/state/DashboardState';
import { Dispatch } from 'redux';
import externalLink from '../../assets/external-link.png';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';

interface OfferScreenProps {
  dashboardState: DashboardState;
}

const OfferScreen: FC<OfferScreenProps> = ({ dashboardState: { offers } }) => {
  const classes = useStyles();
  const { id } = extractParams<OfferScreenParams>();
  const selectedOffer = offers![id];

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.imageBannerContainer}>
              <img src={selectedOffer.bannerImage} className={classes.banner} />
              <img src={selectedOffer.icon} className={classes.icon} />
            </div>
            <Box lightBorder parentStyles={classes.contentContainer}>
              <div className={classes.offer}>Offers</div>
              <div className={classes.title}>{selectedOffer.title}</div>
              <div className={classes.subtitle}>{selectedOffer.content.offerSummary}</div>
              <div className={classes.description}>{selectedOffer.content.description}</div>
              <div className={classes.stepsTitle}>{selectedOffer.content.stepsTitle}</div>
              {selectedOffer.content.steps.map((step, idx) => (
                <div key={idx} className={classes.step}>
                  {`${idx + 1}. ${step}`}
                </div>
              ))}
              <div className={classes.links}>
                {selectedOffer.content.links.map((link, idx) => (
                  <div
                    key={idx}
                    className={classes.link}
                    onClick={() => window.open(link.link, '_blank')}
                  >
                    <div className={classes.linkText}>{link.text}</div>
                    <img src={externalLink} className={classes.linkIcon} />
                  </div>
                ))}
              </div>
              <div className={classes.actionContainer}>
                <Button
                  muted
                  parentStyles={classes.actionButton}
                  onPress={() => window.open(selectedOffer.content.action.link, '_blank')}
                >
                  {selectedOffer.content.action.text}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OfferScreen);
