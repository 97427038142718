import { SetOnboardingPropertyDetails } from '../../models/property/property';
import { PropertyAction, PropertyActionTypes } from '../actions/PropertyOnboardingActions';
import { PropertyOnboardingState } from '../state/PropertyOnboardingState';

export const initialState: PropertyOnboardingState = {
  address: {
    state: '',
    country: '',
    postcode: '',
    streetName: '',
    streetNumber: '',
    streetType: '',
    unitNumber: '',
    suburb: '',
  },
  selectedType: undefined,
  selectedClass: undefined,
};

export const propertyOnboardingReducer = (state = initialState, action: PropertyAction) => {
  switch (action.type) {
    case PropertyActionTypes.POST_PROPERTY_SUCCESS:
      return {
        ...state,
      };
    case PropertyActionTypes.SET_PROPERTY_DETAILS:
      const { field, value } = action.data as SetOnboardingPropertyDetails;
      return {
        ...state,
        [field]: value,
      };
    default:
      return state;
  }
};
