/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { TypeHelper } from './TypeHelper';
import { API_DATE_FORMAT } from '../containers/home/components/activity/ActivityConstants';

export interface DateDifferenceData {
  format: 'weeks' | 'years';
  startDate: string;
  endDate: string;
}

export class CalculationHelper {
  public static isPastDate(value: any) {
    const currentDay = moment();
    const date = moment(value, 'DD/MM/YY');
    return date.diff(currentDay, 'days') < 0;
  }
  public static isAbove18(value: any) {
    return moment().diff(moment(value), 'years', true) > 18;
  }

  public static checkPhoneNumberValidity = (getPhoneDigit: object, isValidNumber: object) => {
    for (const key in isValidNumber) {
      if (
        !TypeHelper.isNullOrUndefined(getPhoneDigit[key]) &&
        getPhoneDigit[key] !== '' &&
        !isValidNumber[key]
      ) {
        return false;
      }
    }
    return true;
  };

  public static getDurationString = ({ format, startDate, endDate }: DateDifferenceData) => {
    const startMoment = moment(startDate, API_DATE_FORMAT);
    const endMoment = moment(endDate, API_DATE_FORMAT).add(1, 'day');
    const diffDuration = endMoment.diff(startMoment, 'days');

    const years = endMoment.diff(startMoment, 'years');
    const months = endMoment.diff(startMoment, 'months') - 12 * years;

    const remainingDays = endMoment.diff(startMoment.add({ years, months }), 'days');
    switch (format) {
      case 'weeks':
        return `${Math.trunc(diffDuration / 7)} week(s) ${diffDuration % 7} day(s)`;

      case 'years':
        return `${years} Year(s) ${months % 12} Month(s) ${remainingDays} Day(s)`;

      default:
        return null;
    }
  };
}
