/* eslint-disable max-lines */
import { get, isNull } from 'lodash';
import {
  PostPersonalDetailsFormData,
  CurrentIncome,
  PostEmploymentFormData,
  PostPetsFormData,
  Address,
  ApplicantProfile,
  PostDeclarationFormData,
  SourceOfIncome,
  LivingArrangement,
  PetTypes,
  PostIdentificationFormData,
  PostVehiclesFormData,
  PostReferencesFormData,
  Identification,
  IntroFormData,
  hasIncomeArray,
} from '../models/masterProfile/MyProfileFormState';
import { MIN_ID_POINTS } from '../constants/Strings';
import { DependentProfile } from '../models/masterProfile/MasterProfile';
import { CalculationHelper } from './CalculationHelper';
import { TypeHelper } from './TypeHelper';
import { Percentage, getPercentage } from './PercentageHelper';
import { dollarFormatter } from './CurrencyHelper';

export enum SectionStatus {
  COMPLETE,
  INCOMPLETE,
  OPTIONAL,
}

// PERSONAL DETAILS ------------------------------------------------------------------------- //
/* tslint:disable:cyclomatic-complexity */
export function isPersonalDetailsFilled(
  details: PostPersonalDetailsFormData | undefined,
): SectionStatus {
  if (
    details &&
    details.title &&
    details.email &&
    details.firstName &&
    details.lastName &&
    details.mobile &&
    details.selectedDateOfBirth &&
    details.emergencyContactName &&
    details.emergencyContactMobile &&
    details.emergencyContactEmail &&
    details.emergencyContactRelationship
  ) {
    if (details.hasGuarantor) {
      if (details.guarantorContactNumber && details.guarantorEmail && details.guarantorName) {
        return SectionStatus.COMPLETE;
      }
      return SectionStatus.INCOMPLETE;
    }
    return SectionStatus.COMPLETE;
  } else if (
    details &&
    details.title &&
    details.firstName &&
    details.lastName &&
    details.selectedDateOfBirth &&
    !CalculationHelper.isAbove18(details.selectedDateOfBirth)
  ) {
    return SectionStatus.COMPLETE;
  }
  return SectionStatus.INCOMPLETE;
}

export function personalDetailsPercentage(
  details: PostPersonalDetailsFormData | undefined,
  isPrimary?: boolean,
): Percentage {
  let outOf = 4;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (
    (details.selectedDateOfBirth && CalculationHelper.isAbove18(details.selectedDateOfBirth)) ||
    isPrimary
  ) {
    outOf = 10;
  }
  if (details.title) completed++;
  if (details.email) completed++;
  if (details.firstName) completed++;
  if (details.lastName) completed++;
  if (details.selectedDateOfBirth) completed++;
  if (details.mobile) completed++;
  if (details.emergencyContactName) completed++;
  if (details.emergencyContactMobile) completed++;
  if (details.emergencyContactEmail) completed++;
  if (details.emergencyContactRelationship) completed++;

  if (details.hasGuarantor) {
    outOf = 13;
    if (details.guarantorContactNumber) completed++;
    if (details.guarantorEmail) completed++;
    if (details.guarantorName) completed++;
  }

  return { outOf, completed };
}

export function personalDetailsSummary(details: PostPersonalDetailsFormData | undefined): string {
  if (!details || !(details.firstName && details.lastName)) return 'Nameless';
  return `${details.firstName || ''} ${details.lastName || ''}`;
}

// INTRODUCTION ------------------------------------------------------------------------- //
export function isIntroFilled(details: IntroFormData | undefined): SectionStatus {
  if (details && details.introText) {
    return SectionStatus.COMPLETE;
  }
  return SectionStatus.OPTIONAL;
}

export function introPercentage(details: IntroFormData | undefined): Percentage {
  let outOf = 1;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details.introText) completed++;

  return { outOf, completed };
}

export function introSummary(details: IntroFormData | undefined): string {
  if (!(details && details.introText)) return 'A little about me...';
  return `${details.introText.substring(0, 20)}...`;
}

// CURRENT INCOME ------------------------------------------------------------------------- //
export function isCurrentIncomeDetailsFilled(details: CurrentIncome[] | undefined): SectionStatus {
  let flag = SectionStatus.INCOMPLETE;
  if (details) {
    for (const detail of details) {
      if (detail && detail.source && (detail.netWeeklyAmount || detail.savingsAmount)) {
        // if source-income = full_time/part_time/casual_work, then check payslip & bankStatement
        if (
          detail.source === SourceOfIncome.I_AM_WORKING_FULL_TIME ||
          detail.source === SourceOfIncome.I_AM_WORKING_PART_TIME ||
          detail.source === SourceOfIncome.I_HAVE_CASUAL_WORK
        ) {
          flag =
            detail.payslipData.length > 0 && !!detail.netWeeklyAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
          if (flag === SectionStatus.INCOMPLETE) break;
        }
        // if source-income = benefit, then check proofOfBenefit & bankStatement
        else if (detail.source === SourceOfIncome.I_RECEIVE_A_BENEFIT) {
          flag =
            detail.proofOfBenefitData.length > 0 &&
            !!detail.netWeeklyAmount &&
            !!detail.centrePayReference
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
          if (flag === SectionStatus.INCOMPLETE) break;
        }
        // if source-income = others, then check proofOfIncome & bankStatement
        else if (detail.source === SourceOfIncome.OTHER) {
          flag =
            detail.proofOfIncomeData.length > 0 && !!detail.netWeeklyAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
          if (flag === SectionStatus.INCOMPLETE) break;
        }
        // if source-income = self employed, then check bankStatement
        else if (detail.source === SourceOfIncome.SELF_EMPLOYED) {
          flag =
            detail.bankStatementData.length > 0 && !!detail.netWeeklyAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
          if (flag === SectionStatus.INCOMPLETE) break;
        }
        // if source-income = savings, then check bankStatement
        else if (detail.source === SourceOfIncome.I_RECEIVE_SAVINGS) {
          flag =
            detail.bankStatementData.length > 0 && !!detail.savingsAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
          if (flag === SectionStatus.INCOMPLETE) break;
        }
        // All details are filled, source is not "I receive money from my family" and makeGuarantor is false.
        else if (
          detail.source === SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY &&
          !detail.makeGuarantor
        ) {
          // All details are filled, source is "I receive money from my family" and makeGuarantor is false.
          flag =
            detail.bankStatementData.length > 0 && !!detail.netWeeklyAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
        } else if (detail.guarantorEmail) {
          // All details are filled, source is "I receive money from my family" and makeGuarantor is true.
          flag =
            detail.bankStatementData.length > 0 && !!detail.netWeeklyAmount
              ? SectionStatus.COMPLETE
              : SectionStatus.INCOMPLETE;
        } else {
          // All details are not filled, source is "I receive money from my family" and makeGuarantor is true.
          flag = SectionStatus.INCOMPLETE;
          break;
        }
      } else {
        // All details are not filled.
        flag = SectionStatus.INCOMPLETE;
        break;
      }
    }
  }
  return flag;
}

export function currentIncomePercentage(details: CurrentIncome[] | undefined): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  for (const detail of details) {
    outOf += 3;
    if (detail && detail.source && (detail.netWeeklyAmount || detail.savingsAmount)) {
      completed++;

      if (
        detail.source === SourceOfIncome.I_AM_WORKING_FULL_TIME ||
        detail.source === SourceOfIncome.I_AM_WORKING_PART_TIME ||
        detail.source === SourceOfIncome.I_HAVE_CASUAL_WORK
      ) {
        if (detail.payslipData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
        if (!(detail.payslipData.length > 0 && !!detail.netWeeklyAmount)) break;
      } else if (detail.source === SourceOfIncome.I_RECEIVE_A_BENEFIT) {
        outOf += 1;
        if (detail.proofOfBenefitData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
        if (detail.centrePayReference) completed++;
        if (!(detail.proofOfBenefitData.length > 0 && !!detail.netWeeklyAmount)) break;
      } else if (detail.source === SourceOfIncome.OTHER) {
        if (detail.proofOfIncomeData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
        if (!(detail.proofOfIncomeData.length > 0 && !!detail.netWeeklyAmount)) break;
      } else if (detail.source === SourceOfIncome.SELF_EMPLOYED) {
        if (detail.bankStatementData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
        if (!(detail.bankStatementData.length > 0 && !!detail.netWeeklyAmount)) break;
      } else if (detail.source === SourceOfIncome.I_RECEIVE_SAVINGS) {
        if (detail.bankStatementData.length > 0) completed++;
        if (detail.savingsAmount) completed++;
        if (!(detail.bankStatementData.length > 0 && !!detail.savingsAmount)) break;
      } else if (
        detail.source === SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY &&
        !detail.makeGuarantor
      ) {
        outOf += 1;
        completed++;

        if (detail.bankStatementData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
      } else if (detail.guarantorEmail) {
        outOf += 1;
        completed++;

        if (detail.bankStatementData.length > 0) completed++;
        if (detail.netWeeklyAmount) completed++;
      }
    }
  }

  return { outOf, completed };
}

export function currentIncomeSummary(details: CurrentIncome[] | undefined): string {
  if (!details) return 'No financial details recorded';

  let netweeklyAmount = 0;
  let savings = 0;
  for (const detail of details) {
    if (detail.source === SourceOfIncome.I_RECEIVE_SAVINGS) {
      if (detail.savingsAmount) {
        savings += parseInt(detail.savingsAmount);
      }
    } else if (detail.netWeeklyAmount) {
      netweeklyAmount += parseInt(detail.netWeeklyAmount);
    }
  }
  let result = '';
  result += `${dollarFormatter(52 * netweeklyAmount)} per annum`;
  result += ` and ${dollarFormatter(savings)} in savings`;
  return result;
}

// EMPLOYMENT ------------------------------------------------------------------------- //
export function isEmploymentDetailsFilled(
  details: PostEmploymentFormData | undefined,
  incomeDetails: CurrentIncome[] | undefined,
): SectionStatus {
  let flag = SectionStatus.INCOMPLETE;
  if (details && details.hasBeenEmployed) {
    if (details && details.employments) {
      for (const detail of details.employments) {
        // address verification
        if (detail && (isNull(detail.address) || detail.address.trim() === '')) {
          flag = SectionStatus.INCOMPLETE;
          break;
        }

        if (detail && detail.inJob) {
          if (
            detail.managerName &&
            detail.email &&
            detail.companyName &&
            detail.position &&
            detail.grossAnnualSalary &&
            detail.jobStartMonthAndYear
          ) {
            // In job, details are filled.
            flag = SectionStatus.COMPLETE;
          } else {
            // In job, details are not filled.
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        } else if (detail && !detail.inJob) {
          if (
            detail.managerName &&
            detail.email &&
            detail.companyName &&
            detail.position &&
            detail.grossAnnualSalary &&
            detail.jobStartMonthAndYear &&
            detail.jobEndMonthAndYear
          ) {
            // Not in job, details are filled.
            flag = SectionStatus.COMPLETE;
          } else {
            // Not in job, details are not filled.
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        }
      }
    }
  } else if (details && details.hasBeenEmployed !== null && !details.hasBeenEmployed) {
    flag = SectionStatus.COMPLETE;
    if (incomeDetails) {
      for (const income of incomeDetails) {
        if (income && income.source) {
          if (hasIncomeArray.includes(income.source as SourceOfIncome)) {
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        }
      }
    }
  }

  return flag;
}

export function employmentPercentage(
  details: PostEmploymentFormData | undefined,
  incomeDetails: CurrentIncome[] | undefined,
): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details && details.hasBeenEmployed) {
    for (const detail of details.employments) {
      outOf += 8;
      if (!(isNull(detail.address) || detail.address.trim() === '')) completed++;
      if (detail.managerName) completed++;
      if (detail.managerContactNumber) completed++;
      if (detail.email) completed++;
      if (detail.companyName) completed++;
      if (detail.position) completed++;
      if (detail.grossAnnualSalary) completed++;
      if (detail.jobStartMonthAndYear) completed++;
      if (detail && detail.inJob) {
        outOf++;
        completed++;
      }
    }
  } else if (details && details.hasBeenEmployed !== null && !details.hasBeenEmployed) {
    outOf = 1;
    completed = 1;

    if (incomeDetails) {
      for (const income of incomeDetails) {
        if (income && income.source) {
          if (hasIncomeArray.includes(income.source as SourceOfIncome)) {
            outOf = 0;
            completed = 0;
            break;
          }
        }
      }
    }
  }

  return { outOf, completed };
}

export function employmentSummary(details: PostEmploymentFormData | undefined): string {
  if (!(details && details.hasBeenEmployed)) return 'None';
  let currentWorkplace = 'None';
  for (const detail of details.employments) {
    if (detail.inJob && detail.companyName) {
      currentWorkplace = `Currently at ${detail.companyName}`;
      break;
    }
  }

  return currentWorkplace;
}

// VEHICLES ------------------------------------------------------------------------- //
export function isVehiclesDetailsFilled(details: PostVehiclesFormData | undefined): SectionStatus {
  let flag = SectionStatus.OPTIONAL;
  if (details && details.hasVehicles) {
    if (details && details.vehicles.length > 0) {
      if (details.vehicles) {
        for (const detail of details.vehicles) {
          if (
            detail &&
            detail.type &&
            detail.year &&
            detail.make &&
            detail.model &&
            detail.state &&
            detail.registrationNumber
          ) {
            flag = SectionStatus.COMPLETE;
          } else {
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        }
      }
    }
  } else if (details && details.hasVehicles !== null && !details.hasVehicles) {
    flag = SectionStatus.COMPLETE;
  }
  return flag;
}

export function vehiclesPercentage(details: PostVehiclesFormData | undefined): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details.hasVehicles) {
    for (const detail of details.vehicles) {
      outOf += 6;
      if (detail.type) completed++;
      if (detail.year) completed++;
      if (detail.make) completed++;
      if (detail.model) completed++;
      if (detail.state) completed++;
      if (detail.registrationNumber) completed++;
    }
  } else if (details.hasVehicles !== null && !details.hasVehicles) {
    outOf = 1;
    completed = 1;
  }

  return { outOf, completed };
}

export function vehiclesSummary(details: PostVehiclesFormData | undefined): string {
  if (!(details && details.hasVehicles)) return 'None';
  let result = 0;
  for (const detail of details.vehicles) {
    result++;
  }

  return `${result} vehicles`;
}

// PETS ------------------------------------------------------------------------- //
export function isPetsDetailsFilled(details: PostPetsFormData | undefined): SectionStatus {
  let flag = SectionStatus.OPTIONAL;
  if (details && details.hasPets) {
    if (details && details.pets) {
      for (const detail of details.pets) {
        if (
          detail &&
          detail.type &&
          detail.type !== PetTypes.Other &&
          detail.breed &&
          (detail.desexed || !detail.desexed) &&
          detail.size
        ) {
          // All details are filled and PetType is not Other.
          flag = SectionStatus.COMPLETE;
        } else {
          // All details are filled, PetType is Other and the other type is also filled.
          if (detail.specifyOtherType) {
            flag = SectionStatus.COMPLETE;
          } else {
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        }
      }
    }
  } else if (details && details.hasPets !== null && !details.hasPets) {
    flag = SectionStatus.COMPLETE;
  }
  return flag;
}

export function petsPercentage(details: PostPetsFormData | undefined): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details.hasPets) {
    for (const detail of details.pets) {
      outOf += 4;
      if (detail.type) completed++;
      if (detail.breed) completed++;
      if (detail.desexed || !detail.desexed) completed++;
      if (detail.size) completed++;
      if (detail.type === PetTypes.Other) {
        outOf += 1;
        if (detail.specifyOtherType) completed++;
      }
    }
  } else if (details.hasPets !== null && !details.hasPets) {
    outOf = 1;
    completed = 1;
  }

  return { outOf, completed };
}

export function petsSummary(details: PostPetsFormData | undefined): string {
  if (!(details && details.hasPets)) return 'None';
  let result = 0;
  for (const detail of details.pets) {
    result++;
  }

  return `${result} pets`;
}

function onLivingArrangementChange(detail: Address) {
  // Returns true if livingArrangement is "I'm the owner" and agencyName, propertyManagerName, monthlyRent and contactNumber fields are empty.
  // Returns true is livingArrangement is not "I'm the owner" and agencyName, propertyManagerName, monthlyRent and contactNumber fields are filled.
  return detail.livingArrangement === LivingArrangement.OWNER ||
    detail.livingArrangement === LivingArrangement.PARENTS ||
    detail.livingArrangement === LivingArrangement.SHARING ||
    detail.livingArrangement === LivingArrangement.OTHER
    ? !!(detail.propertyManagerName === '') &&
        !TypeHelper.isNullOrUndefined(detail.durationOfStayYear) &&
        !TypeHelper.isNullOrUndefined(detail.durationOfStayMonth)
    : !!detail.propertyManagerName &&
        !TypeHelper.isNullOrUndefined(detail.durationOfStayYear) &&
        !TypeHelper.isNullOrUndefined(detail.durationOfStayMonth) &&
        detail.email &&
        detail.monthlyRent;
}

// ADDRESS ------------------------------------------------------------------------- //
export const isAddressComponentsFilled = (details: Address): boolean => {
  if (!details.addressComponents) return false;
  const { addressComponents } = details;
  if (
    addressComponents.streetNumber &&
    addressComponents.streetName &&
    addressComponents.suburb &&
    addressComponents.state &&
    addressComponents.postcode &&
    addressComponents.country
  )
    return true;
  return false;
};

export function isAddressDetailsFilled(details: Address[]): SectionStatus {
  let flag = SectionStatus.INCOMPLETE;
  if (details) {
    for (const detail of details) {
      if (detail && detail.livingArrangement === LivingArrangement.OWNER) {
        flag =
          detail.additionalAddressDocuments.length > 0
            ? SectionStatus.COMPLETE
            : SectionStatus.INCOMPLETE;
        if (flag === SectionStatus.INCOMPLETE) break;
      }
      if (detail && detail.isCurrent) {
        if (detail.postalAddressDifferentFromCurrentAddress) {
          if (detail.livingArrangement && detail.address) {
            const livingArrangementFlag = onLivingArrangementChange(detail);
            if (livingArrangementFlag) {
              // Current address, postal address is different from current address, details are filled.
              flag = SectionStatus.COMPLETE;
            } else {
              // Current address, postal address is different from current address, living arrangement details are not filled.
              flag = SectionStatus.INCOMPLETE;
              break;
            }
          } else {
            // Current address, postal address is different from current address, some details are not filled.
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        } else if (!detail.postalAddressDifferentFromCurrentAddress) {
          if (detail.livingArrangement && detail.address && isAddressComponentsFilled(detail)) {
            const livingArrangementFlag = onLivingArrangementChange(detail);
            if (livingArrangementFlag) {
              // Current address, postal address is same as current address, details are filled.
              flag = SectionStatus.COMPLETE;
            } else {
              // Current address, postal address is same as current address, living arrangement details are not filled.
              flag = SectionStatus.INCOMPLETE;
              break;
            }
            // Current address, postal address is same as current address, details are not filled."
          } else {
            // Current address, postal address is same as current address, some details are not filled."
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        }
      } else if (detail && !detail.isCurrent) {
        if (detail.livingArrangement && detail.address && isAddressComponentsFilled(detail)) {
          // Previous address, details are filled.
          const livingArrangementFlag = onLivingArrangementChange(detail);
          if (livingArrangementFlag) {
            // Previous address, living arrangement details are filled.
            flag = SectionStatus.COMPLETE;
          } else {
            // Previous address, living arrangement details are not filled.
            flag = SectionStatus.INCOMPLETE;
            break;
          }
        } else {
          // Previous address, details are not filled.
          flag = SectionStatus.INCOMPLETE;
          break;
        }
      }
    }
  }
  return flag;
}

export function addressPercentage(details: Address[]): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  for (const detail of details) {
    outOf += 4;

    if (detail.livingArrangement) completed++;
    if (detail.address && isAddressComponentsFilled(detail)) completed++;
    if (!TypeHelper.isNullOrUndefined(detail.durationOfStayYear)) completed++;
    if (!TypeHelper.isNullOrUndefined(detail.durationOfStayMonth)) completed++;

    if (detail.livingArrangement === LivingArrangement.OWNER) {
      outOf += 1;
      if (detail.additionalAddressDocuments.length > 0) completed++;
    }
    if (
      detail.livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
      detail.livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD
    ) {
      outOf += 3;
      if (detail.propertyManagerName) completed++;
      if (detail.email) completed++;
      if (detail.monthlyRent) completed++;
    }

    if (detail.postalAddressDifferentFromCurrentAddress === true) {
      outOf += 1;
      if (detail.postalAddress) completed++;
    }
  }

  return { outOf, completed };
}

export function addressSummary(details: Address[]): string {
  if (!(details && details.length > 0 && details[0].address)) return 'None';
  return details[0].address;
}

// REFERENCE ------------------------------------------------------------------------- //
export function isReferenceDetailsFilled(details?: PostReferencesFormData): SectionStatus {
  let flag = SectionStatus.OPTIONAL;
  if (details && details.addReference) {
    if (details && details.references) {
      for (const detail of details.references) {
        if (detail && detail.email && detail.name) {
          flag = SectionStatus.COMPLETE;
        } else {
          flag = SectionStatus.INCOMPLETE;
          break;
        }
      }
    }
  } else if (details && details.addReference !== null && !details.addReference) {
    flag = SectionStatus.COMPLETE;
  }

  return flag;
}

// Check that user has added any among DRIVING_LICENSE/PASSPORT/MEDICARE
export function checkRequiredDocument(
  identification: Identification[],
): Identification | undefined {
  return identification.find(
    (id: Identification) =>
      id.type === 'DRIVING_LICENSE' || id.type === 'PASSPORT' || id.type === 'MEDICARE',
  );
}

export function referencePercentage(details: PostReferencesFormData | undefined): Percentage {
  let outOf = 1;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details && details.addReference) {
    completed++;
    for (const detail of details.references) {
      outOf += 2;
      if (detail.email) completed++;
      if (detail.name) completed++;
    }
  } else if (details && details.addReference !== null && !details.addReference) {
    completed++;
  }

  return { outOf, completed };
}

export function referenceSummary(details: PostReferencesFormData | undefined): string {
  if (!(details && details.addReference)) return 'None';
  let result = 0;
  for (const detail of details.references) {
    result++;
  }

  return `${result} references listed`;
}

// IDENTIFICATION ------------------------------------------------------------------------- //
export function isIdentificationDetailsFilled(
  details: PostIdentificationFormData | undefined,
): SectionStatus {
  let flag = SectionStatus.INCOMPLETE;
  if (details && details.IDPoints && details.IDPoints >= MIN_ID_POINTS) {
    if (details && details.identification) {
      for (const detail of details.identification) {
        if (
          detail &&
          detail.type &&
          detail.identificationLink &&
          detail.identificationLink !== 'Error'
        ) {
          flag = SectionStatus.COMPLETE;
          if (detail.type === 'DRIVING_LICENSE') {
            flag =
              detail.licenseNumber && detail.stateOfIssueDL && detail.expiryDate
                ? SectionStatus.COMPLETE
                : SectionStatus.INCOMPLETE;
          }
          if (detail.type === 'PASSPORT') {
            flag =
              detail.passportNumber && detail.countryOfOrigin && detail.expiryDate
                ? SectionStatus.COMPLETE
                : SectionStatus.INCOMPLETE;
          }
          if (detail.type === 'MEDICARE') {
            flag =
              detail.referenceNumber &&
              detail.cardNumber &&
              detail.medicareColour &&
              detail.expiryDate
                ? SectionStatus.COMPLETE
                : SectionStatus.INCOMPLETE;
          }
        } else {
          flag = SectionStatus.INCOMPLETE;
          break;
        }

        if (flag === SectionStatus.INCOMPLETE) return SectionStatus.INCOMPLETE;
      }
    }
  }
  if (details && !checkRequiredDocument(details.identification)) {
    return SectionStatus.INCOMPLETE;
  }
  return flag;
}

export function identificationPercentage(
  details: PostIdentificationFormData | undefined,
): Percentage {
  let outOf = 0;
  let completed = 0;

  if (!details) return { outOf, completed };
  for (const detail of details.identification) {
    outOf += 2;
    if (detail.type) completed++;
    if (detail.identificationLink && detail.identificationLink !== 'Error') completed++;
    if (detail.type === 'DRIVING_LICENSE') {
      outOf += 3;
      if (detail.licenseNumber) completed++;
      if (detail.stateOfIssueDL) completed++;
      if (detail.expiryDate) completed++;
    }

    if (detail.type === 'PASSPORT') {
      outOf += 3;
      if (detail.passportNumber) completed++;
      if (detail.countryOfOrigin) completed++;
      if (detail.expiryDate) completed++;
    }

    if (detail.type === 'MEDICARE') {
      outOf += 4;
      if (detail.referenceNumber) completed++;
      if (detail.cardNumber) completed++;
      if (detail.medicareColour) completed++;
      if (detail.expiryDate) completed++;
    }
  }

  // If one is complete but need more to be filled, set to 50%
  if (details && details.IDPoints && details.IDPoints < MIN_ID_POINTS && outOf === completed) {
    return { outOf: outOf * 2, completed };
  }

  return { outOf, completed };
}

export function identificationSummary(details: PostIdentificationFormData | undefined): string {
  if (!(details && details.identification.length > 0)) return 'None';
  let result = 0;
  for (const detail of details.identification) {
    result++;
  }

  return `${result} added`;
}

// TERMS AND CONDITIONS ------------------------------------------------------------------------- //
export function isDeclarationDetailsFilled(
  details: PostDeclarationFormData | undefined,
): SectionStatus {
  if (details && details.isSortedTermsAccepted) {
    return SectionStatus.COMPLETE;
  }
  return SectionStatus.INCOMPLETE;
}

export function declarationPercentage(details: PostDeclarationFormData | undefined): Percentage {
  let outOf = 1;
  let completed = 0;

  if (!details) return { outOf, completed };
  if (details.isSortedTermsAccepted) completed++;

  return { outOf, completed };
}

export function declarationSummary(details: PostDeclarationFormData | undefined): string {
  if (details && details.isSortedTermsAccepted) {
    return 'Signed';
  }
  return 'Not signed';
}

export function getProfilePercentage(details: ApplicantProfile | undefined): number {
  let result = 0;

  result += getPercentage(personalDetailsPercentage(get(details, 'personalDetails'), true));
  result += getPercentage(currentIncomePercentage(get(details, 'currentIncomeDetails.incomes')));
  result += getPercentage(
    employmentPercentage(
      get(details, 'employmentDetails'),
      get(details, 'currentIncomeDetails.incomes'),
    ),
  );
  result += getPercentage(petsPercentage(get(details, 'petsDetails')));
  result += getPercentage(vehiclesPercentage(get(details, 'vehiclesDetails')));
  result += getPercentage(addressPercentage(get(details, 'addressDetails.addresses')!));
  result += getPercentage(referencePercentage(get(details, 'referencesDetails')));
  result += getPercentage(identificationPercentage(get(details, 'identificationDetails')));
  result += getPercentage(declarationPercentage(get(details, 'declarationDetails')));

  return getPercentage({ completed: result, outOf: 900 });
}

export function getSecondaryProfilePercentage(
  details: ApplicantProfile[] | undefined,
  index: number,
): number {
  let result = 0;

  result += getPercentage(personalDetailsPercentage(get(details, `${index}.personalDetails`)));
  result += getPercentage(
    currentIncomePercentage(get(details, `${index}.currentIncomeDetails.incomes`)),
  );
  result += getPercentage(
    employmentPercentage(
      get(details, `${index}.employmentDetails`),
      get(details, `${index}.currentIncomeDetails.incomes`),
    ),
  );
  result += getPercentage(petsPercentage(get(details, `${index}.petsDetails`)));
  result += getPercentage(vehiclesPercentage(get(details, `${index}.vehiclesDetails`)));
  result += getPercentage(addressPercentage(get(details, `${index}.addressDetails.addresses`)!));
  result += getPercentage(referencePercentage(get(details, `${index}.referencesDetails`)));
  result += getPercentage(identificationPercentage(get(details, `${index}.identificationDetails`)));
  result += getPercentage(declarationPercentage(get(details, `${index}.declarationDetails`)));
  return getPercentage({ completed: result, outOf: 900 });
}

export function additionalProfilePercentage(tenant: DependentProfile | ApplicantProfile): number {
  const outOf = 4;
  let completed = 0;
  if (tenant) {
    const dependentProfile = tenant as DependentProfile;

    if (dependentProfile.title) completed++;
    if (dependentProfile.selectedDateOfBirth) completed++;
    if (dependentProfile.firstName) completed++;
    if (dependentProfile.lastName) completed++;
  }
  return getPercentage({ outOf, completed });
}

export function verifyProfileDetails(details: ApplicantProfile | undefined): boolean {
  return !!(
    details &&
    isPersonalDetailsFilled(get(details, 'personalDetails')) !== SectionStatus.INCOMPLETE &&
    isCurrentIncomeDetailsFilled(get(details, 'currentIncomeDetails.incomes')) !==
      SectionStatus.INCOMPLETE &&
    isEmploymentDetailsFilled(
      get(details, 'employmentDetails'),
      get(details, 'currentIncomeDetails.incomes'),
    ) !== SectionStatus.INCOMPLETE &&
    isPetsDetailsFilled(get(details, 'petsDetails')) !== SectionStatus.INCOMPLETE &&
    isVehiclesDetailsFilled(get(details, 'vehiclesDetails')) !== SectionStatus.INCOMPLETE &&
    isAddressDetailsFilled(get(details, 'addressDetails.addresses')!) !==
      SectionStatus.INCOMPLETE &&
    isReferenceDetailsFilled(get(details, 'referencesDetails')) !== SectionStatus.INCOMPLETE &&
    isIdentificationDetailsFilled(get(details, 'identificationDetails')) !==
      SectionStatus.INCOMPLETE &&
    isDeclarationDetailsFilled(get(details, 'declarationDetails')) !== SectionStatus.INCOMPLETE
  );
}

export function verifyDeclarationDetails(details: ApplicantProfile | undefined) {
  return !!(
    details &&
    isPersonalDetailsFilled(get(details, 'personalDetails')) !== SectionStatus.INCOMPLETE &&
    isCurrentIncomeDetailsFilled(get(details, 'currentIncomeDetails.incomes')) !==
      SectionStatus.INCOMPLETE &&
    isEmploymentDetailsFilled(
      get(details, 'employmentDetails'),
      get(details, 'currentIncomeDetails.incomes'),
    ) !== SectionStatus.INCOMPLETE &&
    isPetsDetailsFilled(get(details, 'petsDetails')) !== SectionStatus.INCOMPLETE &&
    isVehiclesDetailsFilled(get(details, 'vehiclesDetails')) !== SectionStatus.INCOMPLETE &&
    isAddressDetailsFilled(get(details, 'addressDetails.addresses')!) !==
      SectionStatus.INCOMPLETE &&
    isReferenceDetailsFilled(get(details, 'referencesDetails')) !== SectionStatus.INCOMPLETE &&
    isIdentificationDetailsFilled(get(details, 'identificationDetails')) !==
      SectionStatus.INCOMPLETE &&
    !(isDeclarationDetailsFilled(get(details, 'declarationDetails')) !== SectionStatus.INCOMPLETE)
  );
}

export function verifySeconadaryDetails(details: ApplicantProfile[] | undefined, index: number) {
  return !!(
    isPersonalDetailsFilled(get(details, `${index}.personalDetails`)) !==
      SectionStatus.INCOMPLETE &&
    isCurrentIncomeDetailsFilled(get(details, `${index}.currentIncomeDetails.incomes`)) !==
      SectionStatus.INCOMPLETE &&
    isEmploymentDetailsFilled(
      get(details, `${index}.employmentDetails`),
      get(details, `${index}.currentIncomeDetails.incomes`),
    ) !== SectionStatus.INCOMPLETE &&
    isPetsDetailsFilled(get(details, `${index}.petsDetails`)) !== SectionStatus.INCOMPLETE &&
    isVehiclesDetailsFilled(get(details, `${index}.vehiclesDetails`)) !==
      SectionStatus.INCOMPLETE &&
    isAddressDetailsFilled(get(details, `${index}.addressDetails.addresses`)!) !==
      SectionStatus.INCOMPLETE &&
    isReferenceDetailsFilled(get(details, `${index}.referencesDetails`)) !==
      SectionStatus.INCOMPLETE &&
    isIdentificationDetailsFilled(get(details, `${index}.identificationDetails`)) !==
      SectionStatus.INCOMPLETE &&
    isDeclarationDetailsFilled(get(details, `${index}.declarationDetails`)) !==
      SectionStatus.INCOMPLETE
  );
}

export function isAdditionalProfileDetailsFilled(
  tenant: DependentProfile | ApplicantProfile,
): boolean {
  if (tenant) {
    const dependentProfile = tenant as DependentProfile;

    if (
      dependentProfile.title &&
      dependentProfile.selectedDateOfBirth &&
      dependentProfile.firstName &&
      dependentProfile.lastName
    ) {
      return true;
    }
    return false;
  }
  return false;
}
