import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ServiceSummaryStyles';
import {
  DEFAULT_SERVICE_PROVIDER,
  DETAIL_CARD_DATE_FORMAT,
  InvoiceData,
  LABELS,
} from './ServiceSummaryConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ServiceActions } from '../../store/actions/ServiceActions';
import { LeaseResponse } from '../../models/dashboard/dashboard';
import { ServiceState } from '../../store/state/ServiceState';
import { ServiceSummaryParams } from '../../navigation/NavigationConstants';
import { MappedData, filterData } from './ServiceSummaryUtils';
import ActivityIndicator from '../../components/activityIndicator/ActivityIndicator';
import Box from '../../components/box/Box';
import broadbandIcon from '../../assets/marketplace/dark/broadband.png';
import electricityIcon from '../../assets/marketplace/dark/electricity.png';
import { ServiceTypes } from '../../models/services/services';
import arrowRight from '../../assets/navigation/arrow-right.png';
import { calculateCost } from '../../helpers/CostFrequencyHelper';
import { DateFormatter } from '../../helpers/DateFormatter';
import {
  InvoiceDownloadRequest,
  InvoiceRequest,
  InvoiceResponse,
} from '../../models/invoices/invoices';
import Invoices from './components/invoices/Invoices';
import { ToastMessage } from '../../helpers/ToastMessage';

interface ServiceSummaryProps {
  serviceState: ServiceState;
  selectedProperty: number;
  onboardedProperties: LeaseResponse[];
  getServiceData: (summaryAccountId: string) => void;
}

const ServiceSummary: FC<ServiceSummaryProps> = ({
  serviceState,
  selectedProperty,
  onboardedProperties,
  getServiceData,
}) => {
  const classes = useStyles();
  const { serviceAccountId, serviceType } = extractParams<ServiceSummaryParams>();
  const serviceData: MappedData | null = serviceState.otherService
    ? filterData(
        onboardedProperties[selectedProperty].agency,
        serviceType,
        serviceState.otherService[serviceAccountId],
      )
    : null;

  useEffect(() => {
    getServiceData(serviceAccountId.toString());
  }, []);

  const handleSummary = () => {
    ToastMessage.showMessage('Please use the app for this feature', '', 'warning', 3000);
    switch (serviceType) {
      case ServiceTypes.Electricity:
        // TODO
        // navigate('Onboarding', {
        //   url: onboardingUrls.summary(
        //     ServiceTypes.Electricity.toLowerCase(),
        //     serviceAccountId,
        //     'electricitysummary',
        //   ),
        // });
        break;
      case ServiceTypes.Broadband:
        // TODO
        // navigate('Onboarding', {
        //   url: onboardingUrls.summary(
        //     ServiceTypes.Broadband.toLowerCase(),
        //     serviceAccountId,
        //     'broadbandsummary',
        //   ),
        // });
        break;
      default:
        break;
    }
  };

  const onEdit = () => {
    navigate('selectPayment', {
      propertyId: onboardedProperties[selectedProperty].property!.id,
      serviceType: serviceType === ServiceTypes.Electricity ? 'editelectricity' : 'editbroadband',
      paymentRefId:
        serviceState &&
        serviceState.otherService &&
        serviceState.otherService[serviceAccountId].paymentMethodResponse
          ? serviceState.otherService[serviceAccountId].paymentMethodResponse!.refId
          : 'random-value',
      serviceAccountId,
      agencyName: 'Sorted_Services',
    });
  };

  const renderPaymentCardItems = (
    label: string,
    value: string,
    buttonText?: string,
    buttonRedirect?: () => void,
  ) => {
    return (
      <div className={classes.cardItem}>
        <div className={classes.flexBox}>
          <div className={classes.itemLabel}>{label}</div>
          <div className={classes.itemValue}>{value}</div>
        </div>
        <div className={classes.itemButtonBox}>
          <div className={classes.itemButton} onClick={buttonRedirect}>
            {buttonText}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            {serviceState.otherService ? (
              <Box lightBorder>
                {/* YOUR PLAN */}
                <div className={classes.sectionTitle}>{LABELS.SERVICE_SECTION}</div>
                <div className={classes.serviceCard} onClick={handleSummary}>
                  <div className={classes.serviceCardLeftVerticalView}>
                    <img
                      className={classes.serviceIcon}
                      src={
                        serviceData!.serviceName === ServiceTypes.Electricity
                          ? electricityIcon
                          : broadbandIcon
                      }
                    />
                    <div className={classes.serviceCardLeftVerticalView}>
                      <div className={classes.itemLabel}>
                        {serviceData!.serviceName.toUpperCase()}
                      </div>
                      <div className={classes.itemValue}>{DEFAULT_SERVICE_PROVIDER}</div>
                    </div>
                  </div>
                  <img src={arrowRight} className={classes.serviceChevron} />
                </div>
                <div className={classes.sectionTitle2}>{LABELS.PAYMENT_SECTION}</div>
                <div className={classes.detailCard}>
                  {renderPaymentCardItems(
                    LABELS.PAYMENTS,
                    calculateCost(serviceData!.serviceAmountFrequency, serviceData!.serviceAmount),
                    LABELS.ADJUST,
                  )}
                  {!!serviceData!.serviceNextPayment &&
                    renderPaymentCardItems(
                      LABELS.NEXT_PAYMEMT_DATE,
                      DateFormatter.format(
                        serviceData!.serviceNextPayment,
                        DETAIL_CARD_DATE_FORMAT,
                      ),
                    )}
                  {!!serviceData!.servicePaymentMethod &&
                    !!serviceData!.servicePaymentAccount &&
                    renderPaymentCardItems(
                      serviceData!.servicePaymentMethod,
                      serviceData!.servicePaymentAccount.replace(/\./g, '*'),
                      serviceData!.servicePaymentMethod === LABELS.NOTAVAILABLE
                        ? LABELS.ADD
                        : LABELS.EDIT,
                      onEdit,
                    )}
                </div>
                <Invoices
                  serviceAccountId={serviceAccountId}
                  serviceType={
                    serviceType === ServiceTypes.Electricity
                      ? ServiceTypes.Electricity
                      : ServiceTypes.Broadband
                  }
                />
              </Box>
            ) : (
              <div className={classes.activityLoadingContainer}>
                <ActivityIndicator size="large" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  serviceState: state.services,
  selectedProperty: state.dashboardState.selectedProperty,
  onboardedProperties: state.dashboardState.onboardedProperties,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getServiceData: (summaryAccountId: string) =>
    dispatch(ServiceActions.getOtherServiceRequest(summaryAccountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSummary);
