import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    activeButton: {
      color: theme.colors.light,
      backgroundColor: theme.colors.buttonPrimary,
      height: '35px',
      minWidth: '100px',
      textTransform: 'capitalize',
      marginRight: '5px',
      '&:hover': {
        backgroundColor: theme.colors.buttonPrimary,
      },
    },
    inactiveButton: {
      color: theme.colors.label,
      backgroundColor: '#f6f6f6',
      height: '35px',
      minWidth: '100px',
      marginRight: '5px',
      textTransform: 'capitalize',
    },
    noPadding: {
      padding: 0,
    },
  })();
};
