import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './ProfileSelectionStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ProfileType } from '../../models/user/user';
import { ProfileActions } from '../../store/actions/ProfileActions';
import Box from '../../components/box/Box';
import Text from '../../components/text/Text';
import { LABELS } from './ProfileSelectionConstants';
import Button from '../../components/button/Button';
import sortedLogo from '../../assets/sorted-icon-dark.png';
import naxLogo from '../../assets/logosCircle/nax.png';
import home from '../../assets/home-active.png';
import key from '../../assets/key-active.png';
import tick from '../../assets/tick-active.png';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface ProfileSelectionProps extends RouteComponentProps {
  updateProfileType: (data: ProfileType) => void;
  fetchTenantDashboard: () => void;
}

const ProfileSelection: FC<ProfileSelectionProps> = ({
  updateProfileType,
  fetchTenantDashboard,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();
  const [profileType, setProfileType] = useState(ProfileType.TENANT);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            <Box lightBorder parentStyles={classes.mainBox}>
              <img
                src={themeKey === ThemeKey.NAX ? naxLogo : sortedLogo}
                className={classes.icon}
                alt="icon"
              />
              <Text parentStyles={classes.title} textVariant="title">
                {LABELS.WELCOME}
              </Text>
              <Text parentStyles={classes.subtitle} textVariant="info">
                {LABELS.WELCOME_SUBTITLE}
              </Text>
              <Box
                parentStyles={classes.option}
                highlight
                lightBorder
                selected={profileType === ProfileType.LANDLORD}
                onPress={() => setProfileType(ProfileType.LANDLORD)}
              >
                <div className={classes.optionRow}>
                  <img className={classes.optionIcon} src={home} alt="option-icon" />
                  <Text parentStyles={classes.optionText}>{LABELS.RENT_OUT}</Text>
                  {profileType === ProfileType.LANDLORD && (
                    <img className={classes.optionTick} src={tick} alt="option-tick" />
                  )}
                </div>
              </Box>
              <Box
                parentStyles={classes.option}
                highlight
                lightBorder
                selected={profileType === ProfileType.TENANT}
                onPress={() => setProfileType(ProfileType.TENANT)}
              >
                <div className={classes.optionRow}>
                  <img className={classes.optionIcon} src={key} alt="option-icon" />
                  <Text parentStyles={classes.optionText}>{LABELS.LIVE_IN}</Text>
                  {profileType === ProfileType.TENANT && (
                    <img className={classes.optionTick} src={tick} alt="option-tick" />
                  )}
                </div>
              </Box>
              <div className={classes.actionButtonContainer}>
                <Button
                  parentStyles={classes.actionButton}
                  // TODO remove once landlord is supported
                  disabled={profileType === ProfileType.LANDLORD}
                  onPress={() => {
                    updateProfileType(profileType);
                    if (profileType === ProfileType.LANDLORD) {
                      // TODO support landlord
                      // getLandLordDashboard();
                    } else {
                      fetchTenantDashboard();
                    }
                  }}
                >
                  {LABELS.NEXT}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileType: (data: ProfileType) => dispatch(ProfileActions.switchProfileType(data)),
  fetchTenantDashboard: () => dispatch(DashboardActions.fetchCompletedRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileSelection));
