import React, { FC, useEffect, useState } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputDateStyles';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import calendar from '../../assets/calendar.png';
import moment, { Moment } from 'moment';
import { isEmpty } from '../../helpers/Validation';
import { blockDatesFn } from './InputDateUtils';

interface InputDateProps {
  value: string | Date | null | undefined;
  placeholder: string;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  password?: boolean;
  endElement?: any;
  showRequiredError?: boolean;
  maxDate?: string | Date;
  minDate?: string | Date;
  yearMonthOnly?: boolean;
  blockedDates: string[];
  disableWeekends?: boolean;
  big?: boolean;
  onBlur?: () => void;
  setValue: (value: string | Date | null | undefined) => void;
}

const InputDate: FC<InputDateProps> = ({
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  password,
  endElement,
  showRequiredError,
  maxDate,
  minDate,
  yearMonthOnly,
  blockedDates,
  disableWeekends,
  big,
  onBlur,
  setValue,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        value={value}
        label="Basic example"
        open={open}
        orientation="landscape"
        maxDate={maxDate}
        minDate={minDate}
        inputFormat="DD/MM/YYYY"
        onClose={() => setOpen(false)}
        onAccept={() => setOpen(false)}
        shouldDisableDate={(date) =>
          !!blockedDates ? blockDatesFn(date as any, blockedDates, disableWeekends) : false
        }
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className={classNameGenerator([classes.inputCard, parentStyles])}>
            {(touched && error) || (showRequiredError && isEmpty(value)) ? (
              <div className={classes.errorTextStyle}>{error || 'Required'}</div>
            ) : (
              <>
                {title && (
                  <div
                    className={classNameGenerator([
                      classes.titleContainer,
                      big && classes.titleContainerBig,
                    ])}
                  >
                    <div
                      className={classNameGenerator([
                        classes.titleStyle,
                        big && classes.titleStyleBig,
                      ])}
                    >
                      {title}
                    </div>
                    <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
                  </div>
                )}
              </>
            )}
            <div
              className={classNameGenerator([
                classes.inputContainer,
                big && classes.inputContainerBig,
                ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
              ])}
              onClick={() => setOpen(true)}
            >
              <input
                ref={inputRef}
                {...inputProps}
                value={value ? moment(value).format(yearMonthOnly ? 'MM/YYYY' : 'DD/MM/YYYY') : ''}
                type={password ? 'password' : 'text'}
                className={classNameGenerator([
                  classes.inputStyle,
                  big && classes.inputStyleBig,
                  parentInputStyles,
                ])}
                placeholder={placeholder}
                onChange={(event) => setValue('')}
                onBlur={onBlur}
                disabled={disabled}
              />
              <div className={classes.calendarContainer} onClick={() => setOpen(!open)}>
                <img
                  src={calendar}
                  className={classNameGenerator([classes.calendar, big && classes.calendarBig])}
                  alt="arrow"
                />
              </div>
              <div
                className={classNameGenerator([classes.endElement, big && classes.endElementBig])}
              >
                {endElement}
              </div>
              {((touched && error) || (showRequiredError && isEmpty(value)) || value) && (
                <img
                  className={classNameGenerator([classes.endStatus, big && classes.endStatusBig])}
                  src={
                    (touched && error) || (showRequiredError && isEmpty(value))
                      ? IncompleteIcon
                      : value
                      ? CompletedIcon
                      : ''
                  }
                  alt=""
                />
              )}
              {/* {InputProps?.endAdornment} */}
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
