import { MasterProfileState } from '../state/MasterProfileState';
import { MasterProfileAction, MasterProfileActionTypes } from '../actions/MasterProfileActions';
import { SubmittedApplicationResponse } from '../../models/masterProfile/MasterProfile';
import { AustraliaState } from '../../models/australiaStates/australiaStates';

export const initialState: MasterProfileState = {
  masterProfileData: null,
};

export const masterProfileReducer = (
  state = initialState,
  action: MasterProfileAction,
): MasterProfileState => {
  switch (action.type) {
    case MasterProfileActionTypes.GET_MASTER_PROFILE_REQUEST:
      return {
        ...state,
      };

    case MasterProfileActionTypes.GET_MASTER_PROFILE_SUCCESS:
      return {
        ...state,
        masterProfileData: action.data as SubmittedApplicationResponse,
      };

    case MasterProfileActionTypes.GET_MASTER_PROFILE_ERROR:
      return {
        ...state,
      };
    case MasterProfileActionTypes.SET_AUSTRALIA_STATE: {
      const { masterProfileData } = state;
      (masterProfileData as SubmittedApplicationResponse).applicants[0].selectedState =
        action.data as AustraliaState | null;
      return {
        ...state,
        masterProfileData,
      };
    }

    default:
      return state;
  }
};
