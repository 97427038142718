import * as Yup from 'yup';

export const LABELS = {
  TITLE: 'Enter a new password',
  NEW_PASSWORD: 'New password',
  CONFIRM_PASSWORD: 'Confirm password',
  DONE: 'Next',
};

export const ERRORS = {
  requiredPassword: 'Please enter password',
  passwordRequirement: "Password doesn't fulfill the requirements",
  noMatch: "Passwords don't match",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  newPassword: Yup.string()
    .required(ERRORS.requiredPassword)
    .min(8, ERRORS.passwordRequirement)
    .matches(/[A-Z]/, ERRORS.passwordRequirement)
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, ERRORS.passwordRequirement),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], ERRORS.noMatch),
});
