import React, { FC } from 'react';
import { useStyles } from './TaskStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import Box from '../box/Box';
import Text from '../text/Text';

interface TaskProps {
  icon: string;
  title: string;
  description: string;
  time?: string;
  focus?: boolean;
  loading?: boolean;
  green?: boolean;
  parentStyles?: string;
  handlePress: () => void;
}

const Task: FC<TaskProps> = ({
  icon,
  title,
  description,
  time,
  focus,
  green,
  loading,
  parentStyles,
  handlePress,
}) => {
  const classes = useStyles();

  return (
    <Box
      lightBorder
      parentStyles={classNameGenerator([
        classes.container,
        focus && classes.focusContainer,
        parentStyles,
      ])}
      loading={loading}
      onPress={() => handlePress()}
    >
      {!loading && (
        <>
          <div className={classes.nextTop}>
            <img src={icon} className={classes.nextIcon} alt="next icon" />
            {time && (
              <div
                className={classNameGenerator([classes.nextTime, green && classes.nextTimeGreen])}
              >
                {time}
              </div>
            )}
          </div>
          <Text textVariant="title" size="xl" parentStyles={classes.nextTitle}>
            {title}
          </Text>
          <Text textVariant="info" size="l">
            {description}
          </Text>
        </>
      )}
    </Box>
  );
};

export default Task;
