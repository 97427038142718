/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState } from 'react';
import downloadIcon from '../../assets/downloadIcon.png';
import closeIcon from '../../assets/clear.png';
import { Image as ImageInterface } from '../../models/landlord/LandlordProperty';
import { LABELS } from './MultipleImageComponentConstants';
import { useStyles } from './MultipleImageComponentStyles';
import { Dialog } from '@mui/material';

interface MultipleImageComponentProps {
  images: ImageInterface[];
}

const MultipleImageComponent: FC<MultipleImageComponentProps> = ({ images }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  console.log('images', images);
  return (
    <div>
      {images && images.length > 0 ? (
        <>
          <div className={classes.mainteanceImagesContainer}>
            {images.map((image, index) => (
              <div
                onClick={() => {
                  setSelectedImage(index);
                  setShowModal(true);
                }}
                className={classes.imageContainer}
                key={index}
              >
                <div className={classes.imageViewContainer}>
                  <div className={classes.contentViewStyle}>
                    <div className={classes.loaderStyle} />
                  </div>
                  <div className={classes.contentViewStyle}>
                    {image.contentType.includes('video') ? (
                      <video src={image.link!} className={classes.maintenanceImage} />
                    ) : (
                      <img src={image.link!} className={classes.maintenanceImage} key={index} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={classes.noAttachmentsText}>No attachments</div>
      )}
      <Dialog open={showModal} onClose={() => setShowModal(false)} className={classes.modal}>
        <div className={classes.modalView}>
          <div onClick={() => setShowModal(false)} className={classes.closeIconContainer}>
            <img src={closeIcon} className={classes.closeIcon} />
          </div>
          {images[selectedImage].contentType.includes('video') ? (
            <video
              src={images[selectedImage] ? images[selectedImage].link! : ''}
              className={classes.modalImage}
              controls
            />
          ) : (
            <img
              src={images[selectedImage] ? images[selectedImage].link! : ''}
              className={classes.modalImage}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default MultipleImageComponent;
