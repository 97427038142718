import { makeStyles } from '@mui/styles';
import { getCurrentTheme } from '../../theme/Theme';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    snackBarContentStyles: {
      borderRadius: '5px',
      backgroundColor: theme.colors.darkGrey,
      color: theme.colors.light,
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '1.19',
      letterSpacing: '0px',
      fontFamily: 'Helvetica',
      borderLeft: '4px solid #1badc1',
      boxShadow:
        '0 2px 4px - 1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
    },
    snackBarTextStyles: {
      marginLeft: '15px',
    },
    errorStyle: {
      borderLeft: `0.3rem solid ${theme.colors.error}`,
    },
    icon: {
      width: theme.units.iconSize * 1.6,
      height: theme.units.iconSize * 1.6,
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })();
};
