import { takeEvery, put } from 'redux-saga/effects';
import {
  InvoiceDownloadRequest,
  InvoiceRequest,
  InvoiceResponse,
} from '../../models/invoices/invoices';
import {
  JobDetailResponse,
  LocationData,
  MaintenanceSummaryRequest,
} from '../../models/maintenance/maintenance';
import { CheckBillData } from '../../models/services/checkBill';
import {
  GenericServiceSummaryRequest,
  GenericServiceSummaryResponse,
} from '../../models/services/genericServiceSummary';
import { RentService } from '../../models/services/rentService';
import {
  ActiveServicesPaymentSummaryRequest,
  ActiveServicesPaymentSummaryResponse,
  ResetServicePlanRequest,
} from '../../models/services/services';
import SortedService from '../../services/sorted/SortedService';
import { ServiceAction, ServiceActions, ServiceActionTypes } from '../actions/ServiceActions';
import { SpinnerActions } from '../actions/SpinnerActions';
import camelCaseKeys from 'camelcase-keys';
import { navigate } from '../../navigation/NavigationUtils';

const mapCost = (
  response: any, // TODO: Resolve any
) => {
  return {
    ...response,
    cost: response.monthlyCost,
  };
};

function* fetchMaintenanceSummary(action: ServiceAction) {
  try {
    const response: JobDetailResponse = yield SortedService.getMaintenanceSummary(
      action.data as MaintenanceSummaryRequest,
    );
    yield put(ServiceActions.getMaintenanceSummarySuccess(response));
  } catch (error) {
    yield put(ServiceActions.getMaintenanceSummaryError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* fetchGenericServiceSummary(action: ServiceAction) {
  try {
    const response: GenericServiceSummaryResponse = yield SortedService.getGenericServiceSummary(
      action.data as GenericServiceSummaryRequest,
    );
    response.serviceAccountId = (action.data as GenericServiceSummaryRequest).serviceAccountId;
    yield put(ServiceActions.getGenericServiceSummarySuccess(response));
  } catch (error) {
    yield put(ServiceActions.getGenericServiceSummaryError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* fetchLocationData(action: ServiceAction) {
  try {
    const response: LocationData = yield SortedService.getLocationData();
    yield put(ServiceActions.getLocationDataSuccess(response));
  } catch (error) {
    yield put(ServiceActions.getLocationDataError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* fetchRentService(action: ServiceAction) {
  try {
    const response: RentService = yield SortedService.fetchService(action.data as string);
    response.serviceAccountId = parseInt(action.data as string);

    yield put(ServiceActions.getRentServiceSuccess(mapCost(response)));
  } catch (error) {
    yield put(ServiceActions.getRentServiceError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* fetchOtherService(action: ServiceAction) {
  try {
    const response: RentService = yield SortedService.fetchService(action.data as string);
    response.serviceAccountId = parseInt(action.data as string);

    yield put(ServiceActions.getOtherServiceSuccess(mapCost(response)));
  } catch (error) {
    yield put(ServiceActions.getOtherServiceError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* downloadDocument(action: ServiceAction) {
  try {
    yield SortedService.downloadDocument(action.data as string);
    yield put(ServiceActions.downloadDocumentSuccess());
  } catch (error) {
    yield put(ServiceActions.downloadDocumentError(error as Error));
  }
}

function* downloadInvoice(action: ServiceAction) {
  try {
    yield SortedService.downloadInvoice(action.data as InvoiceDownloadRequest);
    yield put(ServiceActions.downloadInvoiceSuccess());
  } catch (error) {
    yield put(ServiceActions.downloadInvoiceError(error as Error));
  }
}

function* fetchInvoices(action: ServiceAction) {
  try {
    const response: InvoiceResponse[] = yield SortedService.fetchInvoices(
      action.data as InvoiceRequest,
    );
    yield put(ServiceActions.fetchInvoicesSuccess(response));
  } catch (error) {
    yield put(ServiceActions.fetchInvoicesError(error as Error));
  }
}

function* fetchServiceSummaryWorker(action: ServiceAction) {
  try {
    const response: RentService = yield SortedService.getServiceSummary(action.data as string);
    yield put(ServiceActions.getServiceSummarySuccess(mapCost(response)));
  } catch (error) {
    yield put(ServiceActions.getServiceSummaryError(error as Error));
    yield put(SpinnerActions.hide());
    navigate('errorScreen');
  }
}

function* postCheckBillWorker(action: ServiceAction) {
  try {
    const data = action.data as CheckBillData;
    yield SortedService.postCheckBill(data);
    yield put(ServiceActions.postCheckBillSuccess());
  } catch (error) {
    yield put(ServiceActions.postCheckBillError(error as Error));
  }
}

function* resetServicePlanWorker(action: ServiceAction) {
  try {
    const data = action.data as ResetServicePlanRequest;
    yield SortedService.resetServicePlan(data);
    yield put(ServiceActions.resetServicePlanSuccess());
  } catch (error) {
    yield put(ServiceActions.resetServicePlanError(error as Error));
  }
}

export function* activeServicesPaymentSummaryWorker(action: ServiceAction) {
  try {
    const data = action.data as ActiveServicesPaymentSummaryRequest;
    if (!!data.serviceAccountIds.length) {
      const response: ActiveServicesPaymentSummaryResponse[] =
        yield SortedService.getActiveServicesPaymentSummaryRequest(data);
      yield put(
        ServiceActions.getActiveServicesPaymentSummarySuccess(
          camelCaseKeys(response, { deep: true }),
        ),
      );
    } else {
      yield put(ServiceActions.getActiveServicesPaymentSummarySuccess([]));
    }
  } catch (error) {
    yield put(ServiceActions.getActiveServicesPaymentSummaryError(error as Error));
  }
}

function* serviceWatcher() {
  yield takeEvery(ServiceActionTypes.GET_RENT_SERVICE_REQUEST, (action) =>
    fetchRentService(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_OTHER_SERVICE_REQUEST, (action) =>
    fetchOtherService(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.DOWNLOAD_DOCUMENT_REQUEST, (action) =>
    downloadDocument(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.FETCH_INVOICES_REQUEST, (action) =>
    fetchInvoices(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.DOWNLOAD_INVOICE_REQUEST, (action) =>
    downloadInvoice(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_SERVICE_SUMMARY_REQUEST, (action) =>
    fetchServiceSummaryWorker(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_GENERIC_SERVICE_SUMMARY_REQUEST, (action) =>
    fetchGenericServiceSummary(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_LOCATION_DATA_REQUEST, (action) =>
    fetchLocationData(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_MAINTENANCE_SUMMARY_REQUEST, (action) =>
    fetchMaintenanceSummary(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.POST_CHECK_BILL_REQUEST, (action) =>
    postCheckBillWorker(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.RESET_SERVICE_PLAN_REQUEST, (action) =>
    resetServicePlanWorker(action as ServiceAction),
  );
  yield takeEvery(ServiceActionTypes.GET_ACTIVE_SERVICES_PAYMENT_SUMMARY_REQUEST, (action) =>
    activeServicesPaymentSummaryWorker(action as ServiceAction),
  );
}

export default serviceWatcher;
