import { all } from 'redux-saga/effects';
import authWatcher from './sagas/AuthSaga';
import dashboardWatcher from './sagas/DashboardSaga';
import paymentWatcher from './sagas/PaymentSaga';
import profileWatcher from './sagas/ProfileSaga';
import requestWatcher from './sagas/RequestSaga';
import serviceWatcher from './sagas/ServiceSaga';
import cimetWatcher from './sagas/CimetSaga';
import tokenWatcher from './sagas/TokenSaga';
import groupsWatcher from './sagas/GroupsSaga';
import masterProfileWatcher from './sagas/MasterProfileSaga';
import walletWatcher from './sagas/WalletSaga';
import landlordWatcher from './sagas/LandlordSaga';
import maintenanceWatcher from './sagas/MaintenanceSaga';
import checkoutWatcher from './sagas/CheckoutSaga';
import rentOnboardingWatcher from './sagas/RentOnboardingSaga';
import fileUploadWatcher from './sagas/FileUploadSaga';
import propertyOnboardingWatcher from './sagas/PropertyOnboardingSaga';

export default function* sagas() {
  yield all([
    dashboardWatcher(),
    authWatcher(),
    profileWatcher(),
    requestWatcher(),
    paymentWatcher(),
    serviceWatcher(),
    cimetWatcher(),
    tokenWatcher(),
    groupsWatcher(),
    masterProfileWatcher(),
    walletWatcher(),
    landlordWatcher(),
    maintenanceWatcher(),
    checkoutWatcher(),
    rentOnboardingWatcher(),
    fileUploadWatcher(),
    propertyOnboardingWatcher(),
  ]);
}

// TODO: Rahul help me
// export function* cancellationSaga() {
//   yield take(AuthActionTypes.LOGOUT_SUCCESS);
// }

// export default function* sagas() {
//   const watchers = [
//     dashboardWatcher(),
//     authWatcher(),
//     profileWatcher(),
//     requestWatcher(),
//     paymentWatcher(),
//     serviceWatcher(),
//     cimetWatcher(),
//     tokenWatcher(),
//     groupsWatcher(),
//     masterProfileWatcher(),
//     walletWatcher(),
//     landlordWatcher(),
//   ];

//   yield race([cancellationSaga(), all(watchers)]);
// }
