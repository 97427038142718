import { PopupType } from '../../containers/checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import {
  AddAndUpdateCartActionRequest,
  AvailableServicesRequest,
  AvailableServicesResponse,
  CheckoutRequest,
  DeleteCartItemActionRequest,
  GetCartResponse,
  ModifyCart,
  PersonalDetails,
  Preopen,
  RemindMeLaterRequest,
} from '../../models/checkout/Checkout';
import { ServiceTypes } from '../../models/services/services';
import { StoreAction } from '../StoreHelper';

export enum CheckoutActionTypes {
  TOGGLE_CART = 'TOGGLE_CART',

  GET_AVAILABLE_SERVICES = 'GET_AVAILABLE_SERVICES',
  GET_AVAILABLE_SERVICES_REQUEST = 'GET_AVAILABLE_SERVICES_REQUEST',
  GET_AVAILABLE_SERVICES_SUCCESS = 'GET_AVAILABLE_SERVICES_SUCCESS',
  GET_AVAILABLE_SERVICES_ERROR = 'GET_AVAILABLE_SERVICES_ERROR',

  ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  ADD_TO_COMPARE = 'ADD_TO_COMPARE',
  REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE',
  RESET_COMPARE = 'RESET_COMPARE',

  ADD_AND_UPDATE_CART = 'ADD_AND_UPDATE_CART',
  ADD_AND_UPDATE_CART_REQUEST = 'ADD_AND_UPDATE_CART_REQUEST',
  ADD_AND_UPDATE_CART_SUCCESS = 'ADD_AND_UPDATE_CART_SUCCESS',
  ADD_AND_UPDATE_CART_ERROR = 'ADD_AND_UPDATE_CART_ERROR',
  ADD_AND_UPDATE_CART_RESET = 'ADD_AND_UPDATE_CART_RESET',

  CHECKOUT = 'CHECKOUT',
  CHECKOUT_REQUEST = 'CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  CHECKOUT_ERROR = 'CHECKOUT_ERROR',
  CHECKOUT_RESET = 'CHECKOUT_RESET',

  DELETE_CART_ITEM_REQUEST = 'DELETE_CART_ITEM_REQUEST',
  SET_REQUIRED_SERVICE_TYPES = 'SET_REQUIRED_SERVICE_TYPES',
  SET_PREOPEN = 'SET_PREOPEN',
  SET_CHECKOUT_PROPERTY_ID = 'SET_CHECKOUT_PROPERTY_ID',

  UPDATE_PERSONAL_DETAILS = 'UPDATE_PERSONAL_DETAILS',

  DISMISS_INFO_POPUP = 'DISMISS_INFO_POPUP',

  REMIND_ME_LATER = 'REMIND_ME_LATER',

  TOGGLE_LL_MODAL = 'TOGGLE_LL_MODAL',

  SET_IS_LANDLORD = 'SET_IS_LANDLORD',
  SET_IS_TRADE_REQUEST = 'SET_IS_TRADE_REQUEST',
}

export type CheckoutActionPayload =
  | AvailableServicesResponse[]
  | AvailableServicesRequest
  | ModifyCart
  | Preopen
  | ServiceTypes[]
  | boolean
  | Error
  | string
  | AddAndUpdateCartActionRequest
  | GetCartResponse
  | CheckoutRequest
  | DeleteCartItemActionRequest
  | PersonalDetails
  | PopupType
  | RemindMeLaterRequest
  | null;

export type CheckoutAction = StoreAction<CheckoutActionTypes, CheckoutActionPayload>;

export class CheckoutActions {
  public static toggleCart() {
    return { type: CheckoutActionTypes.TOGGLE_CART };
  }

  public static toggleModal(data: PopupType) {
    return { type: CheckoutActionTypes.TOGGLE_LL_MODAL, data };
  }

  public static getAvailableServicesStart(data: AvailableServicesRequest): CheckoutAction {
    return { type: CheckoutActionTypes.GET_AVAILABLE_SERVICES_REQUEST, data };
  }

  public static getAvailableServicesSuccess(data: GetCartResponse): CheckoutAction {
    return {
      type: CheckoutActionTypes.GET_AVAILABLE_SERVICES_SUCCESS,
      data,
    };
  }

  public static getAvailableServicesError(data: Error): CheckoutAction {
    return { type: CheckoutActionTypes.GET_AVAILABLE_SERVICES_ERROR, data };
  }

  public static addToCart(data: ModifyCart): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_TO_CART, data };
  }

  public static removeFromCart(data: ModifyCart): CheckoutAction {
    return { type: CheckoutActionTypes.REMOVE_FROM_CART, data };
  }

  public static addToCompare(data: ModifyCart): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_TO_COMPARE, data };
  }

  public static removeFromCompare(data: ModifyCart): CheckoutAction {
    return { type: CheckoutActionTypes.REMOVE_FROM_COMPARE, data };
  }

  public static resetCompare(): CheckoutAction {
    return { type: CheckoutActionTypes.RESET_COMPARE, data: null };
  }

  public static updateCart(data: AddAndUpdateCartActionRequest): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_AND_UPDATE_CART_REQUEST, data };
  }

  public static updateCartSuccess(data: AvailableServicesResponse[]): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_AND_UPDATE_CART_SUCCESS, data };
  }

  public static updateCartError(data: Error): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_AND_UPDATE_CART_ERROR, data };
  }

  public static postCheckout(data: CheckoutRequest): CheckoutAction {
    return { type: CheckoutActionTypes.CHECKOUT_REQUEST, data };
  }

  public static postCheckoutSuccess(): CheckoutAction {
    return { type: CheckoutActionTypes.CHECKOUT_SUCCESS, data: null };
  }

  public static postCheckoutError(data: Error): CheckoutAction {
    return { type: CheckoutActionTypes.CHECKOUT_ERROR, data };
  }

  public static deleteCartItem(data: DeleteCartItemActionRequest): CheckoutAction {
    return { type: CheckoutActionTypes.DELETE_CART_ITEM_REQUEST, data };
  }
  public static setRequiredServiceTypes(data: ServiceTypes[]): CheckoutAction {
    return { type: CheckoutActionTypes.SET_REQUIRED_SERVICE_TYPES, data };
  }

  public static setPreopen(data: Preopen): CheckoutAction {
    return { type: CheckoutActionTypes.SET_PREOPEN, data };
  }

  public static setCheckoutPropertyId(data: string): CheckoutAction {
    return { type: CheckoutActionTypes.SET_CHECKOUT_PROPERTY_ID, data };
  }

  public static updatePersonalDetails(data: PersonalDetails) {
    return { type: CheckoutActionTypes.UPDATE_PERSONAL_DETAILS, data };
  }

  public static dismissedInfoPopup() {
    return { type: CheckoutActionTypes.DISMISS_INFO_POPUP };
  }

  public static remindMeLater(data: RemindMeLaterRequest): CheckoutAction {
    return { type: CheckoutActionTypes.REMIND_ME_LATER, data };
  }

  public static setIsLandlord(value: boolean): CheckoutAction {
    return {
      type: CheckoutActionTypes.SET_IS_LANDLORD,
      data: value,
    };
  }
  public static resetCheckout(): CheckoutAction {
    return { type: CheckoutActionTypes.CHECKOUT_RESET, data: null };
  }

  public static resetAddUpdate(): CheckoutAction {
    return { type: CheckoutActionTypes.ADD_AND_UPDATE_CART_RESET, data: null };
  }

  public static setIsTradeRequest(value: boolean): CheckoutAction {
    return {
      type: CheckoutActionTypes.SET_IS_TRADE_REQUEST,
      data: value,
    };
  }
}
