import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './RentOnboardingConfirmationStyles';
import { LABELS, getPaymentType } from './RentOnboardingConfirmationConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { connect } from 'react-redux';
import { RentOnboardingActions } from '../../store/actions/RentOnboarding';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import {
  PaymentProvider,
  PostRentOnboardingPayload,
  PostRentOnboardingRequest,
} from '../../models/rentOnboarding/rentOnboarding';
import { routes } from '../../Routes';
import { calculateCost } from '../../helpers/CostFrequencyHelper';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import { useGetLeaseData } from '../../helpers/RentServiceHelper';
import { RentalFrequency } from '../../models/lease/lease';
import { ServiceTypes } from '../../models/services/services';
import SORTED from '../../assets/logosCircle/sorted.png';
import house from '../../assets/houses/house3.png';
import TileContainer from '../../components/tileContainer/TileContainer';
import home from '../../assets/navigation/home.png';
import moment from 'moment';
import Button from '../../components/button/Button';
import { ToastMessage } from '../../helpers/ToastMessage';

interface RentOnboardingConfirmationProps {
  postRentService: (data: PostRentOnboardingRequest, onSuccess: () => void) => void;
}

const RentOnboardingConfirmation: FC<RentOnboardingConfirmationProps> = ({ postRentService }) => {
  const classes = useStyles();
  const lease = useGetLeaseData();

  const address = useMemo<{ address1: string; address2: string } | undefined>(() => {
    if (lease && lease.property) {
      const { unitNumber, streetNumber, streetName, state, suburb, postcode } = lease.property;
      const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
      const address2 = `${suburb}, ${state} ${postcode}`;
      return { address1, address2 };
    } else {
      return undefined;
    }
  }, [lease]);

  const handleNext = () => {
    const { paymentDetails, serviceability, leaseRefId, initialPayments } = lease!;
    const { paymentProvider } = paymentDetails!;

    if (
      !!initialPayments.length ||
      (paymentProvider && paymentProvider === PaymentProvider.SORTED)
    ) {
      // Select a paymentProvider
      navigate('selectPayment', {
        propertyId: lease!.property!.id.toString(),
        serviceType: ServiceTypes.Rent,
      });
    } else if (paymentProvider) {
      // Add a new paymentProvider
      navigate('rentSummary');
    } else if (serviceability && serviceability.length !== 0) {
      // There are services attached, please see them
      navigate('leaseServiceability');
    } else {
      // Go straight to setup complete
      postRentService(
        {
          leaseRefId,
          paymentRefId: null,
        },
        () => {
          ToastMessage.showMessage('Rent setup success', '', 'success', 3000);
          navigate('home', { refresh: true });
        },
      );
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.centerPageContent}>
          <div className={classes.primaryContainer}>
            {lease ? (
              <>
                <div className={classes.title}>
                  {!!lease.initialPayments.length ? LABELS.NAV_INITIAL_TITLE : LABELS.NAV_TITLE}
                </div>
                <div className={classes.subtitle}>
                  {!!lease.initialPayments.length
                    ? LABELS.NAV_INITIAL_SUBTITLE
                    : LABELS.NAV_SUBTITLE}
                </div>
                <TileContainer title={LABELS.PROPERTY} noScroll>
                  <div className={classes.propertyContainer}>
                    {address && (
                      <div className={classes.messageContainer}>
                        <div className={classes.messageTitle}>{address.address1}</div>
                        <div className={classes.messageSubtitle}>{address.address2}</div>
                      </div>
                    )}
                    <img
                      className={classes.agencyLogo}
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = SORTED;
                      }}
                      src={lease && !!lease.agency.logoUrl ? lease.agency.logoUrl : SORTED}
                    />
                    <div className={classes.houseContainer}>
                      <img className={classes.house} src={house} />
                    </div>
                  </div>
                </TileContainer>
                <TileContainer
                  title={
                    !!lease.initialPayments.length ? LABELS.INITIAL_PAYMENTS : LABELS.RENT_PAYMENTS
                  }
                  noScroll
                >
                  {!!lease.initialPayments.length ? (
                    <>
                      {lease.initialPayments.map((payment, idx) => (
                        <div className={classes.rentContainer}>
                          <div className={classes.rentLogoContainer}>
                            <img className={classes.rentLogo} src={home} />
                          </div>{' '}
                          <div className={classes.messageContainer}>
                            <div className={classes.messageTitle}>{getPaymentType(payment)}</div>
                            <div className={classes.messageSubtitle}>
                              {lease.agency.tradingName}
                            </div>
                          </div>
                          <div className={classes.rentTextContainer}>
                            <div className={classes.rentTitle}>${payment.amount.toFixed(2)}</div>
                            <div className={classes.rentSubtitle}>
                              Draw date: {moment(payment.paymentDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={classes.rentContainer}>
                      <div className={classes.rentLogoContainer}>
                        <img className={classes.rentLogo} src={home} />
                      </div>
                      <div className={classes.messageContainer}>
                        <div className={classes.messageTitle}>{LABELS.RENT}</div>
                        <div className={classes.messageSubtitle}>{lease.agency.tradingName}</div>
                      </div>
                      <div className={classes.rentTextContainer}>
                        <div className={classes.rentTitle}>
                          {calculateCost(
                            lease.paymentFrequency as RentalFrequency,
                            lease.monthlyCost as number,
                            true,
                          )}
                        </div>
                        <div className={classes.rentSubtitle}>{lease.paymentFrequency}</div>
                      </div>
                    </div>
                  )}
                </TileContainer>
                <div className={classes.buttonContainer}>
                  <Button onPress={() => handleNext()} parentStyles={classes.button}>
                    {LABELS.THATS_IT}
                  </Button>
                </div>
              </>
            ) : (
              <>Loading</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRentService: (data: PostRentOnboardingRequest, onSuccess: () => void) => {
    dispatch(
      RentOnboardingActions.postRentOnboardingStart({
        data,
        onSuccess,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RentOnboardingConfirmation);
