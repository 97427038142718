import { getFonts } from '../../helpers/GetFonts';
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    informationContainer: {
      // paddingBottom: 60,
    },
    flex: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
    supplierBackgroundImgContainer: {
      width: '100%',
      height: '180px',
      position: 'relative',
    },
    supplierBackgroundImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    informationHeader: {
      position: 'relative',
      padding: 15,
      paddingBottom: 0,
    },
    supplierLogoContainer: {
      position: 'absolute',
      right: 15,
      top: -35,
      width: '70px',
      height: '70px',
      borderRadius: 5000,
      overflow: 'hidden',
      border: `${theme.colors.light} 1px solid`,
      boxSizing: 'border-box',
      backgroundColor: 'white',
    },
    supplierLogo: {
      width: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'relative',
      top: '-2px',
      left: '-2px',
    },
    supplierName: {
      fontWeight: getFonts('Medium'),
      fontSize: '24px',
      color: theme.colors.black87,
    },
    tabsContainer: {
      marginTop: 15,
      display: 'flex',
    },
    activeTab: {
      padding: '14px 24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    marker: {
      position: 'absolute',
      bottom: 4,
      left: 0,
      right: 0,
      height: 4,
      backgroundColor: theme.colors.lightBlue,
      margin: 'auto',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    inactiveTab: {
      padding: '14px 24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    activeTabText: {
      fontWeight: getFonts('Medium'),
      fontSize: '15px',
      color: theme.colors.black87,
    },
    inactiveTabText: {
      fontWeight: getFonts('Medium'),
      fontSize: '15px',
      color: theme.colors.black38,
    },
    section: {
      padding: 15,
      backgroundColor: theme.colors.light,
      marginTop: 15,
      borderRadius: 8,
    },
    description: {
      fontWeight: getFonts('Light'),
      fontSize: '13px',
      color: theme.colors.black87,
      lineHeight: 1.5,
      marginBottom: theme.units.spacing * 4,
    },
    sectionTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: '18px',
      color: theme.colors.black87,
    },
    featureContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
    },
    featureIconContainer: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
    featureIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    featureText: {
      fontWeight: getFonts('Medium'),
      fontSize: '14px',
      color: theme.colors.black87,
    },
    testimonialTitle: {
      fontWeight: getFonts('Medium'),
      fontSize: '18px',
      color: theme.colors.black87,
      padding: 15,
    },
    testimonialContainer: {
      'display': 'flex',
      'alignItems': 'center',
      'overflow': 'scroll',
      'paddingLeft': 15,
      'height': '100%',
      'scrollbarWidth': 'none',
      'msOverflowStyle': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    testimonialCard: {
      padding: '18px 15px',
      border: `${theme.colors.borderColor} 2px solid`,
      borderRadius: 6,
      backgroundColor: theme.colors.light,
      marginRight: 15,
      minWidth: 180,
    },
    starsContainer: {
      height: 20,
      marginBottom: 25,
    },
    stars: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    testimonalText: {
      fontWeight: getFonts('Medium'),
      fontSize: '13px',
      color: theme.colors.black87,
      marginBottom: 25,
      textAlign: 'center',
    },
    testimonalSource: {
      fontWeight: getFonts('Medium'),
      fontSize: '16px',
      color: theme.colors.black38,
      textAlign: 'center',
    },
    plansContainer: {
      padding: 15,
      paddingTop: 0,
    },
    popupContainer: {
      backgroundColor: theme.colors.light,
      height: '100%',
    },
    popupHeader: {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'start',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      height: '48px',
      backgroundColor: theme.colors.sortedPurple,
      position: 'relative',
    },
    popupLabel: {
      position: 'absolute',
      left: 0,
      right: 0,
      textAlign: 'center',
      margin: 'auto',
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      pointerEvents: 'none',
    },
    popupActionContainer: {
      width: 25,
      height: 25,
    },
    popupAction: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
    closeContainer: {
      position: 'absolute',
      top: 15,
      right: 15,
      textAlign: 'center',
      margin: 'auto',
      color: theme.colors.black87,
      fontWeight: getFonts('Medium'),
      fontSize: 15,
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      filter: 'invert(1)',
      cursor: 'pointer',
    },
    closeIcon: {
      width: '100%',
      height: '100%',
      objectFix: 'contain',
      objectPosition: 'center',
    },
  })();
};
