import {
  BottomNavValue,
  FetchDashboardRequest,
  LeaseResponse,
  SwitchTab,
  TabProps,
} from '../../models/dashboard/dashboard';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { OffersRequest, OffersResponse } from '../../models/offers/Offers';
import { TabParams } from '../../navigation/NavigationConstants';
import { Route } from '../../navigation/NavigationUtils';
import { StoreAction } from '../StoreHelper';

export enum DashboardActionTypes {
  FETCH_PENDING = 'FETCH_PENDING',
  FETCH_PENDING_REQUEST = 'FETCH_PENDING_REQUEST',
  FETCH_PENDING_SUCCESS = 'FETCH_PENDING_SUCCESS',
  FETCH_PENDING_ERROR = 'FETCH_PENDING_ERROR',

  FETCH_COMPLETED = 'FETCH_COMPLETED',
  FETCH_COMPLETED_REQUEST = 'FETCH_COMPLETED_REQUEST',
  FETCH_COMPLETED_SUCCESS = 'FETCH_COMPLETED_SUCCESS',
  FETCH_COMPLETED_ERROR = 'FETCH_COMPLETED_ERROR',

  REFRESH_REQUEST = 'REFRESH_REQUEST',
  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
  REFRESH_ERROR = 'REFRESH_ERROR',

  GET_PROPERTY_MAINTENANCE_REQUEST = 'GET_PROPERTY_MAINTENANCE_REQUEST',
  GET_PROPERTY_MAINTENANCE_SUCCESS = 'GET_PROPERTY_MAINTENANCE_SUCCESS',
  GET_PROPERTY_MAINTENANCE_ERROR = 'GET_PROPERTY_MAINTENANCE_ERROR',

  GET_OFFERS = 'GET_OFFERS',
  GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST',
  GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS',
  GET_OFFERS_ERROR = 'GET_OFFERS_ERROR',

  SWITCH_CTA = 'SWITCH_CTA',
  NONE_ONBOARDED = 'NONE_ONBOARDED',
  SELECTED_PROPERTY = 'SELECTED_PROPERTY',
  SHOW_TOAST = 'SHOW_TOAST',
  SWITCH_FOOTER_TAB = 'SWITCH_FOOTER_TAB',
  SELECT_NEW_PROPERTY = 'SELECT_NEW_PROPERTY',
}

export type DashboardActionPayload =
  | LeaseResponse[]
  | OffersRequest
  | OffersResponse[]
  | FetchDashboardRequest
  | MaintenanceResponse[]
  | string
  | boolean
  | number
  | Error
  | undefined
  | SwitchTab;

export type DashboardAction = StoreAction<DashboardActionTypes, DashboardActionPayload>;

export class DashboardActions {
  public static fetchPendingRequest(): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_PENDING_REQUEST,
      data: undefined,
    };
  }

  public static fetchPendingSuccess(response: LeaseResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_PENDING_SUCCESS,
      data: response,
    };
  }

  public static fetchPendingError(error: Error): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_PENDING_ERROR,
      data: error,
    };
  }

  public static fetchCompletedRequest(data?: FetchDashboardRequest): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_COMPLETED_REQUEST,
      data,
    };
  }

  public static fetchCompletedSuccess(response: LeaseResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_COMPLETED_SUCCESS,
      data: response,
    };
  }

  public static fetchCompletedError(error: Error): DashboardAction {
    return {
      type: DashboardActionTypes.FETCH_COMPLETED_ERROR,
      data: error,
    };
  }

  public static getPropertyMaintenance(propertyId: string): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROPERTY_MAINTENANCE_REQUEST,
      data: propertyId,
    };
  }

  public static getPropertyMaintenanceSuccess(data: MaintenanceResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROPERTY_MAINTENANCE_SUCCESS,
      data,
    };
  }

  public static getPropertyMaintenanceError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROPERTY_MAINTENANCE_ERROR,
      data,
    };
  }

  public static getOffersRequest(data: OffersRequest): DashboardAction {
    return {
      type: DashboardActionTypes.GET_OFFERS_REQUEST,
      data,
    };
  }

  public static getOffersSuccess(data: OffersResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.GET_OFFERS_SUCCESS,
      data,
    };
  }

  public static getOffersError(error: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_OFFERS_ERROR,
      data: error,
    };
  }

  public static refreshRequest(): DashboardAction {
    return {
      type: DashboardActionTypes.REFRESH_REQUEST,
      data: undefined,
    };
  }

  public static refreshSuccess(response: LeaseResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.REFRESH_SUCCESS,
      data: response,
    };
  }

  public static refreshError(error: Error): DashboardAction {
    return {
      type: DashboardActionTypes.REFRESH_ERROR,
      data: error,
    };
  }

  public static switchCta(isActive: boolean): DashboardAction {
    return {
      type: DashboardActionTypes.SWITCH_CTA,
      data: isActive,
    };
  }

  public static noneOnboarded(noneOnboarded: boolean): DashboardAction {
    return {
      type: DashboardActionTypes.NONE_ONBOARDED,
      data: noneOnboarded,
    };
  }

  public static setSelectedProperty(selected: number): DashboardAction {
    return {
      type: DashboardActionTypes.SELECTED_PROPERTY,
      data: selected,
    };
  }

  public static showToast(showToast: boolean): DashboardAction {
    return {
      type: DashboardActionTypes.SHOW_TOAST,
      data: showToast,
    };
  }

  public static switchFooterTab(tabName: Route, tabProps?: TabParams): DashboardAction {
    return {
      type: DashboardActionTypes.SWITCH_FOOTER_TAB,
      data: { tabName, tabProps },
    };
  }

  public static selectNewProperty(data?: boolean): DashboardAction {
    return {
      type: DashboardActionTypes.SELECT_NEW_PROPERTY,
      data,
    };
  }
}
