/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    twoColumn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.units.spacing * 4,
    },
    buttonContainer: {
      marginTop: theme.units.spacing * 8,
      marginBottom: theme.units.spacing * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      minWidth: '200px !important',
      height: 35,
      marginLeft: theme.units.spacing * 4,
    },
  })();
};
