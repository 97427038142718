import { SelectPaymentParams } from '../../../navigation/NavigationConstants';
import { navigate } from '../../../navigation/NavigationUtils';

export const handlePaymentTypeSuccess = (
  isPaymentMethods: boolean,
  matchParams: SelectPaymentParams,
) => {
  const { propertyId, serviceType, agencyName, paymentRefId, serviceAccountId } = matchParams;

  if (!isPaymentMethods) {
    navigate('addPayment', { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName });
  }
};

export const openAddPayments = (matchParams: SelectPaymentParams) => {
  const { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName } = matchParams;

  navigate('addPayment', { propertyId, serviceType, paymentRefId, serviceAccountId, agencyName });
};
