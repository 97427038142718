import { LeaseResponse } from '../../models/dashboard/dashboard';
import { MaintenanceResponse } from '../../models/landlord/LandlordProperty';
import { OffersRequest, OffersResponse } from '../../models/offers/Offers';
import ApiService from '../ApiService';
import { QueryPath } from '../QueryPath.data';
import { ServiceType } from '../ServiceType.data';

class DashboardService {
  public fetchProperties(completed: boolean): Promise<LeaseResponse[]> | LeaseResponse[] {
    const endpoint = completed ? ServiceType.dashboard : ServiceType.pendingTenancy;
    const service: ApiService = new ApiService(endpoint);
    // filter the property based on Agency code for NAX and TA
    const path: QueryPath = {
      query: {
        filterByAgencyCode: 'STNDALN',
      },
    };
    return service.get({});
  }

  public getPropertyMaintenance(
    propertyId: string,
  ): Promise<MaintenanceResponse[]> | MaintenanceResponse[] {
    const service = new ApiService(ServiceType.request);
    const path: QueryPath = {
      query: { property: propertyId, status: 'ALL' },
    };
    return service.get<MaintenanceResponse[]>(path);
  }

  public getOffers(payload: OffersRequest): Promise<OffersResponse[]> {
    const service = new ApiService(ServiceType.offers);
    const path: QueryPath = {
      query: { branchId: payload.branchId },
      route: ['offers'],
    };
    return service.get<OffersResponse[]>(path);
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
