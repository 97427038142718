import {
  additionalProfilePercentage,
  getProfilePercentage,
  getSecondaryProfilePercentage,
  isAdditionalProfileDetailsFilled,
  verifyDeclarationDetails,
  verifyProfileDetails,
  verifySeconadaryDetails,
} from '../../helpers/ProfileVerifyHelper';
import { DependentProfile } from '../../models/masterProfile/MasterProfile';
import { ApplicantProfile, PostFormData } from '../../models/masterProfile/MyProfileFormState';
import { LandingScreenState } from '../../store/state/LandingScreenState';
import { LABELS } from './ManagedProfilesConstants';

export interface Status {
  statusText: string;
  statusClass: string;
  percentage: number;
}

export const myProfileStatus = (myProfileDetails: ApplicantProfile): Status => {
  const percentage = getProfilePercentage(myProfileDetails);
  if (verifyProfileDetails(myProfileDetails))
    return { statusText: LABELS.COMPLETE, statusClass: 'completeLabel', percentage };
  else if (verifyDeclarationDetails(myProfileDetails))
    return { statusText: LABELS.DECLARATION_REQUIRED, statusClass: 'incompleteLabel', percentage };
  else return { statusText: LABELS.INCOMPLETE, statusClass: 'incompleteLabel', percentage };
};

export const secondaryProfileStatus = (
  secondaryApplicantProfiles: ApplicantProfile[],
  tenant: ApplicantProfile,
  key: number,
): Status => {
  const percentage = getSecondaryProfilePercentage(secondaryApplicantProfiles, key);

  if (!(tenant.personalDetails && tenant.personalDetails.isManaged))
    return { statusText: LABELS.APPLYING_SEPARATELY, statusClass: 'completeLabel', percentage };
  else if (verifySeconadaryDetails(secondaryApplicantProfiles, key))
    return { statusText: LABELS.COMPLETE, statusClass: 'completeLabel', percentage };
  else if (verifyDeclarationDetails(tenant))
    return { statusText: LABELS.DECLARATION_REQUIRED, statusClass: 'incompleteLabel', percentage };
  else return { statusText: LABELS.INCOMPLETE, statusClass: 'incompleteLabel', percentage };
};

export const dependentProfileStatus = (tenant: DependentProfile): Status => {
  const percentage = additionalProfilePercentage(tenant);
  if (isAdditionalProfileDetailsFilled(tenant))
    return { statusText: LABELS.COMPLETE, statusClass: 'completeLabel', percentage };
  else return { statusText: LABELS.INCOMPLETE, statusClass: 'incompleteLabel', percentage };
};

export const addNewManagedProfile = (
  tenantDetails: LandingScreenState,
  action: (result: PostFormData, key: number) => void,
) => {
  const key =
    tenantDetails &&
    tenantDetails.tenantDetails &&
    tenantDetails.tenantDetails.otherApplicants.length
      ? tenantDetails.tenantDetails.otherApplicants[
          tenantDetails.tenantDetails.otherApplicants.length - 1
        ].key + 1
      : 0;
  const result = {
    key,
    email: null,
    emergencyContactEmail: null,
    emergencyContactMobile: null,
    emergencyContactName: null,
    emergencyContactRelationship: null,
    firstName: null,
    isChild: false,
    isManaged: true,
    lastName: null,
    mobile: null,
    selectedDate: null,
    selectedDateOfBirth: null,
    title: null,
  } as any;

  action(result, key);
};
