import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CheckoutPersonalDetailsStyles';
import { LABELS } from './CheckoutPersonalDetailsConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack } from '../../navigation/NavigationUtils';
import { PersonalDetails } from '../../models/checkout/Checkout';
import Button from '../../components/button/Button';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckoutActions } from '../../store/actions/CheckoutActions';
import AccountSection from '../checkoutPersonalDetails/components/accountSection/AccountSection';
import { hasAnyPayments } from '../checkoutFinalize/CheckoutFinalizeUtils';
import { useCart } from '../../helpers/CheckoutHelper';
import { isPersonalDetailsCompleted } from './components/accountSection/AccountSectionUtils';
import { essentialServices } from '../checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import { ServiceTypes } from '../../models/services/services';
import CartItem from '../cart/components/cartItem/CartItem';

interface CheckoutPersonalDetailsProps {
  personalDetails: PersonalDetails;
  updatePersonalDetails: (data: PersonalDetails) => void;
}

const CheckoutPersonalDetails: FC<CheckoutPersonalDetailsProps> = ({
  personalDetails,
  updatePersonalDetails,
}) => {
  const classes = useStyles();
  const { cartItems } = useCart();
  const savePersonalDetails = (personalDetails: PersonalDetails) => {
    updatePersonalDetails(personalDetails);
  };
  const hasPayments = hasAnyPayments(cartItems);

  const onNext = () => {
    if (hasPayments) {
      navigate('checkoutFinalize');
    } else {
      navigate('checkoutSummary');
    }
  };

  const isPersonalInfoCompleted = isPersonalDetailsCompleted(personalDetails);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link" size="l">
            Confirm service details
          </Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.disabledTitle} style={{ marginTop: 16 }}>
              {LABELS.CONFIRM_DETAILS}
            </div>
            <div className={classes.title} style={{ marginBottom: 32, borderBottom: 'none' }}>
              {LABELS.PERSONAL_DETAILS}
            </div>
            <div className={classes.section}>
              <AccountSection
                setPersonalDetails={(personalDetails: PersonalDetails) =>
                  savePersonalDetails(personalDetails)
                }
                validate={false}
              />
              <div className={classes.buttonContainer}>
                <Button
                  disabled={!isPersonalInfoCompleted}
                  onPress={() => onNext()}
                  parentStyles={classes.button}
                >
                  {LABELS.NEXT}
                </Button>
              </div>
            </div>
            {hasPayments && <div className={classes.disabledTitle}>{LABELS.PAYMENT}</div>}
            <div className={classes.disabledTitle}>{LABELS.SUMMARY}</div>
          </div>
          <div className={classes.secondaryContainer}>
            <div className={classes.summaryContainer}>
              <div className={classes.summaryTitle}>{LABELS.SUMMARY}</div>
              <div className={classes.cards}>
                {!!cartItems.length && (
                  <>
                    {!!cartItems.length &&
                      cartItems.map((cartItem, i) => (
                        <CartItem
                          hideAction
                          hidePrice
                          cartItem={cartItem}
                          key={`${cartItem.type}`}
                          handleTap={() => null}
                          handleAction={() => null}
                          quote={!essentialServices.includes(cartItem.type as ServiceTypes)}
                          showTick={isPersonalDetailsCompleted(personalDetails)}
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  personalDetails: state.checkout.personalDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePersonalDetails: (data: PersonalDetails) =>
    dispatch(CheckoutActions.updatePersonalDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPersonalDetails);
