import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';
import { loadingAnimation } from '../../theme/GlobalStyles';

const fontFamily = getFonts();

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    propertyContainer: {
      borderRadius: theme.units.borderRadius,
      borderColor: theme.colors.borderColorLight,
      borderWidth: 1,
      borderStyle: 'solid',
      padding: theme.units.spacing * 4,
      // width: 340,
      // minWidth: 340,
      flex: 1,
      marginRight: theme.units.spacing * 3,
      backgroundColor: theme.colors.light,
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      minHeight: 55,
    },
    fullWidth: {
      marginTop: theme.units.spacing * 3,
      marginRight: 0,
      width: 'calc(100% - 32px)',
    },
    selectedBorder: {
      borderColor: theme.colors.buttonPrimary,
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    greyBorder: {
      border: `${theme.colors.borderColorLight} 1px solid !important`,
    },
    titleContainer: {
      marginRight: 'auto',
    },
    address: {
      fontSize: 20,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
    },
    address2: {
      marginTop: 4,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black38,
    },
    agencyLogo: {
      width: 42,
      height: 42,
      borderRadius: 5000,
      borderWidth: 1,
      borderColor: theme.colors.borderColor,
    },
    house: {
      marginLeft: 6,
      width: 50,
      height: 50,
      resizeMode: 'contain',
    },
    bottomRow: {
      position: 'absolute',
      // marginTop: 12,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: 'white',
      width: 20,
      height: 20,
      borderRadius: 500,
      bottom: 8,
      right: 8,
    },
    toggleIcon: {
      width: 20,
      height: 20,
      resizeMode: 'contain',
    },
    addPropertyContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flex: 1,
    },
    addPropertyText: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black,
      marginLeft: 12,
    },
    addPropertyIcon: {
      width: theme.units.iconSize,
      height: theme.units.iconSize,
    },
    photoContainer: {
      width: 55,
      height: 55,
      borderRadius: 500,
      marginRight: theme.units.spacing * 4,
      overflow: 'hidden',
    },
    photo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    loading: {
      border: 'none',
      ...loadingAnimation,
    },
  })();
};
