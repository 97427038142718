import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './MaintainScreenStyles';
import { LABELS, Tab, initialTags } from './MaintainScreenConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { findExtraServiceInfo, useDashboardServices } from '../../helpers/ServiceInfoHelper';
import { TagBarItem } from '../../components/tagBar/TagBarConstants';
import { areAllTabsOff, isTabActive } from '../../components/tagBar/TagBarUtils';
import TagBar from '../../components/tagBar/TagBar';
import Button from '../../components/button/Button';
import Box from '../../components/box/Box';
import { onboardingScreenValidStates } from '../../constants/AccountStatus';
import { ServiceTypes, Services } from '../../models/services/services';
import { filterServicesByStatus } from '../home/components/dashboard/DashboardUtils';
import Supplier from '../../components/supplier/Supplier';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { DashboardState } from '../../store/state/DashboardState';
import accordian from '../../assets/navigation/accordian.png';
import blackWrench from '../../assets/marketplace/dark/trades.png';

interface MaintainScreenProps {
  dashboardState: DashboardState;
}

const MaintainScreen: FC<MaintainScreenProps> = ({
  dashboardState: { onboardedProperties, selectedProperty },
}) => {
  const classes = useStyles();
  const { property, otherServices, newServices } = useDashboardServices();
  const [tags, setTags] = useState<TagBarItem[]>(initialTags(otherServices));

  useEffect(() => {
    setTags(initialTags(otherServices));
  }, [property]);

  const allTagsOff = useMemo<boolean>(() => {
    return areAllTabsOff(tags);
  }, [tags]);

  const isMaintenanceActive = useMemo<boolean>(() => {
    if (property) {
      return !!onboardedProperties![selectedProperty].property!.isMaintenanceAllowed;
    }
    return false;
  }, [property]);

  const renderServiceType = (service: Services) => {
    const suppliers = findExtraServiceInfo(
      service,
      onboardedProperties![selectedProperty].availableServices,
    ).Suppliers;
    return (
      <div className={classes.suppliersRoot}>
        <div className={classes.suppliersTitle}>{service.serviceType}</div>
        {suppliers.map((provider, index) => (
          <Supplier
            service={service}
            suppliers={suppliers}
            provider={provider}
            index={index}
            onboardedProperties={onboardedProperties}
            selectedProperty={selectedProperty}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="title" size="xxl" parentStyles={classes.title}>
              {LABELS.TITLE}
            </Text>
            {tags.length ? (
              <>
                <TagBar tags={tags} handlePress={setTags} togglable />
                <Box lightBorder parentStyles={classes.box}>
                  {(isTabActive(tags, Tab.Trades) || allTagsOff) && isMaintenanceActive && (
                    <div className={classes.suppliersRoot}>
                      <div className={classes.suppliersTitle}>Trades</div>
                      <div className={classes.requestRow} onClick={() => navigate('maintenance')}>
                        <div className={classes.iconContainer}>
                          <img className={classes.icon} src={blackWrench} />
                        </div>
                        <div className={classes.titleContainer}>
                          <div className={classes.rowTitle}>Maintenance</div>
                          <div className={classes.description}>Request a quote</div>
                        </div>
                        <img className={classes.accordian} src={accordian} />
                      </div>
                    </div>
                  )}
                  {!!otherServices.length && (
                    <>
                      {(isTabActive(tags, Tab.Cleaning) || allTagsOff) &&
                        filterServicesByStatus(onboardingScreenValidStates, otherServices, [
                          ServiceTypes.Cleaning,
                        ]).map((service) => <>{renderServiceType(service)}</>)}
                      {(isTabActive(tags, Tab.Gardening) || allTagsOff) &&
                        filterServicesByStatus(onboardingScreenValidStates, otherServices, [
                          ServiceTypes.Gardening,
                        ]).map((service) => <>{renderServiceType(service)}</>)}
                    </>
                  )}
                </Box>
              </>
            ) : (
              <div className={classes.noServicesContainer}>
                <div className={classes.sorryText}>{LABELS.SORRY}</div>
                <Button onPress={() => navigateBack()}>{LABELS.BACK}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MaintainScreen);
