import { getFonts } from '../../../../helpers/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    planContainer: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      borderRadius: theme.units.borderRadius,
      marginTop: theme.units.spacing * 4,
      overflow: 'hidden',
    },
    activePlan: {
      border: `${theme.colors.buttonPrimary} 1px solid`,
    },
    planContent: {
      paddingTop: theme.units.spacing * 4,
      paddingBottom: theme.units.spacing * 4,
    },
    planHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.units.spacing * 4,
      paddingRight: theme.units.spacing * 4,
    },
    logoContainer: {
      height: '50px',
      width: '50px',
      borderRadius: 5000,
      overflow: 'hidden',
      cursor: 'pointer',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    titleContainer: {
      marginLeft: theme.units.spacing * 4,
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingBottom: 3,
    },
    title: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.buttonPrimary,
      marginBottom: theme.units.spacing,
      cursor: 'pointer',
    },
    description: {
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      color: theme.colors.black87,
    },
    priceContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginRight: 'auto',
    },
    from: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black38,
      opacity: 0.6,
      marginBottom: theme.units.spacing,
    },
    priceWrapper: {
      display: 'flex',
      alignItems: 'baseline',
    },
    price: {
      fontWeight: getFonts('Medium'),
      fontSize: 20,
      color: theme.colors.buttonPrimary,
    },
    uom: {
      fontWeight: getFonts('Medium'),
      fontSize: '12px',
      color: theme.colors.buttonPrimary,
    },
    infoText: {
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.black60,
      marginTop: 15,
      paddingLeft: 15,
      paddingRight: 15,
      opacity: 0.6,
      lineHeight: 1.5,
    },
    actionContainer: {
      backgroundColor: theme.colors.light,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: theme.units.spacing * 4,
      marginRight: theme.units.spacing * 4,
      paddingTop: 10,
      paddingBottom: 10,
      borderTop: `${theme.colors.borderColorLight} 1px solid`,
      cursor: 'pointer',
    },
    actionContainerSelected: {
      borderTop: `rgba(211, 211, 211, 0.6) 2px solid`,
      backgroundColor: 'unset',
      cursor: 'pointer',
    },
    actionText: {
      fontWeight: getFonts('Medium'),
      fontSize: '16px',
      color: theme.colors.black87,
      marginLeft: 'auto',
    },
    actionIconContainer: {
      width: 24,
      height: 24,
      marginLeft: '15px',
    },
    actionIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    tick: {
      width: 20,
      height: 20,
      marginLeft: 15,
    },
    compareContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
      padding: '6px 16px',
      backgroundColor: theme.colors.light,
      borderRadius: 6,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      cursor: 'pointer',
      marginRight: theme.units.spacing * 3,
      '&:last-child': {
        marginRight: 0,
      },
    },
    activeCompare: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.light,
      padding: '6px 16px',
      backgroundColor: theme.colors.buttonPrimary,
      borderRadius: 6,
      border: `${theme.colors.buttonPrimary} 1px solid`,
      cursor: 'pointer',
      marginRight: theme.units.spacing * 3,
      '&:last-child': {
        marginRight: 0,
      },
    },
    planInformationContainer: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
      cursor: 'pointer',
    },
    infoIcon: {
      width: theme.units.iconSize * 0.8,
      height: theme.units.iconSize * 0.8,
      objectFit: 'contain',
      objectPosition: 'center',
      ...actionGreen,
      marginRight: theme.units.spacing * 2,
    },
    planInformationText: {
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.buttonPrimary,
    },
  })();
};
