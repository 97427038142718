import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/RootReducer';
import { GetRentOnboardingResponse } from '../models/rentOnboarding/rentOnboarding';

export const useGetLeaseData = (): GetRentOnboardingResponse | undefined => {
  const rentServiceReducer = useSelector((state: ApplicationState) => state.rentOnboarding);
  const { propertyId, services } = rentServiceReducer;

  let lease = useMemo<GetRentOnboardingResponse | undefined>(() => {
    if (!propertyId && services && services.length > 0) {
      return services[0];
    } else if (propertyId && services && services.length > 0) {
      return services.findIndex((serviceObj) => serviceObj.property!.id === propertyId) !== -1
        ? services.find((serviceObj) => serviceObj.property!.id === propertyId)!
        : services[0];
    } else {
      return undefined;
    }
  }, [propertyId, services]);

  return lease;
};
