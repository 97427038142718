import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  container: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightOrange,
    marginBottom: theme.units.spacing * 8,
  },
  title: {},
  subtitle: {},
  button: {
    backgroundColor: theme.colors.darkOrange,
    borderColor: theme.colors.darkOrange,
    marginTop: theme.units.spacing * 4,
  },
});
