import React, { FC } from 'react';
import { useStyles } from './TileStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { Ribbon } from './TileConstants';
import addIcon from '../../assets/plus.png';

interface TileProps {
  icon?: string;
  title?: string;
  subtitle?: string;
  active?: boolean;
  loading?: boolean;
  ribbon?: Ribbon;
  add?: boolean;
  status?: string;
  handlePress: () => void;
}

const Tile: FC<TileProps> = ({
  icon,
  title,
  subtitle,
  active,
  loading,
  ribbon,
  add,
  status,
  handlePress,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNameGenerator([
        classes.tileContainer,
        active && classes.activeContainer,
        ribbon === Ribbon.OFFER && classes.ribbonBorderGreen,
        ribbon === Ribbon.NEW && classes.ribbonBorder,
        loading && classes.loading,
      ])}
      onClick={handlePress}
    >
      {ribbon !== undefined && (
        <div
          className={classNameGenerator([
            classes.ribbon,
            ribbon === Ribbon.OFFER && classes.ribbonGreen,
          ])}
        >
          <div className={classes.ribbonText}>{ribbon}</div>
        </div>
      )}
      {!loading && (
        <>
          {add ? (
            <div className={classes.addServicesContainer}>
              <div className={classes.servicesText}>{title}</div>
              <img src={addIcon} className={classes.addIcon} alt="icon" />
            </div>
          ) : (
            <div className={classes.tileWrapper}>
              <div className={classes.titleContainer}>
                <div className={classNameGenerator([classes.title, active && classes.activeTitle])}>
                  {title}
                </div>
                {subtitle && (
                  <div
                    className={classNameGenerator([
                      classes.subtitle,
                      active && classes.activeSubtitle,
                    ])}
                  >
                    {subtitle.replace(/([A-Z][A-Z\-]*)/g, ' $1').trim()}
                  </div>
                )}
              </div>
              <div className={classes.bottomRow}>
                <div
                  className={classNameGenerator([
                    classes.iconCircle,
                    active && classes.activeIconCircle,
                  ])}
                >
                  <img src={icon} className={classes.icon} alt="icon" />
                </div>
                {active ? (
                  <div className={classes.status}>{status || 'Connected'}</div>
                ) : (
                  <img src={addIcon} className={classes.addIcon} alt="icon" />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Tile;
