import React, { FC } from 'react';
import { useStyles } from './InputMultiCheckboxStyles';
import { SelectValues } from '../inputSelect/InputSelectConstants';
import { isEmpty } from '../../helpers/Validation';
import { theme } from '../../theme/Theme';
import { Checkbox } from '@mui/material';

interface InputMultiCheckboxProps {
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  value: string[];
  values: SelectValues[];
  showRequiredError?: boolean;
  setValue: (value: string[]) => void;
}

const InputMultiCheckbox: FC<InputMultiCheckboxProps> = ({
  title,
  mandatory,
  touched,
  error,
  value,
  values,
  showRequiredError,
  setValue,
}) => {
  const classes = useStyles();

  const handleClick = (index: number) => {
    if (value.includes(values[index].value as string)) {
      const updatedSelected = value.filter(
        (selectedIndex) => selectedIndex !== (values[index].value as string),
      );
      setValue(updatedSelected);
    } else {
      const updatedSelected = [...value, values[index].value as string];
      setValue(updatedSelected);
    }
  };

  return (
    <>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <div className={classes.checkboxGroup}>
        {values.map((v, i) => (
          <div key={`checkbox_${i}`} className={classes.checkboxRow} onClick={() => handleClick(i)}>
            <Checkbox
              style={{
                flexShrink: 0,
                paddingLeft: 0,
              }}
              sx={{
                color: theme.colors.borderColor,
                '&.Mui-checked': {
                  color: theme.colors.buttonPrimary,
                },
              }}
              checked={value.includes(v.value as string)}
              onChange={() => handleClick(i)}
            />
            <div className={classes.checkboxText}>{v.display}</div>
          </div>
        ))}
      </div>
    </>
  );
};
export default InputMultiCheckbox;
