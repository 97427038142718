/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import {
  AddressComponents,
  SetOnboardingPropertyDetails,
} from '../../../../models/property/property';
import { useStyles } from './ManualEntryStyles';
import { LABELS, validationSchema } from './ManualEntryConstants';
import { useFormik } from 'formik';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button/Button';

interface ManualEntryProps {
  address: AddressComponents;
  setPropertyDetails: (data: SetOnboardingPropertyDetails) => void;
  goToNextPage: () => void;
}

const ManualEntry: FC<ManualEntryProps> = ({ address, setPropertyDetails, goToNextPage }) => {
  const classes = useStyles();
  const { country, streetNumber, suburb, streetType, streetName, unitNumber, postcode, state } =
    address;

  const formik = useFormik({
    initialValues: {
      unitNumber: unitNumber || '',
      streetNumber: streetNumber || '',
      streetName: streetName || '',
      streetType: streetType || '',
      suburb: suburb || '',
      postcode: postcode || '',
      state: state || '',
      country: country || 'Australia',
    },
    onSubmit: (values) => {
      setPropertyDetails({ field: 'address', value: values });
      goToNextPage();
    },
    validationSchema,
  });

  return (
    <>
      <div className={classes.twoColumn}>
        <Input
          placeholder={LABELS.UNIT}
          key={LABELS.UNIT}
          value={formik.values.unitNumber}
          setValue={formik.handleChange('unitNumber')}
          onBlur={() => formik.setFieldTouched('unitNumber')}
          touched={formik.touched.unitNumber}
          error={formik.errors.unitNumber}
          title={LABELS.UNIT}
          mandatory
          big
        />
        <Input
          placeholder={LABELS.STREET_NUMBER}
          key={LABELS.STREET_NUMBER}
          value={formik.values.streetNumber}
          setValue={formik.handleChange('streetNumber')}
          onBlur={() => formik.setFieldTouched('streetNumber')}
          touched={formik.touched.streetNumber}
          error={formik.errors.streetNumber}
          title={LABELS.STREET_NUMBER}
          mandatory
          big
        />
      </div>

      <Input
        placeholder={LABELS.STREET_NAME}
        key={LABELS.STREET_NAME}
        value={formik.values.streetName}
        setValue={formik.handleChange('streetName')}
        onBlur={() => formik.setFieldTouched('streetName')}
        touched={formik.touched.streetName}
        error={formik.errors.streetName}
        title={LABELS.STREET_NAME}
        mandatory
        big
      />
      <Input
        placeholder={LABELS.STREET_TYPE}
        key={LABELS.STREET_TYPE}
        value={formik.values.streetType}
        setValue={formik.handleChange('streetType')}
        onBlur={() => formik.setFieldTouched('streetType')}
        touched={formik.touched.streetType}
        error={formik.errors.streetType}
        title={LABELS.STREET_TYPE}
        mandatory
        big
      />
      <Input
        placeholder={LABELS.SUBURB}
        key={LABELS.SUBURB}
        value={formik.values.suburb}
        setValue={formik.handleChange('suburb')}
        onBlur={() => formik.setFieldTouched('suburb')}
        touched={formik.touched.suburb}
        error={formik.errors.suburb}
        title={LABELS.SUBURB}
        mandatory
        big
      />
      <div className={classes.twoColumn}>
        <Input
          placeholder={LABELS.POSTCODE}
          key={LABELS.POSTCODE}
          value={formik.values.postcode}
          setValue={formik.handleChange('postcode')}
          onBlur={() => formik.setFieldTouched('postcode')}
          touched={formik.touched.postcode}
          error={formik.errors.postcode}
          title={LABELS.POSTCODE}
          mandatory
          big
        />
        <Input
          placeholder={LABELS.STATE}
          key={LABELS.STATE}
          value={formik.values.state}
          setValue={formik.handleChange('state')}
          onBlur={() => formik.setFieldTouched('state')}
          touched={formik.touched.state}
          error={formik.errors.state}
          title={LABELS.STATE}
          mandatory
          big
        />
      </div>
      <Input
        placeholder={LABELS.COUNTRY}
        key={LABELS.COUNTRY}
        value={formik.values.country}
        setValue={formik.handleChange('country')}
        onBlur={() => formik.setFieldTouched('country')}
        touched={formik.touched.country}
        error={formik.errors.country}
        title={LABELS.COUNTRY}
        mandatory
        big
      />
      <div className={classes.buttonContainer}>
        <Button onPress={() => formik.handleSubmit()} parentStyles={classes.button}>
          Next
        </Button>
      </div>
    </>
  );
};

export default ManualEntry;
