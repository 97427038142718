import React, { FC, useEffect, useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useStyles } from './PropertyInfoStyles';
import { LABELS } from './PropertyInfoConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { useDashboardServices } from '../../helpers/ServiceInfoHelper';
import houseAvatar from '../../assets/property-mock.jpg';
import PropertyCard from '../../components/propertyCard/PropertyCard';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DashboardState } from '../../store/state/DashboardState';
import { getLabel } from '../../constants/Strings';
import MultipleImageComponent from '../../components/multipleImageComponent/MultipleImageComponent';
import moment from 'moment';
import { getDarkServiceTypeIcon } from '../home/components/dashboard/DashboardConstants';
import tick from '../../assets/tick-active.png';
import plus from '../../assets/plus.png';
import ICON_TOOL from '../../assets/tool.png';
import ICON_TOOL_RED from '../../assets/tool-red.png';
import { User } from '../../models/user/user';
import { stringToHslColor } from '../../helpers/GravatarHelper';
import { classNameGenerator } from '../../theme/GlobalStyles';
import documentIcon from '../../assets/landlord/document.png';
import downloadIcon from '../../assets/landlord/download.png';
import { DownloadHelper } from '../../helpers/DownloadHelper';
import accordian from '../../assets/navigation/accordian.png';

interface PropertyInfoProps {
  dashboardState: DashboardState;
}

const PropertyInfo: FC<PropertyInfoProps> = ({ dashboardState: { selectedProperty } }) => {
  const classes = useStyles();
  const { property, openServices, cimetPurchasedServices } = useDashboardServices();

  const renderAgent = (agent: User, title: string, role: string) => {
    const fullName = `${agent.name} ${agent.surname}`;
    return (
      <div className={classes.cardContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.gravatar}
            style={{ backgroundColor: stringToHslColor(`${fullName}`, 70, 70) }}
          >
            <div className={classes.gravatarText}>
              {fullName.split(' ')[0].charAt(0)}
              {fullName.split(' ')[1].charAt(0)}
            </div>
          </div>
          <div className={classes.textContainer}>
            <div className={classes.text}>{fullName}</div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.key}>Phone</div>
          <div className={classes.value}>{agent.phone}</div>
        </div>
        <div className={classNameGenerator([classes.row, classes.noBorder])}>
          <div className={classes.key}>Email </div>
          <div className={classes.value}>{agent.email}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.headerBottom}>
              <img
                src={
                  // property!.photos && !!property!.photos.length
                  //   ? property!.photos[0].link
                  //   :
                  houseAvatar
                }
                className={classes.image}
              />
            </div>
            <div className={classes.contentContainer}>
              <PropertyCard
                property={property}
                selected
                greyBorder
                addProperty={false}
                index={selectedProperty}
                fullWidth
                snapTo={() => null}
              />
              {property!.type && property!.propertyClass && (
                <div className={classes.cardContainer}>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{LABELS.PROPERTY_DETAILS}</div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>Property type</div>
                    <div className={classes.value}>{property!.type}</div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>You are the </div>
                    <div className={classes.value}>
                      {getLabel(property!.propertyClass as string)}
                    </div>
                  </div>
                  {property!.photos && property!.photos.length > 0 && (
                    <div>
                      <div style={{ marginTop: 12 }}>
                        <div className={classes.value}>Attachments</div>
                      </div>
                      <MultipleImageComponent
                        images={
                          property!.photos && property!.photos.length > 0
                            ? property!.photos.map((photo) => ({
                                id: `${photo.id}`,
                                name: '',
                                link: photo.link,
                                contentType: '',
                              }))
                            : []
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {property!.extendedData && property!.extendedData.salesProperty && (
                <div className={classes.cardContainer}>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{LABELS.CONTRACT_DETAILS}</div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>Contract date</div>
                    <div className={classes.value}>
                      {property!.extendedData.contractDetails.contractDate
                        ? moment(property!.extendedData.contractDetails.contractDate).format(
                            'DD/MM/YYYY',
                          )
                        : ''}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>Price</div>
                    <div className={classes.value}>
                      {`$${property!.extendedData.contractDetails.price}`}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>Unconditional date</div>
                    <div className={classes.value}>
                      {property!.extendedData.contractDetails.unconditionalDate
                        ? moment(property!.extendedData.contractDetails.unconditionalDate).format(
                            'DD/MM/YYYY',
                          )
                        : ''}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.key}>Settled date</div>
                    <div className={classes.value}>
                      {property!.extendedData.contractDetails.settledDate
                        ? moment(property!.extendedData.contractDetails.settledDate).format(
                            'DD/MM/YYYY',
                          )
                        : ''}
                    </div>
                  </div>
                </div>
              )}
              {(!!openServices.length || !!cimetPurchasedServices.length) && (
                <div className={classes.cardContainer}>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{LABELS.ACTIVE}</div>
                    <div
                      onClick={() => {
                        navigate('home', { index: 0 });
                      }}
                    >
                      <div className={classes.link}>See all</div>
                    </div>
                  </div>
                  {openServices.map((service) => (
                    <div className={classes.row}>
                      <img
                        src={getDarkServiceTypeIcon(service.serviceType)}
                        className={classes.icon}
                      />
                      <div className={classes.serviceType}>{service.serviceType}</div>
                      <img src={tick} className={classes.tick} />
                    </div>
                  ))}
                  {cimetPurchasedServices.map((service) => (
                    <div className={classes.row}>
                      <img
                        src={getDarkServiceTypeIcon(service.serviceType)}
                        className={classes.icon}
                      />
                      <div className={classes.serviceType}>{service.serviceType}</div>
                      <img src={tick} className={classes.tick} />
                    </div>
                  ))}
                  <div
                    className={classes.row}
                    onClick={() => {
                      navigate('browse');
                    }}
                  >
                    <img src={plus} className={classes.icon} />
                    <div className={classes.addMore}>Add more</div>
                  </div>
                </div>
              )}
              {property!.managingAgent && (
                <>{renderAgent(property!.managingAgent, LABELS.REAL, 'Property manager')}</>
              )}
              {/* {property!.leasingAgent && (
                <>{renderAgent(property!.leasingAgent, 'Agent', 'Leasing agent')}</>
              )} */}
              {(property!.isMaintenanceAllowed || !!property!.emergencyMaintenanceLink) && (
                <div className={classes.cardContainer}>
                  <div className={classes.titleContainer}>
                    <div className={classes.title}>{LABELS.MAINTAIN}</div>
                  </div>
                  {property!.isMaintenanceAllowed && (
                    <div
                      onClick={() => navigate('maintenance')}
                      className={classes.row}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={ICON_TOOL} className={classes.icon} />
                      <div className={classes.serviceType}>Create maintenance request</div>
                      <img src={accordian} className={classes.accordian} />
                    </div>
                  )}
                  {!!property!.emergencyMaintenanceLink && (
                    <div
                      onClick={() => navigate('emergencyMaintenance')}
                      className={classes.row}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={ICON_TOOL_RED} className={classes.icon} />
                      <div className={classes.serviceType}>Emergency maintenance</div>
                      <img src={accordian} className={classes.accordian} />
                    </div>
                  )}
                </div>
              )}
              {property!.extendedData &&
                property!.extendedData.salesProperty &&
                property!.extendedData.documents &&
                property!.extendedData.documents.length > 0 && (
                  <div className={classes.cardContainer}>
                    <div className={classes.titleContainer}>
                      <div className={classes.title}>{LABELS.DOCUMENTS}</div>
                    </div>
                    {property!.extendedData.documents.map((file) => (
                      <div
                        className={classes.documentRow}
                        onClick={() => DownloadHelper.downloadLink(file.url, file.title || '')}
                      >
                        <img src={documentIcon} className={classes.documentIcon} />
                        <div className={classes.documentTitle}>
                          {file.title.length > 30
                            ? `${file.title.substring(0, 30)}...`
                            : file.title}
                        </div>
                        <img src={downloadIcon} className={classes.documentIcon} />
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  dashboardState: state.dashboardState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInfo);
