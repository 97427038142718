import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './SupportStyles';
import { LABELS, naxSupportUrls, sortedSupportUrls, switchSupportUrls } from './SupportConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import { navigate, navigateBack, resetNavigation } from '../../navigation/NavigationUtils';
import { ApplicationState } from '../../store/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Box from '../../components/box/Box';

import accordian from '../../assets/navigation/accordian.png';
import { ThemeKey, getCurrentThemeKey } from '../../theme/Theme';

interface SupportProps {
  isGuest: boolean;
}

const Support: FC<SupportProps> = ({ isGuest }) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  const renderListItem = (value: string, onPress?: () => void) => {
    return (
      <div className={classes.listItem} onClick={onPress}>
        <div className={classes.textValue}>{value}</div>
        <img className={classes.accordian} src={accordian} alt="arrow" />
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="title" size="xxl" parentStyles={classes.title}>
              Support
            </Text>
            <Box lightBorder parentStyles={classes.box}>
              {!isGuest &&
                themeKey === ThemeKey.SORTED &&
                renderListItem(LABELS.CHAT, () => (window as any).Intercom('show'))}
              {themeKey === ThemeKey.NAX
                ? naxSupportUrls.map((support) =>
                    renderListItem(support.title, () => window.open(support.link, '_blank')),
                  )
                : themeKey === ThemeKey.SWITCH
                ? switchSupportUrls.map((support) =>
                    renderListItem(support.title, () => window.open(support.link, '_blank')),
                  )
                : sortedSupportUrls.map((support) =>
                    renderListItem(support.title, () => window.open(support.link, '_blank')),
                  )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isGuest: state.authState.guest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
