/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  AddAndUpdateCartActionRequest,
  AddAndUpdateCartRequest,
  AvailableServicesResponse,
  CheckoutType,
  PersonalDetails,
  PlanResponse,
  Preopen,
  ServiceDetailsData,
  SupplierResponse,
} from '../models/checkout/Checkout';
import { store } from '../store/Store';
import { ApplicationState } from '../store/RootReducer';
import { FooterPricing, PlanDetailObject, Fees, SupplierOptions } from '../models/billing/Billing';
import { ServiceTypes } from '../models/services/services';
import { essentialServices } from '../containers/checkoutSelect/components/serviceContainer/ServiceContainerConstants';
import { hasPayments } from '../containers/checkoutFinalize/CheckoutFinalizeUtils';

export const getCartCount = (
  availableServices: AvailableServicesResponse[] | undefined,
): number => {
  return availableServices
    ? availableServices.filter(
        (service) => !!service.suppliers!.filter((supplier) => supplier.selected).length,
      ).length
    : 0;
};

export const useCart = () => {
  const availableServices = useSelector(
    (state: ApplicationState) => state.checkout.availableServices,
  );
  const requiredServiceTypes = useSelector(
    (state: ApplicationState) => state.checkout.requiredServiceTypes,
  );

  const cartItems = useMemo<AvailableServicesResponse[]>(() => {
    return availableServices
      ? availableServices
          .filter((service) => !!service.suppliers!.filter((supplier) => supplier.selected).length)
          .map((service) => ({
            ...service,
            suppliers: service
              .suppliers!.filter((supplier) => supplier.selected)
              .map((supplier) => ({
                ...supplier,
                plans: supplier.plans
                  .filter((plan) => plan.selected)
                  .map((plan) => {
                    return {
                      ...plan,
                      selectedProductId:
                        plan.cartData &&
                        plan.cartData.planDetails &&
                        plan.cartData.planDetails.productDetails
                          ? plan.cartData.planDetails.productDetails.productId
                          : plan.productId,
                    };
                  }),
              })),
          }))
          .filter((cartItem) =>
            requiredServiceTypes
              ? requiredServiceTypes.includes(cartItem.type as ServiceTypes)
              : cartItem,
          )
      : [];
  }, [availableServices, requiredServiceTypes]);

  const cartServices = useMemo<AvailableServicesResponse[]>(() => {
    return cartItems.filter((i) => essentialServices.includes(i.type as ServiceTypes));
  }, [cartItems]);

  const cartReferrals = useMemo<AvailableServicesResponse[]>(() => {
    return cartItems.filter((i) => !essentialServices.includes(i.type as ServiceTypes));
  }, [cartItems]);

  const cartCount = useMemo<number>(() => {
    return cartItems.length;
  }, [cartItems]);

  const paymentItems = useMemo<AvailableServicesResponse[]>(() => {
    return cartItems.filter((paymentItem) =>
      hasPayments(paymentItem.suppliers![0].plans[0].cartData),
    );
  }, [cartItems]);

  const cartPricing = useMemo<FooterPricing[]>(() => {
    const result: FooterPricing[][] = [];
    let daily = false;
    let monthly = false;
    cartItems.map((serviceType) =>
      serviceType.suppliers!.map((supplier) => {
        supplier.extendedData!.Plans.forEach((plan: PlanDetailObject) => {
          result.push(plan.FooterPricing);
        });
      }),
    );
    const temp = result.flat();

    const r: FooterPricing[] = temp.filter((plan) => {
      if (plan.UnitOfMeasure === 'Daily' && !daily) {
        daily = true;
        return true;
      }
      if (plan.UnitOfMeasure === 'Monthly' && !monthly) {
        monthly = true;
        return true;
      }
      return false;
    });
    return r;
  }, [cartItems]);

  const feeItems = useMemo<Fees[]>(() => {
    const fees: Fees[] = [];
    cartItems.map((cartItem) => {
      cartItem.suppliers!.map((supplier) => {
        let selectedPlan: PlanDetailObject | undefined;
        supplier.extendedData!.Plans.map((plan) => {
          plan.Options &&
            plan.Options.length > 0 &&
            plan.Options.map((option) => {
              if (option.Type === 'Product') {
                const selectedProduct = option.Options.find(
                  (innerOption) => innerOption.ProductId === supplier.plans[0].productId,
                );
                if (selectedProduct) {
                  selectedPlan = plan;
                }
              }
            });
        });
        const cartData = supplier.plans[0].cartData;
        if (
          selectedPlan &&
          (selectedPlan as PlanDetailObject).Fees &&
          (selectedPlan as PlanDetailObject).Fees.length > 0
        ) {
          const feeDetails = (selectedPlan as PlanDetailObject).Fees;
          (selectedPlan as PlanDetailObject).Options.map((option) => {
            const selectedValue =
              cartData && cartData.planDetails && cartData.planDetails.featureDetails
                ? cartData.planDetails.featureDetails.find(
                    (featureDetail) => featureDetail.labelId === option.Group,
                  )
                : null;
            if (selectedValue) {
              const selectedOption = option.Options.find(
                (option) => option.Label === selectedValue.value,
              );
              if (selectedOption && selectedOption.FeeId && selectedOption.FeeId.length > 0) {
                selectedOption.FeeId.map((feeId) => {
                  const feeItem = feeDetails.find((feeDetail) => feeDetail.Id === feeId);
                  if (feeItem) {
                    fees.push({ ...feeItem, ServiceType: cartItem.type });
                  }
                });
              }
            } else {
              option.Options.map((innerOption) => {
                if (innerOption.Default) {
                  innerOption.FeeId.map((feeId) => {
                    const feeItem = feeDetails.find((feeDetail) => feeDetail.Id === feeId);
                    if (feeItem) {
                      fees.push({ ...feeItem, ServiceType: cartItem.type });
                    }
                  });
                }
              });
            }
          });
        }

        // to add cost from feature details
        if (
          cartData &&
          cartData.planDetails &&
          cartData.planDetails.featureDetails &&
          cartData.planDetails.featureDetails.length > 0
        ) {
          cartData.planDetails.featureDetails.map((featureDetail) => {
            if (featureDetail.cost) {
              const feeItem: Fees = {
                Heading: '',
                FeeItems: [
                  {
                    Label: featureDetail.label || '',
                    Links: [],
                    Name: '',
                    OrderBy: 0,
                    Rate: featureDetail.cost,
                    Rule: '',
                    Type: '',
                    Unit: '',
                  },
                ],
                HelpText: '',
                Id: '',
                LinkItems: [],
                Name: 'OtherCharges',
                Type: 'OtherCharges',
                ServiceType: cartItem.type,
                ChargesFeesText:
                  selectedPlan && selectedPlan.PaymentInformation
                    ? selectedPlan.PaymentInformation.ChargesFeesText
                    : '',
              };
              fees.push({ ...feeItem });
            }
          });
        }
      });
    });
    let helpTextList: string[] = [];

    return fees.map((fee) => {
      if (fee.HelpText) {
        if (!helpTextList.includes(fee.HelpText)) {
          helpTextList.push(fee.HelpText);
        }
        const index = helpTextList.findIndex((item) => item === fee.HelpText);
        if (index !== -1) {
          return {
            ...fee,
            FeeItems: [
              { ...fee.FeeItems[0], Label: fee.FeeItems[0].Label + `*`.repeat(index + 1) },
            ],
            HelpText: `*`.repeat(index + 1) + fee.HelpText,
          };
        }
      }
      return fee;
    });

    return fees;
  }, [cartItems]);

  // for removing duplicate helper text from feeItems
  const feeItemsHelperTextList = useMemo<string[]>(() => {
    if (feeItems && feeItems.length > 0) {
      let helperText: string[] = [];
      feeItems.map((fee) => helperText.push(fee.HelpText));
      // to remove duplicate helper text from feeitems list.
      return helperText.reduce(function (a: string[], b) {
        if (!a.includes(b)) a.push(b);
        return a;
      }, []);
    }
    return [];
  }, [feeItems]);

  const compareItems = useMemo<AvailableServicesResponse[]>(() => {
    return availableServices
      ? availableServices
          .filter((service) => !!service.suppliers!.filter((supplier) => supplier.compare).length)
          .map((service) => ({
            ...service,
            suppliers: service
              .suppliers!.filter((supplier) => supplier.compare)
              .map((supplier) => ({
                ...supplier,
                plans: supplier.plans.filter((plan) => plan.compare),
              })),
          }))
      : [];
  }, [availableServices]);

  const compareCount = useMemo<number>(() => {
    let count = 0;
    compareItems.map((service) => {
      service.suppliers!.map((supplier) => {
        supplier.plans.map(() => {
          count++;
        });
      });
    });
    return count;
  }, [compareItems]);

  const updatedCartItem = cartItems.map((cartItem) => {
    const { cartData } = cartItem.suppliers![0].plans[0];
    const isOptionsCompleted = !!(
      cartData &&
      cartData.planDetails &&
      cartData.planDetails.featureDetails
    );
    const isTermsCompleted = !!(cartData && cartData.acceptTermAndCondition);
    const isPersonalDetailsCompleted = !!(cartData && cartData.personalDetails);
    const isPaymentCompleted =
      cartData && cartData.planDetails
        ? !cartData.planDetails.skipPaymentStep
          ? !!cartData!.paymentRefId
          : true
        : false;
    return {
      ...cartItem,
      complete: {
        ...cartItem.complete,
        options: isOptionsCompleted,
        terms: isTermsCompleted,
        personalDetails: isPersonalDetailsCompleted,
        payment: isPaymentCompleted,
      },
    };
  });

  return {
    cartCount,
    cartItems: updatedCartItem,
    compareItems,
    compareCount,
    paymentItems,
    cartPricing,
    feeItems,
    feeItemsHelperTextList,
    cartServices,
    cartReferrals,
  };
};

export const addToCompare = (
  serviceType: string,
  providerId: string,
  productId: string,
  availableServices: AvailableServicesResponse[],
): AvailableServicesResponse[] => {
  return availableServices.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) => ({
            ...supplier,
            compare: supplier.providerId === providerId ? true : supplier.compare,
            plans: supplier.plans.map((plan) => ({
              ...plan,
              compare: plan.productId === productId ? true : plan.compare,
            })),
          })),
        },
  );
};

export const removeFromCompare = (
  serviceType: string,
  providerId: string,
  productId: string,
  availableServices: AvailableServicesResponse[],
): AvailableServicesResponse[] => {
  return availableServices.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) => ({
            ...supplier,
            compare:
              supplier.providerId === providerId &&
              supplier.plans.filter((plan) => plan.compare).length === 1
                ? false
                : supplier.compare,
            plans: supplier.plans.map((plan) => ({
              ...plan,
              compare: plan.productId === productId ? false : plan.compare,
            })),
          })),
        },
  );
};

export const resetCompare = (
  availableServices: AvailableServicesResponse[],
): AvailableServicesResponse[] => {
  return availableServices.map((service) => ({
    ...service,
    suppliers: service.suppliers!.map((supplier) => ({
      ...supplier,
      compare: false,
      plans: supplier.plans.map((plan) => ({
        ...plan,
        compare: false,
      })),
    })),
  }));
};

export const updateAvailableServicesOnSelect = (
  isPlanSelected: boolean,
  serviceType: string,
  providerId: string,
  productId: string,
): AvailableServicesResponse[] => {
  const { availableServices } = store.getState().checkout;
  let updateAvailableServices = availableServices ? [...availableServices] : [];
  if (isPlanSelected) {
    updateAvailableServices = availableServices!.map((service) =>
      service.type !== serviceType
        ? service
        : {
            ...service,
            suppliers: service.suppliers!.map((supplier) => ({
              ...supplier,
              selected: false,
              plans: supplier.plans.map((plan) => ({
                ...plan,
                selected: false,
              })),
            })),
          },
    );
  } else {
    updateAvailableServices = availableServices!.map((service) =>
      service.type !== serviceType
        ? service
        : {
            ...service,
            suppliers: service.suppliers!.map((supplier) => ({
              ...supplier,
              selected: supplier.providerId === providerId,
              plans: supplier.plans.map((plan) => ({
                ...plan,
                selected: plan.productId === productId,
              })),
            })),
          },
    );
  }
  return updateAvailableServices;
};

export const updateCartItemId = (
  availableServices: AvailableServicesResponse[],
  serviceType: string,
  providerId: string,
  productId: string,
  id: number,
) => {
  const result = availableServices.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) =>
            supplier.providerId === providerId
              ? {
                  ...supplier,
                  plans: supplier.plans.map((plan) =>
                    plan.productId === productId ? { ...plan, cartItemId: id } : plan,
                  ),
                }
              : supplier,
          ),
        },
  );
  return result;
};

export const updateCardDataInAvailableServices = (
  availableServices: AvailableServicesResponse[],
  serviceType: string,
  providerId: string,
  productId: string,
  cardData: ServiceDetailsData,
): AvailableServicesResponse[] => {
  const updateAvailableServices = availableServices!.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) =>
            supplier.providerId === providerId
              ? {
                  ...supplier,
                  plans: supplier.plans.map((plan) =>
                    plan.productId === productId ? { ...plan, cartData: cardData } : plan,
                  ),
                }
              : supplier,
          ),
        },
  );

  return updateAvailableServices;
};

export const getCardRequest = (availableServices: AvailableServicesResponse[]) => {
  const requestBody: AddAndUpdateCartRequest[] = [];
  availableServices.forEach((service: AvailableServicesResponse) => {
    const supplierDetails =
      service.suppliers && service.suppliers.find((supplier) => supplier.selected);
    const planDetails =
      supplierDetails &&
      supplierDetails.plans &&
      supplierDetails.plans.find((plan) => plan.selected);
    const extendedData = supplierDetails && supplierDetails.extendedData;
    if (supplierDetails && planDetails) {
      requestBody.push({
        serviceType: service.type,
        supplierId: supplierDetails.providerId,
        planId: planDetails.productId,
        data: planDetails.cartData ? planDetails.cartData : null,
        checkoutType:
          extendedData && extendedData.ServiceCategoryId === 'TradeRequest'
            ? CheckoutType.TRADE_REQUEST
            : extendedData && extendedData.ServiceCategoryId === 'Referral'
            ? CheckoutType.EMAIL
            : CheckoutType.CHECKOUT,
      });
    }
  });
  return requestBody;
};

export const updateAccountAndPaymentInAvailableServices = (
  availableServices: AvailableServicesResponse[],
  serviceType: string,
  providerId: string,
  productId: string,
  personalDetails: PersonalDetails,
  paymentRefId: string | null,
) => {
  const updateAvailableServices = availableServices!.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) =>
            supplier.providerId === providerId
              ? {
                  ...supplier,
                  plans: supplier.plans.map((plan) =>
                    plan.productId === productId
                      ? {
                          ...plan,
                          cartData: {
                            ...(plan.cartData as ServiceDetailsData),
                            personalDetails,
                            paymentRefId,
                          },
                        }
                      : plan,
                  ),
                }
              : supplier,
          ),
        },
  );
  return updateAvailableServices;
};

export const updateAccountInAvailableServices = (
  availableServices: AvailableServicesResponse[],
  serviceType: string,
  providerId: string,
  productId: string,
  personalDetails: PersonalDetails,
) => {
  const updateAvailableServices = availableServices!.map((service) =>
    service.type !== serviceType
      ? service
      : {
          ...service,
          suppliers: service.suppliers!.map((supplier) =>
            supplier.providerId === providerId
              ? {
                  ...supplier,
                  plans: supplier.plans.map((plan) =>
                    plan.productId === productId
                      ? {
                          ...plan,
                          cartData: {
                            ...(plan.cartData as ServiceDetailsData),
                            personalDetails,
                          },
                        }
                      : plan,
                  ),
                }
              : supplier,
          ),
        },
  );
  return updateAvailableServices;
};

export const createRequiredServiceTypes = (serviceTypes: ServiceTypes[]) => {
  return serviceTypes.join(',');
};

export const sortSupplierOptions = (supplierOptions: SupplierOptions[]): SupplierOptions[] => {
  return supplierOptions && supplierOptions.length > 0
    ? supplierOptions.sort(function (a, b) {
        var x = a.OrderBy;
        var y = b.OrderBy;
        return x < y ? -1 : x > y ? 1 : 0;
      })
    : [];
};

export const createPreopen = (serviceType: ServiceTypes, providerId?: string): Preopen => {
  if (providerId) {
    return {
      [serviceType]: {
        providerId,
      },
    };
  } else {
    return {
      [serviceType]: {},
    };
  }
};

export const createPreopenURL = (preopen: Preopen[]): Preopen => {
  // This is used to automatically open a service in checkout
  const result = {};
  preopen.map((o) => {
    Object.keys(o).map((key) => {
      if (o[key].providerId) {
        result[key] = {
          providerId: o[key].providerId,
        };
      } else {
        result[key] = {};
      }
    });
  });
  return result;
};

export const DEFAULT_SERVICES = 'Broadband,Electricity,Gas';
