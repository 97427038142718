import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './TabMenuStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BottomNavValue, TabProps } from '../../models/dashboard/dashboard';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface TabMenuProps extends RouteComponentProps {
  tabs: string[];
  tabProps: TabProps | undefined;
  selectedTab: BottomNavValue;
  switchFooterTab: (tabName: BottomNavValue, tabProps?: TabProps) => void;
}

const TabMenu: FC<TabMenuProps> = ({ tabs, selectedTab, tabProps, switchFooterTab }) => {
  const classes = useStyles();
  return (
    <div className={classes.tabMenu}>
      {tabs.map((t, idx) => (
        <div
          key={idx}
          className={classNameGenerator([
            classes.tabWrapper,
            ((tabProps && idx === tabProps.index) || (tabProps === undefined && idx === 0)) &&
              classes.selected,
          ])}
          onClick={() => switchFooterTab(selectedTab, { ...tabProps, index: idx })}
        >
          <div
            className={classNameGenerator([
              classes.unselectedTabText,
              ((tabProps && idx === tabProps.index) || (tabProps === undefined && idx === 0)) &&
                classes.selectedTabText,
            ])}
          >
            {t}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedTab: state.dashboardState.selectedTab,
  tabProps: state.dashboardState.tabProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchFooterTab: (tabName: BottomNavValue, tabProps?: TabProps) =>
    dispatch(DashboardActions.switchFooterTab(tabName, tabProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabMenu));
