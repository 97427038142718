export enum AccountStatus {
  NEW = 'New',
  OPEN = 'Open',
  PENDING = 'Pending',
  INPROGRESS = 'InProgress',
  IN_PROGRESS = 'in_progress',
  LANDLORD = 'landlord_attending',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  CLOSE = 'closed',
  COMPLETE = 'complete',
  RECIEVED = 'received',
  PRESIGNED = 'Presigned',
}

export const summaryScreenValidStates = [
  AccountStatus.COMPLETED,
  AccountStatus.OPEN,
  AccountStatus.PENDING,
  AccountStatus.PRESIGNED,
];

export const graphApiValidStates = [AccountStatus.CLOSED, AccountStatus.OPEN];

export const onboardingScreenValidStates = [AccountStatus.NEW, AccountStatus.INPROGRESS];
export const onboardingScreenValidStatesPlusPending = [
  AccountStatus.NEW,
  AccountStatus.INPROGRESS,
  AccountStatus.PENDING,
];

export const activeAccountStatus = [...summaryScreenValidStates, ...onboardingScreenValidStates];

export const inactiveAccountStatus = [AccountStatus.CANCELLED, AccountStatus.CLOSED];
export const newService = [AccountStatus.NEW];
export const activeService = [AccountStatus.COMPLETED, AccountStatus.OPEN];
export const activePlusPending = [
  AccountStatus.COMPLETED,
  AccountStatus.OPEN,
  AccountStatus.PENDING,
  AccountStatus.PRESIGNED,
];
export const pendingService = [
  AccountStatus.PRESIGNED,
  AccountStatus.INPROGRESS,
  AccountStatus.PENDING,
];
