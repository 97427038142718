/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/Sizes';
import { getFonts } from '../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    paddingBottom: {
      backgroundColor: theme.colors.light,
      paddingBottom: 16,
    },
    searchBarContainer: {
      padding: theme.units.spacing * 4,
      borderRadius: theme.units.borderRadius,
      border: `${theme.colors.borderColorLight} 1px solid`,
      backgroundColor: theme.colors.light,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    searchIcon: {
      width: 20,
      height: 20,
      marginRight: 12,
      cursor: 'pointer',
    },
    searchInput: {
      marginBottom: '0px !important',
    },
    searchInput2: {
      border: 'none !important',
    },
    searchPlaceholder: {
      fontWeight: getFonts('Medium'),
      fontSize: 14,
      color: theme.colors.black87,
    },
    resultsContainer: {
      position: 'absolute',
      top: 0,
      width: 'calc(100% - 24px)',
      left: 0,
      right: 0,
      margin: 'auto',
      backgroundColor: theme.colors.light,
      border: `${theme.colors.borderColor} 1px solid`,
      marginTop: theme.units.spacing * 3,
      borderRadius: theme.units.borderRadius,
      overflow: 'hidden',
      zIndex: 1,
    },
    resultsTitle: {
      margin: 12,
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      borderBottom: `${theme.colors.borderColorLight} 1px solid`,
      paddingBottom: 12,
      // textTransform: 'uppercase',
    },
    noResult: {
      margin: 12,
      marginTop: 0,
      fontWeight: getFonts('Medium'),
      fontSize: 12,
      color: theme.colors.grey,
    },
    requestRow: {
      height: 64,
      backgroundColor: theme.colors.light,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: 12,
      borderBottomColor: theme.colors.borderColor,
      borderBottomWidth: 1,
    },
    iconContainer: {
      borderRadius: 5000,
      backgroundColor: theme.colors.lightGrey,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 36,
      height: 36,
    },
    icon: {
      width: 20,
      height: 20,
    },
    titleContainer: {
      marginLeft: 12,
      marginRight: 'auto',
    },
    title: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    description: {
      marginTop: 4,
      fontSize: 13,
      fontWeight: getFonts('Medium'),
      color: theme.colors.grey,
    },
    arrow: {
      marginRight: 2,
      alignSelf: 'center',
      height: 25,
      width: 25,
      padding: 0,
      borderRadius: 13,
    },
  })();
};
