import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    container: {},
    providerPicker: {
      marginBottom: theme.units.spacing * 8,
    },
    providerTitle: {
      fontSize: 16,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
      marginLeft: theme.units.spacing * 4,
      marginBottom: theme.units.spacing * 4,
    },
    providerRow: {
      display: 'flex',
      alignItems: 'center',
    },
    providerContainer: {
      border: `${theme.colors.borderColorLight} 1px solid`,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      padding: theme.units.spacing * 1.5,
      paddingRight: theme.units.spacing * 4,
      cursor: 'pointer',
      marginRight: theme.units.spacing * 3,
    },
    selectedProviderContainer: {
      border: `${theme.colors.buttonPrimary} 1px solid !important`,
      // backgroundColor: theme.colors.lightBlue,
    },
    providerText: {
      fontSize: 12,
      fontWeight: getFonts('Medium'),
      color: theme.colors.black87,
    },
    providerIconContainer: {
      width: '32px',
      height: '32px',
      borderRadius: 5000,
      overflow: 'hidden',
      boxSizing: 'border-box',
      backgroundColor: theme.colors.lightGrey,
      marginRight: theme.units.spacing * 3,
      flexShrink: 0,
    },
    providerIcon: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      flexShrink: 0,
    },
    popupContainer: {
      backgroundColor: theme.colors.light,
      height: '100%',
    },
    quotesContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.units.spacing * 4,
      [`@media (max-width: ${SIZES.lg}px)`]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [`@media (max-width: ${SIZES.md}px)`]: {
        gridTemplateColumns: '1fr',
      },
    },
    servicesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  })();
};
