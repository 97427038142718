import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './OtherServiceSummaryStyles';
import { LABELS } from './OtherServiceSummaryConstants';
import arrowLeft from '../../assets/navigation/arrow-left.png';
import Text from '../../components/text/Text';
import {
  extractParams,
  navigate,
  navigateBack,
  resetNavigation,
} from '../../navigation/NavigationUtils';
import { ServiceSummaryResponse, ServiceTypes } from '../../models/services/services';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ServiceSummaryParams } from '../../navigation/NavigationConstants';
import { ApplicationState } from '../../store/RootReducer';
import { ServiceActions } from '../../store/actions/ServiceActions';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import moment from 'moment';
import HEADER_IMAGE from '../../assets/lake.jpg';
import HEADER_IMAGE_FOXIE from '../../assets/foxie.png';

interface OtherServiceSummaryProps {
  serviceSummary: ServiceSummaryResponse;
  getServiceData: (serviceAccountId: string) => void;
}

// This screen is for water and foxie, referrals too I think
const OtherServiceSummary: FC<OtherServiceSummaryProps> = ({ serviceSummary, getServiceData }) => {
  const classes = useStyles();
  const { serviceAccountId, serviceType } = extractParams<ServiceSummaryParams>();

  useEffect(() => {
    getServiceData(serviceAccountId.toString());
  }, []);

  const renderPaymentCardItems = (label: string, value: string) => {
    return (
      <div className={classes.cardItem}>
        <div className={classes.flexBox}>
          <div className={classes.itemLabel}>{label}</div>
          <div className={classes.itemValue}>{value}</div>
        </div>
      </div>
    );
  };

  const renderAccountDetailCard = () => {
    const accountHoldername = get(serviceSummary, 'serviceDetails.personalDetails.name', undefined);
    const accountHolderSurname = get(
      serviceSummary,
      'serviceDetails.personalDetails.surname',
      undefined,
    );
    const accountHolderPhone = get(
      serviceSummary,
      'serviceDetails.personalDetails.phone',
      undefined,
    );
    const referenceNumber = get(serviceSummary, 'serviceDetails.referenceNumber');
    const date = get(serviceSummary, 'serviceDetails.dateRequested');
    const name =
      accountHoldername && accountHolderSurname
        ? `${accountHoldername} ${accountHolderSurname}`
        : '';
    return (
      <div className={classes.card}>
        {renderPaymentCardItems(
          serviceType === ServiceTypes.Water ? LABELS.ACCOUNT_HOLDER : LABELS.ACCOUNT_NAME,
          name,
        )}
        {serviceType !== ServiceTypes.Water &&
          renderPaymentCardItems(LABELS.PHONE, accountHolderPhone || '')}
        {serviceType !== ServiceTypes.Water &&
          renderPaymentCardItems(LABELS.DATE_REQUESTED, moment(date).format('DD MM YYYY') || '')}
        {renderPaymentCardItems(
          serviceType === ServiceTypes.Water ? LABELS.ACCOUNT_NUMBER : LABELS.REFERENCE_NUMBER,
          referenceNumber || '',
        )}
        {serviceType === ServiceTypes.Water &&
          renderPaymentCardItems(LABELS.DATE_REQUESTED, moment(date).format('DD MMM YYYY') || '')}
      </div>
    );
  };

  const renderSupplierDetailCard = () => {
    const iconUrl = get(serviceSummary, 'serviceProvider.iconUrl', undefined);
    const name = get(serviceSummary, 'serviceProvider.name', undefined);
    const phone = get(serviceSummary, 'serviceProvider.phone', undefined);
    const emailId = get(serviceSummary, 'serviceProvider.email', undefined);
    const website = get(serviceSummary, 'serviceProvider.website', undefined);
    return (
      <div className={classes.card}>
        <div className={classes.supplierHeader}>
          <img className={classes.serviceLogo} src={iconUrl} />
          <div className={classes.itemValue}>{name || ''}</div>
        </div>
        {renderPaymentCardItems(LABELS.CALL, phone || '')}
        {renderPaymentCardItems(LABELS.EMAIL, emailId || '')}
        {renderPaymentCardItems(LABELS.WEB, website || '')}
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.backRow} onClick={() => navigateBack()}>
          <img src={arrowLeft} className={classes.backArrow} />
          <Text textVariant="link">Back</Text>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div>
              <img
                className={classes.header}
                src={
                  serviceSummary.serviceProvider.backgroundUrl ||
                  (serviceType === ServiceTypes.Water ? HEADER_IMAGE : HEADER_IMAGE_FOXIE)
                }
              />
            </div>
            <div className={classes.body}>
              <div className={classes.rentCard}>
                <div className={classes.rentCardVerticalView}>
                  <div className={classes.headerText}>
                    {serviceType === ServiceTypes.Water ? ServiceTypes.Water : 'Utility Callback'}
                  </div>
                  <img
                    className={classes.serviceIcon}
                    src={serviceSummary.serviceProvider.iconUrl}
                  />
                </div>
                <div className={classes.sectionTitle}>{LABELS.ACCOUNT_DETAILS_TITLE}</div>
                {renderAccountDetailCard()}
              </div>
              <div className={classes.body}>
                <div className={classes.sectionTitle}>{LABELS.ACCOUNT_DETAILS_TITLE}</div>
                {renderSupplierDetailCard()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  serviceSummary: state.services.serviceSummary,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getServiceData: (serviceAccountId: string) =>
    dispatch(ServiceActions.getServiceSummaryRequest(serviceAccountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherServiceSummary);
