/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import {
  MasterProfileAction,
  MasterProfileActionTypes,
  MasterProfileActions,
} from '../actions/MasterProfileActions';
import {
  MasterProfileResponsePayload,
  MasterProfilePayload,
  ApplicantResponsePayload,
} from '../../models/masterProfile/MasterProfile';
import MasterProfileService from '../../services/masterProfile/MasterProfileService';

export function* getMasterProfile(action: MasterProfileAction) {
  try {
    const masterProfileResponse: MasterProfileResponsePayload =
      yield MasterProfileService.getMasterProfileData();
    const resData: MasterProfilePayload = {
      applicants: [masterProfileResponse.primary.profileData],
    };
    let key = 0;
    if (masterProfileResponse.secondary.length > 0) {
      masterProfileResponse.secondary.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    if (masterProfileResponse.dependents.length > 0) {
      masterProfileResponse.dependents.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }

    yield put(MasterProfileActions.getMasterProfileSuccess(resData));
  } catch (error) {
    yield put(MasterProfileActions.getMasterProfileError(error as Error));
  }
}

function* masterProfileWatcher() {
  yield takeEvery(
    MasterProfileActionTypes.GET_MASTER_PROFILE_REQUEST,
    (action: MasterProfileAction) =>
      getMasterProfile({
        type: action.type,
        data: action.data,
      }),
  );
}

export default masterProfileWatcher;
