import React, { FC, useContext } from 'react';
import { useStyles } from './ServiceOfferStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface ServiceOfferProps {
  image: string;
  title: string;
  subtitle: string;
  buttonText: string;
  handlePress: () => void;
}

const ServiceOffer: FC<ServiceOfferProps> = ({
  image,
  title,
  subtitle,
  buttonText,
  handlePress,
}) => {
  const classes = useStyles();
  const handleAction = () => {
    handlePress();
  };

  return (
    <div className={classes.offerContainer} onClick={() => handleAction()}>
      <div className={classes.imageContainer}>
        <img src={image} className={classes.image} />
      </div>

      <div className={classes.textContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{subtitle}</div>
        <div className={classNameGenerator([classes.button])}>
          <div className={classes.buttonText}>{buttonText}</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOffer;
