/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import {
  Job,
  MaintenanceResponse,
  PreferredAvailability,
  Quote,
  WorkOrder,
} from '../../models/landlord/LandlordProperty';
import { NO_PREFERENCE, WeekdayList } from './LandlordMaintenanceInfoContants';

export const getQuotes = (maintenance: MaintenanceResponse | null) => {
  let quotes: Quote[] = [];
  maintenance &&
    maintenance.workOrders &&
    maintenance.workOrders.length > 0 &&
    maintenance.workOrders.map((workOrder) => {
      if (workOrder.quotes && workOrder.quotes.length > 0) {
        quotes = [...quotes, ...workOrder.quotes];
      }
    });
  return quotes;
};

export const removeQuotesOnReject = (
  maintenance: MaintenanceResponse,
  quoteId: string,
): MaintenanceResponse => {
  return {
    ...maintenance,
    workOrders: maintenance.workOrders.map((workOrder) => {
      const updatedQuotes: Quote[] = [];
      workOrder.quotes.forEach((quote) => {
        if (quote.id !== quoteId) {
          updatedQuotes.push(quote);
        }
      });
      return {
        ...workOrder,
        quotes: updatedQuotes,
      };
    }),
  };
};

export const getTradeName = (item: Quote | Job, havingQuote: boolean) => {
  let tradeName = '';
  let officeName = '';
  let firstChar = '';
  let secondChar = '';
  let logo = '';
  if (havingQuote) {
    if ((item as Quote).officeDetails) {
      officeName = (item as Quote).officeDetails.name || '';
      tradeName = (item as Quote).officeDetails.trade
        ? (item as Quote).officeDetails.trade.name || ''
        : '';
      const trade = tradeName ? tradeName.split(' ') : [];
      firstChar = trade && trade.length > 0 ? trade[0].charAt(0) : '';
      secondChar = trade && trade.length > 1 ? trade[1].charAt(0) : '';
      logo = (item as Quote).officeDetails.logo;
    }
  } else {
    const jobDetails = item as Job;
    tradeName = jobDetails.tradeName || '';
    const trade = tradeName ? tradeName.split(' ') : [];
    firstChar = trade && trade.length > 0 ? trade[0].charAt(0) : '';
    secondChar = trade && trade.length > 1 ? trade[1].charAt(0) : '';
    officeName =
      jobDetails.officeTradeResponse && jobDetails.officeTradeResponse.name
        ? jobDetails.officeTradeResponse.name
        : '';
    logo =
      jobDetails.officeTradeResponse && jobDetails.officeTradeResponse.logo
        ? jobDetails.officeTradeResponse.logo
        : '';
  }
  return { tradeName, officeName, firstChar, secondChar, logo };
};

export const getTimeSlot = (preferredAvailability: PreferredAvailability) => {
  if (!preferredAvailability) {
    return '';
  }
  if (preferredAvailability.otherTime) {
    return `Other (${preferredAvailability.otherTime})`;
  }
  switch (preferredAvailability.timeSlot) {
    case NO_PREFERENCE:
      return 'The tenants are flexible and do not have a particular time preference';
    case 'Morning':
      return 'Morning (8am - 12pm)';
    case 'Afternoon':
      return 'Afternoon (12pm - 5pm)';
    default:
      return '';
  }
};

export const getDays = (preferredAvailability: PreferredAvailability) => {
  const uppercaseWeekDaysList = WeekdayList.map((d) => d.toUpperCase());
  if (preferredAvailability) {
    if (
      uppercaseWeekDaysList.every((d) => {
        return preferredAvailability.days.includes(d);
      })
    ) {
      return 'all days';
    }
    return preferredAvailability.days
      .slice()
      .sort((a, b) => uppercaseWeekDaysList.indexOf(a) - uppercaseWeekDaysList.indexOf(b))
      .map((d) => `${d.charAt(0)}${d.slice(1).toLowerCase()}`)
      .join(', ');
  }
  return '';
};

export const getTradeContactDetails = (item: Quote | Job, havingQuote: boolean) => {
  let phone = '';
  let email = '';
  let name = '';
  if (havingQuote) {
    if ((item as Quote).officeDetails) {
      email = (item as Quote).officeDetails.email || '';
      phone = (item as Quote).officeDetails.phone || '';
    }
  } else {
    if ((item as Job).assignedUsers && (item as Job).assignedUsers.length > 0) {
      const user = (item as Job).assignedUsers[0];
      email = user.email || '';
      phone = user.mobileNumber || '';
      name = user.firstName || user.lastName ? user.firstName + '' + user.lastName : '';
    }
  }

  return { phone, email, name };
};

export const getJobs = (maintenance: MaintenanceResponse | null) => {
  let jobs: Job[] = [];
  maintenance &&
    maintenance.workOrders &&
    maintenance.workOrders.length > 0 &&
    maintenance.workOrders.map((workOrder) => {
      if (workOrder.jobs && workOrder.jobs.length > 0) {
        jobs = [...jobs, ...workOrder.jobs];
      }
    });
  return jobs;
};

export const getCostValues = (item: Quote | Job, havingQuote: boolean) => {
  let labourCost = 0;
  let materialCost = 0;
  let callOutCost = 0;
  let totalCost = 0;
  let addedMaterials = [];
  let addedMaterialsCost = 0;
  let startDate = '';
  let endDate = '';
  if (havingQuote) {
    const quoteItem = item as Quote;
    labourCost = quoteItem.labourCost;
    materialCost = quoteItem.materialCost;
    callOutCost = quoteItem.callOutCost;
    totalCost = quoteItem.totalCost;
    addedMaterials =
      quoteItem.specificMaterials && quoteItem.specificMaterials.length > 0
        ? quoteItem.specificMaterials.filter((data) => data.includedMaterial === false)
        : [];
    startDate =
      quoteItem && quoteItem.availability && quoteItem.availability.startDate
        ? moment(quoteItem.availability.startDate).format('DD/MM/YYYY')
        : '';
    endDate =
      quoteItem && quoteItem.availability && quoteItem.availability.endDate
        ? moment(quoteItem.availability.endDate).format('DD/MM/YYYY')
        : '';
  } else {
    const jobDetail = (item as Job).jobDetail;
    labourCost = jobDetail.labourCost;
    materialCost = jobDetail.includedMaterialCost;
    callOutCost = jobDetail.callOutCost;
    totalCost = jobDetail.totalCost;
    addedMaterials =
      jobDetail.specificMaterial && jobDetail.specificMaterial.length > 0
        ? jobDetail.specificMaterial.filter((data) => data.includedMaterial === false)
        : [];
  }
  addedMaterials.forEach((data) => {
    addedMaterialsCost += data.charge;
  });
  return {
    labourCost,
    materialCost,
    callOutCost,
    totalCost,
    addedMaterials,
    addedMaterialsCost,
    startDate,
    endDate,
  };
};

export const getJobDates = (item: WorkOrder[]) => {
  let startDate = '';
  let expectedCompletion = '';
  let timeSlot = '';
  let jobs = item && item.length > 0 ? item[0].jobs : [];
  if (jobs && jobs.length > 0 && jobs[0].keyDates) {
    const keyDates = jobs[0].keyDates;
    startDate = keyDates.startDate
      ? moment(keyDates.startDate, 'DD/MM/YYYYThh:mm').format('DD/MM/YYYY')
      : '';
    expectedCompletion = keyDates.expectedCompletion
      ? moment(keyDates.expectedCompletion, 'DD/MM/YYYYThh:mm').format('DD/MM/YYYY')
      : '';
    timeSlot =
      keyDates.timeSlot && keyDates.timeSlot.clockTimeRange ? keyDates.timeSlot.clockTimeRange : '';
  }
  return { startDate, expectedCompletion, timeSlot };
};
