import { GuestTokenResponse } from '../../models/auth/RegisterToken';
import { StoreAction } from '../StoreHelper';

export enum TokenActionTypes {
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_APP_NAME = 'SET_APP_NAME',
  SET_USER_ID = 'SET_USER_ID',
  SET_EXTERNAL = 'SET_EXTERNAL',
  SET_IS_WEB_VIEW = 'SET_IS_WEB_VIEW',
  GET_SESSION_TOKEN_REQUEST = 'GET_SESSION_TOKEN_REQUEST',
}

export type TokenActionPayload = string | number | boolean | GuestTokenResponse | Error;

export type TokenAction = StoreAction<TokenActionTypes, TokenActionPayload>;

export class TokenActions {
  public static setAccessToken(token: string): TokenAction {
    return {
      type: TokenActionTypes.SET_ACCESS_TOKEN,
      data: token,
    };
  }
  public static setRefreshToken(token: string): TokenAction {
    return {
      type: TokenActionTypes.SET_REFRESH_TOKEN,
      data: token,
    };
  }
  public static setAppName(appName: string): TokenAction {
    return {
      type: TokenActionTypes.SET_APP_NAME,
      data: appName,
    };
  }
  public static setUserId(userId: number): TokenAction {
    return {
      type: TokenActionTypes.SET_USER_ID,
      data: userId,
    };
  }
  public static setExternal(value: boolean): TokenAction {
    return {
      type: TokenActionTypes.SET_EXTERNAL,
      data: value,
    };
  }

  public static setIsWebView(value: boolean): TokenAction {
    return {
      type: TokenActionTypes.SET_IS_WEB_VIEW,
      data: value,
    };
  }

  public static getSessionTokenRequest(data: string): TokenAction {
    return {
      type: TokenActionTypes.GET_SESSION_TOKEN_REQUEST,
      data,
    };
  }
}
