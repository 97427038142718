import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './QuoteStyles';
import { LABELS } from './QuoteConstants';
import {
  AddAndUpdateCartActionRequest,
  DeleteCartItemActionRequest,
  ModifyCart,
  PlanResponse,
  SupplierResponse,
} from '../../../../models/checkout/Checkout';
import { Dispatch } from 'redux';
import { CheckoutActions } from '../../../../store/actions/CheckoutActions';
import { connect } from 'react-redux';
import { Property } from '../../../../models/property/property';
import { updateAvailableServicesOnSelect } from '../../../../helpers/CheckoutHelper';
import { getProductDetails } from '../../../checkoutConfigure/components/selectOptions/SelectOptionsUtils';
import { Options } from '../../../../models/billing/Billing';
import { ThemeKey, getCurrentThemeKey } from '../../../../theme/Theme';
import { ApplicationState } from '../../../../store/RootReducer';
import info from '../../../../assets/info.png';

interface PlanProps {
  plan: PlanResponse;
  serviceType: string;
  supplier: SupplierResponse;
  logo: string;
  property: Property;
  frequency?: string;
  handleAction: () => void;
  onSelection?: () => void;
  handleSupplierAction: () => void;
  updateCart: (data: AddAndUpdateCartActionRequest) => void;
  deleteCartItem: (data: DeleteCartItemActionRequest) => void;
}

const Plan: FC<PlanProps> = ({
  plan,
  serviceType,
  supplier,
  logo,
  property,
  frequency,
  handleAction,
  onSelection,
  handleSupplierAction,
  updateCart,
  deleteCartItem,
}) => {
  const classes = useStyles();
  const themeKey = getCurrentThemeKey();

  // TODO: Types conflict here
  const { productOptions } = useMemo(() => {
    return getProductDetails(supplier.extendedData!, plan.selectedProductId);
  }, []);

  const isTradeRequestSupplier = supplier.extendedData!.ServiceCategoryId === 'TradeRequest';

  const handleTap = () => {
    const updatedAvailableServices = updateAvailableServicesOnSelect(
      !!plan.selected,
      serviceType,
      supplier.providerId,
      plan.productId,
    );
    if (!!plan.selected) {
      deleteCartItem({
        availableServices: updatedAvailableServices,
        deleteCartItemRequest: {
          propertyId: property.id.toString(),
          serviceType,
          supplierId: supplier.providerId,
          planId: plan.productId,
        },
      });
    } else {
      updateCart({
        availableServices: updatedAvailableServices,
        propertyId: property.id.toString(),
      });
    }

    if (!plan.selected && onSelection) {
      onSelection();
    }
  };

  return (
    <div className={`${classes.quoteContainer} ${plan.selected && classes.activeQuote}`}>
      <div
        className={classes.supplierBackgroundImgContainer}
        onClick={() => handleSupplierAction()}
      >
        <img className={classes.supplierBackgroundImg} src={supplier.backgroundImg} alt="bg" />
        <div className={classes.supplierLogoContainer}>
          <img className={classes.supplierLogo} src={supplier.logo} alt="logo" />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{supplier.name}</div>
        <div className={classes.description}>{plan.title}</div>
        {plan.subtext && (
          <div
            className={classes.infoText}
            dangerouslySetInnerHTML={{
              __html: plan.subtext,
            }}
          />
        )}
        <div className={classes.actionContainer} onClick={() => handleTap()}></div>
        <div
          className={`${plan.selected ? classes.activeButton : classes.button}`}
          onClick={(e) => {
            handleTap();
          }}
        >
          {plan.selected ? LABELS.UNSELECT : LABELS.SELECT}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  property: state.checkout.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCart: (data: AddAndUpdateCartActionRequest) => dispatch(CheckoutActions.updateCart(data)),
  addToCompare: (data: ModifyCart) => dispatch(CheckoutActions.addToCompare(data)),
  removeFromCompare: (data: ModifyCart) => dispatch(CheckoutActions.removeFromCompare(data)),
  deleteCartItem: (data: DeleteCartItemActionRequest) =>
    dispatch(CheckoutActions.deleteCartItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
