export const LABELS = {
  TITLE: 'Request submitted!',
  SUBTITLE:
    "Congratulations, you've submitted your request! Our team is on it. While you eagerly await the next steps, here are some things you can do",
  GET_STARTED: 'Get started',
  QUICK_START: 'What you can do while you wait',
  VIEW_OR_EDIT: 'Get familar with your dashboard',
  CHECK_OUT: 'Check out our other services and referrals in our marketplace',
  CHECK_BLOGS: 'Spread the word. Loved your experience so far?',
};
