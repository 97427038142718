import React, { FC, useState, useEffect } from 'react';
import { useStyles } from './SupplierDetailsStyles';
import { KeyValuePair } from '../../../../models/services/genericServiceSummary';

interface SupplierDetailsProps {
  data: KeyValuePair[];
  logo: string;
  serviceProvider: string;
}

const SupplierDetails: FC<SupplierDetailsProps> = ({ data, logo, serviceProvider }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.supplierDetailsRow}>
        <img src={logo} className={classes.logoStyle} />
        <div className={classes.supplierName}>{serviceProvider}</div>
      </div>

      {data.map((dataItem, index) => (
        <div className={classes.row} key={index}>
          <div className={classes.titleContainer}>
            <div className={classes.sectionKey}>{dataItem.key}</div>
            <div className={classes.sectionValue}>{dataItem.value}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SupplierDetails;
