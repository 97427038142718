/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import { Formik, ErrorMessage, useFormik } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import { VALIDATION_SCHEMA, LABELS } from './ForgotPasswordFormConstants';
import { useStyles } from './ForgotPasswordFormStyles';
import { View } from '../../Login';
import Input from '../../../../components/input/Input';
import Text from '../../../../components/text/Text';
import Box from '../../../../components/box/Box';
import Button from '../../../../components/button/Button';
import { ApplicationState } from '../../../../store/RootReducer';
import {
  ForgotPasswordRequest,
  ForgotPasswordToastMesssages,
  RegisterUserPayload,
} from '../../../../models/auth/auth';
import UserConfirmationDialog from '../../../../components/userConfirmationDialog/UserConfirmationDialog';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { AuthActionTypes, AuthActions } from '../../../../store/actions/AuthActions';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { ToastMessage } from '../../../../helpers/ToastMessage';

interface ForgotPasswordProps {
  // error: any;
  loading: boolean;
  userDetails: RegisterUserPayload | null;
  postForgotPasswordEmail: (data: ForgotPasswordRequest, onSubmit: () => void) => void;
  setView: (view: View) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordProps> = ({
  // error,
  loading,
  userDetails,
  postForgotPasswordEmail,
  setView,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  // useEffect(() => {
  //   if (error) {
  //     setErrorMessage('User does not exist');
  //   }
  // }, [error]);

  const sendForgotPasswordEmail = (data: ForgotPasswordRequest) => {
    postForgotPasswordEmail(data, () => {
      setErrorMessage(null);
      setOpenConfirmDialog(true);
    });
  };

  const formik = useFormik({
    initialValues: {
      username: userDetails && userDetails.email ? userDetails.email : '',
    },
    onSubmit: (values) => {
      sendForgotPasswordEmail({
        application_name: 'TenancyPortal',
        username: values.username,
        product_name: 'sorted',
      });
    },
    validationSchema: VALIDATION_SCHEMA,
  });

  return (
    <>
      <div className={classes.centerPageContent}>
        <div className={classes.primaryContainer}>
          <form onSubmit={formik.handleSubmit}>
            <Box lightBorder parentStyles={classes.boxContainer}>
              <Text parentStyles={classes.title} textVariant="title" size="xl">
                {LABELS.FORGOT_PASSWORD}
              </Text>
              <Text textVariant="info">{LABELS.FORGOT_PASSWORD_SUBTITLE}</Text>
              <div className={classes.inputRow}>
                <Input
                  placeholder={LABELS.EMAIL}
                  key={LABELS.EMAIL}
                  value={formik.values.username}
                  setValue={formik.handleChange('username')}
                  onBlur={() => formik.setFieldTouched('username')}
                  touched={formik.touched.username}
                  error={formik.errors.username}
                  title={LABELS.EMAIL}
                  mandatory
                  big
                />
              </div>
              <div className={classes.forgotRow}>
                <Button
                  parentStyles={classes.forgotButton}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  onPress={() => null}
                >
                  {LABELS.RESET_PASSWORD}
                </Button>
              </div>
              <div className={classes.backRow}>
                <Text textVariant="info">{LABELS.BACK}</Text>
                <Text
                  textVariant="link"
                  parentStyles={classes.backLink}
                  onPress={() => setView(View.Login)}
                >
                  {LABELS.LOGIN}
                </Text>
              </div>
            </Box>
          </form>
        </div>
      </div>
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title="A reset password email has been sent!"
        content={
          <div>
            If you don’t see it in your inbox, please make sure to also check your spam folders.
          </div>
        }
        primaryButtonTitle="OK"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};

const loading = loadingSelector([AuthActionTypes.FORGOT_PASSWORD]);

const error = errorMessageSelector([AuthActionTypes.FORGOT_PASSWORD]);

const mapStateToProps = (state: ApplicationState) => ({
  error: error(state),
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  postForgotPasswordEmail: (data: ForgotPasswordRequest, onSuccess: () => void) => {
    dispatch(AuthActions.forgotPasswordRequest({ ...data, onSuccess }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
