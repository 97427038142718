import { StoreAction } from '../StoreHelper';

export enum SpinnerActionTypes {
  // Shows the indicator after 100ms
  ShowIndicator = 'SHOW_LOADING',

  // Shows the indicator immediatelly. Increases the counter.
  // Don't use it in your code.
  ShowIndicatorNowInternal = 'SHOWNOW_LOADING_INTERNAL',

  // Decreases the counter. Hides the indicator, if counter reaches zero.
  HideIndicator = 'HIDE_LOADING',
}

export type SpinnerAction = StoreAction<SpinnerActionTypes, null>;

export class SpinnerActions {
  // Shows the indicator after 100ms
  public static show(): SpinnerAction {
    return { type: SpinnerActionTypes.ShowIndicator, data: null };
  }

  // Decreases the counter. Hides the indicator, if counter reaches zero.
  public static hide(): SpinnerAction {
    return { type: SpinnerActionTypes.HideIndicator, data: null };
  }
}
