import { useSelector } from 'react-redux';
import { ServiceTypes, Services } from '../../../../models/services/services';
import { ApplicationState } from '../../../../store/RootReducer';
import { DashboardState } from '../../../../store/state/DashboardState';
import { AvailableServicesResponse } from '../../../../models/checkout/Checkout';

export const getServiceIconForCheckout = (
  item: AvailableServicesResponse,
  dashboardState: DashboardState,
) => {
  if (!!dashboardState.onboardedProperties && !!dashboardState.onboardedProperties.length) {
    const foundService = dashboardState.onboardedProperties[
      dashboardState.selectedProperty
    ].availableServices.find((a) => a.ServiceType.replace(' ', '') === item.type.replace(' ', ''))!;
    return foundService.Logo.Dark;
  } else {
    return item.logo;
  }
};
