import { MultiFileUploadResponse } from '../../models/files/files';
import { FileUploadAction, FileUploadActionTypes } from '../actions/FileUploadActions';
import { FileUploadState } from '../state/FileUploadState';

export const initialState: FileUploadState = {
  fileUpload: undefined,
  callerId: '',
  isUploading: false,
};

export const fileUploadReducer: (
  state: FileUploadState,
  action: FileUploadAction,
) => FileUploadState = (state = initialState, action) => {
  switch (action.type) {
    case FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_SUCCESS: {
      const data = action.data as MultiFileUploadResponse;
      return {
        ...state,
        success: true,
        fileUpload: data.file,
        callerId: data.id,
      };
    }
    case FileUploadActionTypes.DELETE_FILE_SUCCESS: {
      return {
        ...state,
        fileUpload: null,
      };
    }
    case FileUploadActionTypes.RESET_FILE_UPLOAD_STATE: {
      return { ...initialState };
    }

    case FileUploadActionTypes.SHOW_FILE_LOADING:
      return {
        ...state,
        isUploading: true,
      };

    case FileUploadActionTypes.HIDE_FILE_LOADING:
      return {
        ...state,
        isUploading: false,
      };

    default:
      return state;
  }
};
