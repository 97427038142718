/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCurrentTheme } from '../../../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/Sizes';
import { getFonts } from '../../../../helpers/GetFonts';

export const useStyles = () => {
  const theme = getCurrentTheme();

  return makeStyles({
    cantFindText: {
      marginTop: 16,
      marginBottom: 12,
      color: theme.colors.buttonPrimary,
      cursor: 'pointer',
    },
    hide: {
      display: 'none',
    },
  })();
};
